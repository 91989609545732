const borderInput = {
	default: 'solid 1px #e9ecef',
	hover: 'solid 1px #1a7ae5',
	focus: 'solid 1px #1a7ae5',
	error: 'solid 1px #d64531',
};

const borderRadius = {
	default: '8px',
	popover: '16px',
};

export { borderInput, borderRadius };
