import React, { useEffect, useRef, useState } from 'react';

import isNil from 'lodash/isNil';

import useEventListener from 'utils/customHook/useEventListener';

import { TextField } from 'ella-storybook';
import { isValidNumber } from 'modules/MyCourses/containers/GradeBook/GroupTable/helpers';

export const GradeInput = (props) => {
	const { grade, totalPoint, onChange, onBlur, disabled, className, ...rest } = props;

	const [gradeTemporary, setGradeTemporary] = useState(null);
	useEffect(() => {
		setGradeTemporary(grade);
	}, [grade]);

	const inputRef = useRef(null);

	const handleKeyDown = (e) => {
		const { key } = e;
		if (key === 'Enter') {
			inputRef.current && inputRef.current.blur();
		}
	};

	useEventListener('keydown', handleKeyDown);
	return (
		<TextField
			type='number'
			inputRef={inputRef}
			value={gradeTemporary}
			onChange={(e) => onChange && onChange(e.target.value)}
			onInput={(e) => {
				const isValid = isValidNumber(e.target.value) && e.target.value > totalPoint;
				if (isValid) {
					e.target.value = totalPoint;
					return;
				}
				if (isValidNumber(e.target.value)) {
					setGradeTemporary(e.target.value);
					return;
				}
				setGradeTemporary(null);
				return;
			}}
			onBlur={(e) => {
				setGradeTemporary((prev) => {
					if (isNil(prev)) {
						return grade;
					}
				});
				return onBlur(e.target.value);
			}}
			inputProps={{
				decimalScale: 2,
				allowNegative: false,
				// max: totalPoint,
				isAllowed: (values) => {
					const { floatValue, formattedValue } = values;
					return formattedValue === '' || floatValue >= 0;
				},
			}}
			className={className}
			disabled={disabled}
			{...rest}
		/>
	);
};
