import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PreviewFile from 'components/PreviewFile';

import commonActions from 'shared/Common/actions';

import { Typography } from 'ella-storybook';
import { Box, CardAttachment } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { getTypeFile, splitNameAndExtension } from 'utils';

const useStyles = makeStyles()((theme) => ({
	root: {},
	scrollBar: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		//NOTE: maxheight: base on Item google file
	},
	cardAttachments: {
		'& div > img': {
			width: theme.spacing(2),
			height: theme.spacing(2),
		},
		'& div > svg': {
			margin: `${theme.spacing(0, 0.2)} !important`,
		},
	},
	emptyContent: {
		color: theme.newColors.gray[700],
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
}));
const commonSelector = (state) => state.Common;
function AttachmentList(props) {
	const {
		files,
		disabled,
		archiveMode,
		onRemove,
		isRenderContextMenu,
		onRetry,
		allowDownloadAttachmentFiles,
		onSelectFile,
		fileSelected,
	} = props;
	const { classes } = useStyles(props);
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const [isVisiblePreviewFile, setIsVisiblePreviewFile] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const { url, fileInformation } = useSelector(commonSelector);

	const togglePreviewFile = () => {
		setSelectedFile(null);
		setIsVisiblePreviewFile(!isVisiblePreviewFile);
		dispatch(
			commonActions.commonSetState({
				openPreview: false,
			}),
		);
	};

	const onPreviewFiles = (item) => {
		setIsVisiblePreviewFile(true);
		setSelectedFile(item);

		dispatch(
			commonActions.getLinkToView({
				param: {
					filename: item.filename,
					originalname: item.originalName,
					mimetype: item.mimetype,
				},
				isFetchingFile: true,
			}),
		);
	};

	const onDownload = (item) => {
		dispatch(
			commonActions.download({
				param: {
					filename: item.filename,
					originalname: item.originalName,
					mimetype: item.mimetype,
				},
			}),
		);
	};

	return (
		<>
			{files.map((item) => {
				const typeFile = getTypeFile(splitNameAndExtension(item?.originalName || item?.filename || ''));
				const subTitle = !item.error
					? typeFile[0].toUpperCase() + typeFile.slice(1)
					: t('upload_unsuccessful', { ns: 'media' }); // capitalize first letter

				return (
					<Box
						aria-selected={fileSelected?.id === item.id && fileSelected?.mediaType === item.mediaType}
						className='media-css'
					>
						<CardAttachment
							icon={typeFile}
							subTitle={subTitle}
							className={classes.cardAttachments}
							onClick={() => {
								if (onSelectFile) {
									return onSelectFile(item);
								}
								onPreviewFiles(item);
							}}
							onRemove={!disabled && !item?.uploading && !archiveMode ? () => onRemove(item) : null}
							onDownload={allowDownloadAttachmentFiles ? () => onDownload(item) : null}
							key={item.id || item.uid}
							title={item?.originalName || item?.filename}
							uploading={item?.uploading}
							uploadingText={t('uploading', { ns: 'media' })}
							percentage={item?.percentage}
							error={item?.error}
							tryAgainText={t('try_again', { ns: 'media' })}
							onRetry={() => onRetry(item)}
							contextMenuProps={
								isRenderContextMenu && {
									items: [
										{
											label: <Typography variant='bodyMediumRegular'>{t('view', { ns: 'common' })}</Typography>,
											onClick: () => onPreviewFiles(item),
										},
										{
											label: <Typography variant='bodyMediumRegular'>{t('download', { ns: 'common' })}</Typography>,
											onClick: () => onDownload(item),
										},
									],
								}
							}
						/>
					</Box>
				);
			})}
			{isVisiblePreviewFile && (
				<PreviewFile
					currentFile={selectedFile}
					fileType={selectedFile?.mimetype}
					url={url || ''}
					isFullScreen
					fileName={selectedFile?.originalName || ''}
					onClose={togglePreviewFile}
					fileInformation={fileInformation}
				/>
			)}
		</>
		// </PerfectScrollbar>
	);
}

AttachmentList.propTypes = {
	canDownload: PropTypes.bool,
	disableUseTemplate: PropTypes.func,
	files: PropTypes.array,
	hasPermission: PropTypes.bool,
	itemOnView: PropTypes.number,
	onChangeChooseTemplate: PropTypes.func,
	onRemove: PropTypes.func,
	onUseTemplate: PropTypes.func,
	useTemplateLoading: PropTypes.bool,
	isRenderContextMenu: PropTypes.bool,
	allowDownloadAttachmentFiles: PropTypes.bool,
};

AttachmentList.defaultProps = {
	files: [],
	itemOnView: 4,
	allowDownloadAttachmentFiles: false,
	archiveMode: false,
	fileSelected: null,
};

export default AttachmentList;
