import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isNil from 'lodash/isNil';

import { Box } from '@mui/material';

import ErrorPage from 'components/TblErrorPage';
import withReducer from 'components/TblWithReducer';

import useDidMountEffect from 'utils/customHook/useDidMoutEffect';
import { deepCompareObject } from 'utils/deepCompareObject';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import clsx from 'clsx';
import { FullScreenDialog } from 'ella-storybook';
import { delay } from 'lodash';
import myCourseActions from 'modules/MyCourses/actions.js';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { ATTEMPTS } from 'modules/MyTasks/constants';
import PropTypes from 'prop-types';
import { checkPermission } from 'utils';

import MyCoursesActions from '../../MyCourses/actions';
import { getStatusStudentProgressFilter, initFilterStatus } from '../../MyCourses/utils';
import GraderActions from '../actions';
import { ROLE_CAN_VIEW_GRADER } from '../constants';
import reducers from '../reducers';

import GeneralBar from './GeneralBar';
import GraderLeftContent from './GraderLeftContent';
import GraderRightContent from './GraderRightContent';
import StudentListDialog from './StudentListDialog';
import { useStyles } from './styles';
import ToggleCommentContainer from './ToggleCommentContainer';

function GraderFullScreenDialog({
	onCloseDialog,
	openGrader,
	courseId,
	activityId,
	// extraCredit,
	activityType,
	originalSectionSelected,
	gradingPeriodSelected,
	schoolYearSelected,
	studentId,
	currentTerm,
	unitInfo,
	studyForTestWithTestId,
}) {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const authContext = useContext(AuthDataContext);
	const { currentUser } = authContext;
	const { organizationId, timezone } = currentUser;

	// const permission = useSelector((state) => state?.Grader?.permission);
	const gradingList = useSelector((state) => state?.Grader?.gradingList);
	const masterActivity = useSelector(
		(state) => state.Grader.graderDetail?.shadowActivity?.masterActivity,
		deepCompareObject,
	);

	const toggleComment = useSelector((state) => state?.Grader?.toggleComment);
	const openStudentListDialog = useSelector((state) => state?.Grader?.openStudentListDialog);
	const loadingLogSubmission = useSelector((state) => state?.Grader?.loadingLogSubmission);

	const sectionSelected = useSelector((state) => state?.Grader?.sectionSelected);
	const filtersSelected = useSelector((state) => state?.Grader?.filtersSelected);
	const studentSelected = useSelector((state) => state?.Grader?.studentSelected);
	const shadowActivityId = useSelector((state) => state?.Grader?.shadowActivityId);

	const basicInfo = useSelector((state) => state?.Grader?.basicInfo);
	const sectionList = useSelector((state) => state?.Grader?.sectionList);
	const sort = useSelector((state) => state?.Grader?.sort);
	const sortField = useSelector((state) => state?.Grader?.sortField);

	//---------------HOOK-----------------
	const activityName = useMemo(
		() => (activityType === ACTIVITY_CATEGORY.TEST ? `Study for ${masterActivity?.name}` : masterActivity?.name),
		[masterActivity?.name],
	);

	useEffect(
		() => () => {
			dispatch(GraderActions.graderResetState());
			dispatch(MyCoursesActions.myCoursesSetState({ gradeSuccess: null }));
		},
		[],
	);

	useEffect(() => {
		if (isNil(originalSectionSelected)) return;

		dispatch(
			GraderActions.graderSetState({
				sectionSelected: originalSectionSelected,
			}),
		);
	}, [originalSectionSelected]);

	useEffect(() => {
		if (openGrader) {
			dispatch(
				GraderActions.graderSetState({
					sectionSelected: originalSectionSelected ?? -1,
					activitySelected: masterActivity?.id ?? -1,
					activitySelectedType: parseInt(activityType),
					attemptType: masterActivity?.attempts < 0 ? ATTEMPTS.UNLIMITED : ATTEMPTS.UNLIMITED * -1,
					gradingAttemptsMethod: masterActivity?.gradingAttemptsMethod,
					filtersSelected: initFilterStatus(),
					sort: '',
					sortField: 'grading',
				}),
			);
		}
	}, [openGrader, originalSectionSelected, masterActivity]);

	useEffect(() => {
		const beforeUnloadListener = (event) => {
			event.preventDefault();
			if (loadingLogSubmission) {
				return (event.returnValue = '');
			}
		};

		const onBackButtonEvent = (event) => {
			event.preventDefault();
			if (loadingLogSubmission) {
				return (event.returnValue = '');
			}
		};
		window.addEventListener('beforeunload', beforeUnloadListener);

		window.addEventListener('popstate', onBackButtonEvent);

		return () => {
			window.removeEventListener('beforeunload', beforeUnloadListener);
			window.addEventListener('popstate', onBackButtonEvent);
		};
	}, [loadingLogSubmission]);

	useEffect(() => {
		if (organizationId && courseId && openGrader) {
			const payload = { orgId: organizationId, courseId };

			dispatch(
				GraderActions.getActivitiesInGrader({
					...payload,
					currentActivityId: activityId || null,
				}),
			);
			dispatch(myCourseActions.getPermissionCourse(payload));
			dispatch(GraderActions.getBasicInfo(payload));
		}
	}, [openGrader, organizationId, courseId, activityId]);

	useDidMountEffect(() => {
		if (!isNaN(+activityId) && activityId !== -1 && activityType) {
			dispatch(
				GraderActions.getSectionsByActivityGrader({
					orgId: organizationId,
					courseId,
					activityId: activityId,
					activityType: activityType,
					fetchingSectionList: true,
				}),
			);
		}
	}, [activityId, activityType]);

	useDidMountEffect(() => {
		if (openGrader) {
			const params = getParams();

			dispatch(
				GraderActions.graderSetState({
					fetchingGradingList: true,
					shadowActivityId: null,
					comments: [],
				}),
			);

			const payload = {
				orgId: organizationId,
				courseId,
				activityId: activityId,
				activityType: activityType,
				urlParams: params,
			};
			dispatch(GraderActions.getGradingList(payload));
			// dispatch(GraderActions.getTotalGraded(payload));
		}
	}, [organizationId, activityId, activityType, openGrader, sectionSelected]);

	useDidMountEffect(() => {
		// shadowActivityId in gradeList is already mapped with masterActivity
		const callback = (g, idx, arr) => g.studentId === Number(studentSelected ?? studentId);

		let _studentSelected = gradingList.find(callback) ?? gradingList?.[0];

		if (_studentSelected) {
			dispatch(
				GraderActions.graderSetState({
					studentSelected: _studentSelected.studentId,
					shadowActivityId: _studentSelected.shadowActivityId,
				}),
			);
		}
	}, [gradingList, studentSelected, studentId]);

	useDidMountEffect(() => {
		if (studentSelected && shadowActivityId && openGrader && studentSelected !== -1) {
			dispatch(
				GraderActions.getGraderDetail({
					orgId: organizationId,
					courseId,
					shadowId: shadowActivityId,
					activityType: activityType,
					studentId: studentSelected,
					isFetchingGraderDetail: true,
				}),
			);
		}
	}, [studentSelected, shadowActivityId, organizationId, activityType, openGrader]);

	// useDidMountEffect(() => {
	//   if (inputGradeSuccess && openGrader && sectionSelected) {
	//     const params = getParams();
	//     dispatch(
	//       GraderActions.getTotalGraded({
	//         orgId: organizationId,
	//         courseId,
	//         activityId: activityId,
	//         activityType: activityType,
	//         urlParams: params,
	//       })
	//     );

	//     currentTermId &&
	//       dispatch(
	//         myCourseActions.mcCalculateOverallCourseGrade({
	//           courseId,
	//           termId: currentTermId,
	//           data: {
	//             studentId: studentSelected,
	//           },
	//           mcCalculateOverallCourseGradeSuccess: null,
	//         })
	//       );
	//   }
	// }, [inputGradeSuccess, openGrader, activityId, organizationId, activityType, sectionList]);

	//------------------------------------

	//---------------EVENT----------------
	const handleCloseDialog = () => {
		delay(() => onCloseDialog(), 200);
	};
	//------------------------------------

	const studentInfo = useMemo(
		() => (gradingList || []).find((item) => item.studentId === studentSelected),
		[studentSelected, gradingList],
	);

	const getParams = useCallback(() => {
		const params = {
			activityId: activityId,
			activityType: activityType,
			filter: getStatusStudentProgressFilter(filtersSelected),
			sortField: sortField || 'grading',
			sort: sort || '',
			sectionId: sectionSelected,
		};

		Object.assign(params, { timezone });
		return params;
	}, [activityId, activityType, sort, sortField, sectionSelected, filtersSelected]);

	const handleChangeSection = useCallback(
		(objSection) => {
			const params = getParams();
			params.sectionId = objSection?.value;

			const payload = {
				orgId: organizationId,
				courseId,
				activityId: activityId,
				activityType: activityType,
				urlParams: params,
			};

			dispatch(GraderActions.getGradingList(payload));
			dispatch(GraderActions.getTotalGraded(payload));
			dispatch(
				GraderActions.graderSetState({
					sectionSelected: params.sectionId ?? null,
					gradingList: [],
					studentSelected: -1,
				}),
			);
		},
		[organizationId, courseId, activityId, activityType, getParams],
	);

	return (
		<>
			<FullScreenDialog
				displayAppBar={false}
				open={openGrader}
				// onClose={() => onCloseDialog()}
				className={classes.root}
			>
				<Box mb={-10}>
					{currentUser && !checkPermission(currentUser, ROLE_CAN_VIEW_GRADER) && (
						<ErrorPage
							errorCode='403'
							shortDescription='forbidden'
							detailDescription='no_permission'
							isNotFoundPage={false}
							isPublic={true}
						/>
					)}
					<GeneralBar
						onCloseDialog={handleCloseDialog}
						schoolYearSelected={schoolYearSelected}
						isExtraCredit={masterActivity?.extraCredit}
						{...{
							courseId,
							activityName,
							activityType,
							activityId,
							sectionSelected,
							filtersSelected,
							gradingList,
							basicInfo,
							sectionList,
							studentSelected,
							unitInfo,
							studyForTestWithTestId,
						}}
					/>
					<Box display={'flex'} className={classes.content}>
						<Box flex={'1 1 auto'} overflow={'hidden'}>
							<GraderLeftContent />
						</Box>
						<Box className={clsx({ [classes.hide]: toggleComment })} flex={'0 0 320px'}>
							<ToggleCommentContainer />
						</Box>
						<Box flex={'0 0 320px'} overflow={'hidden'}>
							<GraderRightContent
								currentTermId={currentTerm?.id}
								sectionSelected={originalSectionSelected}
								gradingPeriodSelected={gradingPeriodSelected}
								extraCredit={masterActivity?.extraCredit}
								courseId={courseId}
								{...{ activityType, studentInfo }}
							/>
						</Box>
						<StudentListDialog
							open={openStudentListDialog}
							studentList={gradingList}
							{...{
								sectionList,
								sectionSelected,
								handleChangeSection,
								studentSelected,
							}}
						/>
					</Box>
				</Box>
			</FullScreenDialog>
		</>
	);
}

GraderFullScreenDialog.propTypes = {
	activityId: PropTypes.any,
	activityName: PropTypes.any,
	activityType: PropTypes.any,
	attempts: PropTypes.number,
	courseId: PropTypes.any,
	// extraCredit: PropTypes.any,
	gradingAttemptsMethod: PropTypes.any,
	onCloseDialog: PropTypes.func,
	openGrader: PropTypes.any,
	originalSectionSelected: PropTypes.any,
	schoolYearSelected: PropTypes.any,
	unitInfo: PropTypes.shape({
		id: PropTypes.number,
		unitName: PropTypes.string,
	}),
	studyForTestWithTestId: PropTypes.number,
};

GraderFullScreenDialog.defaultProps = {};

export default withReducer('Grader', reducers)(GraderFullScreenDialog);
