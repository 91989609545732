import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	isFetchingAgendaDetails: false,
	isFetchingAgendaList: false,
	schoolYearStatus: null,
	nearestSession: null,
};

export default createReducers(initialState, actions);
