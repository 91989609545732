import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import trim from 'lodash/trim';

import { Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import TblEditor from 'components/TblEditor';
import TblIcon from 'components/TblIcon';
import TourContent from 'components/TblTour/TourContent';
import TblUseSnackbar from 'components/TblUseSnackbar';

import { MAX_GOOGLE_UPLOAD_FILES } from 'utils/constants';

import MyAttachments from 'shared/Attachments/MyAttachments';
import { useSchoolYearDataContext } from 'shared/Auth/containers/SchoolYear';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import TimeToCompleteTourImage from 'assets/images/timeToComplete.png';
import { Alert, BaseModal, Button, FullScreenDialog, TextField, Tooltip, Typography } from 'ella-storybook';
import { useFormik } from 'formik';
import { delay } from 'lodash-es';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY, ASSIGNMENT_STATUS, GRADE_WEIGHT_TYPE, MAX_LENGTH } from 'modules/MyCourses/constants';
import myProfileActions from 'modules/MyProfile/actions';
import { SCHOOL_YEAR_STATUS } from 'modules/SchoolYear/constants';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from 'tss-react/mui';
import { handleConvertBase64Image, replaceHTMLTag } from 'utils';
import * as Yup from 'yup';

import { filterGoogleFileSelected } from '../../Google/utils';
import { USER_BEHAVIOR } from '../../User/constants';
import FormSkeleton from '../components/FormSkeleton';
import Grading from '../components/Grading';
import LateTurnIn from '../components/LateTurnIn';
import ScheduleDialog from '../components/ScheduleDialog/ScheduleDialog';
import SubmissionAttempts from '../components/SubmissionAttempt/SubmissionAttempts';
import SubmissionRequirements from '../components/SubmissionRequirements';
import {
	APPLY_DEDUCTION,
	ATTEMPT_TYPES,
	COURSE_STATUS,
	DIALOG_TYPE,
	ESTIMATE,
	EXTRA_CREDIT_DEFAULT,
	GRADE_TYPE,
	PICTURE_IN_CLASS_SUBMISSIONS_METHOD,
	SESSIONS,
	SESSIONS_DUE_LATE_DEFAULT,
	SUBMISSION_METHOD,
	SUBMISSION_REQUIRED_DEFAULT,
	TYPE_OF_CREATE,
} from '../constants';
import { checkPermissionCreateAndPublish, formatTime, getDefaultGradeWeightValue } from '../utils';

import LinkedContents from './LinkedContents';

const useStyles = makeStyles()((theme) => ({
	indicator: {
		margin: theme.spacing(3, 0, 3.25),
	},
	errorIndicator: {
		marginRight: theme.spacing(26.5),
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
	},
	leftContent: {
		width: theme.spacing(82),
		marginRight: theme.spacing(4),
	},
	estimate: {
		display: 'flex',
		position: 'relative',
		'& .MuiInputBase-root': {
			width: theme.spacing(15),
		},
	},
	estimateInfo: {
		cursor: 'pointer',
		top: '4px',
		'& .material-symbols-rounded': {
			fontSize: theme.spacing(1.75),
		},
	},
	formatTime: {
		color: theme.newColors.gray[400],
		marginTop: theme.spacing(4.5),
		position: 'absolute',
		left: theme.spacing(15),
	},
	rightContent: {
		width: theme.spacing(39),
		gap: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

function CreateAssignmentDrawer(props) {
	const {
		isVisible,
		onClose,
		unit,
		assignmentId,
		courseIdProp,
		editable,
		updateUnit,
		// updateMasterItem,
		// assignmentInfo,
		// shadowAssignmentInfo,
		unitId,
		// getCourseItemByUnit,
		assignmentViewData,
		shouldCallAPItoGetModalData,
		isViewOnly,
		shouldResetState,
	} = props;

	const { classes } = useStyles();
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const { t } = useTranslation(['myCourses', 'common', 'tour']);
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const authContext = useContext(AuthDataContext);
	const { organizationId, settings } = authContext.currentUser;
	const haveCreated = settings?.behavior?.includes(USER_BEHAVIOR.HAVE_CREATED_ASSIGNMENT);
	const courseId = match?.params?.courseId || courseIdProp;

	const schoolYearData = useSchoolYearDataContext();
	const archiveMode = schoolYearData.status === SCHOOL_YEAR_STATUS.ARCHIVED;

	const permission = useSelector((state) => state.AllCourses?.permission);
	const havePermissionPublish = checkPermissionCreateAndPublish(permission);
	const gradeWeight = useSelector((state) => state?.MyCourses?.gradeWeight ?? []);
	const assignment = useSelector((state) => state?.AllCourses?.assignment ?? assignmentViewData);
	const isEditingAssignment = useSelector((state) => state?.AllCourses?.isEditingAssignment);
	const sessions = useSelector((state) => state.AllCourses?.sessions);
	const sections = useSelector((state) => state.AllCourses?.sections);
	const shadowAssignments = useMemo(() => assignment?.shadowAssignments ?? {}, [assignment?.shadowAssignments]);
	const isFetchingAssignmentDetails = useSelector((state) => state.AllCourses?.isFetchingAssignmentDetails);
	const errorMasterAssignment = useSelector((state) => state.AllCourses?.errorMasterAssignment);
	const createNewAssignmentSuccess = useSelector((state) => state.AllCourses?.createNewAssignmentSuccess);
	const editMasterAssignmentSuccess = useSelector((state) => state.AllCourses?.editMasterAssignmentSuccess);
	const isDraft = useSelector((state) => state?.AllCourses?.basicInfo?.status === COURSE_STATUS.DRAFT);

	const gradingLateCompletion = useSelector((state) => state.MyCourses?.gradingLateCompletion || {});

	const gradeCalculation = useSelector((state) => state.MyCourses?.gradeCalculation);

	const [visiblePublishDialog, setVisiblePublishDialog] = useState(false);
	const [openTour, setOpenTour] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [typeOfCreate, setTypeOfCreate] = useState(0);
	const [isShowTooltip, setIsShowToolTip] = useState(true);

	// const [media, setMedia] = React.useState({});
	const [attachments, setAttachments] = React.useState(assignment?.attachments || []);
	const [linkContents, setLinkContents] = React.useState({
		lessonLinkIds: compact(assignment?.linkContents?.map((item) => item.lessonLinkId)) || [],
		assignmentLinkIds: compact(assignment?.linkContents?.map((item) => item.assignmentLinkId)) || [],
	});
	const [gradeWeightOptions, setGradeWeightOptions] = useState([]);
	const [gradeWeightInitial, setGradeWeightInitial] = useState(null);
	const [uploadingState, setUploadingState] = useState(false);

	//User guiding
	const history = useHistory();
	const location = useLocation();

	const gradeWeightCategories = useCallback(() => {
		// if (isVisible) {
		//   dispatch(
		//     myCoursesActions.getCompareGradeWeightCategories({
		//       orgId: organizationId,
		//       courseId: courseId,
		//     })
		//   );
		// }

		dispatch(
			myCoursesActions.mcGetGradeCalculation({
				courseId: courseId,
				orgId: organizationId,
				urlParams: {
					isCreateActivity: true,
				},
			}),
		);

		dispatch(
			myCoursesActions.getDetailGradingLateCompletion({
				orgId: organizationId,
				courseId: Number(courseId),
				// urlParams: {
				//   activityType: Number(ACTIVITY_CATEGORY.ASSIGNMENT) // epic have handle this case for assignment and test
				// }
			}),
		);
	}, [courseId, organizationId]);

	const getAssignmentDetail = useCallback(() => {
		if (assignmentId && isVisible) {
			setIsFetching(true);
			dispatch(
				myCoursesActions.getAssignmentDetail({
					orgId: organizationId,
					courseId: courseId,
					unitId: unit?.id,
					assignmentId: assignmentId,
					isFetchingAssignmentDetails: true,
				}),
			);
		}
	}, [assignmentId, courseId, organizationId, unit?.id, isVisible]);

	useEffect(() => {
		return () => {
			if (!shouldResetState) return;
			myCoursesActions.myCoursesSetState({
				assignment: {},
				errorMasterAssignment: null,
				createNewAssignmentSuccess: false,
				editMasterAssignmentSuccess: false,
				gradeWeight: [],
				isFetchingAssignmentDetails: false,
				gradeCalculation: {},
				mcGetGradeCalculationSuccessTest: false,
				// gradingLateCompletion: {},
			});
		};
	}, [shouldResetState]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		if (isVisible) {
			queryParams.append('modal', 'assignment');
			history.replace({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		}
	}, [isVisible]);
	/////////////////////////

	// for show tooltip with option allow attempt
	useEffect(() => {
		if (assignmentId === undefined) {
			setIsShowToolTip(true);
			return;
		}
		setIsShowToolTip(false);
	}, [assignmentId]);

	useEffect(() => {
		if (!shouldCallAPItoGetModalData || !isVisible) return;
		getAssignmentDetail();
		gradeWeightCategories();
	}, [getAssignmentDetail, gradeWeightCategories, shouldCallAPItoGetModalData, isVisible]);

	useEffect(() => {
		if (isVisible && !haveCreated) {
			delay(() => {
				setOpenTour(true);
			}, 700);
		}
	}, [isVisible]);

	useEffect(() => {
		setLinkContents({
			lessonLinkIds: assignment?.linkContents?.map((item) => item.lessonLinkId) || [],
			assignmentLinkIds: assignment?.linkContents?.map((item) => item.assignmentLinkId) || [],
		});

		setAttachments(assignment?.attachments || []);
		if (assignment?.id === assignmentId) {
			setIsFetching(false);
		}
	}, [assignment, assignmentId]);

	useEffect(() => {
		const gradeWeightAssignment = gradeWeight
			.filter(({ type }) => type === GRADE_WEIGHT_TYPE.ASSIGNMENT)
			.map((item) => ({ value: item.id, label: item.name, ...item }));
		if (gradeWeightAssignment.length) {
			// set first grade weight for default
			let init = getDefaultGradeWeightValue({ gradeWeightOptions: gradeWeightAssignment });
			if (!isEmpty(assignment)) {
				init = gradeWeightAssignment.find(({ value }) => value === assignment?.gradeWeightCriteriaId);
			}

			setGradeWeightInitial(init);
			setGradeWeightOptions(gradeWeightAssignment);
		}
	}, [gradeWeight]);

	useEffect(() => {
		if (errorMasterAssignment) {
			switch (errorMasterAssignment.subcode) {
				case 5:
					const initialAllowLate = !!assignment?.allowLateTurnIn;
					enqueueSnackbar(t('myCourses:error_assigned_late'), {
						variant: 'error',
					});
					handleChange('allowLateTurnIn', initialAllowLate, 7);
					break;
				default:
					break;
			}
		}
	}, [errorMasterAssignment]);

	useEffect(() => {
		if (createNewAssignmentSuccess || editMasterAssignmentSuccess) {
			switch (typeOfCreate) {
				case TYPE_OF_CREATE.CREATE_AS_DRAFT:
					toggleCloseDrawer();
					break;
				case TYPE_OF_CREATE.CREATE_AND_PUBLISH:
					setVisiblePublishDialog(true);
					dispatch(
						myCoursesActions.myCoursesSetState({
							errorMasterAssignment: null,
							createNewAssignmentSuccess: false,
							editMasterAssignmentSuccess: false,
							gradeWeight: [],
							gradeCalculation: {},
							mcGetGradeCalculationSuccessTest: false,
							// gradingLateCompletion: {},
						}),
					);
					setAttachments([]);
					onClose();
					//User Guiding
					const queryParams = new URLSearchParams(location.search);

					if (queryParams.has('modal')) {
						queryParams.delete('modal');
						history.replace({
							search: queryParams.toString(),
						});
					}
					////////////////
					break;
				default:
					break;
			}
			updateUnit && updateUnit(unit?.id);
		}
	}, [createNewAssignmentSuccess, editMasterAssignmentSuccess, unit, updateUnit]);

	const validationAssigned = Yup.object().shape({
		assignmentName: Yup.string().trim().required(t('common:required_message')),
		description: Yup.string().nullable(),
		duration: Yup.number()
			.min(ESTIMATE.MIN)
			.max(ESTIMATE.MAX)
			.test('is-required', t('common:required_message'), (value) => !!value),
		gradeType: Yup.number().required(t('common:required_message')),
		totalPoint: Yup.mixed().when('gradeType', {
			is: (val) => val === GRADE_TYPE.GRADED,
			then: Yup.number().nullable().min(1).max(10000).required(t('common:required_message')),
		}),
		extraCredit: Yup.boolean().nullable(),
		// gradeWeightCriteriaId: Yup.mixed().when('gradeType', {
		//   is: (val) => val === GRADE_TYPE.GRADED,
		//   then: Yup.number().nullable().required(t('common:required_message')),
		// }),
		sessionDueLate: Yup.mixed().when('completeBy', {
			is: (val) => val === SESSIONS.CUSTOM,
			then: Yup.number().min(1).max(99).required(t('common:required_message')),
		}),
		attempts: Yup.mixed().when('submissionRequired', {
			is: true,
			then: Yup.number().nullable().min(-1).max(100),
		}),
		deduct: Yup.mixed().when('partialCredit', {
			is: true,
			then: Yup.number().required(t('common:required_message')),
		}),
		deductType: Yup.mixed()
			.nullable()
			.when('partialCredit', {
				is: true,
				then: Yup.object().required(t('common:required_message')),
			}),
		lowestGradePercent: Yup.mixed().when('partialCredit', {
			is: true,
			then: Yup.number().min(0).max(100).required(t('common:required_message')),
		}),
	});

	const onAttachFile = (files, isRemove = false) => {
		if (isRemove) {
			return setAttachments(attachments.filter((f) => f.id !== files.id));
		}
		setAttachments((preVal) => [...preVal, files]);
	};

	const onSubmit = async (values) => {
		let payload = {
			...values,
			description: replaceHTMLTag(values.description) === '' ? '' : values.description,
			assignmentName: trim(values.assignmentName),
			submissionRequired: JSON.parse(values.submissionRequired),
			attempts: values.submissionRequired ? values.attempts : -1,
			attachments: {
				mediaId: attachments?.map((file) => file.id) || [],
			},
			deductType: values.deductType.value,
			gradeWeightCriteriaId: values.gradeWeightCriteriaId,
			linkContents: linkContents,
		};

		payload.description = await handleConvertBase64Image(payload.description);

		if (isEmpty(assignment)) {
			dispatch(
				myCoursesActions.createNewAssignment({
					orgId: organizationId,
					courseId: courseId,
					unitId: unit?.id,
					isFetchingAssignmentDetails: true,
					data: payload,
				}),
			);
		} else {
			dispatch(
				myCoursesActions.editMetadataAssignment({
					orgId: organizationId,
					courseId: courseId,
					unitId: unit?.id || unitId,
					assignmentId: assignment?.id,
					isFetchingAssignmentDetails: true,
					isEditingAssignment: true,
					data: payload,
				}),
			);
		}
	};

	const formik = useFormik({
		initialValues: {
			...assignment,
			googleFiles: assignment?.googleFiles || [],
			// gradeWeightCriteriaId: assignment?.gradeWeightCriteriaId ?? gradeWeightInitial?.value,
			gradeType: assignment?.gradeType ?? GRADE_TYPE.GRADED,
			totalPoint: assignment?.totalPoint,
			extraCredit: assignment?.extraCredit || EXTRA_CREDIT_DEFAULT,
			submissionRequired: assignment?.submissionRequired ?? SUBMISSION_REQUIRED_DEFAULT, // undefined return true, else its value
			submissionMethod: assignment?.submissionMethod ?? SUBMISSION_METHOD.ONLINE,
			pictureInClassSubmissions: assignment?.pictureInClassSubmissions ?? PICTURE_IN_CLASS_SUBMISSIONS_METHOD.OPTIONAL,
			allowLateTurnIn: assignment?.allowLateTurnIn ?? gradingLateCompletion?.lateCompletion,
			partialCredit: assignment?.partialCredit ?? gradingLateCompletion?.partialCredit,
			completeBy: assignment?.completeBy ?? gradingLateCompletion?.mustComplete,
			sessionDueLate: assignment?.sessionDueLate ?? gradingLateCompletion?.sessions,
			deduct: assignment?.deduct ?? gradingLateCompletion?.deduct,
			deductType:
				(assignment?.deductType && APPLY_DEDUCTION.find(({ value }) => `${assignment?.deductType}` === `${value}`)) ??
				APPLY_DEDUCTION.find((item) => Number(item.value) === Number(gradingLateCompletion?.applyDeduction)),
			lowestGradePercent: assignment?.lowestGradePercent ?? gradingLateCompletion?.maximumDeduction,
			attempts:
				assignment?.attempts ??
				(gradingLateCompletion?.attemptType === ATTEMPT_TYPES.LIMITED ? gradingLateCompletion?.attempts : -1),
			gradingAttemptsMethod: assignment?.gradingAttemptsMethod ?? gradingLateCompletion?.gradingAttemptsMethod,

			// completeByDefault: assignment?.completeBy ?? gradingLateCompletion?.mustComplete,
			// sessionDueLateDefault: Number(assignment?.sessionDueLate) ?? Number(gradingLateCompletion?.sessions),
			// deductDefault: assignment?.deduct ?? gradingLateCompletion?.deduct,
			// deductTypeDefault: APPLY_DEDUCTION.find(
			//   (item) => Number(item.value) === Number(assignment?.deductType)
			// ) ?? APPLY_DEDUCTION.find(
			//   (item) => Number(item.value) === Number(gradingLateCompletion?.applyDeduction)
			// ),
			// lowestGradePercentDefault: assignment?.lowestGradePercent ?? gradingLateCompletion?.maximumDeduction,
		},
		enableReinitialize: true,
		validationSchema: validationAssigned,
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: onSubmit,
	});

	const { values, touched, errors, setFieldValue, resetForm, setFieldError, submitCount, handleSubmit, handleBlur } =
		formik;

	const toggleCloseDrawer = () => {
		//User Guiding
		const queryParams = new URLSearchParams(location.search);

		if (queryParams.has('modal')) {
			queryParams.delete('modal');
			history.replace({
				search: queryParams.toString(),
			});
		}
		////////////////
		resetForm();
		//NOTE: Reset gradeWeight when closing the drawer to fix bug TL-2936

		dispatch(
			myCoursesActions.myCoursesSetState({
				errorMasterAssignment: null,
				createNewAssignmentSuccess: false,
				editMasterAssignmentSuccess: false,
				isFetchingAssignmentDetails: false,
				mcGetGradeCalculationSuccessTest: false,
			}),
		);

		shouldResetState &&
			dispatch(
				myCoursesActions.myCoursesSetState({
					assignment: {},
					gradeWeight: [],
					gradeCalculation: {},
					gradingLateCompletion: {},
				}),
			);
		setAttachments([]);
		onClose();
	};

	const toggleCloseDialog = useCallback(() => {
		setVisiblePublishDialog(!visiblePublishDialog);
		dispatch(
			myCoursesActions.myCoursesSetState({
				assignment: {},
				errorMasterAssignment: null,
				createNewAssignmentSuccess: false,
				editMasterAssignmentSuccess: false,
				gradeWeight: [],
				gradeCalculation: {},
				mcGetGradeCalculationSuccessTest: false,
				gradingLateCompletion: {},
			}),
		);
	}, [visiblePublishDialog]);

	const handleChange = (fieldName, value) => {
		if (value !== values[fieldName]) {
			setFieldValue(fieldName, value);
		}
	};

	const editorChange = React.useCallback(
		(content) => {
			const trimValue = replaceHTMLTag(content);
			if (touched.description || trimValue || assignmentId) {
				handleChange('description', content, 1);
			}
			if (!trimValue) {
				handleChange('description', '', 2);
			}
		},
		[touched.description, assignmentId],
	);

	const onChangeChooseTemplate = async (sourceId) => {
		values.googleFiles.forEach((file) => {
			if (file.sourceId === sourceId) {
				return (file.isTemplate = !file.isTemplate);
			}
			file.isTemplate = false;
		});
		setFieldValue('googleFiles', values.googleFiles);
	};

	const editorMemo = React.useMemo(
		() => (
			<TblEditor
				name='description'
				disabled={isViewOnly || archiveMode}
				label={t('common:instructions')}
				placeholder={t('assignment_description_placeholder')}
				// customButtons={{
				//   insertImage: {
				//     onClick: insertImage,
				//   },
				// }}
				onChange={editorChange}
				defaultValue={values.description}
			/>
		),
		[values.description, touched.description, assignmentId],
	);
	const createAsDraft = async () => {
		if (values.totalPoint !== assignment?.totalPoint && checkAssignedStatus()) {
			setFieldError('totalPoint', t('can_not_update_total_possible_points'));
			return false;
		}
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AS_DRAFT);
		handleSubmit(values);
		openDialog && setOpenDialog(false);
	};

	const createAndPublish = async () => {
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AND_PUBLISH);
		handleSubmit(values);
	};

	const updateBehavior = useCallback(() => {
		settings.behavior.push(USER_BEHAVIOR.HAVE_CREATED_ASSIGNMENT);
		const payload = { settings };
		dispatch(myProfileActions.updateMyProfile(payload));
		setOpenTour(false);
	}, []);

	// old logic, keep it in case: require rollback
	const handleChangeGradeWeight = (gradeWeight) => {
		if (!isEmpty(assignment) && `${gradeWeight.value}` === `${assignment?.gradeWeightCriteriaId}`) {
			handleChange('completeBy', assignment.completeBy);
			handleChange('sessionDueLate', assignment.sessionDueLate);

			handleChange('deduct', assignment.deduct);
			handleChange(
				'deductType',
				assignment.deductType && APPLY_DEDUCTION.find(({ value }) => `${assignment.deductType}` === `${value}`),
			);
			handleChange('lowestGradePercent', assignment.lowestGradePercent);
			handleChange('allowLateTurnIn', assignment.allowLateTurnIn);
		} else {
			handleChange('completeBy', Number(gradeWeight.mustComplete));
			if (Number(gradeWeight.mustComplete) === SESSIONS.END) {
				handleChange('sessionDueLate', SESSIONS_DUE_LATE_DEFAULT);
			} else {
				handleChange('sessionDueLate', gradeWeight.sessions);
			}

			handleChange('deduct', gradeWeight.deduct);
			handleChange('deductType', APPLY_DEDUCTION[gradeWeight.applyDeduction - 1]);
			handleChange('lowestGradePercent', gradeWeight.maximumDeduction);

			const newGradeWeight = gradeWeightOptions.find(({ value }) => value === gradeWeight.value);
			// set config default according to grade weight
			handleChange('completeByDefault', newGradeWeight.mustComplete);
			handleChange('sessionDueLateDefault', newGradeWeight.sessions);
			handleChange('deductDefault', newGradeWeight.deduct);
			handleChange('deductTypeDefault', APPLY_DEDUCTION[newGradeWeight.applyDeduction - 1]);
			handleChange('lowestGradePercentDefault', newGradeWeight.maximumDeduction);
			handleChange('allowLateTurnIn', newGradeWeight.allowLateTurnIn);
			handleChange('partialCredit', newGradeWeight.partialCredit);
		}
	};

	const tourConfig = [
		{
			selector: '[data-tut="reactour__timeToComplete"]',
			content: () => (
				<TourContent
					image={TimeToCompleteTourImage}
					label={t('tour:time_to_complete')}
					content={t('tour:time_to_complete_content')
						.split('\n')
						.map((line) => (
							<div>{line}</div>
						))}
				/>
			),
			position: 'left',
		},
	];
	const checkAssignedStatus = useCallback(() => {
		if (!shadowAssignments || shadowAssignments.length === 0) return false;
		for (let i = 0; i < shadowAssignments.length; i++) {
			if (
				[ASSIGNMENT_STATUS.PUBLISHED_LATE, ASSIGNMENT_STATUS.CLOSED, ASSIGNMENT_STATUS.PUBLISHED].includes(
					shadowAssignments[i].status,
				)
			)
				return true;
		}
		return false;
	}, [shadowAssignments]);

	const renderContent = () => {
		const disableEditField =
			[
				ASSIGNMENT_STATUS.PUBLISHED,
				ASSIGNMENT_STATUS.CLOSED,
				ASSIGNMENT_STATUS.PUBLISHED_LATE,
				ASSIGNMENT_STATUS.SCHEDULED,
			].includes(values?.status) || isViewOnly;

		return isFetching ? (
			<FormSkeleton />
		) : (
			<form>
				{editMasterAssignmentSuccess && <TblUseSnackbar />}
				{createNewAssignmentSuccess && (
					<TblUseSnackbar message={t('common:object_created', { objectName: 'Assignment' })} />
				)}

				{!isEmpty(errorMasterAssignment) && ![4, 5].includes(errorMasterAssignment?.subcode) && (
					<Box className={classes.errorIndicator} mb={2}>
						<Alert severity='error'>{errorMasterAssignment?.message}</Alert>
					</Box>
				)}
				<div className={classes.content}>
					<div className={classes.leftContent}>
						{/* <MediaWithReducer
              isVisible={media.openMedia}
              onClose={onMediaClose}
              onSelect={handleMediaSelect}
              accept={media.acceptType}
              mediaType={media.mediaType}
            /> */}
						<Box mt={2}>
							{/**NOTE: Section 3 in TL-3330 */}
							<TextField
								fullWidth={true}
								name='assignmentName'
								required
								onBlur={handleBlur}
								disabled={isViewOnly || archiveMode}
								label={t('common:name')}
								placeholder={t('common:enter_name')}
								type='text'
								error={!!((touched.assignmentName || submitCount) && errors.assignmentName)}
								helperText={!!(touched.assignmentName || submitCount) && errors.assignmentName}
								inputProps={{ maxLength: MAX_LENGTH.ACTIVITY_NAME }}
								value={values?.assignmentName}
								onChange={(e) => handleChange('assignmentName', e.target.value, 4)}
								autoFocus
							/>
						</Box>
						<Box className={classes.estimate} mt={3}>
							<TextField
								endAdornment={
									<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
										{t('mins')}
									</Typography>
								}
								required
								name='duration'
								onBlur={(e) => {
									if (e.target.value === '') {
										return handleBlur(e);
									}
									let value = Number(e.target.value);
									if (value > ESTIMATE.MAX) {
										value = ESTIMATE.MAX;
									} else if (value < ESTIMATE.MIN) {
										value = ESTIMATE.MIN;
									}
									handleChange('duration', value, 5);
									setFieldError('duration', null);
								}}
								disabled={isViewOnly || !editable || archiveMode}
								label={
									<Box display='flex'>
										{t('time_to_complete')}
										<Tooltip
											title={`${t('time_to_complete_indicator')} ${t('number_range', {
												min: ESTIMATE.MIN,
												max: ESTIMATE.MAX,
											})}`}
											placement='top'
										>
											<Box ml='3px' mt='2px'>
												<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
											</Box>
										</Tooltip>
									</Box>
								}
								type='number'
								error={!!((touched.duration || submitCount) && errors.duration)}
								helperText={!!(touched.duration || submitCount) && errors.duration}
								inputProps={{ maxLength: 3 }}
								value={values?.duration}
								onChange={(e) => handleChange('duration', e.target.value, 6)}
							/>

							{values.duration / 60 >= 1 && (
								<Typography className={classes.formatTime} mt={3} ml={1} variant='bodySmallRegular'>
									{`Total ${formatTime(values.duration)}`}
								</Typography>
							)}
						</Box>
						<Box mt={3}>
							{/**NOTE: Section 3 in TL-3330 */}
							{editorMemo}
						</Box>

						<Box mt={3.5}>
							<Typography variant='labelMedium'>{t('common:attachments')}</Typography>
						</Box>
						<Box mt={2}>
							<MyAttachments
								// google attachments
								initialValues={values.googleFiles}
								maxItems={MAX_GOOGLE_UPLOAD_FILES}
								onChange={(files) => setFieldValue('googleFiles', files)}
								onChangeChooseTemplate={onChangeChooseTemplate}
								onAdd={(googleAttachmentsSelected) => {
									const uniqSelected = filterGoogleFileSelected(values.googleFiles, googleAttachmentsSelected);
									setFieldValue('googleFiles', uniqSelected);
								}}
								disabled={isViewOnly}
								archiveMode={archiveMode}
								// file attachments
								attachmentFiles={assignment?.attachments}
								updateData={(files, isRemove) => onAttachFile(files, isRemove)}
								setUploadingState={setUploadingState}
								canAdd={!isViewOnly && !archiveMode}
							/>
						</Box>
						{!isViewOnly && (
							<Box mt={2}>
								<LinkedContents
									// unit={{ id: unitId }}
									courseActivityInfo={assignment}
									initialLinkedContents={assignment?.linkContents}
									updateData={(linkContents) => setLinkContents(linkContents)}
									viewOnly={isViewOnly || archiveMode}
									archiveMode={archiveMode}
									courseIdProp={courseIdProp || match.params.courseId}
									subtitle={t('choose_courses_activities', {
										ns: 'myCourses',
									})}
									emptyContent={t('myCourses:no_linked_contents')}
								/>
							</Box>
						)}
					</div>
					<div className={classes.rightContent}>
						<Grading
							gradeCalculation={gradeCalculation}
							values={values}
							handleBlur={handleBlur}
							errors={errors}
							touched={touched}
							handleChange={handleChange}
							disabled={!editable || archiveMode}
							setFieldError={setFieldError}
							activityType={ACTIVITY_CATEGORY.ASSIGNMENT}
							isEditCategory={assignment?.isEditCategory}
							gradingAttemptsMethodDefault={gradingLateCompletion?.gradingAttemptsMethod}
							gradingAttemptsMethodActivity={assignment?.gradingAttemptsMethod}
						/>

						<SubmissionRequirements
							disabled={!editable || archiveMode}
							handleBlur={handleBlur}
							values={values}
							handleChange={handleChange}
						/>

						{values?.submissionRequired && (
							<SubmissionAttempts
								disabled={!editable || archiveMode}
								handleBlur={handleBlur}
								values={values}
								handleChange={handleChange}
								attemptsActivity={assignment?.attempts}
								gradingAttemptsMethodActivity={assignment?.gradingAttemptsMethod}
								attemptsGradingDefault={gradingLateCompletion?.attempts}
								gradingAttemptsMethodDefault={gradingLateCompletion?.gradingAttemptsMethod}
								gradingLateCompletion={gradingLateCompletion}
								isShowTooltip={isShowTooltip}
							/>
						)}
						{values.submissionRequired && (
							<LateTurnIn
								assignment={assignment}
								gradingLateCompletion={gradingLateCompletion}
								setFieldError={setFieldError}
								handleBlur={handleBlur}
								handleChange={handleChange}
								values={values}
								errors={errors}
								touched={touched}
								disableEditField={disableEditField}
								disabled={!editable || archiveMode}
							/>
						)}
					</div>
				</div>
			</form>
		);
	};

	const buttonsOption = [
		{
			label: t('create'),
			value: TYPE_OF_CREATE.CREATE_AS_DRAFT,
			onItemClick: createAsDraft,
		},
		{
			label: t('discard'),
			value: TYPE_OF_CREATE.DISCARD,
			onItemClick: toggleCloseDrawer,
		},
	];

	return (
		<>
			<BaseModal
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				title={t('schedule_a_course_activity')}
				contentComponent={
					<Typography variant='bodyMediumRegular'>
						{t('the_course_activity_cannot_be_scheduled', { ns: 'common' })}
					</Typography>
				}
				customFooter={
					<Box className='customFooterModal'>
						<Button onClick={createAsDraft}>{t('create')}</Button>
					</Box>
				}
			/>
			<FullScreenDialog
				open={isVisible}
				onClose={toggleCloseDrawer}
				title={
					isFetching ? (
						<Skeleton variant='text' />
					) : !isEmpty(assignment) ? (
						t('edit_the_assignment')
					) : (
						t('create_an_assignment')
					)
				}
				groupButtons={
					!!!assignmentId ? (
						<Box style={{ display: 'flex', direction: 'row' }}>
							<Button
								disabled={
									(isFetchingAssignmentDetails || isViewOnly || uploadingState || isEditingAssignment) &&
									havePermissionPublish
								}
								variant='outlined'
								onClick={createAsDraft}
							>
								{t('create_only')}
							</Button>
							<Box width={'16px'} />
							<Button
								disabled={
									(isFetchingAssignmentDetails || isViewOnly || uploadingState || isEditingAssignment) &&
									havePermissionPublish
								}
								variant='primary'
								onClick={() => {
									if (isDraft) {
										setOpenDialog(true);
									} else {
										createAndPublish();
									}
								}}
							>
								{t('create_and_schedule')}
							</Button>
						</Box>
					) : (
						!archiveMode && (
							<Stack direction={'row'} gap={2}>
								<Button size='medium' variant='outlined' color='primary' onClick={toggleCloseDrawer}>
									{t('common:cancel')}
								</Button>
								<Button
									disabled={isFetchingAssignmentDetails || isViewOnly || uploadingState || isEditingAssignment}
									loading={isEditingAssignment}
									size='medium'
									variant='primary'
									onClick={createAsDraft}
								>
									{t('common:save')}
								</Button>
							</Stack>
						)
					)
				}
			>
				{renderContent()}
			</FullScreenDialog>
			{/* {!isViewOnly && (
        <TblTour
          steps={tourConfig}
          isOpen={openTour}
          onFinish={() => updateBehavior()}
          lastStepNextButton={t('tour:benefits_acknowledged')}
        />
      )} */}
			{/* {visiblePublishDialog && (
        <AssignmentDueTimeDialog
          isVisible={visiblePublishDialog}
          assignment={assignment}
          toggleCloseDialog={toggleCloseDialog}
        />
      )} */}
			{visiblePublishDialog && (
				<ScheduleDialog
					type={ACTIVITY_CATEGORY.ASSIGNMENT}
					name={assignment?.assignmentName}
					activityId={assignment?.id || assignmentId}
					unitId={unit?.id || unitId}
					// gradingPeriodId={unit?.gradingPeriod?.id}
					courseId={courseId}
					isVisible={visiblePublishDialog}
					toggleCloseDialog={toggleCloseDialog}
					sessions={sessions}
					sections={sections}
					method={DIALOG_TYPE.SCHEDULE}
					updateUnit={updateUnit}
				/>
			)}
		</>
	);
}
// CreateAssignmentDrawer.whyDidYouRender=true;
CreateAssignmentDrawer.propTypes = {
	visibleState: PropTypes.bool,
	onClose: PropTypes.func,
	updateUnit: PropTypes.func,
	unit: PropTypes.object,
	assignmentId: PropTypes.number,
	courseIdProp: PropTypes.number,
	isVisible: PropTypes.bool,
	assignmentInfo: PropTypes.object,
	unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	shadowAssignmentInfo: PropTypes.object,
	getCourseItemByUnit: PropTypes.func,
	isViewOnly: PropTypes.bool,
	shouldCallAPItoGetModalData: PropTypes.bool,
	shouldResetState: PropTypes.bool,
	assignmentViewData: PropTypes.object,
};
CreateAssignmentDrawer.defaultProps = {
	isViewOnly: false,
	editable: true,
	viewMode: true,
	shouldCallAPItoGetModalData: true,
	shouldResetState: true,
};

export default React.memo(CreateAssignmentDrawer, isEqual);
