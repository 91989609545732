import cloneDeep from 'lodash/cloneDeep';

import { makeAjaxRequest } from 'utils/ajax';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { bufferTime, catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import myCoursesActions from '../actions';
import { END_POINT, GRADE_CALCULATION_TYPE, actions } from '../constants';

const getMcColumnGradeBook = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_COLUMN_GRADE_BOOK),
		switchMap(() =>
			makeAjaxRequest(END_POINT.mc_get_column_grade_book.method, END_POINT.mc_get_column_grade_book.url()).pipe(
				mergeMap(() => of(myCoursesActions.mcColumnGradeBookSuccess({}))),
				catchError(() => of(myCoursesActions.mcColumnGradeBookFailed({}))),
			),
		),
	);

// ofType(...[actions.MC_GET_GRADE_BOOK, actions.GRADE_SUCCESS, actions.INPUT_GRADE_SUCCESS])

const getGradeBookEpic = (action$, state$) =>
	action$.pipe(
		ofType(...[actions.MC_GET_GRADE_BOOK, actions.MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE_SUCCESS]),
		switchMap((action) => {
			const { paramDataGetGradeBook } = state$.value.MyCourses;
			const courseId = action.payload.courseId ?? paramDataGetGradeBook?.courseId;
			const termId = action.payload.termId ?? paramDataGetGradeBook?.termId;
			const urlParams = action.payload.urlParams ?? paramDataGetGradeBook?.urlParams;

			if (!(courseId || termId || urlParams)) return [];

			return makeAjaxRequest(
				END_POINT.mc_get_grade_book.method,
				END_POINT.mc_get_grade_book.url(courseId, termId, urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetGradeBookSuccess({
							isFetchingGradeBook: false,
							gradeBookColumnLatest: data.response.activitiesCategoryColumn,
							unitInfo: data.response.unitInfo,
							gradeBookGridMatrix: data.response.matrix2D,
							referenceGridColumns: data.response.cols,
							referenceGridRows: data.response.rows,
							gradeCalculationType: data.response.gradeCalculationType,
							paramDataGetGradeBook: {
								courseId: action.payload.courseId ?? paramDataGetGradeBook?.courseId,
								termId: action.payload.termId ?? paramDataGetGradeBook?.termId,
								urlParams: action.payload.urlParams ?? paramDataGetGradeBook?.urlParams,
							},
						}),
					),
				),
				catchError(() =>
					of(
						myCoursesActions.mcGetGradeBookFailed({
							gradeBookColumn: [],
							gradeBookRow: [],
							isFetchingGradeBook: false,
							gradeBookColumnLatest: [],
						}),
					),
				),
			);
		}),
	);

const mcEditSubmissionStatusEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_EDIT_SUBMISSION_STATUS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_grade_book_edit_submission_status.method,
				END_POINT.mc_grade_book_edit_submission_status.url(action.payload.courseId, action.payload.submissionId),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					// const { studentId, gradeWeightCriteriaId, studentProgressId, newStatus } = action.payload.data;
					// const { gradeBookRowLatest } = state$.value.AllCourses;
					// try {
					// let newGradeBookRowLatest = [];
					// if (gradeBookRowLatest.length) {
					// newGradeBookRowLatest = cloneDeep(gradeBookRowLatest);
					// const gradeBookRowLatestIndex = newGradeBookRowLatest.findIndex((item) => item.id === studentId);
					// const gradeBookRowOfStudent = newGradeBookRowLatest[gradeBookRowLatestIndex];
					// let activitiesCategoryDataIndex;

					// const activitiesCategoryDataOfStudent = gradeBookRowOfStudent.activitiesCategoryData;
					// const currentGradedActivityIndex = activitiesCategoryDataOfStudent.findIndex(
					//   (item) => item.gradeWeightCriteriaId === gradeWeightCriteriaId
					// );
					// const activityInGWIndex = activitiesCategoryDataOfStudent[currentGradedActivityIndex].data.findIndex(
					//   (item) => item.id === studentProgressId
					// );

					// if (
					//   ![
					//     gradeBookRowLatestIndex,
					//     activitiesCategoryDataIndex,
					//     currentGradedActivityIndex,
					//     activityInGWIndex,
					//   ].includes(-1)
					// ) {
					//   activitiesCategoryDataOfStudent[currentGradedActivityIndex].data[activityInGWIndex].status = newStatus;
					// }
					// }
					// } catch (error) {
					// eslint-disable-next-line
					// console.error('error', error);
					// }
					of(
						myCoursesActions.mcEditSubmissionStatusSuccess({
							mcDoActionSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcEditSubmissionStatusFailed({
							mcDoActionFailed: true,
							mcDoActionFailedMessage: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const mcInputOverallGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_INPUT_OVERALL_GRADE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_input_overall_grade.method,
				END_POINT.mc_input_overall_grade.url(
					action.payload.courseId,
					action.payload.shadowAssignmentId,
					action.payload.progressId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) => {
					const studentProgress = data.response.data.studentProgress;
					const currentCellUpdate = state$.value.AllCourses.currentCellUpdate;
					currentCellUpdate.value.overallGrade = studentProgress.overallGrade;

					const gradeBookRow = state$.value.AllCourses.gradeBookRow;
					const length = gradeBookRow.length;
					for (let i = 0; i < length; i++) {
						if (gradeBookRow[i].id === studentProgress.studentId) {
							gradeBookRow[i][`a-${action.payload.shadowAssignmentId}`].overallGrade = studentProgress.overallGrade;
						}
					}
					return of(
						myCoursesActions.mcInputOverallGradeSuccess({
							currentCellUpdate,
							gradeBookRow,
							mcDoActionSuccess: true,
							graderDetail: {
								...state$.value.AllCourses.graderDetail,
								status: studentProgress.status,
							},
						}),
					);
				}),
				catchError(() =>
					of(
						myCoursesActions.mcInputOverallGradeFailed({
							mcDoActionFailed: true,
						}),
					),
				),
			),
		),
	);

const mcGetGraderDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_GRADER_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_grader_detail.method,
				END_POINT.get_grader_detail.url(
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.studentId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(({ response }) =>
					of(
						myCoursesActions.mcGetGraderDetailSuccess({
							getGraderDetailSuccess: true,
							graderDetail: {
								activity: response.activity,
								studentProgress: response.studentProgress,
							},
							isFetchingGraderDetail: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetGraderDetailFailed({
							error: error?.response?.errors,
							isFetchingGraderDetail: false,
						}),
					),
				),
			),
		),
	);

const mcGetQuizGraderDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_QUIZ_GRADER_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_quiz_grader_detail.method,
				END_POINT.get_quiz_grader_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.studentId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetQuizGraderDetailSuccess({
							getGraderDetailSuccess: true,
							graderDetail: data?.response.data,
							isFetchingGraderDetail: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetQuizGraderDetailFailed({
							error: error?.response?.errors,
							isFetchingGraderDetail: false,
						}),
					),
				),
			),
		),
	);

const mcInputOverallGradeTestEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_INPUT_OVERALL_GRADE_TEST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_input_overall_grade.method,
				END_POINT.mc_input_overall_grade_test.url(
					action.payload.courseId,
					action.payload.shadowQuizId,
					action.payload.quizSubmissionId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) => {
					const studentProgress = data.response.data.studentProgress;
					const { overallGrade } = studentProgress;
					const currentCellUpdate = state$.value.AllCourses.currentCellUpdate;
					currentCellUpdate.value.overallGrade = studentProgress.overallGrade;

					const gradeBookRow = state$.value.AllCourses.gradeBookRow;
					const graderDetail = state$.value.AllCourses.graderDetail;

					const length = gradeBookRow.length;
					for (let i = 0; i < length; i++) {
						if (gradeBookRow[i].id === studentProgress.studentId) {
							gradeBookRow[i][`q-${action.payload.shadowQuizId}`].overallGrade = studentProgress.overallGrade;
							break;
						}
					}
					const returnPayload = {
						currentCellUpdate,
						gradeBookRow,
						mcDoActionSuccess: true,
					};
					if (graderDetail) {
						graderDetail.overallGrade = overallGrade;
					}
					returnPayload.graderDetail = graderDetail;
					return of(myCoursesActions.mcInputOverallGradeSuccess(returnPayload));
				}),
				catchError(() =>
					of(
						myCoursesActions.mcInputOverallGradeFailed({
							mcDoActionFailed: true,
						}),
					),
				),
			),
		),
	);

const mcCreateQuizAttemptEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_CREATE_QUIZ_ATTEMPT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.create_quiz_attempt.method,
				END_POINT.create_quiz_attempt.url(
					action.payload.courseId,
					action.payload.shadowQuizId,
					action.payload.studentId,
				),
			).pipe(
				mergeMap((data) => {
					const graderDetail = state$.value.AllCourses.graderDetail;
					const { quizSubmissionAttempts } = graderDetail;
					const newQuizAttempt = data.response.data;
					quizSubmissionAttempts.unshift(newQuizAttempt);
					return of(
						myCoursesActions.mcCreateQuizAttemptSuccess({
							creatingQuizAttempt: false,
							newQuizAttempt: data.response.data,
							createQuizAttemptSuccess: true,
							graderDetail: {
								...graderDetail,
								quizSubmissionAttempts: quizSubmissionAttempts,
							},
						}),
					);
				}),
				catchError(() =>
					of(
						myCoursesActions.mcCreateQuizAttemptFailed({
							creatingQuizAttempt: false,
						}),
					),
				),
			),
		),
	);
const mcInputGradeQuizAttemptEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_INPUT_GRADE_QUIZ_ATTEMPT),
		mergeMap((action) => {
			const { inputQuizAttemptGrade, retake, makeup } = action.payload;
			const data = {
				grade: inputQuizAttemptGrade,
				retake,
				makeup,
			};
			return makeAjaxRequest(
				END_POINT.input_grade_quiz_attempt.method,
				END_POINT.input_grade_quiz_attempt.url(
					action.payload.courseId,
					action.payload.shadowQuizId,
					action.payload.studentId,
					action.payload.attemptId,
				),
				data,
			).pipe(
				mergeMap((data) => {
					const { attempt, overallGrade } = data.response.data;

					const graderDetail = state$.value.AllCourses.graderDetail;
					const gradeBookRow = state$.value.AllCourses.gradeBookRow;
					const currentCellUpdate = state$.value.AllCourses.currentCellUpdate;
					const { quizSubmissionAttempts, shadowQuizId, studentId } = graderDetail;
					const index = gradeBookRow.findIndex((row) => row.id === studentId);
					gradeBookRow[index][`q-${shadowQuizId}`].overallGrade = overallGrade ?? '';
					currentCellUpdate.value.overallGrade = overallGrade || '';

					const indexAttempt = quizSubmissionAttempts.findIndex((item) => item.id === attempt.id);
					quizSubmissionAttempts[indexAttempt] = attempt;
					return of(
						myCoursesActions.mcInputGradeQuizAttemptSuccess({
							graderDetail: {
								...graderDetail,
								overallGrade: overallGrade ?? '',
								quizSubmissionAttempts: quizSubmissionAttempts,
							},
							mcDoActionSuccess: true,
							gradeBookRow,
							currentCellUpdate,
						}),
					);
				}),
				catchError(() => of(myCoursesActions.mcInputGradeQuizAttemptFailed({}))),
			);
		}),
	);
const mcRemoveQuizAttemptEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_REMOVE_QUIZ_ATTEMPT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.remove_quiz_attempt.method,
				END_POINT.remove_quiz_attempt.url(
					action.payload.courseId,
					action.payload.shadowQuizId,
					action.payload.studentId,
					action.payload.attemptId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) => {
					const { overallGrade } = data.response.data;

					const graderDetail = state$.value.AllCourses.graderDetail;
					const gradeBookRow = state$.value.AllCourses.gradeBookRow;
					const currentCellUpdate = state$.value.AllCourses.currentCellUpdate;
					const { quizSubmissionAttempts, shadowQuizId, studentId } = graderDetail;
					const index = gradeBookRow.findIndex((row) => row.id === studentId);
					gradeBookRow[index][`q-${shadowQuizId}`].overallGrade = overallGrade ?? '';
					currentCellUpdate.value.overallGrade = overallGrade || '';

					const filter = quizSubmissionAttempts.filter((attempt) => attempt.id !== action.payload.attemptId);

					return of(
						myCoursesActions.mcRemoveQuizAttemptSuccess({
							removeQuizAttemptSuccess: true,
							graderDetail: {
								...graderDetail,
								overallGrade: overallGrade ?? '',
								quizSubmissionAttempts: filter,
							},
							mcDoActionSuccess: true,
							currentCellUpdate,
							gradeBookRow,
							// removeAttemptId: action.payload.attemptId
						}),
					);
				}),
				catchError(() => of(myCoursesActions.mcRemoveQuizAttemptFailed({}))),
			),
		),
	);

const mcInputStudentParticipationEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_INPUT_STUDENT_PARTICIPATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_input_student_participation.method,
				END_POINT.mc_input_student_participation.url(action.payload.courseId, action.payload.termId),
				action.payload.data,
			).pipe(
				mergeMap(({ response }) => {
					const { overallGrade, originalGrade } = response.data.studentParticipation;
					const { courseId, termId } = action.payload;
					const { activityType, studentId, gradeWeightCriteriaId, studentParticipationId } = action.payload.data;

					return of(
						myCoursesActions.mcInputStudentParticipationSuccess(),
						myCoursesActions.mcCalculateOverallCourseGrade({
							courseId,
							termId: termId,
							gradeWeightCriteriaId: gradeWeightCriteriaId,
							activityType,
							overallGrade: overallGrade,
							originalGrade: originalGrade,
							data: {
								studentId,
								studentParticipationId,
							},
						}),
					);
				}),
				catchError(() =>
					of(
						myCoursesActions.mcInputStudentParticipationFailed({
							mcDoActionFailed: true,
						}),
					),
				),
			),
		),
	);

const multipleMcInputStudentParticipationEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.multiple_mc_input_student_participation.method,
				END_POINT.multiple_mc_input_student_participation.url(action.payload.courseId, action.payload.termId),
				action.payload,
			).pipe(
				mergeMap(({ response }) => {
					const { dataParticipation } = response;
					const { courseId, termId } = action.payload;
					const dataPayload = action.payload.data;
					const gradeCalculationType = state$.value.MyCourses.gradeCalculationType;

					const actions = [
						myCoursesActions.multipleMcInputStudentParticipationSuccess({
							multipleGradeSuccess: true,
							gradedList: dataParticipation,
						}),
					];
					if (gradeCalculationType !== GRADE_CALCULATION_TYPE.NO_OVERALL_GRADE) {
						actions.push(
							myCoursesActions.multipleMcCalculateOverallCourseGrade({
								courseId,
								termId: termId,
								data: dataParticipation,
								dataPayload,
							}),
						);
					}
					return from(actions);
				}),
				catchError(() =>
					of(
						myCoursesActions.multipleMcInputStudentParticipationFailed({
							mcDoActionFailed: true,
						}),
					),
				),
			),
		),
	);

const debounceMcInputStudentParticipationEpic = (action$) =>
	action$.pipe(
		ofType(actions.DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION), // Your action type for fetching batch data
		bufferTime(1000), // Buffer the actions for 1 second
		filter((actions) => actions.length > 0), // Make sure there are actions to process
		map((arrActions) => {
			const arrDataPass = arrActions.map((action) => action.payload);
			return {
				type: actions.MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION,
				payload: {
					courseId: arrDataPass[0]?.courseId,
					termId: arrDataPass[0]?.termId,
					data: arrDataPass,
				},
			};
		}),
	);

const mcCalculateOverallCourseGrade = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_CALCULATE_OVERALL_COURSE_GRADE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_calculate_overall_course_grade.method,
				END_POINT.mc_calculate_overall_course_grade.url(action.payload.courseId, action.payload.termId),
				action.payload.data,
			).pipe(
				mergeMap((data) => {
					const calculateData = data.response.data.calculateData;
					const { gradeWeightCriteriaId, progressId, overallGrade, originalGrade } = action.payload;
					const { studentId, studentParticipationId } = action.payload.data;
					const { gradeBookRowLatest } = state$.value.MyCourses;

					try {
						let newGradeBookRowLatest = [];
						if (gradeBookRowLatest.length) {
							newGradeBookRowLatest = cloneDeep(gradeBookRowLatest);
							const gradeBookRowLatestIndex = newGradeBookRowLatest.findIndex((item) => item.id === studentId);
							const gradeBookRowOfStudent = newGradeBookRowLatest[gradeBookRowLatestIndex];
							let activitiesCategoryDataIndex;

							const activitiesCategoryDataOfStudent = gradeBookRowOfStudent.activitiesCategoryData;
							const currentGradedActivityIndex = activitiesCategoryDataOfStudent.findIndex(
								(item) => item.gradeWeightCriteriaId === gradeWeightCriteriaId,
							);
							const activityInGWIndex = activitiesCategoryDataOfStudent[currentGradedActivityIndex].data.findIndex(
								(item) =>
									!isNaN(studentParticipationId)
										? item.studentParticipationId === studentParticipationId
										: item.id === progressId,
							);

							if (![gradeBookRowLatestIndex].includes(-1)) {
								if (![activitiesCategoryDataIndex, currentGradedActivityIndex, activityInGWIndex].includes(-1)) {
									activitiesCategoryDataOfStudent[currentGradedActivityIndex].data[activityInGWIndex].overallGrade =
										overallGrade;

									activitiesCategoryDataOfStudent[currentGradedActivityIndex].data[activityInGWIndex].originalGrade =
										originalGrade;
								}

								gradeBookRowOfStudent.overallCourseGrade.calculateOverallGrade =
									calculateData.calculateOverallGrade ?? null;

								gradeBookRowOfStudent.overallCourseGrade.letterValue = calculateData.letterValue ?? null;
							}
						}
						return of(
							myCoursesActions.mcCalculateOverallCourseGradeSuccess({
								mcCalculateOverallCourseGradeSuccess: true,
								gradeBookRowLatest: newGradeBookRowLatest,
							}),
						);
					} catch (error) {
						// eslint-disable-next-line
						console.error('error', error);
					}
				}),
				catchError(() => of(myCoursesActions.mcCalculateOverallCourseGradeFailed({}))),
			),
		),
	);

const multipleMcCalculateOverallCourseGrade = (action$) =>
	action$.pipe(
		ofType(actions.MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.multiple_mc_calculate_overall_course_grade.method,
				END_POINT.multiple_mc_calculate_overall_course_grade.url(action.payload.courseId, action.payload.termId),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.multipleMcCalculateOverallCourseGradeSuccess({
							mcCalculateOverallCourseGradeSuccess: true,
							studentPayload: (data.response.data || []).map((item) => ({
								studentId: item.studentId,
								letterValue: item.letterValue,
								calculateOverallGrade: item.calculateOverallGrade,
							})),
						}),
					),
				),
				catchError(() => of(myCoursesActions.multipleMcCalculateOverallCourseGradeFailed({}))),
			),
		),
	);

const getReleaseGradeOfGradeBookEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_RELEASE_GRADE_OF_GRADE_BOOK),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_release_grade_of_grade_book.method,
				END_POINT.mc_get_release_grade_of_grade_book.url(
					action.payload.courseId,
					action.payload.termId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetReleaseGradeOfGradeBookSuccess({
							releaseGradeOfGradeBook: data.response.data ?? [],
						}),
					),
				),
				catchError(() =>
					of(
						myCoursesActions.mcGetReleaseGradeOfGradeBookFailed({
							releaseGradeOfGradeBook: [],
						}),
					),
				),
			),
		),
	);

const gradeSubmissionEpic = (action$) =>
	action$.pipe(
		ofType(actions.GRADE_SUBMISSION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_grade_submission.method,
				END_POINT.mc_grade_submission.url(action.payload.progressId),
				action.payload.data,
			).pipe(
				mergeMap(({ response }) => {
					const { code: _, ...payload } = response;
					const { courseId, currentTermId } = action.payload;
					const recentlyStudentGraded = {
						...payload,
						shadowId: action.payload?.shadowId,
						activityType: action.payload.data?.type,
					};
					return of(
						myCoursesActions.gradeSubmissionSuccess({
							isSubmittingGrade: false,
							recentlyStudentGraded,
							inputGradeSuccess: true,
						}),
						myCoursesActions.multipleMcCalculateOverallCourseGrade({
							courseId,
							termId: currentTermId,
							data: [payload],
							mcCalculateOverallCourseGradeSuccess: null,
						}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.gradeSubmissionFailed({
							error: error?.response?.errors,
							isSubmittingGrade: false,
						}),
					),
				),
			),
		),
	);

const multipleGradeSubmissionEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MULTIPLE_GRADE_SUBMISSION),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.multiple_mc_grade_submission.method,
				END_POINT.multiple_mc_grade_submission.url(action.payload.courseId),
				action.payload,
			).pipe(
				mergeMap(({ response }) => {
					const { dataUpdate } = response;
					const { courseId, termId } = action.payload;
					const gradeCalculationType = state$.value.MyCourses.gradeCalculationType;

					const actions = [
						myCoursesActions.multipleGradeSubmissionSuccess({
							multipleGradeSuccess: true,
							gradedList: dataUpdate.map((item) => {
								const updated = action.payload.data.find(
									(payload) => item.studentProgressId === payload.studentProgressId,
								);
								return {
									...item,
									...(updated.activityType && { activityType: updated.activityType }),
								};
							}),
						}),
					];
					if (gradeCalculationType !== GRADE_CALCULATION_TYPE.NO_OVERALL_GRADE) {
						const studentList = dataUpdate.map((item) => ({ studentId: item?.studentId }));
						actions.push(
							myCoursesActions.multipleMcCalculateOverallCourseGrade({
								courseId,
								termId,
								data: studentList,
								mcCalculateOverallCourseGradeSuccess: null,
							}),
						);
					}
					return from(actions);
				}),
				catchError((error) =>
					of(
						myCoursesActions.multipleGradeSubmissionFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getSubmissionsToReleaseEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SUBMISSIONS_TO_RELEASE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_release_submissions_release.method,
				END_POINT.get_release_submissions_release.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.urlParams,
				),
				action.payload.data,
			).pipe(
				mergeMap(({ response }) =>
					of(
						myCoursesActions.getSubmissionsToReleaseSuccess({
							arrActivities: response.arrActivities,
							arrParticipation: response.arrParticipation,
							arrNoCategories: response.noCategoryActivities,
							isFetchingSubmissionToRelease: false,
							isFetchingSubmissionToReleaseSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.getSubmissionsToReleaseFailed({
							error: error?.response?.errors,
							isFetchingSubmissionToReleaseSuccess: false,
							isFetchingSubmissionToRelease: false,
						}),
					),
				),
			),
		),
	);
const debounceGradeSubmissionEpic = (action$) =>
	action$.pipe(
		ofType(actions.DEBOUNCE_GRADE_SUBMISSION), // Your action type for fetching batch data
		bufferTime(1000), // Buffer the actions for 1 second
		filter((actions) => actions.length > 0), // Make sure there are actions to process
		map((arrActions) => {
			const arrDataPass = arrActions.map((action) => action.payload);
			return {
				type: actions.MULTIPLE_GRADE_SUBMISSION,
				payload: {
					courseId: arrDataPass[0]?.courseId,
					termId: arrDataPass[0]?.termId,
					data: arrDataPass.map((item) => item.data),
				},
			};
		}),
	);

export default [
	getMcColumnGradeBook,
	getGradeBookEpic,
	mcEditSubmissionStatusEpic,
	mcInputOverallGradeEpic,
	mcGetGraderDetailEpic,
	mcGetQuizGraderDetailEpic,
	mcInputOverallGradeTestEpic,
	mcCreateQuizAttemptEpic,
	mcRemoveQuizAttemptEpic,
	mcInputGradeQuizAttemptEpic,
	mcInputStudentParticipationEpic,
	debounceMcInputStudentParticipationEpic,
	multipleMcInputStudentParticipationEpic,
	mcCalculateOverallCourseGrade,
	multipleMcCalculateOverallCourseGrade,
	getReleaseGradeOfGradeBookEpic,
	gradeSubmissionEpic,
	multipleGradeSubmissionEpic,
	debounceGradeSubmissionEpic,
	getSubmissionsToReleaseEpic,
];
