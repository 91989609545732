import get from 'lodash/get';

import { isNil } from 'lodash';
import { ROUTE_MY_COURSES } from 'modules/MyCourses/constantsRoute';

const handleShowActivityModal =
	({ setShowActivityModal }) =>
	() => {
		console.log('click');
		setShowActivityModal(true);
	};

const handleCloseActivityModal =
	({ setShowActivityModal, history, location, queryParams }) =>
	() => {
		if (queryParams.has('mode')) {
			queryParams.delete('mode');

			history.replace({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		}
		console.log('close');

		setShowActivityModal(false);
	};

const handleShowScheduleModal =
	({ setShowScheduleModal }) =>
	() => {
		setShowScheduleModal(true);
	};

const handleCloseScheduleModal =
	({ setShowScheduleModal }) =>
	() => {
		setShowScheduleModal(false);
	};

const handleShowGraderModal =
	({ setShowGraderModal, setStudentInfo }) =>
	(studentInfo) => {
		setShowGraderModal(true);
		setStudentInfo(studentInfo);
	};

const handleCloseGraderModal =
	({ setShowGraderModal }) =>
	() => {
		setShowGraderModal(false);
	};

const handleSearch =
	({ searchValueRef, setSearchValueInput, searchValueInput }) =>
	(event) => {
		searchValueRef.current = searchValueInput;
		const value = get(event.target, 'value', null);

		setSearchValueInput(value || null);
	};

const handleChangeQuery =
	({ history, location, queryParams }) =>
	(query, value) => {
		if (Array.isArray(query) && Array.isArray(value)) {
			query.forEach((q, index) => {
				isNil(value[index]) ? queryParams.delete(q) : queryParams.set(q, value[index]);
			});
		} else {
			isNil(value) ? queryParams.delete(query) : queryParams.set(query, value);
		}

		history.replace({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

const handleSelectSection =
	({ history, location, queryParams, setSelectedSection }) =>
	(event) => {
		const value = get(event.target, 'value', null);

		if (!value) return;

		queryParams.set('defaultSectionId', value);

		history.replace({
			pathname: location.pathname,
			search: queryParams.toString(),
		});

		setSelectedSection(value);
	};

const handleClickSubHeader =
	({ history, courseId }) =>
	() => {
		history.push(`${ROUTE_MY_COURSES.MY_COURSES_DETAIL(courseId)}`);
	};

const handleSelectTab =
	({ setSelectedTab }) =>
	(index) => {
		setSelectedTab(index);
	};

const handleSelectChip =
	({ setSelectedChip }) =>
	(index) => {
		setSelectedChip(index);
	};

const handleDispatchSetState =
	({ dispatch, action }) =>
	(value) => {
		dispatch(action(value));
	};

const handleContentScrollY =
	({ setContentScrollTop }) =>
	(containerElement) => {
		if (!containerElement) return;

		setContentScrollTop(containerElement.scrollTop);
	};

export {
	handleShowActivityModal,
	handleCloseActivityModal,
	handleShowScheduleModal,
	handleCloseScheduleModal,
	handleShowGraderModal,
	handleCloseGraderModal,
	handleSearch,
	handleChangeQuery,
	handleSelectSection,
	handleClickSubHeader,
	handleSelectTab,
	handleSelectChip,
	handleDispatchSetState,
	handleContentScrollY,
};
