import { fontFamily, fontSize, fontWeight, letterSpacing, lineHeight } from './font';

export default {
	displaySmall: {
		fontSize: fontSize['text-7xl'],
		lineHeight: lineHeight['leading-13'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-normal'],
		fontFamily,
	},
	headlineLarge: {
		fontSize: fontSize['text-6xl'],
		lineHeight: lineHeight['leading-12'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-normal'],
		fontFamily,
	},
	headlineMedium: {
		fontSize: fontSize['text-5xl'],
		lineHeight: lineHeight['leading-11'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-normal'],
		fontFamily,
	},
	headlineSmall: {
		fontSize: fontSize['text-4xl'],
		lineHeight: lineHeight['leading-10'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-normal'],
		fontFamily,
	},
	titleLarge: {
		fontSize: fontSize['text-3xl'],
		lineHeight: lineHeight['leading-9'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-normal'],
		fontFamily,
	},
	titleMedium: {
		fontSize: fontSize['text-xl'],
		lineHeight: lineHeight['leading-7'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-normal'],
		fontFamily,
	},
	titleSmall: {
		fontSize: fontSize['text-base'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodyLargeRegular: {
		fontSize: fontSize['text-lg'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.normal,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodyLargeMedium: {
		fontSize: fontSize['text-lg'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.medium,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodyLargeBold: {
		fontSize: fontSize['text-lg'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodyMediumRegular: {
		fontSize: fontSize['text-base'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.normal,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodyMediumMedium: {
		fontSize: fontSize['text-base'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.medium,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodyMediumBold: {
		fontSize: fontSize['text-base'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	bodySmallRegular: {
		fontSize: fontSize['text-sm'],
		lineHeight: lineHeight['leading-5'],
		fontWeight: fontWeight.normal,
		letterSpacing: letterSpacing['tracking-3xwide'],
		fontFamily,
	},
	bodySmallMedium: {
		fontSize: fontSize['text-sm'],
		lineHeight: lineHeight['leading-5'],
		fontWeight: fontWeight.medium,
		letterSpacing: letterSpacing['tracking-3xwide'],
		fontFamily,
	},
	bodySmallBold: {
		fontSize: fontSize['text-sm'],
		lineHeight: lineHeight['leading-5'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-3xwide'],
		fontFamily,
	},
	bodySmallTemporary: {
		fontSize: fontSize['text-sm'],
		lineHeight: lineHeight['leading-5'],
		fontWeight: fontWeight.normal,
		letterSpacing: letterSpacing['tracking-3xwide'],
		fontFamily,
	},
	labelLarge: {
		fontSize: fontSize['text-base'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
	},
	labelMedium: {
		fontSize: fontSize['text-sm'],
		lineHeight: lineHeight['leading-5'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-xwide'],
		fontFamily,
	},
	labelSmall: {
		fontSize: fontSize['text-xs'],
		lineHeight: lineHeight['leading-4'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-4xwide'],
		fontFamily,
	},
	labelSmallTemporary: {
		fontSize: fontSize['text-xs'],
		lineHeight: lineHeight['leading-4'],
		fontWeight: fontWeight.bold,
		letterSpacing: letterSpacing['tracking-4xwide'],
		fontFamily,
	},
	caption: {
		fontSize: fontSize['text-xs'],
		lineHeight: lineHeight['leading-4'],
		fontWeight: fontWeight.normal,
		letterSpacing: letterSpacing['tracking-5xwide'],
		fontFamily,
	},
	button: {
		fontSize: fontSize['text-base'],
		lineHeight: lineHeight['leading-6'],
		fontWeight: fontWeight.medium,
		letterSpacing: letterSpacing['tracking-2xwide'],
		fontFamily,
		textTransform: 'unset',
	},
};
