const GUARDIAN = 'Guardian';

const STUDENT = 'Student';

const TEACHER = 'Teacher';

const OBSERVER = 'Observer';

const COURSE_MANAGER = 'Course Manager';

const USER_MANAGER = 'User Manager';

const SCHOOL_MANAGER = 'School Manager';

const OWNER = 'Owner';

const DOMAIN_OWNER = 'Domain Owner';

const STUDENT_ASSIST_MANAGER = 'Student Assist Manager';

const isStudent = (currentUser = {}) => currentUser?.roles?.find(({ roleName }) => roleName === STUDENT);

const isTeacher = (currentUser = {}) =>
	currentUser?.roles?.find(({ roleName }) => roleName === TEACHER || roleName === COURSE_ROLE.TEACHING_ASSISTANT);

const isGuardian = (currentUser = {}) => currentUser?.roles?.find(({ roleName }) => roleName === GUARDIAN);

const COURSE_ROLE = {
	TEACHER: 'Teacher',
	TEACHING_ASSISTANT: 'Teaching Assistant',
	STUDENT: 'Student',
	GUARDIAN: 'Guardian',
	OBSERVER: 'Observer',
};

const ROLES_UPDATE_COURSE = [TEACHER, COURSE_ROLE.TEACHING_ASSISTANT];

export {
	GUARDIAN,
	STUDENT,
	TEACHER,
	OBSERVER,
	COURSE_MANAGER,
	USER_MANAGER,
	SCHOOL_MANAGER,
	OWNER,
	DOMAIN_OWNER,
	STUDENT_ASSIST_MANAGER,
	COURSE_ROLE,
	ROLES_UPDATE_COURSE,
	isStudent,
	isTeacher,
	isGuardian,
};
