import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { safeInstanceOf } from 'roosterjs-editor-dom';
import { makeStyles } from 'tss-react/mui';

const MaxRows = 10;
const MaxCols = 10;
const useStyles = makeStyles()((theme) => ({
	tableButton: {
		width: '15px',
		height: '15px',
		margin: '1px 1px 0 0',
		border: `solid 1px ${theme.newColors.gray[400]}`,
		display: 'inline-block',
		cursor: 'pointer',
		backgroundColor: 'transparent',
	},
	hovered: {
		border: `solid 1px ${theme.newColors.red[400]}`,
	},
	tablePane: {
		width: '160px',
		minWidth: 'auto',
		padding: '4px',
	},
	tablePaneInner: {
		lineHeight: '12px',
	},
	title: {
		marginBottom: '5px',
	},
	myFocusZone: {
		display: 'flex',
		flexWrap: 'wrap',
	},
}));

function formatText(row, col) {
	return `${col} x ${row}`;
}

function InsertTablePane(props) {
	const { classes } = useStyles();

	const { t } = useTranslation('common');

	const { item, onClick } = props;
	const [col, setCol] = useState(1);
	const [row, setRow] = useState(1);

	const updateSize = useCallback((t) => {
		if (safeInstanceOf(t, 'HTMLElement')) {
			const col = parseInt(t.dataset.col);
			const row = parseInt(t.dataset.row);

			if (col > 0 && col <= MaxCols && row > 0 && row <= MaxRows) {
				setCol(col);
				setRow(row);
			}
		}
	}, []);

	const onMouseEnter = useCallback((e) => {
		updateSize(e.target);
	}, []);

	const onClickButton = useCallback(
		(e) => {
			onClick(e, {
				...item,
				key: {
					cols: col,
					rows: row,
				},
			});
		},
		[row, col, item],
	);

	const ariaLabels = useMemo(() => {
		const result = [];
		for (let i = 1; i <= MaxCols; i++) {
			let col = [];
			for (let j = 1; j <= MaxRows; j++) {
				col[j] = formatText(i, j);
			}
			result[i] = col;
		}
		return result;
	}, [item.text]);

	const items = useMemo(() => {
		const items = [];

		for (let i = 1; i <= MaxRows; i++) {
			for (let j = 1; j <= MaxCols; j++) {
				const key = `cell_${i}_${j}`;
				const isSelected = j <= col && i <= row;
				items.push(
					<button
						className={`${classes.tableButton} ${isSelected ? classes.hovered : ''}`}
						onClick={onClickButton}
						key={key}
						id={key}
						data-col={j}
						data-row={i}
						data-is-focusable={true}
						onMouseEnter={onMouseEnter}
						aria-label={ariaLabels[i][j]}
					/>,
				);
			}
		}
		return items;
	}, [col, row]);

	const text = formatText(row, col);

	return (
		<Box className={classes.tablePaneInner}>
			<Box className={classes.title}>
				{text} {t('table')}
			</Box>
			<Box className={classes.myFocusZone} defaultTabbableElement='cell_1_1' onActiveElementChanged={updateSize}>
				{items}
			</Box>
		</Box>
	);
}

export default InsertTablePane;
