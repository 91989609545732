export const NOTIFICATION_EXTRACT_CHAR_FIRST = '=';
export const NOTIFICATION_EXTRACT_CHAR_SECOND = '%';

export const NOTIFICATION_SPLIT_CHAR = '|';

export const NOTIFICATION_CONDITION_RENDER = {
	BOLD: 'b',
	DATE_FORMAT: 'd',
};

export const NOTI_TYPES = {
	HOMEWORK_TODAY: 'stu_during_homework_today',
	LAST_CHANCE: 'stu_during_last_chance',
	LAST_CHANCE_MORE_THAN: 'stu_during_last_change_on_more_than',
	HOMEWORK_FOR_TODAY: 'stu_after_homework_for_today',
	DONT_FORGET_HOMEWORK: 'stu_after_dont_forget_about_tonight_homework',
	STILL_NEED_FINISH_HOMEWORK: 'stu_after_still_need_to_finish_tonights_homework',
	GUARDIANS_HOMEWORK_FOR_TODAY: 'guardians_after_homework_for_today',
	GUARDIANS_DONT_FORGET_HOMEWORK: 'guardians_after_dont_forget_about_tonight_homework',
	GUARDIANS_STILL_NEED_FINISH_HOMEWORK: 'guardians_after_still_need_to_finish_tonights_homework',
	NO_SECTION_AFTER_WAKEUP: 'stu_no_section_after_wakeup',
	NO_SECTION_AFTER_FILLUP: 'stu_no_section_after_fillup',
	NO_SECTION_NO_START: 'stu_no_section_no_start',
	NO_SECTION_REMAIN_TASK: 'stu_no_section_remain_task',
	GUARDIANS_NO_SECTION_AFTER_FILLUP: 'guardians_no_section_after_fillup',
	GUARDIANS_NO_SECTION_NO_START: 'guardians_no_section_no_start',
	GUARDIANS_NO_SECTION_REMAIN_TASK: 'guardians_no_section_remain_task',
	CHECK_FILL_UP: 'check_fill_up',
	DELETE_OLD_NOTIFICATION: 'delete_old_notification',
	DELETE_ACTIVITY: 'delete_activity',
	UPCOMING_SESSIONS: 'upcoming_sessions',
	RESCHEDULE_ACTIVITY: 'stu_activity_reschedule',
	REPUBLISHED_SCHOOL_YEAR: 'stu_republished_school_year',
};
