import { pipe } from 'lodash/fp';

import { isEmpty } from 'lodash';

const extractModuleAliasFromString = (regex) => (str) => str.match(regex)?.[0] || '';
const debug = (value) => {
	console.log('value', value);
	return value;
};
const parseStrToLowercase = (str) => str.toLowerCase();

const splitCommna = (str) => str.split(',').filter((item) => item !== ',');

const intersection = (permissions) => (usrPermissions) => {
	const superUser = usrPermissions.indexOf('*') > -1;

	return superUser || permissions.some((p) => usrPermissions.includes(`${p}`.toLowerCase()));
};

export const modifyStrPermission = (moduleAlias) => (str) => {
	const regex = new RegExp(`(?<=(${moduleAlias}\/))(.*?)(?=\:|$)`, 'gim');

	return pipe(
		extractModuleAliasFromString(regex), // return string

		parseStrToLowercase, // return string

		splitCommna,
	)(str);
};

export const checkAuthorized = ({ moduleAlias, permissions, strPermissions }) => {
	if (
		isEmpty(strPermissions) ||
		typeof strPermissions !== 'string' ||
		!Array.isArray(permissions) ||
		typeof moduleAlias !== 'string'
	) {
		return false;
	}

	return pipe(modifyStrPermission(moduleAlias), intersection(permissions))(strPermissions);
};
