import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	loadingControlBar: false,
	coursesWithSections: [],
	fetchCourseSectionsSuccess: null,
	loadingParticipationDistribution: false,
	loadingGradeDistributionByTask: false,
	loadingAtRiskStudents: false,
	loadingGradeDistributionOverall: false,
	loadingTaskProgressActive: false,
	loadingTaskProgressClosed: false,
	loadingTaskProgressDetail: false,
	fetchTaskProgressDetailSuccess: null,
	participationDistribution: {},
	atRiskStudents: [],
	chartData: [],
	gradeDistributions: [],
	avgDistribution: {},
	distributionByTasks: {},
	overallDistribution: {},
	taskProgress: {
		active: [],
		closed: [],
		amountActive: null,
		amountClosed: null,
		missedRate: null,
	},
	maxSample: 0,
	taskProgressDetail: {},
	lastUpdatedTime: ''
};

export default createReducers(initialState, actions);
