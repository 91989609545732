export const ROUTE_MY_COURSES = {
	DEFAULT: '/my-courses',
	MY_COURSES_DETAIL: (courseId) => `/my-courses/${courseId}`,
	MY_COURSES_DETAIL_UNIT: (courseId, unitId) => `/my-courses/${courseId}?unitId=${unitId}`,
	MY_COURSES_DETAIL_UNIT_ACTIVITY: (courseId, activityId, activityType, unitId) =>
		`/my-courses/${courseId}?unitId=${unitId}&activityId=${activityId}&activityType=${activityType}`,
	MY_COURSES_ACTIVITY_DETAIL_PAGE_QUERY: (courseId, unitId, activityId, activityType) =>
		`/my-courses/${courseId}/unit/${unitId}/activity/${activityId}/activity-type/${activityType}/detail-page`,
	MY_COURSES_ACTIVITY_DETAIL_PAGE: (courseId, unitId, activityId, activityType) =>
		`/my-courses/${courseId}/unit/${unitId}/activity/${activityId}/activity-type/${activityType}/detail-page`,
	MY_TASKS_GUARDIAN: (studentId) => `/my-tasks/guardian/${studentId}`,
	MY_COURSES_GUARDIAN: (studentId) => `/my-courses/guardian/${studentId}`,
	MY_COURSES_GUARDIAN_NO_STUDENT: () => '/my-courses/guardian',
	MY_COURSES_DETAIL_GUARDIAN: (studentId, courseId) => `/my-courses/guardian/${studentId}/course/${courseId}`,
	MY_COURSES_DETAIL_GUARDIAN_NO_STUDENT: (courseId) => `/my-courses/guardian/course/${courseId}`,
	MY_COURSES_DETAIL_GUARDIAN_UNIT: (studentId, courseId, unitId) =>
		`/my-courses/guardian/${studentId}/course/${courseId}?unitId=${unitId}`,
	MY_COURSES_DETAIL_GUARDIAN_UNIT_ACTIVITY: (studentId, courseId, activityId, activityType, unitId) =>
		`/my-courses/guardian/${studentId}/course/${courseId}?unitId=${unitId}&activityId=${activityId}&activityType=${activityType}`,
};
