import React from 'react';

import Loading from 'components/TblLoading';
import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/icn_allCourses.svg';
import GuardCourse from 'modules/MyCourses/Guards/GuardCourse';

import { ROUTE_ALL_COURSES } from './constantsRoute';
import reducer from './reducers';

const AllCourses = loadable(() => import(/* webpackChunkName: "LazyAllCourses" */ './containers'), {
	fallback: <Loading />,
});
const AllCoursesDetail = loadable(
	() => import(/* webpackChunkName: "LazyAllCoursesDetail" */ './containers/AllCoursesDetail'),
	{ fallback: <Loading /> },
);

// const SchoolYearDetail = Loadable({
//   loader: () => import('./containers/SchoolYearDetail'),
//   loading: Loading
// });

export default [
	{
		path: ROUTE_ALL_COURSES.DEFAULT,
		component: withReducer('AllCourses', reducer)(AllCourses),
		permissions: [PERMISSIONS.COURSE.CREATE, PERMISSIONS.COURSE.UPDATE],
		moduleAlias: MODULE_ALIAS.COURSE,
		private: true,
		exact: true,
		menu: {
			title: 'Course Administration',
			group: 'Course Administration',
			icon: IcnMenu,
			order: 1,
			groupOrder: 1,
		},
	},
	{
		path: ROUTE_ALL_COURSES.ALL_COURSES_DETAIL(':courseId'),
		component: withReducer(
			'AllCourses',
			reducer,
		)(
			GuardCourse({
				permissions: [PERMISSIONS.COURSE.READ],
				Component: AllCoursesDetail,
			}),
		),
		private: true,
		exact: true,
	},
];
