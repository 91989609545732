import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { USER_MANAGER } from 'utils/roles';

import { MODULE_ALIAS, PERMISSION_BASIC_KEYS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/sidebarIcon/students_n_guardians.svg';

import { ROUTE_GUARDIAN_SETTINGS } from './constantsRoute';
import reducer from './reducers';

const Students = loadable(() => import(/* webpackChunkName: "LazyStudents" */ './containers/Students'));

export default [
	{
		path: ROUTE_GUARDIAN_SETTINGS.DEFAULT,
		component: withReducer('GuardianStudent', reducer)(Students),
		private: true,
		exact: true,
		roles: [USER_MANAGER],
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.USER_MANAGEMENT,
		menu: {
			title: 'Students & Guardians',
			group: 'School Administration',
			icon: IcnMenu,
			order: 2,
			groupOrder: 2,
		},
	},
];
