export const USER_STATUS = {
	PENDING: 0,
	ACTIVE: 1,
	RESET_PWD: -1,
	SUSPEND: -2,
	CHANGE_EMAIL: -3,
	EXPIRED: -4,
};

export const GUIDE_TEACHER_UPDATE_SCHEDULE = {
	VIEW_ALL: 0,
	ACCESS_EACH_COURSE: 1,
};

export const USER_BEHAVIOR = {
	HAVE_SET_UP_PROFILE: 'have_set_up_profile',
	HAVE_SET_UP_CONNECT_GOOGLE: 'have_set_up_connect_google',
	HAVE_ACCESSED_SET_UP_REQUIRED: 'have_accessed_set_up_required',
	HAVE_ACCESSED_COURSE_LIST: 'have_accessed_course_list',
	HAVE_FINISHED_SET_UP_COURSE: 'have_finished_set_up_course',
	HAVE_ACCESSED_COURSE: 'have_access_course',
	HAVE_CREATED_ASSIGNMENT: 'have_created_assignment',
	HAVE_CREATED_LESSON: 'have_created_lesson',
	HAVE_CREATED_POP_QUIZ: 'have_created_pop_quiz',
	HAVE_CREATED_ANNOUNCE_QUIZ: 'have_created_announce_quiz',
	HAVE_PLANED: 'have_planed',
	HAVE_VERIFIED_PHONE: 'have_verified_phone',
	HAVE_IGNORE_MODAL_OVERRIDE_GRADE_BOOK: 'have_ignore_modal_override_grade_book',
	HAVE_RESCHEDULE_COURSES: 'have_reschedule_courses',
	HAVE_MOVE_TO_COURSE_UPDATE_SCHEDULE: 'have_move_to_course_schedule',
	HAVE_SET_UP_GOT_IT_COURSE_UPDATE_SCHEDULE: 'have_set_up_got_it_update_schedule',
};

export const TYPE_UPDATE_PROFILE = {
	TEACHER_ON_BOARDING: 1,
};
