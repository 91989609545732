import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
	root: {
		position: 'relative',
		marginBottom: theme.spacing(1),
		'& ::placeholder': {
			color: theme.globalColors.placeholderColor,
			fontSize: theme.fontSize['text-base'],
			lineHeight: '24px',
			fontWeight: theme.fontWeight.normal,
			letterSpacing: '0.15px',
			opacity: 1,
		},

		'& .MuiTextField-root': {
			'& .MuiInputLabel-outlined': {
				display: 'none',
			},
		},
		'&.multiline': {
			maxHeight: 'inherit',
			'& .MuiOutlinedInput-multiline': {
				padding: 0,
			},
		},
		'& .MuiOutlinedInput-root': {
			padding: theme.spacing(1),
			borderRadius: theme.spacing(1),
			transition: theme.transitionDefault,

			'& .MuiIconButton-root': {
				padding: 0,
				marginRight: 0,
				color: theme.mainColors.primary1[0],
				'&:hover, &:focus': {
					background: 'none',
				},
			},
			'&.MuiOutlinedInput-adornedEnd  ': {
				paddingRight: theme.spacing(1),
			},
			'&.MuiOutlinedInput-adornedStart  ': {
				paddingLeft: theme.spacing(1),
			},
			'&.Mui-focused': {
				backgroundColor: 'white !important',
				color: theme.mainColors.primary1[0],
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: '1px',
				borderColor: theme.newColors.primary[500],
			},

			'&:hover': {
				'.MuiOutlinedInput-notchedOutline': {
					borderColor: theme.newColors.primary[500],
				},
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.newColors.gray[200],
			transition: theme.transitionDefault,

			'& legend': {
				maxWidth: 0,
			},
		},
		'& .MuiOutlinedInput-input': {
			color: theme.newColors.gray[800],
			fontSize: theme.fontSize['text-base'],
			lineHeight: '24px',
			height: '24px',
			padding: 0,
			fontWeight: theme.fontWeight.normal,
			letterSpacing: '0.15px',
			'&:-webkit-autofill, :-webkit-autofill:focus, :-webkit-autofill:hover, :-webkit-autofill:active': {
				transition: 'background-color 5000s ease-in-out 0s',
			},
		},

		'& .MuiFormHelperText-root': {
			margin: 0,
			display: 'none',
		},
		'& .MuiInputAdornment-root': {
			fontSize: theme.fontSize['text-base'],
			lineHeight: '24px',
			// minWidth: theme.spacing(3),
			fontWeight: theme.fontWeight.normal,
			letterSpacing: '0.15px',
			color: theme.newColors.gray[400],
			'& .MuiSvgIcon-root': {
				fontSize: theme.fontSizeIcon.normal,
			},
		},
		'& .Mui-disabled': {
			backgroundColor: `${theme.newColors.gray[100]} !important`,
			color: theme.newColors.gray[600],
			WebkitBoxShadow: 'none !important',
			cursor: 'not-allowed',
			'& .MuiIconButton-root.Mui-disabled': {
				display: 'none',
			},
			'& input': {
				width: '100%',
				whiteSpace: 'nowrap',
				overflow: ' hidden !important',
				textOverflow: 'ellipsis',
				backgroundColor: 'inherit !important',
				WebkitTextFillColor: 'unset',
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.newColors.gray[100],
		},
		'& .MuiOutlinedInput-root:focus-within': {
			boxShadow: `${theme.newColors.primary[50]} 0 0 0 3px`,
			borderColor: theme.newColors.primary[500],
			transition: theme.transitionDefault,

			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.newColors.primary[500],
				transition: theme.transitionDefault,
			},
		},
		'& .react-tel-input': {
			'& .form-control': {
				border: 'none',
				padding: theme.spacing(1),
				paddingLeft: theme.spacing(6.5),
				borderRadius: theme.spacing(1),
				minHeight: '40px',
				color: theme.newColors.gray[800],
				fontWeight: theme.fontWeight.normal,
				fontSize: theme.fontSize['text-base'],
				background: theme.openColors.white,
				transition: theme.transitionDefault,
				'&:-webkit-autofill, :-webkit-autofill:focus, :-webkit-autofill:hover, :-webkit-autofill:active': {
					transition: 'background-color 5000s ease-in-out 0s',
				},
				// '&:hover': {
				//   borderColor: theme.newColors.primary[500],
				// },
				// '&:focus': {
				//   borderColor: theme.newColors.primary[500],
				//   backgroundColor: 'white',
				//   boxShadow: `${theme.newColors.primary[50]} 0 0 0 3px`,
				// },
			},
			'& .flag-dropdown': {
				background: 'none',
				border: 'none',
				'&:hover': {
					background: 'none',
					borderColor: theme.newColors.primary[500],
					borderRadius: theme.spacing(1),
				},
				'& .selected-flag': {
					background: 'none',
					'&:hover': {
						background: 'none',
					},
					'&:focus': {
						background: 'none',
					},
				},
				'&::after': {
					content: '""',
					position: 'absolute',
					borderLeft: `1px solid ${theme.newColors.gray[200]}`,
					borderRadius: '2px',
					right: '-8px',
					height: '24px',
					top: '8px',
				},
				'& .arrow': {
					border: `solid ${theme.newColors.gray[400]}`,
					borderWidth: '0 1.5px 1.5px 0',
					display: 'inline-block',
					padding: '2px',
					transform: 'rotate(45deg)',
					top: '-10px',
					marginLeft: '2px',
				},
			},
			'& .selected-flag': {
				background: 'none',
				'&:hover': {
					background: 'none',
				},
				'&:focus': {
					background: 'none',
				},
			},
			'& .country-list': {
				margin: 0,
				color: theme.newColors.gray[800],
				borderRadius: theme.borderRadius.default,
				'& li': {
					whiteSpace: 'normal',
					paddingRight: theme.spacing(4),
					paddingTop: '10px',
					paddingBottom: '10px',
					fontSize: theme.fontSize['text-sm'],

					'&:hover': {
						backgroundColor: theme.newColors.gray[100],
					},
				},
				'& .active': {
					color: theme.palette.secondary.main,
					backgroundColor: theme.customColors.primary1.light[3],
					'&:hover': {
						backgroundColor: theme.customColors.primary1.light[3],
					},
				},
			},
		},
		'& .disabled': {
			'& .form-control': {
				backgroundColor: `${theme.newColors.gray[100]} !important`,
			},
		},
	},

	phoneInput: {
		'& .MuiOutlinedInput-root': {
			padding: theme.spacing(0, 1, 0, 0),
		},
	},

	viewOnly: {
		background: theme.newColors.gray[100],
		borderRadius: 8,
		'& .MuiOutlinedInput-input': {
			color: `${theme.newColors.gray[500]} !important`,
		},
		'& .MuiIconButton-label': {
			color: `${theme.newColors.gray[500]} !important`,
		},
	},
	noneBorder: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root:focus-within': {
			boxShadow: 'none',
		},
	},

	hasError: {
		'&.MuiSvgIcon-root': {
			fontSize: theme.fontSizeIcon.small,
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.error.main,
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.error.main,
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: `${theme.newColors.red[600]} !important`,
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: `${theme.newColors.red[600]} !important`,
			},
			'&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: `${theme.newColors.red[600]} !important`,
				boxShadow: `${theme.newColors.red[50]} 0 0 0 3px`,
			},
			'&.Mui-focused:focus .MuiOutlinedInput-notchedOutline': {
				borderColor: `${theme.newColors.red[600]} !important`,
				boxShadow: `${theme.newColors.red[50]} 0 0 0 3px`,
			},
			'&:focus-within': {
				boxShadow: `${theme.newColors.red[50]} 0 0 0 3px`,
			},
		},
		'& .form-control': {
			'&:focus': {
				borderColor: `${theme.newColors.red[600]} !important`,
				boxShadow: `${theme.newColors.red[50]} 0 0 0 3px !important  `,
			},
		},
	},
	clearIcon: {
		fontSize: `${theme.fontSizeIcon.small} !important`,
		color: `${theme.newColors.gray[500]}!important`,
		'&:hover': {
			color: `${theme.newColors.gray[700]}!important`,
		},
	},
}));

export default useStyles;
