import { memo, useContext } from 'react';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { get, pick } from 'lodash';

import { checkAuthorized } from '../utils';

const AllowOneOfAccess = ({ moduleAlias, components, fallback = <></>, ...props }) => {
	const authContext = useContext(AuthDataContext);
	const { currentUser } = pick(authContext, ['currentUser']);
	const strPermissions = get(currentUser, 'permissions', ''); // 'usrmn/*:subj/r:grasch/'

	for (const { permissions, module, Component } of components) {
		if (checkAuthorized({ moduleAlias: module || moduleAlias, permissions, strPermissions })) {
			return <Component {...props} />;
		}
	}
	return fallback;
};

AllowOneOfAccess.defaultProps = {
	permissions: [],
};

export default memo(AllowOneOfAccess);
