export default {
	MC_GET_GRADE_BOOK: 'MC_GET_GRADE_BOOK',
	MC_GET_GRADE_BOOK_SUCCESS: 'MC_GET_GRADE_BOOK_SUCCESS',
	MC_GET_GRADE_BOOK_FAILED: 'MC_GET_GRADE_BOOK_FAILED',

	MC_EDIT_CELL_GRADE_BOOK: 'MC_EDIT_CELL_GRADE_BOOK',
	MC_EDIT_CELL_GRADE_BOOK_SUCCESS: 'MC_EDIT_CELL_GRADE_BOOK_SUCCESS',
	MC_EDIT_CELL_GRADE_BOOK_FAILED: 'MC_EDIT_CELL_GRADE_BOOK_FAILED',

	MC_GET_COLUMN_GRADE_BOOK: 'MC_GET_COLUMN_GRADE_BOOK',
	MC_GET_COLUMN_GRADE_BOOK_SUCCESS: 'MC_GET_COLUMN_GRADE_BOOK_SUCCESS',
	MC_GET_COLUMN_GRADE_BOOK_FAILED: 'MC_GET_COLUMN_GRADE_BOOK_FAILED',

	MC_GRADE_BOOK_SET_STATE: 'MC_GRADE_BOOK_SET_STATE',

	MC_EDIT_SUBMISSION_STATUS: 'MC_EDIT_SUBMISSION_STATUS',
	MC_EDIT_SUBMISSION_STATUS_SUCCESS: 'MC_EDIT_SUBMISSION_STATUS_SUCCESS',
	MC_EDIT_SUBMISSION_STATUS_FAILED: 'MC_EDIT_SUBMISSION_STATUS_FAILED',

	MC_INPUT_OVERALL_GRADE: 'MC_INPUT_OVERALL_GRADE',
	MC_INPUT_OVERALL_GRADE_SUCCESS: 'MC_INPUT_OVERALL_GRADE_SUCCESS',
	MC_INPUT_OVERALL_GRADE_FAILED: 'MC_INPUT_OVERALL_GRADE_FAILED',

	MC_GET_GRADER_DETAIL: 'MC_GET_GRADER_DETAIL',
	MC_GET_GRADER_DETAIL_SUCCESS: 'MC_GET_GRADER_DETAIL_SUCCESS',
	MC_GET_GRADER_DETAIL_FAILED: 'MC_GET_GRADER_DETAIL_FAILED',

	MC_GET_QUIZ_GRADER_DETAIL: 'MC_GET_QUIZ_GRADER_DETAIL',
	MC_GET_QUIZ_GRADER_DETAIL_SUCCESS: 'MC_GET_QUIZ_GRADER_DETAIL_SUCCESS',
	MC_GET_QUIZ_GRADER_DETAIL_FAILED: 'MC_GET_QUIZ_GRADER_DETAIL_FAILED',

	MC_CREATE_QUIZ_ATTEMPT: 'MC_CREATE_QUIZ_ATTEMPT',
	MC_CREATE_QUIZ_ATTEMPT_SUCCESS: 'MC_CREATE_QUIZ_ATTEMPT_SUCCESS',
	MC_CREATE_QUIZ_ATTEMPT_FAILED: 'MC_CREATE_QUIZ_ATTEMPT_FAILED',

	MC_INPUT_GRADE_QUIZ_ATTEMPT: 'MC_INPUT_GRADE_QUIZ_ATTEMPT',
	MC_INPUT_GRADE_QUIZ_ATTEMPT_SUCCESS: 'MC_INPUT_GRADE_QUIZ_ATTEMPT_SUCCESS',
	MC_INPUT_GRADE_QUIZ_ATTEMPT_FAILED: 'MC_INPUT_GRADE_QUIZ_ATTEMPT_FAILED',

	MC_REMOVE_QUIZ_ATTEMPT: 'MC_REMOVE_QUIZ_ATTEMPT',
	MC_REMOVE_QUIZ_ATTEMPT_SUCCESS: 'MC_REMOVE_QUIZ_ATTEMPT_SUCCESS',
	MC_REMOVE_QUIZ_ATTEMPT_FAILED: 'MC_REMOVE_QUIZ_ATTEMPT_FAILED',

	MC_INPUT_OVERALL_GRADE_TEST: 'MC_INPUT_OVERALL_GRADE_TEST',
	MC_INPUT_OVERALL_GRADE_TEST_SUCCESS: 'MC_INPUT_OVERALL_GRADE_TEST_SUCCESS',
	MC_INPUT_OVERALL_GRADE_TEST_FAILED: 'MC_INPUT_OVERALL_GRADE_TEST_FAILED',

	MC_INPUT_STUDENT_PARTICIPATION: 'MC_INPUT_STUDENT_PARTICIPATION',
	MC_INPUT_STUDENT_PARTICIPATION_SUCCESS: 'MC_INPUT_STUDENT_PARTICIPATION_SUCCESS',
	MC_INPUT_STUDENT_PARTICIPATION_FAILED: 'MC_INPUT_STUDENT_PARTICIPATION_FAILED',

	MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION: 'MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION',
	MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION_SUCCESS: 'MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION_SUCCESS',
	MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION_FAILED: 'MULTIPLE_MC_INPUT_STUDENT_PARTICIPATION_FAILED',

	DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION: 'DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION',
	DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION_SUCCESS: 'DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION_SUCCESS',
	DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION_FAILED: 'DEBOUNCE_MC_INPUT_STUDENT_PARTICIPATION_FAILED',

	MC_CALCULATE_OVERALL_COURSE_GRADE: 'MC_CALCULATE_OVERALL_COURSE_GRADE',
	MC_CALCULATE_OVERALL_COURSE_GRADE_SUCCESS: 'MC_CALCULATE_OVERALL_COURSE_GRADE_SUCCESS',
	MC_CALCULATE_OVERALL_COURSE_GRADE_FAILED: 'MC_CALCULATE_OVERALL_COURSE_GRADE_FAILED',

	MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE: 'MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE',
	MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE_SUCCESS: 'MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE_SUCCESS',
	MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE_FAILED: 'MULTIPLE_MC_CALCULATE_OVERALL_COURSE_GRADE_FAILED',

	MC_GET_RELEASE_GRADE_OF_GRADE_BOOK: 'MC_GET_RELEASE_GRADE_OF_GRADE_BOOK',
	MC_GET_RELEASE_GRADE_OF_GRADE_BOOK_SUCCESS: 'MC_GET_RELEASE_GRADE_OF_GRADE_BOOK_SUCCESS',
	MC_GET_RELEASE_GRADE_OF_GRADE_BOOK_FAILED: 'MC_GET_RELEASE_GRADE_OF_GRADE_BOOK_FAILED',

	MC_RELEASE_GRADE_OF_GRADE_BOOK: 'MC_RELEASE_GRADE_OF_GRADE_BOOK',
	MC_RELEASE_GRADE_OF_GRADE_BOOK_SUCCESS: 'MC_RELEASE_GRADE_OF_GRADE_BOOK_SUCCESS',
	MC_RELEASE_GRADE_OF_GRADE_BOOK_FAILED: 'MC_RELEASE_GRADE_OF_GRADE_BOOK_FAILED',
};
