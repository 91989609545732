import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';
import { useSchoolYearDataContext } from 'shared/Auth/containers/SchoolYear';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { checkAuthorized } from 'AppRoute/rolePermission/utils';
import { BaseModal, Box, Button, Typography } from 'ella-storybook';
import { get } from 'lodash';
import { ATTEMPTS } from 'modules/MyTasks/constants';
import { SCHOOL_YEAR_STATUS } from 'modules/SchoolYear/constants';
import PropTypes from 'prop-types';

import GraderActions from '../actions';
import FinalGrade from '../components/RightContent/FinalGrade';
import Grading from '../components/RightContent/Grading';
import { OVERRIDE_GRADE_TYPE } from '../constants';

function FinalGradeBlock({
	totalPoint,
	courseId,
	extraCredit,
	studentProgressId,
	activityType,
	attemptType,
	gradingAttemptsMethod,
	sectionSelected,
	gradingPeriodSelected,
	currentTermId,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const authContext = useContext(AuthDataContext);
	const strPermissions = get(authContext.currentUser, 'permissions', '');
	const gradable = checkAuthorized({
		moduleAlias: MODULE_ALIAS.GRADING,
		permissions: [PERMISSIONS.GRADING.UPDATE],
		strPermissions,
	});

	const schoolYearData = useSchoolYearDataContext();
	const archiveMode = schoolYearData.status === SCHOOL_YEAR_STATUS.ARCHIVED;

	const graderDetail = useSelector((state) => state.Grader.graderDetail);
	const finalGrade = useSelector((state) => state.Grader.graderDetail?.overallGrade);
	const isSubmittingGrade = useSelector((state) => state.Grader.isSubmittingGrade);
	const isFetchingGraderDetail = useSelector((state) => state.Grader.isFetchingGraderDetail);

	const [grade, setGrade] = useState(finalGrade);
	const [openConfirmSwitch, setOpenConfirmSwitch] = useState(false);

	const isLimitedMode = attemptType !== ATTEMPTS.UNLIMITED;

	const onChangeGrade = useCallback(
		(value) => {
			// onResetInputGradeSuccess();
			if (value > totalPoint) return;

			setGrade(value);
		},
		[totalPoint],
	);

	const sortModel = {
		field: 'lastName',
		sort: 'asc',
	};

	const updateGrade = useCallback(
		(value) => {
			const newGrade = value;
			const oldGrade = graderDetail.overallGrade;
			if (!isNaN(oldGrade) && !newGrade && newGrade !== 0) {
				onChangeGrade(oldGrade);
				return;
			}

			const urlParams = {
				sectionId: sectionSelected,
				gradingPeriodId: gradingPeriodSelected,
				sortBy: sortModel.field !== 'studentName' ? sortModel.field : 'lastName',
				studentName: undefined,
			};
			if (gradingPeriodSelected === 'All') {
				delete urlParams.gradingPeriodId;
			}

			if (graderDetail.id && activityType) {
				dispatch(
					GraderActions.inputGrade({
						inputGradeSuccess: null,
						updateStudentAttemptsSuccess: null,
						switchModeSuccess: null,
						progressId: studentProgressId,
						courseId: courseId,
						data: {
							originalInputGrade: newGrade,
							type: activityType,
							termId: currentTermId,
						},
						isSubmittingGrade: true,
						urlParams,
					}),
				);
			}
		},
		[grade, graderDetail.grade, graderDetail.id, activityType, courseId],
	);

	const closeDialog = () => {
		setOpenConfirmSwitch(false);
	};

	useEffect(
		() => () => {
			setGrade(finalGrade);
			setOpenConfirmSwitch(false);
		},
		[],
	);

	useEffect(() => {
		setGrade(finalGrade);
	}, [finalGrade, graderDetail]);

	const switchMode = useCallback(
		(mode) => {
			dispatch(
				GraderActions.switchOverrideMode({
					overallGradeMode: mode,
					progressId: studentProgressId,
					currentTermId,
					courseId,
					inputGradeSuccess: null,
					updateStudentAttemptsSuccess: null,
					switchModeSuccess: null,
				}),
			);
			closeDialog();
		},
		[studentProgressId],
	);

	const confirmSwitchMode = useCallback(
		(mode) => {
			if (mode === OVERRIDE_GRADE_TYPE.MANUAL_GRADE) {
				setOpenConfirmSwitch(true);
			} else {
				switchMode(mode);
			}
		},
		[switchMode],
	);

	return (
		<>
			{isLimitedMode ? (
				<FinalGrade
					finalGrade={finalGrade}
					totalPoint={totalPoint}
					overallGradeType={graderDetail?.overrideGradeType}
					gradingAttemptsMethod={gradingAttemptsMethod}
					attemptIndex={graderDetail?.attemptIndex}
					disableInputGrade={!gradable || archiveMode}
					switchMode={confirmSwitchMode}
					updateGrade={updateGrade}
					onChangeGrade={onChangeGrade}
					grade={grade}
					isSubmittingGrade={isSubmittingGrade}
					isFetchingGraderDetail={isFetchingGraderDetail}
				/>
			) : (
				<Grading
					extraCredit={extraCredit}
					totalPoint={totalPoint}
					disabled={archiveMode || !gradable}
					courseId={courseId}
					currentTermId={currentTermId}
					sectionSelected={sectionSelected}
					gradingPeriodSelected={gradingPeriodSelected}
				/>
			)}

			<BaseModal
				size='small'
				open={openConfirmSwitch}
				onClose={closeDialog}
				contentComponent={<Typography variant='bodyMediumRegular'>{t('grader:switch_dialog_content')}</Typography>}
				customFooter={
					<Box className='customFooterModal'>
						<Button variant='outlined' onClick={closeDialog}>
							{t('cancel', { ns: 'common' })}
						</Button>
						<Button onClick={() => switchMode(OVERRIDE_GRADE_TYPE.MANUAL_GRADE)}>{t('grader:switch')}</Button>
					</Box>
				}
				title={t('grader:switch_to_override_mode')}
			/>
		</>
	);
}

FinalGradeBlock.propTypes = {
	activityType: PropTypes.any,
	attemptType: PropTypes.any,
	courseId: PropTypes.any,
	extraCredit: PropTypes.any,
	gradingAttemptsMethod: PropTypes.any,
	overallGrade: PropTypes.any,
	studentProgressId: PropTypes.any,
	totalPoint: PropTypes.number,
};
export default React.memo(FinalGradeBlock);
