import React, { useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import ContextMenuComponent from './component/ContextMenuComponent';

export const CONTEXT_MENU_DATA_PROVIDER = {
	render: (container, items, onDismiss) => {
		container.style.zIndex = 1202; // z-index of MuiDrawer is 1200 and TableResizePlugin is 1201
		ReactDOM.render(<ContextMenu items={items} onDismiss={onDismiss} />, container);
	},
	dismiss: (container) => {
		ReactDOM.unmountComponentAtNode(container);
	},
};

function ContextMenu(props) {
	const ref = useRef();
	const { items, onDismiss } = props;
	const [document, setDocument] = useState(null);

	useEffect(() => {
		const doc = ref.current.ownerDocument;
		doc.addEventListener('click', onDismiss);
		doc.addEventListener('keydown', onDismiss);
		setDocument(ref.current);
		return () => {
			doc.removeEventListener('click', onDismiss);
			doc.removeEventListener('keydown', onDismiss);
		};
	}, []);

	return <ContextMenuComponent ref={ref} items={items} document={document} />;
}

ContextMenu.propTypes = {
	onDismiss: PropTypes.func,
	items: PropTypes.array,
};
