import React, { forwardRef } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';

import { size } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	wrapper: {
		borderRadius: theme.spacing(0.5),
		width: theme.spacing(22.5),
		boxShadow: 'rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0,0,0,0.11) 0px 0.6px 1.8px 0px',
	},
	menu: {
		display: 'flex',
	},
	menuItem: {
		backgroundColor: 'white',
		padding: theme.spacing(1),
		cursor: 'pointer',
		width: theme.spacing(22.5),
		transition: '0.2s all',
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover': {
			backgroundColor: '#F4F4F5', // newColors.gray[100]
		},
		'&:hover + .subItems': {
			display: 'flex',
		},
	},
	menuSubItems: {
		boxShadow: 'rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0,0,0,0.11) 0px 0.6px 1.8px 0px',
		backgroundColor: 'white',
		width: theme.spacing(22.5),
		transition: '0.2s all',
		display: 'none',
		justifyContent: 'space-between',
		position: 'absolute',
		left: theme.spacing(22.5),
		flexDirection: 'column',
		borderRadius: theme.spacing(0.5),
		'&:hover': {
			display: 'flex',
		},
	},
	subItem: {
		padding: theme.spacing(1),
		cursor: 'pointer',
		transition: '0.2s all',
		'&:hover': {
			backgroundColor: '#F4F4F5', // newColors.gray[100]
		},
	},
}));

const ContextMenuComponent = forwardRef(({ items }, ref) => {
	const { classes } = useStyles();

	const isHaveSubItems = (item) => !!size(item.subItems);

	return (
		<Box className={classes.wrapper} ref={ref}>
			{items.map(
				(item) =>
					item && (
						<Box className={classes.menu}>
							<Box
								id='menuPluginItem'
								className={classes.menuItem}
								key={item.key}
								onClick={() => item.onClick && item.onClick()}
							>
								{item.name}
								{isHaveSubItems(item) && <ChevronRightIcon />}
							</Box>
							{isHaveSubItems(item) && (
								<Box className={`${classes.menuSubItems} subItems`}>
									{Object.entries(item.subItems).map(([key, value]) => (
										<Box
											id='subMenuPluginItem'
											className={classes.subItem}
											key={key}
											onClick={() => value.onClick && value.onClick(key)}
										>
											{value.name}
										</Box>
									))}
								</Box>
							)}
						</Box>
					),
			)}
		</Box>
	);
});

ContextMenuComponent.propTypes = {
	items: PropTypes.array,
};
export default ContextMenuComponent;
