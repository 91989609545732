import { CLASS_NAME } from 'utils/constants';

export default function loadingImg() {
	const img = document.querySelectorAll('img.lazy');
	let observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (entry.target.dataset.src) {
						entry.target.src = entry.target.dataset.src;
					}
					entry.target.removeAttribute('data-src');
					entry.target.classList.remove(CLASS_NAME.LAZY);
					entry.target.classList.add(CLASS_NAME.INIT);
					observer.unobserve(entry.target);
				}
			});
		},
		{
			threshold: 1,
		},
	);
	img.forEach((img) => {
		observer.observe(img);
	});
}
export function addClassInsideHeading(editor) {
	let traverser = editor.getSelectionTraverser();
	let blockElement = traverser ? traverser.currentBlockElement : null;
	while (blockElement) {
		let element = blockElement.collapseToSingleElement();
		element
			.querySelectorAll('img')
			.forEach(({ dataset, classList }) => classList.add(dataset?.src ? CLASS_NAME.LAZY : CLASS_NAME.INIT));
		blockElement = traverser.getNextBlockElement();
	}
}
