import React from 'react';

import Grid from '@mui/material/Grid';

import { keyframes } from '@emotion/react';
import { ReactComponent as EllaLogo } from 'assets/images/ella_logo.svg';
import { makeStyles } from 'tss-react/mui';

const progressBar = keyframes`
    0% {
      width: 0
    },
    100% {
       width: '100%'
    }
  `;

const useStyles = makeStyles()((theme) => ({
	root: {
		height: '100vh',
	},
	progress: {
		width: theme.spacing(35),
		height: theme.spacing(0.75),
		borderRadius: theme.spacing(0.5),
		backgroundColor: theme.newColors.gray[200],
		justifyItems: 'center',
		marginTop: theme.spacing(5),
		'& > .loading': {
			width: theme.spacing(35),
			height: theme.spacing(0.75),
			borderRadius: theme.spacing(0.5),
			backgroundColor: theme.newColors.gray[800],
			animation: `${progressBar} 5s ease-in-out`,
		},
	},
}));

function Preloader() {
	const { classes } = useStyles();

	return (
		<Grid
			className={classes.root}
			container
			alignContent='center'
			alignItems='center'
			justifyContent='center'
			direction='column'
		>
			<EllaLogo />
			<div id='progress' className={classes.progress}>
				<div className='loading' />
			</div>
		</Grid>
	);
}
export default Preloader;
