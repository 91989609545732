import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse, useTheme } from '@mui/material';

import StoryBookCheckBox from 'components/StoryBookCheckBox';
import TblIcon from 'components/TblIcon';

import { Box, Dropdown, Radio, TextField, Tooltip } from 'ella-storybook';
import { ACTIVITY_CATEGORY, GRADE_CALCULATION_TYPE } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { GRADE_OPTIONS, GRADE_TYPE } from '../constants';

import CollapseOptions from './CollapseOptions';
const TOTAL_POINTS = {
	MIN: 1,
	MAX: 10000,
};
const MAX_LENGTH = 5;

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: theme.spacing(2),
		borderRadius: theme.spacing(2),
		background: theme.newColors.gray[100],
	},
	category: {
		marginTop: theme.spacing(3),
	},
	radio: {
		marginTop: theme.spacing(3),
	},
	totalPoints: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
		marginTop: theme.spacing(3),
		'& .MuiFormHelperText-root': {},
	},
}));
function Grading({
	gradeCalculation,
	// categories,
	disabled,
	handleChange,
	errors,
	touched,
	handleBlur,
	submitCount,
	values,
	fieldName,
	setFieldError,
	tooltip,
	activityType,
	isEditCategory,
	gradingAttemptsMethodActivity,
	gradingAttemptsMethodDefault,
}) {
	const theme = useTheme();
	const [open, setOpen] = useState(true);

	const { classes } = useStyles();
	const { t } = useTranslation('myCourses');
	const categoryName = !!fieldName ? `${fieldName}.gradeWeightCriteriaId` : 'gradeWeightCriteriaId';
	const gradeTypeName = !!fieldName ? `${fieldName}.gradeType` : 'gradeType';
	const totalPointName = !!fieldName ? `${fieldName}.totalPoint` : 'totalPoint';
	const extraCreditName = !!fieldName ? `${fieldName}.extraCredit` : 'extraCredit';
	const gradingAttemptsMethodName = !!fieldName ? `${fieldName}.gradingAttemptsMethod` : 'gradingAttemptsMethod';

	const isShowGuideText =
		Number(gradeCalculation?.gradeCalculationType) === GRADE_CALCULATION_TYPE.WEIGHTED_BY_CATEGORY;

	const { categories, gradeCalculationType } = gradeCalculation || {};

	const enableChangeWeightCategory =
		isEditCategory === undefined &&
		(gradeCalculationType === GRADE_CALCULATION_TYPE.TOTAL_POINT ||
			gradeCalculationType === GRADE_CALCULATION_TYPE.NO_OVERALL_GRADE);

	const categoriesOption = (categories || []).map((item) => ({
		value: item.id,
		label: item.name,
		weight: !item.id ? '' : item.weight,
		description:
			!item.id && isShowGuideText
				? t('guild_text_no_category', {
						subject: activityType !== ACTIVITY_CATEGORY.PARTICIPATION ? 'activity' : 'participation',
				  })
				: '',
	}));

	return (
		<Box className={classes.root}>
			<CollapseOptions open={open} onClose={() => setOpen(!open)} title={t('grading')} tooltip={tooltip}>
				{activityType !== ACTIVITY_CATEGORY.PARTICIPATION && (
					<Radio
						className={classes.radio}
						name={gradeTypeName}
						label={t('grade_type')}
						options={GRADE_OPTIONS}
						disabled={disabled}
						value={values?.gradeType}
						onChange={(value) => {
							handleChange(gradeTypeName, Number(value));
							if (Number(value) === GRADE_TYPE.NON_GRADED) {
								handleChange(gradingAttemptsMethodName, null);
								return;
							}
							handleChange(
								gradingAttemptsMethodName,
								gradingAttemptsMethodActivity ?? gradingAttemptsMethodDefault ?? null,
							);
							return;
						}}
					/>
				)}
				{values?.gradeType === GRADE_TYPE.GRADED && (
					<>
						<Dropdown
							className={classes.category}
							onBlur={handleBlur}
							disableCloseOnSelect={false}
							name={categoryName}
							multiple={false}
							label={t('category')}
							width={'100%'}
							disableClearable
							disableFilter
							disabled={isEditCategory ? !isEditCategory : false}
							options={categoriesOption}
							helperText={(touched?.gradeWeightCriteriaId || submitCount) && errors?.gradeWeightCriteriaId}
							error={!!((touched?.gradeWeightCriteriaId || submitCount) && errors?.gradeWeightCriteriaId)}
							value={
								categoriesOption.length &&
								(categoriesOption?.find(({ value }) => values?.gradeWeightCriteriaId === value) ||
									categoriesOption[categoriesOption.length - 1])
							}
							onChange={(item) => {
								handleChange(categoryName, item?.value);
								// only change when grade calculation type is total point or no overall grade and only change weight when create modal
								if (enableChangeWeightCategory) {
									handleChange(totalPointName, item?.weight);
									return;
								}
							}}
						/>
						<Collapse in={Number(values?.gradeType) === GRADE_TYPE.GRADED}>
							<Box className={classes.totalPoints} sx={{ color: (theme) => theme.newColors.gray[400] }}>
								<TextField
									sx={{ color: (theme) => theme.newColors.gray[400] }}
									label={
										<Box display='flex'>
											{t('total_points')}
											<Tooltip
												title={t('number_range', { min: TOTAL_POINTS.MIN, max: TOTAL_POINTS.MAX })}
												placement='top'
											>
												<Box>
													<TblIcon icon='info' fontSize={theme.fontSizeIcon.normal} />
												</Box>
											</Tooltip>
										</Box>
									}
									disabled={activityType !== ACTIVITY_CATEGORY.PARTICIPATION && disabled}
									type='number'
									name={totalPointName}
									fullWidth={true}
									value={values?.totalPoint}
									onBlur={(e) => {
										if (!e.target.value) {
											handleChange(e.target.name, null);
											setFieldError(e.target.name, null);
										} else {
											let value = Number(e.target.value);
											if (value > TOTAL_POINTS.MAX) {
												value = TOTAL_POINTS.MAX;
											} else if (value < TOTAL_POINTS.MIN) {
												value = TOTAL_POINTS.MIN;
											}
											handleChange(e.target.name, value);
											setFieldError(e.target.name, null);
										}
									}}
									helperText={!!((touched?.totalPoint || submitCount) && errors?.totalPoint) && errors?.totalPoint}
									error={!!((touched?.totalPoint || submitCount) && errors?.totalPoint)}
									required
									inputProps={{ maxLength: MAX_LENGTH }}
									onChange={(e) => {
										handleChange(totalPointName, e.target.value);
									}}
								/>

								{activityType === ACTIVITY_CATEGORY.ASSIGNMENT && (
									<StoryBookCheckBox
										name={extraCreditName}
										label={t('extra_credit')}
										typographyVariant={'bodyMediumRegular'}
										labelPlacement='end'
										checked={values?.extraCredit}
										disabled={disabled}
										onChange={(value) => {
											handleChange(extraCreditName, Boolean(value));
										}}
										sx={(theme) => ({
											color: theme.newColors.gray[800],
										})}
									/>
								)}
							</Box>
						</Collapse>
					</>
				)}
			</CollapseOptions>
		</Box>
	);
}

Grading.propTypes = {
	gradeCalculation: PropTypes.object,
	categories: PropTypes.array,
	gradeType: PropTypes.number,
	totalPoints: PropTypes.number,
	category: PropTypes.string,
	handleChange: PropTypes.func,
	fieldName: PropTypes.string,
	tooltip: PropTypes.string,
	gradingAttemptsMethodActivity: PropTypes.number,
	gradingAttemptsMethodDefault: PropTypes.number,
};
Grading.defaultProps = {
	fieldName: '',
};

export default Grading;
