import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AllowOneOfAccess from 'AppRoute/rolePermission/components/AllowOneOfAccess';
import Loading from 'components/TblLoading';

import authActions from 'shared/Auth/actions';
import { MODULE_ALIAS } from 'shared/Auth/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { get } from 'lodash';
import MyCoursesActions from 'modules/MyCourses/actions';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { ROUTE_MY_COURSES } from '../constantsRoute';

import CoursePermissionDenied from './CoursePermissionDenied';

const GuardCourse = (...components) => {
	const WithComponent = (props) => {
		const dispatch = useDispatch();
		const params = useParams();
		const history = useHistory();

		const authContext = useContext(AuthDataContext);
		const strPermissions = get(authContext.currentUser, 'permissions');

		const originPermissions = useSelector((state) => state.Auth?.originPermissions);
		const getPermissionCourseLoading = useSelector((state) => state.AllCourses?.getPermissionCourseLoading);

		useEffect(() => {
			if (strPermissions === originPermissions) {
				dispatch(
					MyCoursesActions.getPermissionCourse({
						courseId: params?.courseId,
					}),
				);
			}
		}, [originPermissions, strPermissions]);

		useEffect(() => {
			return () => {
				// NOTE: reset to previous permission when being out of course
				originPermissions &&
					dispatch(
						authActions.authSetState({
							currentUser: { ...authContext.currentUser, permissions: originPermissions },
						}),
					);
			};
		}, [originPermissions]);

		return (
			<AllowOneOfAccess
				moduleAlias={MODULE_ALIAS.COURSE}
				components={components}
				fallback={
					getPermissionCourseLoading ? (
						<Loading />
					) : (
						<CoursePermissionDenied
							onBack={() => {
								history.push(ROUTE_MY_COURSES.DEFAULT);
							}}
						/>
					)
				}
				{...props}
			/>
		);
	};
	return WithComponent;
};

export default GuardCourse;
