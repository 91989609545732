import { makeAjaxRequest } from 'utils/ajax';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import myTodosActions from './actions';
import { END_POINT, actions } from './constants';

const getGradeBoostInformationEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADE_BOOST_INFORMATION),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_grade_boost_information.method,
				END_POINT.get_grade_boost_information.url(payload?.orgId, payload?.params),
			).pipe(
				(data) => data,
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTodosActions.getGradeBoostInformationFailed({
								error: data.response.errors,
								isFetchingGradeBoost: false,
							}),
						);
					}
					return of(
						myTodosActions.getGradeBoostInformationSuccess({
							gradeBoostData: data.response.result,
							numOfAvailableCourses: data.response.numOfAvailableCourses,
							selectedCourses: data.response.selectedCourses,
							isFetchingGradeBoost: false,
						}),
					);
				}),
				catchError(() => of(myTodosActions.getGradeBoostInformationFailed({ isFetchingGradeBoost: false }))),
			),
		),
	);

const getSchoolPlanEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_PLAN),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_school_plan.method,
				END_POINT.get_school_plan.url(payload?.orgId, {
					...payload?.params,
					sort: 'desc',
					fieldSort: 'completedBy',
				}),
			).pipe(
				mergeMap((data) =>
					of(
						myTodosActions.getSchoolPlanSuccess({
							schoolPlan: data.response.items || [],
							isFetchingSchoolPlan: false,
						}),
					),
				),
				catchError(() =>
					of(
						myTodosActions.getSchoolPlanFailed({
							isFetchingSchoolPlan: false,
						}),
					),
				),
			),
		),
	);

const updateStudentGradeBoostActivityEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_STUDENT_GRADE_BOOST_ACTIVITY),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.update_student_grade_boost_activity.method,
				END_POINT.update_student_grade_boost_activity.url(payload?.orgId, payload?.courseId),
				{ data: payload?.data },
			).pipe(
				mergeMap((data) =>
					of(
						myTodosActions.updateStudentGradeBoostActivitySuccess({
							listUpdateStudentGradeBoostActivity: data.response.result || [],
							isUpdateStudentGradeBoostActivitySuccess: true,
							isUpdatingStudentGradeBoostActivity: false,
						}),
					),
				),
				catchError(() =>
					of(
						myTodosActions.updateStudentGradeBoostActivityFailed({
							isUpdateStudentGradeBoostActivityFailed: true,
							isUpdatingStudentGradeBoostActivity: false,
						}),
					),
				),
			),
		),
	);

const getInfoPotentialGradeEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_INFO_POTENTIAL_GRADE),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_potential_information.method,
				END_POINT.get_potential_information.url(),
				payload,
			).pipe(
				(data) => data,
				mergeMap((data) => {
					if (data.response.errors) {
						return of(myTodosActions.getInfoPotentialGradeFailed({ error: data.response.errors }));
					}
					return of(
						myTodosActions.getInfoPotentialGradeSuccess({
							dataInfoPotential: data.response.data,
							isGetInfoPotentialGradeSuccess: true,
						}),
					);
				}),
				catchError(() => of(myTodosActions.getInfoPotentialGradeFailed({ isGetInfoPotentialGradeFailed: true }))),
			),
		),
	);

export default [getGradeBoostInformationEpic, updateStudentGradeBoostActivityEpic, getInfoPotentialGradeEpic];
