import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';

import { isTeacher } from 'utils/roles';

import { ROUTE_AUTH } from 'shared/Auth/constantsRoute';
import googleActions from 'shared/Google/actions';
import { TYPE_UPDATE_PROFILE, USER_BEHAVIOR } from 'shared/User/constants';

import { ReactComponent as ImageDrive } from 'assets/images/Drive.svg';
import { ReactComponent as GoogleIcon } from 'assets/images/icn_google.svg';
import { BaseModal, Box, Button, Typography } from 'ella-storybook';
import myProfileActions from 'modules/MyProfile/actions';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const ConnectGoogleDialog = (props) => {
	const { currentUser, isShowConnectGoogleDialog } = props;

	const { t } = useTranslation('google', 'error');
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { settings } = currentUser;
	const domain = window.location.hostname.split('.');

	const googleOauthUrlSuccess = useSelector((state) => state?.Google?.googleOauthUrlSuccess);

	const [openConnectGoogleDialog, setOpenConnectGoogleDialog] = useState(false);

	const toggleConnectGoogleDialog = () => {
		setOpenConnectGoogleDialog((prev) => !prev);
	};

	useEffect(() => {
		if (!isNil(isShowConnectGoogleDialog)) {
			setOpenConnectGoogleDialog(isShowConnectGoogleDialog);
			return;
		}
	}, [isShowConnectGoogleDialog]);

	useEffect(() => {
		if (googleOauthUrlSuccess) {
			setOpenConnectGoogleDialog(false);
			routingAuth();
			if (!settings?.behavior?.includes(USER_BEHAVIOR.HAVE_SET_UP_CONNECT_GOOGLE)) {
				updateProfile();
			}
		}
	}, [googleOauthUrlSuccess]);

	const routingAuth = () => {
		const isFromSetUpCourse = new URLSearchParams(location.search).get('setup_course');
		if (Boolean(isFromSetUpCourse)) {
			const courseId = new URLSearchParams(location.search).get('courseId');
			history.push(`${ROUTE_AUTH.LOGIN}?setup_course=${Boolean(isFromSetUpCourse)}&courseId=${courseId}`);
		}
		// else {
		// history.push(ROUTE_AUTH.LOGIN);
		// }
	};

	const OauthGoogle = () => {
		dispatch(
			googleActions.getGoogleOauthUrl({
				urlParams: {
					redirectUrl: `${window.location.origin}/google-oauth?subdomain=${domain[0]}&rollback=${window.location.href}`,
				},
			}),
		);
	};

	const updateProfile = () => {
		if (!isArray(settings?.behavior)) {
			return;
		}

		settings.behavior.push(USER_BEHAVIOR.HAVE_SET_UP_CONNECT_GOOGLE);

		const payload = {
			userId: currentUser.id,
			organization: currentUser.organizationId,
			settings,
			type: TYPE_UPDATE_PROFILE.TEACHER_ON_BOARDING,
		};
		if (isTeacher(currentUser)) {
			dispatch(myProfileActions.updateMyProfile(payload));
		}
	};

	const customFooter = () => {
		return (
			<Box className='customFooterModal' flexDirection={'column'} alignItems={'center'}>
				<Button
					variant='outlined'
					startIcon={<GoogleIcon />}
					onClick={() => OauthGoogle()}
					sx={{
						gap: '16px',
						padding: '10px 16px !important',
						width: '310px',
						'.MuiButton-startIcon': {
							margin: '0',
							svg: {
								width: '24px',
								height: '24px',
							},
						},
					}}
				>
					{'Sign in with Google'}
				</Button>
				<Button variant='outlined' onClick={updateProfile} sx={{ marginTop: (theme) => theme.spacing(1) }}>
					{'Dismiss'}
				</Button>
				<Typography variant='bodySmallTemporary' color={(theme) => theme.newColors.gray[500]}>
					{'You can do it later when attaching a Google file to course activities. '}
				</Typography>
			</Box>
		);
	};

	const contentComponent = () => {
		return (
			<Box
				display='flex'
				flexDirection={'column'}
				alignItems='center'
				alignContent='center'
				justifyContent='center'
				gap={2}
				// width={'500px'}
				// textAlign={'center'}
			>
				<ImageDrive />
				<Typography variant='bodyMediumRegular' color={(theme) => theme.newColors.gray[800]}>
					{'Connect to your Google account so that your students will be able to submit Google files for the tasks. '}
				</Typography>
			</Box>
		);
	};

	return (
		<BaseModal
			open={openConnectGoogleDialog}
			title={t('title_connect_google')}
			onClose={toggleConnectGoogleDialog}
			customFooter={customFooter()}
			contentComponent={contentComponent()}
		/>
	);
};

ConnectGoogleDialog.propTypes = {
	openConnectGoogleDialog: PropTypes.bool,
	toggleConnectGoogleDialog: PropTypes.func,
	currentUser: PropTypes.object,
	isShowConnectGoogleDialog: PropTypes.bool,
};

export default ConnectGoogleDialog;
