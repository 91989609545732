import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, FormControlLabel } from '@mui/material';

import TblActivityIcon from 'components/TblActivityIcon';
import TblFormHelperText from 'components/TblFormHelperText';
import TblIcon from 'components/TblIcon';
import TblUseSnackbar from 'components/TblUseSnackbar';

import { APPLY_FOR, APPLY_SECTIONS, DATE_TYPE, DIALOG_TYPE, PUBLISH_NOW } from 'shared/MyCourses/constants';
import { convertedDefaultValue, hasArray, processSessionsAllSections } from 'shared/MyCourses/utils';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { BaseModal, Button, Checkbox, Dropdown, Radio, Typography } from 'ella-storybook';
import { Field, FieldArray, Form, Formik } from 'formik';
import { omit, pick } from 'lodash';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup';

import ScheduleAllSectionsSkeleton from './ScheduleAllSectionsSkeleton';
import ScheduleCustomSkeleton from './ScheduleCustomSkeleton';
import SectionSchedule from './SectionSchedule';
import { Content, Header, HeaderTitle } from './styles';
export default function ScheduleDialog(props) {
	const {
		name,
		unitId,
		courseId,
		applyFor,
		activityId,
		type,
		isVisible,
		toggleCloseDialog,
		method,
		shadowId,
		sectionId,
		updateUnit,
		hasPublished,
		showOldSessions,
		shouldResetState,
		// gradingPeriodId,
	} = props;

	const { t } = useTranslation(['myCourses', 'common', 'error']);
	const dispatch = useDispatch();
	const authContext = useContext(AuthDataContext);
	const { organizationId } = authContext.currentUser;
	const formikRef = useRef();

	const assignLabel =
		type === ACTIVITY_CATEGORY.ASSIGNMENT
			? t('assigned_on', { ns: 'myCourses' })
			: t('announced_on', { ns: 'myCourses' });
	const dueLabel =
		type === ACTIVITY_CATEGORY.ASSIGNMENT ? t('due_on', { ns: 'myCourses' }) : t('taken_on', { ns: 'myCourses' });
	const sessions = useSelector((state) => state.AllCourses.sessions);
	const sections = useSelector((state) => state.AllCourses.sections);
	const {
		mcConsolidateAssignmentSuccess,
		mcConsolidateAssignmentFailed,
		error,
		mcConsolidateTestSuccess,
		isFetchingSectionsList,
	} = useSelector((state) => state.AllCourses);
	const { mcConsolidateAssignmentNeedConfirm, studentHasCustomDue } = useSelector((state) => state.MyCourses);

	const assignSessions = processSessionsAllSections(sessions, DATE_TYPE.ASSIGN, type);
	const dueSessions = processSessionsAllSections(sessions, DATE_TYPE.DUE, type);

	const headerOptions = (activityType) => (onSelect, idx) =>
		[
			{
				value: PUBLISH_NOW,
				label: activityType === ACTIVITY_CATEGORY.TEST ? t('myCourses:announce_now') : t('myCourses:assign_now'),
				disabled: false,
				index: idx ?? 0, // a little trick because value is time.now so it will change by time => compare by index
				control: ({ value, disabled, onChange, item }) => {
					return (
						<FormControlLabel
							onClick={(e) => {
								onSelect(e, { value, disabled, onChange, item });
							}}
							sx={{
								margin: 0,
								'.MuiFormControlLabel-root': {
									marginRight: 0,
								},
							}}
							label={activityType === ACTIVITY_CATEGORY.TEST ? t('myCourses:announce_now') : t('myCourses:assign_now')}
							control={<Checkbox checked={item?.index === value?.index} />}
						/>
					);
				},
			},
		];
	// eslint-disable-next-line no-use-before-define
	const stickyOptions = useMemo(() => headerOptions(type)(() => {}), [type]); // just get the options value

	const [formState, setFormState] = useState({
		applyFor: shadowId ? APPLY_FOR.CUSTOM : applyFor ?? APPLY_FOR.ALL,
		showOldSessions: showOldSessions,
		assignDate: null,
		dueDate: null,
		sections: [],
	});

	const [commonError, setCommonError] = useState({
		isError: false,
		errorMessage: '',
	});

	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [confirmStudentCustomDue, setConfirmStudentCustomDue] = useState(false);

	//User guiding
	const history = useHistory();
	const location = useLocation();

	useEffect(
		() => () => {
			setOpenConfirmModal(false);
		},
		[],
	);

	useEffect(() => {
		if (mcConsolidateAssignmentNeedConfirm) {
			setOpenConfirmModal(true);
		}
	}, [mcConsolidateAssignmentNeedConfirm]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		if (isVisible) {
			queryParams.append('modal', 'schedule');
			history.replace({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		} else {
			if (queryParams.has('modal')) {
				queryParams.delete('modal');
				history.replace({
					search: queryParams.toString(),
				});
			}
		}
	}, [isVisible]);
	/////////////////////////;

	useEffect(() => {
		if (sections?.length) {
			const defaultAllAssignDate = sections.find((item) => item.assignDate);
			const defaultAlDueDate = sections.find((item) => item.dueDate);
			setFormState({
				applyFor: shadowId ? APPLY_FOR.CUSTOM : hasPublished ? APPLY_FOR.CUSTOM : applyFor ?? APPLY_FOR.ALL,
				showOldSessions: showOldSessions,
				assignDate:
					applyFor === APPLY_FOR.ALL
						? Number(defaultAllAssignDate) === PUBLISH_NOW || !defaultAllAssignDate
							? defaultAllAssignDate
								? {
										value: PUBLISH_NOW,
										label: defaultAlDueDate?.assignDateLabel,
								  }
								: omit(stickyOptions[0], ['control'])
							: assignSessions?.find((item) => item.value === Number(defaultAllAssignDate?.assignDate)) || null
						: omit(stickyOptions[0], ['control']),
				dueDate:
					applyFor === APPLY_FOR.ALL
						? dueSessions?.find((item) => item.value === Number(defaultAlDueDate?.dueDate)) || null
						: null,
				sections: sections?.map((item, idx) => ({
					select: false,
					id: item.id,
					assignDate: Number(item?.assignDate)
						? convertedDefaultValue(
								Number(item?.assignDate),
								item.id,
								DATE_TYPE.ASSIGN,
								sessions,
								item?.isPublished,
								item,
						  )
						: { ...omit(stickyOptions[0], ['control']), index: idx },
					dueDate: Number(item?.dueDate)
						? convertedDefaultValue(Number(item?.dueDate), item.id, DATE_TYPE.DUE, sessions)
						: null,
					sectionName: item.sectionName,
					status: item.status,
				})),
			});
		}
	}, [sections, hasPublished, showOldSessions, stickyOptions]);

	useEffect(() => {
		if (mcConsolidateAssignmentSuccess || mcConsolidateTestSuccess) {
			//User Guiding
			const queryParams = new URLSearchParams(location.search);

			if (queryParams.has('modal')) {
				queryParams.delete('modal');
				history.replace({
					search: queryParams.toString(),
				});
			}
			////////////////
			toggleCloseDialog(type);

			///////////////
			formikRef.current && formikRef.current.setSubmitting(false);
		}
		return () => {
			if (!shouldResetState) {
				dispatch(
					myCoursesActions.myCoursesSetState({
						mcConsolidateAssignmentSuccess: null,
						mcConsolidateAssignmentFailed: null,
						mcConsolidateTestSuccess: null,
						mcConsolidateTestFailed: null,
					}),
				);
				return;
			}

			switch (type) {
				case ACTIVITY_CATEGORY.ASSIGNMENT:
					dispatch(
						myCoursesActions.myCoursesSetState({
							mcConsolidateAssignmentSuccess: null,
							mcConsolidateAssignmentFailed: null,
							mcConsolidateTestSuccess: null,
							mcConsolidateTestFailed: null,
							assignment: {},
							sections: [],
						}),
					);
					break;

				case ACTIVITY_CATEGORY.TEST:
					dispatch(
						myCoursesActions.myCoursesSetState({
							mcConsolidateAssignmentSuccess: null,
							mcConsolidateAssignmentFailed: null,
							mcConsolidateTestSuccess: null,
							mcConsolidateTestFailed: null,
							test: {},
							sections: [],
						}),
					);
					break;

				default:
					break;
			}
		};
	}, [mcConsolidateAssignmentSuccess, mcConsolidateTestSuccess, shouldResetState]);

	useEffect(() => {
		dispatch(
			myCoursesActions.getAllScheduleSections({
				orgId: organizationId,
				courseId,
				urlParams: { activityId: activityId, type },
				isFetchingSectionsList: true,
			}),
		);
	}, []);

	const initialValues = formState;
	const validationSchema = Yup.object().shape({
		applyFor: Yup.number().required(t('common:required_message')),
		showOldSessions: Yup.boolean(),
		assignDate: Yup.object()
			.nullable()
			.when('applyFor', {
				is: (value) => value === APPLY_FOR.ALL,
				then: Yup.object().required(t('common:required_message')),
			})
			.test('unevenSession', t('uneven_sessions', { ns: 'error' }), function (value) {
				const { applyFor } = this.parent;
				if (value?.value === PUBLISH_NOW) {
					return true;
				}
				if (value && applyFor === APPLY_FOR.ALL && !hasArray(value.sectionSchedules, sections)) {
					return false;
				}
				return true;
			}),

		dueDate: Yup.object()
			.nullable()
			.when('applyFor', {
				is: (value) => value === APPLY_FOR.ALL,
				then: Yup.object()
					.required(t('common:required_message'))
					// .test(
					//   'has-PastSection',
					//   t('has_section_in_the_past', { ns: 'error', dueOn: dueLabel.toLowerCase() }),
					//   function (value) {
					//     if (value && value?.sectionSchedules?.length) {
					//       const hasPastSections = checkPastSections(value.sectionSchedules);
					//       if (hasPastSections) {
					//         return false;
					//       }
					//     }
					//     return true;
					//   }
					// )
					.test(
						'compareWithAssignOn',
						t('due_on_less_than_assign_on', { ns: 'error', dueOn: dueLabel, assignOn: assignLabel }),
						function (value) {
							const { assignDate } = this.parent;
							if (value) {
								// const hasPastSections = checkPastSections(value.sectionSchedules);
								if (assignDate?.value === PUBLISH_NOW) {
									const keyToCheck = type === ACTIVITY_CATEGORY.ASSIGNMENT ? 'end' : 'start';
									const sessionsDue = (value?.sectionSchedules || []).map((item) => moment(item[keyToCheck]));
									const hasPastDue = sessionsDue.find((item) => item.isSameOrBefore(moment()));
									if (hasPastDue) {
										return false;
									}
									return true;
								}
								if (assignDate) {
									const assignDateNum = Number(assignDate.label.split(' ')[1]);
									const dueDateNum = Number(value.label.split(' ')[1]);
									return assignDateNum > dueDateNum ? false : true;
								}
							}
							return true;
						},
					)
					.test('unevenSession', t('uneven_sessions', { ns: 'error' }), function (value) {
						const { applyFor } = this.parent;

						if (value && applyFor === APPLY_FOR.ALL && !hasArray(value.sectionSchedules, sections)) {
							return false;
						}
						return true;
					}),
			}),
		sections: Yup.array().when('applyFor', {
			is: (value) => value === APPLY_FOR.CUSTOM,
			then: Yup.array().of(
				Yup.object().shape({
					select: Yup.boolean().required(t('common:required_message')),
					assignDate: Yup.object()
						.nullable()
						.when('select', {
							is: true,
							then: Yup.object().nullable().required(t('common:required_message')),
						}),
					dueDate: Yup.object()
						.nullable()
						.when('select', {
							is: true,
							then: Yup.object().nullable().required(t('common:required_message')),
						})
						.test(
							'compareAssignOn',
							t('due_on_less_than_assign_on', { ns: 'error', dueOn: dueLabel, assignOn: assignLabel }),
							function (value) {
								const { assignDate, select } = this.parent;
								if (select && assignDate?.start && value?.end) {
									return moment(assignDate.start).isAfter(moment(value.end)) ? false : true;
								}
								if (!this.parent?.sectionDisabled && select && assignDate?.value === PUBLISH_NOW && value) {
									const keyToCheck = type === ACTIVITY_CATEGORY.ASSIGNMENT ? 'end' : 'start';
									return moment().isAfter(moment(value[keyToCheck])) ? false : true;
								}
								return true;
							},
						),
					// .test('compareCurrent', t('select_from_the_past', { ns: 'error', dueOn: dueLabel }), function (value) {
					//   const { assignDate, select } = this.parent;
					//   const today = moment();
					//   if (select && assignDate && value) {
					//     return (moment(assignDate.start).isAfter(moment(value.end)) && today.isAfter(moment(value.end))) ||
					//       today.isAfter(moment(value.end))
					//       ? false
					//       : true;
					//   }
					//   return true;
					// }),
				}),
			),
		}),
	});

	const onClose = () => {
		//User Guiding
		const queryParams = new URLSearchParams(location.search);

		if (queryParams.has('modal')) {
			queryParams.delete('modal');
			history.replace({
				search: queryParams.toString(),
			});
		}
		////////////////
		toggleCloseDialog(type);
	};
	const handleChange = (fieldName, value, setFieldValue, values) => {
		setFieldValue(fieldName, value);
		if (
			(fieldName.indexOf('select') !== -1 && value && commonError.isError) ||
			(fieldName === 'applyFor' && value !== values.applyFor)
		) {
			//reset error when select
			setCommonError({
				isError: false,
				errorMessage: '',
			});
		}
		if (fieldName === 'applyFor' && values.applyFor !== value) {
			values.applyFor = value; // because setFieldValue above is async so we have to this value manually
			setFormState(values);
		}
	};
	const handleBlur = (fieldName, setFieldTouched, value = true) => {
		setFieldTouched(fieldName, value);
	};

	const filterOldSessions = (sessions, showOldSessions) => {
		if (showOldSessions) {
			return sessions;
		}

		return sessions.map((s) => ({
			...s,
			isHidden: s.inThePast || s.value === -1 ? true : false,
		}));
	};

	const emptyText = (sessions) => {
		if (sessions.find((item) => item.category === 'Old sessions' && item.value === -1)) {
			return 'You don’t have any old sessions in this course.';
		}
		if (sessions.find((item) => item.category === 'Upcoming sessions' && item.value === -1)) {
			return 'You don’t have any upcoming sessions in this course.';
		}
		return '';
	};

	const convertDataToSubmit = (values, setSubmitting) => {
		let payload = {};
		if (values.applyFor === APPLY_FOR.CUSTOM) {
			const { sections } = values;
			const sectionsPayload = [];
			if (sections?.length) {
				payload['inThePast'] = false;
				sections.forEach(({ select, assignDate, dueDate, id, sectionDisabled }) => {
					if (select && !sectionDisabled) {
						const _assignDate = pick(assignDate, ['value']);
						const _dueDate = pick(dueDate, ['value']);

						sectionsPayload.push({
							id,
							assignDate: _assignDate.value === PUBLISH_NOW ? { value: assignDate.start || moment() } : _assignDate,
							dueDate: _dueDate,
						});
						if (dueDate.inThePast) {
							payload['inThePast'] = true;
						}
					}
				});
			}
			if (!sectionsPayload.length) {
				setSubmitting(false);
				setCommonError({
					isError: true,
					errorMessage: t('error:please_select_section'),
				});
				return;
			}
			payload['data'] = sectionsPayload;
		} else {
			const { assignDate, dueDate } = values;
			if (assignDate && dueDate) {
				/** TODO: need to change the variable name for more meaningful */
				const assignDateId = assignDate.value === PUBLISH_NOW ? moment() : assignDate.value;
				const dueDateId = dueDate.value;
				payload['data'] = {
					assignDateId,
					dueDateId,
				};
				if (dueDate.inThePast) {
					payload['inThePast'] = true;
				} else {
					payload['inThePast'] = false;
				}
			}
		}
		payload['applyFor'] = values.applyFor;
		payload['showOldSessions'] = values.showOldSessions;
		return payload;
	};

	const handleCancelModalCustomDue =
		({ setSubmitting }) =>
		() => {
			setSubmitting(false);
			setOpenConfirmModal(false);
			dispatch(
				myCoursesActions.myCoursesSetState({
					mcConsolidateAssignmentNeedConfirm: false,
					studentHasCustomDue: [],
				}),
			);
		};

	const compareSchedule = ({ originData, updateData }) => {
		if (!updateData.length) {
			return false;
		}
		let isDifferent = false;
		updateData.forEach((item) => {
			const dataCompare = originData.find((section) => section.id === item.id);

			if (dataCompare.assignDate.value !== item.assignDate.value || dataCompare.dueDate.value !== item.dueDate.value) {
				isDifferent = true;
				return;
			}
		});
		return isDifferent;
	};

	const onSubmit = (payload) => {
		if (!payload) return;
		switch (type) {
			case ACTIVITY_CATEGORY.ASSIGNMENT:
				if (method === DIALOG_TYPE.SCHEDULE) {
					dispatch(
						myCoursesActions.mcConsolidateAssignment({
							orgId: organizationId,
							courseId,
							unitId,
							masterId: activityId,
							scheduleInThePast: payload?.inThePast,
							body: {
								...payload,
								confirmStudentCustomDue,
							},
							method: DIALOG_TYPE.SCHEDULE,
						}),
					);
				} else if (method === DIALOG_TYPE.RESCHEDULE) {
					dispatch(
						myCoursesActions.mcConsolidateAssignment({
							orgId: organizationId,
							courseId,
							unitId,
							masterId: activityId,
							scheduleInThePast: payload?.inThePast,
							body: {
								...payload,
								confirmStudentCustomDue,
							},
							method: DIALOG_TYPE.RESCHEDULE,
						}),
					);
				}
				break;

			case ACTIVITY_CATEGORY.TEST:
				if (method === DIALOG_TYPE.SCHEDULE) {
					dispatch(
						myCoursesActions.mcConsolidateTest({
							orgId: organizationId,
							courseId,
							unitId,
							masterId: activityId,
							scheduleInThePast: payload?.inThePast,
							body: {
								...payload,
							},
							method: DIALOG_TYPE.SCHEDULE,
						}),
					);
				} else if (method === DIALOG_TYPE.RESCHEDULE) {
					dispatch(
						myCoursesActions.mcConsolidateTest({
							orgId: organizationId,
							courseId,
							unitId,
							masterId: activityId,
							scheduleInThePast: payload?.inThePast,
							body: {
								...payload,
							},
							method: DIALOG_TYPE.RESCHEDULE,
						}),
					);
				}
				break;

			default:
				break;
		}

		updateUnit && updateUnit(unitId);
	};

	const publishNowOnAll = (e, props) => {
		const { value, item, onChange } = props;
		e.preventDefault(); // prevent blur
		if (value?.index === item.index) {
			//   setDisableAllOptions(false);
			onChange && onChange(null);
			return;
		}
		if (onChange) {
			//   setDisableAllOptions(true);
			onChange(item);
		}
	};

	const renderAllSections = (errors, values, touched, setFieldValue, setFieldTouched) => {
		switch (type) {
			case ACTIVITY_CATEGORY.ASSIGNMENT:
			case ACTIVITY_CATEGORY.TEST:
				return (
					<Box display='flex' gap={2}>
						<Field
							component={Dropdown}
							disableCloseOnSelect={false}
							onClose={() => {
								handleBlur('assignDate', setFieldTouched);
							}}
							label={assignLabel}
							width={'100%'}
							name='assignDate'
							multiple={false}
							// open={true}
							helperText={
								!!(
									touched?.assignDate &&
									errors?.assignDate &&
									errors?.assignDate !== t('uneven_sessions', { ns: 'error' })
								) && errors?.assignDate
							}
							// disabled={checkPastSections(values?.assignDate?.sectionSchedules)}
							error={!!(touched?.assignDate && errors?.assignDate)}
							placeholder={t('common:please_select')}
							value={values?.assignDate}
							fitContent={true}
							groupBy={(option) => values.showOldSessions && option.category}
							placement={'bottom-start'}
							options={filterOldSessions(assignSessions, values.showOldSessions)}
							disabled={hasPublished}
							onChange={(value) => {
								handleChange('assignDate', value, setFieldValue, values);
								if (!value) {
									handleBlur('assignDate', setFieldTouched);
								}
							}}
							stickyOptions={headerOptions(type)(publishNowOnAll, 0)} // 0 becasue all sections just have one to identify
							disableAllOption={values?.assignDate?.value === PUBLISH_NOW}
							emptyGroupText={emptyText(assignSessions)}
						/>
						<Field
							component={Dropdown}
							disableCloseOnSelect={false}
							fitContent={true}
							groupBy={(option) => values.showOldSessions && option.category}
							placement={'bottom-start'}
							label={dueLabel}
							width={'100%'}
							name='dueDate'
							multiple={false}
							onClose={() => {
								handleBlur('dueDate', setFieldTouched);
							}}
							disabled={hasPublished}
							helperText={
								!!(
									touched?.dueDate &&
									errors?.dueDate &&
									errors?.dueDate !== t('has_section_in_the_past', { ns: 'error', dueOn: dueLabel.toLowerCase() }) &&
									errors?.dueDate !== t('uneven_sessions', { ns: 'error' })
								) && errors?.dueDate
							}
							error={!!(touched?.dueDate && errors?.dueDate) || commonError.isError}
							placeholder={t('common:please_select')}
							value={values?.dueDate}
							options={filterOldSessions(dueSessions, values.showOldSessions)}
							onChange={(value) => {
								handleChange('dueDate', value, setFieldValue, values);
								if (!value) {
									handleBlur('dueDate', setFieldTouched);
								}
							}}
							emptyGroupText={emptyText(dueSessions)}
						/>
					</Box>
				);
			default:
				return;
		}
	};

	const renderCustomizeSections = (
		errors,
		values,
		touched,
		setValues,
		isSubmitting,
		setFieldValue,
		setFieldTouched,
	) => {
		switch (type) {
			case ACTIVITY_CATEGORY.ASSIGNMENT:
			case ACTIVITY_CATEGORY.TEST:
				return (
					<SectionSchedule
						assignLabel={assignLabel}
						dueLabel={dueLabel}
						errors={errors}
						touched={touched}
						handleBlur={handleBlur}
						handleChange={handleChange}
						sessions={sessions}
						// gradingPeriodId={gradingPeriodId}
						sections={values?.sections}
						values={values}
						activityType={type}
						setValues={setValues}
						isSubmitting={isSubmitting}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						showOldSessions={values.showOldSessions}
						emptyText={emptyText}
						headerOptions={headerOptions(type)}
					/>
				);
			default:
				return;
		}
	};
	const renderCommonError = (errors) => {
		const dueErrorMsg =
			errors?.dueDate === t('has_section_in_the_past', { ns: 'error', dueOn: dueLabel.toLowerCase() }) ||
			errors?.dueDate === t('uneven_sessions', { ns: 'error' })
				? errors?.dueDate
				: '';

		const assignErrorMsg = errors?.assignDate === t('uneven_sessions', { ns: 'error' }) ? errors?.assignDate : '';

		const commonErrMsg = commonError.errorMessage;

		if (!dueErrorMsg && !assignErrorMsg && !commonErrMsg) return null;

		return <TblFormHelperText errorMessage={commonErrMsg || dueErrorMsg || assignErrorMsg} />;
	};

	const checkDisableDone = (values) => {
		const { sections } = values;
		if (values?.applyFor === APPLY_FOR.CUSTOM && !sections.find((section) => section.select)) {
			return true;
		}
		return false;
	};

	const contentComponent = ({
		touched,
		errors,
		type,
		name,
		hasPublished,
		values,
		handleChange,
		setFieldValue,
		isFetchingSectionsList,
		renderCustomizeSections,
		renderAllSections,
		renderCommonError,
		commonError,
		setValues,
		isSubmitting,
		setFieldTouched,
	}) => {
		return (
			<>
				<Header>
					<HeaderTitle>
						<TblActivityIcon type={type} variant='titleSmall' name={name} />
					</HeaderTitle>
					{type === ACTIVITY_CATEGORY.TEST && (
						<Typography variant='bodySmallRegular'>{t('schedule_test_description', { ns: 'myCourses' })}</Typography>
					)}
				</Header>

				<Content>
					<Form>
						<Radio
							label={t('apply_for')}
							name='applyFor'
							options={
								hasPublished
									? APPLY_SECTIONS.map((i) => {
											if (i.value === APPLY_FOR.ALL) {
												return {
													...i,
													disabled: true,
												};
											}
											return i;
									  })
									: APPLY_SECTIONS
							}
							value={values.applyFor}
							onChange={(value) => {
								handleChange('applyFor', Number(value), setFieldValue, values);
							}}
						/>
						{hasPublished && (
							<Box
								display={'flex'}
								gap={1}
								mt={1}
								mb={3}
								justifyContent={'flex-start'}
								color={(theme) => theme.newColors.gray[500]}
							>
								<TblIcon icon='info' />
								<Typography variant='bodyMediumRegular'>{t('guide_text', { ns: 'myCourses' })}</Typography>
							</Box>
						)}

						<Box className='scheduleSection'>
							{values.applyFor === APPLY_FOR.CUSTOM ? (
								isFetchingSectionsList ? (
									<ScheduleCustomSkeleton type={type} />
								) : (
									<FieldArray name='sections'>
										{renderCustomizeSections(
											errors,
											values,
											touched,
											setValues,
											isSubmitting,
											setFieldValue,
											setFieldTouched,
										)}
									</FieldArray>
								)
							) : isFetchingSectionsList ? (
								<ScheduleAllSectionsSkeleton type={type} />
							) : (
								renderAllSections(errors, values, touched, setFieldValue, setFieldTouched)
							)}
						</Box>
					</Form>
				</Content>
				{(commonError.isError ||
					!!(touched?.dueDate && errors?.dueDate) ||
					!!(touched?.assignDate && errors?.assignDate)) &&
					renderCommonError(errors)}
			</>
		);
	};

	const renderStudentName = (student) => {
		const { firstName, lastName, sectionName } = student;
		return `${firstName} ${lastName} - ${sectionName}`;
	};
	const renderBodyCustomDue = () => {
		const sectionNames = [...new Set((studentHasCustomDue || []).map((item) => item.sectionName))];
		return (
			<Box>
				<Typography variant=''>
					<Trans
						i18nKey='myCourses' // optional -> fallbacks to defaults if not provided
						defaults={t('the_changed_schedule_applied_content', { sectionName: sectionNames.join(', ') })} // optional defaultValue
					/>
				</Typography>
				{(studentHasCustomDue || []).map((student) => (
					<Typography>
						<span style={{ padding: '0 8px' }}>•</span>
						{` ${renderStudentName(student)}`}
					</Typography>
				))}
			</Box>
		);
	};

	return (
		<>
			{mcConsolidateAssignmentFailed && <TblUseSnackbar message={error?.message} options={{ variant: 'error' }} />}
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				validateOnChange={true}
				validateOnBlur={false}
				validateOn
				onSubmit={(value, actions) => {
					if (commonError.isError) {
						actions.setSubmitting(false);
						return;
					}
					onSubmit(convertDataToSubmit(value, actions.setSubmitting));
				}}
			>
				{({
					errors,
					handleSubmit,
					values,
					touched,
					setFieldValue,
					setFieldTouched,
					setValues,
					isSubmitting,
					setSubmitting,
				}) => {
					return (
						<>
							<BaseModal
								open={isVisible}
								size={'large'}
								onClose={onClose}
								title={t('myCourses:schedule_activity')}
								labelPrimaryBtn={t('done', { ns: 'myCourses' })}
								labelSecondaryBtn={t('cancel', { ns: 'common' })}
								showCheckBox={true}
								onCheckBox={(value) => handleChange('showOldSessions', value, setFieldValue, values)}
								labelCheckBox={t('show_old_sessions', { ns: 'myCourses' })}
								isCheckedCheckBox={values.showOldSessions}
								disabledPrimaryBtn={(isSubmitting && !mcConsolidateAssignmentFailed) || checkDisableDone(values)}
								disabledSecondaryBtn={isSubmitting && !mcConsolidateAssignmentFailed}
								loadingBtn={(isSubmitting && !mcConsolidateAssignmentFailed) || mcConsolidateAssignmentNeedConfirm}
								onAction={handleSubmit}
								contentComponent={contentComponent({
									touched,
									errors,
									type,
									name,
									hasPublished,
									values,
									handleChange,
									setFieldValue,
									isFetchingSectionsList,
									renderCustomizeSections,
									renderAllSections,
									renderCommonError,
									commonError,
									setValues,
									isSubmitting,
									setFieldTouched,
								})}
							/>

							<BaseModal
								open={openConfirmModal}
								title={t('myCourses:the_changed_schedule_applied_title')}
								onClose={handleCancelModalCustomDue({ setSubmitting })}
								customFooter={
									<Box className='customFooterModal'>
										<Button variant='outlined' onClick={handleCancelModalCustomDue({ setSubmitting })}>
											{t('common:cancel')}
										</Button>
										<Button
											onClick={() => {
												setConfirmStudentCustomDue(true);
												setOpenConfirmModal(false);
												handleSubmit();
											}}
										>
											{t('common:continue')}
										</Button>
									</Box>
								}
								contentComponent={renderBodyCustomDue()}
							/>
						</>
					);
				}}
			</Formik>
		</>
	);
}

ScheduleDialog.propTypes = {
	activity: PropTypes.object,
	// gradingPeriodId: PropTypes.number,
	type: PropTypes.oneOf(Object.values(ACTIVITY_CATEGORY)),
	showOldSessions: PropTypes.bool,
};

ScheduleDialog.defaultProps = {
	type: ACTIVITY_CATEGORY.ASSIGNMENT,
	shadowId: null,
	showOldSessions: false,
	shouldResetState: true,
};
