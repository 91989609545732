import { CircularProgress } from '@mui/material';

import { Box, Typography } from 'ella-storybook';

const Loading = ({ title }) => (
	<Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
		<Box>
			<CircularProgress size={20} color='primary' />
		</Box>
		<Typography component={'div'} variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[800] }}>
			{title}
		</Typography>
	</Box>
);

export default Loading;
