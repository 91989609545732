import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { Routing } from 'utils/middlewareRoute';
import { isGuardian } from 'utils/roles';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

import { EmptyStudentPage } from './components/ErrorPage';
import { ROUTE_TO_DO } from './constantsRoute';
import reducer from './reducers';

const ToDoList = loadable(() => import(/* webpackChunkName: "LazyToDoList" */ './containers/ToDoContainer'));
const TaskDetail = loadable(() =>
	import(/* webpackChunkName: "LazyTaskDetail" */ 'modules/MyTasks/containers/TaskDetail'),
);

export default [
	// {
	// 	path: ROUTE_TO_DO.DEFAULT,
	// 	component: withReducer('MyTodos', reducer)(ToDoList),
	// 	private: true,
	// 	exact: true,
	// 	menu: {
	// 		title: 'To Do',
	// 		group: 'noname',
	// 		icon: IcnMenu,
	// 		order: 1,
	// 	},
	// 	permissions: [PERMISSIONS.COURSE_ACTIVITY.READ_PUBLIC],
	// 	moduleAlias: MODULE_ALIAS.ACTIVITY,
	// 	},
	{
		path: [
			ROUTE_TO_DO.DEFAULT,
			ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS(':studentId'),
			ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT(),
		],
		component: withReducer(
			'MyTodos',
			reducer,
		)(
			Routing(
				{
					defaultPath: ROUTE_TO_DO.DEFAULT,
				},
				{
					pathWithQuery: ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS(':studentId'),
					redirectToThis: (studentId) => ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS(studentId),
					DefaultComponent: ToDoList,
				},
				{
					errorPath: ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT(),
					errorRedirect: () => ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT(),
					ErrorComponent: EmptyStudentPage,
				},
				ToDoList,
			),
		),
		permissions: [PERMISSIONS.COURSE.READ_PUBLIC],
		moduleAlias: MODULE_ALIAS.COURSE,
		// menu: {
		// 	title: 'To Do',
		// 	group: 'noname',
		// 	icon: IcnMenu,
		// 	order: 1,
		// },
		exact: true,
		private: true,
		getSelectedPath: (context) => {
			if (isGuardian(context?.currentUser)) {
				if (!!context?.currentStudentId) {
					return ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS(context.currentStudentId);
				}
				return ROUTE_TO_DO.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT();
			}
			return ROUTE_TO_DO.DEFAULT;
		},
	},
	{
		path: ROUTE_TO_DO.TASK_DETAIL(':taskId'),
		component: withReducer('MyTasks', reducer)(TaskDetail, {
			fromAnotherModule: { from: 'MyTodos', overrideRoute: ROUTE_TO_DO },
		}),
		private: true,
		exact: true,
		permissions: [PERMISSIONS.COURSE_ACTIVITY.READ_PUBLIC],
		moduleAlias: MODULE_ALIAS.COURSE_ACTIVITY,
	},
];
