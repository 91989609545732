import { memo } from 'react';

import TblIcon from 'components/TblIcon';

import { Button } from 'ella-storybook';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import FilterArea from './FilterArea';

const ActionBar = ({
	translate,
	editLabel,
	sections,
	selectedSection,
	hasFilter,
	searchValueInput,
	handleEdit,
	handleSearch,
	handleSelectSection,
}) => {
	return (
		<>
			<FilterArea
				{...{ translate, hasFilter, sections, searchValueInput, selectedSection, handleSearch, handleSelectSection }}
			/>

			<Button
				size='large'
				variant='outlined'
				startIcon={<TblIcon icon='edit' />}
				// disabled={studentView}
				onClick={handleEdit}
				sx={{ alignSelf: 'flex-start' }}
			>
				{translate(editLabel, { ns: 'common' })}
			</Button>
		</>
	);
};

ActionBar.propTypes = {
	editLabel: PropTypes.any,
	handleEdit: PropTypes.any,
	handleSearch: PropTypes.any,
	handleSelectSection: PropTypes.any,
	hasFilter: PropTypes.any,
	searchValueInput: PropTypes.any,
	sections: PropTypes.any,
	selectedSection: PropTypes.any,
	translate: PropTypes.func,
};

export default memo(ActionBar, isEqual);
