const ROUTE_TO_DO = {
	DEFAULT: '/my-todos',

	GUARDIAN_VIEW_MY_TASKS: (studentId) => `/my-todos/guardian/${studentId}`,
	GUARDIAN_VIEW_MY_TASKS_NO_STUDENT: () => '/my-todos/guardian',

	TASK_DETAIL: (taskId) => `/my-todos/${taskId}`,
};

export { ROUTE_TO_DO };
