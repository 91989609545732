export default {
	mc_get_grade_weight: {
		url: (id, courseId) =>
			`${process.env.REACT_APP_API_URL}/organization/${id}/courses/${courseId}?attribute=gradeWeight`,
		method: 'GET',
	},
	mc_update_grade_weight: {
		url: (id, courseId) =>
			`${process.env.REACT_APP_API_URL}/organization/${id}/courses/${courseId}?attribute=gradeWeight`,
		method: 'PUT',
	},

	// New endpoint
	get_compare_grade_weight_categories: {
		url: (id, courseId) =>
			`${process.env.REACT_APP_API_URL}/organization/${id}/courses/${courseId}/gradeWeight/compare`,
		method: 'GET',
	},
	grade_weight_categories: {
		url: (id, courseId) => `${process.env.REACT_APP_API_URL}/organization/${id}/courses/${courseId}/gradeWeight`,
	},
	publish_grade_weight_categories: {
		url: (id, courseId) =>
			`${process.env.REACT_APP_API_URL}/organization/${id}/courses/${courseId}/gradeWeight/publish`,
		method: 'POST',
	},
};
