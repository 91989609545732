import React from 'react';

import PropTypes from 'prop-types';
import { insertTable } from 'roosterjs-editor-api';
import { withStyles } from 'tss-react/mui';

import InsertTablePane from './InsertTablePane';
import styles from './styled';

class TableOptions extends React.PureComponent {
	onInsertTable = (cols, rows) => {
		this.props.onDismiss();
		if (cols > 0 && rows > 0) {
			insertTable(this.props.editor, cols, rows);
		}
	};
	isAllowedInput = (values) => {
		const { floatValue, formattedValue } = values;
		return formattedValue === '' || floatValue > 0;
	};

	handleClick = (e, obj) => {
		const { key } = obj;
		this.onInsertTable(key.cols, key.rows);
	};

	render() {
		const item = {
			text: '0 x 1',
		};

		return <InsertTablePane item={item} onClick={this.handleClick} />;
	}
}
TableOptions.propTypes = {
	classes: PropTypes.object,
	editor: PropTypes.object,
	onDismiss: PropTypes.func,
};
TableOptions.defaultProps = {};
const FormTable = withStyles(TableOptions, styles);

export default function renderTableOptions(editor, onDismiss) {
	return <FormTable editor={editor} onDismiss={onDismiss} />;
}
