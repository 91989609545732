import { objectToParams } from 'utils';

export const actions = {
	MEDIA_SET_STATE: 'MEDIA_SET_STATE',
	MEDIA_FETCH: 'MEDIA_FETCH',
	MEDIA_FETCH_SUCCESS: 'MEDIA_FETCH_SUCCESS',
	MEDIA_FETCH_FAILED: 'MEDIA_FETCH_FAILED',
	MEDIA_FETCH_IMAGES: 'MEDIA_FETCH_IMAGES',
	MEDIA_FETCH_IMAGES_SUCCESS: 'MEDIA_FETCH_IMAGES_SUCCESS',
	MEDIA_FETCH_IMAGES_FAILED: 'MEDIA_FETCH_IMAGES_FAILED',
	MEDIA_DELETE: 'MEDIA_DELETE',
	MEDIA_DELETE_SUCCESS: 'MEDIA_DELETE_SUCCESS',
	MEDIA_DELETE_FAILED: 'MEDIA_DELETE_FAILED',

	GET_COURSE_DEFAULT_MEDIA: 'GET_COURSE_DEFAULT_MEDIA',
	GET_COURSE_DEFAULT_MEDIA_SUCCESS: 'GET_COURSE_DEFAULT_MEDIA_SUCCESS',
	GET_COURSE_DEFAULT_MEDIA_FAILED: 'GET_COURSE_DEFAULT_MEDIA_FAILED',

	GET_PRESIGNED_URL: 'GET_PRESIGNED_URL',
	GET_PRESIGNED_URL_SUCCESS: 'GET_PRESIGNED_URL_SUCCESS',
	GET_PRESIGNED_URL_FAILED: 'GET_PRESIGNED_URL_FAILED',

	UPLOAD_TO_S3: 'UPLOAD_TO_S3',
	UPLOAD_TO_S3_SUCCESS: 'UPLOAD_TO_S3_SUCCESS',
	UPLOAD_TO_S3_FAILED: 'UPLOAD_TO_S3_FAILED',

	UPDATE_TO_MEDIA: 'UPDATE_TO_MEDIA',
	UPDATE_TO_MEDIA_SUCCESS: 'UPDATE_TO_MEDIA_SUCCESS',
	UPDATE_TO_MEDIA_FAILED: 'UPDATE_TO_MEDIA_FAILED',

	UPLOAD_SINGLE_FILE: 'UPLOAD_SINGLE_FILE',
	UPLOAD_SINGLE_FILE_SUCCESS: 'UPLOAD_SINGLE_FILE_SUCCESS',
	UPLOAD_SINGLE_FILE_FAILED: 'UPLOAD_SINGLE_FILE_FAILED',
};

// Define media type, we can add more in the future
export const MEDIA_TYPES = {
	OFFICE: [
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/pdf',
		'application/vnd.oasis.opendocument.text',
		'application/vnd.oasis.opendocument.spreadsheet',
		'application/vnd.oasis.opendocument.presentation',
		'application/rtf',
	],
	IMAGES: ['image/jpg', 'image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/ico'],
	AUDIO: ['audio/mpeg', 'audio/mp3', 'audio/ogg', 'audio/wav', 'video/ogg', 'audio/x-wav'],
	VIDEO: ['video/mp4', 'video/mpeg', 'video/webm', 'video/x-msvideo'],
	FILE_UPLOADED: 1,
	GOOGLE_DRIVE_FILE: 2,
};
export const MEDIA_TYPES_KEYS = {
	OFFICE: 'OFFICE',
	IMAGES: 'IMAGES',
	AUDIO: 'AUDIO',
	VIDEO: 'VIDEO',
	FILE_UPLOADED: 1,
	GOOGLE_DRIVE_FILE: 2,
};
// Define ext
export const EXT = {
	OFFICE: ['.doc', '.docx', '.odt', '.xls', '.xlsx', '.ppt', '.pptx', '.pps', '.ods', '.wps', '.wpd', '.pdf', '.rtf'],
	IMAGES: ['.jpg', '.jpeg', '.bmp', '.png', '.ico', '.gif', '.tif', '.tiff', '.svg', '.ps', '.psd', '.ai'],
	AUDIO: ['.mp3', '.ogg', '.wav', '.cda', '.aif', '.mid', '.mpa', '.wma', '.wpl'],
	VIDEO: [
		'.mp4',
		'.webm',
		'.mpg',
		'.mpeg',
		'.rm',
		'.vob',
		'.swf',
		'.3g2',
		'.3gp',
		'.avi',
		'.flv',
		'h264',
		'.m4v',
		'.mov',
		'.mkv',
		'.wmv',
	],
};

export const END_POINT = {
	fetch_images: {
		url: `${process.env.REACT_APP_API_URL}/media/images`,
		method: 'GET',
	},
	fetch_media: {
		url: `${process.env.REACT_APP_API_URL}/media`,
		method: 'GET',
	},
	delete_media: {
		url: (mediaId) => `${process.env.REACT_APP_API_URL}/library/${mediaId}`,
		method: 'DELETE',
	},
	get_media: {
		url: (urlParams) => `${process.env.REACT_APP_API_URL_V2}/media?${objectToParams(urlParams)}`,
		method: 'GET',
	},
	get_presigned_url: {
		url: (urlParams) =>
			`${process.env.REACT_APP_API_URL_V2}/media/presigned-url/upload/single?${objectToParams(urlParams)}`,
		method: 'GET',
	},
	update_media: {
		url: () => `${process.env.REACT_APP_API_URL_V2}/media/update`,
		method: 'POST',
	},
};
