export default {
	listUnitGroup: [],
	isGettingUnitsGroupByGradingPeriod: false,

	allActivitiesByUnitId: [],
	isGettingAllActivitiesByUnitId: false,

	// activity details
	isFetchingCourseActivityDetails: false,
	courseActivityDetails: null,

	activitiesReschedule: [],
	totalActivitiesReschedule: 0,
	sectionsActivityReschedule: [],
	sessionsActivityReschedule: [],
	isGettingCoursesNeedReschedule: false,

	triggerModalUpdateCourseSchedule: false,
	selectedCourseToReschedule: null,
	hasProcessedAll: true,
	necessaryFetchCourses: { value: true },
	sectionKeys: [],
	arrUpdatedActivities: [],
};
