import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import compose from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

import TblAppBar from 'components/TblAppBar';
import TblIcon from 'components/TblIcon';
import TblSidebar from 'components/TblSidebar';
import TblSidebarLogo from 'components/TblSidebarLogo';
import withMediaQuery from 'components/TblWithMediaQuery';

import useGetSchoolYear from 'utils/customHook/useGetSchoolYear';
import useLocalStorage from 'utils/customHook/useLocalStorage';
import { isGuardian, isTeacher } from 'utils/roles';

import CommonActions from 'shared/Common/actions';
import { USER_BEHAVIOR } from 'shared/User/constants';

import { AuthDataContext, useAuthDataContext } from 'AppRoute/AuthProvider';
import clsx from 'clsx';
import { Button, Dialog, Typography } from 'ella-storybook';
import { isEqual } from 'lodash';
import myCoursesActions from 'modules/MyCourses/actions';
import ConnectGoogleDialog from 'modules/MyCourses/containers/ConnectGoogleDialog';
import myProfileActions from 'modules/MyProfile/actions';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import Breadcumb from '../components/TblBreadcrumb';

import ToggleInfo from './components/ToggleInfo';
import UpdateScheduleModal from './containers/UpdateScheduleContainer';
import { useLayoutContext } from './LayoutContext';
import styles from './styled';

const Layout = ({ children, mediaQuery, classes, routes, location, t, handleTriggerModal, setOpenZella }) => {
	const dispatch = useDispatch();

	const [windowSizeLg, setWindowSizeLg] = useState(mediaQuery);
	const [openSideBar, setOpenSideBar] = useLocalStorage('openSideBarStatus', false);

	const { schoolYearSelected, setSchoolYearSelected } = useGetSchoolYear();
	const selectedCourseToReschedule = useSelector((state) => state?.MyCourses?.selectedCourseToReschedule);
	const arrCourseSchedule = useSelector(
		(state) => state?.MyCourses?.coursesNeedReschedule || state?.MyCourses?.courseNeedScheduleFromGetCourseList,
	);
	const triggerModalUpdateCourseSchedule = useSelector((state) => state?.MyCourses?.triggerModalUpdateCourseSchedule);
	const hasProcessedAll = useSelector((state) => state?.MyCourses?.hasProcessedAll);
	const isPolling = useSelector((state) => state?.MyCourses?.isPolling);

	const [openUpdateSchedule, setOpenUpdateSchedule] = useState(false);

	const { isPublic, fullScreen } = useLayoutContext();
	const authContext = useAuthDataContext();
	const { currentUser } = authContext;
	const settings = currentUser?.settings;

	const [warningBannerElementInfo, setWarningBannerElementInfo] = useState(null);
	useEffect(() => {
		if (warningBannerElementInfo) return;

		const warningBanner = document.getElementById('need-update-schedule-after-school-year-change');

		if (!warningBanner) return;

		const clientHeight = warningBanner.clientHeight;
		const clientWidth = warningBanner.clientWidth;

		const info = { clientHeight, clientWidth };

		setWarningBannerElementInfo((prev) => (isEqual(prev, info) ? prev : info));
	});

	useEffect(() => {
		if (!warningBannerElementInfo) return;
		dispatch(
			CommonActions.commonSetState({
				warningBannerInfo: warningBannerElementInfo,
			}),
		);
	}, [warningBannerElementInfo]);

	useEffect(() => {
		setOpenSideBar((prev) => (!windowSizeLg ? false : prev));
		setWindowSizeLg(mediaQuery);

		localStorage.setItem('openSideBarStatus', !windowSizeLg ? false : openSideBar);
	}, []);
	useEffect(() => {
		if (isTeacher(currentUser) && currentUser?.organizationId && schoolYearSelected) {
			dispatch(
				myCoursesActions.getCoursesNeedReschedule({
					id: currentUser?.organizationId,
					schoolYearId: schoolYearSelected,
					isGettingCoursesNeedReschedule: true,
				}),
			);
		}
	}, [currentUser, schoolYearSelected]);

	useEffect(() => {
		if (currentUser) {
			const haveAccessed = settings?.behavior?.includes(USER_BEHAVIOR.HAVE_RESCHEDULE_COURSES);
			if (!haveAccessed) {
				dispatch(
					myCoursesActions.myCoursesSetState({
						isPolling: false,
					}),
				);
			}

			if (
				(haveAccessed || triggerModalUpdateCourseSchedule) &&
				isTeacher(currentUser) &&
				currentUser.organizationId &&
				schoolYearSelected
			) {
				if (triggerModalUpdateCourseSchedule) {
					// manual open from user dont need to check status because it must have been done
					setOpenUpdateSchedule(true);
					return;
				}
				dispatch(
					myCoursesActions.pollingGenerateStatus({
						organizationId: currentUser.organizationId,
						schoolYearId: schoolYearSelected,
						isPolling: true,
					}),
				);

				dispatch(
					myCoursesActions.myCoursesSetState({
						triggerModalUpdateCourseSchedule: false,
					}),
				);
			}
		}
	}, [currentUser, schoolYearSelected, triggerModalUpdateCourseSchedule, selectedCourseToReschedule]);

	useEffect(() => {
		const courseNeedReschedule = localStorage.getItem('courseNeedReschedule');
		const haveAccessed = settings?.behavior?.includes(USER_BEHAVIOR.HAVE_RESCHEDULE_COURSES);
		if (((!isPolling && hasProcessedAll) || courseNeedReschedule) && isTeacher(currentUser) && haveAccessed) {
			setOpenUpdateSchedule(true);
		}
	}, [isPolling, hasProcessedAll, settings]);

	const toggleSideBar = () => {
		setOpenSideBar((prev) => !prev);
		setWindowSizeLg(mediaQuery);
		localStorage.setItem('openSideBarStatus', !openSideBar);
	};

	const handleCloseUpdateSchedule = () => {
		//Update setting behavior (remove USER_BEHAVIOR.HAVE_RESCHEDULE_COURSES)
		const haveAccessed = settings?.behavior?.includes(USER_BEHAVIOR.HAVE_RESCHEDULE_COURSES);
		if (haveAccessed) {
			const payload = {
				settings: {
					...settings,
					behaviorRemove: [USER_BEHAVIOR.HAVE_RESCHEDULE_COURSES],
				},
			};
			dispatch(myProfileActions.updateMyProfile(payload));
		}

		if (currentUser?.organizationId && schoolYearSelected && isTeacher(currentUser)) {
			dispatch(
				myCoursesActions.getCoursesNeedReschedule({
					id: currentUser?.organizationId,
					schoolYearId: schoolYearSelected,
					isGettingCoursesNeedReschedule: true,
				}),
			);
		}

		setOpenUpdateSchedule(false);
		localStorage.removeItem('courseNeedReschedule');
	};

	if (isPublic) {
		return <>{children}</>;
	}
	if (fullScreen) {
		return <>{children}</>;
	}
	return (
		<AuthDataContext.Consumer>
			{(value) => {
				const { currentUser } = value;

				const haveSetupConnectGoogle = currentUser?.settings?.behavior?.includes(
					USER_BEHAVIOR.HAVE_SET_UP_CONNECT_GOOGLE,
				);

				const isShowConnectGoogleDialog =
					isTeacher(currentUser) && currentUser?.connectors.length === 0 && !haveSetupConnectGoogle;
				return (
					<>
						{openUpdateSchedule && (
							<UpdateScheduleModal
								open={openUpdateSchedule}
								onClose={handleCloseUpdateSchedule}
								organizationId={currentUser?.organizationId}
								selectedCourseToReschedule={selectedCourseToReschedule}
								{...{ schoolYearSelected }}
							/>
						)}
						{isTeacher(currentUser) && isPolling && (
							<Dialog
								open={!hasProcessedAll}
								hasCloseIcon={false}
								sx={{
									'& .MuiPaper-root': {
										width: 'auto',
									},
								}}
							>
								<Box
									display={'flex'}
									alignItems={'center'}
									justifyContent={'center'}
									flexDirection={'column'}
									height={336}
									width={496}
								>
									<CircularProgress size={40} />
									<Typography variant='bodyMediumRegular' color={(theme) => theme.newColors.gray[800]}>
										{t('common:please_wait_while_process_data')}
									</Typography>
								</Box>
							</Dialog>
						)}
						<Box display='flex' className={classes.root}>
							<Box
								className={clsx('sidebar-container', classes.layoutSidebar, {
									[classes.layoutSidebarClose]: !openSideBar,
								})}
							>
								<TblSidebarLogo
									routes={routes}
									openSideBar={!windowSizeLg && openSideBar}
									toggleSidebar={toggleSideBar}
								/>
								<TblSidebar routes={routes} openSideBar={windowSizeLg && openSideBar} />
							</Box>
							{!isEmpty(currentUser) && (
								<>
									<TblAppBar toggleSidebar={toggleSideBar} setOpenZella={setOpenZella} />
									<ConnectGoogleDialog
										isShowConnectGoogleDialog={isShowConnectGoogleDialog}
										currentUser={currentUser}
									/>
								</>
							)}
							<Box className={classes.layoutContent}>
								{isGuardian(currentUser) && <ToggleInfo className={classes.toggleInfo} />}
								{arrCourseSchedule?.length > 0 && (
									<Box className={classes.warningBanner} id='need-update-schedule-after-school-year-change'>
										<Box gap={1} sx={{ display: 'flex', flexDirection: 'row' }}>
											<TblIcon color={'#DC2626'} icon='info' />
											<Typography
												className={classes.contentWarningSchedule}
												variant='bodyMediumRegular'
												data-tut={'reactour__tblSideBarLogo'}
											>
												{t('tour:content_warning_update_schedule')}
											</Typography>
										</Box>
										<Button
											onClick={() => handleTriggerModal({ triggerModalUpdateCourseSchedule: true })}
											data-tut={'reactour__buttonViewAll'}
											variant='danger'
											id='reactour__buttonViewAll'
											sx={{ flex: '0 0 auto' }}
										>
											{t('tour:view_all')}
										</Button>
									</Box>
								)}
								<Breadcumb />
								{children}
							</Box>
						</Box>
					</>
				);
			}}
		</AuthDataContext.Consumer>
	);
};

Layout.propTypes = {
	classes: PropTypes.object,
	routes: PropTypes.array,
	isPublic: PropTypes.bool,
	children: PropTypes.node,
	location: PropTypes.object,
	setOpenZella: PropTypes.func,
};

const mapStateToProps = (state) => ({
	triggerModalUpdateCourseSchedule: state?.MyCourses?.triggerModalUpdateCourseSchedule,
	arrCourseSchedule: state?.MyCourses?.coursesNeedReschedule || state?.MyCourses?.courseNeedScheduleFromGetCourseList,
	selectedCourseToReschedule: state?.MyCourses?.selectedCourseToReschedule,
});

const mapDispatchToProps = (dispatch) => ({
	handleTriggerModal: (payload) => dispatch(myCoursesActions.myCoursesSetState(payload)),
});

export default compose(
	withTranslation(['calendar']),
	connect(mapStateToProps, mapDispatchToProps),
)(withRouter(withStyles(withMediaQuery('(min-width:1366px)')(Layout), styles)));
