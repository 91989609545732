import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

import { makeAjaxRequest } from 'utils/ajax';
import { TYPE_BLOCK_CALENDAR } from 'utils/constants';
import { COURSE_ROLE } from 'utils/roles';

import { ACTIVITY_CATEGORY, STUDENT_PROGRESS_STATUS } from 'modules/MyCourses/constants';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { generateCalendarSchedule } from 'utils';

import myTasksActions from './actions';
import { ATTEMPTS, END_POINT, actions } from './constants';
import { ordinal, standardizeTaskDetail } from './utils';

const getScheduledTasksEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHEDULED_TASKS),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_scheduled_tasks.method,
				END_POINT.get_scheduled_tasks.url(payload?.orgId, {
					...payload?.params,
					sort: 'desc',
					fieldSort: 'completedBy',
				}),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTasksActions.getScheduledTasksFailed({
								isFetchingScheduled: false,
							}),
						);
					}
					return of(
						myTasksActions.getScheduledTasksSuccess({
							scheduledTasks: data.response.items || [],
							isFetchingScheduled: false,
						}),
					);
				}),
				catchError(() =>
					of(
						myTasksActions.getScheduledTasksFailed({
							isFetchingScheduled: false,
						}),
					),
				),
			),
		),
	);

const getUnscheduledTasksEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_UNSCHEDULED_TASKS),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_unscheduled_tasks.method,
				END_POINT.get_unscheduled_tasks.url(payload?.orgId, {
					...payload?.params,
					sort: 'desc',
					fieldSort: 'completedBy',
				}),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTasksActions.getScheduledTasksFailed({
								isFetchingUnscheduled: false,
							}),
						);
					}
					return of(
						myTasksActions.getScheduledTasksSuccess({
							unscheduledTasks: data.response.items || [],
							isFetchingUnscheduled: false,
						}),
					);
				}),
				catchError(() =>
					of(
						myTasksActions.getScheduledTasksFailed({
							isFetchingUnscheduled: false,
						}),
					),
				),
			),
		),
	);

const getCompletedTasksEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COMPLETED_TASKS),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_completed_tasks.method,
				END_POINT.get_completed_tasks.url(payload?.orgId, {
					...payload?.params,
					sort: 'asc',
					fieldSort: 'completedBy',
				}),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTasksActions.getCompletedTasksFailed({
								isFetchingCompleted: false,
							}),
						);
					}
					return of(
						myTasksActions.getCompletedTasksSuccess({
							completedTasks: data.response.items || [],
							totalCompletedTasks: data.response.total || 0,
							isFetchingCompleted: false,
						}),
					);
				}),

				catchError(() =>
					of(
						myTasksActions.getCompletedTasksFailed({
							isFetchingCompleted: false,
						}),
					),
				),
			),
		),
	);

const getMissedTasksEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_MISSED_TASKS),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_missed_tasks.method,
				END_POINT.get_missed_tasks.url(payload?.orgId, {
					...payload?.params,
					sort: 'asc',
					fieldSort: 'completedBy',
				}),
			).pipe(
				mergeMap((data) =>
					of(
						myTasksActions.getMissedTasksSuccess({
							missedTasks: data.response.items || [],
							totalMissedTasks: data.response.total || 0,
							isFetchingMissed: false,
						}),
					),
				),
				catchError(() =>
					of(
						myTasksActions.getCompletedTasksFailed({
							isFetchingMissed: false,
						}),
					),
				),
			),
		),
	);

// const getDetailTaskEpic = (action$) =>
// 	action$.pipe(
// 		ofType(actions.GET_DETAIL_TASK),
// 		mergeMap(({ payload }) =>
// 			makeAjaxRequest(
// 				END_POINT.get_detail_task.method,
// 				END_POINT.get_detail_task.url(payload?.orgId, payload?.taskId, payload?.params),
// 			).pipe(
// 				mergeMap((data) => {
// 					if (data.response.errors) {
// 						return of(
// 							myTasksActions.getDetailTaskFailed({
// 								isFetchingDetailTask: false,
// 								errorDetailTask: data.response.errors.subcode,
// 							}),
// 						);
// 					}

// 					return of(
// 						myTasksActions.getDetailTaskSuccess({
// 							detailTask: data.response.task,
// 							isFetchingDetailTask: false,
// 							errorDetailTask: null,
// 						}),
// 						// myTasksActions.getStudentAttempts({
// 						//   orgId: payload?.orgId,
// 						//   params: {
// 						//     taskId: payload?.taskId,
// 						//   },
// 						//   isLimited: data.response.task?.activity?.attempts === ATTEMPTS.UNLIMITED,
// 						// })
// 					);
// 				}),
// 				catchError((error) =>
// 					of(
// 						CommonActions.commonSetState({
// 							subcodeError: error?.response?.errors?.subcode,
// 							isFetchingDetailTask: false,
// 						}),
// 						myTasksActions.getDetailTaskFailed({
// 							isFetchingDetailTask: false,
// 							errorDetailTask: error?.response?.errors,
// 						}),
// 					),
// 				),
// 			),
// 		),
// 	);

const getCalendarSchedulesEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_CALENDAR_SCHEDULES),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_calendar_schedules.method,
				END_POINT.get_calendar_schedules.url(payload?.payloadDataCalendar?.orgId, payload?.payloadDataCalendar?.params),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							myTasksActions.getCalendarSchedulesFailed({
								isFetchingCalendar: false,
								errorCalendar: data.response.errors,
							}),
						);
					}
					const { schedules, collision } = generateCalendarSchedule(
						[
							...data.response?.schedules,
							...(data.response?.tasks?.map((e) =>
								payload?.payloadDataCalendar?.taskRescheduled === e.id
									? { ...e, isIndicator: true, resizable: true }
									: e,
							) || []),
							...data.response?.curricularActivities,
						],
						{ resizable: true, disabledBlock: true },
						[TYPE_BLOCK_CALENDAR.TASK],
					);
					return of(
						myTasksActions.getCalendarSchedulesSuccess({
							isFetchingCalendar: false,
							errorCalendar: undefined,
							calendarSchedules: schedules,
							calendarAvailableTime: data.response?.availableTime,
							calendarStudyHall: data.response?.studyHall,
							calendarCollision: collision,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.getCalendarSchedulesFailed({
							isFetchingCalendar: false,
							errorCalendar: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getDailyCalendarSchedulesEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_DAILY_CALENDAR_SCHEDULES),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_calendar_schedules.method,
				END_POINT.get_calendar_schedules.url(
					payload?.payloadDataDailyCalendar?.orgId,
					payload?.payloadDataDailyCalendar?.params,
				),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							myTasksActions.getDailyCalendarSchedulesFailed({
								isFetchingDailyCalendar: false,
								errorDailyCalendar: data.response.errors,
							}),
						);
					}
					const { schedules } = generateCalendarSchedule(
						[
							...data.response?.schedules,
							...data.response?.tasks.map((e) => ({ ...e, resizable: true })),
							...data.response?.curricularActivities,
						],
						{ resizable: true, disabledBlock: true },
						[TYPE_BLOCK_CALENDAR.TASK],
					);
					return of(
						myTasksActions.getDailyCalendarSchedulesSuccess({
							isFetchingDailyCalendar: false,
							errorDailyCalendar: undefined,
							dailyCalendarSchedules: schedules,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.getDailyCalendarSchedulesFailed({
							isFetchingDailyCalendar: false,
							errorDailyCalendar: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getCalendarSchoolYearEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_CALENDAR_SCHOOL_YEAR),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_calendar_school_year.method,
				END_POINT.get_calendar_school_year.url(
					payload?.payloadData?.orgId,
					payload?.payloadData?.studentId,
					payload?.payloadData?.params,
				),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							myTasksActions.getCalendarSchoolYearFailed({
								isFetchingSchoolYear: false,
								error: data.response.errors,
							}),
						);
					}

					return of(
						myTasksActions.getCalendarSchoolYearSuccess({
							isFetchingSchoolYear: false,
							error: undefined,
							schoolYears: data.response?.schoolYear,
							currentSchoolYear: data.response?.currentSchoolYear,
							isGetCalendarSchoolYearSuccess: true,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.getCalendarSchoolYearFailed({
							isFetchingSchoolYear: false,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const createTaskEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_TASK),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.create_task.method,
				END_POINT.create_task.url(
					payload?.payloadData?.orgId,
					payload?.payloadData?.taskId,
					payload?.payloadData?.params,
				),
				payload?.payloadData?.body,
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							myTasksActions.createTaskFailed({
								isFetchingCreateTask: false,
								taskJustScheduled: [],
								errorCreateTask: data?.response,
							}),
						);
					}
					return of(
						myTasksActions.createTaskSuccess({
							isFetchingCreateTask: false,
							taskJustScheduled: payload?.payloadData?.taskId,
							errorCreateTask: undefined,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.createTaskFailed({
							isFetchingCreateTask: false,
							taskJustScheduled: [],
							errorCreateTask: error?.response,
						}),
					),
				),
			),
		),
	);

const rescheduleTaskEpic = (action$) =>
	action$.pipe(
		ofType(actions.RESCHEDULE_TASK),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.reschedule_task.method,
				END_POINT.reschedule_task.url(
					payload?.payloadData?.orgId,
					payload?.payloadData?.taskId,
					// timezone
					payload?.payloadData?.params,
				),
				payload?.payloadData?.body,
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							myTasksActions.rescheduleTaskFailed({
								isFetchingRescheduleTask: false,
								errorRescheduleTask: data.response,
							}),
						);
					}
					return of(
						myTasksActions.rescheduleTaskSuccess({
							isFetchingRescheduleTask: false,
							isReSchedulesSuccess: true,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.rescheduleTaskFailed({
							isFetchingRescheduleTask: false,
							errorRescheduleTask: error?.response,
						}),
					),
				),
			),
		),
	);

const getTimeBlocksByTaskEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TIME_BLOCKS_BY_TASK),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_time_blocks_by_task.method,
				END_POINT.get_time_blocks_by_task.url(
					payload?.payloadGetTimeBlocksByTask?.orgId,
					payload?.payloadGetTimeBlocksByTask?.taskId,
					payload?.payloadGetTimeBlocksByTask?.params,
				),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTasksActions.getTimeBlocksByTaskFailed({
								isFetchingGetTimeBlocksByTask: false,
								errorGetTimeBlocksByTask: data.response.errors.subcode,
							}),
						);
					}
					return of(
						myTasksActions.getTimeBlocksByTaskSuccess({
							isFetchingGetTimeBlocksByTask: false,
							timeBlocksByTask: data.response.task,
							errorGetTimeBlocksByTask: null,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.getTimeBlocksByTaskFailed({
							isFetchingGetTimeBlocksByTask: false,
							errorGetTimeBlocksByTask: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const deleteTimeBlockEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_TIME_BLOCK),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.delete_time_block.method,
				END_POINT.delete_time_block.url(
					payload?.payloadDeleteTimeBlock?.orgId,
					payload?.payloadDeleteTimeBlock?.task?.id,
					payload?.payloadDeleteTimeBlock?.timeBlock?.id,
				),
				payload?.payloadDeleteTimeBlock?.body,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTasksActions.deleteTimeBlockFailed({
								isFetchingDeleteTimeBlock: false,
								errorDeleteTimeBlock: data.response.errors.subcode,
							}),
						);
					}
					if (payload?.payloadDeleteTimeBlock?.isOnlyUpcoming) {
						return of(
							myTasksActions.deleteTimeBlockSuccess({
								isFetchingDeleteTimeBlock: false,
								errorDeleteTimeBlock: null,
							}),
						);
					}
					return concat(
						of(
							myTasksActions.deleteTimeBlockSuccess({
								isFetchingDeleteTimeBlock: false,
								errorDeleteTimeBlock: null,
							}),
						),
						of(myTasksActions.getTimeBlocksByTask(payload?.payloadGetTimeBlocksByTask)),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.deleteTimeBlockFailed({
							isFetchingDeleteTimeBlock: false,
							errorDeleteTimeBlock: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const getMTDetailsEpic = (action$) =>
	action$.pipe(
		ofType(actions.MT_GET_TASK_DETAILS),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.mt_get_task_details.method,
				END_POINT.mt_get_task_details.url(action.payload.orgId, action.payload.taskId, action.payload?.params),
			).pipe(
				mergeMap((data) =>
					of(
						myTasksActions.mtGetTaskDetailsSuccess({
							taskDetails: data?.response?.task,
							taskInProgress: data?.response?.inProgress || {},
							isFetchingMTTaskDetails: false,
							errorMTTaskDetails: null,
						}),
					),
				),
				catchError((error) =>
					of(
						myTasksActions.mtGetTaskDetailsFailed({
							isFetchingMTTaskDetails: false,
							errorMTTaskDetails: error?.response?.errors,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const workingOnTimeBlockEpic = (action$) =>
	action$.pipe(
		ofType(actions.WORKING_ON_TIME_BLOCK),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.working_on_time_block.method,
				END_POINT.working_on_time_block.url(
					payload?.payloadWorkingOnTimeBlock?.orgId,
					payload?.payloadWorkingOnTimeBlock?.taskId,
					payload?.payloadWorkingOnTimeBlock?.timeBlockId,
				),
				payload?.payloadWorkingOnTimeBlock?.body,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myTasksActions.workingOnTimeBlockFailed({
								isFetchingWorkingOnTimeBlock: false,
								errorWorkingOnTimeBlock: data.response.errors.subcode,
							}),
						);
					}
					return of(
						myTasksActions.workingOnTimeBlockSuccess({
							isFetchingWorkingOnTimeBlock: false,
							errorWorkingOnTimeBlock: null,
							workingOnTimeBlockSuccess: true,
							taskStatus: data.response?.status,
						}),
					);
				}),
				catchError((error) =>
					of(
						myTasksActions.workingOnTimeBlockFailed({
							isFetchingWorkingOnTimeBlock: false,
							errorWorkingOnTimeBlock: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const getTaskInProgressEpic = (action$) =>
	action$.pipe(
		ofType(actions.MT_GET_TASK_IN_PROGRESS),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.mt_get_task_in_progress.method,
				END_POINT.mt_get_task_in_progress.url(action.payload.orgId, action.payload?.params),
			).pipe(
				mergeMap((data) =>
					of(
						myTasksActions.mtGetTaskInProgressSuccess({
							taskInProgress: data?.response?.result || {},
							isFetchingMTTaskInProgress: false,
							errorMTTaskInProgress: null,
						}),
					),
				),
				catchError((error) =>
					of(
						myTasksActions.mtGetTaskInProgressFailed({
							errorMTTaskInProgress: error?.response?.errors,
							isFetchingMTTaskInProgress: false,
						}),
					),
				),
			),
		),
	);

const mtStartUrgentTaskEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MT_START_URGENT_TASK),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.start_urgent_task.method,
				END_POINT.start_urgent_task.url(
					state$.value.Auth.currentUser.organizationId,
					action.payload.taskId,
					action.payload?.params,
				),
			).pipe(
				mergeMap(() =>
					of(
						myTasksActions.mtStartUrgentTaskSuccess({
							mtStartUrgentTaskSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						myTasksActions.mtStartUrgentTaskFailed({
							errorStartUrgentTask: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const mtUseGoogleTemplateEpic = (action$) =>
	action$.pipe(
		ofType(actions.MT_USE_GOOGLE_TEMPLATE),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.use_google_template.method,
				END_POINT.use_google_template.url(
					action.payload.studentProgressId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
				action.payload.data,
			).pipe(
				mergeMap(({ response }) =>
					of(
						myTasksActions.mtUseGoogleTemplateSuccess({
							googleFileTemplate: response.data,
							useTemplateLoading: false,
							mtUseGoogleTemplateSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						myTasksActions.mtUseGoogleTemplateFailed({
							errorUseGoogleTemplate: error?.response?.errors?.subcode,
							useTemplateLoading: false,
						}),
					),
				),
			),
		),
	);

const submitSubmissionEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.SUBMIT_SUBMISSION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.submit_submission.method,
				END_POINT.submit_submission.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.studentProgressId,
					action.payload.urlParams,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) => {
					const { studentProgressStatus, taskStatus, activityStatus, submittedAttempt } = data.response;
					const { activityType } = action.payload.urlParams;
					const { limitedAttempt } = action.payload;

					const newState = {};
					if (!limitedAttempt) {
						newState.activeAttempt = submittedAttempt;
					} else {
						newState.studentAttempts = [submittedAttempt, ...state$.value.MyTasks.studentAttempts].map(
							(item, index, arr) => ({
								...item,
								ordinalText: ordinal(arr.length - index),
							}),
						);
						newState.activeAttempt = {};
						newState.usedAttempt = newState.studentAttempts.filter(
							(item) => item.status !== STUDENT_PROGRESS_STATUS.NOT_TURN_IN,
						).length;
					}

					const detailTask = cloneDeep(state$.value.MyTasks.detailTask);
					const convertedTask = cloneDeep(state$.value.MyTasks.convertedTask);

					if (studentProgressStatus && taskStatus && detailTask) {
						detailTask.status = taskStatus;
						convertedTask.status = taskStatus;
						switch (activityType) {
							case ACTIVITY_CATEGORY.ASSIGNMENT:
								detailTask.shadowAssignment.studentProgress[0].status = studentProgressStatus;
								if (activityStatus) {
									detailTask.shadowAssignment.status = activityStatus;
								}
								break;
							case ACTIVITY_CATEGORY.TEST:
								detailTask.shadowStudyForTest.studentProgress[0].status = studentProgressStatus;
								if (activityStatus) {
									detailTask.shadowStudyForTest.status = activityStatus;
								}
								break;
							default:
								break;
						}
					}

					if (taskStatus && convertedTask) {
						convertedTask.status = taskStatus;
					}

					return of(
						myTasksActions.submitSubmissionSuccess({
							isTurningIn: false,
							errorTurnIn: null,
							isTurnedIn: true,
							detailTask: detailTask,
							convertedTask: convertedTask,
							...newState,
						}),
					);
				}),
				catchError((error) => {
					return of(
						myTasksActions.submitSubmissionFailed({
							isTurningIn: false,
							errorTurnIn: error?.response?.errors,
							isTurnedIn: false,
						}),
					);
				}),
			),
		),
	);

const checkTeacherGoogleConnectEpic = (action$) =>
	action$.pipe(
		ofType(actions.CHECK_TEACHER_GOOGLE_CONNECT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.check_teacher_google_connect.method,
				END_POINT.check_teacher_google_connect.url(action.payload.courseId),
			).pipe(
				mergeMap((data) =>
					of(
						myTasksActions.checkTeacherGoogleConnectSuccess({
							teacherGoogleConnect: data.response.data.isConnected,
						}),
					),
				),
				catchError(() =>
					of(
						myTasksActions.checkTeacherGoogleConnectFailed({
							teacherGoogleConnect: false,
						}),
					),
				),
			),
		),
	);
const saveTaskForLaterWorkEpic = (action$) =>
	action$.pipe(
		ofType(actions.SAVE_TASK_FOR_LATER_WORK),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.save_task_for_later_work.method,
				END_POINT.save_task_for_later_work.url(action.payload.taskId, action.payload.studentProgressId),
				action.payload.data,
			).pipe(
				mergeMap(() => of(myTasksActions.saveTaskForLaterWorkSuccess({}))),
				catchError((error) =>
					of(
						myTasksActions.saveTaskForLaterWorkFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getSchoolPlanEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_PLAN),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_school_plan.method,
				END_POINT.get_school_plan.url(payload?.orgId, {
					...payload?.params,
					sort: 'desc',
					fieldSort: 'completedBy',
				}),
			).pipe(
				mergeMap((data) =>
					of(
						myTasksActions.getSchoolPlanSuccess({
							schoolPlan: data.response.items || [],
							isFetchingSchoolPlan: false,
						}),
					),
				),
				catchError(() =>
					of(
						myTasksActions.getSchoolPlanFailed({
							isFetchingSchoolPlan: false,
						}),
					),
				),
			),
		),
	);

const getStudentAttemptsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_STUDENT_ATTEMPTS),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_student_attempts.method,
				END_POINT.get_student_attempts.url(payload?.orgId, payload?.courseId, {
					...payload.params,
					viewing: COURSE_ROLE.STUDENT,
				}),
			).pipe(
				mergeMap((data) => {
					const isUnlimited = payload.isUnlimited;
					let studentAttempts = data.response.studentAttempts.map((item, index) => ({
						...item,
						ordinalText: ordinal(data.response.studentAttempts.length - index),
					}));
					const activeAttempt =
						studentAttempts.find(
							(item) => (isNil(item.attemptedAt) && item.status === STUDENT_PROGRESS_STATUS.NOT_TURN_IN) || isUnlimited,
						) || {};
					studentAttempts = studentAttempts.filter((item) => item.id !== activeAttempt.id);
					return of(
						myTasksActions.getStudentAttemptsSuccess({
							studentAttempts: studentAttempts || [],
							activeAttempt: activeAttempt,
							usedAttempt: data.response.usedAttempt || null,
						}),
					);
				}),
				catchError((errors) => of(myTasksActions.getStudentAttemptsFailed({ errors }))),
			),
		),
	);
const startNewAttemptEpic = (action$) =>
	action$.pipe(
		ofType(actions.START_NEW_ATTEMPT),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.start_new_attempt.method,
				END_POINT.start_new_attempt.url(payload.orgId, payload.studentProgressId),
			).pipe(
				mergeMap((data) => {
					const attemptData = data.response.attemptData;

					return of(
						myTasksActions.startNewAttemptSuccess({
							activeAttempt:
								{ ...attemptData?.studentAttempts, ordinalText: ordinal(attemptData?.usedAttempt + 1) } || {},
							usedAttempt: attemptData?.usedAttempt || null,
						}),
					);
				}),
				catchError(() => of(myTasksActions.startNewAttemptFailed({}))),
			),
		),
	);

const getTaskDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TASK_DETAIL),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_task_detail.method,
				END_POINT.get_task_detail.url(payload.orgId, payload.taskId, payload.urlParams),
			).pipe(
				mergeMap((data) => {
					const taskDetail = data.response.task;
					return of(
						myTasksActions.getTaskDetailSuccess({
							// activeAttempt: { ...attemptData?.studentAttempts, ordinalText: ordinal(attemptData?.usedAttempt) } || {},
							// usedAttempt: attemptData?.usedAttempt || null,
							convertedTask: standardizeTaskDetail(taskDetail),
						}),
						myTasksActions.getStudentAttempts({
							orgId: payload?.orgId,
							courseId: taskDetail.courseId,
							params: {
								taskId: payload?.taskId,
							},
							isUnlimited: data.response.task?.activity?.attempts === ATTEMPTS.UNLIMITED,
						}),
					);
				}),
				catchError(() => of(myTasksActions.getTaskDetailFailed({}))),
			),
		),
	);

const getTasksEpic = (action$) => 
	action$.pipe(
		ofType(actions.GET_TASKS),
		mergeMap(({payload}) => 
			makeAjaxRequest(
				END_POINT.get_tasks.method,
				END_POINT.get_tasks.url(payload.orgId, payload.urlParams)
			).pipe(
				mergeMap((data) => {
					return of(
						myTasksActions.getTasksSuccess({
							taskList: data.response.items || [],
							isFetchingTasks: false
						})
					);
				}),
				catchError(() => of(myTasksActions.getTasksFailed({isFetchingTasks: false})))
			)
		)
	);
export default [
	getScheduledTasksEpic,
	getUnscheduledTasksEpic,
	getCompletedTasksEpic,
	// getDetailTaskEpic,
	getCalendarSchedulesEpic,
	getDailyCalendarSchedulesEpic,
	getCalendarSchoolYearEpic,
	createTaskEpic,
	rescheduleTaskEpic,
	deleteTimeBlockEpic,
	getTimeBlocksByTaskEpic,
	getMTDetailsEpic,
	workingOnTimeBlockEpic,
	getTaskInProgressEpic,
	mtStartUrgentTaskEpic,
	mtUseGoogleTemplateEpic,
	submitSubmissionEpic,
	checkTeacherGoogleConnectEpic,
	saveTaskForLaterWorkEpic,
	getSchoolPlanEpic,
	getMissedTasksEpic,
	getStudentAttemptsEpic,
	startNewAttemptEpic,
	getTaskDetailEpic,
	getTasksEpic
];
