import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
	divider: {
		height: '1px',
		background: theme.newColors.gray[100],
		borderRadius: theme.spacing(0.5),
	},
	iconButton: {
		padding: '10px',
	},
	badge: {
		'.MuiBadge-badge': {
			backgroundColor: `${theme.newColors.red[600]} !important`,
			color: 'white',
			fontSize: '14px',
			fontWeight: '700',
		},
	},
	popover: {
		overflow: 'hidden',
		top: `${theme.spacing(7.125)} !important`,
	},
	header: {
		padding: '10px 20px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	content: {
		width: '20vw',
	},
	contentItemFirst: {
		paddingTop: '0 !important',
	},
	contentItem: {
		display: 'flex',
		padding: '20px',
		position: 'relative',
		':hover': {
			cursor: 'pointer',
		},
	},
	dotNewNotification: {
		position: 'absolute',
		left: '8px',
		top: '8px',
		width: '8px',
		height: '8px',
		borderRadius: '5px',
		backgroundColor: 'red',
	},
	contentItemIcon: {
		marginRight: '10px',
	},
	contentItemBody: { display: 'flex', flexDirection: 'column', width: '100%' },
	footer: {
		height: '40px',
		textAlign: 'center',
		padding: '10px 0',
		color: theme.newColors.primary[500],
	},
	loadingSkeleton: {
		height: '80px',
	},
	markAllReadBtn: {
		':hover': {
			cursor: 'pointer',
		},
		'& .MuiTypography-bodySmall': {
			marginRight: '5px',
		},
		div: {
			width: '20px',
			height: '20px',
		},

		svg: {
			fontSize: '14px',
		},

		width: '120px',
	},
	markReadBtn: {
		margin: '10px 0',
		minWidth: '122px',
		width: 'fit-content',
	},

	boxLink: {
		textDecoration: 'none',
		color: 'black',
	},
}));

export default useStyles;
