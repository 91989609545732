import { Typography as MTypography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import styles from './styles';

const TypographyCheckbox = ({ children, ...rest }) => <MTypography {...rest}>{children}</MTypography>;

TypographyCheckbox.propTypes = {
	children: PropTypes.node,
};

export default withStyles(TypographyCheckbox, styles);
