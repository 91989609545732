import authActions from 'shared/Auth/actions';
import CommonActions from 'shared/Common/actions';

import { cloneDeep, get } from 'lodash';
import { ofType } from 'redux-observable';
import { from, interval, merge, of, timer } from 'rxjs';
import {
	catchError,
	debounceTime,
	delay,
	endWith,
	mergeMap,
	retryWhen,
	switchMap,
	take,
	takeUntil,
	tap,
	timeout,
} from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import myCoursesActions from './actions';
import { ACTIVITY_CATEGORY, ACTIVITY_CATEGORY_NAME, END_POINT, GEN_COURSE_STATUS, actions } from './constants';
import courseContentEpics from './epics/courseContentEpics';
import gradeBookEpics from './epics/gradeBookEpics';
import gradeEpic from './epics/gradeEpic';
import gradeWeightingEpics from './epics/gradeWeightingEpics';
import gradeWeightingEpicsV2 from './epics/gradeWeightingEpicsV2';
import gradingEpics from './epics/gradingEpics';
import {
	getQueueUpdate,
	removeShadowFromCourseItemByUnit,
	updateActivityItemByUnits,
	updateCourseItemByUnit,
	updateSectionsByGradingPeriod,
} from './utils';
// import Worker from './course.worker.js';

const validateActivityEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_VALIDATE_ACTIVITY),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_validate_activity.method,
				END_POINT.mc_validate_activity.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
					action.payload.urlParams,
				),
				action.payload.body,
			).pipe(
				mergeMap(({ response }) => {
					const { applyFor, showOldSessions } = action.payload.body;

					const { validation } = response;
					const masterActivity = {
						id: Number(validation.masterId),
						editable: validation.editable,
						removable: validation.removable,
						reschedule: validation.reschedule,
						type: validation.type,
						isScheduledAtLeastOne: validation.isScheduledAtLeastOne,
						hasPublished: validation.hasPublished,
					};
					if (applyFor) {
						masterActivity.applyFor = applyFor;
					}
					masterActivity.showOldSessions = showOldSessions;

					// const currentCourseItemByUnit = state$.value?.AllCourses?.courseItemByUnit ?? {};
					// const typeName = ACTIVITY_CATEGORY_NAME[validation.type];
					// const courseItemByUnit = updateCourseItemByUnit(
					//   currentCourseItemByUnit,
					//   masterActivity.id,
					//   masterActivity,
					//   typeName
					// );

					const currentListActivitiesByUnits = state$.value.AllCourses.listActivitiesByUnits;
					const typeName = ACTIVITY_CATEGORY_NAME[masterActivity.type];
					const listActivitiesByUnits = updateActivityItemByUnits(
						currentListActivitiesByUnits,
						masterActivity.id,
						masterActivity,
						typeName,
					);

					return of(
						myCoursesActions.mcValidateActivitySuccess({
							listActivitiesByUnits: listActivitiesByUnits,
						}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcValidateActivityFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getMyCoursesListEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_MY_COURSES_LIST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_my_courses_list.method,
				END_POINT.get_my_courses_list.url(action.payload.orgId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getMyCoursesListSuccess({
							myCoursesList: data?.response?.listCourse,
							numberOfCourses: data?.response?.numberOfCourses,
							courseNeedScheduleFromGetCourseList: data?.response?.courseNeedSchedules,
							isBusy: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getMyCoursesListFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const getLinkedContentsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_LINKED_CONTENTS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_linked_contents.method,
				END_POINT.get_linked_contents.url(action.payload.orgId, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getLinkedContentsSuccess({
							assignmentsContents: data?.response?.assignments,
							lessonsContents: data?.response?.lessons,
							quizzesContents: data?.response?.quizzes || [],
							testsContents: data?.response?.tests || [],
							isBusy: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getLinkedContentsFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const getSyllabusEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_SYLLABUS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_syllabus.method,
				END_POINT.mc_get_syllabus.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) => {
					if (data.response.syllabus) {
						return of(
							myCoursesActions.mcGetSyllabusSuccess({
								syllabus: data.response?.syllabus,
								syllabusFetching: false,
							}),
						);
					}
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetSyllabusFailed({ syllabusFetching: false }),
					),
				),
			),
		),
	);

const updateSyllabusEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_UPDATE_SYLLABUS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_syllabus.method,
				END_POINT.mc_update_syllabus.url(action.payload.orgId, action.payload.courseId),
				{ syllabus: action.payload.syllabus },
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.mcUpdateSyllabusSuccess({
							updateSyllabusSuccess: true,
						}),
						myCoursesActions.getCourseValidation({
							orgId: action.payload.orgId,
							courseId: action.payload.courseId,
						}),
						myCoursesActions.mcGetSyllabus({
							orgId: action.payload.orgId,
							courseId: action.payload.courseId,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcUpdateSyllabusFailed({
							updateSyllabusSuccess: false,
						}),
					),
				),
			),
		),
	);

const getAssignmentDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ASSIGNMENT_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_assignment_detail.method,
				END_POINT.get_assignment_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.assignmentId,
				),
			).pipe(
				mergeMap((data) => {
					const actions = [
						myCoursesActions.getAssignmentDetailSuccess({
							assignment: data?.response?.assignment,
							activityDetails: data?.response?.assignment,
							// isBusy: false,
							fetchSessionsAfterDone: false,
							isFetchingAssignmentDetails: false,
						}),
					];
					if (action.payload?.fetchSessionsAfterDone) {
						actions.push(
							myCoursesActions.getAllSessions({
								orgId: action.payload.orgId,
								courseId: action.payload.courseId,
							}),
						);
					}
					return of(...actions);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getAssignmentDetailFailed({
							error: error?.response?.errors,
							errorCode: error?.response?.code,
							// isBusy: false,
							isFetchingAssignmentDetails: false,
						}),
					),
				),
			),
		),
	);

const getParticipationDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_PARTICIPATION_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_participation_detail.method,
				END_POINT.get_participation_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.participationId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getParticipationDetailSuccess({
							participation: data?.response?.participation,
							// isBusy: false,
							isFetchingParticipationDetails: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getParticipationDetailFailed({
							error: error?.response?.errors,
							errorCode: error?.response?.code,
							// isBusy: false,
							isFetchingParticipationDetails: false,
						}),
					),
				),
			),
		),
	);

const getShadowAssignmentDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SHADOW_ASSIGNMENT_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_shadow_assignment_detail.method,
				END_POINT.get_shadow_assignment_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getShadowAssignmentDetailSuccess({
							shadowAssignment: data?.response?.assignment,
							isBusy: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getShadowAssignmentDetailFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const studentViewShadowAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.STUDENT_GET_SHADOW_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.student_get_shadow_assignment.method,
				END_POINT.student_get_shadow_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.studentGetShadowAssignmentSuccess({
							shadowAssignmentDetail: data?.response?.data,
							errorStudentEditShadowAssignment: null,
							activityDetails: data?.response?.data,
							isBusy: false,
							studentGetShadowAssignmentError: null,
							studentGetShadowQuizError: null,
						}),
					),
				),
				takeUntil(action$.pipe(ofType(actions.CANCLE_STUDENT_GET_SHADOW_ASSIGNMENT))),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.studentGetShadowAssignmentFailed({
							// error: error?.response?.errors,
							studentGetShadowAssignmentError: error?.response,
							activityDetails: error?.response?.detail,
							isBusy: false,
							errorCode: error?.response?.code,
						}),
					),
				),
			),
		),
	);

const studentEditShadowAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.STUDENT_EDIT_SHADOW_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.student_edit_shadow_assignment.method,
				END_POINT.student_edit_shadow_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
				),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.studentEditShadowAssignmentSuccess({
							isTurningIn: false,
							studentEditShadowAssignmentSuccess: true,
							errorStudentEditShadowAssignment: null,
							isTurnIn: action.payload.data?.turnIn || false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.studentEditShadowAssignmentFailed({
							isTurningIn: false,
							studentEditShadowAssignmentFailed: true,
							errorStudentEditShadowAssignment: error.response.errors,
						}),
					),
				),
			),
		),
	);

const createNewAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_NEW_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.create_new_assignment.method,
				END_POINT.create_new_assignment.url(action.payload.orgId, action.payload.courseId, action.payload.unitId),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.createNewAssignmentSuccess({
							createNewAssignmentSuccess: true,
							createdActivity: data?.response?.assignment,
							assignment: data?.response?.assignment,
							isFetchingAssignmentDetails: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.createNewAssignmentFailed({
							createNewAssignmentSuccess: false,
							errorMasterAssignment: error.response.errors,
							isFetchingAssignmentDetails: false,
						}),
					),
				),
			),
		),
	);

const createNewMasterParticipationEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_NEW_MASTER_PARTICIPATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.create_new_participation_master.method,
				END_POINT.create_new_participation_master.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.gradingPeriodId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.createNewMasterParticipationSuccess({
							createNewMasterParticipationSuccess: true,
							createdActivity: data?.response?.participation,
							participation: data?.response?.participation,
							isFetchingParticipationDetails: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.createNewMasterParticipationFailed({
							createNewMasterParticipationSuccess: false,
							errorMasterParticipation: error.response.errors,
							isFetchingParticipationDetails: false,
						}),
					),
				),
			),
		),
	);

// not use anymore
const editAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.EDIT_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_assignment.method,
				END_POINT.edit_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.assignmentId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.editAssignmentSuccess({
							isEditingAssignment: false,
							editMasterAssignmentSuccess: true,
							errorMasterAssignment: null,
							queueUpdate: getQueueUpdate(data?.response?.courseDayIds),
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.editAssignmentFailed({
							isEditingAssignment: false,
							editMasterAssignmentFailed: true,
							errorMasterAssignment: error.response.errors,
						}),
					),
				),
			),
		),
	);

const editMetadataAssignmentEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.EDIT_METADATA_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_metadata_assignment.method,
				END_POINT.edit_metadata_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.assignmentId,
				),
				action.payload.data,
			).pipe(
				mergeMap(() => {
					const masterId = action.payload.assignmentId;
					const { assignmentName: name } = action.payload.data;

					const oldList = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod ?? []);
					let newSectionsByGradingPeriod = updateSectionsByGradingPeriod(
						masterId,
						{ name: name },
						oldList,
						ACTIVITY_CATEGORY.ASSIGNMENT,
					);

					const currentCourseItemByUnit = state$.value?.AllCourses?.courseItemByUnit ?? {};
					const typeName = ACTIVITY_CATEGORY_NAME[ACTIVITY_CATEGORY.ASSIGNMENT];
					const courseItemByUnit = updateCourseItemByUnit(currentCourseItemByUnit, masterId, { name: name }, typeName);

					return of(
						myCoursesActions.editMetadataAssignmentSuccess({
							editMasterAssignmentSuccess: true,
							errorMasterAssignment: null,
							courseItemByUnit: courseItemByUnit,
							sectionsByGradingPeriod: newSectionsByGradingPeriod,
							isFetchingAssignmentDetails: false,
							isEditingAssignment: false,
						}),
					);
				}),

				catchError((error) =>
					of(
						myCoursesActions.editMetadataAssignmentFailed({
							editMasterAssignmentFailed: true,
							errorMasterAssignment: error?.response?.errors,
							isFetchingAssignmentDetails: false,
							isEditingAssignment: false,
						}),
					),
				),
			),
		),
	);

const editParticipationEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.EDIT_PARTICIPATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_participation.method,
				END_POINT.edit_participation.url(action.payload.orgId, action.payload.courseId, action.payload.participationId),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.editParticipationSuccess({
							editMasterParticipationSuccess: true,
							errorMasterParticipation: null,
							isFetchingParticipationDetails: false,
							isEditingParticipation: false,
						}),
					),
				),

				catchError((error) =>
					of(
						myCoursesActions.editParticipationFailed({
							editMasterParticipationFailed: true,
							errorMasterParticipation: error.response.errors,
							isFetchingParticipationDetails: false,
							isEditingParticipation: false,
						}),
					),
				),
			),
		),
	);

const editShadowAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.EDIT_SHADOW_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_shadow_assignment.method,
				END_POINT.edit_shadow_assignment.url(action.payload.orgId, action.payload.courseId, action.payload.shadowId),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.editShadowAssignmentSuccess({
							editShadowAssignmentSuccess: true,
							editShadowAssignmentFailed: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.editShadowAssignmentFailed({
							editShadowAssignmentFailed: true,
							errorShadowAssignment: error.response.errors,
						}),
					),
				),
			),
		),
	);

const deleteAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_assignment.method,
				END_POINT.delete_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.assignmentId,
				),
				action.payload.assignment,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.deleteAssignmentSuccess({
							deleteAssignmentSuccess: true,
							isDeletingActivity: false,
							deleteActivitySuccess: true,
							deletedActivityInfo: {
								id: action.payload.assignmentId,
								activityType: ACTIVITY_CATEGORY.ASSIGNMENT,
								unitId: action.payload.unitId,
							},
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.deleteAssignmentFailed({
							error: error.response.errors,
							isDeletingActivity: false,
							deleteActivitySuccess: false,
							deletedActivityInfo: {},
						}),
					),
				),
			),
		),
	);

const deleteParticipationEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_PARTICIPATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_participation.method,
				END_POINT.delete_participation.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.participationId,
				),
				action.payload.assignment,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.deleteParticipationSuccess({
							deleteParticipationSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.deleteParticipationFailed({
							error: error.response.errors,
						}),
					),
				),
			),
		),
	);

const createNewUnitEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_NEW_UNIT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.create_new_unit.method,
				END_POINT.create_new_unit.url(action.payload.orgId, action.payload.courseId),
				action.payload.unit,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.createNewUnitSuccess({
							isCreateNewUnitSuccess: true,
							isCreatingUnit: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.createNewUnitFailed({
							error: error?.response?.errors,
							isCreatingUnit: false,
						}),
					),
				),
			),
		),
	);

const getTermsListByCourseEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TERMS_LIST_BY_COURSE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_terms_list_by_course.method,
				END_POINT.get_terms_list_by_course.url(action.payload.orgId, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getTermsListByCourseSuccess({
							termsListByCourse: data?.response?.terms,
							isFetchingTermsList: false,
						}),
						// myCoursesActions.getUnitsByTerm({
						//   orgId: action.payload.orgId,
						//   courseId: action.payload.courseId,
						//   urlParams: { termIds: data?.response?.terms[0]?.id, groupBy: 'gradingPeriod' },
						//   isFetchingUnitsList: true
						// })
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getTermsListByCourseFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isFetchingTermsList: false,
						}),
					),
				),
			),
		),
	);

const exchangeParamBetweenArray = (arrA, arrB) => {
	const result = arrA
		.map((modulePermission) => {
			const module = modulePermission?.[0];

			const moduleIndex = arrB.findIndex((cp) => cp?.[0] === module);

			const overrideModule = moduleIndex !== -1 ? arrB.splice(moduleIndex, 1) : [];

			return overrideModule?.length ? overrideModule[0].join('/') : modulePermission.join('/');
		})
		.concat(arrB?.length ? arrB.map((modulePermission) => modulePermission.join('/')) : []);

	return result;
};

function mergePermissionsStrings(a = [], b = []) {
	const allPermissions = [...a.split(':'), ...b.split(':')].filter((item) => typeof item === 'string' && item !== '');
	const permissionModule = {};
	allPermissions.forEach((mp) => {
		const [moduleAlias, permissionStr] = mp.split('/');
		if (permissionModule.hasOwnProperty(moduleAlias)) {
			if (permissionModule[moduleAlias] === '*') {
				return;
			}
			if (permissionStr === '*') {
				permissionModule[moduleAlias] = '*';
				return;
			}
			const currentPermissions = permissionModule[moduleAlias].split(',');
			const targetPermissions = permissionStr.split(',');
			const unique = new Set([...currentPermissions, ...targetPermissions]);
			const concatenated = Array.from(unique).join(',');
			permissionModule[moduleAlias] = concatenated;
		} else {
			permissionModule[moduleAlias] = permissionStr;
		}
	});
	return Object.entries(permissionModule)
		.map(([moduleAlias, permissionStr]) => `${moduleAlias}/${permissionStr}`)
		.join(':');
}

// NOTE: get permission for course resource to adapt latest requirement EW-8428
const getCoursePermissionV2Epic = (action$, state$) =>
	action$.pipe(
		ofType(actions.GET_PERMISSION_COURSE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_permission_course.method,
				END_POINT.get_permission_course.url(action.payload.courseId),
			).pipe(
				mergeMap((data) => {
					const coursePermissions = get(data, 'response.permissions', '');
					// 		.split(':')
					// 		.map((m) => m.split('/')) || [];

					const currentUser = get(state$, 'value.Auth.currentUser', {});
					const originPermissions = get(state$, 'value.Auth.originPermissions', {});

					// const prevPermissions = currentUser.permissions.split(':').map((m) => m.split('/')) || [];

					const latestPermissions = mergePermissionsStrings(originPermissions, coursePermissions);
					return of(
						authActions.authSetState({
							currentUser: { ...currentUser, permissions: latestPermissions },
							originPermissions,
						}),
						myCoursesActions.getPermissionCourseSuccess({}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.getPermissionCourseFailed({
							error: error,
						}),
					),
				),
			),
		),
	);
// NOTE: legacy permission for course resource. It will be replaced after permission of course module finishing
const getCoursePermissionEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_PERMISSION_COURSE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_permission_course.method,
				END_POINT.mc_get_permission_course.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) => {
					let roles = [...data.response.organizationRoles];
					if (!!data?.response?.courseRoles) {
						roles = [...roles, ...data?.response?.courseRoles];
					}
					return of(
						myCoursesActions.mcGetPermissionCourseSuccess({
							permission: {
								roles: roles,
								courseRoles: data?.response?.courseRoles,
							},
						}),
						// myCoursesActions.getPermissionCourse({
						// 	courseId: action.payload.courseId,
						// }),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcGetPermissionCourseFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getUnitsByTermEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_UNITS_BY_TERM),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_units_by_term.method,
				END_POINT.get_units_by_term.url(action.payload.orgId, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getUnitsByTermSuccess({
							gradingPeriodList: data?.response?.gradingPeriods,
							isFetchingUnitsList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getUnitsByTermFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isFetchingUnitsList: false,
						}),
					),
				),
			),
		),
	);

const createQuizEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_CREATE_QUIZ),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.create_quiz.method,
				END_POINT.create_quiz.url(action.payload.orgId, action.payload.courseId, action.payload.unitId),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcCreateQuizSuccess({
							currentQuiz: data.response.quiz,
							createQuizSuccess: true,
							isBusy: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcCreateQuizFailed({
							error: error?.response?.errors,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const getQuizEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_QUIZ),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_quiz.method,
				END_POINT.get_quiz.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.quizId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetQuizSuccess({
							currentQuiz: data.response.quiz,
							activityDetails: data.response.quiz,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetQuizFailed({
							error: error?.response?.errors,
							errorCode: error?.response?.code,
						}),
					),
				),
			),
		),
	);

const editQuizEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_EDIT_QUIZ),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_quiz.method,
				END_POINT.edit_quiz.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.quizId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcEditQuizSuccess({
							editQuizSuccess: true,
							queueUpdate: getQueueUpdate(data?.response?.courseDayIds),
							isBusy: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcEditQuizFailed({
							error: error?.response?.errors,
							isBusy: false,
						}),
					),
				),
			),
		),
	);
const deleteQuizEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_DELETE_QUIZ),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_quiz.method,
				END_POINT.delete_quiz.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.quizId,
				),
			).pipe(
				mergeMap(() => of(myCoursesActions.mcDeleteQuizSuccess({ deleteQuizSuccess: true, isBusy: false }))),
				catchError((error) =>
					of(
						myCoursesActions.mcDeleteQuizFailed({
							error: error?.response?.errors,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const editUnitEpic = (action$) =>
	action$.pipe(
		ofType(actions.EDIT_UNIT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_unit.method,
				END_POINT.edit_unit.url(action.payload.orgId, action.payload.courseId, action.payload.unitId),
				action.payload.unit,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.editUnitSuccess({
							isEditUnitSuccess: true,
							isEditingUnit: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.editUnitFailed({
							error: error?.response?.errors,
							isEditingUnit: false,
						}),
					),
				),
			),
		),
	);

const deleteLessonEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_LESSON),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_lesson.method,
				END_POINT.delete_lesson.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.lessonId,
				),
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.deleteLessonSuccess({
							isDeleteLessonSuccess: true,
							isDeletingActivity: false,
							deleteActivitySuccess: true,
							deletedActivityInfo: {
								id: action.payload.lessonId,
								activityType: ACTIVITY_CATEGORY.LESSON,
								unitId: action.payload.unitId,
							},
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.deleteLessonFailed({
							error: error?.response?.errors,
							isDeletingActivity: false,
							deleteActivitySuccess: false,
							deletedActivityInfo: {},
						}),
					),
				),
			),
		),
	);

const deleteUnitEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_UNIT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_unit.method,
				END_POINT.delete_unit.url(action.payload.orgId, action.payload.courseId, action.payload.unitId),
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.deleteUnitSuccess({ deleteUnitSuccess: true }),
						// myCoursesActions.getUnitsByTerm({
						//   orgId: action.payload.orgId,
						//   courseId: action.payload.courseId,
						//   urlParams: {
						//     termIds: action.payload.termId,
						//     timezone: action.payload.timezone,
						//     groupBy: 'gradingPeriod',
						//   },
						// })
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.deleteUnitFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getUnitByCourseEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_UNIT_BY_COURSE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_unit_by_course.method,
				END_POINT.mc_get_unit_by_course.url(action.payload.orgId, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetUnitByCourseSuccess({
							unitList: data?.response?.data,
							mcGetUnitByCourseSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetUnitByCourseFailed({
							mcGetUnitByCourseFailed: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getCourseItemByUnitEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_COURSE_ITEM_BY_UNIT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_course_item_by_unit.method,
				END_POINT.mc_get_course_item_by_unit.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetCourseItemByUnitSuccess({
							courseItemByUnit: data?.response?.data,
							isFetchingGradingPeriodList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetCourseItemByUnitFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isFetchingGradingPeriodList: false,
						}),
					),
				),
			),
		),
	);

//
const getSectionsByGradingPeriodEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_GET_SECTIONS_BY_GRADING_PERIOD),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_sections_by_grading_period.method,
				END_POINT.mc_get_sections_by_grading_period.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.gradingPeriodId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetSectionsByGradingPeriodSuccess({
							sectionsByGradingPeriod: data?.response?.sections,
							isFetchingPlanningData: false,
							currentCourseDayId: state$.value.AllCourses.firstTime
								? state$.value.AllCourses.currentCourseDayId
								: data?.response?.currentDayId,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetSectionsByGradingPeriodFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isFetchingPlanningData: false,
						}),
					),
				),
			),
		),
	);

const getCourseDayDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_COURSE_DAY_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_course_day_detail.method,
				END_POINT.mc_get_course_day_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.courseDayId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetCourseDayDetailSuccess({
							courseDayDetail: data?.response?.courseDays,
							isFetchingGradingPeriodList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetCourseDayDetailFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							// isFetchingGradingPeriodList: false,
						}),
					),
				),
			),
		),
	);
const updateMasterItemEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_UPDATE_MASTER_ITEM),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_master_item.method,
				END_POINT.mc_update_master_item.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.courseDayId,
					action.payload.urlParams,
				),
				action.payload.activity,
			).pipe(
				mergeMap(() => {
					const updateReducer = myCoursesActions.mcUpdateMasterItemSuccess({
						mcUpdateMasterItemSuccess: true,
						updateData: {
							destinationId: action.payload.courseDayId,
							sourceId: action.payload.sourceId,
						},
					});
					if (!!action.payload.unitId) {
						return of(
							updateReducer,
							myCoursesActions.mcGetCourseItemByUnit({
								orgId: action.payload.orgId,
								courseId: action.payload.courseId,
								unitId: action.payload.unitId,
							}),
						);
					}
					return of(updateReducer);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcUpdateMasterItemFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							// isFetchingGradingPeriodList: false,
						}),
					),
				),
			),
		),
	);
const updateShadowLessonEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_UPDATE_SHADOW_LESSON),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_shadow_lesson.method,
				END_POINT.mc_update_shadow_lesson.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
				action.payload.activity,
			).pipe(
				mergeMap(() => {
					const payload = {
						// courseDayDetail: data?.response?.courseDays,
						mcUpdateShadowLessonSuccess: true,
						updateData: {
							destinationId: action.payload.courseDayId,
							sourceId: action.payload.sourceId,
						},
					};
					if (action.payload.isEditingShadowLesson) {
						Object.assign(payload, { isEditingShadowLesson: false });
					}
					return of(myCoursesActions.mcUpdateShadowLessonSuccess(payload));
				}),
				catchError((error) => {
					const payload = {
						error: error?.response?.errors,
						errorCode: error?.status,
					};
					if (action.payload.isInModal) {
						delete payload.error;
						Object.assign(payload, { errorShadow: error?.response?.errors });
					}
					if (action.payload.isEditingShadowLesson) {
						Object.assign(payload, { isEditingShadowLesson: false });
					}
					return of({ type: 'GLOBAL_ERROR', payload: { error } }, myCoursesActions.mcUpdateShadowLessonFailed(payload));
				}),
			),
		),
	);

const getCourseDayListEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_COURSE_DAY_LIST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_course_day_list.method,
				END_POINT.mc_get_course_day_list.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.sectionId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetCourseDayListSuccess({
							courseDayList: data?.response?.schedules,
							currentDayId: data?.response?.currentDayId,
							isFetchingCourseDayList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetCourseDayListFailed({
							error: error?.response?.errors,
							isFetchingCourseDayList: false,
						}),
					),
				),
			),
		),
	);

const getAllCourseDaysEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ALL_COURSE_DAYS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_all_course_days.method,
				END_POINT.get_all_course_days.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getAllCourseDaysSuccess({
							courseDays: data?.response?.courseDays,
							currentDayId: data?.response?.currentDayId,
							isFetchingCourseDayList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getAllCourseDaysFailed({
							error: error?.response?.errors,
							isFetchingCourseDayList: false,
						}),
					),
				),
			),
		),
	);
const getAllSessionsEpic = (action$, state$) =>
	action$.pipe(
		ofType(
			actions.GET_ALL_SESSIONS,
			actions.CREATE_NEW_ASSIGNMENT_SUCCESS,
			actions.CREATE_NEW_LESSON_SUCCESS,
			actions.CREATE_NEW_TEST_SUCCESS,
			actions.MC_GET_SECTIONS_BY_GRADING_PERIOD,
		),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_all_sessions.method,
				END_POINT.get_all_sessions.url(
					action.payload.orgId || state$.value.Auth.orgId,
					action.payload.courseId || state$.value.MyCourses?.basicInfo?.id,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getAllSessionsSuccess({
							sessions: data.response.sessions,
							currentSessionId: data.response.currentSessionId,
							// sections: data.response.sections,
							isFetchingSessionsList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.getAllSessionsFailed({
							error: error?.response?.errors,
							isFetchingSessionsList: false,
						}),
					),
				),
			),
		),
	);

const getAllScheduleSectionsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ALL_SCHEDULE_SECTIONS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_all_schedule_sections.method,
				END_POINT.get_all_schedule_sections.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getAllScheduleSectionsSuccess({
							sections: data.response.sections,
							applyFor: data.response.applyFor,
							isFetchingSectionsList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.getAllScheduleSectionsFailed({
							error: error?.response?.errors,
							isFetchingSectionsList: false,
						}),
					),
				),
			),
		),
	);

const getShadowLessonDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_SHADOW_LESSON_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_shadow_lesson_detail.method,
				END_POINT.mc_get_shadow_lesson_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetShadowLessonDetailSuccess({
							shadowLessonDetail: data?.response?.data,
							isFetchingShadowLessonDetail: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetShadowLessonDetailFailed({
							error: error?.response?.errors,
							isFetchingShadowLessonDetail: false,
						}),
					),
				),
			),
		),
	);

const updateShadowQuizzesEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_UPDATE_SHADOW_QUIZZES),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_shadow_quizzes.method,
				END_POINT.mc_update_shadow_quizzes.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
				action.payload.activity,
			).pipe(
				mergeMap(() => {
					const payload = {
						// courseDayDetail: data?.response?.courseDays,
						mcUpdateShadowQuizzesSuccess: true,
						updateData: {
							destinationId: action.payload.courseDayId,
							sourceId: action.payload.sourceId,
						},
					};
					if (action.payload.isEditingShadowQuizzes) {
						Object.assign(payload, { isEditingShadowQuizzes: false });
					}
					return of(myCoursesActions.mcUpdateShadowQuizzesSuccess(payload));
				}),
				catchError((error) => {
					const payload = {
						error: error?.response?.errors,
						errorCode: error?.status,
					};
					if (action.payload.isInModal) {
						delete payload.error;
						Object.assign(payload, { errorShadow: error?.response?.errors });
					}
					if (action.payload.isEditingShadowQuizzes) {
						Object.assign(payload, { isEditingShadowQuizzes: false });
					}
					return of(myCoursesActions.mcUpdateShadowQuizzesFailed(payload));
				}),
			),
		),
	);

const getShadowQuizDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_SHADOW_QUIZ_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_shadow_quiz_detail.method,
				END_POINT.mc_get_shadow_quiz_detail.url(action.payload.orgId, action.payload.courseId, action.payload.shadowId),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetShadowQuizDetailSuccess({
							shadowQuizDetail: data?.response?.data,
							isFetchingShadowQuizDetail: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetShadowQuizDetailFailed({
							error: error?.response?.errors,
							isFetchingShadowQuizDetail: false,
						}),
					),
				),
			),
		),
	);

const updateShadowAssignmentsEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_UPDATE_SHADOW_ASSIGNMENTS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_shadow_assignments.method,
				END_POINT.mc_update_shadow_assignments.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
				action.payload.activity,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.mcUpdateShadowAssignmentsSuccess({
							// courseDayDetail: data?.response?.courseDays,
							mcUpdateShadowAssignmentsSuccess: true,
							updateData: {
								destinationId: action.payload.courseDayId,
								sourceId: action.payload.sourceId,
							},
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcUpdateShadowAssignmentsFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							// isFetchingGradingPeriodList: false,
						}),
					),
				),
			),
		),
	);

// Student
const getCourseContentEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_COURSE_CONTENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_course_content.method,
				END_POINT.mc_get_course_content.url(action.payload.orgId, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetCourseContentSuccess({
							mcGetCourseContentSuccess: true,
							courseContent: data?.response,
							error: null,
							errGetCourseContent: null,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetCourseContentFailed({
							errGetCourseContent: error?.response?.errors,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getLessonDetailsEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_LESSON_DETAILS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_lesson_details.method,
				END_POINT.mc_get_lesson_details.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetActivityDetailsSuccess({
							mcGetActivityDetailsSuccess: true,
							activityDetails: data?.response?.lesson,
						}),
					),
				),
				takeUntil(action$.pipe(ofType(actions.MC_CANCLE_GET_LESSON_DETAILS))),
				catchError((error) =>
					of(
						myCoursesActions.mcGetActivityDetailsFailed({
							error: error?.response?.errors,
							errorCode: error?.response?.code,
						}),
					),
				),
			),
		),
	);

const removeShadowLessonEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_REMOVE_SHADOW_LESSON),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_remove_shadow_lesson.method,
				END_POINT.mc_remove_shadow_lesson.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap(() => {
					const { sectionIds } = action.payload.body;
					const { masterId, type } = action.payload;

					const listGroup = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod || []);
					const newList = removeShadowFromCourseItemByUnit(listGroup, sectionIds, masterId, type);

					return of(
						myCoursesActions.mcRemoveShadowLessonSuccess({
							mcRemoveShadowSuccess: true,
							sectionsByGradingPeriod: newList,
						}),
						myCoursesActions.mcValidateActivity({
							...action.payload,
							urlParams: {
								type: type,
							},
						}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcRemoveShadowLessonFailed({
							mcRemoveShadowFailed: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getQuizDetailsEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_QUIZ_DETAILS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_quiz_details.method,
				END_POINT.mc_get_quiz_details.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetActivityDetailsSuccess({
							mcGetActivityDetailsSuccess: true,
							activityDetails: data?.response?.detail,
							studentGetShadowQuizError: null,
							studentGetShadowAssignmentError: null,
							isFetchingQuizDetails: false,
						}),
					),
				),
				takeUntil(action$.pipe(ofType(actions.MC_CANCLE_GET_QUIZ_DETAILS))),
				catchError((error) =>
					of(
						myCoursesActions.mcGetActivityDetailsFailed({
							error: error?.response?.errors,
							studentGetShadowQuizError: error?.response,
							activityDetails: error?.response?.detail,
							errorCode: error?.response?.code,
							isFetchingQuizDetails: false,
						}),
					),
				),
			),
		),
	);

const getAssignmentStudentSubmissionEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_ASSIGNMENT_STUDENT_SUBMISSION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_assignment_student_submission.method,
				END_POINT.mc_get_assignment_student_submission.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.assignmentId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetAssignmentStudentSubmissionSuccess({
							mcGetAssignmentStudentSubmissionSuccess: true,
							assignmentStudentSubmission: data?.response,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetAssignmentStudentSubmissionFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getActivitiesByUnitEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_ACTIVITIES_BY_UNIT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_activities_by_unit.method,
				END_POINT.mc_get_activities_by_unit.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetActivitiesByUnitSuccess({
							mcGetActivitiesByUnitSuccess: true,
							activitiesByUnit: data?.response,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetActivitiesByUnitFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

// Publish shadow lessons, assignments, quizzes at the master level
const getShadowItemValidationsEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_SHADOW_ITEM_VALIDATIONS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_shadow_item_validations.method,
				END_POINT.mc_get_shadow_item_validations.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.courseItemType,
					action.payload.masterId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetShadowItemValidationsSuccess({
							shadowItemValidations: data?.response?.result,
							isFetchingShadowItemValidations: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcGetShadowItemValidationsFailed({
							error: error?.response?.errors,
							isFetchingShadowItemValidations: false,
						}),
					),
				),
			),
		),
	);

const changeShadowItemsStatusAtMasterLevelEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_CHANGE_SHADOW_ITEMS_STATUS_AT_MASTER_LEVEL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_change_shadow_items_status_at_master_level.method,
				END_POINT.mc_change_shadow_items_status_at_master_level.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.courseItemType,
					action.payload.masterId,
				),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.mcChangeShadowItemsStatusAtMasterLevelSuccess({
							mcChangeShadowItemsStatusAtMasterLevelSuccess: true,
							isChangingShadowItemsStatusAtMasterLevel: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcChangeShadowItemsStatusAtMasterLevelFailed({
							error: error?.response?.errors,
							isChangingShadowItemsStatusAtMasterLevel: false,
						}),
					),
				),
			),
		),
	);

const consolidateAssignmentEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_CONSOLIDATE_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				action.payload.method,
				END_POINT.mc_consolidate_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap(({ response }) => {
					const isEditSchoolYear = action?.payload;

					if (response.code === 202) {
						return of(
							myCoursesActions.myCoursesSetState({
								mcConsolidateAssignmentNeedConfirm: true,
								studentHasCustomDue: response?.studentHasCustomDue,
							}),
						);
					}
					return of(
						myCoursesActions.mcConsolidateAssignmentSuccess({
							mcConsolidateAssignmentSuccess: true,
							mcConsolidateAssignmentNeedConfirm: false,
						}),
						!isEditSchoolYear
							? myCoursesActions.mcValidateActivity({
									...action.payload,
									urlParams: {
										type: ACTIVITY_CATEGORY.ASSIGNMENT,
									},
							  })
							: { type: '', payload: {} },
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcConsolidateAssignmentFailed({
							mcConsolidateAssignmentFailed: true,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const removeShadowAssignmentEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_REMOVE_SHADOW_ASSIGNMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_remove_shadow_assignment.method,
				END_POINT.mc_remove_shadow_assignment.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap(() => {
					const { sectionIds } = action.payload.body;
					const { masterId, type } = action.payload;

					const listGroup = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod ?? []);
					const newList = removeShadowFromCourseItemByUnit(listGroup, sectionIds, masterId, type);

					return of(
						myCoursesActions.mcRemoveShadowAssignmentSuccess({
							mcRemoveShadowSuccess: true,
							sectionsByGradingPeriod: newList,
						}),
						myCoursesActions.mcValidateActivity({
							...action.payload,
							urlParams: {
								type: type,
							},
						}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcRemoveShadowAssignmentFailed({
							mcRemoveShadowFailed: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const consolidateTestEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_CONSOLIDATE_TEST),
		switchMap((action) =>
			makeAjaxRequest(
				action.payload.method,
				END_POINT.mc_consolidate_test.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.mcConsolidateTestSuccess({
							mcConsolidateTestSuccess: true,
						}),
						!action.payload?.isEditSchoolYear
							? myCoursesActions.mcValidateActivity({
									...action.payload,
									urlParams: {
										type: ACTIVITY_CATEGORY.TEST,
									},
							  })
							: { type: '', payload: {} },
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcConsolidateTestFailed({
							mcConsolidateTestFailed: true,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const removeShadowTestEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_REMOVE_SHADOW_TEST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_remove_shadow_test.method,
				END_POINT.mc_remove_shadow_test.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap(() => {
					const { sectionIds } = action.payload.body;
					const { masterId, type } = action.payload;

					const listGroup = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod ?? []);
					const newList = removeShadowFromCourseItemByUnit(listGroup, sectionIds, masterId, type);

					return of(
						myCoursesActions.mcRemoveShadowTestSuccess({
							mcRemoveShadowSuccess: true,
							sectionsByGradingPeriod: newList,
						}),
						myCoursesActions.mcValidateActivity({
							...action.payload,
							urlParams: {
								type: type,
							},
						}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcRemoveShadowTestFailed({
							mcRemoveShadowFailed: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
const consolidateQuizEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_CONSOLIDATE_QUIZ),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_consolidate_quiz.method,
				END_POINT.mc_consolidate_quiz.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcConsolidateQuizSuccess({
							mcConsolidateQuizSuccess: true,
							nonGeneratedSections: data?.response?.nonGeneratedSections,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.mcConsolidateQuizFailed({
							mcConsolidateQuizFailed: true,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const consolidateLessonEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MC_CONSOLIDATE_LESSON),
		switchMap((action) =>
			makeAjaxRequest(
				action.payload.method,
				END_POINT.mc_consolidate_lesson.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.masterId,
				),
				action.payload.body,
			).pipe(
				mergeMap(() => {
					const { applyFor } = action.payload.body;
					const { masterId } = action.payload;

					const currentCourseItemByUnit = state$.value?.AllCourses?.courseItemByUnit ?? {};
					const typeName = ACTIVITY_CATEGORY_NAME[ACTIVITY_CATEGORY.LESSON];

					const courseItemByUnit = updateCourseItemByUnit(
						currentCourseItemByUnit,
						masterId,
						{ applyFor: applyFor },
						typeName,
					);

					return of(
						myCoursesActions.mcConsolidateLessonSuccess({
							mcConsolidateLessonSuccess: true,
							courseItemByUnit: courseItemByUnit,
						}),
						!action.payload?.isEditSchoolYear
							? myCoursesActions.mcValidateActivity({
									...action.payload,
									urlParams: {
										type: ACTIVITY_CATEGORY.LESSON,
									},
							  })
							: { type: '', payload: {} },
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.mcConsolidateLessonFailed({
							mcConsolidateLessonFailed: true,
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const relinkShadowItem = (action$) =>
	action$.pipe(
		ofType(actions.RELINK_SHADOW_ITEM),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.relink_shadow_item.method,
				END_POINT.relink_shadow_item.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.type,
					action.payload.shadowId,
				),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.relinkShadowItemSuccess({
							relinkShadowItemSuccess: true,
							isRelinkingShadowItem: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.relinkShadowItemFailed({
							error: error?.response?.errors,
							isRelinkingShadowItem: false,
						}),
					),
				),
			),
		),
	);

const getSectionDetail = (action$) =>
	action$.pipe(
		ofType(actions.GET_SECTION_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_section_detail.method,
				END_POINT.get_section_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.courseDayId,
					action.payload.sectionId,
					action.payload.urlParams,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getSectionDetailSuccess({
							sectionDetail: data.response,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.getSectionDetailFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getReleaseListStudentSubmissionEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_RELEASE_LIST_STUDENT_SUBMISSION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_assignment_student_submission.method,
				END_POINT.mc_get_assignment_student_submission.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.assignmentId,
					action.payload.urlParams,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getReleaseListStudentSubmissionSuccess({
							releaseListStudentSubmission: data.response.data,
							currentTermId: data.response.currentTermId,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.getReleaseListStudentSubmissionFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const releaseGradeStudentSubmissionEpic = (action$) =>
	action$.pipe(
		ofType(actions.RELEASE_GRADE_STUDENT_SUBMISSION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.release_grade_student_submission.method,
				END_POINT.release_grade_student_submission.url(action.payload.courseId),
				{
					listAssignmentAndTestSubmissionIds: action.payload.listAssignmentAndTestSubmissionIds,
					listParticipationIds: action.payload.listParticipationIds,
				},
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.releaseGradeStudentSubmissionSuccess({
							releaseGradeStudentSubmissionSuccess: true,
						}),
						myCoursesActions.calculatePublicOverallCourseGrade({
							courseId: action.payload.courseId,
							termId: action.payload.currentTermId,
							listStudentId: action.payload.selectedPublicStudentIds,
						}),
						myCoursesActions.mcGetGradeBook({
							courseId: action.payload.courseId,
							termId: action.payload.currentTermId,
							gradeBookColumn: [],
							gradeBookRow: [],
							urlParams: action.payload.urlParams,
							isFetchingGradeBook: true,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.releaseGradeStudentSubmissionFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const calculatePublicOverallCourseGradeEpic = (action$) =>
	action$.pipe(
		ofType(actions.CALCULATE_PUBLIC_OVERALL_COURSE_GRADE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.calculate_public_overall_course_grade.method,
				END_POINT.calculate_public_overall_course_grade.url(action.payload.courseId, action.payload.termId),
				{
					listStudentId: action.payload.listStudentId,
				},
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.releaseGradeStudentSubmissionSuccess({
							calculatePublicOverallCourseGradeSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.releaseGradeStudentSubmissionFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const createNewTestEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_NEW_TEST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.create_new_test.method,
				END_POINT.create_new_test.url(action.payload.orgId, action.payload.courseId, action.payload.unitId),
				action.payload.data,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.createNewTestSuccess({
							createNewTestSuccess: true,
							createdActivity: data?.response?.test,
							test: data.response.test,
							isFetchingTestDetails: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.createNewTestFailed({
							createNewTestSuccess: false,
							error: error.response.errors,
							isFetchingTestDetails: false,
						}),
					),
				),
			),
		),
	);
const getTestDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TEST_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_test_detail.method,
				END_POINT.get_test_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.testId,
				),
				action.payload.data,
			).pipe(
				mergeMap((data) => {
					const actions = [
						myCoursesActions.getTestDetailSuccess({
							getTestDetailSuccess: true,
							test: data.response.test,
							// isBusy: false,
							isFetchingTestDetails: false,
							fetchSessionsAfterDone: false,
						}),
					];
					if (action.payload?.fetchSessionsAfterDone) {
						actions.push(
							myCoursesActions.getAllSessions({
								orgId: action.payload.orgId,
								courseId: action.payload.courseId,
							}),
						);
					}
					return of(...actions);
				}),
				catchError((error) =>
					of(
						myCoursesActions.getTestDetailFailed({
							getTestDetailSuccess: false,
							error: error.response.errors,
							// isBusy: false,
							isFetchingTestDetails: false,
						}),
					),
				),
			),
		),
	);
const deleteTestEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_TEST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_test.method,
				END_POINT.delete_test.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.testId,
				),
				action.payload.data,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.deleteTestSuccess({
							deleteTestSuccess: true,
							isBusy: false,
							isDeletingActivity: false,
							deleteActivitySuccess: true,
							deletedActivityInfo: {
								id: action.payload.testId,
								activityType: ACTIVITY_CATEGORY.TEST,
								unitId: action.payload.unitId,
							},
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.deleteTestFailed({
							deleteTestSuccess: false,
							error: error.response.errors,
							isBusy: false,
							isDeletingActivity: false,
							deleteActivitySuccess: false,
							deletedActivityInfo: {},
						}),
					),
				),
			),
		),
	);
const editTestEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.EDIT_TEST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_test.method,
				END_POINT.edit_test.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.testId,
				),
				action.payload.data,
			).pipe(
				mergeMap(() => {
					const masterId = action.payload.testId;
					const { name } = action.payload.data;

					const oldList = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod ?? []);
					let newSectionsByGradingPeriod = updateSectionsByGradingPeriod(
						masterId,
						{ name: name },
						oldList,
						ACTIVITY_CATEGORY.TEST,
					);

					const currentCourseItemByUnit = state$.value?.AllCourses?.courseItemByUnit ?? {};
					const typeName = ACTIVITY_CATEGORY_NAME[ACTIVITY_CATEGORY.TEST];
					const courseItemByUnit = updateCourseItemByUnit(currentCourseItemByUnit, masterId, { name: name }, typeName);
					return of(
						myCoursesActions.editTestSuccess({
							editTestSuccess: true,
							isEditingTest: false,
							isFetchingTestDetails: false,
							sectionsByGradingPeriod: newSectionsByGradingPeriod,
							courseItemByUnit: courseItemByUnit,
						}),
					);
				}),
				catchError((error) =>
					of(
						myCoursesActions.editTestFailed({
							isEditingTest: false,
							editTestSuccess: false,
							error: error.response.errors,
							isFetchingTestDetails: false,
						}),
					),
				),
			),
		),
	);

const getActivitiesByUnitsEpic = (action$) =>
	action$.pipe(
		ofType(actions.MC_GET_ACTIVITIES_BY_UNITS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_activities_by_units.method,
				END_POINT.mc_get_activities_by_units.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.gradingPeriodId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetActivitiesByUnitsSuccess({
							listActivitiesByUnits: data?.response?.data,
							mcGetActivitiesByUnitsSuccess: true,
							isFetchingActivitiesByUnits: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcGetActivitiesByUnitsFailed({
							mcGetActivitiesByUnitsFailed: error?.response?.errors,
							isFetchingActivitiesByUnits: false,
						}),
					),
				),
			),
		),
	);

const getCourseActivityDetailsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COURSE_ACTIVITY_DETAILS),
		debounceTime(500),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_course_activity_details.method,
				END_POINT.get_course_activity_details.url(
					action.payload.organizationId,
					action.payload.courseId,
					action.payload.studentId,
					action.payload.activityType,
					action.payload.activityId,
				),
			).pipe(
				mergeMap((data) => {
					let { activity, activityType } = data.response.data;
					const type = Number(activityType);
					if (type === ACTIVITY_CATEGORY.TEST) {
						activity.studyForTest.type = type;
						activity.studyForTest.studyForTestId = activity.studyForTest.id; // for distinguish between Test and studyForTest
					}

					activity.type = type;

					return of(
						myCoursesActions.getCourseActivityDetailsSuccess({
							courseActivityDetails: activity,
							isFetchingCourseActivityDetails: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						CommonActions.commonSetState({
							subcodeError: error?.response?.errors?.subcode,
							isFetchingCourseActivityDetails: false,
						}),
						myCoursesActions.getCourseActivityDetailsFailed({
							courseActivityDetails: null,
							isFetchingCourseActivityDetails: false,
						}),
					),
				),
			),
		),
	);

const validateBelongToCourseEpic = (action$) =>
	action$.pipe(
		ofType(actions.VALIDATE_BELONG_TO_COURSE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.validateBelongToCourse.method,
				END_POINT.validateBelongToCourse.url(
					action.payload.organizationId,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.validateBelongToCourseSuccess({
							subCodeError: null,
							isValidateUserBelongToCourse: false,
						}),
					),
				),
				catchError((err) =>
					of(
						myCoursesActions.validateBelongToCourseFailed({
							subCodeError: err.response.errors.subcode,
							isValidateUserBelongToCourse: false,
						}),
					),
				),
			),
		),
	);

const removeShadowFromCourseDayListEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.REMOVE_SHADOW_FROM_COURSE_DAY_LIST),
		mergeMap((action) => {
			const { sectionIds, masterId, activityType, unitId } = action.payload;

			const listGroup = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod ?? []);
			const newList = removeShadowFromCourseItemByUnit(listGroup, sectionIds, masterId, activityType);

			const listActivitiesByUnits = cloneDeep(state$.value?.AllCourses?.listActivitiesByUnits ?? []);

			const typeName = ACTIVITY_CATEGORY_NAME[activityType];
			if (listActivitiesByUnits?.length) {
				const list =
					listActivitiesByUnits[listActivitiesByUnits.findIndex((item) => Number(item.id) === Number(unitId))];
				if (list) {
					const listActivity = list?.items?.[typeName];
					const index = listActivity ? listActivity.findIndex((item) => item.id === masterId) : -1;
					if (index !== -1) {
						listActivity.splice(index, 1);
					}
				}
			}
			return of(
				myCoursesActions.removeShadowFromCourseDayListSuccess({
					listActivitiesByUnits,
					sectionsByGradingPeriod: newList,
				}),
			);
		}),
		catchError(() => of(myCoursesActions.removeShadowFromCourseDayListFailed({}))),
	);

const pollingGenerateStatusEpic = (action$) =>
	action$.pipe(
		ofType(actions.POLLING_GENERATE_STATUS),
		switchMap((action) => {
			const pollingAction$ = interval(1000).pipe(
				takeUntil(merge(timer(2 * 60 * 1000), action$.pipe(ofType(actions.STOP_POLLING_GENERATE_STATUS)))),
				mergeMap(() =>
					makeAjaxRequest(
						END_POINT.pooling_generate_status.method,
						END_POINT.pooling_generate_status.url(action.payload.organizationId, action.payload.schoolYearId),
					).pipe(
						mergeMap((data) => {
							const statusList = data.response.statusList;
							const observable = [];

							if (!statusList?.length) {
								observable.push(myCoursesActions.stopPollingGenerateStatus({}));
								observable.push(
									myCoursesActions.pollingGenerateStatusSuccess({
										hasProcessedAll: true,
									}),
								);
								return from(observable);
							}
							const stillProcessing = statusList.every((item) => item.status === GEN_COURSE_STATUS.PROCESSING);
							if (!stillProcessing) {
								observable.push(myCoursesActions.stopPollingGenerateStatus({}));
							}
							observable.push(
								myCoursesActions.pollingGenerateStatusSuccess({
									hasProcessedAll: !stillProcessing,
								}),
							);
							return from(observable);
						}),
						catchError((error) => of(myCoursesActions.pollingGenerateStatusFailed(error))),
						timeout(6000),
						retryWhen((errors) =>
							errors.pipe(
								tap((error) => console.error('Polling error:', error)),
								delay(1000),
								take(2),
							),
						),
					),
				),
				endWith({
					type: actions.STOP_POLLING_GENERATE_STATUS,
					payload: {
						isPolling: false,
						hasProcessedAll: true, // currently dont have behaviour to handle case timeout from sever or status not matching
					},
				}),
			);

			return pollingAction$;
		}),
	);

const getActivityStudentWorkListEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ACTIVITY_STUDENT_WORK_LIST),
		debounceTime(500),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_activity_student_work_list.method,
				END_POINT.get_activity_student_work_list.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getActivityStudentWorkListSuccess({
							activityStudentWorkList: data?.response?.section,
							activiTyDetailWorkList: data?.response?.activiTyDetail,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getActivityStudentWorkListFailed({
							error: error?.response?.errors,
							errorCode: error?.response?.code,
						}),
					),
				),
			),
		),
	);

const customizeDueDateEpic = (action$) =>
	action$.pipe(
		ofType(actions.CUSTOMIZE_DUE_DATE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.custom_due_date.method,
				END_POINT.custom_due_date.url(action.payload.organizationId, action.payload.courseId),
				action.payload.body,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.customizeDueDateSuccess({
							customizeDueDateSuccess: true,
							customizeDueDate: data?.response,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.customizeDueDateFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getCoursePermissionsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COURSE_PERMISSION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.course_permissions.method,
				END_POINT.course_permissions.url(action.payload.organizationId, action.payload.courseId),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getCoursePermissionsSuccess({
							coursePermissions: data.response.permissions,
							fetchingCoursePermission: false,
						}),
					),
				),
				catchError((error) =>
					of(
						myCoursesActions.getCoursePermissionsFailed({
							error: error?.response?.errors,
							fetchingCoursePermission: false,
						}),
					),
				),
			),
		),
	);

const getToGradeListEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TO_GRADE_LIST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_to_grade_list.method,
				END_POINT.get_to_grade_list.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.myCoursesSetState({
							toGradeList: data.response.listCourses,
							totalSubmission: data.response.totalSubmission,
							isFetchGradeList: false,
						}),
					),
				),
				catchError(() => of(myCoursesActions.myCoursesSetState({ isFetchGradeList: false }))),
			),
		),
	);

export default [
	getAssignmentDetailEpic,
	createNewAssignmentEpic,
	editAssignmentEpic,
	editMetadataAssignmentEpic,
	deleteAssignmentEpic,
	getShadowAssignmentDetailEpic,
	editShadowAssignmentEpic,
	removeShadowFromCourseDayListEpic,
	pollingGenerateStatusEpic,
	createNewTestEpic,
	getTestDetailEpic,
	editTestEpic,
	deleteTestEpic,
	validateActivityEpic,
	getMyCoursesListEpic,
	getSyllabusEpic,
	updateSyllabusEpic,
	// getGradeWeightEpic,
	// updateGradWeightEpic,
	createNewUnitEpic,
	getTermsListByCourseEpic,
	getLinkedContentsEpic,
	getUnitByCourseEpic,
	getCoursePermissionEpic,
	createQuizEpic,
	getToGradeListEpic,
	editUnitEpic,
	deleteLessonEpic,
	getQuizEpic,
	editQuizEpic,
	deleteQuizEpic,
	deleteUnitEpic,
	getCourseDayListEpic,
	getAllCourseDaysEpic,
	getShadowLessonDetailEpic,
	getShadowQuizDetailEpic,
	removeShadowAssignmentEpic,
	removeShadowLessonEpic,
	removeShadowTestEpic,

	// Planning Tab
	getUnitsByTermEpic,
	getCourseItemByUnitEpic,
	getSectionsByGradingPeriodEpic,
	getCourseDayDetailEpic,
	updateShadowLessonEpic,
	updateShadowQuizzesEpic,
	updateShadowAssignmentsEpic,
	getActivitiesByUnitsEpic,
	getActivityStudentWorkListEpic,

	// Student
	getCourseContentEpic,
	getLessonDetailsEpic,
	studentViewShadowAssignmentEpic,
	studentEditShadowAssignmentEpic,
	getQuizDetailsEpic,
	getAssignmentStudentSubmissionEpic,
	getActivitiesByUnitEpic,
	// getGraderDetailEpic,

	// Publish shadow lessons, assignments, quizzes at the master level
	updateMasterItemEpic,
	getShadowItemValidationsEpic,
	changeShadowItemsStatusAtMasterLevelEpic,
	consolidateAssignmentEpic,
	consolidateTestEpic,
	consolidateQuizEpic,
	consolidateLessonEpic,
	getAllSessionsEpic,
	getAllScheduleSectionsEpic,

	relinkShadowItem,
	getSectionDetail,
	getReleaseListStudentSubmissionEpic,
	releaseGradeStudentSubmissionEpic,
	calculatePublicOverallCourseGradeEpic,
	...gradeWeightingEpics,
	...gradeWeightingEpicsV2,
	...gradeBookEpics,
	...courseContentEpics,
	...gradingEpics,
	...gradeEpic,

	getCourseActivityDetailsEpic,
	// participation
	createNewMasterParticipationEpic,
	getParticipationDetailEpic,
	editParticipationEpic,
	deleteParticipationEpic,
	validateBelongToCourseEpic,
	customizeDueDateEpic,

	getCoursePermissionV2Epic,
];
