import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

import { isSafari } from 'utils/checkBrowser';
import { getMaterialIconByExt } from 'utils/getMaterialIconByExt';

import clsx from 'clsx';
import { Typography } from 'ella-storybook';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { splitNameAndExtension } from 'utils';

import { isGoogleFileSupported } from '../../shared/Google/constants';
import { MEDIA_TYPES } from '../../shared/Media/constants';

import { FILE_TYPE } from './constants';
import GoogleViewFile from './GoogleViewFile';
import useStyles from './styled';

function PreviewFile(props) {
	const {
		fileType,
		url,
		isFullScreen,
		fileName,
		currentFile,
		fileInformation,
		isRenderControlButton = true,
		hasAttachments,
	} = props;

	const { t } = useTranslation('common');
	const isFetchingFile = useSelector((state) => state.Common.isFetchingFile);

	const checkImage = /^image/;
	const { classes } = useStyles({ isFullScreen, fileType });
	const onClose = () => {
		const { onClose } = props;
		if (onClose) {
			onClose();
		}
	};

	const onPrevious = () => {
		if (props.onPrevious) {
			props.onPrevious(currentFile);
		}
	};

	const onNext = () => {
		if (props.onNext) {
			props.onNext(currentFile);
		}
	};

	const renderHeader = () => {
		const { isShowFilename } = props;
		if (isFullScreen || isShowFilename) {
			return (
				<Box className={classes.previewHeader}>
					<Box className='file-name text-ellipsis'>
						<Box className='icon'>{getMaterialIconByExt(splitNameAndExtension(fileName), currentFile)}</Box>
						<Box className='data text-ellipsis'>{splitNameAndExtension(fileName, 'name')}</Box>
						<Box>{splitNameAndExtension(fileName, 'extension')}</Box>
					</Box>
					{isFullScreen && (
						<Box className='icon-close' onClick={onClose}>
							<CloseOutlinedIcon fontSize='inherit' />
						</Box>
					)}
				</Box>
			);
		}
		return null;
	};

	const renderControlButton = () => {
		const { disabledControlButton } = props;
		if (disabledControlButton || isFullScreen) return null;
		return (
			<>
				<Box className={`${classes.controlIcon} left`} onClick={onPrevious}>
					<ArrowBackOutlinedIcon fontSize='inherit' />
				</Box>
				<Box className={`${classes.controlIcon} right`} onClick={onNext}>
					<ArrowForwardOutlinedIcon fontSize='inherit' />
				</Box>
			</>
		);
	};

	const renderContent = (fileType) => {
		switch (true) {
			case isGoogleFileSupported(fileType) && currentFile?.mediaType === MEDIA_TYPES.GOOGLE_DRIVE_FILE:
				return (
					<Box className={classes.googleView}>
						<GoogleViewFile url={currentFile.url} sourceId={currentFile.sourceId} />
					</Box>
				);
			case !isGoogleFileSupported(fileType) && currentFile?.mediaType === MEDIA_TYPES.GOOGLE_DRIVE_FILE:
				return (
					<Box className={classes.centerHorizontalVertical}>
						<Box fontSize={18}>
							{t('no_preview')}
							{'. '}
							<span>
								<a href={currentFile.url} target='_blank' rel='opener noreferrer'>
									{t('view_in_new_tab')}
								</a>{' '}
							</span>
						</Box>
					</Box>
				);
			case fileType === FILE_TYPE.PDF:
				return (
					<Box className={classes.centerHorizontalVertical}>
						<iFrame
							src={`${process.env.REACT_APP_API_URL}/view/${fileName}?key=${fileInformation?.filename}`} // to view pdf file with the right name
							className={classes.pdfArea}
						/>
					</Box>
				);
			case fileType === FILE_TYPE.AUDIO_MP3:
			case fileType === FILE_TYPE.AUDIO_MPEG:
				return (
					<Box className={classes.centerHorizontalVertical}>
						<audio controls key={url}>
							<source src={url} type='audio/mpeg' />
							{t('no_support_audio_tag')}
						</audio>
					</Box>
				);
			case fileType === FILE_TYPE.VIDEO_MP4:
			case fileType === FILE_TYPE.VIDEO_WEBM:
			case fileType === FILE_TYPE.VIDEO_OGG:
			case fileType === FILE_TYPE.AUDIO_OGG:
				return (
					<Box className={classes.centerHorizontalVertical}>
						<video controls key={url}>
							<source src={url} type={fileType} />
							{t('no_support_video_tag')}
						</video>
					</Box>
				);
			case checkImage.test(fileType) && (isSafari() || !fileInformation?.mimetype.includes('tif')):
				return (
					<Box className='image-area'>
						<img src={url} alt='' />
					</Box>
				);
			// case !(studentSubmittedFiles.length || studentSubmittedFilesStudent.length):
			//   return (
			// <Box className={classes.centerHorizontalVertical}>
			//   <Typography className={classes.text} variant='titleSmall'>
			//     {t('no_attachments', { ns: 'myCourses' })}
			//   </Typography>
			// </Box>
			//   );
			default:
				return (
					<Box className={classes.centerHorizontalVertical}>
						<Typography className={classes.text} variant='titleSmall'>
							{t('no_preview')}
						</Typography>
					</Box>
				);
		}
	};

	const renderNoAttachment = () => (
		<Box className={classes.centerHorizontalVertical}>
			<Typography className={classes.text} variant='titleSmall'>
				{t('no_attachments', { ns: 'myCourses' })}
			</Typography>
		</Box>
	);
	const renderFetching = () => (
		<Box className={classes.centerHorizontalVertical}>
			<Box fontSize={20}>
				<CircularProgress color='secondary' />
			</Box>
		</Box>
	);

	const renderPreviewFile = () => (
		<>
			{renderHeader()}
			{isRenderControlButton && renderControlButton()}
			{renderContent(fileType)}
		</>
	);

	return (
		<Box className={clsx(classes.root, { [classes.fullScreen]: isFullScreen })}>
			{isFetchingFile ? (
				renderFetching()
			) : url !== '' ? (
				renderPreviewFile()
			) : isEmpty(currentFile) && !hasAttachments ? (
				renderNoAttachment()
			) : (
				<></>
			)}
		</Box>
	);
}

PreviewFile.propTypes = {
	disabledControlButton: PropTypes.bool,
	isShowFilename: PropTypes.bool,
	fileType: PropTypes.string,
	url: PropTypes.string,
	content: PropTypes.string,
	t: PropTypes.func,
	isFullScreen: PropTypes.bool,
	fileName: PropTypes.string,
	onClose: PropTypes.func,
	onPrevious: PropTypes.func,
	onNext: PropTypes.func,
	currentFile: PropTypes.object,
	fileInformation: PropTypes.object,
	isRenderControlButton: PropTypes.bool,
};

export default PreviewFile;
