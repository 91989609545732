import { useEffect } from 'react';

const useCurrentSection = (queryParams, defaultSection, sections, setState) => {
	const { setDefaultSection, setSelectedSection } = setState;
	const defaultSectionId = queryParams.get('defaultSectionId');

	useEffect(() => {
		setDefaultSection((prev) => (defaultSectionId && defaultSectionId !== prev ? defaultSectionId : prev));
	}, [queryParams]);

	useEffect(() => {
		if (!isNaN(+`${defaultSection}`)) {
			setSelectedSection(Number(defaultSection));
			return;
		}

		if (sections?.length) setSelectedSection((prev) => prev || -1); // default section
	}, [sections, defaultSection]);
};

export default useCurrentSection;
