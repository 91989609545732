import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/CloseRounded';
import { AppBar, Avatar, Toolbar } from '@mui/material';

// import { AuthDataContext } from 'AppRoute/AuthProvider';

import TblIcon from 'components/TblIcon';
import { TblIconButton } from 'components/TblIconButton';
import TblTooltipDynamic from 'components/TblTooltipDynamic';

import clsx from 'clsx';
import { BaseModal, Typography } from 'ella-storybook';
import { Box, Button, Chip, Divider, IconButton, Skeleton } from 'ella-storybook';
import myCoursesActions from 'modules/MyCourses/actions';
import { isNumber } from 'modules/MyCourses/utils';
import PropTypes from 'prop-types';

import GraderActions from '../../actions';

import { useStyles } from './styles';

function GraderNavBar({
	onCloseDialog,
	schoolYearSelected,
	basicInfo,
	studentInfo,
	activityName,
	totalStudent,
	currentStudentIndex,
	handlePreviousStudentSelect,
	handleNextStudentSelect,
	handleRedirectToNewTab,
	isExtraCredit,
}) {
	const { t } = useTranslation('grader');
	// const authContext = useContext(AuthDataContext);
	const [isLeave, setIsLeavePage] = useState(false);
	const loadingLogSubmission = useSelector((state) => state?.Grader?.loadingLogSubmission);

	const { classes } = useStyles();
	const dispatch = useDispatch();

	const openAskLeave = () => {
		setIsLeavePage(true);
	};

	const onLeave = () => {
		setIsLeavePage(false);
		onCloseDialog();
	};

	const onCancelLeave = () => {
		setIsLeavePage(false);
	};

	const stopGrading = () => {
		if (schoolYearSelected) {
			dispatch(
				myCoursesActions.myCoursesSetState({
					isFetchGradeList: true,
				}),
			);
		}
		if (loadingLogSubmission) {
			openAskLeave();
			return;
		}
		!loadingLogSubmission && onCloseDialog();
	};

	const handleOpenStudentListDialog = useCallback(() => {
		dispatch(
			GraderActions.graderSetState({
				openStudentListDialog: true,
			}),
		);
	}, []);

	return (
		<>
			<BaseModal
				size={'small'}
				open={isLeave}
				title={t('common:leave_page')}
				onClose={onCancelLeave}
				customFooter={
					<Box className='customFooterModal'>
						<Button variant='outlined' onClick={onCancelLeave}>
							{t('common:cancel')}
						</Button>
						<Button onClick={onLeave}>{t('common:leave')}</Button>
					</Box>
				}
				contentComponent={<Typography variant='bodyMediumRegular'>{t('common:change_not_save')}</Typography>}
			/>

			<AppBar className={classes.graderNavBarRoot} position='static'>
				<Toolbar className={classes.toolbar}>
					<Box mr={2} display={'flex'} alignItems={'center'} flex={'1 1 auto'} overflow={'hidden'}>
						<IconButton aria-label='close' size='medium' onClick={stopGrading} className={classes.iconClose}>
							<CloseIcon />
						</IconButton>

						{basicInfo?.courseName ? (
							<Box display={'flex'} flexDirection={'column'} overflow={'hidden'}>
								<Box display={'flex'} alignItems={'center'}>
									<Typography component={'div'} variant='titleLarge' className={clsx(classes.courseName)} noWrap>
										<TblTooltipDynamic title={activityName} placement={'right'}>
											{activityName}
										</TblTooltipDynamic>
									</Typography>
									{isExtraCredit && (
										<Chip
											status='information'
											size='large'
											label={t('extra_credit', { ns: 'myCourses' })}
											sx={(theme) => ({
												color: theme.newColors.violet[900],
												ml: theme.spacing(2),
												mr: theme.spacing(0.75),
											})}
										/>
									)}
									<TblIconButton className={classes.iconBtn} onClick={handleRedirectToNewTab}>
										<TblIcon icon='open_in_new' fontSize={'20px'} />
									</TblIconButton>
								</Box>
								<Typography variant='bodySmallRegular' sx={{ color: (theme) => theme.newColors.gray[500] }} noWrap>
									{basicInfo.courseName}
								</Typography>
							</Box>
						) : (
							<Skeleton height={32} width={120} />
						)}
					</Box>
					<Box display={'flex'} gap={'16px'} alignItems={'center'}>
						<Box
							display={'flex'}
							alignItems={'center'}
							height={(theme) => theme.spacing(6)}
							className={classes.actionBox}
							onClick={handleOpenStudentListDialog}
						>
							<Typography
								variant='bodyMediumRegular'
								className={`${classes.sectionNameBox} ${classes.sectionName} text-ellipsis`}
								noWrap
								flex={'0 0 auto'}
								component={'div'}
							>
								{studentInfo?.sectionName}
							</Typography>

							<Box className={clsx(classes.box, classes.divider)} borderWidth={'1px'} padding={'0 8px'}>
								<Divider orientation='vertical' flexItem />
							</Box>

							<Box className={classes.studentInfo}>
								<Box className={classes.avatarContainer}>
									<Avatar className={`${classes.rootAvatar}`}>
										{((studentInfo?.name || '')?.charAt(0).toUpperCase() || '') +
											((studentInfo?.name || '')?.split(' ')?.[1]?.charAt(0).toUpperCase() || '')}
									</Avatar>
								</Box>
								<Typography
									variant='titleSmall'
									className={clsx('text-ellipsis')}
									sx={{ color: 'black' }}
									flex={'0 0 196px'}
									component={'div'}
								>
									{studentInfo?.name}
								</Typography>
								<Typography component={'div'} flex={'0 0 auto'} variant='bodySmallRegular' sx={{ color: 'black' }}>
									{t('overall_grade_on_total_point', {
										ns: 'grader',
										overallGrade: isNumber(studentInfo?.overallGrade) ? studentInfo?.overallGrade : '--',
										totalPoint: studentInfo?.totalPoint,
									})}
								</Typography>
								<Box display={'flex'}>
									<TblIcon icon='filter_list' fontSize={'24px'} />
								</Box>
							</Box>
						</Box>

						<Box display={'flex'} gap={'16px'} padding={'4px 0'}>
							<TblIconButton
								disabled={currentStudentIndex === -1 || currentStudentIndex === 0}
								className={classes.iconBtn}
								onClick={handlePreviousStudentSelect}
							>
								<TblIcon icon='chevron_left' fontSize={'24px'} />
							</TblIconButton>
							<TblIconButton
								disabled={currentStudentIndex === -1 || currentStudentIndex === totalStudent - 1}
								className={classes.iconBtn}
								onClick={handleNextStudentSelect}
							>
								<TblIcon icon='chevron_right' fontSize={'24px'} />
							</TblIconButton>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}

GraderNavBar.propTypes = {
	activityInfo: PropTypes.shape({
		activityName: PropTypes.any,
	}),
	activityName: PropTypes.any,
	activitySelected: PropTypes.number,
	activitySelectedInfo: PropTypes.object,
	basicInfo: PropTypes.shape({
		courseName: PropTypes.any,
	}),
	currentStudentIndex: PropTypes.number,
	filtersSelected: PropTypes.array,
	graderDetail: PropTypes.object,
	gradingList: PropTypes.array,
	handleNextStudentSelect: PropTypes.any,
	handlePreviousStudentSelect: PropTypes.any,
	handleRedirectToNewTab: PropTypes.func,
	isExtraCredit: PropTypes.bool,
	onCloseDialog: PropTypes.func,
	schoolYearSelected: PropTypes.any,
	sectionSelected: PropTypes.any,
	studentInfo: PropTypes.shape({
		name: PropTypes.string,
		overallGrade: PropTypes.any,
		sectionName: PropTypes.any,
		totalPoint: PropTypes.any,
	}),
	totalStudent: PropTypes.number,
};

GraderNavBar.defaultProps = {
	handleRedirectToNewTab: () => {},
	isExtraCredit: false,
};

export default React.memo(GraderNavBar);
