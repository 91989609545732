import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, Divider } from '@mui/material';

import { TblIconButton } from 'components/TblIconButton';
import GuardianMenu from 'components/TblSidebar/GuardianMenu';
import UserProfile from 'components/TblSidebar/UserProfile';

import { isGuardian } from 'utils/roles';

import { ROUTE_AUTH } from 'shared/Auth/constantsRoute';

import { useAuthDataContext } from 'AppRoute/AuthProvider';
import clsx from 'clsx';
import { Box, MenuItem, MenuList, Popover, Typography } from 'ella-storybook';
import { orderBy } from 'lodash';
import routes from 'routes';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: theme.spacing(0.5),
		'&:hover': {
			backgroundColor: theme.newColors.gray[100],
		},
	},
	activeButton: {
		backgroundColor: theme.newColors.gray[100],
	},
	rootAvatar: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		background: theme.newColors.gray[100],
		color: theme.mainColors.gray[6],
		// fontSize: theme.fontSize['text-sm'],
		...theme.typography.bodyLargeBold,
	},
	popover: {
		'& .MuiPaper-root': {
			width: theme.spacing(32),
			top: `${theme.spacing(7.125)} !important`,
		},
	},
	menuItem: {
		color: theme.mainColors.primary1[0],
		fontSize: theme.fontSize['text-sm'],
		margin: theme.spacing(1),
		paddingTop: theme.spacing(2.75),
		paddingBottom: theme.spacing(2.75),
	},
	userMenuIcn: {
		fontSize: theme.fontSizeIcon.medium,
	},
	avatar: {
		minWidth: 40,
		fontSize: theme.fontSize['text-sm'],
	},
	logout: {
		color: theme.newColors.red[600],
	},
	domain: {
		margin: theme.spacing(1),
	},
	divider: {
		marginTop: theme.spacing(1),
		height: '1px',
		background: theme.newColors.gray[100],
		borderRadius: theme.spacing(0.5),
	},
}));

function TblUserMenu() {
	const { classes } = useStyles();
	const { currentUser } = useAuthDataContext();

	const [anchorRef, setAnchorRef] = useState(null);
	const history = useHistory();
	const [open, setOpen] = useState(false);

	const toggle = (e) => {
		e.preventDefault();
		setAnchorRef(e.target);
		setOpen(!open);
	};
	const handleClose = (event, item) => {
		if (anchorRef && anchorRef.contains(event.target)) {
			return;
		}
		setOpen(false);
		if (
			// item
			//NOTE: Change condition to handle onCLose function in GuardianMenu
			!!item?.path
		) {
			history.push(item.path);
		}
	};

	const getMenuItems = () => {
		const menu = routes?.reduce((total, elm) => {
			if (elm?.userMenu) return [...total, elm];
			return total;
		}, []);
		return orderBy(menu, ['userMenu.order'], ['asc']);
	};
	const userInfo = {
		name: `${currentUser.firstName} ${currentUser.lastName}`,
		email: currentUser.email,
	};

	return (
		<>
			<TblIconButton
				className={clsx(classes.root, { [classes.activeButton]: open })}
				disableRipple
				aria-controls={open ? 'menu-list-grow' : undefined}
				ref={anchorRef}
				onClick={toggle}
			>
				<Avatar className={classes.rootAvatar}>
					{(currentUser?.firstName?.charAt(0).toUpperCase() || '') +
						(currentUser?.lastName?.charAt(0).toUpperCase() || '')}
				</Avatar>
			</TblIconButton>
			<Popover
				className={classes.popover}
				keepMounted
				anchorEl={anchorRef}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuList>
					<Box className={classes.domain}>
						<UserProfile itemInfo={userInfo} />
						<Divider className={classes.divider} light />
					</Box>

					{isGuardian(currentUser) && (
						<>
							<GuardianMenu handleCloseMenuList={handleClose} />
							<Divider className={classes.divider} light />
						</>
					)}
					{getMenuItems().map((item, index) => (
						<MenuItem onClick={(e) => handleClose(e, item)} key={index} className={classes.menuItem} disableRipple>
							<Typography
								variant='bodyMediumRegular'
								className={clsx({
									[classes.logout]: item.path === ROUTE_AUTH.LOGOUT,
								})}
							>
								{item.userMenu.title}
							</Typography>
						</MenuItem>
					))}
				</MenuList>
			</Popover>
		</>
	);
}

export default TblUserMenu;
