import React from 'react';

import Drawer from '@mui/material/Drawer';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import SidebarMenu from './SidebarMenu';
import styles from './styled';

class TblSidebar extends React.PureComponent {
	state = {
		onHover: false,
	};
	handleHover = (hover) => {
		this.setState({ onHover: hover });
	};
	render() {
		const { classes, routes, openSideBar, ...rest } = this.props;
		return (
			<Drawer
				open={openSideBar}
				anchor='left'
				{...rest}
				classes={{
					root: classes.root,
					paper: clsx(classes.paper, {
						[classes.drawerHoverOpen]: this.state.onHover,
						[classes.drawerHoverClose]: !this.state.onHover,
						[classes.drawerOpen]: openSideBar,
						[classes.drawerClose]: !openSideBar,
					}),
				}}
				variant='permanent'
				onClose={this.closeSidebar}
			>
				<SidebarMenu
					routes={routes}
					closeSidebar={this.closeSidebar}
					className={classes.list}
					onHover={!openSideBar && this.handleHover}
				/>
			</Drawer>
		);
	}
}

TblSidebar.propTypes = {
	classes: PropTypes.object,
	routes: PropTypes.array,
	openSideBar: PropTypes.bool,
};

export default withStyles(TblSidebar, styles);
