import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';

import { ROUTE_MY_PROFILE } from './constantsRoute';
import reducers from './reducers';

const MyProfile = loadable(() => import(/* webpackChunkName: "LazyMyProfile" */ './containers/MyProfile'));
const EnterVerifyCode = loadable(() =>
	import(/* webpackChunkName: "LazyMyProfile" */ './components/VerifyPhone/EnterVerifyCode'),
);
const VerifyPhoneSuccess = loadable(() =>
	import(/* webpackChunkName: "LazyMyProfile" */ './components/VerifyPhone/VerifyPhoneSuccess'),
);
const AddPhoneNumber = loadable(() =>
	import(/* webpackChunkName: "LazyMyProfile" */ './components/VerifyPhone/AddPhoneNumber'),
);
const OneMoreStep = loadable(() => import(/* webpackChunkName: "LazyMyProfile" */ './containers/OneMoreStep'));

export default [
	{
		path: ROUTE_MY_PROFILE.DEFAULT,
		component: MyProfile,
		private: true,
		exact: true,
		userMenu: {
			title: 'My Profile',
			order: 1,
		},
	},
	{
		path: ROUTE_MY_PROFILE.VERIFY_PHONE,
		component: withReducer('MyProfile', reducers)(EnterVerifyCode),
		private: true,
		exact: true,
	},
	{
		path: ROUTE_MY_PROFILE.VERIFY_PHONE_SUCCESS,
		component: withReducer('MyProfile', reducers)(VerifyPhoneSuccess),
		private: true,
		exact: true,
	},
	{
		path: ROUTE_MY_PROFILE.ADD_PHONE_NUMBER,
		component: withReducer('MyProfile', reducers)(AddPhoneNumber),
		private: true,
		exact: true,
	},
	{
		path: ROUTE_MY_PROFILE.ONE_MORE_STEP,
		component: withReducer('MyProfile', reducers)(OneMoreStep),
		private: true,
		exact: true,
	},
];
