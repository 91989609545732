import { Box } from 'ella-storybook';

import TooltipSubmissionAttempts from './components/SubmissionAttempt/TooltipSubmissionAttempts';

export const QUIZ_TYPE = {
	ANNOUNCED: 1,
	POP: 2,
};
export const SESSIONS_DUE_LATE_DEFAULT = 3;
export const LOWEST_GRADE_PERCENT_DEFAULT = 40;
export const DEDUCT_DEFAULT = 5;
export const MAXIMUM_DEDUCTION = 40;
export const PARTIAL_CREDIT_DEFAULT = false;
export const SUBMISSION_REQUIRED_DEFAULT = true;
export const EXTRA_CREDIT_DEFAULT = false;
export const SUBMISSION_REQUIRED_DEFAULT_TEST = false;
export const ALLOW_LATE_DEFAULT = true;
export const TOTAL_POINTS_DEFAULT = 100;
export const AMOUNT_ATTEMPT_DEFAULT = 1;

export const DEDUCT = {
	MIN: 1,
	MAX: 100,
};

export const WEIGHT = {
	MIN: 1,
	MAX: 100,
};

export const SESSION_DUE = {
	MIN: 0,
	MAX: 30,
};
export const QUIZ_GRADE_NAME = {
	0: 'not_started',
	1: 'not_graded',
	2: 'graded',
};

export const ESTIMATE = {
	MIN: 5,
	MAX: 999,
};

export const SUBMISSION_REQUIRED = [
	{
		label: 'Yes',
		value: true,
	},
	{
		label: 'No',
		value: false,
	},
];

export const GRADE_TYPE = {
	GRADED: 1,
	NON_GRADED: 0,
};
export const GRADE_OPTIONS = [
	{
		label: 'Graded',
		value: GRADE_TYPE.GRADED,
	},
	{
		label: 'Non-graded',
		value: GRADE_TYPE.NON_GRADED,
	},
];
export const GRADE_TYPE_NAME = {
	0: 'non_graded',
	1: 'graded',
};
export const CREDIT_VALUE = [
	{
		value: true,
		label: 'Yes',
	},
	{
		value: false,
		label: 'No',
	},
];
export const ALLOW_LATE = [
	{
		value: true,
		label: 'Yes',
	},
	{
		value: false,
		label: 'No',
	},
];
export const PARTIAL_CREDIT = [
	{
		value: true,
		label: 'Yes',
	},
	{
		value: false,
		label: 'No',
	},
];

const contentTooltipLimited = () => (
	<Box>
		{'Students can submit the assignment a limited number of times, as set by the teacher.'}
		<br />
		{'Once students have submitted their assignment, they cannot edit it.'}
		<br />
		{'Teachers will see all of the student’s submissions.'}
		<br />
		{
			'This mode is ideal for assignments where students are expected to submit their best work and there is no room for revisions. It also suits if students or teachers want to see how the students work has improved in each submission.'
		}
	</Box>
);

const contentTooltipUnLimited = () => (
	<Box>
		{'Students can submit the assignment once.'}
		<br />
		{'After submitting, students can still edit their submission by the due date.'}
		<br />
		{'Teachers will only see one submission, which will be updated in real time.'}
		<br />
		{
			'This mode is ideal for assignments where students are still learning the materials and may need to make revisions.'
		}
	</Box>
);

export const SUBMISSION_ATTEMPTS_TOOLTIP = [
	{
		value: -1,
		label: <TooltipSubmissionAttempts label={'Editable submission'} tooltip={contentTooltipUnLimited()} />,
	},
	{
		value: 1,
		label: <TooltipSubmissionAttempts label={'Limited attempt submission'} tooltip={contentTooltipLimited()} />,
	},
];

export const SUBMISSION_ATTEMPTS = [
	{
		value: -1,
		label: 'Editable submission',
	},
	{
		value: 1,
		label: 'Limited attempt submission',
	},
];

export const ATTEMPT_TYPES = {
	UNLIMITED: -1,
	LIMITED: 1,
};

export const APPLY_DEDUCTION = [
	{
		value: 1,
		label: 'Per hour',
	},
	{
		value: 2,
		label: 'Per day',
	},
	{
		value: 3,
		label: 'Per week',
	},
];

export const DEDUCT_TYPE = [
	{
		value: 1,
		title: 'hour',
	},
	{
		value: 2,
		title: 'day',
	},
	{
		value: 3,
		title: 'week',
	},
];

export const GRADE_CALCULATION_ATTEMPT = [
	{
		value: 1,
		label: 'Highest grade attempt',
	},
	{
		value: 2,
		label: 'Lowest grade attempt',
	},
	{
		value: 3,
		label: 'Average of all attempts',
	},
	{
		value: 4,
		label: 'First graded attempt',
	},
	{
		value: 5,
		label: 'Last graded attempt',
	},
];

export const GRADE_CALCULATION_ATTEMPT_TYPE = {
	HIGHEST_GRADE_ATTEMPT: 1,
	LOWEST_GRADE_ATTEMPT: 2,
	AVERAGE_OF_ALL_ATTEMPTS: 3,
	FIRST_GRADED_ATTEMPT: 4,
	LAST_GRADED_ATTEMPT: 5,
};

export const DEDUCTION = {
	PER_HOURS: APPLY_DEDUCTION[0],
	PER_DAYS: APPLY_DEDUCTION[1],
	PER_WEEKS: APPLY_DEDUCTION[2],
	MIN: 1,
	MAX: 100,
};

export const SESSIONS = {
	CUSTOM: 0,
	END: -1,
	MIN: 1,
	MAX: 99,
};

export const COMPLETE_BY = [
	{
		value: SESSIONS.END,
		label: 'Last session of grading period',
	},
	{
		value: SESSIONS.CUSTOM,
		label: 'Session(s) after original due date',
	},
];

export const DEFAULT_GRADING_RULES = [
	{
		value: false,
		label: 'No',
	},
	{
		value: true,
		label: 'Yes',
	},
];

export const GRADING_PARTIAL_CREDIT = [
	{
		value: false,
		label: 'No',
	},
	{
		value: true,
		label: 'Yes',
	},
];

export const TYPE_OF_CREATE = {
	CREATE_AS_DRAFT: 0,
	CREATE_AND_PUBLISH: 1,
	DISCARD: -1,
};

export const SUBMISSION_METHOD = {
	ONLINE: 1,
	OFFLINE: 2,
	QUIZ: 'QUIZ', // add for gradebook
};

export const PICTURE_IN_CLASS_SUBMISSIONS_METHOD = {
	REQUIRED: 1,
	OPTIONAL: 2,
};
export const SUBMISSION_OPTIONS = [
	{
		value: SUBMISSION_METHOD.ONLINE,
		label: 'Online',
	},
	{
		value: SUBMISSION_METHOD.OFFLINE,
		label: 'Submit in class',
	},
];
export const PICTURE_IN_CLASS_SUBMISSIONS_OPTIONS = [
	{
		value: PICTURE_IN_CLASS_SUBMISSIONS_METHOD.REQUIRED,
		label: 'Required',
	},
	{
		value: PICTURE_IN_CLASS_SUBMISSIONS_METHOD.OPTIONAL,
		label: 'Optional',
	},
];
export function infoByType(type) {
	switch (type) {
		case 0:
			return null;
		case 1:
			return {
				typeName: 'Finish',
				typeLabel: 'Complete assignment',
				typeIcon: 'icon-icn_assignment',
			};
		// case 2:
		//   return ({
		//     typeName: 'Assignment',
		//     typeIcon: 'icon-icn_assignment'
		//   });
		case 3:
			return {
				typeName: 'Study',
				typeLabel: 'Study for',
				typeIcon: 'icon-icn_test1',
			};
		case 2:
		default:
			return {
				typeName: 'N/A',
				typeLabel: 'N/A',
				typeIcon: 'no-icon',
			};
	}
}

export const UPDATE_COURSE_SUBCODE = {
	CAN_NOT_UPDATE_TERM: 2,
	TERM_IN_THE_PAST: 11,
	CAN_NOT_ADD_SECTION_WHEN_PLANNED: 4,
	DELETE_GRADE_WEIGHT_CATEGORY_FAILED: 101,
};
export const COURSE_STATUS = {
	DRAFT: -1,
	WAITING_FOR_TEACHER: 0,
	PUBLISHED: 1,
	ARCHIVED: 2,
};
export const APPLY_SECTIONS = [
	{
		value: 2,
		label: 'All sections',
	},
	{
		value: 1,
		label: 'Customize',
	},
];

export const APPLY_FOR = {
	CUSTOM: 1,
	ALL: 2,
};
export const DATE_TYPE = {
	ASSIGN: 1,
	DUE: 2,
};
export const DIALOG_TYPE = {
	SCHEDULE: 'POST',
	RESCHEDULE: 'PUT',
	REMOVE: 'DELETE',
};

export const PUBLISH_NOW = -1;