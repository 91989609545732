import { safeInstanceOf } from 'roosterjs-editor-dom';
import { ImageEdit, canRegenerateImage, resetImage, resizeByPercentage } from 'roosterjs-editor-plugins/lib/ImageEdit';
import { ImageEditOperation } from 'roosterjs-editor-types';
import { ExperimentalFeatures } from 'roosterjs-editor-types';

function getEditingImage(node) {
	return safeInstanceOf(node, 'HTMLImageElement') && node.isContentEditable;
}

export default class ImageEditPlugin extends ImageEdit {
	allowCrop;

	getName() {
		return 'ImageEdit';
	}

	initialize(editor) {
		this.editor = editor;
		this.allowCrop = editor.isFeatureEnabled(ExperimentalFeatures.ImageCrop);
	}

	dispose() {
		this.editor = null;
	}

	getContextMenuItems(node) {
		const items = [];
		if (getEditingImage(node)) {
			if (this.allowCrop && canRegenerateImage(node)) {
				items.push({
					key: 'crop',
					name: 'Crop image',
					onClick: () => {
						this.setEditingImage(node, ImageEditOperation.Crop);
					},
				});
			}
			items.push(
				{
					key: '25',
					name: 'Resize to 25%',
					onClick: () => {
						resizeByPercentage(this.editor, node, 0.25, this.options.minWidth, this.options.minHeight);
					},
				},
				{
					key: '50',
					name: 'Resize to 50%',
					onClick: () => {
						resizeByPercentage(this.editor, node, 0.5, this.options.minWidth, this.options.minHeight);
					},
				},
				{
					key: '100',
					name: 'Resize to 100%',
					onClick: () => {
						resizeByPercentage(this.editor, node, 1, this.options.minWidth, this.options.minHeight);
					},
				},
				{
					key: 'reset',
					name: 'Reset image',
					onClick: () => {
						resetImage(this.editor, node);
					},
				},
				{
					key: 'delete',
					name: 'Delete image',
					onClick: () => {
						this.editor.deleteNode(node);
					},
				},
			);
		}
		return items;
	}
}
