import React, { Suspense, lazy } from 'react';
const lazyRetry = function (componentImport) {
	return new Promise((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
		// try to import the component
		componentImport()
			.then((component) => {
				window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
				resolve(component);
			})
			.catch((error) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
					console.log('reload chunk');
					return window.location.reload();
				}
				reject(error);
			});
	});
};
/**
 * @param {import('react').ComponentType} ComponentImport LazyComponent import
 * @param {Object} options
 * @param {import('react').ComponentType} options.fallback Loading component
 */
const loadable = (ComponentImport, options = { fallback: <></> }) => {
	const LazyComponent = lazy(() => lazyRetry(ComponentImport));
	const { fallback } = options;
	return (props) => (
		<Suspense fallback={fallback}>
			<LazyComponent {...props} />
		</Suspense>
	);
};

export default loadable;
