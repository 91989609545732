import React from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Scrollbar from 'components/Scrollbar';

import useWindowSize from 'utils/windowSize';

import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

const styles = (theme, props) => ({
	wrapContent: {
		height: props.contentHeight,
	},
});
function Layout1(props) {
	const {
		children,
		className,
		scrollable,
		bgcolor = 'transparent',
		padding = {},
		margin = {},
		classes,
		onScrollBottom,
		isSchoolYearList,
	} = props;
	const theme = useTheme();
	const { pTop = 2, pRight = 5, pBottom = 0, pLeft = 5 } = padding;
	const { mTop = 0, mRight = 0, mBottom = 0, mLeft = 0 } = margin;
	const size = useWindowSize();
	const height = size.height - (theme.navbar.appBar + theme.navbar.breadcrumb);
	const layoutContent = (
		<Box
			className={className}
			height={height}
			bgcolor={bgcolor}
			pt={pTop}
			pl={pLeft}
			pr={pRight}
			pb={pBottom}
			mt={mTop}
			ml={mLeft}
			mr={mRight}
			mb={mBottom}
		>
			<Box
				className={classes.wrapContent}
				style={{ paddingBottom: isSchoolYearList ? theme.spacing(16.5) : theme.spacing(10) }}
			>
				{children}
			</Box>
		</Box>
	);

	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				{scrollable ? (
					// <PerfectScrollbar id='scrollBar-listCourse' onScrollY={(e) => onScrollBottom(e)}>
					// 	{layoutContent}
					// </PerfectScrollbar>
					<Scrollbar id='scrollBar-listCourse' onScroll={(e) => onScrollBottom(e)}>{layoutContent}</Scrollbar>
				) : (
					layoutContent
				)}
			</Grid>
		</Grid>
	);
}

Layout1.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	scrollable: PropTypes.bool,
	bgcolor: PropTypes.string,
	padding: PropTypes.object,
	margin: PropTypes.object,
	onScrollBottom: PropTypes.func,
	isSchoolYearList: PropTypes.bool,
};

Layout1.defaultProps = {
	children: '',
	scrollable: true,
	onScrollBottom: () => {},
};

export default withStyles(Layout1, styles);
