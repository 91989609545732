import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import moment from 'moment';

import {
	NOTIFICATION_CONDITION_RENDER,
	NOTIFICATION_EXTRACT_CHAR_FIRST,
	NOTIFICATION_EXTRACT_CHAR_SECOND,
	NOTIFICATION_SPLIT_CHAR,
} from './constants';

const generateTimeString = (dateTime) => {
	const sentDate = moment(dateTime);

	const current = moment(new Date());

	const yesterday = current.clone().subtract(1, 'days');

	const _time = 'h:mm A';
	const _date = 'MMM DD';

	if (current.diff(sentDate, 'minutes') <= '5') {
		return 'Now';
	}

	if (current.diff(sentDate, 'minutes') > '5' && current.isSame(sentDate, 'd')) {
		return `Today at ${sentDate.format('hh:mm A')}`;
	}

	if (sentDate.isSame(yesterday, 'd')) {
		return `Yesterday at ${sentDate.format('hh:mm A')}`;
	}

	if (sentDate.isSame(current, 'week')) {
		return sentDate.format(`dddd-${_time}`).split('-').join(' at ');
	}

	return sentDate.format(`${_date}-${_time}`).split('-').join(' at ');
};

const generateTimeStringForNotiContent = (dateTime) => {
	const sentDate = moment(dateTime);

	const current = moment(new Date());

	const tomorrow = current.clone().add(1, 'days');

	const _date = 'ddd, MMM d - h:mm A';

	if (current.isSame(sentDate, 'd')) {
		return `Today - ${sentDate.format('hh:mm A')}`;
	}

	if (sentDate.isSame(tomorrow, 'd')) {
		return `Tomorrow - ${sentDate.format('hh:mm A')}`;
	}

	return sentDate.format(`${_date}`);
};

const getUrlToStudentTaskList = () => `${ROUTE_TASKS.DEFAULT}`;

const extractWordsBetweenCharacter = (str, charFirst = null, charSecond = null) => {
	if (!charFirst || !charSecond) {
		return [];
	}

	const words = [];
	for (let i = 0; i < str.length; i++) {
		if (str.charAt(i) === charFirst && str.charAt(i + 1) === charSecond) {
			const stopIndex = str.indexOf(charFirst, i + 2);
			if (stopIndex !== -1) {
				words.push(str.substring(i + 2, stopIndex));
			}
		}
	}
	return words;
};

const transformText = (message) => {
	const words = extractWordsBetweenCharacter(
		message,
		NOTIFICATION_EXTRACT_CHAR_FIRST,
		NOTIFICATION_EXTRACT_CHAR_SECOND,
	).filter((w) => w.includes(NOTIFICATION_SPLIT_CHAR));

	(words || []).forEach((word) => {
		const splitStr = word.split(NOTIFICATION_SPLIT_CHAR);
		const originalWord = splitStr[0];
		const conditionsToRender = Array.from(splitStr[1]).filter((item) => item !== ','); // d,b >>> ['d', ',', 'b'] >>> ['d', 'b']

		let _word = null;
		for (let i = 0; i < conditionsToRender.length; i++) {
			const cond = conditionsToRender[i];

			if (cond === NOTIFICATION_CONDITION_RENDER.DATE_FORMAT) {
				_word = generateTimeStringForNotiContent(originalWord);
			}

			if (cond === NOTIFICATION_CONDITION_RENDER.BOLD) {
				_word = _word ? `<b>${_word}</b>` : `<b>${originalWord}</b>`;
			}
		}

		message = message.replace(word, _word);
	});

	return message.replaceAll(`${NOTIFICATION_EXTRACT_CHAR_FIRST}${NOTIFICATION_EXTRACT_CHAR_SECOND}`, '');
};

export {
	generateTimeString,
	getUrlToStudentTaskList,
	extractWordsBetweenCharacter,
	generateTimeStringForNotiContent,
	transformText,
};
