import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import GetAppIcon from '@mui/icons-material/GetApp';

import { getMaterialIconByExt } from 'utils/getMaterialIconByExt';

import { Box, Typography } from 'ella-storybook';
import { Divider, IconButton } from 'ella-storybook';
import PropTypes from 'prop-types';
import { splitNameAndExtension } from 'utils';

import { useStyles } from './styles';

function PreviewFileToolBar(props) {
	const { classes } = useStyles();
	const { t } = useTranslation('grader');
	const { file, indexOfFileSelected, downloadFile, onNext, onPrevious, onFullScreen, attemptTitle } = props;

	const studentAttempts = useSelector((state) => state.Grader.studentAttempts);

	const isDisable = !file || !studentAttempts.length;

	return (
		<Box className={classes.previewFileRoot}>
			<Box display={'flex'} gap={0.5} justifyContent={'center'} alignItems={'center'}>
				{attemptTitle && (
					<>
						<Typography variant='titleSmall'>{`${attemptTitle} attempt`}</Typography>
						<Divider orientation='vertical' flexItem />
					</>
				)}

				<Box display='flex' alignItems='center' overflow={'hidden'}>
					{file && (
						<Box className={classes.iconBtn} pt={1} pb={1} mr={1}>
							{getMaterialIconByExt(splitNameAndExtension(file?.originalName ?? ''), file)}
						</Box>
					)}
					<Typography variant='titleSmall' noWrap sx={{ color: (theme) => theme.newColors.gray[800] }}>
						{!studentAttempts.length ? t('no_submissions', { ns: 'grader' }) : file?.originalName}
					</Typography>
				</Box>
			</Box>

			<Box display='flex' alignItems='center'>
				<Box mr={2}>
					<IconButton className={classes.iconBtn} onClick={onFullScreen} disabled={isDisable}>
						<FullscreenIcon />
					</IconButton>
				</Box>
				<Box mr={2}>
					<IconButton disabled={isDisable} className={classes.iconBtn} onClick={() => downloadFile()}>
						<GetAppIcon />
					</IconButton>
				</Box>

				{/* <Box display='flex' marginRight={2} height={24}>
          <Divider orientation='vertical' flexItem />
        </Box>
        {totalFile > 0 && (
          <Typography className={classes.fileCount}>
            {t('file_index_on_total', {
              index: indexOfFileSelected,
              total: totalFile,
            })}
          </Typography>
        )} */}

				{/* <Box mr={1}>
          <IconButton
            disabled={isDisable || indexOfFileSelected === 1 || !!!submissionAttempts.length}
            className={classes.iconBtn}
            onClick={() => onPrevious()}
          >
            <TblTooltip title={t('previous_file')} placement='top' arrow>
              <NavigateBeforeIcon />
            </TblTooltip>
          </IconButton>
        </Box> */}
				{/* <Box>
          <IconButton
            disabled={isDisable || indexOfFileSelected === totalFile || !!!submissionAttempts.length}
            className={classes.iconBtn}
            onClick={() => onNext()}
          >
            <TblTooltip title={t('next_file')} placement='top' arrow>
              <NavigateNextIcon />
            </TblTooltip>
          </IconButton>
        </Box> */}
			</Box>
		</Box>
	);
}

PreviewFileToolBar.propTypes = {
	downloadFile: PropTypes.func,
	file: PropTypes.shape({
		originalName: PropTypes.string,
	}),
	indexOfFileSelected: PropTypes.number,
	onFullScreen: PropTypes.func,
	onNext: PropTypes.func,
	onPrevious: PropTypes.func,
};

export default PreviewFileToolBar;
