const styles = (theme) => ({
	root: {
		alignItems: 'center',
	},
	inputContainer: {
		padding: theme.spacing(0, 1),
	},
	formActions: {
		height: 32,
		marginLeft: 'auto',
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(),
	},
	colorTable: {
		borderRadius: theme.spacing(1),
		cursor: 'pointer',
	},
});

export default styles;
