import { makeAjaxRequest } from 'utils/ajax';

import { cloneDeep } from 'lodash';
import { ACTIVITY_CATEGORY, ACTIVITY_CATEGORY_NAME } from 'modules/MyCourses/constants';
import { updateCourseItemByUnit, updateSectionsByGradingPeriod } from 'modules/MyCourses/utils';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import myCoursesActions from './actions';
import { END_POINT, actions } from './constants';
import { getQueueUpdate } from './utils';

const createNewLessonEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_NEW_LESSON),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.create_new_lesson.method,
				END_POINT.create_new_lesson.url(action.payload.orgId, action.payload.courseId, action.payload.unitId),
				action.payload.lesson,
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.createNewLessonSuccess({
							isCreateNewLessonSuccess: true,
							isCreatingLesson: false,
							createdActivity: data?.response?.lesson,
							lessonDetail: data?.response?.lesson,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.createNewLessonFailed({
							error: error?.response?.errors,
							isCreatingLesson: false,
						}),
					),
				),
			),
		),
	);

const editLessonEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.EDIT_LESSON),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_lesson.method,
				END_POINT.edit_lesson.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.lessonId,
				),
				action.payload.lesson,
			).pipe(
				mergeMap((data) => {
					const masterId = action.payload.lessonId;
					const { metadata } = action.payload.lesson;
					const { lessonName } = metadata;
					const oldList = cloneDeep(state$.value?.AllCourses?.sectionsByGradingPeriod ?? []);

					const newList = updateSectionsByGradingPeriod(
						masterId,
						{ name: lessonName },
						oldList,
						ACTIVITY_CATEGORY.LESSON,
					);

					const currentCourseItemByUnit = state$.value?.AllCourses?.courseItemByUnit ?? {};
					const typeName = ACTIVITY_CATEGORY_NAME[ACTIVITY_CATEGORY.LESSON];
					const courseItemByUnit = updateCourseItemByUnit(
						currentCourseItemByUnit,
						masterId,
						{ name: lessonName },
						typeName,
					);

					return of(
						myCoursesActions.editLessonSuccess({
							isEditLessonSuccess: true,
							isEditingLesson: false,
							sectionsByGradingPeriod: newList,
							courseItemByUnit: courseItemByUnit,
							queueUpdate: getQueueUpdate(data?.response?.courseDayIds),
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.editLessonFailed({
							error: error?.response?.errors,
							isEditingLesson: false,
						}),
					),
				),
			),
		),
	);

const getLessonDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_LESSON_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_lesson_detail.method,
				END_POINT.get_lesson_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.unitId,
					action.payload.lessonId,
				),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getLessonDetailSuccess({
							lessonDetail: data?.response?.lesson,
							isFetchingLessonDetails: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getLessonDetailFailed({
							error: error?.response?.errors,
							isFetchingLessonDetails: false,
						}),
					),
				),
			),
		),
	);

export default [createNewLessonEpic, getLessonDetailEpic, editLessonEpic];
