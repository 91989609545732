import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import { Avatar } from '@mui/material';

import TblIcon from 'components/TblIcon';

import clsx from 'clsx';
import { Box, Typography } from 'ella-storybook';
import { isNumber } from 'modules/MyCourses/utils';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const ListStudentBySection = ({ studentList, studentSelected, handleChooseStudent }) => {
	const { classes } = useStyles();
	const { t } = useTranslation('grader');

	return (
		<Box className={classes.studentList}>
			{isEmpty(studentList) ? (
				<Typography variant='bodyMediumRegular'>{t('no_student_assigned')}</Typography>
			) : (
				studentList.map((student) => (
					<Box
						className={clsx(classes.studentInfoBtn, { [classes.chosen]: student?.studentId === studentSelected })}
						onClick={handleChooseStudent(student)}
					>
						<Box className={classes.avatarContainer}>
							<Avatar className={`${classes.rootAvatar}`}>
								{((student.name || '')?.charAt(0).toUpperCase() || '') +
									((student.name || '')?.split(' ')[1].charAt(0).toUpperCase() || '')}
							</Avatar>
						</Box>
						<Box width={'100%'} overflow={'hidden'}>
							<Typography
								variant='titleSmall'
								className={clsx('text-ellipsis')}
								sx={{ color: 'black' }}
								flex={'0 0 196px'}
								component={'div'}
							>
								{student.name}
							</Typography>
						</Box>
						<Typography component={'div'} flex={'0 0 auto'} variant='bodyMediumRegular' sx={{ color: 'black' }}>
							{t('overall_grade_on_total_point', {
								ns: 'grader',
								overallGrade: isNumber(student.overallGrade) ? student.overallGrade : '--',
								totalPoint: student.totalPoint,
							})}
						</Typography>
						<Box display={'flex'} gap={1}>
							<TblIcon icon='chevron_right' fontSize={'24px'} />
						</Box>
					</Box>
				))
			)}
		</Box>
	);
};

ListStudentBySection.propTypes = {
	handleChooseStudent: PropTypes.func,
	studentList: PropTypes.shape({
		map: PropTypes.func,
	}),
	studentSelected: PropTypes.any,
};

ListStudentBySection.defaultProps = {
	handleChooseStudent: () => {},
};

export default ListStudentBySection;
