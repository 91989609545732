import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	isUserActive: true,
	currentUser: {},
	originPermissions: '',
	isLogging: false,
	isNewSession: false,
	gettingUser: false,
	error: null,
	token: null,
	isBusy: false,
	acceptInvitationSuccessfully: false,
	resetPasswordSuccessfully: false,
	setupDomainSuccessfully: false,
	subdomainValid: 0,
	isForgotPasswordSuccessfully: false,
	isResetPasswordSuccessfully: false,
	isValidToken: false,
	checkingToken: true,
	schoolGradeLevel: [],
	currentSchoolYearStatus: null,
	isFetchingSchoolYearStatus: false,
	schoolYears: [],
	currentSchoolYear: null,
	userStatus: null,
	domainName: '',
	organizationStatus: null,
	isValidatingSetupCourse: null,
	domains: [],
	isGettingListDomain: false,
	amountDomain: null,
	isSuspendUser: false,
	subDomainStatus: null,
	isCheckingDomainStatus: false,
	verifyEmail: '',
	verifySubdomain: '',
};

export default createReducers(initialState, actions, actions.AUTH_RESET);
