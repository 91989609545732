import { makeAjaxRequest } from 'utils/ajax';
import handleErrorsFromServer from 'utils/handleErrors';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import mediaActions from './actions';
import { END_POINT, actions } from './constants';

const fetchImagesEpics = (action$) =>
	action$.pipe(
		ofType(actions.MEDIA_FETCH_IMAGES),
		switchMap(() =>
			makeAjaxRequest(END_POINT.fetch_images.method, END_POINT.fetch_images.url).pipe(
				map((data) => {
					if (data.response.errors) {
						return mediaActions.mediaFetchImagesFailed({
							error: data.response.errors,
							fetching: false,
						});
					}
					return mediaActions.mediaFetchImagesSuccess({
						media: data.response,
						fetching: false,
					});
				}),
				catchError((error) =>
					of({
						type: actions.MEDIA_FETCH_IMAGES_FAILED,
						payload: error.xhr.response,
						error: true,
					}),
				),
			),
		),
	);

const fetchMediaEpics = (action$) =>
	action$.pipe(
		ofType(actions.MEDIA_FETCH, actions.DELETE_MEDIA_SUCCESS),
		mergeMap(() =>
			makeAjaxRequest(END_POINT.fetch_media.method, END_POINT.fetch_media.url).pipe(
				map((data) => {
					if (data.response.errors) {
						return mediaActions.mediaFetchFailed({
							error: data.response.errors,
							fetching: false,
						});
					}
					return mediaActions.mediaFetchSuccess({
						media: data.response,
						fetching: false,
					});
				}),
				catchError((error) =>
					of({
						type: actions.MEDIA_FETCH_FAILED,
						payload: error.xhr.response,
						error: true,
					}),
				),
			),
		),
	);

const deleteMediaEpics = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_MEDIA),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.delete_media.method, END_POINT.delete_media.url(action.payload.mediaId)).pipe(
				map((data) => {
					if (data.response.errors) {
						handleErrorsFromServer(data.response);
						return mediaActions.deleteMediaFailed(data.response.errors);
					}
					return mediaActions.deleteMediaSuccess(data.response);
				}),
				catchError((error) => {
					handleErrorsFromServer(error);

					return of({
						type: actions.DELETE_MEDIA_FAILED,
						payload: error.xhr.response,
						error: true,
					});
				}),
			),
		),
	);

const getCourseDefaultMediaEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COURSE_DEFAULT_MEDIA),
		switchMap((action) =>
			makeAjaxRequest(END_POINT.get_media.method, END_POINT.get_media.url(action.payload.urlParams)).pipe(
				map(({ response }) =>
					mediaActions.getCourseDefaultMediaSuccess({
						mediaList: response.mediaList,
					}),
				),
				catchError((error) => of(mediaActions.getCourseDefaultMediaFailed({}))),
			),
		),
	);

const makeRequest = (url, formData) =>
	ajax({
		method: 'POST',
		url: url,
		body: formData,
	});

const updateToMediaEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_TO_MEDIA),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_media.method,
				END_POINT.update_media.url(action.payload.urlParams),
				action.payload.file,
			).pipe(
				mergeMap(({ response }) => {
					return of(
						mediaActions.updateToMediaSuccess({
							selectedMediaId: response.createdFiles.id,
							updateSuccess: true,
							commonLoading: false,
						})
					);
				}),
				catchError((error) =>
					of(
						mediaActions.updateToMediaFailed({
							uploadingError: true,
							commonLoading: false,
							uploadErrorMsg: error?.response?.errors?.message,
						}),
					),
				),
			),
		),
	);
const uploadToS3Epic = (action$, state$) =>
	action$.pipe(
		ofType(actions.UPLOAD_TO_S3),
		mergeMap((action) => {
			const { presignedUrl, file } = action.payload;
			const formData = new FormData();
			formData.append('Content-Type', file.mimetype);
			Object.entries(presignedUrl.fields).forEach(([k, v]) => {
				formData.append(k, v);
			});
			formData.append('file', file.blob);

			return makeRequest(presignedUrl.url, formData).pipe(
				mergeMap((data) => {
					const _file = {
						filename: file.filename,
						originalName: file.originalFilename,
						url: `${process.env.REACT_APP_API_MEDIA}/${presignedUrl.fields.key}`,
						mimetype: file.mimetype,
						size: file.size,
					};
					return of(
						mediaActions.uploadToS3Success({
							// file: _file,
						}),
						mediaActions.updateToMedia({
							file: _file,
						}),
					);
				}),
				catchError((error) =>
					of(
						mediaActions.uploadToS3Failed({
							uploadingError: true,
							commonLoading: false,
							uploadErrorMsg: error?.response?.errors?.message,
						}),
					),
				),
			);
		}),
	);

const getPresignedUrlEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.GET_PRESIGNED_URL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_presigned_url.method,
				END_POINT.get_presigned_url.url(action.payload.urlParams),
			).pipe(
				mergeMap(({ response }) =>
					of(
						mediaActions.getPresignedUrlSuccess(),
						mediaActions.uploadToS3({
							presignedUrl: response.presignedUrl,
							file: { ...state$.value.Media.file, filename: response.filename },
						}),
					),
				),
				catchError((error) =>
					of(
						mediaActions.getPresignedUrlFailed({
							uploadingError: true,
							commonLoading: false,
							uploadErrorMsg: error?.response?.errors?.message,
						}),
					),
				),
			),
		),
	);
// const uploadSingleFileEpic = (action$) =>
//   action$.pipe(
//     ofType(actions.UPLOAD_SINGLE_FILE),
//     mergeMap((action) =>
//       of(mediaActions.getPresignedUrl(action.payload)).pipe(
//         mergeMap((payload) =>
//           mediaActions.uploadToS3(payload).pipe(
//             mergeMap((presignedUrlPayload) =>
//               mediaActions.updateToMedia(presignedUrlPayload).pipe(
//                 map(() =>
//                   mediaActions.uploadSingleFileSuccess({
//                     uploadingFileSuccess: true,
//                   })
//                 )
//               )
//             ),
//             catchError((error) => of(mediaActions.uploadSingleFileFailed({ error })))
//           )
//         ),
//         catchError((error) => of(mediaActions.uploadSingleFileFailed({ error })))
//       )
//     ),
//     catchError((error) => of(mediaActions.uploadSingleFileFailed({ error })))
//   );

export default [
	fetchImagesEpics,
	fetchMediaEpics,
	deleteMediaEpics,
	getCourseDefaultMediaEpic,
	getPresignedUrlEpic,
	uploadToS3Epic,
	updateToMediaEpic,
	// uploadSingleFileEpic,
];
