import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

const PROGRESS_STATUS = {
	MISSING: -3,
	MISSED: -2,
	LATE_TURN_IN: -1,
	NOT_TURN_IN: 0,
	COMPLETED: 2,
	TURN_IN: 3,
	GRADED: 4,
	REJECTED: 5,
};

const OPTIONS_STATUS = {
	[PROGRESS_STATUS.MISSING]: {
		label: 'Missing',
		hotKey: 'M',
	},
	[PROGRESS_STATUS.MISSED]: {
		label: 'Missed',
	},
	[PROGRESS_STATUS.TURN_IN]: {
		label: 'Turned In',
		hotKey: 'T',
	},
	[PROGRESS_STATUS.LATE_TURN_IN]: {
		label: 'Turned In (Late)',
		hotKey: 'L',
	},
};

const EventKeysCell = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'Delete', 'Escape'];

const MovingCode = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'NumpadEnter'];
const DeleteValuesCode = ['Delete'];

const MappingHotKeys = {
	KeyM: PROGRESS_STATUS.MISSING,
	KeyT: PROGRESS_STATUS.TURN_IN,
	KeyL: PROGRESS_STATUS.LATE_TURN_IN,
};

const warningText = 'Unable to grade as the student was added to the course after the course activity closed.';

const lockText = 'Unable to edit grade after 7 days since the last day of term.';

const FIXED_COLUMN_POSITION_INDEX = {
	COLUMN_ONE: 1,
	COLUMN_TWO: 2,
};

export {
	PROGRESS_STATUS,
	OPTIONS_STATUS,
	FIXED_COLUMN_POSITION_INDEX,
	EventKeysCell,
	MovingCode,
	DeleteValuesCode,
	MappingHotKeys,
	warningText,
	lockText,
};

export const AUTOMATED_CALCULATE_OPTION = {
	label: 'return_to_automated_calculation_mode',
	value: 3,
	divider: true,
	rule: {
		module: MODULE_ALIAS.GRADING,
		permissions: [PERMISSIONS.GRADING.UPDATE],
	},
	externalCheck: (checking) =>
		typeof checking === 'boolean' ? checking : typeof checking === 'function' ? checking() : false,
};
export const MENU_MORE_OPTION = [
	{
		label: 'view_details_and_comments',
		value: 1,
		rule: {
			module: MODULE_ALIAS.GRADING,
			permissions: [PERMISSIONS.GRADING.UPDATE],
		},
	},
	{
		label: 'open_in_grader',
		value: 2,
		rule: {
			module: MODULE_ALIAS.GRADING,
			permissions: [PERMISSIONS.GRADING.UPDATE],
		},
	},
	AUTOMATED_CALCULATE_OPTION
];
