import { ReactComponent as IcnAlignCenter } from './images/icn_align_center.svg';
import { ReactComponent as IcnAlignJustify } from './images/icn_align_justify.svg';
import { ReactComponent as IcnAlignLeft } from './images/icn_align_left.svg';
import { ReactComponent as IcnAlignRight } from './images/icn_align_right.svg';
import { ReactComponent as IcnHighlightColor } from './images/icn_color_fill.svg';
import { ReactComponent as IcnFormatBold } from './images/icn_format_bold.svg';
import { ReactComponent as IcnFormatClear } from './images/icn_format_clear.svg';
import { ReactComponent as IcnFormatHeading1 } from './images/icn_format_heading1.svg';
import { ReactComponent as IcnFormatHeading2 } from './images/icn_format_heading2.svg';
import { ReactComponent as IcnFormatItalic } from './images/icn_format_italic.svg';
import { ReactComponent as IcnFormatQuote } from './images/icn_format_quote.svg';
import { ReactComponent as IcnFormatStrikeThrough } from './images/icn_format_strikethrough.svg';
import { ReactComponent as IcnFormatUnderlined } from './images/icn_format_underlined.svg';
import { ReactComponent as IcnInsertFx } from './images/icn_insert_fx.svg';
import { ReactComponent as IcnInsertLink } from './images/icn_insert_link.svg';
import { ReactComponent as IcnInsertMedia } from './images/icn_insert_media.svg';
import { ReactComponent as IcnInsertTable } from './images/icn_insert_table.svg';
import { ReactComponent as IcnListBulleted } from './images/icn_list_bulleted.svg';
import { ReactComponent as IcnListNumbering } from './images/icn_list_numbering.svg';
import { ReactComponent as IcnMore } from './images/icn_more.svg';
import { ReactComponent as IcnTextColor } from './images/icn_text_fill.svg';
const Icons = {
	IcnAlignCenter,
	IcnAlignJustify,
	IcnAlignLeft,
	IcnAlignRight,
	IcnFormatBold,
	IcnFormatClear,
	IcnFormatHeading1,
	IcnFormatHeading2,
	IcnFormatItalic,
	IcnFormatQuote,
	IcnFormatStrikeThrough,
	IcnFormatUnderlined,
	IcnInsertFx,
	IcnInsertLink,
	IcnInsertMedia,
	IcnInsertTable,
	IcnListBulleted,
	IcnListNumbering,
	IcnMore,
	IcnTextColor,
	IcnHighlightColor,
};
export default Icons;
