import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

import { ROUTE_DASH_BOARD } from './constantsRoute';
import reducer from './reducers';

const DashBoard = loadable(() => import('./containers'));

export default [
	{
		path: ROUTE_DASH_BOARD.DEFAULT,
		permissions: [PERMISSIONS.TEACHER_DASHBOARD.READ],
		moduleAlias: MODULE_ALIAS.TEACHER_DASHBOARD,
		component: withReducer('DashBoard', reducer)(DashBoard),
		private: true,
		exact: true,
		menu: {
			title: 'Dashboard',
			icon: 'icon icon-bar_chart_4_bars',
			order: 1,
			group: 'noname',
		},
	},
];
