/* eslint-disable max-len */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { Box, Stack, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import TblEditor from 'components/TblEditor';
import TblIcon from 'components/TblIcon';
import TblUseSnackbar from 'components/TblUseSnackbar';

import { MAX_GOOGLE_UPLOAD_FILES } from 'utils/constants';

import MyAttachments from 'shared/Attachments/MyAttachments';
import { useSchoolYearDataContext } from 'shared/Auth/containers/SchoolYear';
import { filterGoogleFileSelected } from 'shared/Google/utils';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { BaseModal, Button, Divider, FullScreenDialog, TextField, Tooltip, Typography } from 'ella-storybook';
import { useFormik } from 'formik';
import { compact, trim } from 'lodash';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY, GRADE_WEIGHT_TYPE, MAX_LENGTH } from 'modules/MyCourses/constants';
import { SCHOOL_YEAR_STATUS } from 'modules/SchoolYear/constants';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from 'tss-react/mui';
import { handleConvertBase64Image, replaceHTMLTag } from 'utils';
import * as Yup from 'yup';

import FormSkeleton from '../components/FormSkeleton';
import Grading from '../components/Grading';
import ScheduleDialog from '../components/ScheduleDialog/ScheduleDialog';
import SubmissionAttempts from '../components/SubmissionAttempt/SubmissionAttempts';
import {
	COURSE_STATUS,
	DIALOG_TYPE,
	ESTIMATE as ESTIMATE_ASSIGNMENT,
	GRADE_TYPE,
	PICTURE_IN_CLASS_SUBMISSIONS_METHOD,
	SUBMISSION_METHOD,
	SUBMISSION_REQUIRED_DEFAULT_TEST,
	TYPE_OF_CREATE,
} from '../constants';
import { checkPermissionCreateAndPublish, getDefaultGradeWeightValue } from '../utils';

import LinkedContents from './LinkedContents';
import StudyForTest from './StudyForTest';

// const MediaWithReducer = withReducer('Media', reducer)(Media);
const ESTIMATE = {
	MIN: 5,
	MAX: 240,
};

const useStyles = makeStyles()((theme) => ({
	indicator: {
		margin: theme.spacing(3, 0, 3.25),
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
	},
	estimate: {
		display: 'flex',
		'& .MuiInputBase-root': {
			width: theme.spacing(15),
		},
	},
	leftContent: {
		width: theme.spacing(82),
		marginRight: theme.spacing(4),
	},
	estimateInfo: {
		position: 'absolute',
		cursor: 'pointer',
		left: theme.spacing(6.5),
	},
	rightContent: {
		width: theme.spacing(39),
		gap: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	divider: {
		margin: theme.spacing(5.25, 0),
		'&::before, &::after': {
			borderTop: `1px dashed ${theme.newColors.gray[300]}`,
		},
		'& .MuiDivider-wrapper': {
			width: theme.spacing(8.5),
		},
	},
	middleDivider: {
		borderTop: `4px dotted ${theme.newColors.gray[300]}`,
		borderLeftWidth: 0,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
}));

function CreateTest(props) {
	const {
		onClose,
		isVisible,
		testId,
		courseId,
		editable,
		unitId,
		unit,
		testViewData,
		updateMasterItem,
		getCourseItemByUnit,
		testInfo,
		isViewOnly,
		updateUnit,
		shouldResetState,
		shouldCallAPItoGetModalData,
	} = props;

	const { classes } = useStyles();
	const theme = useTheme();

	const dispatch = useDispatch();
	const { t } = useTranslation(['myCourses', 'common', 'tour']);
	const authContext = useContext(AuthDataContext);
	const { organizationId } = authContext.currentUser;

	const schoolYearData = useSchoolYearDataContext();
	const archiveMode = schoolYearData.status === SCHOOL_YEAR_STATUS.ARCHIVED;

	const test = useSelector((state) => state.AllCourses.test ?? testViewData);
	const createNewTestSuccess = useSelector((state) => state.MyCourses?.createNewTestSuccess);
	const editTestSuccess = useSelector((state) => state.MyCourses?.editTestSuccess);

	const isFetchingTestDetails = useSelector((state) => state.MyCourses?.isFetchingTestDetails);
	const permission = useSelector((state) => state.MyCourses?.permission);
	const isEditingTest = useSelector((state) => state.MyCourses?.isEditingTest);

	const gradeWeight = useSelector((state) => state?.MyCourses?.gradeWeight ?? []);
	const isDraft = useSelector((state) => state?.AllCourses?.basicInfo?.status === COURSE_STATUS.DRAFT);

	const gradeCalculation = useSelector((state) => state.MyCourses?.gradeCalculation);

	const gradingLateCompletionForTest = useSelector((state) => state.MyCourses?.gradingLateCompletionForTest);

	const havePermissionPublish = checkPermissionCreateAndPublish(permission);

	const [visiblePublishDialog, setVisiblePublishDialog] = useState(false);

	const [isFetching, setIsFetching] = useState(false);
	const [typeOfCreate, setTypeOfCreate] = useState(0);
	const [gradeWeightTestOptions, setGradeWeightTestOptions] = useState([]);
	const [gradeWeightStudyTestOptions, setGradeWeightStudyTestOptions] = useState([]);

	const [attachments, setAttachments] = useState(test?.attachments || []);
	// const [media, setMedia] = useState({});
	const [openDialog, setOpenDialog] = useState(false);
	const [uploadingState, setUploadingState] = useState(false);
	//User Guiding
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		if (isVisible) {
			queryParams.append('modal', 'test');
			history.replace({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		}
	}, [isVisible]);
	/////////////////////////

	const validationAssigned = Yup.object().shape({
		name: Yup.string().trim().required(t('common:required_message')),
		description: Yup.string().nullable(),
		duration: Yup.number()
			.min(ESTIMATE.MIN)
			.max(ESTIMATE.MAX)
			.test('is-required', t('common:required_message'), (value) => !!value),
		// gradeWeightCriteriaId: Yup.mixed().when('gradeType', {
		//   is: (val) => val === GRADE_TYPE.GRADED,
		//   then: Yup.number().nullable().required(t('common:required_message')),
		// }),
		gradeType: Yup.number().required(t('common:required_message')),
		totalPoint: Yup.mixed().when('gradeType', {
			is: (val) => val === GRADE_TYPE.GRADED,
			then: Yup.number().nullable().min(1).max(10000).required(t('common:required_message')),
		}),
		attempts: Yup.number().min(1).max(100).required(t('common:required_message')),
		studyForTest: Yup.object().shape({
			duration: Yup.number()
				.min(ESTIMATE_ASSIGNMENT.MIN)
				.max(ESTIMATE_ASSIGNMENT.MAX)
				.test('is-required', t('common:required_message'), (value) => !!value),
			// gradeWeightCriteriaId: Yup.mixed().when('gradeType', {
			//   is: (val) => val === GRADE_TYPE.GRADED,
			//   then: Yup.number().nullable().required(t('common:required_message')),
			// }),
			gradeType: Yup.number().required(t('common:required_message')),
			totalPoint: Yup.mixed().when('gradeType', {
				is: (val) => val === GRADE_TYPE.GRADED,
				then: Yup.number().nullable().min(1).max(10000).required(t('common:required_message')),
			}),
		}),
	});

	const onSubmit = async (values) => {
		const payload = {
			...values,
			description: replaceHTMLTag(values.description) === '' ? '' : values.description,
			name: trim(values.name),
			attachments: {
				mediaId: attachments?.map((file) => file.id) || [],
			},
			attempts: JSON.parse(values?.attempts),
			gradeWeightCriteriaId: values.gradeWeightCriteriaId,
			linkContents: linkContents,
			studyForTest: {
				...values.studyForTest,
				gradeWeightCriteriaId: values.studyForTest.gradeWeightCriteriaId,
			},
		};
		payload.description = await handleConvertBase64Image(payload.description);
		const actionPayload = {
			orgId: organizationId,
			courseId,
			unitId,
			isFetchingTestDetails: true,
			data: payload,
		};

		if (isEmpty(test)) {
			dispatch(myCoursesActions.createNewTest(actionPayload));
		} else {
			dispatch(
				myCoursesActions.editTest({
					testId: test?.id,
					isEditingTest: true,
					...actionPayload,
				}),
			);
		}
	};

	const formik = useFormik({
		initialValues: {
			...test,
			googleFiles: test?.googleFiles || [],
			gradeWeightCriteriaId:
				test?.gradeWeightCriteriaId ??
				getDefaultGradeWeightValue({ gradeWeightOptions: gradeWeightTestOptions })?.value,
			gradeType: test?.gradeType ?? GRADE_TYPE.GRADED,
			totalPoint: test?.totalPoint,
			studyForTest: {
				duration: test?.studyForTest?.duration,
				gradeWeightCriteriaId:
					test?.studyForTest?.gradeWeightCriteriaId ??
					getDefaultGradeWeightValue({ gradeWeightOptions: gradeWeightTestOptions })?.value,
				gradeType: test?.studyForTest?.gradeType ?? GRADE_TYPE.NON_GRADED,
				totalPoint: test?.studyForTest?.totalPoint,
				submissionRequired: test?.studyForTest?.submissionRequired ?? SUBMISSION_REQUIRED_DEFAULT_TEST, // undefined return true, else its value
				submissionMethod: test?.studyForTest?.submissionMethod ?? SUBMISSION_METHOD.ONLINE,
				pictureInClassSubmissions:
					test?.studyForTest?.pictureInClassSubmissions ?? PICTURE_IN_CLASS_SUBMISSIONS_METHOD.OPTIONAL,
				gradingAttemptsMethod: null,
			},
			attempts: test?.attempts ?? gradingLateCompletionForTest?.attempts,
			gradingAttemptsMethod: test?.gradingAttemptsMethod ?? gradingLateCompletionForTest?.gradingAttemptsMethod,
		},
		enableReinitialize: true,
		validationSchema: validationAssigned,
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: onSubmit,
	});

	const { values, touched, errors, setFieldValue, resetForm, submitCount, setFieldError, handleSubmit, handleBlur } =
		formik;

	const toggleCloseDrawer = () => {
		//User Guiding
		const queryParams = new URLSearchParams(location.search);

		if (queryParams.has('modal')) {
			queryParams.delete('modal');
			history.replace({
				search: queryParams.toString(),
			});
		}
		////////////////
		resetForm();
		dispatch(
			myCoursesActions.myCoursesSetState({
				error: null,
				createNewTestSuccess: false,
				editTestSuccess: false,
				getTestDetailSuccess: false,
				isFetchingTestDetails: false,
				mcGetGradeCalculationSuccessTest: false,
			}),
		);
		shouldResetState &&
			dispatch(
				myCoursesActions.myCoursesSetState({
					test: {},
					gradeWeight: [],
					gradeCalculation: {},
					gradingLateCompletion: {},
				}),
			);
		setAttachments([]);
		onClose();
	};
	const toggleCloseDialog = useCallback(() => {
		setVisiblePublishDialog(!visiblePublishDialog);
		dispatch(
			myCoursesActions.myCoursesSetState({
				test: {},
				error: null,
				createNewTestSuccess: false,
				editTestSuccess: false,
				getTestDetailSuccess: false,
				gradeWeight: [],
				isFetchingTestDetails: false,
				gradeCalculation: {},
				mcGetGradeCalculationSuccessTest: false,
			}),
		);
	}, [visiblePublishDialog]);

	const handleChange = (fieldName, value) => {
		setFieldValue(fieldName, value);
	};
	const mcGetGradeWeight = useCallback(() => {
		// if (isVisible) {
		//   dispatch(
		//     myCoursesActions.getCompareGradeWeightCategories({
		//       orgId: organizationId,
		//       courseId: courseId,
		//     })
		//   );
		// }

		dispatch(
			myCoursesActions.mcGetGradeCalculation({
				courseId: courseId,
				orgId: organizationId,
				urlParams: {
					isCreateActivity: true,
				},
			}),
		);

		dispatch(
			myCoursesActions.getDetailGradingLateCompletion({
				orgId: organizationId,
				courseId: Number(courseId),
				// urlParams: {
				//   activityType: Number(ACTIVITY_CATEGORY.ASSIGNMENT) // epic have handle this case for assignment and test
				// }
			}),
		);
	}, [courseId, organizationId]);

	const getTestDetail = useCallback(() => {
		if (testId && isVisible) {
			setIsFetching(true);
			dispatch(
				myCoursesActions.getTestDetail({
					orgId: organizationId,
					courseId,
					unitId,
					testId,
				}),
			);
		}
	}, [testId, courseId, organizationId, unitId, isVisible]);

	// useEffect(() => {
	//   getTestDetail();
	//   mcGetGradeWeight();
	// }, []);

	useEffect(() => {
		if (!shouldResetState) return;
		return () => {
			myCoursesActions.myCoursesSetState({
				test: {},
				error: null,
				createNewTestSuccess: false,
				editTestSuccess: false,
				getTestDetailSuccess: false,
				gradeWeight: [],
				isFetchingTestDetails: false,
				gradeCalculation: {},
				mcGetGradeCalculationSuccessTest: false,
			});
		};
	}, []);

	useEffect(() => {
		if (!shouldCallAPItoGetModalData || !isVisible) return;
		getTestDetail();
		mcGetGradeWeight();
	}, [getTestDetail, mcGetGradeWeight, shouldCallAPItoGetModalData, isVisible]);

	useEffect(() => {
		setLinkContents({
			lessonLinkIds: test?.linkContents?.map((item) => item.lessonLinkId) || [],
			assignmentLinkIds: test?.linkContents?.map((item) => item.assignmentLinkId) || [],
			quizLinkIds: test?.linkContents?.map((item) => item.quizLinkId) || [],
		});
		setAttachments(test?.attachments || []);
		if (test?.id === testId) {
			setIsFetching(false);
		}
	}, [test, testId, gradeWeight]);

	useEffect(() => {
		const gradeWeightTest = gradeWeight.filter((item) => item.type === GRADE_WEIGHT_TYPE.ASSIGNMENT);
		if (gradeWeightTest.length) {
			setGradeWeightTestOptions(
				gradeWeightTest.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
			);
		}

		const gradeWeightStudyTest = gradeWeight.filter((item) => item.type === GRADE_WEIGHT_TYPE.ASSIGNMENT);
		if (gradeWeightStudyTest.length) {
			setGradeWeightStudyTestOptions(
				gradeWeightStudyTest.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
			);
		}
	}, [gradeWeight]);

	useEffect(() => {
		if (createNewTestSuccess || editTestSuccess) {
			switch (typeOfCreate) {
				case TYPE_OF_CREATE.CREATE_AS_DRAFT:
					toggleCloseDrawer();
					break;
				case TYPE_OF_CREATE.CREATE_AND_PUBLISH:
					setVisiblePublishDialog(true);
					dispatch(
						myCoursesActions.myCoursesSetState({
							error: null,
							createNewTestSuccess: false,
							editTestSuccess: false,
							gradeWeight: [],
							gradeCalculation: {},
							mcGetGradeCalculationSuccessTest: false,
						}),
					);
					setAttachments([]);
					onClose();
					//User Guiding
					const queryParams = new URLSearchParams(location.search);

					if (queryParams.has('modal')) {
						queryParams.delete('modal');
						history.replace({
							search: queryParams.toString(),
						});
					}
					////////////////
					break;
				default:
					break;
			}
			if (testInfo?.page === 'plan' && unitId) {
				if (updateMasterItem && (testInfo?.planned || testInfo?.courseDayId)) {
					updateMasterItem(testInfo?.courseDayId ?? testInfo?.executeDateId, 'none', unitId);
				}
				if (getCourseItemByUnit && !testInfo?.planned) {
					getCourseItemByUnit();
				}
			}
		}
	}, [createNewTestSuccess, editTestSuccess, toggleCloseDrawer, unit, updateUnit]);

	// const onMediaClose = () => {
	//   setMedia({ openMedia: false, mediaCallback: {} });
	// };
	const onAttachFile = (files, isRemove = false) => {
		if (isRemove) {
			return setAttachments(attachments.filter((f) => f.id !== files.id));
		}
		setAttachments((preVal) => [...preVal, files]);
	};

	const [linkContents, setLinkContents] = React.useState({
		lessonLinkIds: compact(test?.linkContents?.map((item) => item.lessonLinkId)) || [],
		assignmentLinkIds: compact(test?.linkContents?.map((item) => item.assignmentLinkId)) || [],
		quizLinkIds: compact(test?.linkContents?.map((item) => item.quizLinkId)) || [],
	});

	// const handleMediaSelect = React.useCallback(
	//   (m) => {
	//     if (media?.mediaAction === 'insertImage') {
	//       const { editor, insertImage: callback } = media.mediaCallback || {};
	//       callback(editor, m?.url, {
	//         class: CLASS_NAME.INIT,
	//       });
	//     }
	//     setMedia({ mediaCallback: {} });
	//   },
	//   [media]
	// );

	const editorChange = useCallback(
		(content) => {
			const trimValue = replaceHTMLTag(content);
			if (touched.description || trimValue || testId) {
				handleChange('description', content);
			}
			if (!trimValue) {
				handleChange('description', '');
			}
		},
		[touched.description, testId],
	);

	// const insertImage = (editor, value, callbackFunc) => {
	//   setMedia({
	//     openMedia: true,
	//     mediaCallback: {
	//       editor,
	//       value,
	//       insertImage: callbackFunc,
	//     },
	//     mediaAction: 'insertImage',
	//   });
	// };

	const editorMemo = useMemo(
		() => (
			<TblEditor
				name='description'
				disabled={isViewOnly || archiveMode}
				label={t('common:instructions')}
				placeholder={t('assignment_description_placeholder')}
				// customButtons={{
				//   insertImage: {
				//     onClick: insertImage,
				//   },
				// }}
				onChange={editorChange}
				defaultValue={values.description}
			/>
		),
		[values.description, errors.description, touched.description, testId, submitCount],
	);

	const onChangeChooseTemplate = async (sourceId) => {
		values.googleFiles.forEach((file) => {
			if (file.sourceId === sourceId) {
				return (file.isTemplate = !file.isTemplate);
			}
			file.isTemplate = false;
		});
		setFieldValue('googleFiles', values.googleFiles);
	};

	const renderContent = () =>
		isFetching ? (
			<FormSkeleton />
		) : (
			<Box component='form'>
				{editTestSuccess && <TblUseSnackbar />}
				{createNewTestSuccess && <TblUseSnackbar message={t('common:object_created', { objectName: 'Test' })} />}
				<Box className={classes.content}>
					<Box className={classes.leftContent}>
						{/* <MediaWithReducer
              isVisible={media.openMedia}
              onClose={onMediaClose}
              onSelect={handleMediaSelect}
              accept={media.acceptType}
              mediaType={media.mediaType}
            /> */}
						<Box mt={2}>
							<TextField
								required
								fullWidth={true}
								autoFocus
								name='name'
								onBlur={handleBlur}
								disabled={isViewOnly || archiveMode}
								label={t('common:name')}
								placeholder={t('common:enter_name')}
								type='text'
								helperText={!!((touched.name || submitCount) && errors.name) && errors.name}
								error={!!((touched.name || submitCount) && errors.name)}
								inputProps={{ maxLength: MAX_LENGTH.ACTIVITY_NAME }}
								value={values?.name}
								onChange={(e) => handleChange('name', e.target.value)}
							/>
						</Box>
						<Box mt={3} className={classes.estimate}>
							<TextField
								required
								width={'15%'}
								name='duration'
								onBlur={(e) => {
									if (!e?.target?.value) {
										return handleBlur(e);
									}
									let value = Number(e.target.value);
									if (value > ESTIMATE.MAX) {
										value = ESTIMATE.MAX;
									} else if (value < ESTIMATE.MIN) {
										value = ESTIMATE.MIN;
									}
									handleChange('duration', value);
									setFieldError('duration', null);
								}}
								disabled={isViewOnly || !editable || archiveMode}
								label={
									<Box display={'flex'}>
										{t('duration')}
										<Tooltip title={`${t('duration_indicator_test')}`} placement='top'>
											<Box ml={'2px'} mt={'2px'}>
												<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
											</Box>
										</Tooltip>
									</Box>
								}
								type='number'
								helperText={!!((touched.duration || submitCount) && errors.duration) && errors.duration}
								error={!!((touched.duration || submitCount) && errors.duration)}
								endAdornment={
									<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
										{t('mins')}
									</Typography>
								}
								inputProps={{ maxLength: 3 }}
								value={values?.duration}
								onChange={(e) => handleChange('duration', e.target.value)}
							/>
						</Box>
						<Box mt={3}>
							{/**NOTE: Section 3 in TL-3330 */}
							{editorMemo}
						</Box>
						<Box mt={3.5}>
							<Typography variant='labelMedium'>{t('common:attachments')}</Typography>
						</Box>
						<Box mt={2}>
							<MyAttachments
								// google attachments
								initialValues={values.googleFiles}
								maxItems={MAX_GOOGLE_UPLOAD_FILES}
								onChange={(files) => setFieldValue('googleFiles', files)}
								onChangeChooseTemplate={onChangeChooseTemplate}
								onAdd={(googleAttachmentsSelected) => {
									const uniqSelected = filterGoogleFileSelected(values.googleFiles, googleAttachmentsSelected);
									setFieldValue('googleFiles', uniqSelected);
								}}
								disabled={isViewOnly}
								archiveMode={archiveMode}
								// file attachments
								attachmentFiles={test?.attachments}
								updateData={(files, isRemove) => onAttachFile(files, isRemove)}
								setUploadingState={setUploadingState}
								canAdd={!isViewOnly && !archiveMode}
							/>
						</Box>
						{!isViewOnly && (
							<Box mt={3.5}>
								<LinkedContents
									// unit={{ id: unitId }}
									courseActivityInfo={test}
									initialLinkedContents={test?.linkContents}
									updateData={(linkContents) => setLinkContents(linkContents)}
									viewOnly={isViewOnly || archiveMode}
									archiveMode={archiveMode}
									courseIdProp={courseId}
									subtitle={t('choose_courses_activities', {
										ns: 'myCourses',
									})}
									emptyContent={t('myCourses:no_linked_contents')}
								/>
							</Box>
						)}
					</Box>
					<Box className={classes.rightContent}>
						<Grading
							// categories={gradeWeightTestOptions}
							gradeCalculation={gradeCalculation}
							values={values}
							handleBlur={handleBlur}
							errors={errors}
							touched={touched}
							disabled={!editable || archiveMode}
							handleChange={handleChange}
							setFieldError={setFieldError}
							tooltip={t('grading_indicator_test')}
							isEditCategory={test?.isEditCategory}
							gradingAttemptsMethodActivity={test?.gradingAttemptsMethod}
							gradingAttemptsMethodDefault={gradingLateCompletionForTest?.gradingAttemptsMethod}
						/>
						<SubmissionAttempts
							disabled={!editable || archiveMode}
							handleBlur={handleBlur}
							values={values}
							handleChange={handleChange}
							attemptsActivity={test?.attempts}
							gradingAttemptsMethodActivity={test?.gradingAttemptsMethod}
							attemptsGradingDefault={gradingLateCompletionForTest?.attempts}
							gradingAttemptsMethodDefault={gradingLateCompletionForTest?.gradingAttemptsMethod}
							gradingLateCompletion={gradingLateCompletionForTest}
						/>
						<Divider className={classes.divider}>
							<hr className={classes.middleDivider} />
						</Divider>
						<StudyForTest
							studyForTest={values.studyForTest}
							handleBlur={handleBlur}
							fieldName='studyForTest'
							disabled={!editable || archiveMode}
							errors={errors.studyForTest}
							touched={touched.studyForTest}
							handleChange={handleChange}
							// gradeWeightOptions={gradeWeightStudyTestOptions}
							gradeCalculation={gradeCalculation}
							submitCount={submitCount}
							setFieldError={setFieldError}
						/>
					</Box>
				</Box>
			</Box>
		);
	const createAsDraft = () => {
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AS_DRAFT);
		handleSubmit(values);
		openDialog && setOpenDialog(false);
	};
	const createAndPublish = () => {
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AND_PUBLISH);
		handleSubmit(values);
	};
	const buttonsOption = [
		{
			label: t('create'),
			value: TYPE_OF_CREATE.CREATE_AS_DRAFT,
			onItemClick: createAsDraft,
		},
		{
			label: t('discard'),
			value: TYPE_OF_CREATE.DISCARD,
			onItemClick: toggleCloseDrawer,
		},
	];

	return (
		<>
			<BaseModal
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				title={t('schedule_a_course_activity')}
				customFooter={
					<Box className='customFooterModal'>
						<Button onClick={createAsDraft}>{t('create')}</Button>
					</Box>
				}
				contentComponent={
					<Typography variant='bodyMediumRegular'>
						{t('the_course_activity_cannot_be_scheduled', { ns: 'common' })}
					</Typography>
				}
			/>

			<FullScreenDialog
				open={isVisible}
				title={isFetching ? <Skeleton variant='text' /> : !isEmpty(test) ? t('edit_the_test') : t('create_a_test')}
				onClose={toggleCloseDrawer}
				groupButtons={
					!!!testId ? (
						<Box style={{ display: 'flex', direction: 'row' }}>
							<Button
								disabled={
									(isFetchingTestDetails || isViewOnly || uploadingState || isEditingTest) && havePermissionPublish
								}
								variant='outlined'
								onClick={createAsDraft}
							>
								{t('create_only')}
							</Button>
							<Box width={'16px'} />
							<Button
								disabled={
									(isFetchingTestDetails || isViewOnly || uploadingState || isEditingTest) && havePermissionPublish
								}
								variant='primary'
								onClick={() => {
									if (isDraft) {
										setOpenDialog(true);
									} else {
										createAndPublish();
									}
								}}
							>
								{t('create_and_schedule')}
							</Button>
						</Box>
					) : (
						!archiveMode && (
							<Stack direction={'row'} gap={2}>
								<Button size='medium' variant='outlined' color='primary' onClick={toggleCloseDrawer}>
									{t('common:cancel')}
								</Button>
								<Button
									disabled={isFetchingTestDetails || isViewOnly || uploadingState || isEditingTest || archiveMode}
									loading={isEditingTest}
									size='medium'
									variant='primary'
									onClick={createAsDraft}
								>
									{t('common:save')}
								</Button>
							</Stack>
						)
					)
				}
			>
				{renderContent()}
			</FullScreenDialog>
			{visiblePublishDialog && (
				<ScheduleDialog
					type={ACTIVITY_CATEGORY.TEST}
					name={test?.name}
					activityId={test?.id || testId}
					unitId={unit?.id || unitId}
					gradingPeriodId={unit?.gradingPeriod?.id}
					courseId={courseId}
					isVisible={visiblePublishDialog}
					toggleCloseDialog={toggleCloseDialog}
					method={DIALOG_TYPE.SCHEDULE}
					updateUnit={updateUnit}
				/>
			)}
		</>
	);
}
CreateTest.propTypes = {
	testInfo: PropTypes.object,
	testId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
	isVisible: PropTypes.bool,
	unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	testViewData: PropTypes.object,
	isViewOnly: PropTypes.bool,
	shouldResetState: PropTypes.bool,
	shouldCallAPItoGetModalData: PropTypes.bool,
};

CreateTest.defaultProps = {
	shouldResetState: true,
	shouldCallAPItoGetModalData: true,
	isViewOnly: false,
	editable: true,
};
export default React.memo(CreateTest, isEqual);
