import { memo } from 'react';

import { TABS } from 'modules/MyCourses/components/ActivityDetailPage/constants';
import ScheduleDialog from 'shared/MyCourses/components/ScheduleDialog/ScheduleDialog';

import CreateEditLesson from 'shared/Lesson/containers/CreateEditLesson';
import { DIALOG_TYPE } from 'shared/MyCourses/constants';
import CreateAssignmentDrawer from 'shared/MyCourses/containers/CreateAssignmentDrawer';
import CreateParticipationDrawer from 'shared/MyCourses/containers/CreateParticipationDrawer';
import CreateTest from 'shared/MyCourses/containers/CreateTest';

import { get } from 'lodash';
import GraderFullScreenDialog from 'modules/Grader/container/GraderFullScreenDialog';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';

const ModalControl = ({
	type = '',
	currentTab,
	shouldCallAPItoGetModalData = false,
	shouldResetState = false,
	handleCloseActivityModal,
	handleCloseScheduleModal,
	handleCloseGraderModal,
	activiTyDetail,
	unitId,
	courseId,
	activityId,
	activityType,
	visible,
	assignmentName,
	editable,
	modifiableSchedule,
	totalPoint,
	showGraderModal,
	showScheduleModal,
	studentInfo,
	selectedSection,
	gradingPeriod,
	term,
}) => {
	const modalData = {
		unitId,
		courseId,
		activityId,
		activityType,
		visible,
		assignmentName,
		editable,
		modifiableSchedule,
		totalPoint,
		showGraderModal,
		showScheduleModal,
		studentInfo,
		selectedSection,
		gradingPeriod,
		term,
		unitInfo: { id: unitId },
	};

	if (TABS[currentTab] === TABS.instruction) {
		if (type === ACTIVITY_CATEGORY.ASSIGNMENT) {
			return (
				get(modalData, 'visible') && (
					<CreateAssignmentDrawer
						isVisible={get(modalData, 'visible')}
						editable={get(modalData, 'editable')}
						onClose={handleCloseActivityModal}
						unitId={get(modalData, 'unitId')}
						assignmentId={get(modalData, 'activityId')}
						shouldCallAPItoGetModalData={shouldCallAPItoGetModalData}
						shouldResetState={shouldResetState}
					/>
				)
			);
		}

		if (type === ACTIVITY_CATEGORY.TEST) {
			return (
				get(modalData, 'visible') && (
					<CreateTest
						isVisible={get(modalData, 'visible')}
						courseId={get(modalData, 'courseId')}
						editable={get(modalData, 'editable')}
						onClose={handleCloseActivityModal}
						unitId={get(modalData, 'unitId')}
						testId={get(modalData, 'activityId')}
						shouldCallAPItoGetModalData={shouldCallAPItoGetModalData}
						shouldResetState={shouldResetState}
					/>
				)
			);
		}

		if (type === ACTIVITY_CATEGORY.LESSON) {
			return (
				get(modalData, 'visible') && (
					<CreateEditLesson
						isVisible={get(modalData, 'visible')}
						courseId={get(modalData, 'courseId')}
						unitId={get(modalData, 'unitId')}
						onCloseDialog={handleCloseActivityModal}
						shouldCallAPItoGetModalData={shouldCallAPItoGetModalData}
						shouldResetState={shouldResetState}
					/>
				)
			);
		}

		if (type === ACTIVITY_CATEGORY.PARTICIPATION) {
			return (
				get(modalData, 'visible') && (
					<CreateParticipationDrawer
						isVisible={get(modalData, 'visible')}
						editable={get(modalData, 'editable')}
						onClose={handleCloseActivityModal}
						participationId={get(modalData, 'activityId')}
						shouldCallAPItoGetModalData={shouldCallAPItoGetModalData}
						shouldResetState={shouldResetState}
					/>
				)
			);
		}
	}

	if (TABS[currentTab] === TABS.student) {
		return (
			<>
				{get(modalData, 'showGraderModal') && (
					<GraderFullScreenDialog
						onCloseDialog={handleCloseGraderModal}
						openGrader={get(modalData, 'showGraderModal')}
						courseId={get(modalData, 'courseId')}
						activityId={get(modalData, 'activityId')}
						activityType={
							// WE JUST WORKAROUND FOR THIS MISTAKEN ACTIVITY TYPE IN GRADER
							get(modalData, 'activityType') === ACTIVITY_CATEGORY.TEST
								? ACTIVITY_CATEGORY.TEST_IN_CLASS
								: get(modalData, 'activityType') === ACTIVITY_CATEGORY.TEST_IN_CLASS
								? ACTIVITY_CATEGORY.TEST
								: get(modalData, 'activityType')
						}
						originalSectionSelected={get(modalData, 'selectedSection')}
						gradingPeriodSelected={get(modalData, 'gradingPeriod.id')}
						schoolYearSelected={get(modalData, 'schoolYearId')}
						studentId={get(modalData, 'studentInfo.studentId')}
						currentTerm={get(modalData, 'term')}
						unitInfo={get(modalData, 'unitInfo')}
					/>
				)}
				{get(modalData, 'showScheduleModal') && (
					<ScheduleDialog
						type={type}
						name={get(modalData, 'assignmentName')}
						activityId={activityId}
						unitId={get(modalData, 'unitId')}
						courseId={get(modalData, 'courseId')}
						isVisible={get(modalData, 'showScheduleModal')}
						toggleCloseDialog={handleCloseScheduleModal}
						shouldResetState={shouldResetState}
						method={
							!!get(modalData, 'modifiableSchedule.isScheduledAtLeastOne')
								? DIALOG_TYPE.RESCHEDULE
								: DIALOG_TYPE.SCHEDULE
						}
						showOldSessions={get(modalData, 'modifiableSchedule.showOldSessions')}
						hasPublished={get(modalData, 'modifiableSchedule.hasPublished')}
						applyFor={get(activiTyDetail, 'applyFor')}
					/>
				)}
			</>
		);
	}

	return <></>;
};

export default memo(ModalControl);
