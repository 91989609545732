import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Collapse, Radio } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import {
	PICTURE_IN_CLASS_SUBMISSIONS_OPTIONS,
	SUBMISSION_METHOD,
	SUBMISSION_OPTIONS,
	SUBMISSION_REQUIRED,
	SUBMISSION_REQUIRED_DEFAULT,
} from '../constants';

import CollapseOptions from './CollapseOptions';
const useStyles = makeStyles()((theme) => ({
	root: {
		padding: theme.spacing(2),
		borderRadius: theme.spacing(2),
		background: theme.newColors.gray[100],
		marginTop: theme.spacing(3),
	},
	radioSubmissionRequired: {
		marginTop: theme.spacing(3),
	},
	radioSubmissionMethods: {
		marginTop: theme.spacing(3),
	},
	radioPictureSubmission: {
		marginTop: theme.spacing(3),
	},
}));

function SubmissionRequirements({ values, handleChange, disabled, fieldName }) {
	const [open, setOpen] = useState(true);
	const { classes } = useStyles();
	const { t } = useTranslation(['myCourses', 'common']);
	const submissionRequiredName = !!fieldName ? `${fieldName}.submissionRequired` : 'submissionRequired';
	const submissionMethodName = !!fieldName ? `${fieldName}.submissionMethod` : 'submissionMethod';
	const pictureInClassSubmissionsName = !!fieldName
		? `${fieldName}.pictureInClassSubmissions`
		: 'pictureInClassSubmissions';

	return (
		<Box className={classes.root}>
			<CollapseOptions open={open} onClose={() => setOpen(!open)} title={t('submission_requirements')}>
				<Radio
					className={classes.radioSubmissionRequired}
					label={t('submission_required')}
					options={SUBMISSION_REQUIRED}
					name={submissionRequiredName}
					value={values?.submissionRequired}
					disabled={disabled}
					onChange={(value) => {
						handleChange(submissionRequiredName, JSON.parse(value));
						if (JSON.parse(value) === SUBMISSION_REQUIRED_DEFAULT) {
							handleChange(submissionMethodName, SUBMISSION_METHOD.ONLINE);
						}
					}}
					fullWidth={true}
				/>
				<Collapse in={values.submissionRequired}>
					<Radio
						disabled={disabled}
						className={classes.radioSubmissionMethods}
						label={t('submission_method')}
						name={submissionMethodName}
						options={SUBMISSION_OPTIONS}
						value={values?.submissionMethod}
						onChange={(value) => {
							handleChange(submissionMethodName, value);
						}}
						fullWidth={true}
					/>
				</Collapse>
				<Collapse in={values.submissionRequired && Number(values?.submissionMethod) === SUBMISSION_METHOD.OFFLINE}>
					<Radio
						disabled={disabled}
						className={classes.radioPictureSubmission}
						label={t('Picture of in-class submissions')}
						name={pictureInClassSubmissionsName}
						options={PICTURE_IN_CLASS_SUBMISSIONS_OPTIONS}
						value={values?.pictureInClassSubmissions}
						onChange={(value) => {
							handleChange(pictureInClassSubmissionsName, value);
						}}
						fullWidth={true}
					/>
				</Collapse>
			</CollapseOptions>
		</Box>
	);
}

SubmissionRequirements.propTypes = {
	method: PropTypes.number,
	submissionRequired: PropTypes.bool,
	handleChange: PropTypes.func,
};

export default SubmissionRequirements;
