export default {
	GRADING_CONFIG_LATE_COMPLETION: 'GRADING_CONFIG_LATE_COMPLETION',
	GRADING_CONFIG_LATE_COMPLETION_SUCCESS: 'GRADING_CONFIG_LATE_COMPLETION_SUCCESS',
	GRADING_CONFIG_LATE_COMPLETION_FAILED: 'GRADING_CONFIG_LATE_COMPLETION_FAILED',

	GET_DETAIL_GRADING_LATE_COMPLETION: 'GET_DETAIL_GRADING_LATE_COMPLETION',
	GET_DETAIL_GRADING_LATE_COMPLETION_SUCCESS: 'GET_DETAIL_GRADING_LATE_COMPLETION_SUCCESS',
	GET_DETAIL_GRADING_LATE_COMPLETION_FAILED: 'GET_DETAIL_GRADING_LATE_COMPLETION_FAILED',

	UPDATE_GRADING_LATE_COMPLETION: 'UPDATE_GRADING_LATE_COMPLETION',
	UPDATE_GRADING_LATE_COMPLETION_SUCCESS: 'UPDATE_GRADING_LATE_COMPLETION_SUCCESS',
	UPDATE_GRADING_LATE_COMPLETION_FAILED: 'UPDATE_GRADING_LATE_COMPLETION_FAILED',
};
