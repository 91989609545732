import { actions as taskActions, END_POINT as taskEndPoint } from 'modules/MyTasks/constants';
import { objectToParams } from 'utils';

export const actions = {
	...taskActions,
	MY_TODO_SET_STATE: 'MY_TODO_SET_STATE',

	GET_GRADE_BOOST_INFORMATION: 'GET_GRADE_BOOST_INFORMATION',
	GET_GRADE_BOOST_INFORMATION_SUCCESS: 'GET_GRADE_BOOST_INFORMATION_SUCCESS',
	GET_GRADE_BOOST_INFORMATION_FAILED: 'GET_GRADE_BOOST_INFORMATION_FAILED',

	GET_SCHOOL_PLAN: 'GET_SCHOOL_PLAN',
	GET_SCHOOL_PLAN_SUCCESS: 'GET_SCHOOL_PLAN_SUCCESS',
	GET_SCHOOL_PLAN_FAILED: 'GET_SCHOOL_PLAN_FAILED',

	UPDATE_STUDENT_GRADE_BOOST_ACTIVITY: 'UPDATE_STUDENT_GRADE_BOOST_ACTIVITY',
	UPDATE_STUDENT_GRADE_BOOST_ACTIVITY_SUCCESS: 'UPDATE_STUDENT_GRADE_BOOST_ACTIVITY_SUCCESS',
	UPDATE_STUDENT_GRADE_BOOST_ACTIVITY_FAILED: 'UPDATE_STUDENT_GRADE_BOOST_ACTIVITY_FAILED',

	GET_INFO_POTENTIAL_GRADE: 'GET_INFO_POTENTIAL_GRADE',
	GET_INFO_POTENTIAL_GRADE_SUCCESS: 'GET_INFO_POTENTIAL_GRADE_SUCCESS',
	GET_INFO_POTENTIAL_GRADE_FAILED: 'GET_INFO_POTENTIAL_GRADE_FAILED',
};

export const END_POINT = {
	...taskEndPoint,
	get_grade_boost_information: {
		url: (organizationId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organization/${organizationId}/grade-boost/?${objectToParams(params)}`,
		method: 'GET',
	},
	get_school_plan: {
		method: 'GET', // params: schoolYearId, timezone
		url: (orgId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/students/dashboard/school-plan?${objectToParams(
				params,
			)}`,
	},
	update_student_grade_boost_activity: {
		url: (organizationId, courseId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organization/${organizationId}/courses/${courseId}/student/grade-boost-activity?${objectToParams(params)}`,
		method: 'PUT',
	},
	get_potential_information: {
		url: (params) => `${process.env.REACT_APP_API_URL_V2}/grade-boost/get-potential-impact?${objectToParams(params)}`,
		method: 'POST',
	},
};

export const STATUS_GROWING = (value) => {
	if (value > 0) return 'GROW';
	return 'NONE';
};

export const TYPE_MODAL = {
	ACTIVITY: 1,
	COURSE_GRADE_BOOST_ACTIVITIES: 2,
};

export const GRADE_BOOST_TYPE = {
	SELECTED: 1,
	UNSELECTED: -1,
};

export const contentHasBoosterTooltip = ({ courseName, potentialGradeLetter, potentialGrade, gradeImpact }) => (
	<>
		{`You have the opportunity to boost your ${courseName} course Overall Grade to ${potentialGradeLetter} - ${potentialGrade}.`}
		<br />
		{`${gradeImpact} is the improvement percentage when you complete all of the Grade Boost activities in the ${courseName} course with the full total points.`}
	</>
);
