import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	fetchingCourseListByTerm: false,
	fetchingGradeDetailsActivities: false,
	fetchingCourseInfo: false,
	fetchingCourseInfoGradeReport: false,
	listCoursesByTerm: [],
	fetchingCourseListByTermSuccess: null,
	getGradeReportDetailsSuccess: null,
	courseInfo: {},
	selectedCourseId: null,
	gradeDetails: [],
	error: {},
};

export default createReducers(initialState, actions);
