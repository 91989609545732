/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LOCAL_STORAGE } from 'utils/constants';
import firebase from 'utils/firebase';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import PropTypes from 'prop-types';

import notificationAction from '../actions';

function NotificationProvider(props) {
	const dispatch = useDispatch();
	const authContext = useContext(AuthDataContext);
	const { currentUser } = authContext;

	const [newNotification, setNewNotification] = useState();
	const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

	const getMessagingToken = () =>
		messaging
			.requestPermission()
			.then(() => messaging.getToken())
			.then((token) => {
				localStorage.setItem('firebase_token', token);
				if (currentUser) {
					dispatch(notificationAction.setupDeviceToken({ token }));
				}
				localStorage.setItem(LOCAL_STORAGE.DEVICE_TOKEN, token);
			})
			.catch((error) => {
				console.log(error);
			});

	//NOTE: Register notìfication services
	useEffect(() => {
		if (messaging) {
			messaging.onMessage((payload) => {
				console.log('Receive direct message:', payload);
				setNewNotification(payload);
			});

			getMessagingToken();

			const channel = new BroadcastChannel('notifications');
			channel.addEventListener('message', (event) => {
				setNewNotification(event.data);

				console.log('Receive background: ', event.data);
			});
		} else {
			console.log('Firebase not support for this browser :>> ');
		}
	}, []);

	useEffect(() => {
		if (newNotification) {
			dispatch(notificationAction.notificationSetState({ firebaseMessage: newNotification }));
		}
	}, [newNotification]);

	return <>{props.children}</>;
}

NotificationProvider.propTypes = {
	children: PropTypes.node,
};

export default NotificationProvider;
