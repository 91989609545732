import { useTheme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const { Box } = require('ella-storybook');

const useStyles = makeStyles()((theme) => ({
	circleBox: {
		width: '580px',
		height: '580px',
		backgroundColor: 'transparent',
		borderRadius: '50%',
		display: 'flex',
		border: `4px dashed ${theme.newColors.gray[400]}`,
		opacity: '40%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	miniCircle: {
		width: '480px',
		height: '480px',
		borderRadius: '50%',
	},
	bottomCirclePosition: {
		position: 'absolute',
		top: '700px',
		left: '-12px',
		zIndex: -1,
	},
	upperCirclePosition: {
		position: 'absolute',
		top: '-284px',
		right: '-12px',
	},
}));

const BackgroundOtp = (props) => {
	const { classes } = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.container}>
			<Box className={`${classes.circleBox} ${classes.bottomCirclePosition}`}>
				<Box className={classes.miniCircle} backgroundColor={theme.newColors.blue[300]} />
			</Box>
			<Box className={`${classes.circleBox} ${classes.upperCirclePosition}`}>
				<Box className={classes.miniCircle} backgroundColor={theme.newColors.yellow[400]} />
			</Box>
		</div>
	);
};
BackgroundOtp.defaultProps = {};

export default BackgroundOtp;
