import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

import TblIcon from 'components/TblIcon';
import { TblIconButton } from 'components/TblIconButton';

import { Box, Button, Typography } from 'ella-storybook';
import { RESCHEDULE_STEP } from 'modules/MyCourses/constants';

const Footer = ({
	reScheduleStep,
	currentActivityIndexOutOfTotal,
	onClose,
	onUpdateSchedule,
	onPrevActivity,
	onNextActivity,
	isDisablePrev,
	isDisableNext,
	isLoading,
	hasUpdated,
	amountOfCourse,
	isReschedulingActivity,
}) => {
	const { t } = useTranslation('myCourses');

	if (isLoading) return <></>;

	switch (reScheduleStep) {
		case RESCHEDULE_STEP.COURSE:
			return (
				<Box className='customFooterModal'>
					<Button variant='outlined' onClick={onClose}>
						{t(amountOfCourse > 0 ? 'do_it_later' : 'got_it', { ns: 'common' })}
					</Button>
				</Box>
			);
		case RESCHEDULE_STEP.ACTIVITY:
			return <></>;
		case RESCHEDULE_STEP.SCHEDULE:
			return (
				<Box className='customFooterModal' justifyContent={'space-between'}>
					<Box display={'flex'} alignItems={'center'} gap={1}>
						{currentActivityIndexOutOfTotal ? (
							<>
								<Box>
									<TblIconButton
										disabled={isDisablePrev}
										onClick={() => onPrevActivity && onPrevActivity()}
										sx={{ width: (theme) => theme.spacing(4), height: (theme) => theme.spacing(4) }}
									>
										<TblIcon icon='chevron_left' fontSize={'24px'} style={{ marginRight: '2px' }} />
									</TblIconButton>
									<TblIconButton
										disabled={isDisableNext}
										onClick={() => onNextActivity && onNextActivity()}
										sx={{ width: (theme) => theme.spacing(4), height: (theme) => theme.spacing(4) }}
									>
										<TblIcon icon='chevron_right' fontSize={'24px'} style={{ marginLeft: '2px' }} />
									</TblIconButton>
								</Box>
								<Typography component={'div'} variant='bodyMediumRegular'>
									{currentActivityIndexOutOfTotal}
								</Typography>
							</>
						) : (
							<></>
						)}
					</Box>
					{hasUpdated ? (
						<Box display={'flex'} gap={1}>
							<CheckCircleSharpIcon
								sx={{
									color: (theme) => theme.mainColors.green[0],
									fontSize: (theme) => theme.spacing(3),
								}}
							/>
							<Typography variant='bodyMediumRegular'>{t('updated')}</Typography>
						</Box>
					) : (
						<Button
							variant='primary'
							onClick={onUpdateSchedule}
							disabled={isReschedulingActivity}
							loading={isReschedulingActivity}
						>
							{t('Update activity', { ns: 'common' })}
						</Button>
					)}
				</Box>
			);
		default:
			return <></>;
	}
};

export default memo(Footer);
