import React from 'react';

import { CLASS_NAME } from 'utils/constants';

import PropTypes from 'prop-types';
import { Editor as RoosterJsEditor } from 'roosterjs-editor-core';
import {
	ContentEdit,
	ContextMenu,
	CustomReplace as CustomReplacePlugin,
	HyperLink,
	ImageEdit,
	Paste,
	TableCellSelection,
	TableResize,
	Watermark,
	getAllFeatures,
} from 'roosterjs-editor-plugins';
import { withStyles } from 'tss-react/mui';

import { CONTEXT_MENU_DATA_PROVIDER } from '../plugins/contextMenu/ContextMenuProvider';
import ImageEditPlugin from '../plugins/contextMenu/ImageEditPlugin';
import ResetListPlugin from '../plugins/contextMenu/ResetListPlugin';
import TableEditPlugin from '../plugins/contextMenu/TableEditPlugin';
import { exception } from '../utils/constants';

import styles from './styles';
export const UrlPlaceholder = '$url$';

// let editorInstance = null;
let editorInstanceTogglePlugins = null;
const helperType = {
	cellResizer: 'CellResizer',
	tableInserter: 'TableInserter',
	tableResizer: 'TableResizer',
	tableSelector: 'TableSelector',
};

class Editor extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = props.initState;
		this.editor = null;
	}

	getSnapshotBeforeUpdate() {
		this.disposeEditor();
		return null;
	}

	componentDidUpdate() {
		this.initEditor();
	}

	componentDidMount() {
		this.initEditor();
		if (editorInstanceTogglePlugins === null) {
			editorInstanceTogglePlugins = this.editor;
		}
	}

	componentWillUnmount() {
		if (editorInstanceTogglePlugins === this.editor) {
			editorInstanceTogglePlugins = null;
		}
		this.disposeEditor();
	}

	resetEditorPlugin(pluginState) {
		this.setState(pluginState);
	}

	getContent() {
		return this.editor?.getContent();
	}

	changeContent(content) {
		if (!content) return;
		let newContent = content;
		const regex = /<img.*?>/gm;
		let img = null;
		do {
			img = regex.exec(content);
			if (img !== null && img[0].includes(CLASS_NAME.INIT)) {
				const lazyImg = img[0].replace(' src', ' data-src');
				newContent = newContent.replace(img[0], lazyImg);
			}
		} while (img !== null);
		newContent = newContent.replaceAll(CLASS_NAME.INIT, CLASS_NAME.LAZY);
		return newContent;
	}

	initEditor() {
		const { disabled, defaultValue } = this.props;
		const { pluginList, linkTitle } = this.state;

		const onShowHelperElement = (data, type) => {
			if (type === helperType.tableInserter) {
				data.className = exception.addColumnClassName;
			}
			data.style = `${data.style || ''};z-index: 1201`; // z-index of MuiDrawer is 1200
		};

		const getLinkCallBack = () => {
			if (!linkTitle) return null;
			if (linkTitle?.indexOf(UrlPlaceholder) >= 0) {
				return (url) => linkTitle.replace(UrlPlaceholder, url);
			}
			return () => linkTitle;
		};

		editorInstanceTogglePlugins = {
			hyperlink: pluginList.hyperlink ? new HyperLink(getLinkCallBack()) : null,
			paste: pluginList.paste ? new Paste() : null,
			contentEdit: pluginList.contentEdit ? new ContentEdit(this.getContentEditOptions()) : null,
			watermark: pluginList.watermark ? new Watermark(this.state.watermarkText) : null,
			ImageEdit: pluginList.ImageEdit ? new ImageEdit() : null,
			tableResize: pluginList.tableResize ? new TableResize(onShowHelperElement) : null,
			customReplace: pluginList.customReplace ? new CustomReplacePlugin() : null,
			tableCellSelection: pluginList.tableCellSelection ? new TableCellSelection() : null,
			resetList: pluginList.contextMenu ? new ResetListPlugin() : null,
			imageEdit: pluginList.contextMenu ? new ImageEditPlugin() : null,
			tableEditMenu: pluginList.contextMenu ? new TableEditPlugin() : null,
			contextMenu: pluginList.contextMenu ? new ContextMenu(CONTEXT_MENU_DATA_PROVIDER) : null,
		};
		let plugins = [...Object.values(editorInstanceTogglePlugins), ...this.props.plugins];

		let options = {
			plugins: plugins,
			defaultFormat: this.state.defaultFormat,
			initialContent: this.changeContent(defaultValue),
			experimentalFeatures: this.state.experimentalFeatures,
		};
		this.contentDiv.contentEditable = !disabled;
		this.editor = new RoosterJsEditor(this.contentDiv, options);
	}

	disposeEditor() {
		if (this.editor) {
			this.editor.dispose();
		}
		this.editor = null;
	}

	getContentEditOptions() {
		let defaultFeatures = getAllFeatures();
		return Object.assign(defaultFeatures, this.state.contentEditFeatures);
	}

	render() {
		const { classes, disabled } = this.props;
		return (
			<div
				className={`${this.props.className} ${classes.editor} ${
					disabled ? classes.editorDisabled : ''
				} ql-editor tbl-view-editor`}
				ref={(ref) => (this.contentDiv = ref)}
			/>
		);
	}
}

Editor.propTypes = {
	classes: PropTypes.object,
	initState: PropTypes.object,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.string,
	plugins: PropTypes.array,
};
export default withStyles(Editor, styles);
