import React from 'react';

import Box from '@mui/material/Box';

import TblIcon from 'components/TblIcon';
import TblTooltipDynamic from 'components/TblTooltipDynamic';

import { Chip, Collapse, Tooltip, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import theme from 'theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'flex',
		width: '100%',

		justifyContent: 'space-between',
		'&:hover': {
			cursor: 'pointer',
		},
		'& .MuiSvgIcon-root': {
			width: theme.spacing(5),
			height: theme.spacing(5),
			fontSize: '50px',
		},
	},
	titleBox: {
		display: 'flex',
		gap: theme.spacing(1),
		width: '80%',
		'div:first-of-type': {
			width: 'auto',
		},
	},
	expandBox: {
		display: 'flex',
		gap: theme.spacing(1),
		justifyContent: 'right',
		width: '20%',
	},
	indicatorSetting: {
		marginTop: theme.spacing(0.5),
		height: theme.spacing(2.5),
	},
}));
function CollapseOptions({ open, onClose, title, titleVariant, chipTitle, indicator, children, tooltip }) {
	const { classes } = useStyles();
	return (
		<>
			<Box onClick={onClose} className={classes.root}>
				<Box className={classes.titleBox}>
					<TblTooltipDynamic title={title}>
						<Typography variant={titleVariant || 'titleSmall'}>{title}</Typography>
					</TblTooltipDynamic>
					{chipTitle && <Chip label={<Typography variant='labelMedium'>{chipTitle}</Typography>} size='small' />}

					{tooltip && (
						<Tooltip title={tooltip} placement='top'>
							<Box className={classes.indicatorSetting}>
								<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
							</Box>
						</Tooltip>
					)}
				</Box>

				<Box className={classes.expandBox}>
					{indicator && (
						<Typography variant='bodyMediumRegular' color={(theme) => theme.newColors.gray[400]}>
							{indicator}
						</Typography>
					)}
					{open ? <TblIcon icon='expand_less' /> : <TblIcon icon='expand_more' />}
				</Box>
			</Box>
			<Collapse in={open}>{children}</Collapse>
		</>
	);
}

CollapseOptions.propTypes = {
	children: PropTypes.node,
	indicator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.string,
	titleVariant: PropTypes.string,
	chipTitle: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

export default CollapseOptions;
