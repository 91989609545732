import { editTable } from 'roosterjs-editor-api';
import { TableOperation } from 'roosterjs-editor-types';

const TableEditOperations = {
	menuNameTableInsertAbove: TableOperation.InsertAbove,
	menuNameTableInsertBelow: TableOperation.InsertBelow,
	menuNameTableInsertLeft: TableOperation.InsertLeft,
	menuNameTableInsertRight: TableOperation.InsertRight,
	menuNameTableDeleteTable: TableOperation.DeleteTable,
	menuNameTableDeleteColumn: TableOperation.DeleteColumn,
	menuNameTableDeleteRow: TableOperation.DeleteRow,
	menuNameTableMergeAbove: TableOperation.MergeAbove,
	menuNameTableMergeBelow: TableOperation.MergeBelow,
	menuNameTableMergeLeft: TableOperation.MergeLeft,
	menuNameTableMergeRight: TableOperation.MergeRight,
	menuNameTableMergeCells: TableOperation.MergeCells,
	menuNameTableSplitHorizontally: TableOperation.SplitHorizontally,
	menuNameTableSplitVertically: TableOperation.SplitVertically,
	menuNameTableAlignLeft: TableOperation.AlignCellLeft,
	menuNameTableAlignCenter: TableOperation.AlignCellCenter,
	menuNameTableAlignRight: TableOperation.AlignCellRight,
	menuNameTableAlignTop: TableOperation.AlignCellTop,
	menuNameTableAlignMiddle: TableOperation.AlignCellMiddle,
	menuNameTableAlignBottom: TableOperation.AlignCellBottom,
};

function getEditingTable(editor, node) {
	const td = editor.getElementAtCursor('TD,TH', node);
	const table = td && editor.getElementAtCursor('table', td);

	return table?.isContentEditable ? { table, td } : null;
}

export default class TableEditPlugin {
	getName() {
		return 'TableEdit';
	}

	initialize(editor) {
		this.editor = editor;
	}

	dispose() {
		this.editor = null;
	}

	onClick(key, editor) {
		editTable(editor, TableEditOperations[key]);
	}

	getContextMenuItems(node) {
		const items = [];
		if (!!getEditingTable(this.editor, node)) {
			items.push(
				{
					key: 'menuNameTableInsert',
					name: 'Insert',
					subItems: {
						menuNameTableInsertAbove: {
							name: 'Insert above',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableInsertBelow: {
							name: 'Insert below',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableInsertLeft: {
							name: 'Insert left',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableInsertRight: {
							name: 'Insert right',
							onClick: (key) => this.onClick(key, this.editor),
						},
					},
				},
				{
					key: 'menuNameTableDelete',
					name: 'Delete',
					subItems: {
						menuNameTableDeleteColumn: {
							name: 'Delete column',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableDeleteRow: {
							name: 'Delete row',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableDeleteTable: {
							name: 'Delete table',
							onClick: (key) => this.onClick(key, this.editor),
						},
					},
				},
				{
					key: 'menuNameTableMerge',
					name: 'Merge',
					subItems: {
						menuNameTableMergeAbove: {
							name: 'Merge above',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableMergeBelow: {
							name: 'Merge below',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableMergeLeft: {
							name: 'Merge left',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableMergeRight: {
							name: 'Merge right',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableMergeCells: {
							name: 'Merge selected cells',
							onClick: (key) => this.onClick(key, this.editor),
						},
					},
				},
				{
					key: 'menuNameTableSplit',
					name: 'Split',
					subItems: {
						menuNameTableSplitHorizontally: {
							name: 'Split horizontally',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableSplitVertically: {
							name: 'Split vertically',
							onClick: (key) => this.onClick(key, this.editor),
						},
					},
				},
				{
					key: 'menuNameTableAlign',
					name: 'Align cell',
					subItems: {
						menuNameTableAlignLeft: {
							name: 'Align left',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableAlignCenter: {
							name: 'Align center',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableAlignRight: {
							name: 'Align right',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableAlignTop: {
							name: 'Align top',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableAlignMiddle: {
							name: 'Align middle',
							onClick: (key) => this.onClick(key, this.editor),
						},
						menuNameTableAlignBottom: {
							name: 'Align bottom',
							onClick: (key) => this.onClick(key, this.editor),
						},
					},
				},
			);
		}
		return items;
	}
}
