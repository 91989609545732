import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { SCHOOL_MANAGER } from 'utils/roles';

import { MODULE_ALIAS, PERMISSION_BASIC_KEYS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/icn_schoolyear.svg';

import { ROUTE_SCHOOL_YEAR } from './constantsRoute';
import reducer from './reducers';

const SchoolYear = loadable(() => import(/* webpackChunkName: "LazySchoolYear" */ './containers'));
const SchoolYearDetail = loadable(() =>
	import(/* webpackChunkName: "LazySchoolYearDetail" */ './containers/SchoolYearDetail'),
);

export default [
	{
		path: ROUTE_SCHOOL_YEAR.DEFAULT,
		component: withReducer('SchoolYear', reducer)(SchoolYear),
		private: true,
		exact: true,
		roles: [SCHOOL_MANAGER],
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.SCHOOL_YEAR,
		menu: {
			title: 'School Years',
			group: 'School Administration',
			icon: IcnMenu,
			order: 3,
			groupOrder: 2,
		},
	},
	{
		path: ROUTE_SCHOOL_YEAR.SCHOOL_YEAR_DETAIL(':schoolYearId'),
		component: withReducer('SchoolYear', reducer)(SchoolYearDetail),
		private: true,
		exact: true,
		roles: [SCHOOL_MANAGER],
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.SCHOOL_YEAR,
	},
];
