import { objectToParams } from 'utils';

export default {
	get_detail_grading_late_completion: {
		url: (orgId, courseId, urlParams) =>
			`${process.env.REACT_APP_API_URL}/organization/${orgId}/courses/${courseId}/gradeWeight/grading?${objectToParams(
				urlParams,
			)}`,
		method: 'GET',
	},

	update_grading_late_completion: {
		url: (orgId, courseId) =>
			`${process.env.REACT_APP_API_URL}/organization/${orgId}/courses/${courseId}/gradeWeight/grading`,
		method: 'PUT',
	},
};
