import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';

export const TABS = {
	instruction: 0,
	student: 1,
	0: 'instruction',
	1: 'student',
};

export const TEST_TABS = {
	test: 0,
	studyForTest: 1,
	0: 'test',
	1: 'studyForTest',
};

export const TEST_TYPE = {
	test: ACTIVITY_CATEGORY.TEST,
	studyForTest: ACTIVITY_CATEGORY.TEST_IN_CLASS,
};
