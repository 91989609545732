import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';

import { GradeInput } from 'components/GradeInput';
import StoryBookCheckBox from 'components/StoryBookCheckBox';
import GradingStatusSaved from 'shared/MyCourses/components/GradingStatusSaved';

import clsx from 'clsx';
import { Box, Typography } from 'ella-storybook';
import { Divider } from 'ella-storybook';
import { isEmpty } from 'lodash-es';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { isNumber } from 'modules/MyCourses/utils';
import PropTypes from 'prop-types';

import GraderActions from '../../actions';

import { useStyles } from './styles';
function Grading(props) {
	const { totalPoint, courseId, disabled, extraCredit, currentTermId, sectionSelected, gradingPeriodSelected } = props;

	const { classes } = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('grader', 'common');

	const graderDetail = useSelector((state) => state.Grader.graderDetail);
	const gradingList = useSelector((state) => state.Grader.gradingList);
	const activitySelectedType = useSelector((state) => state.Grader.activitySelectedType);
	const isFetchingGraderDetail = useSelector((state) => state.Grader.isFetchingGraderDetail);
	const isSubmittingGrade = useSelector((state) => state.Grader.isSubmittingGrade);

	const submissionAttempts = useSelector((state) => state.Grader.graderDetail?.submissionAttempts) ?? [];
	const applyDeductionValue = useSelector((state) => state.Grader.graderDetail?.applyDeductionValue) ?? 0;

	const { allowLateTurnIn, partialCredit } = useSelector(
		(state) => state.Grader.graderDetail?.shadowActivity?.masterActivity,
	);

	// const finalGradeAttemptSelected = graderDetail?.finalGrade;

	const [isDeduct, setIsDeduct] = useState(true);
	const [grade, setGrade] = useState('');

	// const index = attemptList?.findIndex((item) => item.id === attemptSelected?.id);
	// TL-4113: disable when previous attempt have been graded
	// const disableInputGrade =
	//   (attemptList[0]?.id !== attemptSelected?.id && !isNaN(attemptList[index - 1]?.grade)) || isOverTime;

	// Init grade and final grade
	useEffect(() => {
		if (!isEmpty(graderDetail)) {
			setGrade(graderDetail?.grade || '');

			if (graderDetail?.id && graderDetail?.finalGrade) {
				setIsDeduct(allowLateTurnIn && partialCredit && graderDetail.grade !== graderDetail.finalGrade ? true : false);
			}
		}
	}, [graderDetail?.id]);

	useEffect(() => {
		if (!isEmpty(graderDetail)) {
			setGrade(graderDetail.grade ?? '');
			// dispatch(
			//   GraderActions.graderSetState({
			//     graderAttemptSelected: graderDetail.grade ?? '',
			//     finalGradeAttemptSelected: graderDetail.finalGrade,
			//   })
			// );
		}
	}, [graderDetail]);

	// const onResetInputGradeSuccess = () => {
	//   dispatch(
	//     GraderActions.graderSetState({
	//       inputGradeSuccess: null,
	//       attemptGradedId: null,
	//     })
	//   );
	// };

	const sortModel = {
		field: 'lastName',
		sort: 'asc',
	};

	const onGrade = (applyDeductionParam = null) => {
		let isApplyDeduct = applyDeductionParam !== null ? applyDeductionParam : isDeduct;
		const newGrade = grade;
		const oldGrade = graderDetail.grade;
		// TL-4113: Return oldGrade if edit empty grade
		if (!isNaN(oldGrade) && !newGrade && newGrade !== 0) {
			onChangeGrade(oldGrade);
			return;
		}

		if (graderDetail.id && activitySelectedType) {
			if (activitySelectedType === ACTIVITY_CATEGORY.TEST) {
				isApplyDeduct = false;
			}

			const urlParams = {
				sectionId: sectionSelected,
				gradingPeriodId: gradingPeriodSelected,
				sortBy: sortModel.field !== 'studentName' ? sortModel.field : 'lastName',
				studentName: undefined,
			};

			dispatch(
				GraderActions.inputGrade({
					inputGradeSuccess: null,
					updateStudentAttemptsSuccess: null,
					switchModeSuccess: null,
					progressId: graderDetail.id,
					courseId: courseId,
					data: {
						originalInputGrade: newGrade,
						type: activitySelectedType,
						isDeduct: isApplyDeduct,
						termId: currentTermId,
					},
					isSubmittingGrade: true,
					urlParams,
				}),
			);
		}
	};

	// Until now, we always just have 1 "submissionAttempts".
	// the "attemptSelected" only use on GraderLeftContent
	// Init attempt selected when change student or filter
	useEffect(() => {
		// setGrade('');
		if (!gradingList.length) {
			dispatch(
				GraderActions.graderSetState({
					attemptSelected: {},
				}),
			);
			return;
		}

		if (!!graderDetail?.submissionAttempts?.length) {
			dispatch(
				GraderActions.graderSetState({
					attemptSelected: {
						...graderDetail.submissionAttempts?.[0],
						// attemptIndex: graderDetail.submissionAttempts.length,
					},
				}),
			);
		} else {
			dispatch(
				GraderActions.graderSetState({
					attemptSelected: {},
				}),
			);
		}
	}, [graderDetail?.submissionAttempts, gradingList]);

	// Update attempt list when input grade success
	// useDidMountEffect(() => {
	//   if (inputGradeSuccess) {
	//     const index = attemptList.findIndex((item) => item.id === attemptGradedId);
	//     if (index >= 0) {
	//       attemptList[index].grade = graderAttemptSelected;
	//       attemptList[index].finalGrade = finalGradeAttemptSelected;
	//       dispatch(
	//         GraderActions.graderSetState({
	//           attemptList: attemptList,
	//         })
	//       );
	//     }
	//   }
	// }, [inputGradeSuccess]);

	const onChangeGrade = (value) => {
		// onResetInputGradeSuccess();
		if (value > totalPoint) return;

		setGrade(value);
	};

	const onCheckApplyDeduction = (value) => {
		onGrade(value);
		setIsDeduct(value);
	};

	if (isFetchingGraderDetail) {
		return (
			<Box width={'100%'} minHeight={(theme) => theme.spacing(43)}>
				<Skeleton width='100%' height={88} />
			</Box>
		);
	}

	const renderFinalGrade = () => {
		if (extraCredit) {
			return (
				<Box display={'flex'} alignItems={'center'}>
					<Typography variant='titleMedium'>
						{t('final_grade_on_total_point', {
							grade: isNumber(graderDetail.finalGrade) ? graderDetail.finalGrade : '--',
							totalPoint,
						})}
					</Typography>
				</Box>
			);
		}
		return (
			<Box className={clsx(classes.finalGrade, t)}>
				<Typography variant='titleMedium'>
					{t('final_grade_on_total_point', {
						grade: isNumber(graderDetail.finalGrade) ? graderDetail.finalGrade : '--',
						totalPoint,
					})}
				</Typography>
			</Box>
		);
	};

	return (
		gradingList.length > 0 && (
			<Box sx={{ display: 'flex', flexDirection: 'column' }} gap={'16px'}>
				{renderFinalGrade()}

				<Divider width='100%' sx={{ border: '1px solid #E9ECEF' }} />

				<Box>
					<Typography variant='titleSmall'>{t('grade')}</Typography>
					<Box className={classes.grading}>
						<GradeInput
							sx={{ width: (theme) => theme.spacing(10) }}
							value={grade}
							onChange={onChangeGrade}
							onBlur={onGrade}
							totalPoint={totalPoint}
							disabled={disabled}
						/>
						<Typography component='div' ml={1} mt={0.5} variant='titleSmall'>
							{t('out_of_total_point', { totalPoint })}
						</Typography>
						<GradingStatusSaved isSubmittingGrade={isSubmittingGrade} />
					</Box>

					{!!submissionAttempts.length && allowLateTurnIn && partialCredit && (
						<Box className={classes.isDeduct}>
							<StoryBookCheckBox
								checked={isDeduct}
								onChange={onCheckApplyDeduction}
								disabled={disabled}
								label={
									<Typography variant='bodyMediumRegular' component='div' noWrap>
										{t('apply_deduction', {
											deduct: applyDeductionValue,
										})}
									</Typography>
								}
							/>
						</Box>
					)}
				</Box>

				{/* <TblButton
  className={classes.rejectBtn}
  variant='contained'
  onClick={() => onReject()}
>
  {t('reject_this_attempt')}
</TblButton> */}

				{/* <AttemptList
  label={t('attempts')}
  attemptList={attemptList}
  attemptSelected={attemptSelected}
  onOpenAttempt={onOpenAttempt}
  defaultShow={defaultShowAttemptList}
/> */}
			</Box>
		)
	);
}
Grading.propTypes = {
	totalPoint: PropTypes.number,
};

export default React.memo(Grading);
