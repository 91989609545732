import React from 'react';
import { useTranslation } from 'react-i18next';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Box } from '@mui/material';

import { Grid, IconButton, Skeleton, Tooltip } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';

import { ScheduleLayout, ScheduleRow } from './styles';

export default function ScheduleCustomSkeleton({ type }) {
	const { t } = useTranslation(['myCourses', 'common']);
	const skeletonLabelDropDown = () => {
		if (type === ACTIVITY_CATEGORY.LESSON) {
			return (
				<Grid item xs={8} className='scheduleItem'>
					<Skeleton variant='text' height={24} width={80} />
				</Grid>
			);
		}
		return (
			<>
				<Grid item xs={4} className='scheduleItem'>
					<Skeleton variant='text' height={24} width={80} />
				</Grid>
				<Grid item xs={4} className='scheduleItem'>
					<Skeleton variant='text' height={24} width={80} />
				</Grid>
			</>
		);
	};
	const skeletonBodyDropDown = () => {
		if (type === ACTIVITY_CATEGORY.LESSON) {
			return (
				<Grid item xs={8} className='scheduleItem'>
					<Skeleton variant='rounded' height={40} width={450} />
				</Grid>
			);
		}
		return (
			<>
				<Grid item xs={4} className='scheduleItem'>
					<Skeleton variant='rounded' height={40} width={230} />
				</Grid>
				<Grid item xs={4} className='scheduleItem'>
					<Skeleton variant='rounded' height={40} width={230} />
				</Grid>
			</>
		);
	};

	return (
		<ScheduleLayout>
			<Grid container spacing={2}>
				<Grid item xs={3} className='scheduleItem'>
					<Skeleton variant='text' height={24} width={80} />
				</Grid>
				{skeletonLabelDropDown()}
			</Grid>
			{['', '']?.map((_, index) => (
				<ScheduleRow container spacing={2} key={index}>
					<Grid item xs={3} className='scheduleItem'>
						<Box display='flex'>
							<Box width={40} height={40} mr={1} sx={{ padding: '8px' }}>
								<Skeleton variant='rounded' width={24} height={24} />
							</Box>
							<Skeleton sx={{ alignSelf: 'center' }} variant='text' height={24} width={80} />
						</Box>
					</Grid>
					{skeletonBodyDropDown()}
					<Grid item xs={1} className='scheduleItem'>
						<Tooltip title={t('apply_all')} placement='top'>
							<IconButton size='medium'>
								<CopyAllIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</ScheduleRow>
			))}
		</ScheduleLayout>
	);
}
ScheduleCustomSkeleton.propTypes = {
	type: PropTypes.oneOf(Object.values(ACTIVITY_CATEGORY)),
};
