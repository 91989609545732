import React from 'react';

import emptyImage from 'assets/images/img_data.png';
import clsx from 'clsx';
import { Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	emptyContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		maxHeight: 'calc(100% - 50px)',
		height: '100%',
		'&.style1': {
			color: theme.palette.primary.main,
			marginTop: theme.spacing(160 / 8 - 2),
		},
	},
	wrapContent: {
		textAlign: 'center',
		fontWeight: theme.fontWeight.light,
		marginTop: '4vh',
		marginBottom: theme.spacing(5),
		alignItems: 'center',
		'& .title': {
			fontWeight: theme.fontWeight.semi,
			fontSize: theme.fontSize['text-3xl'],
		},
	},
	image: {
		margin: 'auto',
	},
	emptyTitle: {
		marginTop: theme.spacing(2),
		color: theme.newColors.gray[700],
	},
	emptySubTitle: {
		marginTop: theme.spacing(0.5),
		margin: 'auto',
		maxWidth: 500,
	},
	scrollBar: {
		width: '100%',
	},
}));

export default function EmptyContent(props) {
	const { title, subTitle, className, emptyImage, emptyVideo, width, height } = props;
	const { classes } = useStyles();

	const content = (
		<div className={classes.wrapContent}>
			<div className={classes.image}>
				{emptyVideo ? emptyVideo : <img alt='' src={emptyImage || ''} width={width} height={height} />}
			</div>
			{title && (
				<Typography component='div' variant='bodyMediumRegular' className={`${classes.emptyTitle} title`}>
					{title}
				</Typography>
			)}
			{subTitle && (
				<Typography component='div' variant='bodySmallRegular' className={`${classes.emptySubTitle}`}>
					{subTitle}
				</Typography>
			)}
		</div>
	);

	return <div className={clsx(classes.emptyContent, className)}>{content}</div>;
}

EmptyContent.propTypes = {
	icon: PropTypes.string,
	onClick: PropTypes.func,
	title: PropTypes.any,
	subTitle: PropTypes.any,
	className: PropTypes.string,
	emptyImage: PropTypes.any,
	emptyVideo: PropTypes.any,
	width: PropTypes.any,
	height: PropTypes.any,
};

EmptyContent.defaultProps = {
	// title: 'Empty Data',
	// subTitle: 'You can start by creating new lessons, assignments,...',
	className: '',
	emptyImage: emptyImage,
	emptyVideo: '',
	width: '150px',
};
