import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	sectionList: [],
	filtersSelected: [],
	gradingList: [],
	attemptList: [],
	total: 0,
	graded: 0,
	graderDetail: {},
	basicInfo: {},
	isOverTime: true,
	comments: [],
	isLoadingComment: false,
	activityList: [],
	attemptSelected: null,
	studentSelected: null,
	activitySelected: null,
	activitySelectedType: null,
	sectionSelected: null,
	sort: '',
	sortField: 'grade',
	inputGradeSuccess: null,
	updateStudentAttemptsSuccess: null,
	mcCalculateOverallCourseGradeSuccess: null,
	switchModeSuccess: null,
	loadingLogSubmission: false,

	studentAttempts: [],
	toggleComment: true,
	selectedFile: null,
	selectedAttempt: null,
	isUnlimited: false,
	attemptType: null,
	totalAttempt: 0,
	gradingAttemptsMethod: null,
	openStudentListDialog: false,
	logAttemptSuccess: null,
};

export default createReducers(initialState, actions, actions.GRADER_RESET_STATE);
