import React from 'react';

import Drawer from '@mui/material/Drawer';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import SidebarMenu from '../TblSidebar/SidebarMenu';

import LogoSidebar from './LogoSidebar';
import styles from './styled';

class TblLogoSidebar extends React.PureComponent {
	state = {
		onHover: false,
	};

	render() {
		const { classes, routes, openSideBar, toggleSidebar, ...rest } = this.props;
		return (
			<Drawer
				open={openSideBar}
				anchor='left'
				classes={{
					root: classes.root,
					paper: clsx(classes.paper),
				}}
				onClose={this.closeSidebar}
				{...rest}
			>
				<LogoSidebar toggleSidebar={toggleSidebar} />
				<SidebarMenu
					routes={routes}
					className={classes.list}
					closeSidebar={this.closeSidebar}
					openSideBar={openSideBar}
				/>
			</Drawer>
		);
	}
}

TblLogoSidebar.propTypes = {
	classes: PropTypes.object,
	routes: PropTypes.array,
	openSideBar: PropTypes.bool,
	toggleSidebar: PropTypes.func,
};

export default withStyles(TblLogoSidebar, styles);
