/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import concat from 'lodash/concat';
import differenceBy from 'lodash/differenceBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import trim from 'lodash/trim';
import unionBy from 'lodash/unionBy';
import uniqueId from 'lodash/uniqueId';
// import ImportContactsIcon from '@mui/icons-material/ImportContacts';

// import BallotIcon from '@mui/icons-material/Ballot';
// import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
// import PollIcon from '@mui/icons-material/Poll';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import EmptyContent from 'components/EmptyContent';
import TblActivityIcon from 'components/TblActivityIcon';
import TblButton from 'components/TblButton';
import TblCheckBox from 'components/TblCheckBox';
import TblIcon from 'components/TblIcon';
import TblInputLabel from 'components/TblInputLabel';
import TblSelect from 'components/TblSelect';

import { COURSE_ITEM_TYPE } from 'utils/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import clsx from 'clsx';
import { Button, CardAttachment, Dialog, Tooltip, Typography } from 'ella-storybook';
import { ROUTE_MY_COURSES } from 'modules/MyCourses/constantsRoute';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import myCoursesActions from '../../../modules/MyCourses/actions';
import { isTeacher } from '../../../utils/roles';
import { getIconType } from '../utils';

const useStyles = makeStyles()((theme, props) => ({
	root: {
		width: '100%',
		maxWidth: 598,
		[theme.breakpoints.down('lg')]: {
			maxHeight: 250,
		},
		[theme.breakpoints.up('lg')]: {
			maxHeight: 300,
		},
		'& .MuiListItem-button:hover': {
			background: theme.newColors.gray[300],
		},
	},
	inputRoot: {
		borderBottom: `1px solid ${theme.newColors.gray[300]}`,
		height: theme.spacing(6),
	},
	positionStart: {
		padding: theme.spacing(1, 0.5, 1, 1),
		fontSize: theme.fontSizeIcon.medium,
		color: theme.mainColors.primary1[0],
		marginRight: 0,
	},
	inputSearch: {
		fontSize: theme.fontSize['text-sm'],
		color: theme.newColors.gray[700],
		padding: '11px 0 11px 0',
	},
	rootDialog: {
		zIndex: theme.zIndex.modal,
		minWidth: 600,
	},
	rootContent: {
		overflow: 'hidden',
	},
	paper: {
		minWidth: 648,
	},
	subtitle: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	listTitle: {
		display: 'flex',
		marginTop: theme.spacing(1),
	},
	emptyContent: {
		color: theme.newColors.gray[600],
	},
	selectedNumber: {
		padding: 0,
		marginLeft: 'auto',
	},
	LinkedContents: {
		border: `1px solid ${theme.newColors.gray[300]}`,
		borderRadius: 8,
		// maxHeight: 496,
		maxHeight: 'calc(100vh - 387px)', //FIXED: comment of TL-3172 (overlap the frame)
	},
	actionLabel: {
		alignItems: ' center',
		color: props.viewOnly ? theme.mainColors.gray[6] : theme.mainColors.primary2[0],
		marginTop: '10px',
		display: 'inline-flex',
		cursor: props.viewOnly ? 'default' : 'pointer',
		marginLeft: '8px',
	},
	link: {
		textDecoration: 'none',
		color: theme.mainColors.primary1[0],

		'&:focus, &:hover, &:visited, &:link, &:active': {
			textDecoration: 'none',
		},
	},
	type: {
		paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	listItem: {
		height: 64,
		color: theme.mainColors.primary1[0],
		fontSize: theme.fontSize['text-sm'],
	},
	no_activity_note: {
		color: theme.mainColors.primary1[0],
		fontSize: theme.fontSize['text-sm'],
		paddingLeft: theme.spacing(2),
	},
	fileRow: {
		width: '100%',
		height: theme.spacing(7.5),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
		marginBottom: theme.spacing(2),
		border: `${theme.spacing(0.125)} solid ${theme.newColors.gray[200]}`,
		borderRadius: theme.spacing(1),
	},
	fileIcon: {
		width: theme.spacing(10),
		height: theme.spacing(7.5),
		color: theme.mainColors.primary1[0],
		fontSize: theme.fontSizeIcon.normal,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(0.25),
		borderRight: `${theme.spacing(0.125)} solid ${theme.newColors.gray[200]}`,
	},
	fileName: {
		maxWidth: 'calc(100% - 160px)',
		height: '100%',
		color: theme.newColors.gray[800],
		fontSize: theme.fontSize['text-sm'],
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		marginLeft: theme.spacing(2),
		'& .MuiTypography-bodySmall': {
			color: theme.newColors.gray[400],
		},
	},

	actionIcon: {
		marginLeft: 'auto',
		marginRight: theme.spacing(2),
	},
	labelIcon: {
		fontSize: theme.fontSizeIcon.normal,
	},
	removeBtn: {
		color: `${theme.newColors.gray[800]}!important`,
	},
	listActivity: {
		height: theme.spacing(40),
		maxHeight: 'calc(100vh - 450px)',
	},
	indicatorSetting: {
		marginLeft: theme.spacing(1.0),
		marginTop: theme.spacing(0.5),
		height: theme.spacing(2.5),
	},
	pointerDefault: {
		'&:hover': {
			cursor: 'default',
		},
	},
	svgShrink: {
		'div > svg': {
			flexShrink: 0,
		},
	},
}));

// const myCoursesSelector = (state) => state.AllCourses || {};
const GroupNameEnum = {
	LESSONS: 'lessons',
	ASSIGNMENTS: 'assignments',
	QUIZZES: 'quizzes',
	TESTS: 'tests',
};
const convertLinkedDataToUniqueId = (data) =>
	data.map((item) => {
		if (item?.assignmentLinkId || item?.lessonLinkId || item?.quizLinkId || item?.testLinkId) {
			return {
				...item,
				id: `${item?.assignmentLinkId || item?.lessonLinkId || item?.quizLinkId || item?.testLinkId}_${uniqueId()}`,
			};
		}
		return {
			...item,
			id: `${item?.id}_${uniqueId()}`,
		};
	});

const convertLinkedDataBackToOriginId = (data) =>
	data.map((item) => ({
		...item,
		id: parseInt(item.id.split('_')[0]),
	}));
function LinkedContents(props) {
	const { classes } = useStyles(props);

	const {
		title,
		subtitle,
		updateData,
		emptyContent,
		actionLabel,
		unit,
		initialLinkedContents,
		courseActivityInfo,
		viewOnly,
		ableViewItem,
		// getUrl,
		courseIdProp,
		viewActivityDetail,
		isRenderContextMenu,
		archiveMode,
		showContentIndicator,
		showTitle = true,
	} = props;
	const theme = useTheme();
	const authContext = useContext(AuthDataContext);
	const assignmentsContents = useSelector((state) => state.AllCourses?.assignmentsContents);
	const lessonsContents = useSelector((state) => state.AllCourses?.lessonsContents);
	const quizzesContents = useSelector((state) => state.AllCourses?.quizzesContents);
	const testsContents = useSelector((state) => state.AllCourses?.testsContents);
	const unitList = useSelector((state) => state.AllCourses?.unitList);

	const { t } = useTranslation(['myCourses', 'common', 'error']);

	const [checkedAssignment, setCheckedAssignment] = useState([]);
	const [checkedLesson, setCheckedLesson] = useState([]);
	const [checkedQuiz, setCheckedQuiz] = useState([]);
	const [checkedTest, setCheckedTest] = useState([]);

	const [linkContents, setLinkContents] = useState([]);
	const [open, setOpen] = useState(false);
	const [lessons, setLessons] = useState([]);
	const [assignments, setAssignments] = useState([]);
	// const [quizzes, setQuizzes] = useState([]);
	const [tests, setTests] = useState([]);
	const [selectedUnit, setSelectedUnit] = useState('');

	useEffect(() => {
		if (isArray(initialLinkedContents)) {
			const linkContents = convertLinkedDataToUniqueId(initialLinkedContents);
			setLinkContents(linkContents);
		} else {
			setLinkContents([]);
		}
	}, [initialLinkedContents]);

	const initialAssignmentsContent = useMemo(
		() => convertLinkedDataBackToOriginId(linkContents?.filter((item) => item?.assignmentName)),
		[linkContents],
	);
	const initialLessonsContent = useMemo(
		() => convertLinkedDataBackToOriginId(linkContents?.filter((item) => item?.lessonName)),
		[linkContents],
	);
	const initialQuizzesContent = useMemo(
		() => convertLinkedDataBackToOriginId(linkContents?.filter((item) => item?.quizName)),
		[linkContents],
	);
	const initialTestsContent = useMemo(
		() => convertLinkedDataBackToOriginId(linkContents?.filter((item) => item?.testName)), // test
		[linkContents],
	);
	const availableAssignmentsContents = useMemo(
		() =>
			differenceBy(
				assignmentsContents,
				initialAssignmentsContent,
				courseActivityInfo?.assignmentName ? [courseActivityInfo] : [],
				'id',
			),
		[assignmentsContents, initialAssignmentsContent, courseActivityInfo],
	);

	const availableLessonsContents = useMemo(
		() =>
			differenceBy(
				lessonsContents,
				initialLessonsContent,
				courseActivityInfo?.lessonName ? [courseActivityInfo] : [],
				'id',
			),
		[lessonsContents, initialLessonsContent, courseActivityInfo],
	);
	const availableQuizzesContents = useMemo(
		() =>
			differenceBy(
				quizzesContents,
				initialQuizzesContent,
				courseActivityInfo?.quizName ? [courseActivityInfo] : [],
				'id',
			),
		[initialQuizzesContent, courseActivityInfo, quizzesContents],
	);

	const availableTestsContents = useMemo(
		() => differenceBy(testsContents, initialTestsContent, courseActivityInfo?.name ? [courseActivityInfo] : [], 'id'),
		[initialTestsContent, courseActivityInfo, testsContents],
	);

	useEffect(() => {
		setLessons(availableLessonsContents);
	}, [availableLessonsContents, linkContents, open]); //add open un dependencies to reset available list after searching => close => open [bug 2603]

	useEffect(() => {
		setAssignments(availableAssignmentsContents);
	}, [availableAssignmentsContents, linkContents, open]);

	// useEffect(() => {
	//   setQuizzes(availableQuizzesContents);
	// }, [availableQuizzesContents, linkContents, open]);

	useEffect(() => {
		setTests(availableTestsContents);
	}, [availableTestsContents, linkContents, open]);

	const { organizationId } = authContext.currentUser;
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const courseId = courseIdProp || match.params.courseId;

	const getLinkedContents = useCallback(() => {
		if (courseId) {
			//to prevent call api undefine course in view activity details in my dashboard
			dispatch(
				myCoursesActions.getLinkedContents({
					orgId: organizationId,
					courseId: courseId,
					isBusy: true,
					urlParams: { unitId: selectedUnit }, //fix bug TL-2427: not filter by unit, get all course day
					// improve TL-3479: filter by unit
				}),
			);
		}
	}, [courseId, selectedUnit]);

	const getUnitsByTerm = useCallback(() => {
		if (courseId) {
			dispatch(
				myCoursesActions.mcGetUnitByCourse({
					orgId: organizationId,
					courseId: courseId,
					isBusy: true,
				}),
			);
		}
	}, [courseId, organizationId]);

	useEffect(() => {
		if (isTeacher(authContext.currentUser)) {
			getUnitsByTerm();
		}
	}, []);
	useEffect(() => {
		if (isTeacher(authContext.currentUser)) {
			getLinkedContents();
		}
	}, [getLinkedContents]);

	const toggleDialog = (value) => {
		setOpen(value);
		if (value === false) {
			setCheckedLesson([]);
			setCheckedAssignment([]);
			setCheckedQuiz([]);
			setCheckedTest([]);
		}
		unit?.id && setSelectedUnit(unit.id);
	};

	const handleChecked = (item, e, checkedItems, setCheckedItem) => {
		const currentIndex = checkedItems.findIndex((t) => t.id === item.id);
		const newChecked = [...checkedItems];
		if ((e?.target?.checked || isNull(e)) && currentIndex === -1) {
			newChecked.push(item);
		} else if ((!e?.target?.checked || isNull(e)) && currentIndex !== -1) {
			newChecked.splice(currentIndex, 1);
		} else {
			return;
		}
		setCheckedItem(newChecked);
	};

	const handleToggle = (item, e, group) => {
		if (group === GroupNameEnum.LESSONS) {
			handleChecked(item, e, checkedLesson, setCheckedLesson);
		}
		if (group === GroupNameEnum.ASSIGNMENTS) {
			handleChecked(item, e, checkedAssignment, setCheckedAssignment);
		}
		if (group === GroupNameEnum.QUIZZES) {
			handleChecked(item, e, checkedQuiz, setCheckedQuiz);
		}
		if (group === GroupNameEnum.TESTS) {
			handleChecked(item, e, checkedTest, setCheckedTest);
		}
	};

	const handleSubmit = () => {
		const lessonsData = unionBy(checkedLesson, initialLessonsContent, 'id');
		const assignmentsData = unionBy(checkedAssignment, initialAssignmentsContent, 'id');
		const quizzesData = unionBy(checkedQuiz, initialQuizzesContent, 'id');
		const testsData = unionBy(checkedTest, initialTestsContent, 'id');
		const linkContentsData = {
			lessonLinkIds: lessonsData?.map((lesson) => lesson.id),
			assignmentLinkIds: assignmentsData?.map((assignment) => assignment.id),
			quizLinkIds: quizzesData?.map((quiz) => quiz.id),
			testLinkIds: testsData?.map((test) => test.id),
		};
		const newLinkContents = convertLinkedDataToUniqueId(concat(lessonsData, assignmentsData, quizzesData, testsData));
		updateData && updateData(linkContentsData);
		setOpen(false);
		setLinkContents(newLinkContents);
		setCheckedLesson([]);
		setCheckedAssignment([]);
		setCheckedQuiz([]);
		setCheckedTest([]);
	};

	const onFilterByUnit = (e) => {
		setSelectedUnit(e.target.value);
	};

	const onSearch = (e) => {
		const value = trim(e.target.value.toLowerCase());
		if (isEmpty(value)) {
			setAssignments(availableAssignmentsContents);
			setLessons(availableLessonsContents);
			// setQuizzes(availableQuizzesContents);
			setTests(availableTestsContents);
		} else {
			const totalItems = concat(
				availableLessonsContents,
				availableAssignmentsContents,
				availableQuizzesContents,
				availableTestsContents,
			).map((item) => ({
				...item,
				name: item?.quizName || item?.lessonName || item?.assignmentName || item?.testName,
			}));
			const filteredItems = totalItems?.filter((item) => item?.name?.toLowerCase().indexOf(value) !== -1);

			setAssignments(filteredItems.filter((item) => item?.assignmentName));
			setLessons(filteredItems.filter((item) => item?.lessonName));
			// setQuizzes(filteredItems.filter((item) => item?.quizName));
			setTests(filteredItems.filter((item) => item?.testName));
		}
	};

	const renderGroup = (array, group) => {
		if (isEmpty(array)) {
			return <div className={classes.no_activity_note}>{t('no_activities')}</div>;
		}
		return array?.map((item) => (
			<ListItem key={item.id} button className={classes.listItem} onClick={() => handleToggle(item, null, group)}>
				<Grid container alignItems='center'>
					{getIcon(item)}
					<Grid item>
						<Typography noWrap style={{ maxWidth: 480 }}>
							{item?.lessonName || item?.assignmentName || item?.quizName || item?.testName}
						</Typography>
					</Grid>
				</Grid>
				<ListItemSecondaryAction>
					<TblCheckBox
						edge='end'
						onChange={(e) => handleToggle(item, e, group)}
						// checked={checked.includes(item.id)}
						checked={
							!!checkedLesson.find(
								(checkedItem) => checkedItem.id === item.id && item.lessonName, // fix bug TL 2643: to prevent 2 similar ids checked
							) ||
							!!checkedAssignment.find(
								(checkedItem) => checkedItem.id === item.id && item.assignmentName, // fix bug TL 2643: to prevent 2 similar ids checked
							) ||
							!!checkedQuiz.find(
								(checkedItem) => checkedItem.id === item.id && item.quizName, // fix bug TL 2643: to prevent 2 similar ids checked
							) ||
							!!checkedTest.find((checkedItem) => checkedItem.id === item.id && item.testName)
						}
					/>
				</ListItemSecondaryAction>
			</ListItem>
		));
	};

	const deleteFile = (item) => {
		const data = linkContents.filter((f) => f.id !== item.id);
		setLinkContents(data);
		const getId = (id) => parseInt(id.split('_')[0]);
		const linkContentsData = {
			lessonLinkIds: data?.filter((item) => item.lessonName).map((lesson) => getId(lesson.id)),
			assignmentLinkIds: data?.filter((item) => item.assignmentName).map((assignment) => getId(assignment.id)),
			quizLinkIds: data?.filter((item) => item.quizName).map((quiz) => getId(quiz.id)),
			testLinkIds: data?.filter((item) => item.testName).map((test) => getId(test.id)),
		};
		updateData && updateData(linkContentsData);
	};

	const getIcon = (item) => {
		const type = item?.lessonName
			? COURSE_ITEM_TYPE.LESSON
			: item?.testName
			? COURSE_ITEM_TYPE.TEST
			: item?.assignmentName
			? COURSE_ITEM_TYPE.ASSIGNMENT
			: COURSE_ITEM_TYPE.QUIZ;
		return <TblActivityIcon type={type} className={classes.icon} />;
	};

	const onClickItem = (e, item) => {
		if (!ableViewItem) {
			e.preventDefault();
		}
		!isRenderContextMenu && viewActivityDetail && viewActivityDetail(e, item); // teacher

		isRenderContextMenu && onRedirectLink(e, item); // student and guardian

		// if (isStudent || isGuardian) {
		//   onRedirectLink(e, item); // student and guardian

		// } else if (isTeacher) {
		//   viewActivityDetail && viewActivityDetail(e, item); // teacher
		// }
	};

	const onRedirectLink = (e, item) => {
		if (!ableViewItem) {
			e.preventDefault();
		} else {
			const activityId = item.lessonLinkId || item.testLinkId || item.assignmentLinkId;
			const path = ROUTE_MY_COURSES.MY_COURSES_DETAIL_UNIT_ACTIVITY(courseId, activityId, item.type, item.unitId);
			window.open(`${window.location.origin}${path}`);
			// history.push(path);
		}
	};

	const renderItems = () => (
		<Box display={'flex'} flexDirection={'column'} gap={2} mt={2} marginTop={0}>
			{linkContents && linkContents.length === 0 && viewOnly ? (
				<Typography variant='bodyMediumRegular' className={classes.emptyContent}>
					{emptyContent}
				</Typography>
			) : (
				<>
					{linkContents?.map((item, index) => {
						return (
							<CardAttachment
								className={clsx(classes.svgShrink, { [classes.pointerDefault]: !ableViewItem })}
								key={index}
								icon={getIconType(item)}
								title={item.lessonName || item.quizName || item.assignmentName || item.testName}
								onClick={(e) => onClickItem(e, item)}
								subTitle={
									item.lessonName
										? t('lesson')
										: item.testName // test
										? t('test')
										: item.quizName
										? t('quiz')
										: item.assignmentName
										? t('assignment')
										: null
								}
								onRemove={!viewOnly ? () => deleteFile(item) : null}
								contextMenuProps={
									isRenderContextMenu && {
										items: [
											{
												label: <Typography variant='bodyMediumRegular'>{t('view', { ns: 'common' })}</Typography>,
												onClick: (e) => onClickItem(e, item),
											},
										],
									}
								}
							/>
						);
					})}
				</>
			)}
		</Box>
	);

	const renderDialog = () => (
		<Dialog
			title={title || t('add_linked_contents')}
			open={open}
			onClose={() => {
				toggleDialog(false);
			}}
			classes={{ root: classes.rootDialog, paper: classes.paper }}
			footer={
				<>
					<Box mt={3}>
						<TblButton
							variant='outlined'
							color='primary'
							onClick={() => {
								toggleDialog(false);
								// resetForm();
							}}
						>
							{t('common:cancel')}
						</TblButton>
					</Box>
					<Box mt={3}>
						<TblButton variant='contained' color='primary' type='submit' onClick={handleSubmit}>
							{t('common:add')}
						</TblButton>
					</Box>
				</>
			}
		>
			<Box mb={2}>
				<TblSelect
					label={t('common:unit')}
					placeholder={t('common:select')}
					value={selectedUnit}
					onChange={onFilterByUnit}
				>
					<MenuItem value={''}>{t('common:all_units')}</MenuItem>
					{unitList?.map((unit) => (
						<MenuItem key={unit.id} value={unit.id}>
							{unit.unitName}
						</MenuItem>
					))}
				</TblSelect>
			</Box>

			<div className={classes.subtitle}>{subtitle}</div>
			<div className={classes.listTitle}>
				<TblInputLabel>{t('available_activities')}</TblInputLabel>
				<TblInputLabel className={classes.selectedNumber}>
					{concat(checkedQuiz, checkedAssignment, checkedLesson, checkedTest)?.length} {t('selected')}
				</TblInputLabel>
			</div>
			<Box className={classes.LinkedContents}>
				<Input
					fullWidth
					classes={{ input: classes.inputSearch, root: classes.inputRoot }}
					startAdornment={
						<InputAdornment position='start' classes={{ positionStart: classes.positionStart }}>
							<span className={'icon-icn_search'} />
						</InputAdornment>
					}
					disableUnderline
					placeholder={t('common:search')}
					onChange={onSearch}
				/>
				{concat(lessons, assignments, tests)?.length ? (
					<PerfectScrollbar className={classes.listActivity}>
						<List>
							<>
								<TblInputLabel className={classes.type}>{t('lessons')}</TblInputLabel>
								{renderGroup(lessons, GroupNameEnum.LESSONS)}
							</>
							<>
								<TblInputLabel className={classes.type}>{t('assignments')}</TblInputLabel>
								{renderGroup(assignments, GroupNameEnum.ASSIGNMENTS)}
							</>
							{/* <>
                <TblInputLabel className={classes.type}>{t('quizzes')}</TblInputLabel>
                {renderGroup(quizzes, GroupNameEnum.QUIZZES)}
              </> */}
							<>
								<TblInputLabel className={classes.type}>{t('test')}</TblInputLabel>
								{renderGroup(tests, GroupNameEnum.TESTS)}
							</>
						</List>
					</PerfectScrollbar>
				) : (
					<EmptyContent subTitle={t('common:no_data')} />
				)}
			</Box>
		</Dialog>
	);
	return (
		<Box>
			{renderDialog()}
			{!showTitle ? (
				<></>
			) : (
				<Typography
					variant='titleSmall'
					display='flex'
					color={theme.newColors.gray[800]}
					marginBottom={(theme) => theme.spacing(2)}
				>
					{t('linked_contents')}
					{showContentIndicator && (
						<Tooltip title={t('linked_contents_indicator')} placement='top'>
							<Box className={classes.indicatorSetting}>
								<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
							</Box>
						</Tooltip>
					)}
				</Typography>
			)}

			{actionLabel && !archiveMode && (
				<>
					<Box mb={2} />
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => toggleDialog(viewOnly ? false : true)}
					>
						{t('select_activity')}
					</Button>
					<Box mb={2} />
				</>
			)}
			{renderItems()}
		</Box>
	);
}

export default memo(LinkedContents);
LinkedContents.propTypes = {
	selectedItems: PropTypes.array,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	itemsList: PropTypes.array,
	updateData: PropTypes.func,
	menu: PropTypes.array,
	initialLinkedContents: PropTypes.array,
	unit: PropTypes.object,
	LinkedContentsTitle: PropTypes.string,
	actionLabel: PropTypes.string,
	courseActivityInfo: PropTypes.object,
	viewOnly: PropTypes.bool,
	emptyContent: PropTypes.string,
	type: PropTypes.string,
	getUrl: PropTypes.func,
	ableViewItem: PropTypes.bool,
	sectionId: PropTypes.number,
	courseIdProp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewActivityDetail: PropTypes.func,
	isRenderContextMenu: PropTypes.bool,
	archiveMode: PropTypes.bool,
};
LinkedContents.defaultProps = {
	subtitle: 'Choose the course activities that will be in this Assignment',
	viewOnly: false,
	title: 'Related Lessons, Assignments, Tests',
	actionLabel: 'Add Related Lessons, Assignments, Tests',
	ableViewItem: false,
	isRenderContextMenu: false,
	emptyContent: 'No Related Lessons, Assignments, Tests',
	archiveMode: false,
	showContentIndicator: true,
};
