import { useEffect } from 'react';

import { TABS } from 'modules/MyCourses/components/ActivityDetailPage/constants';

import { isFinite } from 'lodash';

const useCurrentTab = (queryParams, currentTab, setState) => {
	const { setCurrentTab, setSelectedTab, setCurrentTestType } = setState;
	const tab = queryParams.get('tab');

	useEffect(() => {
		setCurrentTab((prev) => (tab && tab !== prev ? tab : prev));
		setSelectedTab((prev) => {
			return isFinite(TABS[tab]) ? TABS[tab] : prev;
		});
	}, [queryParams]);

	useEffect(() => {
		if (TABS[currentTab] !== TABS.student) {
			setCurrentTestType(null);
			return;
		}

		const testType = queryParams.get('type');

		setCurrentTestType((prev) => (testType && testType !== prev ? testType : prev));
	}, [currentTab, queryParams]);
};

export default useCurrentTab;
