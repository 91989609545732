import React from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Avatar from '@mui/material/Avatar';

import clsx from 'clsx';
import { Typography } from 'ella-storybook';
import { PropTypes } from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	itemCard: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		'&:hover': {
			cursor: 'pointer',
		},
		height: '100%',
	},
	content: {
		width: '80%',
		color: theme.mainColors.primary1[0],
		minWidth: 0,
		paddingLeft: theme.spacing(2),
	},
	name: {
		width: '100%',
	},
	email: {
		fontSize: theme.fontSize['text-sm'],
	},
	status: {
		color: theme.newColors.gray[600],
	},

	rootAvatar: {
		width: theme.spacing(4),
		minWidth: theme.spacing(4),
		height: theme.spacing(4),
		background: theme.newColors.gray[100],
		color: theme.newColors.gray[700],
		// fontSize: theme.fontSize['text-sm'],
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '10px',
		// ...theme.typography.bodySmallBold,
	},
}));

function UserInfoCard(props) {
	const { classes } = useStyles();
	const { status } = props;
	const { name, email, lastName } = props?.itemInfo;
	const { iconCollapse } = props;

	return (
		<div className={classes.itemCard}>
			<Avatar className={classes.rootAvatar}>
				<Typography variant='bodyMediumRegular'>
					{(name?.charAt(0).toUpperCase() || '') + (lastName?.charAt(0).toUpperCase() || '')}
				</Typography>
			</Avatar>
			<div className={clsx(classes.content, 'content')}>
				<Typography component='p' variant='titleSmall' className={classes.name} noWrap={true}>
					{name}
				</Typography>
				{email && (
					<Typography component='div' variant='bodySmallRegular' noWrap={true}>
						{email}
					</Typography>
				)}
				{status && (
					<Typography variant='bodyMediumRegular' component='div' className={classes.status} noWrap={true}>
						{status}
					</Typography>
				)}
			</div>
			{iconCollapse && <ArrowRightIcon style={{ marginLeft: 'auto' }} />}
		</div>
	);
}

UserInfoCard.propTypes = {
	email: PropTypes.string,
	firstName: PropTypes.string,
	iconCollapse: PropTypes.bool,
	itemInfo: PropTypes.object,
	lastName: PropTypes.string,
	name: PropTypes.string,
	status: PropTypes.any,
};

UserInfoCard.defaultProps = {
	itemInfo: {
		name: '',
		email: 'No Students',
		firstName: 'First Name',
		lastName: 'Last Name',
	},
	iconCollapse: false,
};

export default UserInfoCard;
