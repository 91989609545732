import { useTranslation } from 'react-i18next';

import { Avatar } from '@mui/material';

import TblActivityIcon from 'components/TblActivityIcon/icon';
import TblIcon from 'components/TblIcon';
import { TblIconButton } from 'components/TblIconButton';
import TblTooltipDynamic from 'components/TblTooltipDynamic';

import clsx from 'clsx';
import { Box, Typography } from 'ella-storybook';
import { RESCHEDULE_STEP } from 'modules/MyCourses/constants';

const NavigatorTop = ({ step, imageUrl, onClickBack, iconType, name, amountActivities }) => {
	const { t } = useTranslation();

	return (
		<Box
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			paddingTop={'12px'}
			paddingBottom={'24px'}
		>
			<Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1} maxWidth={'900px'} width={'100%'}>
				<TblIconButton
					onClick={() => onClickBack && onClickBack()}
					sx={{ width: (theme) => theme.spacing(4), height: (theme) => theme.spacing(4) }}
				>
					<TblIcon icon='chevron_left' fontSize={'24px'} style={{ marginRight: '2px' }} />
				</TblIconButton>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					justifyContent={'space-between'}
					gap={'8px'}
					width={'100%'}
					maxWidth={'calc( 100% - 40px )'}
				>
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						gap={1}
						maxWidth={'calc( 100% - 82px - 8px - 8px )'}
					>
						{step === RESCHEDULE_STEP.ACTIVITY ? (
							<Avatar
								alt='course_image'
								src={imageUrl}
								sx={{ width: (theme) => theme.spacing(4), height: (theme) => theme.spacing(4) }}
							/>
						) : (
							<TblActivityIcon type={iconType} />
						)}
						<Typography
							variant='bodyMediumRegular'
							component={'div'}
							className={clsx('text-ellipsis')}
							sx={{ color: (theme) => theme.newColors.gray[800] }}
						>
							<TblTooltipDynamic title={name}>{name}</TblTooltipDynamic>
						</Typography>
					</Box>
					{step === RESCHEDULE_STEP.ACTIVITY ? (
						<Typography
							variant='bodyMediumRegular'
							component={'div'}
							className={clsx('text-ellipsis')}
							alignItems={'center'}
							sx={{ color: (theme) => theme.newColors.gray[500] }}
						>
							{t('myCourses:amount_activities', { count: amountActivities })}
						</Typography>
					) : (
						<></>
					)}
				</Box>
			</Box>
		</Box>
	);
};

NavigatorTop.defaultProps = {
	step: RESCHEDULE_STEP.ACTIVITY,
	onClickBack: () => {},
	iconType: '',
	name: '',
	imageUrl: '',
};

export default NavigatorTop;
