import get from 'lodash/get';

import { Avatar } from '@mui/material';

import TblIcon from 'components/TblIcon';

import { Box, Tooltip, Typography } from 'ella-storybook';
import { size } from 'lodash';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import theme from 'theme';

export const studentListCols = ({ translate, activityType, hideActionButton, hideGrade }) => {
	const columns = [
		{
			title: 'Student Name',
			key: 'studentName',
			dataIndex: 'studentName',
			width: '378px',
			render: (text, record) => {
				const shortenName =
					(get(record, 'firstName', '').charAt(0).toUpperCase() || '') +
					(get(record, 'lastName', '').charAt(0).toUpperCase() || '');
				return (
					<>
						<Avatar
							sx={{
								width: (theme) => theme.spacing(3),
								height: (theme) => theme.spacing(3),
								backgroundColor: (theme) => theme.newColors.gray[200],
							}}
						>
							<Typography variant='bodySmallBold' color={(theme) => theme.newColors.gray[700]}>
								{shortenName}
							</Typography>
						</Avatar>
						<Typography component={'div'} variant='bodyMediumRegular'>
							{text}
						</Typography>
					</>
				);
			},
		},
		{
			title: activityType === ACTIVITY_CATEGORY.TEST ? 'Taken on' : 'Due on',
			key: 'dueOn',
			dataIndex: 'dueOn',
			width: '378px',
			render: (text, record) => (
				<>
					<Typography component={'div'} variant='bodyMediumRegular'>
						{text}
					</Typography>
					{get(record, 'hasCustomDue') && (
						<Tooltip title={translate('myCourses:due_date_has_been_customized')} placement='top'>
							<Box display={'flex'}>
								<TblIcon icon='edit_calendar' fontSize={'16px'} color={theme.newColors.gray[500]} />
							</Box>
						</Tooltip>
					)}
				</>
			),
		},
		hideGrade
			? null
			: {
					title: 'Grade',
					key: 'grade',
					dataIndex: 'grade',
					width: '244px',
					render: (text, record) => (
						<>
							<Typography component={'div'} variant='bodyMediumRegular'>
								{text}
							</Typography>
							{get(record, 'hasOverriddenGrade') && (
								<Typography
									variant='bodySmallRegular'
									sx={{ fontStyle: 'italic', color: (theme) => theme.newColors.gray[500] }}
								>
									{translate('myCourses:overridden')}
								</Typography>
							)}
						</>
					),
			  },
		hideActionButton
			? null
			: {
					key: 'action',
					align: 'right',
					width: '40px',
					contextMenu: (record, callback) => {},
			  },
	];
	return columns.filter((el) => el);
};

export const studentListActions =
	({
		translate,
		gradeType,
		activityType,
		hasPublished,
		handleCustomModal = () => {},
		handleShowGraderModal = () => {},
	}) =>
	(selectedRow) => {
		return [
			{
				...(![ACTIVITY_CATEGORY.TEST, ACTIVITY_CATEGORY.TEST_IN_CLASS].includes(activityType) && {
					label: selectedRow?.hasCustomDue ? 'myCourses:edit_custom_due' : 'myCourses:custom_due_date',
					value: 1,
					disabled: !selectedRow?.canCustomDue,
					onClick: (record) => {
						handleCustomModal(record);
					},
					moreInfoComponent: !selectedRow?.canCustomDue && (
						<Box display={'flex'} gap={0.5}>
							<TblIcon icon='info' style={{ alignSelf: 'center' }} />
							<Typography variant='bodySmallRegular' sx={{ textWrap: 'wrap' }}>
								{translate('myCourses:can_not_customize_passed_due')}
							</Typography>
						</Box>
					),
				}),
			},
			{
				...(gradeType &&
					hasPublished && {
						label: 'myCourses:open_grader',
						onClick: (record) => {
							handleShowGraderModal(record);
						},
						value: 2,
					}),
			},
		].filter((el) => size(el));
	};

export const ASSIGNMENT_PICK = [
	'id',
	'duration',
	'description',
	'attachments',
	'linkContents',
	'googleFiles',
	'submissionMethod',
	'submissionRequired',
	'attempts',
	'extraCredit',
	'pictureInClassSubmissions',
	'deduct',
	'deductType',
	'allowLateTurnIn',
	'partialCredit',
	'completeBy',
	'sessionDueLate',
	'lowestGradePercent',
	'gradingAttemptsMethod',
	'gradeType',
	'totalPoint',
	'gradeWeightName',
	'editable',
	'courseName',
	'modifiable',
	'hasPublished',
];

export const TEST_PICK = [
	'id',
	'duration',
	'description',
	'attachments',
	'linkContents',
	'googleFiles',
	'submissionMethod',
	'submissionRequired',
	'attempts',
	'pictureInClassSubmissions',
	'gradingAttemptsMethod',
	'gradeType',
	'totalPoint',
	'gradeWeightName',
	'editable',
	'courseName',
	'studyForTest',
	'hasPublished',
	'modifiable',
];

export const STUDY_FOR_TEST_PICK = [
	'id',
	'duration',
	'description',
	'submissionMethod',
	'submissionRequired',
	'pictureInClassSubmissions',
	'gradingAttemptsMethod',
	'gradeType',
	'totalPoint',
	'gradeWeightName',
	'editable',
	'courseName',
];

export const LESSON_PICK = [
	'id',
	'duration',
	'description',
	'attachments',
	'linkContents',
	'googleFiles',
	'submissionMethod',
	'submissionRequired',
	'attempts',
	'extraCredit',
	'pictureInClassSubmissions',
	'deduct',
	'deductType',
	'allowLateTurnIn',
	'partialCredit',
	'completeBy',
	'sessionDueLate',
	'lowestGradePercent',
	'gradingAttemptsMethod',
	'gradeType',
	'totalPoint',
	'gradeWeightName',
	'editable',
	'courseName',
	'modifiable',
];

export const PARTICIPATION_PICK = [
	'id',
	'duration',
	'description',
	'attachments',
	'linkContents',
	'googleFiles',
	'submissionMethod',
	'submissionRequired',
	'attempts',
	'extraCredit',
	'pictureInClassSubmissions',
	'deduct',
	'deductType',
	'allowLateTurnIn',
	'partialCredit',
	'completeBy',
	'sessionDueLate',
	'lowestGradePercent',
	'gradingAttemptsMethod',
	'gradeType',
	'totalPoint',
	'gradeWeightName',
	'editable',
	'courseName',
	'modifiable',
	'hasPublished',
];
