import React, { Suspense, lazy, memo } from 'react';

import BlockSubmissionStatus from 'modules/Grader/components/RightContent/BlockSubmissionStatus.js';

import { ACTIVITY_CATEGORY, STUDENT_PROGRESS_STATUS } from 'modules/MyCourses/constants.js';
import PropTypes from 'prop-types';

const StudentAttemptList = lazy(() => import('./StudentAttemptList.js'));
const StudentSubmissions = lazy(() => import('./StudentSubmissions.js'));

function StudentAttempts(Submission, AttemptsSubmission) {
	const StudentSubmissionMemo = memo(Submission);
	const StudentAttemptsSubmission = memo(AttemptsSubmission);

	return function Render(props) {
		const { unlimitedAttempt, amountAttempts, studentId, currentTermId, submissionStatus, activityType, ...rest } =
			props;

		if (
			activityType !== ACTIVITY_CATEGORY.TEST_IN_CLASS &&
			submissionStatus !== STUDENT_PROGRESS_STATUS.TURN_IN &&
			submissionStatus !== STUDENT_PROGRESS_STATUS.LATE_TURN_IN
		) {
			// return <MissedSubmission />;
			return <BlockSubmissionStatus submissionStatus={submissionStatus} />;
		}

		if (unlimitedAttempt) {
			return (
				<Suspense fallback={<div />}>
					<StudentSubmissionMemo {...rest} />
				</Suspense>
			);
		}

		return (
			<Suspense fallback={<div />}>
				<StudentAttemptsSubmission
					studentId={studentId}
					currentTermId={currentTermId}
					amountAttempts={amountAttempts}
					activityType={activityType}
					{...rest}
				/>
			</Suspense>
		);
	};
}

StudentAttempts.propTypes = {
	unlimitedAttempt: PropTypes.bool,
	amountAttempts: PropTypes.number,
	studentId: PropTypes.number,
	currentTermId: PropTypes.number,
};

export default StudentAttempts(StudentSubmissions, StudentAttemptList);
