export const actions = {
	UPDATE_MY_PROFILE: 'UPDATE_MY_PROFILE',
	UPDATE_MY_PROFILE_SUCCESS: 'UPDATE_MY_PROFILE_SUCCESS',
	UPDATE_MY_PROFILE_FAILED: 'UPDATE_MY_PROFILE_FAILED',

	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',

	UPDATE_BEDTIME_PREFERENCE: 'UPDATE_BEDTIME_PREFERENCE',
	UPDATE_BEDTIME_PREFERENCE_SUCCESS: 'UPDATE_BEDTIME_PREFERENCE_SUCCESS',
	UPDATE_BEDTIME_PREFERENCE_FAILED: 'UPDATE_BEDTIME_PREFERENCE_FAILED',

	SEND_OTP: 'SEND_OTP',
	SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
	SEND_OTP_FAILED: 'SEND_OTP_FAILED',

	RESEND_OTP: 'RESEND_OTP',
	RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
	RESEND_OTP_FAILED: 'RESEND_OTP_FAILED',

	VERIFY_OTP: 'VERIFY_OTP',
	VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
	VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',

	RESET_MY_PROFILE_REDUCER: 'RESET_MY_PROFILE_REDUCER',
	MY_PROFILE_SET_STATE: 'MY_PROFILE_SET_STATE',
};

export const END_POINT = {
	update_my_profile: {
		method: 'PUT',
		url: `${process.env.REACT_APP_API_URL}/organization/profile`,
	},
	change_password: {
		method: 'PUT',
		url: `${process.env.REACT_APP_API_URL}/organization/change-password`,
	},
	verify_otp: {
		method: 'POST',
		url: (orgId) => `${process.env.REACT_APP_API_URL_V2}/organization/${orgId}/account/otp/verify`,
	},
	send_otp: {
		method: 'POST',
		url: (orgId) => `${process.env.REACT_APP_API_URL_V2}/organization/${orgId}/account/otp/get`,
	},
	resend_otp: {
		method: 'POST',
		url: (orgId) => `${process.env.REACT_APP_API_URL_V2}/organization/${orgId}/account/otp/resend`,
	},
};

export const COUNTDOWN_RESEND = 60;

export const OTP_CODE = {
	REACH_LIMIT_RESEND: 1,
	LIMIT_BETWEEN_EACH_RESEND: 2,
	PHONE_NOT_RELATED: 3,
	OTP_NOT_VALID: 4,
	METHOD_NOT_FOUND: 5,
	EXPIRED: 6,
	PHONE_EXIST: 7,

	UNVERIFIED: 19,
	VERIFIED: 20,
	SENT: 21,
};
