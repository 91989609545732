import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Grid } from '@mui/material';

import { DATE_TYPE } from 'shared/MyCourses/constants';
import { processDataSessions } from 'shared/MyCourses/utils';

import { Checkbox, Dropdown, EmptyContent, IconButton, Tooltip } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';

import { ScheduleLabel, ScheduleLayout, ScheduleRow } from './styles';

function LessonPopQuizSectionSchedule({
	sessions,
	sections,
	type,
	errors,
	touched,
	handleChange,
	values,
	setValues,
	handleBlur,
	isSubmitting,
	setFieldValue,
	setFieldTouched,
	showOldSessions,
	filterOldSessions,
}) {
	const { t } = useTranslation(['myCourses', 'common']);
	const listLabel = {
		[ACTIVITY_CATEGORY.LESSON]: [
			{ label: t('section', { ns: 'myCourses' }), size: 3 },
			{ label: t('delivered_on', { ns: 'myCourses' }), size: 8 },
		],
		[ACTIVITY_CATEGORY.POP_QUIZ]: [
			{ label: t('section', { ns: 'myCourses' }), size: 3 },
			{ label: t('taken_on', { ns: 'myCourses' }), size: 8 },
		],
	};

	const [errorForLabel, setErrorForLabel] = useState(false);

	useEffect(() => {
		if (isSubmitting && errors?.sections?.length && touched?.sections?.length) {
			const checkAssign = errors.sections.some(
				(error, index) =>
					error?.assignDate === t('required_message', { ns: 'common' }) && values?.sections?.[index]?.select === true,
			);
			setErrorForLabel(checkAssign);
		} else {
			setErrorForLabel(false);
		}
	}, [errors?.sections, touched?.sections, values?.sections]);

	const applyAll = (selectedSection) => {
		const { sections } = values;

		if (sections.length) {
			const { assignDate } = sections[selectedSection];
			if (!assignDate) return;
			const { sectionSchedules: relateSectionsAssignDate } = sessions.find(({ id }) => Number(id) === assignDate.value);

			const newSections = sections.map((section) => {
				const isSameSession = relateSectionsAssignDate.findIndex(({ sectionId }) => sectionId === section.id) !== -1;
				if (isSameSession && section.select ) {
					const appliedAssignDate = relateSectionsAssignDate.find(({ sectionId }) => sectionId === section.id);
					section.assignDate = {
						...section.assignDate,
						value: Number(assignDate.value),
						label: `${appliedAssignDate.sessionName} (${appliedAssignDate.dateTimeLabel} - ${appliedAssignDate.startTimeLabel})`,
						start: appliedAssignDate.start,
						end: appliedAssignDate.end,
					};
				}
				return section;
			});

			setValues({ ...values, sections: newSections });
		}
	};

	return (
		<ScheduleLayout>
			<Grid container spacing={2}>
				{listLabel[type].map(({ label, size }, index) => (
					<Grid key={index} item xs={size} className='scheduleItem'>
						<ScheduleLabel
							sx={{ color: (theme) => index !== 0 && errorForLabel && theme.newColors.red[600] }}
							variant='labelMedium'
						>
							{label}
						</ScheduleLabel>
					</Grid>
				))}
			</Grid>
			{sections ? (
				sections.map((section, index) => {
					const sectionsErrors = (errors?.sections?.length && errors.sections[index]) || {};
					const sectionsTouched = (touched?.sections?.length && touched.sections[index]) || {};
					const sessionsOption = processDataSessions(
						section.id,
						DATE_TYPE.ASSIGN,
						sessions,
						true,
						ACTIVITY_CATEGORY.LESSON,
					);
					return (
						<ScheduleRow key={index} container spacing={2}>
							<Grid item xs={3} className='scheduleItem lesson-quiz'>
								<Checkbox
									label={section.sectionName}
									labelPlacement='end'
									name={`sections.${index}.select`}
									checked={section?.select}
									onChange={(value) => {
										handleChange(`sections.${index}.select`, value, setFieldValue, values);
										handleBlur(`sections.${index}.assignDate`, setFieldTouched);
									}}
								/>
							</Grid>
							<Grid item xs={8} className='scheduleItem'>
								<Dropdown
									width={'100%'}
									name={`sections.${index}.assignDate`}
									// fitContent={true}
									groupBy={(option) => showOldSessions && option.category}
									placement={'bottom-start'}
									disableCloseOnSelect={false}
									placeholder={t('please_select', { ns: 'common' })}
									options={filterOldSessions(sessionsOption, values.showOldSessions)}
									helperText={
										!!(sectionsTouched?.assignDate && sectionsErrors?.assignDate) ? sectionsErrors?.assignDate : null
									}
									error={!!(sectionsTouched?.assignDate && sectionsErrors?.assignDate)}
									onClose={() => {
										if (section.select) {
											handleBlur(`sections.${index}.assignDate`, setFieldTouched);
										}
									}}
									onChange={(value) => {
										handleChange(`sections.${index}.assignDate`, value, setFieldValue, values);
									}}
									value={section?.assignDate}
									disabled={!sections[index].select }
								/>
							</Grid>
							<Grid item className='scheduleItem'>
								<Tooltip title={t('apply_all', { ns: 'myCourses' })} placement='top'>
									<IconButton
										size='medium'
										onClick={() => {
											applyAll(index);
										}}
									>
										<CopyAllIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</ScheduleRow>
					);
				})
			) : (
				<EmptyContent />
			)}
		</ScheduleLayout>
	);
}

LessonPopQuizSectionSchedule.propTypes = {
	activity: PropTypes.object,
	type: PropTypes.oneOf([ACTIVITY_CATEGORY.LESSON, ACTIVITY_CATEGORY.POP_QUIZ]),
};

export default LessonPopQuizSectionSchedule;
