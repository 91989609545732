import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Box, Typography } from 'ella-storybook';

const Header = styled(Box)(({ theme }) => ({
	display: 'grid',
	gap: theme.spacing(1),
	margin: theme.spacing(2.5, 0, 3, 0),
}));
const HeaderTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: theme.spacing(2),
	backgroundColor: theme.newColors.gray[50],
	borderRadius: theme.spacing(1),
}));
const Content = styled(Box)(({ theme }) => ({
	display: 'grid',
	gap: theme.spacing(3),
}));
const ScheduleLabel = styled(Typography)(({ theme }) => ({
	color: theme.newColors.gray[600],
}));
const ScheduleLayout = styled(Box)(() => ({}));
const ScheduleRow = styled(Grid)(({ theme }) => ({
	'& .scheduleItem': {
		marginTop: theme.spacing(2),
	},
}));

export { Header, HeaderTitle, Content, ScheduleLabel, ScheduleLayout, ScheduleRow };
