/* eslint-disable max-len */
import { makeAjaxRequest } from 'utils/ajax';

import { PUBLISH_NOW } from 'shared/MyCourses/constants';

import { get } from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import myCoursesActions from '../actions';
import { ACTIVITY_CATEGORY, ACTIVITY_CATEGORY_NAME, END_POINT, actions } from '../constants';

const getUnitsGroupByGradingPeriodEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_UNITS_GROUP_BY_GRADING_PERIOD),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_units_groupby_grading_period.method,
				END_POINT.get_units_groupby_grading_period.url(
					action.payload.id,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(({ response }) => {
					const listUnitGroup = (response.data || []).map((item) => {
						let firstDayFormat = moment(item.firstDay).format('MMM DD, YYYY');
						let lastDayFormat = moment(item.lastDay).format('MMM DD, YYYY');
						const rangeDate = `${firstDayFormat} - ${lastDayFormat}`;
						const listUnit = (item.units || []).map((unit) => {
							let newUnit = {
								id: unit.unitId,
								startElement: { title: unit.unitName },
							};
							return newUnit;
						});
						return { ...item, units: listUnit, rangeDate };
					});
					return of(
						myCoursesActions.getUnitsGroupByGradingPeriodSuccess({
							listUnitGroup,
							isGettingUnitsGroupByGradingPeriod: false,
						}),
					);
				}),
				catchError(() =>
					of(myCoursesActions.getUnitsGroupByGradingPeriodFailed({ isGettingUnitsGroupByGradingPeriod: false })),
				),
			),
		),
	);

const getActivitiesByUnitIdEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ACTIVITIES_BY_UNIT_ID),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_activities_by_unit_id.method,
				END_POINT.get_activities_by_unit_id.url(action.payload.id, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap(({ response }) =>
					of(
						myCoursesActions.getActivitiesByUnitIdSuccess({
							allActivitiesByUnitId: response.data,
							isGettingAllActivitiesByUnitId: false,
						}),
					),
				),
				catchError(() => of(myCoursesActions.getActivitiesByUnitIdFailed({ isGettingAllActivitiesByUnitId: false }))),
			),
		),
	);

const getCoursesNeedRescheduleEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COURSES_NEED_RESCHEDULE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_courses_need_reschedule.method,
				END_POINT.get_courses_need_reschedule.url(action.payload.id, action.payload.schoolYearId),
			).pipe(
				mergeMap(({ response }) =>
					of(
						myCoursesActions.getCoursesNeedRescheduleSuccess({
							coursesNeedReschedule: response?.courseList,
							isGettingCoursesNeedReschedule: false,
						}),
					),
				),
				catchError(() =>
					of(myCoursesActions.getCoursesNeedRescheduleFailed({ isGettingCoursesNeedReschedule: false })),
				),
			),
		),
	);

const getActivitiesRescheduleCourseEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ACTIVITIES_RESCHEDULE_COURSE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_activities_reschedule_course.method,
				END_POINT.get_activities_reschedule_course.url(
					action.payload.id,
					action.payload.schoolYearId,
					action.payload.courseId,
				),
			).pipe(
				mergeMap(({ response }) => {
					const flattenActivities = (response?.data?.activities || []).flatMap((item) =>
						(item.activities || []).map((activity) => ({ activityType: item?.type, ...activity })),
					);

					const arrUpdatedActivities = flattenActivities
						.filter((activity) => activity.hasUpdated && activity.activityType && activity.masterActivityId)
						.map((activity) => `${ACTIVITY_CATEGORY_NAME[activity.activityType]}-${activity.masterActivityId}`);

					return of(
						myCoursesActions.getActivitiesRescheduleCourseSuccess({
							activitiesReschedule: response?.data?.activities,
							flattenActivities: flattenActivities,
							totalActivitiesReschedule: response?.data?.total,
							arrUpdatedActivities,
						}),
					);
				}),
				catchError(() => of(myCoursesActions.getActivitiesRescheduleCourseFailed({}))),
			),
		),
	);

const getSectionsRescheduleActivityEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SECTIONS_RESCHEDULE_ACTIVITY),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_sections_reschedule_activity.method,
				END_POINT.get_sections_reschedule_activity.url(
					action.payload.id,
					action.payload.courseId,
					action.payload.activityId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(({ response }) => {
					const modifiedData = (response?.data || []).flatMap((item, index) => {
						const isPublishNow = Number(get(item, 'assignDateId') ?? get(item, 'announceDateId')) === PUBLISH_NOW;

						const newAssignCourseDayName = get(item, 'new.assign.courseDayName');
						const newAssignStartDateTime = get(item, 'new.assign.startDateFormatted');
						const newAssignEndDateTime = get(item, 'new.assign.endDateFormatted');
						const newDueCourseDayName = get(item, 'new.due.courseDayName');
						const newDueEndDateTime = get(item, 'new.due.endDateFormatted');

						const oldAssignCourseDayName = get(item, 'old.assign.courseDayName');
						const oldAssignStartDateTime = get(item, 'old.assign.startDateFormatted');
						const oldDueCourseDayName = get(item, 'old.due.courseDayName');
						const oldDueEndDateTime = get(item, 'old.due.endDateFormatted');

						const oldCourseDayIdAssign = get(item, 'old.assign.courseDayId');
						const oldCourseDayIdDue = get(item, 'old.due.courseDayId');

						const newCourseDayIdAssign = get(item, 'new.assign.courseDayId');
						const newCourseDayIdDue = get(item, 'new.due.courseDayId');

						let oldSessionNameAssign;
						let oldSessionNameDue;
						let newSessionNameAssign;
						let newSessionNameDue;

						const activityType = action.payload.activityType;

						if (activityType === ACTIVITY_CATEGORY.LESSON) {
							oldSessionNameAssign = `${oldAssignCourseDayName} ( ${oldAssignStartDateTime} )`;
							oldSessionNameDue = '';
							newSessionNameAssign = `${newAssignCourseDayName} ( ${newAssignEndDateTime} )`;
							newSessionNameDue = '';
						}
						if (activityType === ACTIVITY_CATEGORY.ASSIGNMENT) {
							oldSessionNameAssign = `${oldAssignCourseDayName} ( ${oldAssignStartDateTime} )`;
							oldSessionNameDue = `${oldDueCourseDayName} ( ${oldDueEndDateTime} )`;
							newSessionNameAssign = `${newAssignCourseDayName} ( ${newAssignStartDateTime} )`;
							newSessionNameDue = `${newDueCourseDayName} ( ${newDueEndDateTime} )`;
						}
						if (activityType === ACTIVITY_CATEGORY.TEST_IN_CLASS) {
							oldSessionNameAssign = `${oldAssignCourseDayName} ( ${oldAssignStartDateTime} )`;
							oldSessionNameDue = `${oldDueCourseDayName} ( ${oldDueEndDateTime} )`;
							newSessionNameAssign = `${newAssignCourseDayName} ( ${newAssignStartDateTime} )`;
							newSessionNameDue = `${newDueCourseDayName} ( ${newDueEndDateTime} )`;
						}

						const newAssignStartDate = get(item, 'new.assign.startDate', '');
						const newAssignEndDate = get(item, 'new.assign.endDate', '');

						if (activityType === ACTIVITY_CATEGORY.LESSON && moment(newAssignStartDate).isBefore(moment())) {
							return [];
						}

						return {
							sectionId: item.sectionId,
							sectionName: item.sectionName,
							isPublish: newAssignStartDate ? moment(newAssignStartDate).isBefore(moment()) : false,
							old: {
								assign:
									oldCourseDayIdAssign && oldSessionNameAssign
										? {
												value: oldCourseDayIdAssign,
												label: isPublishNow ? get(item, 'old.assign.startDateFormatted') : oldSessionNameAssign,
										  }
										: null,
								due:
									oldCourseDayIdDue && oldSessionNameDue
										? { value: oldCourseDayIdDue, label: oldSessionNameDue }
										: null,
							},
							new: {
								assign:
									newCourseDayIdAssign && newSessionNameAssign
										? {
												value: isPublishNow ? PUBLISH_NOW : newCourseDayIdAssign,
												label: isPublishNow ? get(item, 'new.assign.startDateFormatted') : newSessionNameAssign,
												publishAt: isPublishNow ? newAssignStartDate : null,
												index,
										  }
										: null,
								due:
									newCourseDayIdDue && newSessionNameDue
										? { value: newCourseDayIdDue, label: newSessionNameDue, dateMoment: moment(newAssignEndDate) }
										: null,
							},
						};
					});
					return of(
						myCoursesActions.getSectionsRescheduleActivitySuccess({
							sectionsActivityReschedule: modifiedData,
							sectionKeys: modifiedData.map((data) => data.sectionId),
						}),
					);
				}),
				catchError(() => of(myCoursesActions.getSectionsRescheduleActivityFailed({}))),
			),
		),
	);

const getSessionsRescheduleActivityEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SESSIONS_RESCHEDULE_ACTIVITY),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_sessions_reschedule_activity.method,
				END_POINT.get_sessions_reschedule_activity.url(
					action.payload.id,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(({ response }) => {
					const modifiedData = (response?.data || []).map((item) => {
						const modifiedSessions = (item.sessions || []).flatMap((session) => {
							const { courseDayId, courseDayName, startDateFormatted, endDateFormatted, startDate, endDate } = session;

							const sessionNameStart = `${courseDayName} ( ${startDateFormatted} )`;
							const sessionNameEnd = `${courseDayName} ( ${endDateFormatted} )`;
							return [
								{ value: courseDayId, label: sessionNameStart, type: 'start', startDate, endDate },
								{ value: courseDayId, label: sessionNameEnd, type: 'end', startDate, endDate },
							];
						});
						return { sectionId: item.sectionId, sessions: modifiedSessions };
					});
					return of(
						myCoursesActions.getSessionsRescheduleActivitySuccess({
							sessionsActivityReschedule: modifiedData,
						}),
					);
				}),
				catchError(() => of(myCoursesActions.getSessionsRescheduleActivityFailed({}))),
			),
		),
	);

export default [
	getUnitsGroupByGradingPeriodEpic,
	getActivitiesByUnitIdEpic,
	getCoursesNeedRescheduleEpic,
	getActivitiesRescheduleCourseEpic,
	getSectionsRescheduleActivityEpic,
	getSessionsRescheduleActivityEpic,
];
