import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AuthDataContext } from 'AppRoute/AuthProvider';

export const SchoolYearDataContext = createContext(null);

function SchoolYearProvider(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	const authContext = useContext(AuthDataContext);
	const { currentUser } = authContext;

	const searchParams = new URLSearchParams(location.search);
	const schoolYearIdParam = searchParams.get('schoolYearId');
	//   const courseIdParam = searchParams.get('my-courses');
	const courseId = useSelector((state) => state.MyCourses?.courseId || state.AllCourses?.courseId);
	const taskId = useSelector((state) => state.MyTasks?.detailTask?.id);

	const schoolYearSelected = useSelector((state) => state.Auth.schoolYearSelected);
	const currentSchoolYearStatus = useSelector((state) => state.Auth.currentSchoolYearStatus);
	const { id: schoolYearManagerId } = useSelector((state) => state.SchoolYear?.schoolYearDetail);

	const [schoolYearData, setSchoolYearData] = useState({
		status: null,
	});

	useEffect(() => {
		if (schoolYearSelected || courseId || schoolYearIdParam || taskId || schoolYearManagerId) {
			const urlParams = {
				...((schoolYearSelected || schoolYearIdParam || schoolYearManagerId) && {
					schoolYearId: schoolYearSelected || schoolYearIdParam || schoolYearManagerId,
				}),
				...(courseId && { courseId: courseId }),
				...(taskId && { taskId: taskId }),
			};
			// dispatch(
			// 	authActions.getCurrentSchoolYearStatus({
			// 		orgId: currentUser?.organizationId,
			// 		urlParams,
			// 		isFetchingSchoolYearStatus: true,
			// 	}),
			// );
		}
	}, [courseId, schoolYearSelected, schoolYearIdParam, taskId, schoolYearManagerId]);

	useEffect(() => {
		if (currentSchoolYearStatus) {
			setSchoolYearData({
				status: currentSchoolYearStatus,
			});
		}
	}, [currentSchoolYearStatus]);

	return <SchoolYearDataContext.Provider value={{ ...schoolYearData }} {...props} />;
}

export const useSchoolYearDataContext = () => useContext(SchoolYearDataContext);

export default SchoolYearProvider;
