import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { USER_MANAGER } from 'utils/roles';

import { MODULE_ALIAS, PERMISSION_BASIC_KEYS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/icn_people.svg';

import { ROUTE_USERS } from './constantsRoute';
import userReducer from './reducers';

const Users = loadable(() => import(/* webpackChunkName: "LazyUsers" */ './containers'));

export default [
	{
		path: ROUTE_USERS.DEFAULT,
		component: withReducer('Users', userReducer)(Users),
		private: true,
		exact: true,
		roles: [USER_MANAGER],
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.USER_MANAGEMENT,
		menu: {
			title: 'Users',
			group: 'School Administration',
			icon: IcnMenu,
			order: 1,
			groupOrder: 2,
		},
	},
];
