import React, { useEffect, useRef, useState } from 'react';
import './notification.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider } from '@mui/material';
import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';

import { isGuardian, isStudent } from 'utils/roles';

import { useAuthDataContext } from 'AppRoute/AuthProvider';
import NotificationBell from 'assets/images/notification_bell.svg';
import clsx from 'clsx';
import { Box, Button, IconButton, Popover, Typography } from 'ella-storybook';
import { createBrowserHistory as createHistory } from 'history';
import { ROUTE_MY_COURSES } from 'modules/MyCourses/constantsRoute';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import moment from 'moment';
import { setCurrentStudentId } from 'utils';

import notificationActions from '../../modules/Notifications/actions';
import { NOTIFICATION_STATUS } from '../../modules/Notifications/constants';
import TblCustomScrollbar from '../TblCustomScrollbar';

import { NOTI_TYPES } from './constants';
import useStyles from './styled';
import { generateTimeString, transformText } from './utils';

const initialState = {
	notifications: [],
	firebaseMessage: [],
	isGettingNotifications: false,
	cursor: null,
	limit: 10,
};

const ElNotificationBell = () => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('notification');
	const authContext = useAuthDataContext();
	const { currentUser } = authContext;
	const history = createHistory({ forceRefresh: true });
	const { id: studentId } = currentUser;

	const [open, setOpen] = useState(false);
	const [anchorRef, setAnchorRef] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [numberOfNewNoti, setNumberOfNewNoti] = useState(0);
	const [nextCursor, setNextCursor] = useState(initialState.cursor);

	const { notifications, lastNotification, totalNew, firebaseMessage, timeClick, cursor, isThatAll } = useSelector(
		(state) => state.Notification,
	);
	const bottomBoundaryRef = useRef();
	let isLoadingRef = useRef(isLoading);

	// ------------ Hook ------------------
	useEffect(() => {
		dispatch(
			notificationActions.getNotifications({
				studentId,
				perPage: initialState.limit,
				isGettingNotifications: true,
			}),
		);

		return () => {
			dispatch(notificationActions.notificationSetState(initialState));
		};
	}, []);

	useEffect(() => {
		dispatch(
			notificationActions.getNotifications({
				studentId,
				perPage: initialState.limit,
				isGettingNotifications: true,
			}),
		);
	}, [firebaseMessage]);

	useEffect(() => {
		if (notifications?.length) {
			// const latestNotification = notifications[0];

			setIsLoading(false);

			// const _timeClick = (timeClick && moment(timeClick)) || null;

			setNumberOfNewNoti(totalNew);

			// if (_timeClick) {

			//   const { scheduleTime, sentAt } = latestNotification;

			//   const clickBeforeNew = moment(scheduleTime || sentAt).isBefore(_timeClick);

			//   const newAfterClick = ({ sentAt, scheduleTime }) => moment(scheduleTime || sentAt).isAfter(_timeClick);

			//   if (clickBeforeNew) {
			//     setNumberOfNewNoti(0);
			//   } else {
			//     const countTotalNewAfterFirstClick = notifications.filter(newAfterClick).length;
			//     setNumberOfNewNoti(countTotalNewAfterFirstClick);
			//   }
			// } else {
			//   setNumberOfNewNoti(totalNew);
			// }
		}
	}, [notifications]);

	// ------------------------------------

	// ------------ Event -----------------
	const onClose = (event, item) => {
		setOpen(false);
		setAnchorRef(null);
		dispatch(
			notificationActions.markFirstRead({
				studentId,
				timeClick: moment().toISOString(),
			}),
		);
	};

	const onToggle = (e) => {
		e.preventDefault();
		setAnchorRef(e.target);
		setOpen(!open);
		setNumberOfNewNoti(0);
		dispatch(
			notificationActions.markFirstRead({
				studentId,
				timeClick: moment().toISOString(),
			}),
		);
	};

	const onScroll = () => {
		const observer = new IntersectionObserver(
			(entries) => {
				const [entry] = entries;
				if (entry?.intersectionRatio > 0 && !isLoading && !isLoadingRef.current) {
					loadMore();
				} else {
					observer.unobserve(bottomBoundaryRef.current);
					isLoadingRef.current = isLoading;
				}
			},
			{ threshold: 1 },
		);

		if (bottomBoundaryRef.current) {
			observer.observe(bottomBoundaryRef.current);
		}
	};

	const onClickMarkAllRead = (e, item) => {
		if (studentId) {
			dispatch(
				notificationActions.markAllRead({
					studentId,
				}),
			);
			setNumberOfNewNoti(0);
		}
	};

	const onClickMarkRead = (item) => {
		if (item) {
			const notificationId = item.id;

			if (studentId) {
				dispatch(notificationActions.markRead({ notificationId }));
			}
		}
	};

	const loadMore = () => {
		setIsLoading(true);

		if (cursor === nextCursor) {
			const lastNotiId = lastNotification?.id || null;
			const nextCursorTemp = lastNotiId;

			setNextCursor(nextCursorTemp);

			dispatch(
				notificationActions.getNotifications({
					studentId,
					cursor: lastNotiId,
					perPage: initialState.limit,
					isGettingNotifications: true,
					loadMore: true,
				}),
			);
		}
	};

	const onClickContentNotification = (notificationContent) => {
		const { studentId, notificationType, taskIds } = notificationContent;
		authContext.setData({ currentStudentId: studentId }, 'user');
		setCurrentStudentId(studentId);

		const route = isGuardian(currentUser)
			? ROUTE_MY_COURSES.MY_TASKS_GUARDIAN(studentId)
			: isStudent(currentUser) && notificationType === NOTI_TYPES.RESCHEDULE_ACTIVITY
			? ROUTE_TASKS.TASK_DETAIL(taskIds[0])
			: isStudent(currentUser) && notificationType === NOTI_TYPES.REPUBLISHED_SCHOOL_YEAR
			? `${ROUTE_MY_COURSES.MY_COURSES_DETAIL(
					notificationContent?.metaData?.courseId,
			  )}?active=information&info-active=sections_meeting_times`
			: ROUTE_TASKS.DEFAULT;

		history.push(route);
	};
	// ------------------------------------

	return (
		<Box style={{ padding: '4px' }}>
			<IconButton className={classes.iconButton} size='large' onClick={onToggle}>
				<Badge className={classes.badge} badgeContent={numberOfNewNoti} color='primary'>
					<img src={NotificationBell} alt='ella_logo' style={{ width: '20px', height: '20px' }} />
				</Badge>
			</IconButton>

			<Popover
				open={open}
				onClose={onClose}
				anchorEl={anchorRef}
				keepMounted
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				classes={{
					paper: classes.popover,
				}}
			>
				<Box className={classes.header}>
					<Typography variant='titleMedium' style={{ marginRight: '10px' }}>
						{t('notification')}
					</Typography>
					<Box
						className={classes.markAllReadBtn}
						onClick={onClickMarkAllRead}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<Typography variant='bodySmallTemporary'>{t('mark_all_as_read')}</Typography>
						<div>
							<CheckCircleOutlineIcon />
						</div>
					</Box>
				</Box>
				<TblCustomScrollbar maxHeightScroll={'40vh'} onScroll={onScroll}>
					<Box className={classes.content}>
						{((notifications?.length && notifications) || []).map((item, index) => (
							<Box key={`${item.id}-${item.index}`}>
								<Box
									key={`${item.id}-${item.index}`}
									className={clsx(classes.contentItem)}
									onClick={() => {
										onClickMarkRead(item);
										onClickContentNotification(item);
									}}
								>
									{item?.status === NOTIFICATION_STATUS.NEW && <div className={classes.dotNewNotification} />}
									<Box className={classes.contentItemIcon}>
										<InfoOutlinedIcon />
									</Box>
									<Box className={classes.contentItemBody}>
										<Typography variant='titleSmall'>{item.title}</Typography>
										<Typography
											component={'div'}
											variant='bodySmallRegular'
											dangerouslySetInnerHTML={{ __html: transformText(item.messages) }}
										/>
										{item.notificationType !== NOTI_TYPES.DELETE_ACTIVITY && (
											<Button
												key={`notification-button-${index}`}
												id={`notification-button-${index}`}
												className={classes.markReadBtn}
												size='medium'
												variant='filled'
												sx={{ marginRight: (theme) => theme.spacing(2) }}
												color='primary'
												onClick={() => {
													onClickMarkRead(item);
													onClickContentNotification(item);
												}}
											>
												{item.notificationType === NOTI_TYPES.RESCHEDULE_ACTIVITY
													? t('view_activity')
													: item.notificationType === NOTI_TYPES.REPUBLISHED_SCHOOL_YEAR
													? t('view_new_meeting_times')
													: t('view_task_list')}
											</Button>
										)}
										<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
											{generateTimeString(item.scheduleTime || item.sentAt)}
										</Typography>
									</Box>
								</Box>
								<Divider className={classes.divider} light />
							</Box>
						))}
						<div id='page-bottom-boundary' style={{ height: '10px' }} ref={bottomBoundaryRef} />
						{isLoading ? (
							<>
								<Box className={`${classes.contentItem} ${classes.loadingSkeleton}`}>
									<Box className={classes.contentItemIcon}>
										<Skeleton variant='circular' width={26} height={26} />
									</Box>
									<Box className={classes.contentItemBody}>
										<Skeleton width={120} height={20} />
										<Skeleton variant='rectangular' height={40} />
									</Box>
								</Box>
								<Divider className={classes.divider} light />
							</>
						) : isThatAll ? (
							<Box style={{ padding: '0 20px 10px 20px', textAlign: 'center' }}>
								<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
									{t('that_all')}
								</Typography>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</TblCustomScrollbar>
			</Popover>
		</Box>
	);
};

export default ElNotificationBell;
