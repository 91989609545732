import createReducers from 'utils/createReducers';

import { initialState as allCourseInitState } from 'shared/AllCourses/reducers';
import { initialState as lessonInitState } from 'shared/Lesson/reducers';

import { actions } from './constants';
import courseContentStates from './initialStates/courseContentStates';
import gradeBookStates from './initialStates/gradeBookStates';
import gradeWeightingStates from './initialStates/gradeWeightingStates';

const myCoursesState = {
	myCoursesList: [],
	numberOfCourses: {
		published: 0,
		draft: 0,
	},
	course: {},
	termsListByCourse: [],
	isFetchingTermsList: false,
	syllabus: {},
	assignmentsContents: [],
	testsContents: [],
	quizzesContents: [],
	typeOfCreate: 0,
	unitList: [],
	listActivitiesByUnits: [],
	courseItemByUnit: {},
	assignment: {},
	createdActivity: {},
	test: {},
	sectionsByGradingPeriod: [],
	courseDayDetail: {},
	allDataInPlanning: {},
	planningData: {},
	queueUpdate: {},
	// shadowHeight: {},
	courseDaysHeight: {
		maxMasterCourseDayId: null,
		maxMaster: 100,
		maxShadow: [],
		maxShadowCourseDayId: [],
	},
	activityDetails: {},
	gradingLateCompletion: {},
	gradingLateCompletionForTest: {},
	getGradingLateCompletionSuccess: false,
	updateGradingLateCompletionSuccess: false,

	isBusy: false,
	createNewTestSuccess: false,
	getTestDetailSuccess: false,
	isEditingTest: false,
	editTestSuccess: false,
	deleteTestSuccess: false,
	mcConsolidateAssignmentSuccess: null,
	mcConsolidateAssignmentFailed: null,

	mcConsolidateLessonSuccess: null,
	mcConsolidateLessonFailed: null,

	mcConsolidateTestSuccess: null,
	mcConsolidateTestFailed: null,

	mcRemoveShadowSuccess: null,
	mcRemoveShadowFailed: null,

	isFetchingSessionsList: false,
	currentSessionId: undefined,
	sessions: [],

	isFetchingSectionsList: false,
	sections: [],
	applyFor: undefined,

	error: null,

	isShowConfirmRelinkModal: false,
	sortStudentSubmissionParams: { sort: 'asc', sortField: 'section' },

	isFetchingAssignmentDetails: false,
	isFetchingParticipationDetails: false,
	isFetchingLessonDetails: false,
	isFetchingTestDetails: false,

	isFetchGradeList: false,

	isEditLessonSuccess: false,
	editMasterAssignmentSuccess: false,
	editMasterParticipationSuccess: false,
	createNewMasterParticipationSuccess: false,
	isDeletingParticipation: false,
	isDeletingActivity: false,
	deleteActivitySuccess: false,
	deletedActivityInfo: {},

	subCodeError: null,
	isValidateUserBelongToCourse: false,

	isSubmittingGrade: false,
	recentlyStudentGraded: {},
	gradedList: [],
	gradeSuccess: null,
	gradedAttempt: {},

	triggerModalUpdateCourseSchedule: false,
	hasProcessedAll: true,
	isPolling: true,
	necessaryFetchCourses: { value: true },

	basicInfo: {},
	courseValidation: null,
	permission: null,
	activityStudentWorkList: null,
};

const initialState = {
	...allCourseInitState,
	...lessonInitState,
	...gradeWeightingStates,
	...gradeBookStates,
	...myCoursesState,
	...courseContentStates,
};

export { myCoursesState, initialState };

export default createReducers(initialState, actions, actions.MY_COURSES_RESET);
