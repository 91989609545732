export default {
	MC_GET_GRADE_WEIGHT: 'MC_GET_GRADE_WEIGHT',
	MC_GET_GRADE_WEIGHT_SUCCESS: 'MC_GET_GRADE_WEIGHT_SUCCESS',
	MC_GET_GRADE_WEIGHT_FAILED: 'MC_GET_GRADE_WEIGHT_FAILED',

	MC_UPDATE_GRADE_WEIGHT: 'MC_UPDATE_GRADE_WEIGHT',
	MC_UPDATE_GRADE_WEIGHT_SUCCESS: 'MC_UPDATE_GRADE_WEIGHT_SUCCESS',
	MC_UPDATE_GRADE_WEIGHT_FAILED: 'MC_UPDATE_GRADE_WEIGHT_FAILED',

	GRADE_WEIGHTING_SET_STATE: 'GRADE_WEIGHTING_SET_STATE',

	GRADE_WEIGHT_CATEGORIES: 'GRADE_WEIGHT_CATEGORIES',
	GRADE_WEIGHT_CATEGORIES_SUCCESS: 'GRADE_WEIGHT_CATEGORIES_SUCCESS',
	GRADE_WEIGHT_CATEGORIES_FAILED: 'GRADE_WEIGHT_CATEGORIES_FAILED',

	PUBLISH_GRADE_WEIGHT_CATEGORIES: 'PUBLISH_GRADE_WEIGHT_CATEGORIES',
	PUBLISH_GRADE_WEIGHT_CATEGORIES_SUCCESS: 'PUBLISH_GRADE_WEIGHT_CATEGORIES_SUCCESS',
	PUBLISH_GRADE_WEIGHT_CATEGORIES_FAILED: 'PUBLISH_GRADE_WEIGHT_CATEGORIES_FAILED',

	GET_COMPARE_GRADE_WEIGHT_CATEGORIES: 'GET_COMPARE_GRADE_WEIGHT_CATEGORIES',
	GET_COMPARE_GRADE_WEIGHT_CATEGORIES_SUCCESS: 'GET_COMPARE_GRADE_WEIGHT_CATEGORIES_SUCCESS',
	GET_COMPARE_GRADE_WEIGHT_CATEGORIES_FAILED: 'GET_COMPARE_GRADE_WEIGHT_CATEGORIES_FAILED',

	CLEAR_MESSAGE_GRADE_WEIGHT_CATEGORIES: 'CLEAR_MESSAGE_GRADE_WEIGHT_CATEGORIES',
};
