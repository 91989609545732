import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useTheme } from '@mui/material';

import TblIcon from 'components/TblIcon';

import { TextField, Tooltip, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Grading from '../components/Grading';
import SubmissionRequirements from '../components/SubmissionRequirements';
import { ESTIMATE } from '../constants';
import { formatTime } from '../utils';

const useStyles = makeStyles()((theme) => ({
	indicator: {
		margin: theme.spacing(3, 0, 3.25),
	},
	errorIndicator: {
		marginRight: theme.spacing(26.5),
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
	},
	leftContent: {
		width: theme.spacing(82),
		marginRight: theme.spacing(4),
	},
	estimate: {
		display: 'flex',
		position: 'relative',
		'& .MuiInputBase-root': {
			width: theme.spacing(15),
		},
	},
	estimateInfo: {
		cursor: 'pointer',
		top: '4px',
		'& .material-symbols-rounded': {
			fontSize: theme.spacing(1.75),
		},
	},
	formatTime: {
		color: theme.newColors.gray[400],
		marginTop: theme.spacing(4.5),
		position: 'absolute',
		left: theme.spacing(15),
	},
	rightContent: {
		width: theme.spacing(39),
		gap: theme.spacing(1),
		marginTop: theme.spacing(11.75),
	},
	indicatorSetting: {
		marginLeft: theme.spacing(1.25),
		marginTop: theme.spacing(0.25),
		height: theme.spacing(2.5),
	},
}));

function StudyForTest(props) {
	const {
		fieldName,
		gradeWeightOptions,
		gradeCalculation,
		isViewOnly,
		submitCount,
		studyForTest,
		disabled,
		handleChange,
		touched,
		errors,
		handleBlur,
		setFieldError,
	} = props;

	const { t } = useTranslation(['myCourses', 'common', 'tour']);
	const theme = useTheme();

	const { classes } = useStyles();

	const durationName = !!fieldName ? `${fieldName}.duration` : 'duration';

	return (
		<Box>
			<Typography component={'div'} display='flex' variant='titleMedium'>
				{t('study_for_test_setting')}
				<Tooltip title={t('study_for_test_indicator')} placement='top'>
					<Box className={classes.indicatorSetting}>
						<TblIcon icon='info' fontSize={theme.fontSizeIcon.normal} />
					</Box>
				</Tooltip>
			</Typography>
			<Box className={classes.estimate} mt={3}>
				<TextField
					endAdornment={
						<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
							{t('mins')}
						</Typography>
					}
					required
					name={durationName}
					onBlur={(e) => {
						if (!e?.target?.value) {
							return handleBlur(e);
						}
						let value = Number(e.target.value);
						if (value > ESTIMATE.MAX) {
							value = ESTIMATE.MAX;
						} else if (value < ESTIMATE.MIN) {
							value = ESTIMATE.MIN;
						}
						handleChange(durationName, value);
						setFieldError(durationName, null);
					}}
					disabled={isViewOnly || disabled}
					label={
						<Box display='flex'>
							{t('time_to_complete')}
							<Tooltip title={`${t('duration_study_for_test')}`} placement='top'>
								<Box ml={'2px'} mt={'2px'}>
									<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
								</Box>
							</Tooltip>
						</Box>
					}
					type='number'
					helperText={!!((touched?.duration || submitCount) && errors?.duration) && errors?.duration}
					error={!!((touched?.duration || submitCount) && errors?.duration)}
					inputProps={{ maxLength: 3 }}
					value={studyForTest?.duration}
					onChange={(e) => {
						handleChange(durationName, e.target.value);
					}}
				/>

				{studyForTest.duration / 60 >= 1 && (
					<Typography className={classes.formatTime} mt={3} ml={1} variant='bodySmallRegular'>
						{`Total ${formatTime(studyForTest.duration)}`}
					</Typography>
				)}
			</Box>
			<Box mt={3}>
				<Grading
					gradeCalculation={gradeCalculation}
					values={studyForTest}
					handleBlur={handleBlur}
					errors={errors}
					touched={touched}
					disabled={disabled}
					handleChange={handleChange}
					fieldName={fieldName}
					tooltip={t('grading_indicator_sft')}
					setFieldError={setFieldError}
				/>
				<SubmissionRequirements
					fieldName={fieldName}
					handleBlur={handleBlur}
					values={studyForTest}
					disabled={disabled}
					handleChange={handleChange}
				/>
			</Box>
		</Box>
	);
}
StudyForTest.propTypes = {
	fieldName: PropTypes.string,
	isViewOnly: PropTypes.bool,
};

StudyForTest.defaultProps = {
	isViewOnly: false,
	fieldName: '',
};
export default StudyForTest;
