import { styled } from '@mui/material';

import { Box, Button, Typography } from 'ella-storybook';

const GridContainer = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'auto 1000px auto',
	gridTemplateRows: 'auto',
}));

const GridItem = styled(Box)(({ theme }) => ({
	gridColumn: '2',
	gridRow: 'span',
}));

const Container = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: `${theme.spacing(2)} 0`,
}));

const HeaderArea = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	maxWidth: theme.spacing(125),
	flex: '1 1 100%',
}));

const Header = styled(Typography)(({ theme }) => ({}));

const SubHeader = styled(Button)(({ theme }) => ({
	alignSelf: 'flex-start',
	padding: '0 !important',
	marginBottom: theme.spacing(2),
	minWidth: 'unset',
	'&:hover': { backgroundColor: 'transparent !important', cursor: 'pointer', span: { textDecoration: 'underline' } },
}));
const BoxActivityName = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: '16px',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	alignItems: 'center',
	// alignSelf: 'stretch',
	borderRadius: '8px',
	background: theme.newColors.gray[50],
}));

export { HeaderArea, GridContainer, GridItem, Container, SubHeader, Header, BoxActivityName };
