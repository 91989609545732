import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import useDidMountEffect from 'utils/customHook/useDidMoutEffect';
import { isGuardian } from 'utils/roles';

import authActions from 'shared/Auth/actions';

import { useAuthDataContext } from 'AppRoute/AuthProvider';
import {
	convertValueToUrlParam,
	getCurrentSchoolYearLocalStorage,
	getCurrentSchoolYearNew,
	setCurrentSchoolYear,
	setUrlParam,
} from 'utils';

function useGetSchoolYear() {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const authContext = useAuthDataContext();
	const { currentStudentId, currentUser } = authContext;
	const organizationId = currentUser?.organizationId;
	const searchParams = new URLSearchParams(location.search);

	const schoolYears = useSelector((state) => state.Auth.schoolYears) ?? [];
	const currentSchoolYear = useSelector((state) => state.Auth.currentSchoolYear) ?? null;
	const schoolYearSelected = useSelector((state) => state.Auth.schoolYearSelected);
	const schoolYearSelectedLocalStorage = getCurrentSchoolYearLocalStorage();

	const setParamSchoolYear = (schoolYear, type) => {
		setUrlParam(
			location,
			history,
			{
				schoolYearId: schoolYear?.id,
				schoolYear: convertValueToUrlParam(schoolYear?.name),
			},
			type,
		);
	};
	const setSchoolYearSelected = (schoolYearId) => {
		const schoolYearItem = schoolYears.find((e) => e.id === schoolYearId);
		if (schoolYearItem?.id) {
			dispatch(
				authActions.authSetState({
					schoolYearSelected: schoolYearId,
				}),
			);
			setCurrentSchoolYear(schoolYearItem);
			setParamSchoolYear(schoolYearItem, 'replace');
		}
	};

	const setFilterSchoolYear = (schoolYearId) => {
		const schoolYearItem = schoolYears.find((e) => e.id === schoolYearId);
		if (schoolYearItem?.id) {
			setCurrentSchoolYear(schoolYearItem);
			dispatch(
				authActions.authSetState({
					schoolYearSelected: schoolYearId,
				}),
			);
		}
	};
	const resetSchoolYear = () => {
		// const currentSchoolYear = getCurrentSchoolYear(schoolYears);
		const currentSchoolYearNew = getCurrentSchoolYearNew(currentSchoolYear);

		dispatch(
			authActions.authSetState({
				schoolYearSelected: currentSchoolYearNew?.id || null,
			}),
		);
	};
	useEffect(() => {
		if (!organizationId) return;
		dispatch(
			authActions.getCurrentUserSchoolYears({
				orgId: organizationId,
				userId: !!currentStudentId && isGuardian(currentUser) ? currentStudentId : currentUser?.id,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useDidMountEffect(() => {
		if (schoolYears.length > 0) {
			const currentSchoolYearNew = getCurrentSchoolYearNew(currentSchoolYear);

			const schoolYearItem = searchParams.get('schoolYearId')
				? Number(searchParams.get('schoolYearId'))
				: Number(currentSchoolYearNew.id);
			// const schoolYearTmp = schoolYearItem || schoolYears[0].id;
			const schoolYearTmp = schoolYearItem || currentSchoolYear?.id;
			dispatch(
				authActions.authSetState({
					schoolYearSelected: schoolYearTmp,
				}),
			);
		}
	}, [schoolYears]);
	return {
		schoolYearSelected,
		setSchoolYearSelected,
		schoolYearSelectedLocalStorage,
		setFilterSchoolYear,
		resetSchoolYear,
		currentSchoolYear,
	};
}

export const withHooksGetSchoolYear = (Component) => (props) => {
	const { schoolYearSelected, setSchoolYearSelected, schoolYearSelectedLocalStorage } = useGetSchoolYear();
	return (
		<Component
			schoolYearSelected={schoolYearSelected}
			schoolYearSelectedLocalStorage={schoolYearSelectedLocalStorage}
			setSchoolYearSelected={setSchoolYearSelected}
			{...props}
		/>
	);
};

export default useGetSchoolYear;
