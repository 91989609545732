import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';

import { COURSE_ROLE } from 'utils/roles';

import { END_POINT as ALL_COURSE_END_POINT } from 'shared/AllCourses/constants';

// import GradeReportActions from 'modules/GradeReport/actions';

import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { END_POINT as MT_END_POINT } from 'modules/MyTasks/constants';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import GraderActions from './actions';
import { END_POINT, OVERRIDE_GRADE_TYPE, actions } from './constants';

// const FORMAT_DATE_COMMENT = 'MMM D, YYYY - hh:mm A';

// const getGraderPermissionEpic = (action$) =>
// 	action$.pipe(
// 		ofType(actions.GET_PERMISSION_COURSE),
// 		switchMap((action) =>
// 			makeAjaxRequest(
// 				MC_END_POINT.mc_get_permission_course.method,
// 				MC_END_POINT.mc_get_permission_course.url(action.payload.orgId, action.payload.courseId),
// 			).pipe(
// 				mergeMap((data) =>
// 					of(
// 						GraderActions.getPermissionCourseSuccess({
// 							courseName: data.response.courseName,
// 							permission: {
// 								roles: [...data.response.courseRoles, ...data.response.organizationRoles],
// 							},
// 						}),
// 					),
// 				),
// 				catchError((error) =>
// 					of(
// 						{ type: 'GLOBAL_ERROR', payload: { error } },
// 						GraderActions.getPermissionCourseFailed({
// 							error: error.response.errors,
// 						}),
// 					),
// 				),
// 			),
// 		),
// 	);

const getBasicInfoEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_BASIC_INFO),
		switchMap((action) =>
			makeAjaxRequest(
				ALL_COURSE_END_POINT.get_basic_info.method,
				ALL_COURSE_END_POINT.get_basic_info.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) =>
					of(
						GraderActions.getBasicInfoSuccess({
							basicInfo: data?.response?.basicInfo,
							getBasicInfoSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						GraderActions.getBasicInfoFailed({
							getBasicInfoFailed: error?.response?.errors,
							errorCode: error?.status,
						}),
					),
				),
			),
		),
	);

const getActivitiesInGraderEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ACTIVITIES_IN_GRADER),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_activities_in_grader.method,
				END_POINT.get_activities_in_grader.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) => {
					if (data.response.activities.length > 0) {
						let _activity = data.response.activities[0];
						const currentActivityId = action.payload.currentActivityId;
						if (currentActivityId) {
							_activity = data.response.activities.find((ac) => +ac.id === +currentActivityId);
						}

						return of(
							GraderActions.getActivitiesInGraderSuccess({
								activityList: data.response.activities,
							}),
							GraderActions.getSectionsByActivityGrader({
								orgId: action.payload.orgId,
								courseId: action.payload.courseId,
								activityId: _activity.id,
								activityType: _activity.activityType,
							}),
						);
					}
					return of(
						GraderActions.getActivitiesInGraderSuccess({
							activityList: data.response.activities,
						}),
					);
				}),
				catchError(() => of(GraderActions.getActivitiesInGraderFailed({}))),
			),
		),
	);

const getSectionsByActivityGraderEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SECTIONS_BY_ACTIVITY_GRADER),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_sections_by_activity_grader.method,
				END_POINT.get_sections_by_activity_grader.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.activityId,
					action.payload.activityType,
				),
			).pipe(
				mergeMap((data) =>
					of(
						GraderActions.getSectionsByActivityGraderSuccess({
							sectionList: data.response.sections,
						}),
					),
				),
				catchError(() => of(GraderActions.getSectionsByActivityGraderFailed({}))),
			),
		),
	);

const getGradingListEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADING_LIST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_grading_list.method,
				END_POINT.get_grading_list.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.activityId,
					action.payload.activityType,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						GraderActions.getGradingListSuccess({
							gradingList: data.response.data,
							fetchingGradingList: false,
						}),
					),
				),
				catchError((error) =>
					of(
						GraderActions.getGradingListFailed({
							error: error?.response?.errors,
							fetchingGradingList: false,
						}),
					),
				),
				finalize(() =>
					of(
						GraderActions.graderSetState({
							fetchingGradingList: false,
						}),
					),
				),
			),
		),
	);

const getGraderDetailEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.GET_GRADER_DETAIL),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_grader_detail.method,
				END_POINT.get_grader_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.shadowId,
					action.payload.activityType,
					action.payload.studentId,
				),
			).pipe(
				mergeMap((ajaxResponse) => {
					const { data } = ajaxResponse.response;
					const gradingListUpdated = handleUpdateGradingList(data, state$);

					const newData = mapShadowActivity(data);

					return of(
						GraderActions.getGraderDetailSuccess({
							getGraderDetailSuccess: true,
							statusGraded: data.status,
							graderDetail: {
								...newData,
								grade: data.originalGrade,
								finalGrade: data.overallGrade,
							},
							isFetchingGraderDetail: false,
							gradingList: gradingListUpdated,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.getGraderDetailFailed({
							error: error?.response?.errors,
							isFetchingGraderDetail: false,
						}),
					),
				),
			),
		),
	);

const getTotalGradedEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TOTAL_GRADED),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_total_graded.method,
				END_POINT.get_total_graded.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.activityId,
					action.payload.activityType,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						GraderActions.getTotalGradedSuccess({
							summary: data.response.summary,
							total: data.response.total,
							inputGradeSuccess: null,
						}),
					),
				),
				catchError((error) =>
					of(
						GraderActions.getTotalGradedFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
const handleUpdateGradingList = (data, state$) => {
	const { studentSelected } = state$.value.Grader;
	return state$.value.Grader.gradingList.map((student) => {
		if (student.studentId === studentSelected) {
			return {
				...student,
				overallGrade: isFinite(data?.overallGrade) ? data?.overallGrade : data?.finalGrade,
			};
		}
		return student;
	});
};

const mapShadowActivity = (data) => {
	if (isEmpty(data)) return {};

	const { activityType } = data;

	let key = '';

	switch (activityType) {
		case ACTIVITY_CATEGORY.ASSIGNMENT:
			key = 'Assignment';
			break;
		case ACTIVITY_CATEGORY.TEST:
			key = 'StudyForTest';
			break;
		case ACTIVITY_CATEGORY.TEST_IN_CLASS:
			key = 'Test';
			break;
		default:
			break;
	}

	const { [`shadow${key}`]: shadow, ...restShadow } = data;

	if (isEmpty(shadow)) return { ...restShadow, shadowActivity: {} };

	const { [`master${key}`]: master, ...restMaster } = shadow;

	return { shadowActivity: { ...restMaster, masterActivity: { ...(master ?? {}) } }, ...restShadow };
};
const inputGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.INPUT_GRADE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.input_grade.method,
				END_POINT.input_grade.url(action.payload.courseId, action.payload.progressId),
				action.payload.data,
			).pipe(
				mergeMap((ajaxResponse) => {
					const { code: _, ...payload } = ajaxResponse.response;
					const graderDetail = state$.value.Grader.graderDetail;
					const isSubmittingGrade = action.payload?.isSubmittingGrade;
					const gradingListUpdated = handleUpdateGradingList(payload, state$);
					const activityType = action.payload.data.type;
					let currentTermId;
					let shadowId;
					switch (Number(activityType)) {
						case ACTIVITY_CATEGORY.ASSIGNMENT:
							currentTermId = graderDetail?.shadowActivity?.assignDate?.courseDay?.gradingPeriod?.termId;

							shadowId = graderDetail?.shadowAssignment?.id;
							break;
						case ACTIVITY_CATEGORY.TEST:
							currentTermId = graderDetail?.shadowActivity?.announceDate?.courseDay?.gradingPeriod?.termId;

							shadowId = graderDetail?.shadowStudyForTest?.id;
							break;
						default:
							break;
					}

					const nextActions = [
						GraderActions.inputGradeSuccess({ inputGradeSuccess: true }),
						GraderActions.graderSetState({
							inputGradeSuccess: true,
							graderDetail: {
								...state$.value.Grader.graderDetail,
								grade: payload.originalGrade,
								finalGrade: payload.overallGrade,
								overallGrade: payload.overallGrade,
							},
							gradingList: gradingListUpdated,
							isSubmittingGrade: false,
						}),
						myCoursesActions.myCoursesSetState({
							gradeSuccess: null,
						}),
					];

					if (isSubmittingGrade) {
						nextActions.push(
							myCoursesActions.multipleMcCalculateOverallCourseGrade({
								courseId: action.payload.courseId,
								termId: +currentTermId || +action.payload.data.termId,
								mcCalculateOverallCourseGradeSuccess: null,
								data: [payload],
								recentlyStudentGraded: { ...payload, shadowId, activityType },
							}),
							// myCoursesActions.mcGetGradeBook({
							//   courseId: action.payload.courseId,
							//   termId: +action.payload.data.termId,
							//   gradeBookColumn: [],
							//   gradeBookRow: [],
							//   urlParams: action.payload.urlParams,
							//   isFetchingGradeBook: true,
							// })
						);
					}

					return from(nextActions);
				}),
				catchError((error) =>
					of(
						GraderActions.inputGradeSuccess({ inputGradeSuccess: false }),
						GraderActions.getTotalGradedFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const inputOverallGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.INPUT_OVERALL_GRADE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.input_overall_grade.method,
				END_POINT.input_overall_grade.url(
					action.payload.courseId,
					action.payload.shadowActivityId,
					action.payload.progressId,
				),
				action.payload.data,
			).pipe(
				mergeMap((ajaxResponse) => {
					const { data } = ajaxResponse.response;
					const gradingListUpdated = handleUpdateGradingList(data, state$);
					return of(
						GraderActions.graderSetState({
							inputGradeSuccess: true,
							graderDetail: {
								...state$.value.Grader.graderDetail,
								overallGrade: data.overallGrade,
							},
							statusGraded: data.studentProgressStatus,
							gradingList: gradingListUpdated,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.getTotalGradedFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getCommentsGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.GET_GRADER_COMMENTS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_comments_grader.method,
				END_POINT.get_comments_grader.url(
					state$.value.Grader.courseId,
					state$.value.Grader.orgId,
					action.payload.studentId,
					action.payload.submissionId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((ajaxResponse) => {
					const { response } = ajaxResponse;
					// const gradeReport = cloneDeep(state$.value?.GradeReport?.gradeReport);
					// if (gradeReport) {
					//   const index = gradeReport.activities.findIndex(
					//     (activity) => activity.type === action.payload.gradeWeightType
					//   );
					//   const indexActivity = gradeReport.activities[index].courseActivities.findIndex(
					//     (courseActivity) =>
					//       courseActivity?.studentProgress?.[0].id === action.payload.submissionId ||
					//       courseActivity.quizSubmissions?.[0].id === action.payload.submissionId
					//   );
					//   const progress =
					//     gradeReport.activities[index].courseActivities[indexActivity]?.studentProgress?.[0] ||
					//     gradeReport.activities[index].courseActivities[indexActivity]?.quizSubmissions?.[0];
					//   progress.isNotReadComments = false;
					// }
					const convertedTimeZoneComments = response.comments.map((comment) =>
						// const convertedCreatedAt = moment(comment.createdAt).format(FORMAT_DATE_COMMENT);
						// const convertedUpdatedAt = moment(comment.updatedAt).format(FORMAT_DATE_COMMENT);

						({ ...comment, createdAt: comment.createdAt, updatedAt: comment.updatedAt }),
					);

					return of(
						GraderActions.getGraderCommentsSuccess({
							comments: convertedTimeZoneComments,
							isLoadingComment: false,
						}),
						// GradeReportActions.gradeReportSetState({
						//   gradeReport,
						// })
					);
				}),
				catchError((error) =>
					of(
						GraderActions.getGraderCommentsFailed({
							error: error?.response?.errors,
							isLoadingComment: false,
						}),
					),
				),
			),
		),
	);

const postCommentGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.POST_GRADER_COMMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.post_comment_grader.method,
				END_POINT.post_comment_grader.url(
					state$.value.Grader.courseId,
					state$.value.Grader.orgId,
					action.payload.studentId,
					action.payload.submissionId,
				),
				action.payload.data,
			).pipe(
				mergeMap((ajaxResponse) => {
					const { response } = ajaxResponse;
					// const { comment } = response || {};

					// comment.createdAt = moment(comment.createdAt).format(FORMAT_DATE_COMMENT);
					// comment.updatedAt = moment(comment.updatedAt).format(FORMAT_DATE_COMMENT);

					return of(
						GraderActions.postGraderCommentSuccess({
							comments: [response.comment, ...state$.value.Grader.comments],
							isLoadingComment: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.postGraderCommentFailed({
							error: error?.response?.errors,
							isLoadingComment: false,
						}),
					),
				),
			),
		),
	);

const deleteCommentGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.DELETE_GRADER_COMMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_comment_grader.method,
				END_POINT.delete_comment_grader.url(
					state$.value.Grader.courseId,
					state$.value.Grader.orgId,
					action.payload.studentId,
					action.payload.submissionId,
					action.payload.commentId,
				),
			).pipe(
				mergeMap((ajaxResponse) => {
					const { response } = ajaxResponse;
					const oldComments = state$.value.Grader.comments;

					return of(
						GraderActions.deleteGraderCommentSuccess({
							comments: oldComments.filter((comment) => `${comment.id}` !== `${response.commentId}`),
							isLoadingComment: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.deleteGraderCommentFailed({
							error: error?.response?.errors,
							isLoadingComment: false,
						}),
					),
				),
			),
		),
	);

const editCommentGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.EDIT_GRADER_COMMENT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.edit_comment_grader.method,
				END_POINT.edit_comment_grader.url(
					state$.value.Grader.courseId,
					state$.value.Grader.orgId,
					action.payload.studentId,
					action.payload.submissionId,
					action.payload.commentId,
				),
				action.payload.data,
			).pipe(
				mergeMap(({ response }) => {
					const { comment } = response || {};
					const oldComments = cloneDeep(state$?.value?.Grader?.comments ?? []);
					// comment.createdAt = moment(comment.createdAt).format(FORMAT_DATE_COMMENT);
					// comment.updatedAt = moment(comment.updatedAt).format(FORMAT_DATE_COMMENT);

					return of(
						GraderActions.editGraderCommentSuccess({
							comments: oldComments.map((data) => {
								if (data.id === comment.id) {
									return {
										...data,
										...comment,
									};
								}
								return data;
							}),
							isLoadingComment: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.editGraderCommentFailed({
							error: error?.response?.errors,
							isLoadingComment: false,
						}),
					),
				),
			),
		),
	);

const checkReadCommentGradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.CHECK_READ_GRADER_COMMENTS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.check_read_comments_grader.method,
				END_POINT.check_read_comments_grader.url(
					state$.value.Grader.courseId,
					state$.value.Grader.orgId,
					action.payload.studentId,
					action.payload.submissionId,
				),
			).pipe(
				mergeMap((ajaxResponse) => {
					const { response } = ajaxResponse;
					return of(
						GraderActions.checkReadGraderCommentsSuccess({
							isNotReadComments: !response.read,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.checkReadGraderCommentsFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const clearCommentsEpic = (action$) =>
	action$.pipe(
		ofType(actions.CLEAR_COMMENTS),
		switchMap(() =>
			of(
				GraderActions.clearCommentsSuccess({
					comments: [],
					isNotReadComments: false,
				}),
			),
		),
	);
const teacherGetStudentAttemptsEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.TEACHER_GET_STUDENT_ATTEMPTS),
		mergeMap(({ payload }) =>
			makeAjaxRequest(
				MT_END_POINT.get_student_attempts.method,
				MT_END_POINT.get_student_attempts.url(payload?.orgId, payload?.courseId, {
					...payload.params,
					viewing: COURSE_ROLE.TEACHER,
				}),
			).pipe(
				mergeMap(({ response }) => {
					const studentAttempts = response.studentAttempts;

					const isUnlimited = payload.isUnlimited;

					return of(
						GraderActions.teacherGetStudentAttemptsSuccess({
							studentAttempts,
						}),
						GraderActions.selectAttempt({
							selectedFile: studentAttempts[0]?.studentSubmittedFiles?.[0] || null,
							selectedAttempt: studentAttempts[0] || null,
							attemptIndex: studentAttempts?.length,
							isUnlimited: isUnlimited,
						}),
					);
				}),
				catchError((errors) => of(GraderActions.teacherGetStudentAttemptsFailed({ errors, studentAttempts: [] }))),
			),
		),
	);
const switchOverrideModeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.SWITCH_OVERRIDE_MODE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.switch_override_mode.method,
				END_POINT.switch_override_mode.url(
					action.payload.courseId,
					action.payload.overallGradeMode,
					action.payload.progressId,
				),
			).pipe(
				mergeMap((ajaxResponse) => {
					const { data } = ajaxResponse.response;

					const activityType = action?.payload?.activityType;

					let shadowId;
					switch (Number(activityType)) {
						case ACTIVITY_CATEGORY.ASSIGNMENT:
							shadowId = data?.shadowAssignmentId;
							break;
						case ACTIVITY_CATEGORY.TEST:
							shadowId = data?.shadowStudyForTestId;
							break;
						default:
							break;
					}

					return of(
						GraderActions.switchOverrideModeSuccess({
							graderDetail: {
								...state$?.value?.Grader.graderDetail,
								overrideGradeType: action.payload.overallGradeMode,
								overallGrade: data.overallGrade,
								attemptIndex: data.attemptIndex,
								dataResSwitchMode: data,
							},
							switchModeSuccess: true,
							switchModeOverrideSuccess: action.payload.overallGradeMode === OVERRIDE_GRADE_TYPE.MANUAL_GRADE,
							switchModeAutoSuccess: action.payload.overallGradeMode === OVERRIDE_GRADE_TYPE.AUTOMATED_GRADE,
						}),
						myCoursesActions.multipleMcCalculateOverallCourseGrade({
							courseId: action.payload.courseId,
							termId: action.payload.currentTermId,
							mcCalculateOverallCourseGradeSuccess: null,
							data: [data],
							recentlyStudentGraded: { ...data, shadowId, activityType },
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.switchOverrideModeFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
const updateStudentAttemptsEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.UPDATE_STUDENT_ATTEMPTS),
		switchMap(({ payload }) => {
			const { updatedAttempt, mutableUpdate } = payload;
			if (!updatedAttempt) {
				return of(GraderActions.updateStudentAttemptsSuccess({ updateStudentAttemptsSuccess: true }));
			}

			const studentAttempts = [...state$.value.Grader.studentAttempts];

			const newStudentAttempts = (studentAttempts || []).map((item) => {
				if (item.id === updatedAttempt.id) {
					return {
						...item,
						...updatedAttempt,
					};
				}
				return item;
			});
			return of(
				GraderActions.updateStudentAttemptsSuccess({
					studentAttempts: newStudentAttempts,
					updateStudentAttemptsSuccess: true,
				}),
				myCoursesActions.myCoursesSetState({
					gradeSuccess: null,
				}),
			);
		}),
		catchError((error) => of(GraderActions.updateStudentAttemptsFailed({ updateStudentAttemptsSuccess: false }))),
	);

const selectAttemptEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.SELECT_ATTEMPT),
		switchMap(({ payload }) => {
			try {
				const { selectedFile, selectedAttempt, isUnlimited } = payload;
				if (!selectedFile) {
					return of(GraderActions.selectAttemptSuccess({}));
				}

				return of(
					GraderActions.selectAttemptSuccess({
						selectedAttempt: selectedAttempt,
						selectedFile,
						isUnlimited,
					}),
				);
			} catch (error) {
				console.log('error', error);
			}
		}),
		catchError((error) => of(GraderActions.selectAttemptFailed({}))),
	);

const logAttemptEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.LOG_ATTEMPT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.log_attempts.method,
				END_POINT.log_attempts.url(action.payload.orgId, action.payload.courseId, action.payload.logAttemptId),
				action.payload.log_data,
			).pipe(
				mergeMap((ajaxResponse) => {
					const { data } = ajaxResponse.response;
					const { log_ggFiles, log_uploadFiles } = action.payload;
					return of(
						GraderActions.logAttemptSuccess({
							logAttemptSuccess: true,
							log_ggFiles,
							log_uploadFiles,
						}),
					);
				}),
				catchError((error) =>
					of(
						GraderActions.logAttemptFailed({
							error: error?.response?.errors,
							logAttemptSuccess: false,
						}),
					),
				),
			),
		),
	);
export default [
	getBasicInfoEpic,
	// getGraderPermissionEpic,
	getActivitiesInGraderEpic,
	getSectionsByActivityGraderEpic,
	getGradingListEpic,
	getGraderDetailEpic,
	getTotalGradedEpic,
	inputGradeEpic,
	inputOverallGradeEpic,
	getCommentsGradeEpic,
	postCommentGradeEpic,
	deleteCommentGradeEpic,
	editCommentGradeEpic,
	checkReadCommentGradeEpic,
	clearCommentsEpic,
	teacherGetStudentAttemptsEpic,
	updateStudentAttemptsEpic,
	selectAttemptEpic,
	switchOverrideModeEpic,
	logAttemptEpic,
];
