import { ROLES_UPDATE_COURSE } from 'utils/roles';

import { checkPermission } from 'utils';
export const checkPermissionCreateAndPublish = (permission) => checkPermission(permission, ROLES_UPDATE_COURSE);

export const getQueueUpdate = (courseDayIds) => {
	const queueUpdate = {};
	if (courseDayIds) {
		courseDayIds.forEach((id) => (queueUpdate[id] = true));
	}
	return queueUpdate;
};
