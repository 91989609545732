import { makeAjaxRequest } from 'utils/ajax';

import graderActions from 'modules/Grader/actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import myCoursesActions from '../actions';
import { END_POINT, actions } from '../constants';

const gradeEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.GRADE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.grade.method,
				END_POINT.grade.url(action.payload.orgId, action.payload.courseId),
				action.payload.gradingData,
			).pipe(
				mergeMap(({ response }) => {
					const arrPromise = [
						myCoursesActions.gradeSuccess({
							gradeSuccess: true,
							gradedAttempt: response.updated.studentAttempt[0],
						}),
					];
					const gradeUpdate = response?.updated?.gradeUpdate;
					const dataFinal = { ...gradeUpdate, ...action.payload.gradingData.payload };
					if (gradeUpdate) {
						arrPromise.push(
							graderActions.getGraderDetailSuccess({
								graderDetail: {
									...state$.value.Grader.graderDetail,
									overallGrade: response.updated?.gradeUpdate?.overallGrade,
									overallGradeType: response.updated?.gradeUpdate?.overallGradeType,
									attemptIndex: response.updated?.gradeUpdate?.attemptIndex,
								},
							}),
							myCoursesActions.multipleMcCalculateOverallCourseGrade({
								inputGradeSuccess: null,
								updateStudentAttemptsSuccess: null,
								courseId: action.payload.courseId,
								termId: action.payload.gradingData.termId,
								data: [dataFinal],
								mcCalculateOverallCourseGradeSuccess: null,
							}),
						);
					}
					return of(...arrPromise);
				}),
				catchError(() => of(myCoursesActions.gradeFailed({}))),
			),
		),
	);

export default [gradeEpic];
