import React from 'react';

import { Skeleton, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';

function BreadcrumbTitle({ title }) {
	return title ? (
		<Typography variant='headlineSmall' component='div' alignItems='center' className='word-wrap'>
			{title}
		</Typography>
	) : (
		<Skeleton height={50} width={240} />
	);
}

BreadcrumbTitle.propTypes = {
	title: PropTypes.string,
};

export default BreadcrumbTitle;
