import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

import TblIcon from 'components/TblIcon';
import BackgroundOtp from 'shared/Auth/components/BackgroundOtp';

import { isGuardian } from 'utils/roles';

import { USER_BEHAVIOR } from 'shared/User/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { useLayoutContext } from 'layout/LayoutContext';
import { ROUTE_MY_PROFILE } from 'modules/MyProfile/constantsRoute';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { makeStyles } from 'tss-react/mui';

import myProfileActions from '../../actions';

const { Typography, Box, Button } = require('ella-storybook');

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 64px)',
	},
	containerForm: {
		display: 'flex',
		flexDirection: 'column',
		width: '480px',
		textAlign: 'center',
	},
	buttonNext: {
		backgroundColor: theme.newColors.primary[500],
		color: theme.newColors.white,
		width: '100%',
	},
}));

const VerifyPhoneSuccess = (props) => {
	const { isOnBoarding } = props;
	const { classes } = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();

	const isChangeProfileSuccess = useSelector((state) => state.MyProfile.isChangeProfileSuccess);
	const layoutContext = useLayoutContext();

	const authContext = useContext(AuthDataContext);
	const currentUser = authContext.currentUser;
	const { settings } = currentUser;

	const { t } = useTranslation('myProfile');

	useEffect(() => {
		layoutContext.setFullScreen(true);

		return () => {
			dispatch(myProfileActions.resetMyProfileReducer({ error: null, verifyOtpSuccess: false }));
			layoutContext.setFullScreen(false);
		};
	}, []);

	useEffect(() => {
		if (isGuardian(currentUser) && isChangeProfileSuccess && isOnBoarding) {
			const currentHostname = window.location.hostname;

			const studentId = currentUser?.students?.[0]?.id;
			let path = ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT();

			if (studentId) {
				path = ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(studentId);
			}

			const fullUrl = `${window.location.protocol}//${currentHostname}:${window.location.port}${path}`;

			window.location.replace(fullUrl);
		}
	}, [isChangeProfileSuccess, isOnBoarding]);

	const handleNext = () => {
		let path = { pathname: ROUTE_MY_PROFILE.DEFAULT };

		if (isOnBoarding) {
			if (isGuardian(currentUser)) {
				const keys = Object.keys(settings);
				const _settings = { [keys[0]]: [...settings.behavior, USER_BEHAVIOR.HAVE_SET_UP_PROFILE] };

				const payload = {
					settings: _settings,
				};
				dispatch(myProfileActions.updateMyProfile(payload));
				return;
			}

			path = { search: 'step=4' };
		}

		history.push(path);
		localStorage.setItem('backAddPhone', 1);
	};

	return (
		<div className={classes.container}>
			<Box className={classes.containerForm}>
				<TblIcon
					icon='check_circle'
					fontSize={theme.fontSizeIcon.xLarge}
					color={theme.newColors.green[600]}
					weight={300}
				/>

				<Typography mt={2} variant='bodyMediumRegular' color={theme.newColors.green[600]}>
					{t('your_phone_number_is_verified')}
				</Typography>

				<Box mb={'24px'} />
				<Button className={classes.buttonNext} onClick={handleNext}>
					{t('common:next')}
				</Button>
				<Box mb={'20px'} />
			</Box>
			<BackgroundOtp />
		</div>
	);
};
VerifyPhoneSuccess.defaultProps = {};

export default VerifyPhoneSuccess;
