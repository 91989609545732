import loadable from 'utils/loadable';
import { SCHOOL_MANAGER } from 'utils/roles';

import { MODULE_ALIAS, PERMISSION_BASIC_KEYS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/icn_assessmentMethods.svg';

import { ROUTE_ASSESSMENT_METHOD } from './constantsRoute';

const AssessmentMethod = loadable(() => import(/* webpackChunkName: "LazyAssessmentMethod" */ './containers'));

export default [
	{
		path: ROUTE_ASSESSMENT_METHOD.DEFAULT,
		component: AssessmentMethod,
		private: true,
		exact: true,
		roles: [SCHOOL_MANAGER],
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.GRADING_SCHEMA,
		menu: {
			title: 'Grading Scheme',
			group: 'Course Administration',
			icon: IcnMenu,
			order: 3,
			groupOrder: 1,
		},
	},
];
