/* eslint-disable max-len */
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';

import TblIcon from 'components/TblIcon';

import usePrevious from 'utils/customHook/usePrevious';
import { isTeacher } from 'utils/roles';

import { DIALOG_TYPE } from 'shared/MyCourses/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { BaseModal, Box, Typography } from 'ella-storybook';
import { findIndex, get, intersection, isEmpty, isNumber, omit, size } from 'lodash';
import MyCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY, ACTIVITY_CATEGORY_NAME, RESCHEDULE_STEP } from 'modules/MyCourses/constants';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { matchingComponent, matchingHeaderNavigator, parseJson } from 'utils';

import Footer from '../components/UpdateSchedule/Footer';
import LeavePageModal from '../components/UpdateSchedule/LeavePageModal';

const useStyles = makeStyles()((theme) => ({
	boxTooltip: {
		display: 'flex',
		alignItems: 'start',
		borderRadius: '8px',
		border: `1px solid ${theme.newColors.blue[100]}`,
		backgroundColor: theme.newColors.blue[50],
		padding: '12px',
		gap: '8px',
		color: theme.newColors.blue[600],
	},
}));
const getConfigSchedule = (translation) => ({
	[ACTIVITY_CATEGORY.ASSIGNMENT]: {
		assignTitle: translation('assign_on'),
		dueTitle: translation('due_on'),
		publishNow: translation('assign_now'),
		hideDue: false,
		activityType: ACTIVITY_CATEGORY.ASSIGNMENT,
	},
	[ACTIVITY_CATEGORY.TEST_IN_CLASS]: {
		assignTitle: translation('announced_on'),
		dueTitle: translation('taken_on'),
		publishNow: translation('announce_now'),
		hideDue: false,
		activityType: ACTIVITY_CATEGORY.TEST_IN_CLASS,
	},
	[ACTIVITY_CATEGORY.LESSON]: {
		assignTitle: translation('delivered_on'),
		dueTitle: '',
		hideDue: true,
		activityType: ACTIVITY_CATEGORY.LESSON,
	},
});
function UpdateScheduleModal({ open, onClose, organizationId, schoolYearSelected, selectedCourseToReschedule }) {
	const { t } = useTranslation('myCourses');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const authContext = useContext(AuthDataContext);
	const { currentUser } = authContext;
	const { classes } = useStyles();
	// const [reScheduleStep, setReScheduleStep] = useLocalStorage('reScheduleStep', RESCHEDULE_STEP.COURSE);

	const [title, setTitle] = useState(null);
	const [reScheduleStep, setReScheduleStep] = useState(RESCHEDULE_STEP.COURSE);
	const [selectedCourse, setSelectedCourse] = useState(null);
	const [selectedActivity, setSelectedActivity] = useState(null);
	const [temporaryMaster, setTemporaryMaster] = useState(null);
	const [temporarySessions, setTemporarySessions] = useState([]);
	const [openWarningLeavePage, setOpenLeavePageWarning] = useState(null);
	const [rescheduling, setRescheduling] = useState(false);
	const [allowShowLeavePageModal, setAllowShowLeavePageModal] = useState({ value: false });
	const [editedSection, setEditedSection] = useState(null);
	const [triggerCloseModal, setTriggerCloseModal] = useState(false);

	const scheduleFormikRef = useRef(null);

	const beforeUnloadRef = useRef();
	const backButtonRef = useRef();

	const prevTemporaryMaster = usePrevious(temporaryMaster);

	const courseList =
		useSelector(
			(state) => state?.MyCourses?.coursesNeedReschedule || state?.MyCourses?.courseNeedScheduleFromGetCourseList,
		) || [];
	const isGettingCoursesNeedReschedule = useSelector((state) => state.MyCourses.isGettingCoursesNeedReschedule);
	const activitiesReschedule = useSelector((state) => state.MyCourses?.activitiesReschedule);
	const flattenActivities = useSelector((state) => state.MyCourses?.flattenActivities);
	const getActivitiesRescheduleCourseLoading = useSelector(
		(state) => state.MyCourses?.getActivitiesRescheduleCourseLoading,
	);
	const totalActivitiesReschedule = useSelector((state) => state.MyCourses?.totalActivitiesReschedule);
	const sectionsActivityReschedule = useSelector((state) => state.MyCourses?.sectionsActivityReschedule);
	const sectionKeys = useSelector((state) => state.MyCourses?.sectionKeys);
	const sessionsActivityReschedule = useSelector((state) => state.MyCourses?.sessionsActivityReschedule);
	const arrUpdatedActivities = useSelector((state) => state.MyCourses?.arrUpdatedActivities);
	const getSectionsRescheduleActivityLoading = useSelector(
		(state) => state.MyCourses?.getSectionsRescheduleActivityLoading,
	);
	const commonErrors = useSelector((state) => get(state, 'MyCourses.error', ''));
	const rescheduleActivitySuccess = useSelector(
		(state) =>
			get(state, 'MyCourses.mcConsolidateAssignmentSuccess') ||
			get(state, 'MyCourses.mcConsolidateTestSuccess') ||
			get(state, 'MyCourses.mcConsolidateLessonSuccess'),
	);
	const isReschedulingActivity = useSelector(
		(state) =>
			get(state, 'MyCourses.mcConsolidateAssignmentLoading') ||
			get(state, 'MyCourses.mcConsolidateTestLoading') ||
			get(state, 'MyCourses.mcConsolidateLessonLoading'),
	);

	const currentIndexOfActivity = useMemo(() => {
		const currentIndex = findIndex(flattenActivities, omit(selectedActivity));

		return currentIndex;
	}, [selectedActivity, flattenActivities]);

	const currentActivityIndexOutOfTotal = useMemo(
		() =>
			totalActivitiesReschedule > 1
				? t('index_out_of_total', {
						currentIndex: currentIndexOfActivity + 1,
						total: totalActivitiesReschedule,
				  })
				: '',
		[currentIndexOfActivity, totalActivitiesReschedule, flattenActivities],
	);

	const metaDataSchedule = useMemo(() => getConfigSchedule(t)[selectedActivity?.activityType], [selectedActivity]);

	const updateTemporaryMaster = useCallback(() => {
		const values = scheduleFormikRef?.current?.values;

		if (isEmpty(selectedCourse) || isEmpty(selectedActivity) || isEmpty(values?.sections) || isEmpty(editedSection)) {
			return;
		}

		const { courseId } = selectedCourse;
		const { masterActivityId, activityType } = selectedActivity;

		const sectionPath = `[${courseId}].[${ACTIVITY_CATEGORY_NAME[activityType]}-${masterActivityId}].sections`;
		setTemporarySessions([]);
		setTemporaryMaster((prev) => {
			const currentSection = {
				...(get(prev, sectionPath, []).find((item) => item.sectionId === editedSection.sectionId) || {}),
				...editedSection,
			};

			const sectionsOmitCurrentSection = get(prev, sectionPath, []).filter(
				(item) => item.sectionId !== editedSection.sectionId,
			);

			// const hasChangedValue = !isEqual(
			//   get(prev, `[${courseId}].[${masterActivityId}].sections`, []),
			//   get(values, 'sections', [])
			// );

			// if (!hasChangedValue) return prev;

			return {
				...prev,
				[courseId]: {
					...(prev?.[courseId] || {}),
					[`${ACTIVITY_CATEGORY_NAME[activityType]}-${masterActivityId}`]: {
						sections: [...sectionsOmitCurrentSection, currentSection],
					},
				},
			};
		});
		setEditedSection(null);
	}, [selectedCourse, selectedActivity, temporaryMaster, editedSection]);

	const onSelectCourse = useCallback(
		(item) => {
			const { courseId } = item || {};
			setSelectedCourse(item);
			setReScheduleStep(RESCHEDULE_STEP.ACTIVITY);
			setRescheduling(true);

			dispatch(
				MyCoursesActions.myCoursesSetState({
					getActivitiesRescheduleCourseLoading: true,
				}),
			);
		},
		[courseList],
	);

	const onSelectActivity = useCallback((item, currentGroup) => {
		const { activityId } = item || {};
		setSelectedActivity(item);
		setReScheduleStep(RESCHEDULE_STEP.SCHEDULE);

		dispatch(MyCoursesActions.myCoursesSetState({ getSectionsRescheduleActivityLoading: true }));
	}, []);

	const onClickBack = useCallback(() => {
		setSelectedActivity((prev) => (reScheduleStep === RESCHEDULE_STEP.SCHEDULE ? null : prev));
		setSelectedCourse((prev) => (reScheduleStep === RESCHEDULE_STEP.ACTIVITY ? null : prev));
		setReScheduleStep((prev) => (isNumber(prev) && prev > RESCHEDULE_STEP.COURSE ? prev - 1 : prev));
	}, [reScheduleStep]);

	const onPrevActivity = useCallback(() => {
		if (isEmpty(flattenActivities) || currentIndexOfActivity === -1 || currentIndexOfActivity === 0) {
			return;
		}

		scheduleFormikRef.current?.resetForm();
		setSelectedActivity((prev) => flattenActivities[currentIndexOfActivity - 1] || prev);
	}, [currentIndexOfActivity, flattenActivities]);

	const onNextActivity = useCallback(() => {
		if (
			isEmpty(flattenActivities) ||
			currentIndexOfActivity === -1 ||
			currentIndexOfActivity >= size(flattenActivities) - 1
		) {
			return;
		}

		scheduleFormikRef.current?.resetForm();
		setSelectedActivity((prev) => flattenActivities[currentIndexOfActivity + 1] || prev);
	}, [currentIndexOfActivity, flattenActivities]);

	const onUpdateSchedule = () => {
		if (isEmpty(scheduleFormikRef.current)) return;

		const values = scheduleFormikRef.current?.values;
		scheduleFormikRef.current?.submitForm();
	};

	const onRescheduleSubmit = useCallback(
		(payload) => {
			if (!payload || !selectedCourse?.courseId || !selectedActivity?.masterActivityId) return;
			switch (selectedActivity?.activityType) {
				case ACTIVITY_CATEGORY.ASSIGNMENT:
					dispatch(
						MyCoursesActions.mcConsolidateAssignment({
							orgId: organizationId,
							courseId: selectedCourse.courseId,
							masterId: selectedActivity.masterActivityId,
							isEditSchoolYear: true,
							body: {
								...payload,
								confirmStudentCustomDue: true,
							},
							method: DIALOG_TYPE.RESCHEDULE,
						}),
					);
					break;

				case ACTIVITY_CATEGORY.TEST_IN_CLASS:
					dispatch(
						MyCoursesActions.mcConsolidateTest({
							orgId: organizationId,
							courseId: selectedCourse.courseId,
							masterId: selectedActivity.masterActivityId,
							isEditSchoolYear: true,
							body: {
								...payload,
							},
							method: DIALOG_TYPE.RESCHEDULE,
						}),
					);
					break;

				case ACTIVITY_CATEGORY.LESSON:
					dispatch(
						MyCoursesActions.mcConsolidateLesson({
							orgId: organizationId,
							courseId: selectedCourse.courseId,
							masterId: selectedActivity.masterActivityId,
							isEditSchoolYear: true,
							body: {
								...payload,
							},
							method: DIALOG_TYPE.RESCHEDULE,
						}),
					);
					break;

				default:
					break;
			}
		},
		[organizationId, selectedCourse, selectedActivity],
	);

	const onClickShowLeavePage = () => {
		setTriggerCloseModal(true);
	};

	const onConfirmLeavePageDialog = () => {
		onClose && onClose();
	};

	const onCancelWarningDialog = () => {
		setOpenLeavePageWarning(false);
	};

	const onAllowShowLeavePage = useCallback((editedSection, currentSectionId, key) => {
		const _editedSection =
			size(editedSection) && currentSectionId && key
				? { [key]: { ...editedSection }, sectionId: currentSectionId }
				: null;

		setAllowShowLeavePageModal({ value: true });
		setEditedSection(_editedSection);
	}, []);

	const resetDefaultStateRedux = () => {
		dispatch(
			MyCoursesActions.myCoursesSetState({
				mcConsolidateAssignmentSuccess: null,
				mcConsolidateAssignmentFailed: null,

				mcConsolidateLessonSuccess: null,
				mcConsolidateLessonFailed: null,

				mcConsolidateTestSuccess: null,
				mcConsolidateTestFailed: null,

				activitiesReschedule: [],
				totalActivitiesReschedule: 0,
				sectionsActivityReschedule: [],
				sessionsActivityReschedule: [],
				isGettingCoursesNeedReschedule: false,
				triggerModalUpdateCourseSchedule: false,
				selectedCourseToReschedule: null,
				hasProcessedAll: true,
				necessaryFetchCourses: { value: true, options: { isBusy: false } },
			}),
		);
	};

	useEffect(() => {
		// dispatch(MyCoursesActions.myCoursesSetState({ isNecessaryFetchCourses: false }));

		beforeUnloadRef.current = (event) => {
			event.preventDefault();
			localStorage.removeItem('courseNeedReschedule');
			return (event.returnValue = '');
		};

		backButtonRef.current = (event) => {
			event.preventDefault();
			localStorage.removeItem('courseNeedReschedule');
			return (event.returnValue = '');
		};

		return () => {
			resetDefaultStateRedux();
			window.removeEventListener('beforeunload', beforeUnloadRef.current);
			window.removeEventListener('popstate', backButtonRef.current);
		};
	}, []);

	useEffect(() => {
		if (allowShowLeavePageModal?.value) {
			updateTemporaryMaster();
		}
	}, [allowShowLeavePageModal, updateTemporaryMaster]);

	useEffect(() => {
		const isHavingEditValue = size(temporaryMaster) > 0;
		const prevIsHavingEditValue = size(prevTemporaryMaster) > 0;

		// To prevent remove/add event multiple unexpectedly, We just remove/add event only if there is previous value and there is no current value ( AKA: the values have changed ) .

		if (!prevIsHavingEditValue && isHavingEditValue) {
			window.addEventListener('beforeunload', beforeUnloadRef.current);
			window.addEventListener('popstate', backButtonRef.current);
		}

		if (prevIsHavingEditValue && !isHavingEditValue) {
			window.removeEventListener('beforeunload', beforeUnloadRef.current);
			window.removeEventListener('popstate', backButtonRef.current);
		}
	}, [temporaryMaster]);

	useEffect(() => {
		if (isEmpty(arrUpdatedActivities) || isEmpty(selectedCourse)) return;

		setTemporaryMaster((prev) => {
			const { courseId } = selectedCourse;
			const currentCourse = get(prev, `[${courseId}]`);

			const arrKeyActivities = Object.keys(size(currentCourse) ? currentCourse : {});

			const arrValueNeedToRemoveFromTemporaryMaster = intersection(arrUpdatedActivities, arrKeyActivities);

			if (isEmpty(arrValueNeedToRemoveFromTemporaryMaster)) return prev;

			const omitUpdatedActivitiesFromCurrentCourse = {
				[courseId]: {
					...omit(prev?.[courseId] || {}, arrValueNeedToRemoveFromTemporaryMaster),
				},
			};

			const allTemporaryValue = {
				...omit(prev, [courseId]),
				...(size(omitUpdatedActivitiesFromCurrentCourse[courseId]) ? omitUpdatedActivitiesFromCurrentCourse : {}),
			};

			return size(allTemporaryValue) ? allTemporaryValue : null;
		});
	}, [arrUpdatedActivities, selectedCourse]);

	useEffect(() => {
		if (triggerCloseModal) {
			const isHavingEditedValue = size(temporaryMaster) > 0;
			if (isHavingEditedValue) setOpenLeavePageWarning(true);
			if (!isHavingEditedValue) onConfirmLeavePageDialog();

			setTriggerCloseModal(false);
		}
	}, [triggerCloseModal, temporaryMaster]);

	useEffect(() => {
		if (commonErrors) {
			const errorMessage = commonErrors?.message ?? t('error:general_error');
			enqueueSnackbar(errorMessage, { variant: 'error' });
			dispatch(
				MyCoursesActions.myCoursesSetState({
					error: null,
				}),
			);
		}
	}, [commonErrors]);

	useEffect(() => {
		if (isEmpty(selectedActivity)) return;

		if (isEmpty(flattenActivities)) {
			setSelectedActivity([]);
			return;
		}

		const currentIndexSelectedActivity = findIndex(
			flattenActivities,
			(item) =>
				item?.masterActivityId === selectedActivity?.masterActivityId &&
				item?.activityType === selectedActivity?.activityType,
		);

		if (currentIndexSelectedActivity === -1) setSelectedActivity(flattenActivities[0]);

		if (
			currentIndexSelectedActivity >= 0 &&
			flattenActivities[currentIndexSelectedActivity].hasUpdated !== selectedActivity.hasUpdated
		)
			setSelectedActivity(flattenActivities[currentIndexSelectedActivity]);
	}, [selectedActivity, flattenActivities]);

	useEffect(() => {
		if (rescheduleActivitySuccess) {
			// enqueueSnackbar(t('updated_successfully'), { variant: 'success' });
			dispatch(
				MyCoursesActions.myCoursesSetState({
					mcConsolidateLessonSuccess: null,
					mcConsolidateTestSuccess: null,
					mcConsolidateAssignmentSuccess: null,
				}),
			);

			onNextActivity();
		}
	}, [rescheduleActivitySuccess, onNextActivity]);

	useEffect(() => {
		const courseNeedReschedule = parseJson({ json: localStorage.getItem('courseNeedReschedule'), defaultValue: {} });
		const selectedCourse = size(selectedCourseToReschedule)
			? selectedCourseToReschedule
			: size(courseNeedReschedule)
			? courseNeedReschedule
			: null;

		if (selectedCourse) {
			onSelectCourse(selectedCourse);
		}
	}, [selectedCourseToReschedule]);

	useEffect(() => {
		if (
			organizationId &&
			schoolYearSelected &&
			[RESCHEDULE_STEP.COURSE].includes(reScheduleStep) &&
			isTeacher(currentUser)
		) {
			dispatch(
				MyCoursesActions.getCoursesNeedReschedule({
					id: organizationId,
					schoolYearId: schoolYearSelected,
					isGettingCoursesNeedReschedule: true,
				}),
			);
		}
	}, [
		reScheduleStep,
		selectedCourse,
		selectedCourseToReschedule,
		organizationId,
		schoolYearSelected,
		rescheduleActivitySuccess,
	]);

	useEffect(() => {
		if (
			organizationId &&
			schoolYearSelected &&
			selectedCourse?.courseId &&
			[RESCHEDULE_STEP.SCHEDULE, RESCHEDULE_STEP.ACTIVITY].includes(reScheduleStep)
		) {
			dispatch(
				MyCoursesActions.getActivitiesRescheduleCourse({
					id: organizationId,
					schoolYearId: schoolYearSelected,
					courseId: selectedCourse.courseId,
				}),
			);
		}
	}, [reScheduleStep, selectedCourse, organizationId, schoolYearSelected, rescheduleActivitySuccess]);

	useEffect(() => {
		if (
			organizationId &&
			size(activitiesReschedule) &&
			selectedCourse?.courseId &&
			[RESCHEDULE_STEP.ACTIVITY].includes(reScheduleStep)
		) {
			dispatch(
				MyCoursesActions.getSessionsRescheduleActivity({
					id: organizationId,
					courseId: selectedCourse.courseId,
				}),
			);
		}
	}, [reScheduleStep, activitiesReschedule, organizationId, selectedCourse]);

	useEffect(() => {
		if (
			organizationId &&
			selectedActivity?.masterActivityId &&
			selectedActivity?.activityType &&
			selectedCourse?.courseId
		) {
			dispatch(
				MyCoursesActions.getSectionsRescheduleActivity({
					id: organizationId,
					courseId: selectedCourse.courseId,
					activityId: selectedActivity.masterActivityId,
					activityType: selectedActivity.activityType,
					urlParams: {
						activityType: selectedActivity.activityType,
					},
				}),
			);
		}
	}, [selectedActivity, selectedCourse, organizationId]);

	useEffect(() => {
		if (isEmpty(selectedCourse) || isEmpty(selectedActivity) || isEmpty(temporaryMaster)) {
			setTemporarySessions([]);
			return;
		}

		const { courseId } = selectedCourse;
		const { masterActivityId, activityType } = selectedActivity;

		setTemporarySessions(
			(prev) =>
				get(temporaryMaster, [courseId, `${ACTIVITY_CATEGORY_NAME[activityType]}-${masterActivityId}`, 'sections']) ||
				prev,
		);
	}, [selectedCourse, selectedActivity, temporaryMaster]);

	useEffect(() => {
		if (!isGettingCoursesNeedReschedule && isTeacher(currentUser)) {
			if (courseList.length) {
				setTitle(t('update_schedule'));
			} else {
				setTitle(t('all_courses_have_updated_schedule'));
			}
		}
	}, [courseList, isGettingCoursesNeedReschedule]);

	const Component = useMemo(() => matchingComponent(reScheduleStep), [reScheduleStep]);
	const AdditionalHeader = useMemo(() => {
		const data = { selectedCourse, selectedActivity, amountActivities: totalActivitiesReschedule, onClickBack };
		return (
			<Stack gap={2}>
				{reScheduleStep === RESCHEDULE_STEP.SCHEDULE &&
					!getSectionsRescheduleActivityLoading &&
					selectedActivity?.activityType === ACTIVITY_CATEGORY.ASSIGNMENT && (
						<Box className={classes.boxTooltip}>
							<TblIcon icon='info' />
							<Typography sx={{ color: (theme) => theme.newColors.blue[900] }}>
								{t('update_apply_to_all_tooltip')}
							</Typography>
						</Box>
					)}
				{matchingHeaderNavigator(reScheduleStep, data)}
			</Stack>
		);
	}, [
		reScheduleStep,
		selectedCourse,
		selectedActivity,
		totalActivitiesReschedule,
		getSectionsRescheduleActivityLoading,
		onClickBack,
	]);

	return (
		<>
			<LeavePageModal
				openWarningLoadingProcess={openWarningLeavePage}
				onCancelWarningDialog={onCancelWarningDialog}
				onConfirmLeavePageDialog={onConfirmLeavePageDialog}
			/>
			<BaseModal
				size={reScheduleStep === RESCHEDULE_STEP.SCHEDULE ? 'xLarge' : 'medium'}
				open={open}
				title={title}
				slotProps={{ header: { gap: '16px' } }}
				additionalComponentHeader={AdditionalHeader}
				onClose={onClickShowLeavePage}
				customFooter={
					<Footer
						isDisablePrev={currentIndexOfActivity === 0}
						isDisableNext={currentIndexOfActivity === totalActivitiesReschedule - 1}
						hasUpdated={!!selectedActivity?.hasUpdated}
						amountOfCourse={courseList?.length}
						isLoading={
							isGettingCoursesNeedReschedule ||
							(getActivitiesRescheduleCourseLoading && reScheduleStep === RESCHEDULE_STEP.ACTIVITY) ||
							(getSectionsRescheduleActivityLoading && reScheduleStep === RESCHEDULE_STEP.SCHEDULE)
						}
						{...{
							reScheduleStep,
							currentActivityIndexOutOfTotal,
							isReschedulingActivity,
							onClose,
							onUpdateSchedule,
							onPrevActivity,
							onNextActivity,
						}}
					/>
				}
				contentComponent={
					<Box display={'flex'} flexDirection={'column'}>
						{courseList.length || rescheduling ? (
							<Component
								activities={activitiesReschedule}
								sections={sectionsActivityReschedule}
								sessions={sessionsActivityReschedule}
								amountActivities={totalActivitiesReschedule}
								hasUpdated={!!selectedActivity?.hasUpdated}
								onSubmit={onRescheduleSubmit}
								{...{
									organizationId,
									totalActivitiesReschedule,
									getActivitiesRescheduleCourseLoading,
									getSectionsRescheduleActivityLoading,
									courseList,
									selectedCourse,
									selectedActivity,
									isGettingCoursesNeedReschedule,
									onSelectCourse,
									onSelectActivity,
									onClickBack,
									onPrevActivity,
									onNextActivity,
									onAllowShowLeavePage,
									scheduleFormikRef,
									temporaryMaster,
									temporarySessions,
									metaDataSchedule,
									rescheduling,
									sectionKeys,
								}}
							/>
						) : (
							<Box>
								<Typography
									component={'div'}
									variant='bodyMediumRegular'
									sx={{ color: (theme) => theme.newColors.gray[800] }}
								>
									{t('all_courses_have_updated_schedule_content', { ns: 'myCourses' })}
								</Typography>
							</Box>
						)}
					</Box>
				}
			/>
		</>
	);
}

UpdateScheduleModal.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	organizationId: PropTypes.number,
	schoolYearSelected: PropTypes.number,
};

UpdateScheduleModal.defaultProps = {
	open: false,
	organizationId: 0,
	schoolYearSelected: 0,
};
export default UpdateScheduleModal;
