import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	rightContentRoot: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		alignItems: 'flex-end',
		justifyContent: 'flex-start',
		'& .title': {
			color: theme.newColors.gray[800],
			fontWeight: theme.fontWeight.semi,
			fontSize: theme.fontSize['text-xs'],
		},
	},
	emptyText: {
		color: theme.newColors.gray[800],
		fontSize: theme.fontSize['text-sm'],
		fontWeight: theme.fontWeight.semi,
	},
	commentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		'& .MuiPaper-root ': {
			background: 'transparent',
		},
	},
	hide: {
		display: 'none',
	},
	boxItem: {
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
		backgroundColor: 'white',
		border: '1px solid #E9ECEF',
		borderRadius: theme.spacing(2),
	},
	attemptList: {
		width: '100%',
		'& .MuiPaper-root ': {
			background: 'transparent',
		},
	},
}));

export { useStyles };
