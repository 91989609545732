import { styled } from '@mui/material';

import { Box } from 'ella-storybook';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	hideScroll: {
		div: {
			overflow: 'visible',
		},
	},
}));

const StyledActivityCard = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: theme.spacing(7),
	padding: `0 ${theme.spacing(1)}`,
	'&:hover': {
		cursor: 'pointer',
		backgroundColor: theme.newColors.gray[200],
		borderRadius: theme.spacing(1),
	},
}));

export { useStyles, StyledActivityCard };
