export const actions = {
	DOMAIN_SETTINGS_RESET: 'DOMAIN_SETTINGS_RESET',
	DOMAIN_SETTINGS_UPDATE_DOMAIN_SETTING: 'DOMAIN_SETTINGS_UPDATE_DOMAIN_SETTING',
	DOMAIN_SETTINGS_UPDATE_DOMAIN_SETTING_SUCCESS: 'DOMAIN_SETTINGS_UPDATE_DOMAIN_SETTING_SUCCESS',
	DOMAIN_SETTINGS_UPDATE_DOMAIN_SETTING_FAILED: 'DOMAIN_SETTINGS_UPDATE_DOMAIN_SETTING_FAILED',
	DOMAIN_SETTINGS_GET_TERMS: 'DOMAIN_SETTINGS_GET_TERMS',
	DOMAIN_SETTINGS_GET_TERMS_SUCCESS: 'DOMAIN_SETTINGS_GET_TERMS_SUCCESS',
	DOMAIN_SETTINGS_GET_TERMS_FAILED: 'DOMAIN_SETTINGS_GET_TERMS_FAILED',
	DOMAIN_SETTINGS_GET_GRADING_PERIODS: 'DOMAIN_SETTINGS_GET_GRADING_PERIODS',
	DOMAIN_SETTINGS_GET_GRADING_PERIODS_SUCCESS: 'DOMAIN_SETTINGS_GET_GRADING_PERIODS_SUCCESS',
	DOMAIN_SETTINGS_GET_GRADING_PERIODS_FAILED: 'DOMAIN_SETTINGS_GET_GRADING_PERIODS_FAILED',
	DOMAIN_SETTINGS_UPDATE_TERM: 'DOMAIN_SETTINGS_UPDATE_TERM',
	DOMAIN_SETTINGS_UPDATE_TERM_SUCCESS: 'DOMAIN_SETTINGS_UPDATE_TERM_SUCCESS',
	DOMAIN_SETTINGS_UPDATE_TERM_FAILED: 'DOMAIN_SETTINGS_UPDATE_TERM_FAILED',
	DOMAIN_SETTINGS_UPDATE_GRADING_PERIOD: 'DOMAIN_SETTINGS_UPDATE_GRADING_PERIOD',
	DOMAIN_SETTINGS_UPDATE_GRADING_PERIOD_SUCCESS: 'DOMAIN_SETTINGS_UPDATE_GRADING_PERIOD_SUCCESS',
	DOMAIN_SETTINGS_UPDATE_GRADING_PERIOD_FAILED: 'DOMAIN_SETTINGS_UPDATE_GRADING_PERIOD_FAILED',
	DOMAIN_SETTINGS_SET_REDUCER: 'DOMAIN_SETTINGS_SET_REDUCER',
};
export const END_POINT = {
	update_domain_setting: {
		url: (orgId) => `${process.env.REACT_APP_API_URL}/organization/${orgId}/setting`,
		method: 'PUT',
	},
	get_terms: {
		url: (orgId) => `${process.env.REACT_APP_API_URL}/organization/${orgId}/terms`,
		method: 'GET',
	},
	update_term: {
		url: (orgId) => `${process.env.REACT_APP_API_URL}/organization/${orgId}/terms`,
		method: 'PUT',
	},
	get_grading_periods: {
		url: (orgId) => `${process.env.REACT_APP_API_URL}/organization/${orgId}/grading-periods`,
		method: 'GET',
	},
	update_grading_period: {
		url: (orgId) => `${process.env.REACT_APP_API_URL}/organization/${orgId}/grading-periods`,
		method: 'PUT',
	},
};

export const TERMS = {
	NO_TERMS: 0,
	SEMESTERS: 2,
	TRIMESTERS: 3,
	QUARTERS: 4,
};

export const NUMBER_OF_GRADING_PERIODS = [2, 3, 4];
