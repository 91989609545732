import React, { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import trim from 'lodash/trim';

import { Stack, useTheme } from '@mui/material';

import TblEditor from 'components/TblEditor';
import TblIcon from 'components/TblIcon';
import LessonQuizScheduleDialog from 'shared/MyCourses/components/ScheduleDialog/LessonQuizScheduleDialog';

import { MAX_GOOGLE_UPLOAD_FILES } from 'utils/constants';

import MyAttachments from 'shared/Attachments/MyAttachments';
import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';
import { useSchoolYearDataContext } from 'shared/Auth/containers/SchoolYear';
import { filterGoogleFileSelected } from 'shared/Google/utils';
// import lessonActions from 'shared/Lesson/actions';
import { COURSE_STATUS, DIALOG_TYPE, TYPE_OF_CREATE } from 'shared/MyCourses/constants';
import LinkedContents from 'shared/MyCourses/containers/LinkedContents';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { checkAuthorized } from 'AppRoute/rolePermission/utils';
import { BaseModal, Box, Button, FullScreenDialog, Skeleton, TextField, Tooltip, Typography } from 'ella-storybook';
import { useFormik } from 'formik';
import { get } from 'lodash';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY, MAX_LENGTH } from 'modules/MyCourses/constants';
import { SCHOOL_YEAR_STATUS } from 'modules/SchoolYear/constants';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from 'tss-react/mui';
import { handleConvertBase64Image, replaceHTMLTag } from 'utils';
import * as Yup from 'yup';

import FormSkeleton from '../components/FormSkeleton';

const useStyles = makeStyles()((theme) => ({
	indicator: {
		width: '100%',
		marginTop: theme.spacing(3),
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content: {
		width: theme.spacing(80),
	},
	duration: {
		width: theme.spacing(15),
	},
}));

// const mediaType = 'image';
// const acceptType = 'image/*';
const VALUE = {
	DURATION_MIN: 5,
	DURATION_MAX: 240,
};

function CreateEditLesson({
	isVisible,
	onCloseDialog,
	lessonInfo,
	courseId,
	unitId,
	updateUnit,
	isViewOnly,
	shouldResetState,
	shouldCallAPItoGetModalData,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation(['myCourses', 'common']);
	const { classes } = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const organizationId = useSelector((state) => state.Auth.currentUser?.organizationId);
	const {
		permission,
		isFetchingLessonDetails,
		isEditLessonSuccess,
		isEditingLesson,
		isCreatingLesson,
		isCreateNewLessonSuccess,
		error,
	} = useSelector((state) => state.AllCourses);
	const lessonDetail = useSelector((state) => state?.AllCourses?.lessonDetail);
	const isDraft = useSelector((state) => state?.AllCourses?.basicInfo?.status === COURSE_STATUS.DRAFT);

	const schoolYearData = useSchoolYearDataContext();
	const archiveMode = schoolYearData.status === SCHOOL_YEAR_STATUS.ARCHIVED;

	// const [isVisibleMedia, setIsVisibleMedia] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [typeOfCreate, setTypeOfCreate] = useState(0);
	const [visiblePublishDialog, setVisiblePublishDialog] = useState(false);
	// const [mediaAction, setMediaAction] = useState(0);
	// const [editorSelected, setEditorSelected] = useState(0);
	// const [mediaCallback, setMediaCallback] = useState(null);
	const [linkContents, setLinkContents] = useState({
		assignmentLinkIds: [],
		lessonLinkIds: [],
		quizLinkIds: [],
		testLinkIds: [],
	});

	const [uploadingState, setUploadingState] = useState(false);

	const [attachments, setAttachments] = useState(lessonDetail?.attachments || []);

	const authContext = useContext(AuthDataContext);
	const strPermissions = get(authContext.currentUser, 'permissions', '');

	const hasPermission = checkAuthorized({
		moduleAlias: MODULE_ALIAS.ACTIVITY,
		permissions: [PERMISSIONS.COURSE_ACTIVITY.CREATE],
		strPermissions,
	});

	//User Guiding
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (!shouldResetState) return;
		return () => {
			dispatch(
				myCoursesActions.myCoursesSetState({
					error: null,
					isCreateNewLessonSuccess: false,
					isEditLessonSuccess: false,
				}),
			);
		};
	}, []);

	useEffect(() => {
		setFieldValue('attachments', attachments);
	}, [attachments]);

	useEffect(() => {
		if (size(lessonDetail?.linkContents)) {
			setLinkContents(lessonDetail?.linkContents);
		}
	}, [lessonDetail?.linkContents]);

	useEffect(() => {
		if (!shouldCallAPItoGetModalData || !isVisible) return;

		getLessonDetail();
	}, [isVisible, shouldCallAPItoGetModalData]);

	useEffect(() => {
		if (isCreateNewLessonSuccess || isEditLessonSuccess) {
			if (isEmpty(error)) {
				if (isEmpty(lessonDetail)) {
					enqueueSnackbar(t('common:object_created', { objectName: 'Lesson' }), { variant: 'success' });
				} else {
					enqueueSnackbar(t('common:change_saved'), {
						variant: 'success',
					});
				}

				const queryParams = new URLSearchParams(location.search);

				if (queryParams.has('modal')) {
					queryParams.delete('modal');
					history.replace({
						search: queryParams.toString(),
					});
				}
			}
		}
	}, [isCreateNewLessonSuccess, isEditLessonSuccess]);

	useEffect(() => {
		if (isCreateNewLessonSuccess || isEditLessonSuccess) {
			switch (typeOfCreate) {
				case TYPE_OF_CREATE.CREATE_AS_DRAFT:
					resetForm();
					onClose();
					break;
				case TYPE_OF_CREATE.CREATE_AND_PUBLISH:
					setVisiblePublishDialog(true);
					dispatch(
						myCoursesActions.myCoursesSetState({
							error: null,
							isCreateNewLessonSuccess: false,
							isEditLessonSuccess: false,
						}),
					);
					onCloseDialog();
					break;
				default:
					break;
			}

			updateUnit && updateUnit(unitId);
		}
	}, [isCreateNewLessonSuccess, isEditLessonSuccess, updateUnit, unitId]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		if (isVisible) {
			queryParams.append('modal', 'lesson');
			history.replace({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		} else {
			if (queryParams.has('modal')) {
				queryParams.delete('modal');
				history.replace({
					search: queryParams.toString(),
				});
			}
		}
	}, [isVisible]);
	/////////////////////////

	const validationSchema = Yup.object().shape({
		lessonName: Yup.string().trim().required(t('common:required_message')),
		duration: Yup.number().min(VALUE.DURATION_MIN).max(VALUE.DURATION_MAX).required(t('common:required_message')),
	});

	const getInitialValues = () => {
		let initialValues = {
			lessonName: '',
			description: '',
			duration: '',
			googleFiles: [],
		};
		if (!isEmpty(lessonDetail)) {
			Object.assign(initialValues, {
				...lessonDetail,
				description: lessonDetail.lessonContent,
			});
		}
		return { ...initialValues };
	};

	const onSubmit = (values) => {
		const payload = {
			orgId: organizationId,
			courseId,
			unitId: unitId,
			lesson: values,
			error: null,
		};

		if (!isEmpty(lessonDetail)) {
			dispatch(
				myCoursesActions.editLesson({
					...payload,
					lessonId: lessonDetail?.id,
					isEditingLesson: true,
				}),
			);
		} else {
			dispatch(
				myCoursesActions.createNewLesson({
					...payload,
					isCreatingLesson: true,
				}),
			);
		}
	};

	const formik = useFormik({
		initialValues: getInitialValues(),
		enableReinitialize: true,
		validationSchema: validationSchema,
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: async (values) => {
			const newValues = { ...values };
			const payload = Object.assign(newValues, {
				duration: values.duration ? Number(values.duration) : null,
				lessonName: trim(values.lessonName),
				lessonContent: replaceHTMLTag(values.description) === '' ? '' : values.description,
			});
			payload.description = await handleConvertBase64Image(payload.description);
			onSubmit({
				metadata: payload,
				linkContents,
				googleFiles: values.googleFiles,
				attachments: {
					mediaId: values.attachments?.map((file) => file.id) || [],
				},
			});
		},
	});

	const { values, touched, errors, setFieldValue, resetForm, submitCount, handleSubmit, handleBlur, setFieldError } =
		formik;

	const getLessonDetail = () => {
		if (!isNil(organizationId) && !isNil(courseId) && !isNil(unitId) && !isEmpty(lessonInfo)) {
			dispatch(
				myCoursesActions.getLessonDetail({
					orgId: organizationId,
					courseId,
					unitId: unitId,
					lessonId: lessonInfo?.id,
					isFetchingLessonDetails: true,
					error: null,
				}),
			);
		}
	};

	const onClose = () => {
		resetForm();

		const queryParams = new URLSearchParams(location.search);

		if (queryParams.has('modal')) {
			queryParams.delete('modal');
			history.replace({
				search: queryParams.toString(),
			});
		}

		dispatch(
			myCoursesActions.myCoursesSetState({
				error: null,
				isCreateNewLessonSuccess: false,
				isEditLessonSuccess: false,
			}),
		);

		shouldResetState &&
			dispatch(
				myCoursesActions.myCoursesSetState({
					lessonDetail: {},
				}),
			);

		onCloseDialog();
	};

	const handleChange = useCallback(
		(fieldName, value) => {
			if (value !== values[fieldName]) {
				setFieldValue(fieldName, value);
			}
		},
		[values],
	);
	// const onMediaClose = () => {
	//   setIsVisibleMedia(false);
	//   setMediaCallback(null);
	// };

	// const insertImage = (editor, value, insertImageFunc) => {
	//   setIsVisibleMedia(true);
	//   setMediaAction('insertImage');
	//   setEditorSelected(editor);
	//   setMediaCallback(() => insertImageFunc);
	// };

	// const handleMediaSelect = (media) => {
	//   if (mediaAction === 'insertImage' && editorSelected && mediaCallback) {
	//     mediaCallback(editorSelected, media.url, {
	//       class: CLASS_NAME.INIT,
	//     });
	//   }
	//   setMediaCallback(null);
	// };

	const editorChange = (content) => {
		const trimValue = replaceHTMLTag(content);
		if (touched.description || trimValue || !lessonDetail?.id) {
			handleChange('description', content);
		}
		if (!trimValue) {
			handleChange('description', '');
		}
	};

	const onAttachFile = (files, isRemove = false) => {
		if (isRemove) {
			return setAttachments(attachments.filter((f) => f.id !== files.id));
		}
		setAttachments((preVal) => [...preVal, files]);
	};

	const editorMemo = React.useMemo(
		() => (
			<TblEditor
				name='description'
				disabled={!hasPermission || archiveMode}
				label={t('common:content')}
				placeholder={t('assignment_description_placeholder')}
				// customButtons={{
				//   insertImage: {
				//     onClick: insertImage,
				//   },
				// }}
				onChange={editorChange}
				defaultValue={values.description}
				required={false}
			/>
		),
		[values.description, errors.description, touched.description, hasPermission, archiveMode],
	);

	const handleCreateAndPublish = () => {
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AND_PUBLISH);
		handleSubmit(values);
	};

	const handleCreateDraft = () => {
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AS_DRAFT);
		handleSubmit(values);
		openDialog && setOpenDialog(false);
	};

	return (
		<>
			<BaseModal
				size='small'
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				title={t('schedule_a_course_activity')}
				contentComponent={
					<Typography variant='bodyMediumRegular'>
						{t('the_course_activity_cannot_be_scheduled', { ns: 'common' })}
					</Typography>
				}
				customFooter={
					<Box className='customFooterModal'>
						<Button onClick={handleCreateDraft}>{t('create')}</Button>
					</Box>
				}
			/>
			<FullScreenDialog
				open={isVisible}
				onClose={onClose}
				title={
					isFetchingLessonDetails ? (
						<Skeleton variant='text' />
					) : !isEmpty(lessonInfo || lessonDetail) ? (
						t('edit_the_lesson')
					) : (
						t('create_a_lesson')
					)
				}
				groupButtons={
					isEmpty(lessonInfo || lessonDetail) ? (
						<Box style={{ display: 'flex', direction: 'row' }}>
							<Button
								disabled={
									(isViewOnly || isCreatingLesson || isEditingLesson || uploadingState) && hasPermission
								}
								variant='outlined'
								onClick={() => {
									setTypeOfCreate(TYPE_OF_CREATE.CREATE_AS_DRAFT);
									handleSubmit(values);
								}}
							>
								{t('create_only')}
							</Button>
							<Box width={'16px'} />
							<Button
								disabled={
									(isViewOnly || isCreatingLesson || isEditingLesson || uploadingState) && hasPermission
								}
								variant='primary'
								onClick={() => {
									if (isDraft) {
										setOpenDialog(true);
									} else {
										handleCreateAndPublish();
									}
								}}
							>
								{t('create_and_schedule')}
							</Button>
						</Box>
					) : (
						!archiveMode && (
							<Stack direction={'row'} gap={2}>
								<Button size='medium' variant='outlined' onClick={onClose}>
									{t('common:cancel')}
								</Button>
								<Button
									disabled={isCreatingLesson || isEditingLesson || isViewOnly || uploadingState}
									loading={isCreatingLesson || isEditingLesson}
									size='medium'
									variant='primary'
									onClick={() => {
										setTypeOfCreate(TYPE_OF_CREATE.CREATE_AS_DRAFT);
										handleSubmit(values);
									}}
								>
									{t('common:save')}
								</Button>
							</Stack>
						)
					)
				}
			>
				{isFetchingLessonDetails ? (
					<FormSkeleton />
				) : (
					<Box component={'form'}>
						{/* <MediaWithReducer
              visible={isVisibleMedia}
              onClose={onMediaClose}
              onSelect={handleMediaSelect}
              accept={acceptType}
              mediaType={mediaType}
            /> */}

						<Box className={classes.wrapper}>
							<Box className={classes.content}>
								<Box mt={3} width={'100%'}>
									<TextField
										required
										fullWidth={true}
										autoFocus
										name='lessonName'
										onBlur={handleBlur}
										disabled={!hasPermission || archiveMode}
										label={t('common:name')}
										placeholder={t('common:enter_name')}
										type='text'
										error={!!((touched.lessonName || submitCount) && errors.lessonName)}
										helperText={!!(touched.lessonName || submitCount) && errors.lessonName}
										inputProps={{ maxLength: MAX_LENGTH.ACTIVITY_NAME }}
										value={values?.lessonName}
										onChange={(e) => handleChange('lessonName', e.target.value)}
									/>
								</Box>
								<Box mt={3} width={'100%'}>
									<TextField
										required
										className={classes.duration}
										fullWidth={false}
										name='duration'
										value={values.duration}
										disabled={!hasPermission || archiveMode}
										label={
											<Box display={'flex'}>
												{t('duration')}
												<Tooltip
													title={`${t('duration_indicator', { activity: 'lesson' })} ${t('number_range', {
														min: VALUE.DURATION_MIN,
														max: VALUE.DURATION_MAX,
													})}`}
													placement='top'
												>
													<Box mf={'3px'} mt={'2px'}>
														<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
													</Box>
												</Tooltip>
											</Box>
										}
										type='number'
										inputProps={{ maxLength: 3 }}
										helperText={!!(touched.duration || submitCount) && errors.duration}
										error={!!((touched.duration || submitCount) && errors.duration)}
										onBlur={(e) => {
											if (!e.target.value) {
												return handleBlur(e);
											}

											let value = Number(e.target.value);
											if (value > VALUE.DURATION_MAX) {
												value = VALUE.DURATION_MAX;
											} else if (value < VALUE.DURATION_MIN) {
												value = VALUE.DURATION_MIN;
											}

											handleChange('duration', value);
											setFieldError('duration', null);
										}}
										onChange={(e) => handleChange('duration', e.target.value)}
										endAdornment={
											<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
												{t('mins')}
											</Typography>
										}
									/>
								</Box>
								<Box mt={3} width={'100%'}>
									{editorMemo}
								</Box>
								<Box mt={3.5}>
									<Typography variant='labelMedium'>{t('common:attachments')}</Typography>
									<Box mt={2}>
										<MyAttachments
											// google attachments
											initialValues={values.googleFiles}
											maxItems={MAX_GOOGLE_UPLOAD_FILES}
											onChange={(files) => setFieldValue('googleFiles', files)}
											onAdd={(googleAttachmentsSelected) => {
												const uniqSelected = filterGoogleFileSelected(values.googleFiles, googleAttachmentsSelected);
												setFieldValue('googleFiles', uniqSelected);
											}}
											archiveMode={archiveMode}
											type={ACTIVITY_CATEGORY.LESSON} // this is use for disable use template switch
											disabled={isViewOnly}
											// file attachments
											attachmentFiles={lessonDetail?.attachments}
											updateData={(files, isRemove) => onAttachFile(files, isRemove)}
											setUploadingState={setUploadingState}
											canAdd={!isViewOnly && !archiveMode}
										/>
									</Box>
									{!isViewOnly && (
										<Box mt={3.5}>
											<LinkedContents
												subtitle={t('choose_courses_activities', {
													ns: 'myCourses',
												})}
												courseActivityInfo={lessonDetail}
												initialLinkedContents={lessonDetail?.linkContents}
												updateData={(linkContents) => setLinkContents(linkContents)}
												courseIdProp={Number(courseId)}
												viewOnly={!hasPermission || archiveMode}
												archiveMode={archiveMode}
												emptyContent={t('myCourses:no_linked_contents')}
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</FullScreenDialog>

			{visiblePublishDialog && (
				<LessonQuizScheduleDialog
					visible={visiblePublishDialog}
					toggleCloseDialog={() => {
						resetForm();
						setVisiblePublishDialog(false);
					}}
					type={ACTIVITY_CATEGORY.LESSON}
					activityId={lessonDetail?.id}
					name={lessonDetail?.lessonName}
					unitId={Number(unitId)}
					courseId={Number(courseId)}
					method={DIALOG_TYPE.SCHEDULE}
					updateUnit={updateUnit}
				/>
			)}
		</>
	);
}

CreateEditLesson.defaultProps = {
	// lessonInfo: {},
	lesson: {},
	isViewOnly: false,
	shouldResetState: true,
	shouldCallAPItoGetModalData: true,
};

CreateEditLesson.propTypes = {
	isVisible: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	onCloseDialog: PropTypes.func,
	// lessonInfo: PropTypes.object,
	courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	unitId: PropTypes.number,
	updateUnit: PropTypes.func,
	lessonViewData: PropTypes.object,
	shouldCallAPItoGetModalData: PropTypes.bool,
};

export default CreateEditLesson;
