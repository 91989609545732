export default (theme, props) => ({
	root: {
		fontSize: theme.fontSize['text-sm'],
		borderRadius: theme.spacing(1),
		padding: theme.spacing(0, 2),
		zIndex: theme.zIndex.drawer - 2,
		position: 'relative',
		width: props.openSideBar ? theme.sideBar.openWidth : theme.sideBar.width,

		'& svg path': {
			fill: theme.newColors.gray[600],
		},
	},
	drawerHoverOpen: {
		transition: theme.transitionDefault,
		width: !props.openSideBar && theme.sideBar.openWidth,
	},
	drawerHoverClose: {
		transition: theme.transitionDefault,
		width: !props.openSideBar && theme.sideBar.width,
	},
	drawerOpen: {
		transition: theme.transitionDefault,
		width: theme.sideBar.openWidth,
		border: 'none',
		overflowX: 'hidden',
	},
	drawerClose: {
		transition: theme.transitionDefault,
		border: 'none',
		overflowX: 'hidden',
		width: theme.sideBar.width,
		[theme.breakpoints.up('sm')]: {
			width: theme.sideBar.width,
		},
		'& .divider-text': {
			opacity: 0,
		},
	},
	paper: {
		backgroundColor: 'white',
		borderRight: `2px solid ${theme.newColors.gray[100]}`,
		position: 'fixed',
		top: theme.navbar.appBar,
		height: `calc(100vh - ${theme.navbar.appBar}px)`,
	},
	list: {
		height: `calc(100vh - ${theme.navbar.appBar} )`,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		transition: theme.transitionDefault,
		maxWidth: '100%',
		paddingTop: 0,
		margin: theme.spacing(2, 0),
		padding: theme.spacing(0, 1.25),
		'& .MuiListItem-root': {
			margin: theme.spacing(0, 1.25, 1),
			padding: props.openSideBar ? theme.spacing(2, 1.75) : theme.spacing(1.5),
			borderRadius: theme.spacing(1.5),
			height: 48,
			'&:hover': {
				backgroundColor: theme.newColors.gray[100],
			},
		},
		'& .MuiListItemIcon-root': {
			minWidth: 0,
		},
		'& .MuiListItemText-root': {
			marginLeft: theme.spacing(1.5),
			whiteSpace: 'nowrap',
			overflow: ' hidden !important',
			textOverflow: 'ellipsis',
		},
		'& .MuiListItem-root.Mui-selected': {
			backgroundColor: theme.newColors.primary[50],
			color: theme.newColors.primary[500],
			'& .icon-bar_chart_4_bars': {
				color: theme.newColors.primary[500],
			},
			'& svg path': {
				fill: theme.newColors.primary[500],
			},
			'&:hover': {
				backgroundColor: theme.newColors.primary[50],
			},
			'&::before': {
				position: 'absolute',
				display: 'block',
				top: '18%',
				left: '-10px',
				content: '""',
				width: '4px',
				height: '32px',
				backgroundColor: theme.newColors.primary[500],
				borderTopRightRadius: theme.spacing(1),
				borderBottomRightRadius: theme.spacing(1),
			},
		},
	},
	'& .MuiListItemIcon-root': {
		minWidth: 'auto',
	},
	'& .MuiListItemText-root': {
		marginLeft: theme.spacing(2),
		whiteSpace: 'nowrap',
		overflow: ' hidden !important',
		textOverflow: 'ellipsis',
	},
});
