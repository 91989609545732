const colors = {
	primary1: ['#27272A', '#4b4a69', '#37364d', '#2A293D', '#262537'],
	primary2: ['#258fd6', '#2eabff', '#1f82c4'],
	primary3: ['#a5a4bf', '#bbbad1', '#9796b5'],
	red: ['#d64531', '#dd5d4b', '#bf2510'],
	green: ['#2fb800', '#52c22c', '#269900'],
	yellow: ['#feca2e', '#ffdb6f', '#fabf0d'],
	orange: ['#f59a1b', '#ffb857', '#ef8c00'],
	purple: ['#6454c0', '#7565d0', '#523ec4'],
	magenta: ['#c377e0', '#ce93e5', '#bb66db'],
	blue: ['#1818ef', '#3636ff', '#0606d9', '#e6f7ff'],
	sky: ['#84c6e8', '#97d4f4', '#6dbde6'],
	cyan: ['#81d2db', '#a5e0e6', '#67ced9'],
	blueGray: ['#e1e3ea', '#e5e8ef', '#d3d6e0'],
	gray: ['#ffffff', '#f6f6f7', '#f2f2f2', '#ecedf0', '#e9e9f0', '#e5e5e5', '#c3c3c3', '#8e8e8e', '#7c7c7c', '#2f2f2f'],
	tertiary: ['#000000', '#d8c255', '#96baa7', '#107a4a', '#e3924f', '#685a7f', '#a3a0fb', '#97a0af', '#fff2c5'],
	btn1: ['#efeff4', '#e6e6ed'],
	btn2: ['#e6f7ff', '#d8ecf6'],
	input: {
		default: '#212529',
	},

	status: {
		active: '#258fd6',
		pending: '#f59a1b',
		suspened: '#7c7c7c',
		expired: '#f2f2f2',
		locked: '#d64531',
		draft: '#107a4a',
		archived: '#c3c3c3',
	},
	assignmentStatus: {
		turned_in: '#258fd6',
		in_progress: '#f2f2f2',
		missing: '#d64531',
		missed: '#d64531',
		not_started: '#7c7c7c',
		graded: '#258fd6',
	},
	customColors: ['#D7D9E0', '#E5E7EB'],
	planningStatus: {
		draft: '#dd5d4b',
		published: '#52c22c',
	},
};

export default colors;
