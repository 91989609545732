import React from 'react';

import EmptyContent from 'components/EmptyContent';

import emptyImage from 'assets/images/task-empty-list.svg';
import { Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	emptyVideo: {
		minHeight: '320px',
		height: '50vh',
		width: 'auto',
		maxWidth: '100%',
	},
	subTitle: {
		color: theme.newColors.gray[900],
	},
}));

export default function EmptyContentForStudent({ scrollable, subTitle, ...props }) {
	const { classes } = useStyles();
	return (
		<EmptyContent
			scrollable={scrollable}
			emptyImage={emptyImage}
			width={234}
			height={219}
			subTitle={
				<Typography variant='labelMedium' className={classes.subTitle}>
					{subTitle}
				</Typography>
			}
			{...props}
		/>
	);
}

EmptyContentForStudent.propTypes = {
	scrollable: PropTypes.bool,
	subTitle: PropTypes.string,
};

EmptyContentForStudent.defaultProps = {
	scrollable: false,
};
