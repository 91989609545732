import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import IOSDownload from 'assets/images/app-store.png';
import AppIcon from 'assets/images/ella_logo.svg';
import AndroidDownload from 'assets/images/google-play.png';
import { Typography } from 'ella-storybook';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
	container: {
		padding: theme.spacing(4),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		flexDirection: 'column',
	},
	boxLogo: {
		marginBottom: theme.spacing(2.5),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		padding: theme.spacing(2),
		maxWidth: '100%',
	},
	boxDes: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: theme.spacing(2.5),
	},
	boxContainerIosCH: {
		marginBottom: theme.spacing(2.5),
	},
	boxContainerFooter: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
}));
function MobileDownloadScreen() {
	const { classes } = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('auth');
	return (
		<Box className={classes.container}>
			<Box className={classes.boxLogo}>
				<img src={AppIcon} alt='Ella Logo' className={classes.logo} />
			</Box>
			<Box className={classes.boxDes}>
				<Typography
					style={{
						marginBottom: theme.spacing(1),
						fontSize: '28px',
						fontWeight: theme.fontWeight.bold,
						color: '#1F2937',
					}}
				>
					Download our app
				</Typography>
				<Typography
					style={{
						marginBottom: theme.spacing(0.5),
						fontSize: '16px',
						fontWeight: theme.fontWeight.normal,
						color: '#1F2937',
						textAlign: 'center',
					}}
				>
					Please tap the operating system corresponding to your device below to download.
				</Typography>
			</Box>
			<Box className={classes.boxContainerIosCH}>
				<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
					<a href={process.env.REACT_APP_IOS_DOWNLOAD_URL}>
						<img src={IOSDownload} alt='AppStore Logo' width='100%' />
					</a>
				</Box>
				<br />
				<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
					<a href={process.env.REACT_APP_ANDROID_DOWNLOAD_URL}>
						<img src={AndroidDownload} alt='CHPlay Logo' width='100%' />
					</a>
				</Box>
			</Box>
			<Box className={classes.boxContainerFooter}>
				<Typography style={{ fontSize: '16px', fontWeight: 400, color: '#000000' }}>Sincerely,</Typography>
				<Typography style={{ fontSize: '16px', fontWeight: 700, color: '#000000' }}>Ella team.</Typography>
			</Box>
		</Box>
	);
}
export default MobileDownloadScreen;
