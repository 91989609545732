import { useEffect, useRef } from 'react';

import { ACTIVITY_DETAIL_MODE } from 'modules/MyCourses/constants';

const useEditMode = (queryParams, getActivitySuccess, setState) => {
	const mode = +queryParams.get('mode');
	const modeRef = useRef();

	useEffect(() => {
		if (getActivitySuccess && [ACTIVITY_DETAIL_MODE.EDIT].includes(mode) && modeRef.current !== mode) {
			setState.setShowActivityModal(true);

			modeRef.current = mode;
		}
	}, [queryParams, getActivitySuccess]);
};

export default useEditMode;
