import { Typography } from 'ella-storybook';
import PropTypes from 'prop-types';

const { Stack, Chip } = require('@mui/material');

// const { Chip } = require('ella-storybook');
const { get } = require('lodash');

const StackChipNavigator = ({ arrItem = [], selectedChip, sx }) => {
	return (
		<Stack direction='row' spacing={1} sx={{ ...sx }}>
			{arrItem.map((item, index) => {
				const selected =
					selectedChip === index ? { backgroundColor: (theme) => theme.newColors.primary[500], color: 'white' } : {};

				return (
					<Chip
						label={<Typography variant={'labelMedium'}>{get(item, 'label', '')}</Typography>}
						// color={get(item, 'color', 'secondary')}
						value={index}
						onClick={(event) => {
							get(item, 'handleChangeChip', () => {})(event, index);
						}}
						sx={{
							backgroundColor: 'transparent',
							color: (theme) => theme.newColors.gray[600],
							...selected,
							':hover': { backgroundColor: (theme) => theme.newColors.primary[500], color: 'white' },
						}}
					/>
				);
			})}
		</Stack>
	);
};

StackChipNavigator.propTypes = {
	arrItem: PropTypes.array,
};

export default StackChipNavigator;
