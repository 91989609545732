import size from 'lodash/size';

import { OPTIONS_STATUS, PROGRESS_STATUS } from './constants';

const isValidNumber = (value) => {
	if (![null, '', undefined].includes(value) && +value === 0) return true;

	if (!value || isNaN(+value)) return false;

	return true;
};

const getTableConfigs = (cols) => {
	const accessors = [];
	const groupHeaders = [];

	const convertData = (
		{
			accessor,
			columns,
			disabled,
			isHoverShowAdornment,
			disabledEdit,
			alignData,
			width,
			header,
			status,
			stickyLeft,
			inputType,
			maxLength,
			mappingOption,
			numberProps,
			validStatusOption,
			hiddenSelectStatus,
			headerWeight,
			deltaWidth,
			extraCredit,
			endAdornment,
			renderCell,
			...rest
		},
		idx,
		disabledFromParent,
	) => {
		if (accessor) {
			accessors.push({
				accessor,
				disabled: disabledFromParent || disabled,
				isHoverShowAdornment,
				disabledEdit,
				status,
				alignData,
				width,
				stickyLeft,
				inputType,
				maxLength,
				mappingOption,
				numberProps,
				validStatusOption,
				hiddenSelectStatus,
				renderCell,
				endAdornment,
			});
		}

		if (header !== undefined) {
			groupHeaders[idx] = [
				...(groupHeaders?.[idx] ?? []),
				{
					...rest,
					isShowTooltip: rest.isShowTooltip,
					maxWidth: rest.maxWidth,
					variant: rest.variant,
					disabled,
					header,
					stickyLeft,
					headerWeight,
					deltaWidth,
					extraCredit,
				},
			];
		}

		if (size(columns)) {
			columns?.forEach((col) => convertData(col, idx + 1, disabledFromParent || disabled));
		}
	};

	cols.forEach((col) => convertData(col, 0, col?.disabled));

	return { accessors, groupHeaders };
};

const renderBackground = ({ status, disabled, disabledEdit }) => {
	// if (disabledEdit) return 'white';
	if (disabledEdit) {
		switch (status) {
			case PROGRESS_STATUS.MISSING:
				return '#FEF2F2';
			case PROGRESS_STATUS.MISSED:
				return '#E5E7EB';
			case PROGRESS_STATUS.TURN_IN:
				return '#f0fdf4';
			case PROGRESS_STATUS.LATE_TURN_IN:
				return '#FFFBEB';
			default:
				return 'white';
		}
	}
	if (disabled) return '#F9FAFB';
	switch (status) {
		// case PROGRESS_STATUS.MISSING:
		//   return themes.newColors.red[50];
		// case PROGRESS_STATUS.MISSED:
		//   return themes.newColors.gray[200];
		// case PROGRESS_STATUS.TURN_IN:
		//   return themes.newColors.green[50];
		// case PROGRESS_STATUS.LATE_TURN_IN:
		//   return themes.newColors.yellow[50];
		case PROGRESS_STATUS.MISSING:
			return '#FEF2F2';
		case PROGRESS_STATUS.MISSED:
			return '#E5E7EB';
		case PROGRESS_STATUS.TURN_IN:
			return '#f0fdf4';
		case PROGRESS_STATUS.LATE_TURN_IN:
			return '#FFFBEB';
		default:
			return 'white';
	}
};

const renderColor = ({ status }) => {
	switch (status) {
		case PROGRESS_STATUS.MISSING:
			return '#DC2626';
		case PROGRESS_STATUS.TURN_IN:
			return '#16a34a';
		case PROGRESS_STATUS.LATE_TURN_IN:
			return '#D97706';
		default:
			return '#1F2937';
	}
};

const CreateResizableColumn = (col, divResize) => {
	let x = 0;
	let w = 0;

	const mouseDownHandler = (e) => {
		x = e.clientX;
		const styles = window.getComputedStyle(col);

		w = parseInt(styles.width, 10);

		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);

		divResize.classList.add('resizing');
	};

	const mouseMoveHandler = (e) => {
		const dx = e.clientX - x;

		col.style.width = `${w + dx}px`;
		divResize.classList.remove('resizing');
	};

	const mouseUpHandler = () => {
		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);
	};

	divResize.addEventListener('mousedown', mouseDownHandler);
};

const focusElementById = (goToElement, currentElement) => {
	const element = (id) => document.getElementById(id);

	const getElement = element(goToElement);

	if (getElement) {
		getElement.focus();
	} else if (currentElement) {
		const getElement = element(currentElement);

		getElement?.focus();
	}
};

const blurElementById = (goToElement, currentElement) => {
	const element = (id) => document.getElementById(id);

	const getElement = element(goToElement);

	if (getElement) {
		getElement.blur();
	} else if (currentElement) {
		const getElement = element(currentElement);

		getElement?.blur();
	}
};

const isFloat = (number) => !Number.isInteger(number) && Number.isFinite(number);

const formatStringToNumber = (value) => parseFloat(value.replaceAll(',', ''));

const formatNumberToString = (value, options) => {
	const { min, max, allowNegative = false } = options || {};

	let _value = value;

	if (!allowNegative) {
		_value = _value.replace('-', '');
	}

	if (!isValidNumber(_value)) return _value;

	const number = formatStringToNumber(_value);

	if (min !== undefined && number < min) {
		return min;
	}

	if (max !== undefined && number > max) {
		return max;
	}

	const splitNum = _value.split('.');
	const index = (splitNum || []).indexOf(splitNum[1]);

	if (index !== -1) {
		const omitFirstZero = splitNum[0];
		const omitLastZero =
			splitNum[1].length > 1 && splitNum[1].endsWith('0')
				? splitNum[1].slice(0, splitNum[1].length - 1)
				: splitNum[1].slice(0, 2);
		return [omitFirstZero, omitLastZero].join('.');
	}

	return _value;
};

const optionStatus = Object.entries(OPTIONS_STATUS)
	.filter(([value]) => parseInt(value) !== PROGRESS_STATUS.MISSED)
	.map(([value, option]) => ({ value: parseInt(value), ...option }));

const getLabelOption = (label, mapping) => mapping?.label ?? label;

export {
	getTableConfigs,
	getLabelOption,
	focusElementById,
	blurElementById,
	renderBackground,
	renderColor,
	CreateResizableColumn,
	formatNumberToString,
	formatStringToNumber,
	isValidNumber,
	optionStatus,
};
