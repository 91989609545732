import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import styles from './styled';

class LayoutContent extends React.PureComponent {
	render() {
		return (
			<Grid container spacing={0}>
				{React.Children.map(this.props.children, (child) => {
					const { grid, boxStyles, background, children } = child.props;
					return (
						<Grid item {...(grid ?? { xs: 12, sm: 12 })} style={{ background: background ?? 'white' }}>
							<Box component='div' {...(boxStyles ?? { pt: 2, pl: 5, pr: 5 })}>
								{children}
							</Box>
						</Grid>
					);
				})}
			</Grid>
		);
	}
}

LayoutContent.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node,
};

export default withStyles(LayoutContent, styles);
