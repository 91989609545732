import React from 'react';

import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import Loading from 'components/TblLoading';
import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { Routing } from 'utils/middlewareRoute';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

import { EmptyStudentPage } from './components/ErrorPage';
import { ROUTE_GRADE_REPORT } from './contantsRoute';
import reducer from './reducers';

const GradeReport = loadable(() => import(/* webpackChunkName: "LazyGradeReport" */ './containers'), {
	fallback: <Loading />,
});

export default [
	{
		path: [
			ROUTE_GRADE_REPORT.DEFAULT,
			ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(':studentId'),
			ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT(),
		],
		component: withReducer(
			'GradeReport',
			reducer,
		)(
			Routing(
				{
					defaultPath: ROUTE_GRADE_REPORT.DEFAULT,
				},
				{
					pathWithQuery: ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(':studentId'),
					redirectToThis: (studentId) => ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(studentId),
				},
				{
					errorPath: ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT(),
					errorRedirect: () => ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT(),
					ErrorComponent: EmptyStudentPage,
				},
				GradeReport,
			),
		),
		private: true,
		exact: true,
		menu: {
			title: 'Grades',
			group: 'noname',
			icon: SummarizeOutlinedIcon,
			order: 3,
		},
		permissions: [PERMISSIONS.GRADING.READ_PUBLIC],
		moduleAlias: MODULE_ALIAS.GRADING,
	},
	// {
	// permissions: [PERMISSIONS.GRADING.READ_PUBLIC],
	// moduleAlias: MODULE_ALIAS.gr,
	// 	path: [
	// ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(':studentId'),
	// ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT(),
	// 	],
	// 	component: withReducer(
	// 		'GradeReport',
	// 		reducer,
	// 	)(
	// 		GuardianMW(
	// 			{
	// 				defaultPath: ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(':studentId'),
	// 				defaultRedirect: (studentId) => ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(studentId),
	// 				DefaultComponent: GradeReport,
	// 			},
	// 			{
	// errorPath: ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT(),
	// errorRedirect: () => ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT(),
	// ErrorComponent: EmptyStudentPage,
	// 			},
	// 		),
	// 	),
	// 	roles: [GUARDIAN],
	// 	menu: {
	// 		title: 'Grades',
	// 		group: 'noname',
	// 		icon: SummarizeOutlinedIcon,
	// 		order: 3,
	// 	},
	// 	exact: true,
	// 	private: true,
	// 	getSelectedPath: (context) => {
	// 		if (isGuardian(context?.currentUser)) {
	// 			if (!!context?.currentStudentId) {
	// 				return ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT(context.currentStudentId);
	// 			}
	// 			return ROUTE_GRADE_REPORT.GUARDIAN_VIEW_GRADE_REPORT_NO_STUDENT();
	// 		}
	// 		return ROUTE_GRADE_REPORT.DEFAULT;
	// 	},
	// },
];
