export default {
	mcEditCellGradeBookSuccess: null,
	gradeBookColumn: [],
	gradeBookRow: [],
	gradeBookParticipation: [],
	gradeBookActivities: [],
	gradeBookColumnLatest: [],
	gradeBookRowLatest: [],
	isFetchingGradeBook: false,
	isFetchingSubmissionToRelease: false,
	arrActivities: [],
	arrParticipation: [],
	arrNoCategories: [],
	isFetchingSubmissionToReleaseSuccess: null,

	gradeBookGridMatrix: [],
	referenceGridColumns: [],
	referenceGridRows: [],
	studentPayload: [],
	mcCalculateOverallCourseGradeSuccess: null,

	unitInfo: null,

	dataGradeBookGridMatrixUpdate: [],
};
