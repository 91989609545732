import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import PreviewFile from 'components/PreviewFile';

import CommonActions from 'shared/Common/actions';

import { isEmpty } from 'lodash';
import { ordinal } from 'modules/MyTasks/utils';

import { MEDIA_TYPES } from '../../../shared/Media/constants';
import useDownLoadGoogleFile from '../../../utils/customHook/useDownLoadGoogleFile';
import PreviewFileToolBar from '../components/LeftContent/PreviewFileToolBar';

import { useStyles } from './styles';

function GraderLeftContent() {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	const url = useSelector((state) => state.Common.url);
	const fileInformation = useSelector((state) => state.Common.fileInformation);
	const selectedAttempt = useSelector((state) => state.Grader.selectedAttempt) || {};
	const attemptIndex = useSelector((state) => state.Grader.attemptIndex) || {};

	const selectedFile = useSelector((state) => state.Grader.selectedFile) || {};
	const isUnlimited = useSelector((state) => state.Grader.isUnlimited);
	const studentAttempt = useSelector((state) => state.Grader.studentAttempts);

	const [selectedAttachment, setSelectedAttachment] = useState();
	const [fullScreen, setFullScreen] = useState(false);

	const downloadGoogleFile = useDownLoadGoogleFile(selectedAttachment);

	const hasAttachments = studentAttempt.find((item) => item?.studentSubmittedFiles.length);

	//---------------HOOK-----------------

	useEffect(() => {
		if (!isEmpty(selectedFile)) {
			return setSelectedAttachment(selectedFile);
		}
		setSelectedAttachment(null);
		dispatch(
			CommonActions.getLinkToViewFailed({
				url: null,
				fileInformation: null,
				isFetchingFile: false,
			}),
		);
	}, [selectedFile]);

	useEffect(() => {
		if (!isEmpty(selectedAttachment)) {
			const payload = {
				filename: selectedAttachment.filename,
				originalname: selectedAttachment.originalName,
				mimetype: selectedAttachment.mimetype,
			};
			dispatch(CommonActions.getLinkToView({ param: payload, isFetchingFile: true }));
		}
	}, [selectedAttachment]);

	//------------------------------------

	const onFullScreen = () => {
		setFullScreen(!fullScreen);
	};

	// const handleKey = (e) => {
	//   const { key } = e;
	//   if (!checkKeyPressNotInTextArea(e)) {
	//     return;
	//   }
	//   const KEY_LEFT = ['65', 'a'];
	//   const KEY_RIGHT = ['68', 'd'];
	//   if (KEY_LEFT.includes(String(key))) {
	//     onPreviousPreViewFile();
	//   }
	//   if (KEY_RIGHT.includes(String(key))) {
	//     onNextPreViewFile();
	//   }
	// };
	// useEventListener('keydown', handleKey);

	const onNextPreViewFile = () => {
		const index = selectedAttempt?.studentSubmittedFiles?.findIndex((f) => f?.id === selectedAttachment?.id);
		if (selectedAttempt?.studentSubmittedFiles[index + 1]) {
			setSelectedAttachment(selectedAttempt?.studentSubmittedFiles[index + 1]);
		}
	};
	const onPreviousPreViewFile = () => {
		const index = selectedAttempt?.studentSubmittedFiles?.findIndex((f) => f?.id === selectedAttachment?.id);
		if (selectedAttempt?.studentSubmittedFiles[index - 1]) {
			setSelectedAttachment(selectedAttempt?.studentSubmittedFiles[index - 1]);
		}
	};

	const downloadFile = () => {
		if (selectedAttachment.mediaType !== MEDIA_TYPES.GOOGLE_DRIVE_FILE) {
			const payload = {
				filename: selectedAttachment.filename,
				originalname: selectedAttachment.originalName,
				mimetype: selectedAttachment.mimetype,
			};
			dispatch(CommonActions.download({ param: payload }));
		} else {
			downloadGoogleFile(selectedAttachment);
		}
	};

	return (
		<Box className={classes.leftContentRoot}>
			<PreviewFileToolBar
				downloadFile={downloadFile}
				onFullScreen={onFullScreen}
				file={selectedAttachment}
				onNext={onNextPreViewFile}
				onPrevious={onPreviousPreViewFile}
				attemptTitle={!isUnlimited && isFinite(attemptIndex) ? ordinal(attemptIndex) : undefined}
			/>
			<Box className={classes.previewFileContainer}>
				<PreviewFile
					currentFile={selectedAttachment}
					fileType={fileInformation?.mimetype ?? null}
					url={url}
					isFullScreen={fullScreen}
					onClose={onFullScreen}
					fileName={fileInformation?.originalname ?? null}
					onNext={onNextPreViewFile}
					onPrevious={onPreviousPreViewFile}
					fileInformation={fileInformation}
					isRenderControlButton={false}
					hasAttachments={hasAttachments}
				/>
			</Box>
		</Box>
	);
}

export default React.memo(GraderLeftContent);
