const styles = (theme) => ({
	body: {
		overflow: 'hidden',
		color: theme.mainColors.primary1[0],
	},
	'video:focus': {
		outline: 'none',
	},
	'.word-break': {
		wordBreak: 'break-word',
	},
	'.text-ellipsis': {
		whiteSpace: 'nowrap',
		overflow: ' hidden !important',
		textOverflow: 'ellipsis',
	},
	'.text-ellipsis-2row': {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
	},
	'.text-ellipsis-3row': {
		overflow: 'hidden',
		wordBreak: 'break-word',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '3',
		WebkitBoxOrient: 'vertical',
	},
	'.title-container': {
		// height: theme.spacing(5.5),
		lineHeight: theme.spacing(5.5),
		color: theme.palette.primary.main,
		paddingLeft: theme.spacing(1),
		alignItems: 'center',
	},
	'.tbl-circular-progress__container': {
		position: 'relative',
	},
	'.display-flex': {
		display: 'flex',
	},
	'.tbl-circular-progress__item': {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: theme.spacing(-1.5),
		marginLeft: theme.spacing(-1.5),
	},
	'.cursor-pointer': {
		cursor: 'pointer',
	},
	'.tbl-link': {
		color: theme.mainColors.primary2[0],
	},

	'.tbl-scroll': {
		'&::-webkit-scrollbar': {
			width: '6px',
			height: '6px',
		},
		'&::-webkit-scrollbar-track': {
			// background: '#f1f1f1'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#999',
			borderRadius: '6px',
			transition: 'background-color .2s linear, width .2s ease-in-out',
		},
		'&:hover::-webkit-scrollbar': {
			width: '10px',
			height: '12px',
			cursor: 'pointer',
			// background: '#555'
		},
	},
	'.custom-scroll': {
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '8px',
			height: '8px',
			background: 'transparent',
		},
		'&::-webkit-scrollbar-track': {
			// background: '#f1f1f1'
		},
		'&::-webkit-scrollbar-thumb': {
			visibility: 'hidden',
			backgroundColor: 'transparent',
			background: '#BBB',
			borderRadius: '6px',
			transition: 'background-color .2s linear, width .2s ease-in-out',
		},
		// '&:hover::-webkit-scrollbar': {
		// 	width: '10px',
		// 	height: '10px',
		// 	cursor: 'pointer',
		// 	// background: '#555'
		// },
		'&:hover::-webkit-scrollbar-thumb': {
			visibility: 'visible',
			width: '8px',
			height: '8px',
			cursor: 'pointer',
			background: '#DADCE0',
			// background: '#555'
		},
		'&:focus::-webkit-scrollbar-thumb': {
			cursor: 'pointer',
			background: 'red',
		},
	},
	'.MuiMenuItem-root': {
		'& .hidden': {
			display: 'none',
		},
		minWidth: '120px',
	},
	'.emptyText': {
		color: theme.newColors.gray[700],
	},
	'.errorText': {
		fontSize: theme.fontSize['text-xs'],
		color: theme.palette.error.main,
		marginLeft: theme.spacing(1),
	},
	'.tbl-view-editor': {
		div: {
			// wordBreak: 'break-all',
			lineHeight: 'initial',
		},
		padding: 0,
		'& h1': {
			lineHeight: theme.spacing(4),
		},
		'& h2': {
			lineHeight: theme.spacing(3),
		},

		'.empty': {
			color: theme.newColors.gray[600],
			...theme.typography['bodyMediumRegular'],
		},
	},
	'.MuiPickersCalendar-transitionContainer': {
		marginBottom: '12px',
	},
	'.MuiTab-root': {
		fontWeight: theme.fontWeight.semi,
	},
	'span[class^="icon-"], span[class*=" icon-"]': {
		'&.icon-xs': {
			fontSize: '20px',
		},
		'&.icon-sm': {
			fontSize: '20px',
		},
		'&.icon-md': {
			fontSize: '20px',
		},
		'&.icon-lg': {
			fontSize: '20px',
		},
	},
	'.picker-dialog-bg': {
		zIndex: '20000 !important',
	},
	'.picker-dialog': {
		zIndex: '20001 !important',
	},
	'.lazy': {
		background: theme.newColors.gray[200],
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		filter: `blur(${theme.spacing(5)})`,
	},
	'& .divider-wrapper': {
		width: '100%',
		padding: theme.spacing(1, 1.5, 2),
		'& .MuiDivider-root': {
			borderBottomWidth: 'inherit',
		},
	},
	'& .divider': {
		backgroundColor: theme.newColors.gray[100],
		width: '100%',
		height: 2,
		margin: theme.spacing(4.5, 0),
		borderRadius: 2,
	},

	'.customFooterModal': {
		display: 'flex',
		width: '100%',
		padding: theme.spacing(3),
		paddingTop: 0,
		justifyContent: 'flex-end',
		gap: theme.spacing(1),
	},
});

export default styles;
