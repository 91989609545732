import { Box, Skeleton } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';

export default function ScheduleAllSectionsSkeleton({ type }) {
	const skeleton = () => {
		if (type === ACTIVITY_CATEGORY.LESSON) {
			return (
				<Box display='flex' gap={2}>
					<Box>
						<Skeleton variant='text' height={24} width={80} />
						<Skeleton variant='rounded' height={40} width={700} />
					</Box>
				</Box>
			);
		}
		return (
			<Box display='flex' gap={2}>
				<Box>
					<Skeleton variant='text' height={24} width={80} />
					<Skeleton variant='rounded' height={40} width={350} />
				</Box>
				<Box>
					<Skeleton variant='text' height={24} width={80} />
					<Skeleton variant='rounded' height={40} width={350} />
				</Box>
			</Box>
		);
	};
	return skeleton();
}
ScheduleAllSectionsSkeleton.propTypes = {
	type: PropTypes.oneOf(Object.values(ACTIVITY_CATEGORY)),
};
