import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TblGoogleFileList from 'components/TblGoogleFileList';
import TblGooglePicker from 'components/TblGooglePicker';
import TblIcon from 'components/TblIcon';

import { MAX_GOOGLE_UPLOAD_FILES } from 'utils/constants';

import { ReactComponent as GoogleIcon } from 'assets/images/icn_google.svg';
import { Box, Button, Dialog, MenuItem, MenuList, Popover, Tooltip, Typography } from 'ella-storybook';
import { isEmpty, isEqual, keyBy } from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import useWebWorkers from '../../../utils/customHook/useWebUploadWorkers';
import AttachmentList from '../Attachment/AttachmentList';
import AttachmentPicker from '../Attachment/AttachmentPicker';
const headers = {
	Authorization: `Bearer ${localStorage.getItem('access_token')}`,
};
const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/upload`;
const useStyles = makeStyles()((theme) => ({
	fileList: {
		display: 'flex',
		gap: theme.spacing(2),
		flexDirection: 'column',
	},
	title: {
		color: theme.newColors.gray[800],
		marginLeft: theme.spacing(1.5),
	},
	emptyContent: {
		margin: 0,
		color: theme.newColors.gray[600],
	},
}));

function MyAttachments({
	hasPermission,
	initialValues,
	onAdd,
	onChange,
	maxItems,
	onChangeChooseTemplate,
	disabled,
	updateData,
	attachmentFiles,
	onClickEditSubmission,
	type,
	viewOnly,
	isRenderContextMenu,
	isStudentOrGuardian,
	buttonTitle,
	startIconBtn,
	customButtonStyle,
	submissionRequired,
	onUseTemplate,
	disableAttachGoogle,
	columnReserveStyle,
	handleLoadingLogSubmission,
	noAttachmentsText,
	isEditSubmission,
	allowDownloadAttachmentFiles,
	initialMediaFiles,
	archiveMode,
	onSelectFile,
	canAdd,
	selectedFile,
}) {
	const { classes } = useStyles();

	const [event, sendEvent] = useWebWorkers();
	const maxItemCanBeSelected = maxItems - initialValues.length;
	const uploadRef = useRef(null);
	const { t } = useTranslation(['myCourses', 'common', 'myTasks', 'google']);
	// custom style
	const [popoverWidth, setPopoverWidth] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [warningDialog, setWarningDialog] = useState(false);
	const [isOpenGooglePicker, setIsOpenGooglePicker] = useState(false);

	const [attachments, setAttachments] = useState(keyBy(attachmentFiles, 'id'));
	const [maximumFilesReached, setMaximumFilesReached] = useState([]);

	useEffect(() => {
		const initValueKeys = initialMediaFiles.map((item) => item.id).sort();
		const attachmentKeys = attachmentFiles.map((item) => item.id).sort();
		const isResetToInitMediaValues = isEqual(initValueKeys, attachmentKeys);

		if (!isResetToInitMediaValues) {
			return;
		}
		setAttachments(keyBy(initialMediaFiles, 'id'));
	}, [attachmentFiles]);

	useEffect(() => {
		if (!isEmpty(customButtonStyle)) {
			const button = document.getElementById('btn-add-attachment');
			setPopoverWidth(button?.offsetWidth);
		}
	}, [customButtonStyle]);

	useEffect(() => {
		if (maximumFilesReached.length) {
			setWarningDialog(true);
		}
	}, [maximumFilesReached]);

	useEffect(() => {
		const haveLoadingItem = Object.values(attachments).find((item) => item.uploading === true);
		handleLoadingLogSubmission(haveLoadingItem ? true : false);
		const beforeUnloadListener = (event) => {
			if (haveLoadingItem) {
				event.preventDefault();
				return (event.returnValue = '');
			}
		};
		window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
		return () => {
			window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
		};
	}, [attachments]);

	useEffect(() => {
		const { type, data } = event;
		if (type === 'success') {
			const { uid, uploadedFiles } = data;
			setAttachments((preValue) => ({
				...preValue,
				[uid]: {
					...preValue[uid],
					uploaded: true,
					uploading: false,
					...uploadedFiles[0],
				},
			}));
			updateData && updateData(uploadedFiles[0]);
		} else if (type === 'progress') {
			const { uid, percent } = data;
			setAttachments((preValue) => ({
				...preValue,
				[uid]: {
					...preValue[uid],
					percentage: percent,
				},
			}));
		} else if (type === 'error') {
			const { uid } = data;
			setAttachments((preValue) => ({
				...preValue,
				[uid]: {
					...preValue[uid],
					uploaded: false,
					uploading: false,
					error: true,
				},
			}));
		}
	}, [event]);

	const handleOpenPopover = (e) => {
		e.preventDefault();
		setAnchorEl(e.target);
		setOpen(!open);
	};

	const handleClosePopover = (e) => {
		if (anchorEl && anchorEl.contains(e.target)) {
			return;
		}
		setOpen(false);
	};

	const renderPopover = () => (
		<Popover
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={handleClosePopover}
			mt={4}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			PaperProps={{
				style: { width: !isEmpty(customButtonStyle) && popoverWidth },
			}}
		>
			<MenuList>
				<Tooltip title={disableAttachGoogle ? t('teacher_did_not_connect_to_a_google_account', { ns: 'myTasks' }) : ''}>
					<Box>
						<MenuItem
							disableRipple
							disabled={disableAttachGoogle}
							onClick={(e) => {
								setIsOpenGooglePicker(true);
								handleClosePopover(e);
							}}
						>
							<GoogleIcon />
							<Typography variant='bodyMediumRegular' className={classes.title}>
								{t('google_document', { ns: 'google' })}
							</Typography>
						</MenuItem>
					</Box>
				</Tooltip>

				<MenuItem
					disableRipple
					onClick={(e) => {
						uploadRef && uploadRef.current.click();
						handleClosePopover(e);
					}}
				>
					<TblIcon icon='upload' />
					<Typography variant='bodyMediumRegular' className={classes.title}>
						{t('upload_files', { ns: 'myTasks' })}
					</Typography>
				</MenuItem>
			</MenuList>
		</Popover>
	);

	const onRemoveGoogleFile = (sourceId) => {
		const index = initialValues.findIndex((file) => file.sourceId === sourceId);
		initialValues.splice(index, 1);
		onChange(initialValues);
	};

	const handleUploadFiles = (event) => {
		const files = event.target.files;
		const uploadingFiles = {};
		const violateFile = [];
		for (let i = 0; i < files.length; i++) {
			if (files[i].size / (1024 * 1024) > 100) {
				violateFile.push(files[i].name);
				continue;
			}
			files[i].uid = nanoid();
			files[i].percent = 0;
			sendEvent({
				file: files[i],
				filename: 'file',
				uid: files[i].uid,
				headers,
				action: UPLOAD_URL,
			});
			uploadingFiles[files[i].uid] = {
				uid: files[i].uid,
				originalName: files[i].name,
				uploaded: false,
				uploading: true,
				error: false,
				percentage: 0,
				metaData: files[i],
			};
		}
		setMaximumFilesReached(violateFile);
		setAttachments({ ...attachments, ...uploadingFiles });
	};
	const onRetry = (item) => {
		sendEvent({
			file: item.metaData,
			filename: 'file',
			uid: item.uid,
			headers,
			action: UPLOAD_URL,
		});
		setAttachments((preValue) => ({
			...preValue,
			[item.uid]: {
				...preValue[item.uid],
				uploaded: false,
				uploading: true,
				error: false,
				percentage: 0,
			},
		}));
	};
	const deleteFile = (item) => {
		setAttachments((preValue) => {
			// eslint-disable-next-line unused-imports/no-unused-vars
			const { [item.uid || item.id]: _, ...rest } = preValue;
			return rest;
		});
		if (!item.error) {
			updateData && updateData(item, true);
		}
	};
	const onCloseWarningDialog = () => {
		setMaximumFilesReached([]);
		setWarningDialog(false);
	};

	return (
		<>
			<Dialog
				open={warningDialog}
				hasCloseIcon={true}
				maxWidth='md'
				title={t('maximum_title', { ns: 'media' })}
				onClose={() => onCloseWarningDialog()}
				footer={
					<Button variant='outlined' color='primary' onClick={() => onCloseWarningDialog()}>
						{t('got_it', { ns: 'media' })}
					</Button>
				}
			>
				<Box display={'flex'} flexDirection={'column'} gap={1}>
					<Typography variant='bodyMediumRegular'>{t('maximum_file_size_content', { ns: 'media' })}</Typography>
					{maximumFilesReached.map((fileName) => (
						<Typography variant='bodyMediumRegular'>{`• ${fileName}`}</Typography>
					))}
				</Box>
			</Dialog>
			<Box display={'flex'} gap={2} flexDirection={columnReserveStyle ? 'column-reverse' : 'column'}>
				{!viewOnly && (
					<>
						{renderPopover()}
						{canAdd && !archiveMode && (
							<Box>
								<Button
									variant='outlined'
									startIcon={<TblIcon icon={startIconBtn} />}
									color='primary'
									onClick={(e) => (!isEditSubmission ? handleOpenPopover(e) : onClickEditSubmission())}
									disabled={disabled || !hasPermission}
									sx={{ ...customButtonStyle }}
									id='btn-add-attachment'
								>
									{isEditSubmission ? buttonTitle : t('add_attachments', { ns: 'myCourses' })}
								</Button>
								<TblGooglePicker
									maxItemCanBeSelected={maxItemCanBeSelected}
									isEmptyList={initialValues.length === 0}
									hasPermission={hasPermission}
									onChange={onAdd}
									disabled={disabled}
									isOpenGooglePicker={isOpenGooglePicker}
									setIsOpenGooglePicker={setIsOpenGooglePicker}
								/>
								<AttachmentPicker ref={uploadRef} onUpload={handleUploadFiles} />
							</Box>
						)}
					</>
				)}
				<Box
					className={classes.fileList}
					sx={{
						...(Object.values(attachments).length <= 0 &&
							initialValues.length <= 0 &&
							!viewOnly && { display: 'none !important' }),
					}}
				>
					{Object.values(attachments).length === 0 &&
						initialValues.length === 0 &&
						(archiveMode || viewOnly || noAttachmentsText) && (
							<Typography
								component={'div'}
								variant={noAttachmentsText ? 'bodySmallRegular' : 'bodyMediumRegular'}
								className={classes.emptyContent}
							>
								{noAttachmentsText ? noAttachmentsText : t('no_attachments', { ns: 'myCourses' })}
							</Typography>
						)}
					{initialValues.length > 0 && (
						<TblGoogleFileList
							list={initialValues}
							hasPermission={hasPermission}
							onRemove={onRemoveGoogleFile}
							onChangeChooseTemplate={onChangeChooseTemplate}
							disabled={viewOnly || disabled || isEditSubmission}
							type={type}
							isRenderContextMenu={isRenderContextMenu}
							isStudentOrGuardian={isStudentOrGuardian}
							submissionRequired={submissionRequired}
							onUseTemplate={onUseTemplate}
							archiveMode={archiveMode}
							onSelectFile={onSelectFile}
							fileSelected={selectedFile}
						/>
					)}
					{Object.values(attachments).length > 0 && (
						<AttachmentList
							archiveMode={archiveMode}
							files={Object.values(attachments)}
							onRemove={deleteFile}
							disabled={viewOnly || disabled || isEditSubmission}
							hasPermission={hasPermission}
							isRenderContextMenu={isRenderContextMenu}
							onRetry={onRetry}
							allowDownloadAttachmentFiles={allowDownloadAttachmentFiles}
							onSelectFile={onSelectFile}
							fileSelected={selectedFile}
						/>
					)}
				</Box>
			</Box>
		</>
	);
}

MyAttachments.propTypes = {
	hasPermission: PropTypes.bool,
	initialValues: PropTypes.array,
	maxFile: PropTypes.number,
	maxItemCanBeSelected: PropTypes.func,
	maxItems: PropTypes.number,
	onAdd: PropTypes.func,
	onChange: PropTypes.func,
	onChangeChooseTemplate: PropTypes.func,
	disabled: PropTypes.bool,
	viewOnly: PropTypes.bool,
	isRenderContextMenu: PropTypes.bool,
	isStudentOrGuardian: PropTypes.bool,
	submissionRequired: PropTypes.bool,
	customButtonStyle: PropTypes.object,
	buttonTitle: PropTypes.string,
	startIconBtn: PropTypes.string,
	onUseTemplate: PropTypes.func,
	disableAttachGoogle: PropTypes.bool,
	columnReserveStyle: PropTypes.bool,
	handleLoadingLogSubmission: PropTypes.func,
	onClickEditSubmission: PropTypes.func,
	isEditSubmission: PropTypes.bool,
	allowDownloadAttachmentFiles: PropTypes.bool,
	isGuardian: PropTypes.bool,
	initialMediaFiles: PropTypes.array,
	archiveMode: PropTypes.bool,
	selectedFile: PropTypes.object,
};

MyAttachments.defaultProps = {
	initialValues: [],
	maxFile: MAX_GOOGLE_UPLOAD_FILES,
	disabled: false,
	attachmentFiles: [],
	hasPermission: true,
	viewOnly: false,
	isRenderContextMenu: false,
	isStudentOrGuardian: false,
	submissionRequired: false,
	startIconBtn: 'add',
	disableAttachGoogle: false,
	columnReserveStyle: false,
	handleLoadingLogSubmission: () => {},
	onClickEditSubmission: () => {},
	noAttachmentsText: '',
	isEditSubmission: false,
	allowDownloadAttachmentFiles: false,
	isGuardian: false,
	initialMediaFiles: [],
	archiveMode: false,
	selectedFile: null,
};

export default memo(MyAttachments);
