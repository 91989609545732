import React from 'react';

import { Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import typography from 'themes/v3/typography';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	arrow: {
		height: '0.72em',
		'&::before': {
			borderTopLeftRadius: theme.spacing(0.25),
			backgroundColor: theme.newColors.gray[800],
		},
	},
	tooltip: {
		color: 'white',
		backgroundColor: theme.newColors.gray[800],
		borderRadius: theme.borderRadius.default,
		padding: theme.spacing(1, 1.5),
		...typography.bodyMediumRegular,
	},
}));

const TblTooltip = (props) => {
	const { classes } = useStyles();

	return <Tooltip arrow classes={classes} {...props} />;
};

TblTooltip.propTypes = {
	title: PropTypes.any,
	placement: PropTypes.string,
};
TblTooltip.defaultProps = {
	title: '',
	placement: 'top',
};

export default React.forwardRef((props, ref) => <TblTooltip ref={ref} {...props} />);
