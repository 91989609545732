import myCoursesActions from 'modules/MyCourses/actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../../utils/ajax';
import gradingAction from '../actionConstants/gradingActions';
import { ACTIVITY_CATEGORY } from '../constants';
import END_POINT from '../endPoints/gradingEndPoints';

const getDetailGradingLateCompletionEpic = (action$) =>
	action$.pipe(
		ofType(gradingAction.GET_DETAIL_GRADING_LATE_COMPLETION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_detail_grading_late_completion.method,
				END_POINT.get_detail_grading_late_completion.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.getDetailGradingLateCompletionSuccess({
							gradingLateCompletion: (data.response?.gradingLateCompletion?.data || []).find(
								(d) => d.attemptActivityType === ACTIVITY_CATEGORY.ASSIGNMENT,
							),
							gradingLateCompletionForTest: (data.response?.gradingLateCompletion?.data || []).find(
								(d) => d.attemptActivityType === ACTIVITY_CATEGORY.TEST,
							),
							getGradingLateCompletionSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getDetailGradingLateCompletionFailed({
							getGradingLateCompletionFailed: true,
							error: error.response.errors,
						}),
					),
				),
			),
		),
	);

const updateGradingLateCompletionEpic = (action$) =>
	action$.pipe(
		ofType(gradingAction.UPDATE_GRADING_LATE_COMPLETION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.update_grading_late_completion.method,
				END_POINT.update_grading_late_completion.url(action.payload.orgId, action.payload.courseId),
				action.payload,
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.updateGradingLateCompletionSuccess({
							updateGradingLateCompletionSuccess: true,
						}),
						myCoursesActions.getDetailGradingLateCompletion({
							orgId: action.payload.orgId,
							courseId: action.payload.courseId,
						}),
						myCoursesActions.mcGetGradeWeight({
							orgId: action.payload.orgId,
							courseId: action.payload.courseId,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.updateGradingLateCompletionFailed({
							updateGradingLateCompletionFailed: true,
							error: error.response.errors,
						}),
					),
				),
			),
		),
	);
export default [getDetailGradingLateCompletionEpic, updateGradingLateCompletionEpic];
