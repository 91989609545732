import { COURSE_ROLE, TEACHER } from 'utils/roles';

import { objectToParams } from 'utils';

export const ROLE_CAN_GRADER = [TEACHER, COURSE_ROLE.TEACHING_ASSISTANT];
export const ROLE_CAN_VIEW_GRADER = [TEACHER, COURSE_ROLE.TEACHING_ASSISTANT, COURSE_ROLE.OBSERVER];

export const OVERRIDE_GRADE_TYPE = {
	MANUAL_GRADE: -1,
	AUTOMATED_GRADE: 1,
};

export const actions = {
	GET_BASIC_INFO: 'GET_BASIC_INFO',
	GET_BASIC_INFO_SUCCESS: 'GET_BASIC_INFO_SUCCESS',
	GET_BASIC_INFO_FAILED: 'GET_BASIC_INFO_FAILED',

	// MC_GET_PERMISSION_COURSE: 'MC_GET_PERMISSION_COURSE',
	// MC_GET_PERMISSION_COURSE_SUCCESS: 'MC_GET_PERMISSION_COURSE_SUCCESS',
	// MC_GET_PERMISSION_COURSE_FAILED: 'MC_GET_PERMISSION_COURSE_FAILED',

	GET_ACTIVITIES_IN_GRADER: 'GET_ACTIVITIES_IN_GRADER',
	GET_ACTIVITIES_IN_GRADER_SUCCESS: 'GET_ACTIVITIES_IN_GRADER_SUCCESS',
	GET_ACTIVITIES_IN_GRADER_FAILED: 'GET_ACTIVITIES_IN_GRADER_FAILED',

	GET_SECTIONS_BY_ACTIVITY_GRADER: 'GET_SECTIONS_BY_ACTIVITY_GRADER',
	GET_SECTIONS_BY_ACTIVITY_GRADER_SUCCESS: 'GET_SECTIONS_BY_ACTIVITY_GRADER_SUCCESS',
	GET_SECTIONS_BY_ACTIVITY_GRADER_FAILED: 'GET_SECTIONS_BY_ACTIVITY_GRADER_FAILED',

	GET_GRADING_LIST: 'GET_GRADING_LIST',
	GET_GRADING_LIST_SUCCESS: 'GET_GRADING_LIST_SUCCESS',
	GET_GRADING_LIST_FAILED: 'GET_GRADING_LIST_FAILED',

	GET_TOTAL_GRADED: 'GET_TOTAL_GRADED',
	GET_TOTAL_GRADED_SUCCESS: 'GET_TOTAL_GRADED_SUCCESS',
	GET_TOTAL_GRADED_FAILED: 'GET_TOTAL_GRADED_FAILED',

	GET_GRADER_DETAIL: 'GET_GRADER_DETAIL',
	GET_GRADER_DETAIL_SUCCESS: 'GET_GRADER_DETAIL_SUCCESS',
	GET_GRADER_DETAIL_FAILED: 'GET_GRADER_DETAIL_FAILED',

	GRADER_SET_STATE: 'GRADER_SET_STATE',
	GRADER_RESET_STATE: 'GRADER_RESET_STATE',

	INOUT_GRADE_SUCCESS: 'INOUT_GRADE_SUCCESS',
	INPUT_OVERALL_GRADE: 'INPUT_OVERALL_GRADE',

	INPUT_GRADE: 'INPUT_GRADE',
	INPUT_GRADE_SUCCESS: 'INPUT_GRADE_SUCCESS',
	INPUT_GRADE_FAILED: 'INPUT_GRADE_FAILED',

	GET_GRADER_COMMENTS: 'GET_GRADER_COMMENTS',
	GET_GRADER_COMMENTS_SUCCESS: 'GET_GRADER_COMMENTS_SUCCESS',
	GET_GRADER_COMMENTS_FAILED: 'GET_GRADER_COMMENTS_FAILED',

	POST_GRADER_COMMENT: 'POST_GRADER_COMMENT',
	POST_GRADER_COMMENT_SUCCESS: 'POST_GRADER_COMMENT_SUCCESS',
	POST_GRADER_COMMENT_FAILED: 'POST_GRADER_COMMENT_FAILED',

	DELETE_GRADER_COMMENT: 'DELETE_GRADER_COMMENT',
	DELETE_GRADER_COMMENT_SUCCESS: 'DELETE_GRADER_COMMENT_SUCCESS',
	DELETE_GRADER_COMMENT_FAILED: 'DELETE_GRADER_COMMENT_FAILED',

	EDIT_GRADER_COMMENT: 'EDIT_GRADER_COMMENT',
	EDIT_GRADER_COMMENT_SUCCESS: 'EDIT_GRADER_COMMENT_SUCCESS',
	EDIT_GRADER_COMMENT_FAILED: 'EDIT_GRADER_COMMENT_FAILED',

	CHECK_READ_GRADER_COMMENTS: 'CHECK_READ_GRADER_COMMENTS',
	CHECK_READ_GRADER_COMMENTS_SUCCESS: 'CHECK_READ_GRADER_COMMENTS_SUCCESS',
	CHECK_READ_GRADER_COMMENTS_FAILED: 'CHECK_READ_GRADER_COMMENTS_FAILED',

	CLEAR_COMMENTS: 'CLEAR_COMMENTS',
	CLEAR_COMMENTS_SUCCESS: 'CLEAR_COMMENTS_SUCCESS',

	TEACHER_GET_STUDENT_ATTEMPTS: 'TEACHER_GET_STUDENT_ATTEMPTS',
	TEACHER_GET_STUDENT_ATTEMPTS_SUCCESS: 'TEACHER_GET_STUDENT_ATTEMPTS_SUCCESS',
	TEACHER_GET_STUDENT_ATTEMPTS_FAILED: 'TEACHER_GET_STUDENT_ATTEMPTS_FAILED',

	UPDATE_STUDENT_ATTEMPTS: 'UPDATE_STUDENT_ATTEMPTS',
	UPDATE_STUDENT_ATTEMPTS_SUCCESS: 'UPDATE_STUDENT_ATTEMPTS_SUCCESS',
	UPDATE_STUDENT_ATTEMPTS_FAILED: 'UPDATE_STUDENT_ATTEMPTS_FAILED',

	SELECT_ATTEMPT: 'SELECT_ATTEMPT',
	SELECT_ATTEMPT_SUCCESS: 'SELECT_ATTEMPT_SUCCESS',
	SELECT_ATTEMPT_FAILED: 'SELECT_ATTEMPT_FAILED',

	SWITCH_OVERRIDE_MODE: 'SWITCH_OVERRIDE_MODE',
	SWITCH_OVERRIDE_MODE_SUCCESS: 'SWITCH_OVERRIDE_MODE_SUCCESS',
	SWITCH_OVERRIDE_MODE_FAILED: 'SWITCH_OVERRIDE_MODE_FAILED',

	LOG_ATTEMPT: 'LOG_ATTEMPT',
	LOG_ATTEMPT_SUCCESS: 'LOG_ATTEMPT_SUCCESS',
	LOG_ATTEMPT_FAILED: 'LOG_ATTEMPT_FAILED',
};

export const END_POINT = {
	get_activities_in_grader: {
		method: 'GET',
		url: (orgId, courseId) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/grader/activities`,
	},
	get_sections_by_activity_grader: {
		method: 'GET',
		url: (orgId, courseId, activityId, activityType) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/grader/activity/${activityId}/activity-type/${activityType}/sections`,
	},
	get_total_graded: {
		method: 'GET',
		url: (orgId, courseId, activityId, activityType, urlParams) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/courses/${courseId}/activity/${activityId}/activity-type/${activityType}/grader/total-graded?${objectToParams(
				urlParams,
			)}`,
	},
	get_grading_list: {
		method: 'GET',
		url: (orgId, courseId, activityId, activityType, urlParams) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/courses/${courseId}/activity/${activityId}/activity-type/${activityType}/student-submissions?${objectToParams(
				urlParams,
			)}`,
	},

	get_grader_detail: {
		method: 'GET',
		url: (orgId, courseId, shadowId, activityType, studentId) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/courses/${courseId}/shadow-activity/${shadowId}/activity-type/${activityType}/student-submissions/${studentId}`,
	},

	input_grade: {
		method: 'PUT',
		url: (courseId, progressId) =>
			`${process.env.REACT_APP_API_URL_V2}/courses/${courseId}/grader/teacher/student-submissions/${progressId}`,
	},
	get_attempt_detail: {
		method: 'GET',
		url: (orgId, courseId, shadowId, studentId) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/shadow-assignments/${shadowId}/student-submissions/${studentId}`,
	},
	input_overall_grade: {
		method: 'POST',
		url: (courseId, shadowAssignmentId, progressId) =>
			`${process.env.REACT_APP_API_URL}/grader/teacher/courses/${courseId}/shadow-assignments/${shadowAssignmentId}/student-submissions/${progressId}`,
	},
	get_comments_grader: {
		method: 'GET',
		url: (courseId, orgId, studentId, submissionId, urlParams) =>
			`${
				process.env.REACT_APP_API_URL
			}/organizations/${orgId}/courses/${courseId}/students/${studentId}/submissions/${submissionId}/comments?${objectToParams(
				urlParams,
			)}`,
	},
	post_comment_grader: {
		method: 'POST',
		url: (courseId, orgId, studentId, submissionId) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/students/${studentId}/submissions/${submissionId}/comments`,
	},
	delete_comment_grader: {
		method: 'DELETE',
		url: (courseId, orgId, studentId, submissionId, commentId) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/students/${studentId}/submissions/${submissionId}/comments/${commentId}`,
	},
	edit_comment_grader: {
		method: 'PUT',
		url: (courseId, orgId, studentId, submissionId, commentId) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/students/${studentId}/submissions/${submissionId}/comments/${commentId}`,
	},
	check_read_comments_grader: {
		method: 'GET',
		url: (courseId, orgId, studentId, submissionId) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/courses/${courseId}/students/${studentId}/submissions/${submissionId}/comments-read`,
	},
	switch_override_mode: {
		method: 'PUT',
		url: (courseId, overallGradeMode, progressId) =>
			`${process.env.REACT_APP_API_URL_V2}/courses/${courseId}/grader/teacher/student-submissions/${progressId}/switch-grade/${overallGradeMode}`,
	},
	log_attempts: {
		method: 'PUT',
		url: (orgId, courseId, attemptId) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/courses/${courseId}/grader/attempts/${attemptId}/log`,
	},
};
