import { objectToParams } from 'utils';

export const actions = {
	GET_COURSES_GRADE_REPORT: 'GET_COURSES_GRADE_REPORT',
	GET_COURSES_GRADE_REPORT_SUCCESS: 'GET_COURSES_GRADE_REPORT_SUCCESS',
	GET_COURSES_GRADE_REPORT_FAILED: 'GET_COURSES_GRADE_REPORT_FAILED',

	GET_COURSE_INFO_GRADE_REPORT: 'GET_COURSE_INFO_GRADE_REPORT',
	GET_COURSE_INFO_GRADE_REPORT_SUCCESS: 'GET_COURSE_INFO_GRADE_REPORT_SUCCESS',
	GET_COURSE_INFO_GRADE_REPORT_FAILED: 'GET_COURSE_INFO_GRADE_REPORT_FAILED',

	GET_COURSE_LIST_BY_TERM: 'GET_COURSE_LIST_BY_TERM',
	GET_COURSE_LIST_BY_TERM_SUCCESS: 'GET_COURSE_LIST_BY_TERM_SUCCESS',
	GET_COURSE_LIST_BY_TERM_FAILED: 'GET_COURSE_LIST_BY_TERM_FAILED',

	GET_GRADE_DETAILS_ACTIVITIES: 'GET_GRADE_DETAILS_ACTIVITIES',
	GET_GRADE_DETAILS_ACTIVITIES_SUCCESS: 'GET_GRADE_DETAILS_ACTIVITIES_SUCCESS',
	GET_GRADE_DETAILS_ACTIVITIES_FAILED: 'GET_GRADE_DETAILS_ACTIVITIES_FAILED',

	GET_GRADE_REPORT_DETAILS: 'GET_GRADE_REPORT_DETAILS',
	GET_GRADE_REPORT_DETAILS_SUCCESS: 'GET_GRADE_REPORT_DETAILS_SUCCESS',
	GET_GRADE_REPORT_DETAILS_FAILED: 'GET_GRADE_REPORT_DETAILS_FAILED',

	GRADE_REPORT_SET_STATE: 'GRADE_REPORT_SET_STATE',

	GRADE_REPORT_RESET_STATE: 'GRADE_REPORT_RESET_STATE',
};

export const END_POINT = {
	get_grade_report: {
		url: (courseId, termId, urlParams) =>
			`${process.env.REACT_APP_API_URL}/grade-report/courses/${courseId}/terms/${termId}?${objectToParams(urlParams)}`,
		method: 'GET',
	},
	get_courses_grade_report: {
		url: (orgId, urlParams) =>
			`${process.env.REACT_APP_API_URL_V2}/organization/${orgId}/grade-report/courses?${objectToParams(urlParams)}`,
		method: 'GET',
	},
	get_courses_grade_report_detail: {
		url: (orgId, courseId, urlParams) =>
			`${process.env.REACT_APP_API_URL_V2}/organization/${orgId}/grade-report/courses/${courseId}?${objectToParams(
				urlParams,
			)}`,
		method: 'GET',
	},
	get_courses_grade_report_info: {
		url: (orgId, courseId, urlParams) =>
			`${process.env.REACT_APP_API_URL_V2}/organization/${orgId}/grade-report/courses/${courseId}/info?${objectToParams(
				urlParams,
			)}`,
		method: 'GET',
	},
	get_grade_report_assignment_detail: {
		url: (shadowId, urlParams) =>
			`${process.env.REACT_APP_API_URL}/grade-report/shadow-assignments/${shadowId}?${objectToParams(urlParams)}`,
		method: 'GET',
	},
	get_grade_report_quiz_detail: {
		url: (shadowId, urlParams) =>
			`${process.env.REACT_APP_API_URL}/grade-report/shadow-quizzes/${shadowId}?${objectToParams(urlParams)}`,
		method: 'GET',
	},
};
export const MESSAGE_SUBCODE = {
	CONTENT_NOT_AVAILABLE: -1,
};
export const GRADE_CALCULATION_TYPE = {
	NO_OVERALL_GRADE: 1,
	TOTAL_POINT: 2,
	WEIGHTED_BY_CATEGORY: 3,
};
