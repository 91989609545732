// import 'src/assets/fonts/Magenos/Magenos.css';

const fontSize = {
	'text-2xs': '11px',
	'text-xs': '12px',
	'text-sm': '14px',
	'text-base': '16px',
	'text-lg': '18px',
	'text-xl': '20px',
	'text-2xl': '24px',
	'text-3xl': '28px',
	'text-4xl': '32px',
	'text-5xl': '40px',
	'text-6xl': '48px',
	'text-7xl': '64px',
};

const fontSizeIcon = {
	xSmall: '12px',
	small: '16px',
	normal: '20px',
	medium: '24px',
	xMedium: '40px',
	large: '54px',
  xLarge: '64px'
};

const fontWeight = {
	light: 300,
	normal: 400,
	medium: 500,
	semi: 600,
	bold: 700,
	xBold: 800,
};

const lineHeight = {
	'leading-none': 0,
	'leading-4': '16px',
	'leading-5': '20px',
	'leading-6': '24px',
	'leading-7': '28px',
	'leading-8': '32px',
	'leading-9': '36px',
	'leading-10': '40px',
	'leading-11': '48px',
	'leading-12': '56px',
	'leading-13': '80px',
};

const letterSpacing = {
	'tracking-normal': '0',
	'tracking-xwide': '0.1px',
	'tracking-2xwide': '0.15px',
	'tracking-3xwide': '0.25px',
	'tracking-4xwide': '0.5px',
	'tracking-5xwide': '0.6px',
};

const fontFamily = ['"Magenos"', '"Source Sans 3"', 'sans-serif'].join(',');

export { fontSize, fontSizeIcon, fontWeight, fontFamily, lineHeight, letterSpacing };
