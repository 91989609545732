export default {
	MC_GET_GRADE_CALCULATION: 'MC_GET_GRADE_CALCULATION',
	MC_GET_GRADE_CALCULATION_SUCCESS: 'MC_GET_GRADE_CALCULATION_SUCCESS',
	MC_GET_GRADE_CALCULATION_FAILED: 'MC_GET_GRADE_CALCULATION_FAILED',

	MC_UPDATE_GRADE_CALCULATION: 'MC_UPDATE_GRADE_CALCULATION',
	MC_UPDATE_GRADE_CALCULATION_SUCCESS: 'MC_UPDATE_GRADE_CALCULATION_SUCCESS',
	MC_UPDATE_GRADE_CALCULATION_FAILED: 'MC_UPDATE_GRADE_CALCULATION_FAILED',

	GRADE_CALCULATION_SET_STATE: 'GRADE_CALCULATION_SET_STATE',
};
