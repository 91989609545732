import { setOrderedListNumbering } from 'roosterjs-editor-api';
import { safeInstanceOf } from 'roosterjs-editor-dom';

export default class ResetListPlugin {
	editor;

	getName() {
		return 'ResetList';
	}

	initialize(editor) {
		this.editor = editor;
	}

	dispose() {
		this.editor = null;
	}

	getContextMenuItems(node) {
		const items = [];

		if (safeInstanceOf(node, 'HTMLLIElement')) {
			const list = this.editor.getElementAtCursor('ol,ul', node);
			if (safeInstanceOf(list, 'HTMLOListElement')) {
				items.push({
					key: 'resetList',
					name: 'Reset list number',
					onClick: () => {
						setOrderedListNumbering(this.editor, node, 1);
					},
				});
			}
		}
		return items;
	}
}
