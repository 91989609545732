import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Collapse, useTheme } from '@mui/material';

import TblIcon from 'components/TblIcon';
import CollapseOptions from 'shared/MyCourses/components/CollapseOptions';

import { Dropdown, Radio, TextField, Tooltip, Typography } from 'ella-storybook';
import { makeStyles } from 'tss-react/mui';

import { ALLOW_LATE, APPLY_DEDUCTION, COMPLETE_BY, GRADE_TYPE, PARTIAL_CREDIT, SESSIONS } from '../constants';
const MAXIMUM_DEDUCTION = {
	MIN: 1,
	MAX: 100,
};
const SESSION_DUE = {
	MIN: 1,
	MAX: 99,
};
const MAX_LENGTH = 3;
const useStyles = makeStyles()((theme) => ({
	root: {
		padding: theme.spacing(2),
		borderRadius: theme.spacing(2),
		marginTop: theme.spacing(3),
		background: theme.newColors.gray[100],
		'& .MuiFormHelperText-root': {
			display: 'none',
		},
		'& .MuiFormGroup-root': {
			gap: theme.spacing(2.25),
		},
	},
	category: {
		marginTop: theme.spacing(2),
	},
	radio: {
		gap: theme.spacing(1.25),
	},
	completeBy: {
		marginTop: theme.spacing(4),
		display: 'flex',
		'& .MuiFormGroup-root': {
			flexDirection: 'column',
		},
	},
	inputCompleteBy: {
		marginTop: theme.spacing(1.25),
		marginLeft: theme.spacing(2.5),
		width: theme.spacing(15.8),
	},
	deduct: {
		position: 'relative',
		marginTop: theme.spacing(3),
	},
	inputDeduct: {
		marginRight: theme.spacing(2),
		width: theme.spacing(12),
	},
	inputApplyDeduct: {
		width: theme.spacing(20),
	},
	inputLowest: {
		width: theme.spacing(12),
	},
	tooltip: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
	},
	maximumField: {
		left: theme.spacing(15.5),
	},
	deductField: {
		left: theme.spacing(5.5),
	},
}));
function LateTurnIn(props) {
	const { handleChange, values, disableEditField, disabled, errors, touched, gradingLateCompletion, assignment } =
		props;

	const {
		completeBy: completeByAssignment,
		deduct: deductAssignment,
		deductType: deductTypeAssignment,
		sessionDueLate: sessionDueLateAssignment,
		lowestGradePercent: lowestGradePercentAssignment,
		allowLateTurnIn: allowLateTurnInAssignment,
		partialCredit: partialCreditAssignment,
	} = assignment;

	const {
		lateCompletion: lateCompletionDefault,
		applyDeduction: applyDeductionDefault,
		deduct: deductDefault,
		maximumDeduction: maximumDeductionDefault,
		mustComplete: mustCompleteDefault,
		sessions: sessionsDefault,
		partialCredit: partialCreditDefault,
	} = gradingLateCompletion;

	const { t } = useTranslation(['common', 'myCourses']);
	const theme = useTheme();
	const [openAdvance, setOpenAdvance] = useState(true);
	const { classes } = useStyles();

	const handleBlurNumberSession = (e) => {
		let value = e.target.value === '' ? sessionDueLateAssignment || sessionsDefault : Number(e.target.value);
		if (value > SESSION_DUE.MAX) {
			value = SESSION_DUE.MAX;
		} else if (value < SESSION_DUE.MIN) {
			value = SESSION_DUE.MIN;
		}
		if (value !== null) {
			handleChange('sessionDueLate', value);
		}
	};

	const handleBlurDeduct = (e) => {
		let value = e.target.value === '' ? deductAssignment || deductDefault : Number(e.target.value);
		if (value > MAXIMUM_DEDUCTION.MAX) {
			value = MAXIMUM_DEDUCTION.MAX;
		} else if (value < MAXIMUM_DEDUCTION.MIN) {
			value = MAXIMUM_DEDUCTION.MIN;
		}
		handleChange('deduct', value);
	};

	const handleBlurMaximumDeduct = (e) => {
		let value =
			e.target.value === '' ? lowestGradePercentAssignment || maximumDeductionDefault : Number(e.target.value);
		if (value > MAXIMUM_DEDUCTION.MAX) {
			value = MAXIMUM_DEDUCTION.MAX;
		} else if (value < MAXIMUM_DEDUCTION.MIN) {
			value = MAXIMUM_DEDUCTION.MIN;
		}
		handleChange('lowestGradePercent', value);
	};

	return (
		<Box className={classes.root}>
			<CollapseOptions
				open={openAdvance}
				onClose={() => setOpenAdvance(!openAdvance)}
				title={t('late_completion_rules')}
			>
				<Box mt={3}>
					<Radio
						className={classes.radio}
						disabled={disableEditField || disabled}
						name='allowLateTurnIn'
						options={ALLOW_LATE}
						label={t('allow_late')}
						onChange={(value) => {
							const allowLate = JSON.parse(value);
							handleChange('allowLateTurnIn', allowLate);
						}}
						value={!!(values?.allowLateTurnIn ?? allowLateTurnInAssignment ?? lateCompletionDefault)}
						// value={true}
					/>
				</Box>
				<Collapse in={values.allowLateTurnIn}>
					<Box className={classes.completeBy}>
						<Radio
							className={classes.radio}
							disabled={disableEditField || disabled}
							name='completeBy'
							options={COMPLETE_BY}
							value={values?.completeBy ?? completeByAssignment ?? mustCompleteDefault ?? SESSIONS.END}
							label={t('must_complete_by')}
							onChange={(value) => {
								handleChange('completeBy', Number(value));
								if (Number(value) === SESSIONS.END) {
									handleChange('sessionDueLate', sessionsDefault);
								}
							}}
						/>
					</Box>
					<Collapse in={values.completeBy === SESSIONS.CUSTOM}>
						<Box className={classes.inputCompleteBy}>
							<TextField
								width={'100%'}
								name='sessionDueLate'
								type='number'
								disabled={disableEditField || disabled}
								value={values?.sessionDueLate ?? sessionDueLateAssignment ?? sessionsDefault}
								onChange={(e) => {
									handleChange('sessionDueLate', Number(e.target.value));
								}}
								onBlur={handleBlurNumberSession}
								error={!!(errors.sessionDueLate && touched.sessionDueLate)}
								helperText={!!(errors.sessionDueLate && touched.sessionDueLate) ? errors.sessionDueLate : false}
								endAdornment={
									<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
										{'session(s)'}
									</Typography>
								}
								inputProps={{ maxLength: MAX_LENGTH }}
							/>
						</Box>
					</Collapse>
				</Collapse>
				<Collapse in={values.allowLateTurnIn && Number(values?.gradeType) === GRADE_TYPE.GRADED}>
					<Box mt={3}>
						<Radio
							className={classes.radio}
							disabled={disableEditField || disabled}
							options={PARTIAL_CREDIT}
							name='partialCredit'
							label={t('partial_credit_for_late_work')}
							value={!!(values?.partialCredit ?? partialCreditAssignment ?? partialCreditDefault)}
							onChange={(value) => {
								handleChange('partialCredit', JSON.parse(value));
								if (JSON.parse(value) === gradingLateCompletion?.partialCredit) {
									handleChange('deduct', values?.deductDefault);
									handleChange('deductType', values?.deductTypeDefault);
									handleChange('lowestGradePercent', values?.lowestGradePercentDefault);
								}
							}}
						/>
					</Box>
					<Collapse in={values.partialCredit}>
						<Box className={classes.deduct}>
							<TextField
								disabled={disableEditField || disabled}
								className={classes.inputDeduct}
								required
								name='deduct'
								label={
									<Box display='flex'>
										{t('deduct')}
										<Tooltip
											title={t('myCourses:number_range', { min: MAXIMUM_DEDUCTION.MIN, max: MAXIMUM_DEDUCTION.MAX })}
											placement='top'
										>
											<Box ml={'3px'} mt={'2px'}>
												<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
											</Box>
										</Tooltip>
									</Box>
								}
								type='number'
								endAdornment={
									<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
										{'%'}
									</Typography>
								}
								onBlur={handleBlurDeduct}
								value={values?.deduct ?? deductAssignment ?? deductDefault}
								onChange={(e) => handleChange('deduct', Number(e.target.value))}
								error={!!(errors.deduct && touched.deduct)}
								helperText={!!(errors.deduct && touched.deduct) ? errors.deduct : false}
								inputProps={{ maxLength: MAX_LENGTH }}
							/>
							<Dropdown
								multiple={false}
								className={classes.inputApplyDeduct}
								disabled={disableEditField || disabled}
								disableFilter
								disableCloseOnSelect={false}
								label={t('apply_deduction')}
								width={'100%'}
								name='deductType'
								value={
									APPLY_DEDUCTION.find(({ value }) => values?.deductType?.value === value) ??
									APPLY_DEDUCTION.find(({ value }) => deductTypeAssignment === value) ??
									APPLY_DEDUCTION.find(({ value }) => applyDeductionDefault === value) ??
									APPLY_DEDUCTION[0]
								}
								options={APPLY_DEDUCTION}
								disableClearable
								onChange={(value) => {
									if (value === null) {
										value = values.deductTypeDefault;
									}
									handleChange('deductType', value);
								}}
								error={!!(errors.deductType && touched.deductType)}
								helperText={!!(errors.deductType && touched.deductType) ? errors.deductType : false}
							/>
						</Box>
						<Box mt={3}>
							<TextField
								disabled={disableEditField || disabled}
								className={classes.inputLowest}
								required
								name='lowestGradePercent'
								label={
									<Box display='flex'>
										{t('maximum_deduction')}
										<Tooltip
											title={`${t('myCourses:maximum_deduct_indicator')} ${t('myCourses:number_range', {
												min: MAXIMUM_DEDUCTION.MIN,
												max: MAXIMUM_DEDUCTION.MAX,
											})}`}
											placement='top'
										>
											<Box ml={'3px'} mt={'2px'}>
												<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
											</Box>
										</Tooltip>
									</Box>
								}
								type='number'
								onBlur={handleBlurMaximumDeduct}
								value={values?.lowestGradePercent ?? lowestGradePercentAssignment ?? maximumDeductionDefault}
								onChange={(e) => handleChange('lowestGradePercent', Number(e.target.value))}
								error={!!(errors.lowestGradePercent && touched.lowestGradePercent)}
								inputProps={{ maxLength: MAX_LENGTH }}
								helperText={
									!!(errors.lowestGradePercent && touched.lowestGradePercent) ? errors.lowestGradePercent : false
								}
								endAdornment={
									<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[400] }}>
										{'%'}
									</Typography>
								}
							/>
						</Box>
					</Collapse>
				</Collapse>
			</CollapseOptions>
		</Box>
	);
}
export default LateTurnIn;
