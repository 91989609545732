import { useEffect, useState } from 'react';

import Time from './Time';
import useInterval from './useInterval';

export default function useStopwatch({ autoStart, offsetTimestamp, currentTime, isCountDown }) {
	const [passedSeconds, setPassedSeconds] = useState(
		Time.getSecondsFromExpiry(offsetTimestamp, true, currentTime) || 0,
	);

	const [prevTime, setPrevTime] = useState(new Date());
	const [seconds, setSeconds] = useState(passedSeconds + Time.getSecondsFromPrevTime(prevTime || 0, true));
	const [isRunning, setIsRunning] = useState(autoStart);

	useEffect(() => {
		if (seconds !== currentTime) {
			setSeconds(currentTime);
			setPassedSeconds(Time.getSecondsFromExpiry(offsetTimestamp, true, currentTime) || 0);
		}
	}, [currentTime, offsetTimestamp]);

	useInterval(
		() => {
			if (isCountDown) {
				if (seconds === 0) {
					setIsRunning(false);
				} else {
					setSeconds(passedSeconds - Time.getSecondsFromPrevTime(prevTime, true));
				}
			} else {
				setSeconds(passedSeconds + Time.getSecondsFromPrevTime(prevTime, true));
			}
		},
		isRunning ? 1000 : null,
	);

	function start() {
		const newPrevTime = new Date();
		setPrevTime(newPrevTime);
		setIsRunning(true);
		setSeconds(passedSeconds + Time.getSecondsFromPrevTime(newPrevTime, true));
	}

	function pause() {
		setPassedSeconds(seconds);
		setIsRunning(false);
	}

	function reset(offset = 0, newAutoStart = true, isCountDown = false) {
		const newPassedSeconds = isCountDown
			? Time.getSecondsFromExpiry(null, true, offset) || 0
			: Time.getSecondsFromExpiry(offset, true) || 0;

		const newPrevTime = new Date();
		setPrevTime(newPrevTime);
		setPassedSeconds(newPassedSeconds);
		setIsRunning(newAutoStart);
		isCountDown
			? setSeconds(newPassedSeconds - Time.getSecondsFromPrevTime(newPrevTime, true))
			: setSeconds(newPassedSeconds + Time.getSecondsFromPrevTime(newPrevTime, true));
	}

	return {
		...Time.getTimeFromSeconds(seconds),
		start,
		pause,
		reset,
		isRunning,
	};
}
