import { useCallback, useState } from 'react';

import { exception } from './constants';

export default function useOnClickOutside(ref, initialIsVisible, disabled, callback) {
	const [clickedOutside, setClickedOutside] = useState(initialIsVisible);

	const handleClickOutside = useCallback(
		(e) => {
			const mediaEl = document.getElementById('media-lib');

			//Click on filter in media, it generates element which is not inside mediaEl
			//Make it as child element so it won't trigger click outside
			//This is workaround to fix bug 500 when inserted img after filtered
			const mediaFilter = document.getElementById('media-filter');

			if (disabled || (mediaEl && mediaEl.contains(e.target)) || (mediaFilter && mediaFilter.contains(e.target))) {
				return;
			}

			const identifierElement = e.target.id ? e.target.id : e.target.className;
			if (!ref.current.contains(e.target) && !Object.values(exception).includes(identifierElement)) {
				if (callback) {
					callback();
				}
				setClickedOutside(true);
			}
		},
		[callback, disabled, ref],
	);

	const addEventListener = () => document.addEventListener('mousedown', handleClickOutside);
	const removeEventListener = () => document.removeEventListener('mousedown', handleClickOutside);

	return { clickedOutside, setClickedOutside, addEventListener, removeEventListener };
}
