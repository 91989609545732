const picker = {
	plugin: undefined,
	haveBeenOpen: false,
};
export default Object.freeze({
	init: (maxItemCanBeSelected, callBack, accessToken) => {
		if (picker.plugin) {
			picker.plugin.setCallback(callBack);
			return;
		}

		const google = window.google;
		// const uploadView = new google.picker.DocsUploadView();
		const view = new google.picker.DocsView().setOwnedByMe(true);
		picker.plugin = new google.picker.PickerBuilder()
			.addView(view)
			.setMaxItems(maxItemCanBeSelected <= 0 ? -1 : maxItemCanBeSelected)
			.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
			.setOAuthToken(accessToken) //access token fetched from server
			.setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_API_KEY)
			.setCallback(callBack)
			.build();
	},
	open: () => {
		if (picker.haveBeenOpen) {
			return;
		}
		if (picker.plugin) {
			picker.plugin.setVisible(true);
			picker.haveBeenOpen = true;
		}
	},
	close: () => {
		picker.haveBeenOpen = false;
	},
	reset: () => {
		picker.plugin = undefined;
		picker.haveBeenOpen = false;
	},
});
