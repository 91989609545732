import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel } from '@mui/material';

import TblTooltipDynamic from 'components/TblTooltipDynamic';

import { PUBLISH_NOW } from 'shared/MyCourses/constants';

import clsx from 'clsx';
import { Box, Checkbox, Dropdown, Typography } from 'ella-storybook';
import { Field } from 'formik';
import { get, isEqual } from 'lodash';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';

import Delayed from './DelayedComponent';

const Sections = ({
	sections,
	handleChange,
	values,
	errors,
	touched,
	handleBlur,
	setFieldTouched,
	disableAll,
	showSectionName,
	metaDataSchedule,
	hasUpdated,
	setFieldValue,
}) => {
	const defaultValue = null;
	const assignTitle = get(metaDataSchedule, 'assignTitle', '');
	const dueTitle = get(metaDataSchedule, 'dueTitle', '');
	const hideDue = get(metaDataSchedule, 'hideDue', false);
	const publishNowLabel = get(metaDataSchedule, 'publishNow', '');
	const activityType = get(metaDataSchedule, 'activityType', '');

	const [firstRender, setFirstRender] = useState(false);

	useEffect(() => {
		setFirstRender(true);
	}, []);

	const newScheduleRef = useRef();

	const MemoComponent = useMemo(() => memo(({ children }) => children), [sections, disableAll]);

	const { t } = useTranslation();

	const publishNowOnAll = (e, props) => {
		const { value, item, onChange } = props;
		e.preventDefault(); // prevent blur

		if (get(value, 'value') === PUBLISH_NOW && get(value, 'index') === get(item, 'index')) {
			onChange && onChange(null);
			return;
		}
		if (onChange) {
			onChange(item);
		}
	};

	const stickyOptions = (onSelect, idx) => [
		{
			value: PUBLISH_NOW,
			label: publishNowLabel,
			disabled: false,
			index: idx ?? 0,
			control: ({ value, disabled, onChange, item }) => {
				return (
					<FormControlLabel
						onClick={(e) => {
							onSelect(e, { value, disabled, onChange, item });
						}}
						sx={{
							margin: 0,
							'.MuiFormControlLabel-root': {
								marginRight: 0,
							},
						}}
						label={publishNowLabel}
						control={
							<Checkbox checked={get(value, 'value') === PUBLISH_NOW && get(item, 'index') === get(value, 'index')} />
						}
					/>
				);
			},
		},
	];

	return (
		<Box display={'flex'} gap={2}>
			<Box display={'flex'} flexDirection={'column'} gap={0.5}>
				<Box display={'flex'}>
					{showSectionName && <Box width={(theme) => theme.spacing(11.625)} />}
					{assignTitle && (
						<Typography
							component={'div'}
							variant='labelMedium'
							width={(theme) => theme.spacing(26.75)}
							height={(theme) => theme.spacing(2.5)}
							sx={{ color: (theme) => theme.newColors.gray[600] }}
						>
							{assignTitle}
						</Typography>
					)}
					{dueTitle && (
						<Typography
							component={'div'}
							variant='labelMedium'
							width={(theme) => theme.spacing(25.625)}
							height={(theme) => theme.spacing(2.5)}
							sx={{ color: (theme) => theme.newColors.gray[600] }}
						>
							{dueTitle}
						</Typography>
					)}
				</Box>
				<Box display={'flex'} flexDirection={'column'} gap={1}>
					{(sections || []).map((section, index) => (
						<Box display={'flex'} gap={1.25}>
							{showSectionName && (
								<Typography
									variant='bodyMediumRegular'
									component={'div'}
									className={clsx('text-ellipsis')}
									display={'flex'}
									alignItems={'center'}
									justifyContent={'center'}
									width={'83px'}
									color={(theme) => theme.newColors.gray[800]}
								>
									<TblTooltipDynamic title={section.sectionName}>
										<>{section.sectionName}</>
									</TblTooltipDynamic>
								</Typography>
							)}
							<Box
								ref={newScheduleRef}
								display={'flex'}
								gap={2}
								width={(theme) => theme.spacing(50.75)}
								sx={{
									'& .MuiFormHelperText-root': {
										visibility: disableAll ? 'hidden !important' : 'visible !important',
									},
								}}
							>
								<MemoComponent>
									<Delayed waitBeforeShow={disableAll ? index : index + 1} noDelay={firstRender}>
										<Box
											key={`section-assign-${disableAll ? 'old' : 'new'}-${section.sectionId}`}
											// width={(theme) => theme.spacing(25.625)}
											flex={'1 1 205px'}
										>
											<Field
												disabled={disableAll || section.isPublish || hasUpdated}
												// disabled={disableAll}
												// open // remember to delete
												freeSolo={disableAll}
												component={Dropdown}
												fitContent={true}
												disableClearable={true}
												multiple={false}
												allowTooltipOnInputField={true}
												disableCloseOnSelect={disableAll}
												placement={'bottom-start'}
												isOptionEqualToValue={(option, inputOption) => option.value === inputOption.value}
												width={'100%'}
												height={'40px'}
												name={`sections.${index}.assignDate`}
												onClose={() => {
													handleBlur(`sections.${index}.assignDate`, true, setFieldTouched);
												}}
												value={
													disableAll
														? get(section, 'old.assign.label', '')
														: get(values, `sections.[${index}].assignDate`, defaultValue)
												}
												placeholder={t('common:please_select')}
												options={section.assignOption}
												onChange={(value) => {
													handleChange(
														`sections.${index}.assignDate`,
														value,
														index,
														'assignDate',
														values,
														setFieldValue,
													);
													if (!value) {
													}
												}}
												helperText={
													get(touched, `sections.[${index}].assignDate`) &&
													get(errors, `sections.[${index}].assignDate`, '')
												}
												error={
													!!(
														get(touched, `sections.[${index}].assignDate`) &&
														get(errors, `sections.[${index}].assignDate`)
													)
												}
												stickyOptions={
													activityType === ACTIVITY_CATEGORY.LESSON || disableAll
														? {}
														: stickyOptions(publishNowOnAll, index)
												}
												disableAllOption={get(values, `sections.[${index}].assignDate.value`, '') === PUBLISH_NOW}
											/>
										</Box>
									</Delayed>
								</MemoComponent>
								{!hideDue && (
									<MemoComponent>
										<Delayed waitBeforeShow={disableAll ? index : index + 1} noDelay={firstRender}>
											<Box
												key={`section-due-${disableAll ? 'old' : 'new'}-${section?.sectionId}`}
												// width={(theme) => theme.spacing(25.625)}
												flex={'1 1 205px'}
											>
												<Field
													disabled={disableAll || hasUpdated}
													freeSolo={disableAll}
													component={Dropdown}
													disableClearable={true}
													disableCloseOnSelect={disableAll}
													multiple={false}
													fitContent={true}
													allowTooltipOnInputField={true}
													isOptionEqualToValue={(option, inputOption) => option.value === inputOption.value}
													placement={'bottom-start'}
													width={'100%'}
													name={`sections.${index}.dueDate`}
													placeholder={t('common:please_select')}
													onClose={() => {
														handleBlur(`sections.${index}.dueDate`, true, setFieldTouched);
													}}
													value={
														disableAll
															? get(section, 'old.due.label', '')
															: get(values, `sections.[${index}].dueDate`, defaultValue)
													}
													options={section.dueOption}
													onChange={(value) => {
														handleChange(`sections.${index}.dueDate`, value, index, 'dueDate', values, setFieldValue);
													}}
													helperText={
														(get(touched, `sections.[${index}].assignDate`) ||
															get(touched, `sections.[${index}].dueDate`)) &&
														get(errors, `sections.[${index}].dueDate`, '')
													}
													error={
														!!(
															(get(touched, `sections.[${index}].assignDate`) ||
																get(touched, `sections.[${index}].dueDate`)) &&
															get(errors, `sections.[${index}].dueDate`)
														)
													}
												/>
											</Box>
										</Delayed>
									</MemoComponent>
								)}
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};

Sections.propTypes = {
	disableAll: PropTypes.bool,
	errors: PropTypes.oneOfType([
		PropTypes.shape({
			sections: PropTypes.any,
		}),
		PropTypes.object,
	]),
	touched: PropTypes.oneOfType([
		PropTypes.shape({
			sections: PropTypes.any,
		}),
		PropTypes.object,
	]),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	metaDataSchedule: PropTypes.oneOfType([
		PropTypes.shape({
			assignTitle: PropTypes.string,
			dueTitle: PropTypes.string,
			hideDue: PropTypes.bool,
		}),
		PropTypes.object,
	]),
	sections: PropTypes.array,
	sessions: PropTypes.array,
	showSectionName: PropTypes.bool,
	values: PropTypes.shape({
		sections: PropTypes.array,
	}),
};

Sections.defaultProps = {
	disableAll: false,
	showSectionName: false,
	metaDataSchedule: null,
	sections: [],
	sessions: [],
	hasUpdated: false,
};

export default memo(Sections, (prev, curr) => {
	const keys = Object.keys(prev);

	// keys.forEach((k, index) => {
	// 	if (!isEqual(curr[k], prev[k])) {
	// 		console.log(`not equal${ index}`, prev, k, curr[k], prev[k]);
	// 	}
	// });
	return isEqual(prev, curr);
});
