import { memo, useEffect, useRef, useState } from 'react';

const Delayed = ({ children, waitBeforeShow, isLastItem, forceRenderFunc, noDelay }) => {
	const [isShown, setIsShown] = useState(false);
	const timeRef = useRef();

	useEffect(() => {
		timeRef.current = setTimeout(() => {
			setIsShown(true);
		}, waitBeforeShow);

		return () => {
			clearTimeout(timeRef.current);
		};
	}, [waitBeforeShow, isLastItem]);

	if (noDelay) return children;
	return isShown ? children : null;
};

Delayed.defaultProps = {
	waitBeforeShow: 50,
	noDelay: false,
	isLastItem: false,
};

export default memo(Delayed);
