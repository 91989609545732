import difference from 'lodash/difference';

import { LOCAL_STORAGE } from 'utils/constants';

import { actions as authActions } from 'shared/Auth/constants';

import i18next from 'i18next';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import notificationActions from './actions';
import { END_POINT, NOTIFICATION_STATUS, actions } from './constants';

const setDeviceTokenEpic = (action$) =>
	action$.pipe(
		ofType(
			authActions.AUTH_LOGIN_SUCCESS,
			actions.SETUP_DEVICE_TOKEN,
			// actions.GET_SSE_TOKEN_SUCCESS
		),
		filter((action) => action.payload.token),
		switchMap((action) =>
			makeAjaxRequest(END_POINT.setup_device_token.method, END_POINT.setup_device_token.url, {
				deviceId: action.payload.token,
			}).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return notificationActions.setupDeviceTokenFailed();
					}
					return of(notificationActions.setupDeviceTokenSuccess());
				}),
				catchError(() => of(notificationActions.setupDeviceTokenFailed())),
			),
		),
	);

const getSseTokenEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SSE_TOKEN),
		switchMap(() =>
			makeAjaxRequest(
				END_POINT.sse_token.method,
				END_POINT.sse_token.url(),
				// action.payload
			).pipe(
				mergeMap((data) => {
					localStorage.setItem(LOCAL_STORAGE.DEVICE_TOKEN, data.response.token);
					return of(
						notificationActions.getSseTokenSuccess({
							token: data.response.token,
							getSseTokenSuccess: true,
							isBusy: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						notificationActions.getSseTokenFailed({
							error: error?.response?.errors || {
								message: i18next.t('error:general_error'),
							},
							getSseTokenFailed: true,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const replySseEpic = (action$) =>
	action$.pipe(
		ofType(actions.RECEIVED_SSE),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.reply_sse.method, END_POINT.reply_sse.url(), action.payload).pipe(
				mergeMap(() =>
					of(
						notificationActions.replySseSuccess({
							replySseSuccess: true,
						}),
					),
				),
				catchError(() =>
					of(
						notificationActions.replySseSuccess({
							replySseSuccess: false,
						}),
					),
				),
			),
		),
	);

const startUrgentTaskEpic = (action$) =>
	action$.pipe(
		ofType(actions.START_URGENT_TASK),
		debounceTime(1000),
		switchMap((action) => {
			const { notificationId, notificationData } = action.payload;
			return makeAjaxRequest(END_POINT.start_urgent_task.method, END_POINT.start_urgent_task.url(), {
				notificationId,
				action: action.payload.action,
			}).pipe(
				mergeMap(() =>
					of(
						notificationActions.startUrgentTaskSuccess({
							startUrgentTaskSuccess: true,
							isBusy: false,
							notificationData,
						}),
					),
				),
				catchError((error) =>
					of(
						notificationActions.startUrgentTaskFailed({
							error: error?.response?.code,
							startUrgentTaskFailed: true,
							notificationData: {
								...notificationData,
								data: {
									...notificationData.data,
									taskId: error?.response?.errors?.detail?.taskId || notificationData.data.taskId,
									taskStatus: error?.response?.errors?.detail?.taskStatus || notificationData.data.taskStatus,
								},
							},
						}),
					),
				),
			);
		}),
	);

const sendNudgeTextEpic = (action$) =>
	action$.pipe(
		ofType(actions.SEND_NUDGE_TEXT),
		switchMap((action) =>
			makeAjaxRequest(END_POINT.send_nudge_text.method, END_POINT.send_nudge_text.url(), action.payload).pipe(
				mergeMap(() =>
					of(
						notificationActions.sendNudgeTextSuccess({
							sendNudgeTextSuccess: true,
							isBusy: false,
						}),
					),
				),
				catchError((error) =>
					of(
						notificationActions.sendNudgeTextFailed({
							error: error?.response?.errors,
							sendNudgeTextFailed: true,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const getNotificationsEpic = (action$, state$) =>
	action$.pipe(
		ofType(authActions.AUTH_LOGIN_SUCCESS, actions.GET_NOTIFICATIONS),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_notifications.method,
				END_POINT.get_notifications.url({
					studentId: payload?.studentId,
					cursor: payload?.cursor,
					perPage: payload?.perPage || 10,
				}),
				// action.payload
			).pipe(
				mergeMap((data) => {
					const currNotifications = state$?.value?.Notification?.notifications?.length
						? [...state$?.value?.Notification?.notifications]
						: [];

					const currentPerPage = payload?.perPage || 10;

					let latestNotification = data?.response?.notifications || [];

					let isThatAll = true;

					const currNotiKeys = currNotifications.map((noti) => noti.id);
					const latestNotiKeys = latestNotification.map((noti) => noti.id);

					const notiKeys = difference(latestNotiKeys, currNotiKeys);

					latestNotification = notiKeys.length
						? latestNotification.filter((noti) => notiKeys.includes(noti?.id))
						: latestNotification;

					if (latestNotification.length) {
						payload.loadMore
							? currNotifications.push(...latestNotification)
							: notiKeys.length && currNotifications.unshift(...latestNotification);
					}

					const lastNotification = currNotifications[currNotifications.length - 1];

					return of(
						notificationActions.getNotificationsSuccess({
							notifications: currNotifications.length ? currNotifications : [],
							totalNew: data?.response?.total_new || 0,
							cursor: data?.response?.cursor || null,
							timeClick: data?.response?.timeClick || null,
							lastNotification,
							isGettingNotifications: false,
							isThatAll: latestNotification.length === currentPerPage ? false : isThatAll,
						}),
					);
				}),
				catchError((error) =>
					of(
						notificationActions.getNotificationsFailed({
							error: error?.response?.errors || {
								message: i18next.t('error:general_error'),
							},
							isGettingNotifications: false,
						}),
					),
				),
			),
		),
	);

const markReadEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MARK_READ),
		switchMap(({ payload }) =>
			makeAjaxRequest(END_POINT.mark_read.method, END_POINT.mark_read.url(payload?.notificationId)).pipe(
				mergeMap(() => {
					const currNotifications = state$?.value?.Notification?.notifications?.length
						? [...state$?.value?.Notification?.notifications]
						: [];

					if (currNotifications.length) {
						const index = currNotifications.findIndex((notification) => notification.id === payload?.notificationId);

						if (index !== -1) {
							currNotifications[index].status = NOTIFICATION_STATUS.READ;
						}
					}

					return of(
						notificationActions.markReadSuccess({
							notifications: currNotifications,
						}),
					);
				}),
				catchError((error) =>
					of(
						notificationActions.markReadFailed({
							error: error?.response?.errors || {
								message: i18next.t('error:general_error'),
							},
							isGettingNotifications: false,
						}),
					),
				),
			),
		),
	);

const markAllReadEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MARK_ALL_READ),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.mark_all_read.method,
				END_POINT.mark_all_read.url({
					studentId: payload?.studentId,
				}),
			).pipe(
				mergeMap(() => {
					const currNotifications = state$?.value?.Notification?.notifications?.length
						? [...state$?.value?.Notification?.notifications]
						: [];

					if (currNotifications.length) {
						currNotifications.forEach((_, index) => (currNotifications[index].status = NOTIFICATION_STATUS.READ));
					}

					return of(
						notificationActions.markAllReadSuccess({
							notifications: currNotifications.length ? currNotifications : [],
							totalNew: 0,
						}),
					);
				}),
				catchError((error) =>
					of(
						notificationActions.markAllReadFailed({
							error: error?.response?.errors || {
								message: i18next.t('error:general_error'),
							},
							isGettingNotifications: false,
						}),
					),
				),
			),
		),
	);

const markFirstReadEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.MARK_FIRST_READ),
		switchMap(({ payload }) =>
			makeAjaxRequest(END_POINT.mark_first_read.method, END_POINT.mark_first_read.url(payload?.studentId), {
				timeClick: payload?.timeClick,
			}).pipe(
				mergeMap(() =>
					of(
						notificationActions.markFirstReadSuccess({
							timeClick: payload?.timeClick,
						}),
					),
				),
				catchError((error) =>
					of(
						notificationActions.markFirstReadFail({
							error: error?.response?.errors || {
								message: i18next.t('error:general_error'),
							},
						}),
					),
				),
			),
		),
	);

export default [
	setDeviceTokenEpic,
	getSseTokenEpic,
	sendNudgeTextEpic,
	startUrgentTaskEpic,
	replySseEpic,
	getNotificationsEpic,
	markReadEpic,
	markAllReadEpic,
	markFirstReadEpic,
];
