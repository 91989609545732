/* eslint-disable max-len */
import { memo } from 'react';

import { MenuItem } from '@mui/material';

import TblInputs from 'components/TblInputs';
import TblSelect from 'components/TblSelect';

import { Box } from 'ella-storybook';

const FilterArea = ({
	translate,
	hasFilter,
	searchValueInput,
	handleSearch,
	handleSelectSection,
	sections = [],
	selectedSection,
}) => {
	const _sections = [{ id: -1, sectionName: translate('myCourses:all_sections') }, ...(sections ?? [])];

	return hasFilter ? (
		<Box display={'flex'} gap={2}>
			<TblInputs
				value={searchValueInput}
				placeholder={translate('myCourses:enter_student_name')}
				onChange={handleSearch}
				hasSearchIcon={true}
				hasClearIcon={true}
				sx={{ maxWidth: (theme) => theme.spacing(25.625) }}
			/>
			<Box sx={{ width: (theme) => theme.spacing(25) }}>
				<TblSelect value={selectedSection} onChange={handleSelectSection}>
					{_sections.map((section) => (
						<MenuItem key={section.id} value={section.id} sx={{ display: 'block !important', wordWrap: 'break-word' }}>
							{section.sectionName}
						</MenuItem>
					))}
				</TblSelect>
			</Box>
		</Box>
	) : (
		<Box />
	);
};

export default memo(FilterArea);
