import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

import myProfileActions from '../actions';
import AddPhoneNumber from '../components/VerifyPhone/AddPhoneNumber';
import EnterVerifyCode from '../components/VerifyPhone/EnterVerifyCode';
import VerifyPhoneSuccess from '../components/VerifyPhone/VerifyPhoneSuccess';

import OneMoreStep from './OneMoreStep';

const OnBoardingProfile = (props) => {
	const { isOnBoarding } = props;
	const location = useLocation();
	const history = useHistory();

	const [step, setStep] = useState(null);

	const authContext = useContext(AuthDataContext);
	const dispatch = useDispatch();

	const isChangeProfileSuccess = useSelector((state) => state?.MyProfile?.isChangeProfileSuccess);

	const currentUser = authContext?.currentUser || {};
	const { organizationId, phone, settings } = currentUser;

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(location.search);
		const stepParam = urlSearchParams.get('step');

		if (!stepParam) history.replace({ search: 'step=1' });

		// return () => {
		//   localStorage.removeItem('backAddPhone');
		// };
	}, []);

	useEffect(() => {
		window.addEventListener('popstate', onBackButtonEvent);

		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);
		};
	}, [location, history]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(location.search);
		const stepParam = urlSearchParams.get('step');
		const backAddPhone = localStorage.getItem('backAddPhone');

		if (parseInt(stepParam) > 1 && !backAddPhone) {
			history.replace({ search: 'step=1' });
		}

		// if (parseInt(stepParam) > 2 && backVerifyCode && !backAddPhone) {
		//   history.replace({ search: 'step=2' });
		//   localStorage.removeItem('backVerifyCode');
		// }

		if (!!parseInt(stepParam)) {
			setStep((prev) => (prev !== +stepParam ? +stepParam : prev));
		}

		isChangeProfileSuccess && dispatch(myProfileActions.resetMyProfileReducer({ isChangeProfileSuccess: false }));
	}, [location]);

	const renderStep = useMemo(() => {
		switch (step) {
			case 1:
				return <AddPhoneNumber {...{ isOnBoarding }} />;
			case 2:
				return <EnterVerifyCode {...{ isOnBoarding }} />;
			case 3:
				return <VerifyPhoneSuccess {...{ isOnBoarding }} />;
			case 4:
				return <OneMoreStep {...{ isOnBoarding }} />;
			default:
				return <></>;
		}
	}, [step]);

	const onBackButtonEvent = useCallback(
		(e) => {
			e.preventDefault();

			const backAddPhone = localStorage.getItem('backAddPhone');

			if (backAddPhone) {
				history.replace({ search: 'step=1' });
				return;
			}
		},
		[location],
	);
	return renderStep;
};
OnBoardingProfile.defaultProps = { isOnBoarding: false };

export default OnBoardingProfile;
