import { objectToParams } from 'utils';

export const actions = {
	AUTH_RESET: 'AUTH_RESET',
	AUTH_SET_STATE: 'AUTH_SET_STATE',
	AUTH_LOGIN: 'AUTH_LOGIN',
	AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
	AUTH_LOGIN_FAILED: 'AUTH_LOGIN_FAILED',
	AUTH_CHECK_DOMAIN_STATUS: 'AUTH_CHECK_DOMAIN_STATUS',
	AUTH_CHECK_DOMAIN_SUCCESS: 'AUTH_CHECK_DOMAIN_SUCCESS',
	AUTH_CHECK_DOMAIN_FAILED: 'AUTH_CHECK_DOMAIN_FAILED',
	AUTH_FETCH_USER: 'AUTH_FETCH_USER',
	AUTH_FETCH_USER_SUCCESS: 'AUTH_FETCH_USER_SUCCESS',
	AUTH_FETCH_USER_FAILED: 'AUTH_FETCH_USER_FAILED',
	AUTH_LOGOUT: 'AUTH_LOGOUT',
	AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
	AUTH_LOGOUT_FAILED: 'AUTH_LOGOUT_FAILED',
	AUTH_FORGOT_PASSWORD: 'AUTH_FORGOT_PASSWORD',
	AUTH_FORGOT_PASSWORD_SUCCESS: 'AUTH_FORGOT_PASSWORD_SUCCESS',
	AUTH_FORGOT_PASSWORD_FAILED: 'AUTH_FORGOT_PASSWORD_FAILED',
	AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',
	AUTH_RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
	AUTH_RESET_PASSWORD_FAILED: 'AUTH_RESET_PASSWORD_FAILED',
	AUTH_CHECK_TOKEN: 'AUTH_CHECK_TOKEN',
	AUTH_CHECK_TOKEN_SUCCESS: 'AUTH_CHECK_TOKEN_SUCCESS',
	AUTH_CHECK_TOKEN_FAILED: 'AUTH_CHECK_TOKEN_FAILED',
	SETUP_DOMAIN: 'SETUP_DOMAIN',
	SETUP_DOMAIN_SUCCESS: 'SETUP_DOMAIN_SUCCESS',
	SETUP_DOMAIN_FAILED: 'SETUP_DOMAIN_FAILED',
	ACCEPT_INVITATION_DOMAIN: 'ACCEPT_INVITATION_DOMAIN',
	ACCEPT_INVITATION_DOMAIN_SUCCESS: 'ACCEPT_INVITATION_DOMAIN_SUCCESS',
	ACCEPT_INVITATION_DOMAIN_FAILED: 'ACCEPT_INVITATION_DOMAIN_FAILED',
	GET_ORGANIZATION_INFO: 'GET_ORGANIZATION_INFO',
	GET_ORGANIZATION_INFO_SUCCESS: 'GET_ORGANIZATION_INFO_SUCCESS',
	GET_ORGANIZATION_INFO_FAILED: 'GET_ORGANIZATION_INFO_FAILED',
	AUTH_CONFIRM_EMAIL: 'AUTH_CONFIRM_EMAIL',
	AUTH_CONFIRM_EMAIL_FAILED: 'AUTH_CONFIRM_EMAIL_FAILED',
	AUTH_CONFIRM_EMAIL_SUCCESS: 'AUTH_CONFIRM_EMAIL_SUCCESS',

	GET_CURRENT_USER_SCHOOL_YEARS: 'GET_CURRENT_USER_SCHOOL_YEAR',
	GET_CURRENT_USER_SCHOOL_YEARS_SUCCESS: 'GET_CURRENT_USER_SCHOOL_YEARS_SUCCESS',
	GET_CURRENT_USER_SCHOOL_YEARS_FAILED: 'GET_CURRENT_USER_SCHOOL_YEARS_FAILED',

	GET_SCHOOL_GRADE_LEVEL: 'GET_SCHOOL_GRADE_LEVEL',
	GET_SCHOOL_GRADE_LEVEL_SUCCESS: 'GET_SCHOOL_GRADE_LEVEL_SUCCESS',
	GET_SCHOOL_GRADE_LEVEL_FAILED: 'GET_SCHOOL_GRADE_LEVEL_FAILED',

	GET_CURRENT_SCHOOL_YEAR_STATUS: 'GET_CURRENT_SCHOOL_YEAR_STATUS',
	GET_CURRENT_SCHOOL_YEAR_STATUS_SUCCESS: 'GET_CURRENT_SCHOOL_YEAR_STATUS_SUCCESS',
	GET_CURRENT_SCHOOL_YEAR_STATUS_FAILED: 'GET_CURRENT_SCHOOL_YEAR_STATUS_FAILED',

	VALIDATE_SETUP_COURSE: 'VALIDATE_SETUP_COURSE',
	VALIDATE_SETUP_COURSE_SUCCESS: 'VALIDATE_SETUP_COURSE_SUCCESS',
	VALIDATE_SETUP_COURSE_FAILED: 'VALIDATE_SETUP_COURSE_FAILED',

	GET_LIST_DOMAIN: 'GET_LIST_DOMAIN',
	GET_LIST_DOMAIN_SUCCESS: 'GET_LIST_DOMAIN_SUCCESS',
	GET_LIST_DOMAIN_FAILED: 'GET_LIST_DOMAIN_FAILED',
};

export const END_POINT = {
	check_setup_course: {
		method: 'GET',
		url: (urlParams) => `${process.env.REACT_APP_API_URL}/organization/setup-course?${objectToParams(urlParams)}`,
	},
	check_domain_status: {
		method: 'GET',
		url: (subdomain, email) =>
			`${process.env.REACT_APP_API_URL}/organization/status?subdomain=${subdomain}&email=${email}`,
	},
	login: {
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}/organization/session`,
	},
	get_list_domain: {
		method: 'GET',
		url: (email) => `${process.env.REACT_APP_API_URL}/organizations/list-domain?email=${email}`,
	},
	get_current_user: {
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}/organization/me`,
	},
	get_current_school_years_status: {
		method: 'GET',
		url: (orgId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/schoolYearStatus?${objectToParams(params)}`,
	},
	get_current_user_school_years: {
		method: 'GET',
		url: (orgId, userId, params) =>
			`${process.env.REACT_APP_API_URL}/organizations/${orgId}/users/${userId}/school-years?${objectToParams(params)}`,
	},
	logout: {
		method: 'DELETE',
		url: `${process.env.REACT_APP_API_URL}/organization/session`,
	},
	forgot_password: {
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}/organization/forgot-password`,
	},
	reset_password: {
		method: 'POST',
		url: (token) => `${process.env.REACT_APP_API_URL}/organizations/set-password?token=${token}`,
	},
	check_token: {
		method: 'GET',
		url: (token, type) => `${process.env.REACT_APP_API_URL}/check-token?token=${token}&type=${type}`,
	},
	get_organization_info: {
		method: 'GET',
		url: (token) => `${process.env.REACT_APP_API_URL}/organization/${token}/info`,
	},
	setup_domain: {
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL_V2}/organization/accept-invite`,
	},
	accept_inivation_domain: {
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}/organization/user-accept-invite`,
	},
	confirm_email: {
		method: 'PUT',
		url: (token) => `${process.env.REACT_APP_API_URL}/organization/confirm-email/${token}`,
	},
	setup_device_token: {
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}/organizations/users/devices`,
	},
	get_school_grade_level: {
		url: (organizationId) => `${process.env.REACT_APP_SCHOOL_PORTAL_URL}/grade-levels/organizations1/${organizationId}`,
		method: 'GET',
	},
};

export const SETUP_COURSE_CODE = {
	INVALIDATE_TOKEN: -4,
};

export const DOMAIN_STATUS = {
	PENDING: 0,
	ACTIVE: 1,
	SUSPEND: -1,
};

export const AUTHENTICATION_CODE = {
	TOKEN_INVALID: -4,
	ACCOUNT_CHANGE_EMAIL: -3,
	ACCOUNT_SUSPEND: -2,
	ACCOUNT_RESET_PWD: -1,
	DOMAIN_SUSPEND_PENDING: 1,
	INVALID_VALUE: 0,
	ACCOUNT_PENDING: 2,
	USER_NOT_FOUND: 3,
	DOMAIN_NOT_FOUND: 4,
};

export const PERMISSION_BASIC_KEYS = {
	CREATE: 'c',
	READ: 'r',
	UPDATE: 'u',
	DELETE: 'd',
};

export const MODULE_ALIAS = {
	USER_MANAGEMENT: 'usrmn',
	SUBJECTS: 'subj',
	GRADING_SCHEMA: 'grsch',
	SUBMISSIONS: 'crsasub',
	COURSE: 'crs',
	BOOSTER: 'crsab',
	ACTIVITY: 'crsa',
	SYLLABUS: 'crssyl',
	SECTION: 'crss',
	UNIT: 'crsun',
	TEACH: 'teass',
	SCHOOL_YEAR: 'schyr',
	DOMAIN_SETTING: 'ds',
	GRADING: 'gr',
	SUBMISSION_COMMENT: 'grcmt',
	GRADE_WEIGHTING: 'grw',
	STUDENT_CALENDAR: 'sc',
	TEACHER_DASHBOARD: 'td',
};

export const PERMISSIONS = {
	USER_MANAGEMENT: {
		...PERMISSION_BASIC_KEYS,
	},
	SUBJECT: {
		...PERMISSION_BASIC_KEYS,
	},
	GRADING_SCHEMA: {
		...PERMISSION_BASIC_KEYS,
	},
	SCHOOL_YEAR: {
		...PERMISSION_BASIC_KEYS,
		ARCHIVE: 'a',
		PUBLIC: 'p',
	},
	COURSE: {
		...PERMISSION_BASIC_KEYS,
		READ_PUBLIC: 'rp',
		PUBLISH: 'p',
	},
	COURSE_UNIT: {
		...PERMISSION_BASIC_KEYS,
	},
	COURSE_SECTION: {
		...PERMISSION_BASIC_KEYS,
		READ_PUBLIC: 'rp',
	},
	COURSE_SYLLABUS: {
		READ: PERMISSION_BASIC_KEYS.READ,
		UPDATE: PERMISSION_BASIC_KEYS.UPDATE,
	},
	COURSE_ACTIVITY: {
		...PERMISSION_BASIC_KEYS,
		READ_PUBLIC: 'rp',
		SCHEDULE: 'sch',
		UNSCHEDULE: 'unsch',
	},
	COURSE_ACTIVITY_BOOSTER: {
		READ: PERMISSION_BASIC_KEYS.READ,
		UPDATE: PERMISSION_BASIC_KEYS.UPDATE,
	},
	COURSE_ACTIVITY_SUBMISSION: {
		READ: PERMISSION_BASIC_KEYS.READ,
		CREATE: PERMISSION_BASIC_KEYS.CREATE,
		UPDATE: PERMISSION_BASIC_KEYS.UPDATE,
	},
	TEACH_SESSION: {
		...PERMISSION_BASIC_KEYS,
	},
	GRADING: {
		READ: PERMISSION_BASIC_KEYS.READ,
		UPDATE: PERMISSION_BASIC_KEYS.UPDATE,
		PUBLIC: 'p',
		READ_PUBLIC: 'rp',
	},
	SUBMISSION_COMMENT: {
		...PERMISSION_BASIC_KEYS,
	},
	GRADE_WEIGHTING: {
		UPDATE: PERMISSION_BASIC_KEYS.UPDATE,
	},
	STUDENT_CALENDAR: {
		READ: PERMISSION_BASIC_KEYS.READ,
	},
	TEACHER_DASHBOARD: {
		READ: PERMISSION_BASIC_KEYS.READ,
	},
};
