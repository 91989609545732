import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

const AttachmentPicker = forwardRef(({ onUpload, multiple, acceptType }, ref) => (
	<input
		type='file'
		ref={ref}
		onClick={(event) => (event.target.value = '')}
		accept={acceptType}
		onChange={onUpload}
		multiple={multiple}
		style={{ display: 'none' }}
	/>
));
AttachmentPicker.propTypes = {
	multiple: PropTypes.bool,
	acceptType: PropTypes.string,
	onUpload: PropTypes.func,
};

AttachmentPicker.defaultProps = {
	onUpload: () => {},
	multiple: true,
	acceptType: '*',
};
export default AttachmentPicker;
