import { cloneDeep, uniq } from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
	bufferTime,
	catchError,
	concatMap,
	distinctUntilChanged,
	filter,
	flatMap,
	map,
	mergeMap,
	switchMap,
} from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import schoolYearActions from './actions';
import { END_POINT, SCHOOL_YEAR_REPUBLISH_STATUS, SUB_CODE_SY, actions } from './constants';

const getSchoolYearListEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_YEAR_LIST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_school_year_list.method,
				END_POINT.get_school_year_list.url(action.payload.id, action.payload.urlParams),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.getSchoolYearListFailed({
							error: data.response.errors,
							isBusy: false,
						});
					}
					const schoolYearList = data.response.schoolYears;
					return of(
						schoolYearActions.getSchoolYearListSuccess({
							schoolYearList,
							isBusy: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						schoolYearActions.getSchoolYearListFailed({
							error: error.response.errors,
							errorCode: error.status,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const createSchoolYearEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_SCHOOL_YEAR),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.create_school_year.method,
				END_POINT.create_school_year.url(action.payload.orgId),
				action.payload.schoolYear,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.createSchoolYearSuccess({
							createSchoolYearFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.createSchoolYearSuccess({
							createSchoolYearSuccess: true,
							schoolYearDetail: data.response.schoolYear,
							// schoolYearValidation: data?.response?.validate
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						schoolYearActions.createSchoolYearFailed({
							createSchoolYearFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);

const getSchoolYearInformation = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_YEAR_INFORMATION),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_school_year_information.method,
				END_POINT.get_school_year_information.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.getSchoolYearInformationFailed({
							getSchoolYearInformationFailed: data.response.errors,
						});
					}
					if (data.response.code === 201) {
						const publishedSchoolYear = {
							id: data.response?.schoolYear?.publishedId,
						};
						return of(
							schoolYearActions.getSchoolYearInformationSuccess({
								schoolYearDetail: publishedSchoolYear,
								swapModeSuccess: true,
							}),
						);
					}
					return of(
						schoolYearActions.getSchoolYearInformationSuccess({
							getSchoolYearInformationSuccess: true,
							schoolYearDetail: data.response.schoolYear,
							// schoolYearValidation: data?.response?.validate
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						schoolYearActions.getSchoolYearInformationFailed({
							getSchoolYearInformationFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);

const updateSchoolYearInformation = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_SCHOOL_YEAR_INFORMATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.update_school_year_information.method,
				END_POINT.update_school_year_information.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.urlParams,
				),
				action.payload.schoolYear,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateSchoolYearInformationFailed({
							updateSchoolYearInformationFailed: data.response.errors,
							subcodeErrorUpdateSchoolYear: data?.response?.errors?.subcode,
						});
					}
					// console.log(data.response);
					return of(
						schoolYearActions.updateSchoolYearInformationSuccess({
							updateSchoolYearInformationSuccess: true,
							schoolYearDetail: data.response.schoolYear,
							subcodeErrorUpdateSchoolYear: null,
							// termsGradingPeriodsList: data.response.schoolYear.terms,
							// schoolYearValidation: data?.response?.validate
						}),
						schoolYearActions.getSchoolYearValidation({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateSchoolYearInformationFailed({
							updateSchoolYearInformationFailed: error.response,
							subcodeErrorUpdateSchoolYear: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const deleteSchoolYearDraftEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.DELETE_SCHOOL_YEAR_DRAFT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_school_year_draft.method,
				END_POINT.delete_school_year_draft.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap(() => {
					const schoolYearList = state$.value.SchoolYear.schoolYearList.filter(
						(schoolYear) => schoolYear.id !== action.payload.schoolYearId,
					);
					return of(
						schoolYearActions.deleteSchoolYearDraftSuccess({
							deleteSchoolYearDraftSuccess: true,
							schoolYearList,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.deleteSchoolYearDraftFailed({
							deleteSchoolYearDraftFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);
const getTermsGradingPeriodsListEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TERMS_GRADING_PERIODS_LIST),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_terms_grading_periods_list.method,
				END_POINT.get_terms_grading_periods_list.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.getTermsGradingPeriodsListFailed({
							error: data.response.errors,
							isBusy: false,
						});
					}
					const termsGradingPeriodsList = data.response.schoolYear.terms;
					const schoolYearStatus = data.response.schoolYear.status;
					return of(
						schoolYearActions.getTermsGradingPeriodsListSuccess({
							termsGradingPeriodsList,
							schoolYearStatus,
							isBusy: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.getTermsGradingPeriodsListFailed({
							error: error.response.errors,
							errorCode: error.status,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const updateTermsEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.UPDATE_TERMS),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_terms.method,
				END_POINT.update_terms.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.termId,
					action.payload.urlParams,
				),
				action.payload.termInfo,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateTermsFailed({
							updateTermsFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.updateTermsSuccess({
							updateTermsSuccess: true,
							termsGradingPeriodsList: data.response.schoolYear.terms,
							schoolYearDetail: {
								...state$.value.SchoolYear.schoolYearDetail,
								rePublishMode: data.response.schoolYear?.rePublishMode,
							},
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateTermsFailed({
							updateTermsFailed: error.response?.errors,
						}),
					),
				),
			),
		),
	);

const updateGradingPeriodsEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.UPDATE_GRADING_PERIODS),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_grading_periods.method,
				END_POINT.update_grading_periods.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.termId,
					action.payload.urlParams,
				),
				action.payload.gradingPeriods,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateGradingPeriodsFailed({
							updateGradingPeriodsFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.updateGradingPeriodsSuccess({
							// updateSchoolYearInformationSuccess: true,
							updateTermsSuccess: true,
							termsGradingPeriodsList: data.response.schoolYear.terms,
							schoolYearDetail: {
								...state$.value.SchoolYear.schoolYearDetail,
								rePublishMode: data.response.schoolYear?.rePublishMode,
							},
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateGradingPeriodsFailed({
							updateGradingPeriodsFailed: error.response,
						}),
					),
				),
			),
		),
	);

const getSchoolYearSchedulesEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_YEAR_SCHEDULES, actions.SET_SCHEDULE_SUCCESS, actions.COPY_AND_PASTE_A_WEEK_SUCCESS),
		flatMap((action) =>
			makeAjaxRequest(
				END_POINT.get_school_year_schedules.method,
				END_POINT.get_school_year_schedules.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.getSchoolYearSchedulesFailed({
							error: data.response.errors,
							isFetchingSchedules: false,
						});
					}
					return of(
						schoolYearActions.getSchoolYearSchedulesSuccess({
							schedules: data?.response?.terms,
							// validate: data?.response?.validate,
							isFetchingSchedules: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.getSchoolYearSchedulesFailed({
							// error: error.response.errors,
							errorCode: error.status,
							isFetchingSchedules: false,
						}),
					),
				),
			),
		),
	);

const createTimeSlotEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_TIME_SLOT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.create_time_slot.method,
				END_POINT.create_time_slot.url(action.payload.orgId, action.payload.dailyTemplateId),
				action.payload.timeSlot,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.createTimeSlotSuccess({
							createTimeSlotFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.createTimeSlotSuccess({
							createTimeSlotSuccess: true,
						}),
						schoolYearActions.getSchoolYearDailyTemplate({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
							urlParams: action.payload?.urlParams,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.createTimeSlotFailed({
							createTimeSlotFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);

const createdDailyTemplateEpic = (action$) =>
	action$.pipe(
		ofType(actions.CREATE_DAILY_TEMPLATE),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.create_daily_template.method,
				END_POINT.create_daily_template.url(action.payload.orgId, action.payload.schoolYearId),
				action.payload.dailyTemplate,
			).pipe(
				mergeMap((res) =>
					of(
						schoolYearActions.createDailyTemplateSuccess({
							isCreateDailyTemplateSuccess: true,
							isLoadingCreateDailyTemplate: false,
							schoolYearDetail: res.response?.schoolYear,
						}),
						schoolYearActions.getSchoolYearDailyTemplate({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
							urlParams: action.payload?.urlParams,
						}),
					),
				),
				catchError((error) =>
					of(
						schoolYearActions.createDailyTemplateFailed({
							createDailyTemplateError: error.response.errors,
							isLoadingCreateDailyTemplate: false,
						}),
					),
				),
			),
		),
	);

const updateTimeSlotEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_TIME_SLOT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_time_slot.method,
				END_POINT.update_time_slot.url(action.payload.orgId, action.payload.dailyTemplateId, action.payload.periodId),
				action.payload.timeSlot,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateTimeSlotSuccess({
							updateTimeSlotFailed: data?.response?.errors,
						});
					}
					return of(
						schoolYearActions.updateTimeSlotSuccess({
							updateTimeSlotSuccess: true,
						}),
						schoolYearActions.getSchoolYearDailyTemplate({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
							urlParams: action.payload?.urlParams,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateTimeSlotFailed({
							updateTimeSlotFailed: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getSchoolYearDailyTemplateEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_YEAR_DAILY_TEMPLATE, actions.REVIEW_AND_REPUBLISH_PRE_API),
		switchMap((action) => {
			if (action.type === actions.REVIEW_AND_REPUBLISH_PRE_API) {
				action.payload.urlParams = {
					from: moment().format(),
					filter: 'study',
				};
			}

			return makeAjaxRequest(
				END_POINT.get_daily_template.method,
				END_POINT.get_daily_template.url(action.payload.orgId, action.payload.schoolYearId, action.payload.urlParams),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							schoolYearActions.getSchoolYearDailyTemplateFailed({
								isFetchingDaily: false,
							}),
						);
					}
					// console.log(value.SchoolYear)
					// const dailyTemplates = [...data.response.items];
					// console.log(dailyTemplates === value.dailyTemplates);
					if (action.type === actions.REVIEW_AND_REPUBLISH_PRE_API) {
						return of(
							schoolYearActions.getSchoolYearDailyTemplateSuccess({
								dropDownDailyTemplates: data.response.items,
								isFetchingDaily: false,
							}),
						);
					}
					return of(
						schoolYearActions.getSchoolYearDailyTemplateSuccess({
							dailyTemplates: data.response.items,
							isFetchingDaily: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.getSchoolYearDailyTemplateFailed({
							error: error.response.errors,
							isFetchingDaily: false,
						}),
					),
				),
			);
		}),
	);
const deletePeriodEpic = (action$) =>
	action$.pipe(
		ofType(actions.DELETE_SCHOOL_YEAR_PERIOD),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.delete_period.method,
				END_POINT.delete_period.url(action.payload.orgId, action.payload.templateId, action.payload.periodId),
			).pipe(
				mergeMap(() =>
					// if (data.response.errors) {
					//   return of(schoolYearActions.deleteSchoolYearPeriodFailed({isDeletingPeriod: false}));
					// }
					of(
						schoolYearActions.deleteSchoolYearPeriodSuccess({
							isDeletingPeriod: false,
							deleteSchoolYearPeriodSuccess: true,
						}),
						schoolYearActions.getSchoolYearDailyTemplate({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
					),
				),
				catchError((error) =>
					of(
						schoolYearActions.deleteSchoolYearPeriodFailed({
							error: error.response.errors,
							isDeletingPeriod: false,
							deleteSchoolYearPeriodFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);

const updateDailyTemplateEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_SCHOOL_YEAR_TEMPLATE),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.update_template.method,
				END_POINT.update_template.url(action.payload.orgId, action.payload.schoolYearId, action.payload.templateId),
				{ ...action.payload },
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							schoolYearActions.updateSchoolYearTemplateFailed({
								isUpdatingTemplate: false,
							}),
						);
					}
					return of(
						schoolYearActions.updateSchoolYearTemplateSuccess({
							isUpdatingTemplate: false,
							updateSchoolYearTemplateSuccess: true,
						}),
						schoolYearActions.getSchoolYearDailyTemplate({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateSchoolYearTemplateFailed({
							error: {
								...error.response.errors,
								subcode: error.response.subcode,
							},
							isUpdatingTemplate: false,
							updateSchoolYearTemplateFailed: {
								...error.response.errors,
								subcode: error.response.errors.subcode,
								templateId: action.payload.templateId,
							},
						}),
					),
				),
			),
		),
	);

const setScheduleEpic = (action$) =>
	action$.pipe(
		ofType(actions.SET_SCHEDULE),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.set_schedule.method,
				END_POINT.set_schedule.url(action.payload.orgId, action.payload.schoolYearId),
				action.payload.params,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.setScheduleFailed(data.response.errors);
					}
					return of(
						schoolYearActions.setScheduleSuccess({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
							urlParams: action.payload.urlParams,
							isSetScheduleSuccess: true,
							isSettingSchedule: false,
							schoolYearDetail: data.response.schoolYear,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.setScheduleFailed({
							error: error?.response?.errors,
							isSettingSchedule: false,
						}),
					),
				),
			),
		),
	);

const copyAndPasteAWeekEpic = (action$) =>
	action$.pipe(
		ofType(actions.COPY_AND_PASTE_A_WEEK),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.copy_and_paste_a_week.method,
				END_POINT.copy_and_paste_a_week.url(action.payload.orgId, action.payload.schoolYearId),
				action.payload.params,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							schoolYearActions.copyAndPasteAWeekFailed({
								error: data.response.errors,
								isCopingAndPastingAWeek: false,
							}),
						);
					}
					return of(
						schoolYearActions.copyAndPasteAWeekSuccess({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
							schoolYearDetail: data.response.schoolYear,
							urlParams: action.payload.urlParams,
							isSetScheduleSuccess: true,
							isCopingAndPastingAWeek: false,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.copyAndPasteAWeekFailed({
							error: error?.response?.errors,
							isCopingAndPastingAWeek: false,
						}),
					),
				),
			),
		),
	);

const schoolYearValidationEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SCHOOL_YEAR_VALIDATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_school_year_validation.method,
				END_POINT.get_school_year_validation.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap(({ response }) =>
					of(
						schoolYearActions.getSchoolYearValidationSuccess({
							schoolYearValidation: {
								...response.validate,
								schoolYearAndTerm: response.validate?.basicInfo && response.validate?.termAndGradingPeriod,
							},
							schoolYearStatus: response.status,
						}),
					),
				),
			),
		),
	);

const getSettingTermsAndGradingPeriodsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_SETTING_TERMS_AND_GRADING_PERIODS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_setting_terms_and_grading_periods.method,
				END_POINT.get_setting_terms_and_grading_periods.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.getSettingTermsAndGradingPeriodsFailed({
							getSettingTermsAndGradingPeriodsFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.getSettingTermsAndGradingPeriodsSuccess({
							getSettingTermsAndGradingPeriodsSuccess: true,
							settingTerms: data?.response?.terms,
							settingGradingPeriods: data?.response?.gradingPeriods,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.getSettingTermsAndGradingPeriodsFailed({
							getSettingTermsAndGradingPeriodsFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);
const getTermsBySchoolYearEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_TERMS_BY_SCHOOL_YEAR),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_terms_by_school_year.method,
				END_POINT.get_terms_by_school_year.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap((data) =>
					of(
						schoolYearActions.getTermsBySchoolYearSuccess({
							termsList: data?.response?.terms,
							getTermsBySchoolYearSuccess: true,
						}),
					),
				),
			),
		),
	);

const updateSettingTermsAndGradingPeriodsEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_SETTING_TERMS_AND_GRADING_PERIODS),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_setting_terms_and_grading_periods.method,
				END_POINT.update_setting_terms_and_grading_periods.url(action.payload.orgId, action.payload.schoolYearId),
				action.payload.settingData,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateSettingTermsAndGradingPeriodsFailed({
							updateSettingTermsAndGradingPeriodsFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.updateSettingTermsAndGradingPeriodsSuccess({
							updateSettingTermsAndGradingPeriodsSuccess: true,
						}),
						schoolYearActions.getSettingTermsAndGradingPeriods({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
						schoolYearActions.getSchoolYearValidation({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
						schoolYearActions.getTermsGradingPeriodsList({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateSettingTermsAndGradingPeriodsFailed({
							updateSettingTermsAndGradingPeriodsFailed: error.response.errors,
						}),
					),
				),
			),
		),
	);

const updateSchoolYearStatusEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_SCHOOL_YEAR_STATUS),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_school_year_information.method,
				END_POINT.update_school_year_information.url(
					action.payload.orgId,
					action.payload.schoolYearId,
					action.payload.urlParams,
				),
				action.payload.schoolYear,
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateSchoolYearStatusFailed({
							updateSchoolYearStatusFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.updateSchoolYearStatusSuccess({
							updateSchoolYearStatusSuccess: true,
							schoolYearDetail: data.response.schoolYear,
						}),
						schoolYearActions.getSchoolYearValidation({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateSchoolYearStatusFailed({
							updateSchoolYearStatusFailed: error.response,
						}),
					),
				),
			),
		),
	);
const validatePublishedSchoolYearEpic = (action$) =>
	action$.pipe(
		ofType(actions.VALIDATE_PUBLISHED_SCHOOL_YEAR),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.validate_published_school_year.method,
				END_POINT.validate_published_school_year.url(action.payload.orgId),
			).pipe(
				mergeMap((data) =>
					of(
						schoolYearActions.validatePublishedSchoolYearSuccess({
							isPublishedExist: data?.response?.isPublishedExist,
							schoolYearPublishedName: data?.response?.schoolYearPublishedName,
							validatePublishedSchoolYearSuccess: true,
						}),
					),
				),
			),
		),
	);

const getAllSchoolYearActivitiesNotGradeOrReleaseEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_ALL_SCHOOL_YEAR_ACTIVITIES_NOT_GRADE_OR_RELEASE),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_all_school_year_activities_not_grade_or_release.method,
				END_POINT.get_all_school_year_activities_not_grade_or_release.url(
					action.payload.orgId,
					action.payload.schoolYearId,
				),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.getAllSchoolYearActivitiesNotGradeOrReleaseFailed({
							getAllSchoolYearActivitiesNotGradeOrReleaseFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.getAllSchoolYearActivitiesNotGradeOrReleaseSuccess({
							getAllSchoolYearActivitiesNotGradeOrReleaseSuccess: true,
							schoolYearActivitiesNotGradeOrRelease: data.response,
							schoolYearDetailForModalArchive: action.payload.schoolYear,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.getAllSchoolYearActivitiesNotGradeOrReleaseFailed({
							getAllSchoolYearActivitiesNotGradeOrReleaseFailed: error.response,
						}),
					),
				),
			),
		),
	);

const updateSchoolYearStatusPublishedToArchivedEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_STATUS_SCHOOL_YEAR_PUBLISHED_TO_ARCHIVED),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.update_school_year_status_published_to_archived.method,
				END_POINT.update_school_year_status_published_to_archived.url(
					action.payload.orgId,
					action.payload.schoolYearId,
				),
			).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return schoolYearActions.updateStatusSchoolYearPublishedToArchivedFailed({
							updateStatusSchoolYearPublishedToArchivedFailed: data.response.errors,
						});
					}
					return of(
						schoolYearActions.updateStatusSchoolYearPublishedToArchivedSuccess({
							updateStatusSchoolYearPublishedToArchiveSuccess: true,
							updateSchoolYearStatusPublishedToArchived: data.response,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.updateStatusSchoolYearPublishedToArchivedFailed({
							updateStatusSchoolYearPublishedToArchivedFailed: error.response,
						}),
					),
				),
			),
		),
	);

const enableEditSchoolYearDetailEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.ENABLE_EDIT_SCHOOL_YEAR_DETAIL),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.enable_edit_school_year.method,
				END_POINT.enable_edit_school_year.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap((data) =>
					of(
						schoolYearActions.enableEditSchoolYearDetailSuccess({
							currentPublishedSY: state$.value.SchoolYear.schoolYearDetail,
							schoolYearDetail: data.response.draftSchoolYear || {},
							swapModeSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						schoolYearActions.enableEditSchoolYearDetailFailed({
							swapModeSuccess: false,
							error: error,
						}),
					),
				),
			),
		),
	);
const discardChangeSchoolYearDetailEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.DISCARD_CHANGE_SCHOOL_YEAR_DETAIL),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.discard_edit_school_year.method,
				END_POINT.discard_edit_school_year.url(action.payload.orgId, action.payload.schoolYearId),
			).pipe(
				mergeMap((data) =>
					of(
						schoolYearActions.discardChangeSchoolYearDetailSuccess({
							schoolYearDetail: data.response.publishedSchoolYear || state$.value.SchoolYear.currentPublishedSY,
							swapModeSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						schoolYearActions.enableEditSchoolYearDetailFailed({
							swapModeSuccess: false,
							error: error,
						}),
					),
				),
			),
		),
	);
const republishSchoolYearEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.REPUBLISH_SCHOOL_YEAR),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.republish_school_year.method,
				END_POINT.republish_school_year.url(action.payload.orgId, action.payload.schoolYearId),
				action.payload.body,
			).pipe(
				mergeMap(({ response }) =>
					of(
						schoolYearActions.republishSchoolYearSuccess({
							schoolYearDetail: response.schoolYearUpdated || state$.value.SchoolYear.currentPublishedSY,
							currentPublishedSY: response.schoolYearUpdated || state$.value.SchoolYear.currentPublishedSY,
							republishSuccess: true,
							swapModeSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						schoolYearActions.republishSchoolYearFailed({
							swapModeSuccess: false,
							error: error,
							republishSuccess: false,
						}),
					),
				),
			),
		),
	);

const reviewAndRepublishPreApiEpic = (action$) =>
	action$.pipe(
		ofType(actions.REVIEW_AND_REPUBLISH_PRE_API),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_sections.method,
				END_POINT.get_sections.url(
					action.payload.orgId,
					action.payload.publishedSchoolYearId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) => {
					const sections = data?.response?.sections || [];
					const numberOfCourses = data?.response?.numberOfCourses?.published ?? 0;

					return of(
						schoolYearActions.reviewAndRepublishPreApiSuccess({
							orgId: action.payload.orgId,
							schoolYearId: action.payload.schoolYearId,
							fetchReviewNRepublishSuccess: null,
							courseListToReview: sections.map((item) => ({
								courseId: item.courseId,
								courseName: item.courseName,
								termId: item.termId,
								sections: item.sections,
								courseImageUrl: item.courseImageUrl,
								filled: false,
							})),
							numberOfCourses: numberOfCourses,
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						schoolYearActions.reviewAndRepublishPreApiFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
						}),
					),
				),
			),
		),
	);

const debounceEpic = (action$) =>
	action$.pipe(
		ofType(actions.DEBOUNCE), // Your action type for fetching batch data
		bufferTime(1000), // Buffer the actions for 1 second
		filter((actions) => actions.length > 0), // Make sure there are actions to process
		map((arrActions) => {
			const payloads = arrActions.map((action) => action.payload);
			const courseIds = uniq(payloads.map((item) => item?.urlParams?.courseIds?.split(',') || []).flat());

			return {
				type: actions.REVIEW_AND_REPUBLISH,
				payload: {
					orgId: payloads[0].orgId,
					schoolYearId: payloads[0].schoolYearId,
					fetchReviewNRepublishSuccess: null,
					urlParams: {
						groupBy: 'courses',
						courseIds: courseIds.toString(),
					},
				},
			};
		}),
	);
const debounceHideItemsEpic = (action$, $state) =>
	action$.pipe(
		ofType(actions.DEBOUNCE_HIDE_ITEMS), // Your action type for fetching batch data
		bufferTime(1000), // Buffer the actions for 1 second
		filter((actions) => actions.length > 0), // Make sure there are actions to process
		map((arrActions) => {
			const payloads = arrActions.map((action) => action.payload);
			let courseIds = uniq(payloads.map((item) => item?.courseIds?.split(',') || []).flat());
			courseIds = courseIds.map((item) => Number(item));
			const availableData = cloneDeep($state.value.SchoolYear.courseListToReview);

			const courseListToReview = availableData.map((item) => {
				if (courseIds.includes(item.courseId)) {
					return {
						...item,
						hide: true,
					};
				}
				return item;
			});

			return {
				type: actions.SCHOOL_YEAR_SET_STATE,
				payload: {
					courseListToReview: courseListToReview,
				},
			};
		}),
	);
const reviewAndRepublishEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.REVIEW_AND_REPUBLISH),
		// bufferTime(500), // Buffer the actions for 1 second
		distinctUntilChanged((prevAction, currAction) => {
			const preCourseIds = prevAction?.payload?.urlParams?.courseIds;
			const currCourseIds = currAction?.payload?.urlParams?.courseIds;
			return preCourseIds === currCourseIds;
		}),
		mergeMap((action) => {
			if (action.type === actions.REVIEW_AND_REPUBLISH_PRE_API_SUCCESS) {
				const courseIds = action.payload.courseListToReview.map((item) => item.courseId);
				action.payload.urlParams = {
					courseIds: `${courseIds[0]}`,
					groupBy: 'courses',
				};
			}
			action.payload.originalUrlPrams = action.payload?.urlParams;

			let courseIdsArr = action.payload.urlParams?.courseIds;
			courseIdsArr = courseIdsArr ? courseIdsArr.split(',') : [];
			courseIdsArr = courseIdsArr.map((id) => Number(id));

			const availableData = state$.value.SchoolYear.courseListToReview;

			const notFilledYetCourseIds = courseIdsArr.filter((id) =>
				availableData.find((item) => item.courseId === id && !item.filled),
			);

			if (!action.payload.urlParams.courseIds || !notFilledYetCourseIds.length) {
				return of(
					schoolYearActions.reviewAndRepublishSuccess({
						fetchReviewNRepublishSuccess: true,
						courseListToReview: availableData,
					}),
				);
			}

			action.payload.urlParams.courseIds = notFilledYetCourseIds[0].toString();

			return makeAjaxRequest(
				END_POINT.review_n_republish.method,
				END_POINT.review_n_republish.url(action.payload.orgId, action.payload.schoolYearId, action.payload.urlParams),
			).pipe(
				concatMap((data) => {
					const courseListToReview = state$.value.SchoolYear.courseListToReview;
					const publishedSMTByCourses = data.response.publishedSMTByCourses;

					const courseListToReviewFilled = courseListToReview.map((item) => {
						const found = publishedSMTByCourses.find((_res) => _res.courseId === item.courseId);
						if (found) {
							return {
								filled: true,
								...found,
							};
						}
						return item;
					});
					return of(
						schoolYearActions.reviewAndRepublishSuccess({
							fetchReviewNRepublishSuccess: true,
							courseListToReview: courseListToReviewFilled,
						}),
					);
				}),
				catchError((error) =>
					of(
						schoolYearActions.reviewAndRepublishFailed({
							fetchReviewNRepublishSuccess: false,
						}),
					),
				),
			);
		}),
	);

const saveSectionToStoreEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.SAVE_SECTION_TO_STORE), // Your action type for fetching batch data
		bufferTime(100), // Buffer the actions for 1 second
		filter((actions) => actions.length > 0), // Make sure there are actions to process
		map((arrActions) => {
			const payloads = arrActions.map((action) => action.payload);
			const fetchedSections = state$.value.SchoolYear.fetchedSections;
			payloads.forEach((item) => {
				if (!fetchedSections[item.sectionId]) {
					fetchedSections[item.sectionId] = {
						newTimeSlots: item.newTimeSlots,
						timeSlots: item.timeSlots,
						termId: item.termId,
					};
				}
			});

			return {
				type: actions.SCHOOL_YEAR_SET_STATE,
				payload: {
					fetchedSections,
				},
			};
		}),
	);

const submitReviewAndRepublishEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.SUBMIT_REVIEW_AND_REPUBLISH), // Your action type for fetching batch data
		// bufferTime(500), // Buffer the actions for 1 second
		// filter((actions) => actions.length > 0), // Make sure there are actions to process
		mergeMap((action) => {
			const payloads = action?.payload?.data?.filter((item) => item.sectionId && item.courseId && item.termId) || [];
			const dataToUpdate = payloads.map((item) => ({
				sectionId: item.sectionId,
				courseId: item.courseId,
				termId: item.termId,
				dailyTemplate: item.smt.map((_smt) => ({
					dailyTemplateId: _smt.template?.value,
					periodId: _smt.period?.value,
				})),
			}));

			if (dataToUpdate.find((item) => !item.dailyTemplate.length)) {
				return of(
					schoolYearActions.schoolYearSetState({
						reviewAndRepublishError: true,
						isSubmitting: false,
					}),
				);
			}
			// if (fulfill) {
			const orgId = state$.value.Auth?.orgId;
			const schoolYearId = state$.value.SchoolYear?.schoolYearDetail?.id;
			return of(
				schoolYearActions.republish({
					orgId,
					schoolYearId,
					reviewAndRepublish: null,
					error: null,
					body: {
						rePublishMode: SCHOOL_YEAR_REPUBLISH_STATUS.CHANGE_PERIOD,
						updateCourses: dataToUpdate,
					},
				}),
			);
		}),
	);

const republishEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.REPUBLISH),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.republish_school_year.method,
				END_POINT.republish_school_year.url(action.payload.orgId, action.payload.schoolYearId),
				action.payload.body,
			).pipe(
				mergeMap((data) =>
					of(
						schoolYearActions.republishSuccess({
							schoolYearDetail: data.response.schoolYearUpdated,
							isSubmitting: false,
							reviewAndRepublish: true,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						schoolYearActions.republishFailed({
							error: error?.response?.errors,
							errorCode: error?.status,
							isSubmitting: false,
							...(error?.response?.errors?.subcode === SUB_CODE_SY.MISSING_SMT && {
								reviewAndRepublishError: true,
							}),
						}),
					),
				),
			),
		),
	);
export default [
	createSchoolYearEpic,
	getSchoolYearListEpic,
	getSchoolYearInformation,
	updateSchoolYearInformation,
	deleteSchoolYearDraftEpic,
	getTermsGradingPeriodsListEpic,
	getSchoolYearSchedulesEpic,
	updateTermsEpic,
	updateGradingPeriodsEpic,
	getSchoolYearDailyTemplateEpic,
	deletePeriodEpic,
	updateDailyTemplateEpic,
	createTimeSlotEpic,
	updateTimeSlotEpic,
	createdDailyTemplateEpic,
	schoolYearValidationEpic,
	setScheduleEpic,
	copyAndPasteAWeekEpic,
	getSettingTermsAndGradingPeriodsEpic,
	updateSettingTermsAndGradingPeriodsEpic,
	getTermsBySchoolYearEpic,
	updateSchoolYearStatusEpic,
	getAllSchoolYearActivitiesNotGradeOrReleaseEpic,
	updateSchoolYearStatusPublishedToArchivedEpic,
	validatePublishedSchoolYearEpic,
	enableEditSchoolYearDetailEpic,
	discardChangeSchoolYearDetailEpic,
	reviewAndRepublishEpic,
	reviewAndRepublishPreApiEpic,
	debounceEpic,
	debounceHideItemsEpic,
	saveSectionToStoreEpic,
	republishSchoolYearEpic,
	submitReviewAndRepublishEpic,
	republishEpic,
];
