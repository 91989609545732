import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import calendarActions from './actions';
import { END_POINT, actions } from './constants';

const getDayHaveSessionEpic = (action$) => {
	return action$.pipe(
		ofType(actions.GET_DAY_HAVE_SESSION),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_day_have_session.method,
				END_POINT.get_day_have_session.url(payload?.orgId, payload?.params),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							calendarActions.getDayHaveSessionFailed({
								error: data.response.errors,
							}),
						);
					}

					return of(
						calendarActions.getDayHaveSessionSuccess({
							dayHaveSession: data.response.data,
						}),
					);
				}),
				catchError((error) =>
					of(
						calendarActions.getDayHaveSessionFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
};

const getSessionsInPeriodEpic = (action$) => {
	return action$.pipe(
		ofType(actions.GET_SESSIONS_IN_PERIOD),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_sessions_in_period.method,
				END_POINT.get_sessions_in_period.url(payload?.orgId, payload?.params),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							calendarActions.getSessionsInPeriodFailed({
								error: data.response.errors,
							}),
						);
					}

					return of(
						calendarActions.getSessionsInPeriodSuccess({
							sessionsInPeriod: data.response.data,
						}),
					);
				}),
				catchError((error) =>
					of(
						calendarActions.getSessionsInPeriodFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
};

const getEventDetailEpic = (action$) => {
	return action$.pipe(
		ofType(actions.GET_EVENT_DETAIL),
		debounceTime(500),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_event_detail.method,
				END_POINT.get_event_detail.url(payload?.orgId, payload?.sectionScheduleId, payload?.params),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							calendarActions.getEventDetailFailed({
								error: data.response.errors,
							}),
						);
					}

					return of(
						calendarActions.getEventDetailSuccess({
							eventDetail: data.response.data,
						}),
					);
				}),
				catchError((error) =>
					of(
						calendarActions.getEventDetailFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
};

const getEventPersonalNoteEpic = (action$) => {
	return action$.pipe(
		ofType(actions.GET_EVENT_DETAIL),
		debounceTime(500),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_event_personal_note.method,
				END_POINT.get_event_personal_note.url(payload?.orgId, payload?.sectionScheduleId, payload?.params),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							calendarActions.getEventPersonalNoteFailed({
								error: data.response.errors,
							}),
						);
					}

					return of(
						calendarActions.getEventPersonalNoteSuccess({
							personalNote: data.response.data?.text || '',
						}),
					);
				}),
				catchError((error) =>
					of(
						calendarActions.getEventPersonalNoteFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
};

const getEventActivitiesEpic = (action$) => {
	return action$.pipe(
		ofType(actions.GET_EVENT_ACTIVITIES),
		debounceTime(500),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_event_activities.method,
				END_POINT.get_event_activities.url(payload?.orgId, payload?.sectionScheduleId, payload?.params),
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							calendarActions.getEventActivitiesFailed({
								error: data.response.errors,
							}),
						);
					}

					return of(
						calendarActions.getEventActivitiesSuccess({
							eventActivities: data.response.data,
						}),
					);
				}),
				catchError((error) =>
					of(
						calendarActions.getEventActivitiesFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
};

const upsertEventPersonalNoteEpic = (action$) => {
	return action$.pipe(
		ofType(actions.UPSERT_EVENT_PERSONAL_NOTE),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.upsert_event_personal_note.method,
				END_POINT.upsert_event_personal_note.url(payload?.orgId, payload?.sectionScheduleId, payload?.params),
				payload?.data,
			).pipe(
				mergeMap((data) => {
					if (data?.response?.errors) {
						return of(
							calendarActions.upsertEventPersonalNoteFailed({
								error: data.response.errors,
							}),
						);
					}

					return of(
						calendarActions.upsertEventPersonalNoteSuccess({
							// personalNote: data.response.data?.text || '',
						}),
					);
				}),
				catchError((error) =>
					of(
						calendarActions.upsertEventPersonalNoteFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
};

export default [
	getDayHaveSessionEpic,
	getSessionsInPeriodEpic,
	getEventDetailEpic,
	getEventActivitiesEpic,
	getEventPersonalNoteEpic,
	upsertEventPersonalNoteEpic,
];
