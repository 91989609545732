import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

import TblIcon from 'components/TblIcon';
import { TblIconButton } from 'components/TblIconButton';
import BackgroundOtp from 'shared/Auth/components/BackgroundOtp';

import { isGuardian, isStudent } from 'utils/roles';

import { USER_BEHAVIOR } from 'shared/User/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { BaseModal, Box, Button, TextField, Typography } from 'ella-storybook';
import { useLayoutContext } from 'layout/LayoutContext';
import { COUNTDOWN_RESEND, OTP_CODE } from 'modules/MyProfile/constants';
import { ROUTE_MY_PROFILE } from 'modules/MyProfile/constantsRoute';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { makeStyles } from 'tss-react/mui';

import myProfileActions from '../../actions';

import CountdownResend from './CountdownResend';

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 64px)',
		width: '100vw',
	},
	containerForm: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '480px',
	},
	containerCode: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	inputOtp: {
		border: 'none',
		borderBottom: '2px solid #D1D5DB',
		maxWidth: '36px',
		borderRadius: '0',
		color: theme.newColors.primary[500],
		'& fieldset': { border: 'none' },
		'&:focus-within fieldset, &:focus-visible fieldset': {
			border: '0px solid red!important',
			boxShadow: 'none !important',
		},
		'&.MuiInputBase-root': {
			...theme.typography.headlineMedium,
			'& .MuiInputBase-input': {
				padding: '0',
			},
		},
		padding: '8px',
		margin: '0 4px',
	},
	buttonVerify: {
		backgroundColor: theme.newColors.primary[500],
		color: theme.newColors.white,
		width: '100%',
	},
	inlineError: {
		textAlign: 'center',
		width: '100%',
	},
}));

const EnterVerifyCode = (props) => {
	const { isOnBoarding } = props;
	const { classes } = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('myProfile');
	const authContext = useContext(AuthDataContext);

	const dispatch = useDispatch();
	const history = useHistory();
	const layoutContext = useLayoutContext();
	const currentUser = authContext.currentUser;
	const { organizationId, phone: userPhone, settings } = currentUser;

	const otpSubCode = useSelector((state) => state.MyProfile.otpSubCode);
	const verifyOtpSuccess = useSelector((state) => state.MyProfile.verifyOtpSuccess);
	const isChangeProfileSuccess = useSelector((state) => state.MyProfile.isChangeProfileSuccess);

	const listOTP = [...Array.from({ length: 6 })];

	const [openReachedLimit, setOpenReachedLimit] = useState(false);
	const [errVerifyOtp, setErrVerifyOtp] = useState(null);
	const [otpValues, setOtpValues] = useState(listOTP);

	useEffect(() => {
		layoutContext.setFullScreen(true);
		return () => layoutContext.setFullScreen(false);
	}, []);

	useEffect(() => {
		if (verifyOtpSuccess && isOnBoarding) {
			setErrVerifyOtp(null);
			history.replace({
				search: 'step=3',
			});
			localStorage.setItem('backAddPhone', 1);
			return;
		}

		if (verifyOtpSuccess && !isOnBoarding) {
			setErrVerifyOtp(null);
			history.push({
				pathname: `${ROUTE_MY_PROFILE.VERIFY_PHONE_SUCCESS}`,
			});
		}
	}, [verifyOtpSuccess, isOnBoarding]);

	useEffect(() => {
		switch (otpSubCode) {
			case OTP_CODE.REACH_LIMIT_RESEND:
				setOpenReachedLimit(true);
				setErrVerifyOtp(null);
				break;
			case OTP_CODE.OTP_NOT_VALID:
				setErrVerifyOtp(t('incorrect_code'));
				break;
			case OTP_CODE.EXPIRED:
				setErrVerifyOtp(t('expired_code'));
				break;
			default:
				break;
		}
	}, [otpSubCode]);

	useEffect(() => {
		if (isGuardian(currentUser) && isChangeProfileSuccess && isOnBoarding) {
			const currentHostname = window.location.hostname;

			const studentId = currentUser?.students?.[0]?.id;
			let path = ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT();

			if (studentId) {
				path = ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(studentId);
			}

			const fullUrl = `${window.location.protocol}//${currentHostname}:${window.location.port}${path}`;

			window.location.replace(fullUrl);
		}
	}, [isChangeProfileSuccess, isOnBoarding]);

	const resendCode = () => {
		dispatch(
			myProfileActions.resendOtp({
				orgId: organizationId,
				tool: 'phone',
				otpSubCode: null,
			}),
		);
	};

	const verifyCode = () => {
		setErrVerifyOtp(null);
		const otpCode = otpValues.join('');
		if (otpCode.length === 0) {
			setErrVerifyOtp(t('required_message_!'));
			return;
		}
		if (otpCode.length !== 6) {
			setErrVerifyOtp(t('please_enter_a_valid_code'));
			return;
		}

		dispatch(
			myProfileActions.verifyOtp({
				orgId: organizationId,
				tool: 'phone',
				otp: otpCode,
				otpSubCode: null,
			}),
		);
	};

	const onCloseReachedLimit = () => {
		setOpenReachedLimit(false);

		if (isGuardian(currentUser) && isOnBoarding) {
			const keys = Object.keys(settings);
			const _settings = { [keys[0]]: [...settings.behavior, USER_BEHAVIOR.HAVE_SET_UP_PROFILE] };

			const payload = {
				settings: _settings,
			};
			dispatch(myProfileActions.updateMyProfile(payload));
		}

		if (isStudent(currentUser) && isOnBoarding) {
			history.replace({
				search: 'step=4',
			});
		}

		dispatch(
			myProfileActions.myProfileSetState({
				otpSubCode: null,
			}),
		);
	};

	const handleGoBack = () => {
		if (history.length > 1) {
			dispatch(
				myProfileActions.myProfileSetState({
					otpSubCode: null,
				}),
			);
			setErrVerifyOtp(null);
			setOpenReachedLimit(false);
			history.goBack();
		}
	};
	useEffect(() => {
		setErrVerifyOtp(null);
		setOpenReachedLimit(false);
	}, []);

	const otpRef = useRef([]);

	const onChangeOtp = ({ event, index }) => {
		const next = index + 1;
		const value = event.target.value;

		if (value || value === 0) {
			const newOtpValues = [...otpValues];
			newOtpValues[index] = value;
			setOtpValues(newOtpValues);
			next < 6 ? otpRef.current[next].select() : otpRef.current[index].blur();
			const otpCode = otpRef.current.map((e) => e.value).join('');
			if (otpCode.length === 6) {
				setErrVerifyOtp(null);
			}
		}
	};
	const handleOneInputFocus = ({ event, index }) => {
		event.preventDefault();
		otpRef.current[index].select();
	};

	const handleBackspace = (index) => {
		const newOtpValues = [...otpValues];
		newOtpValues[index] = undefined;
		setOtpValues(newOtpValues);
		const previous = index === 0 ? 0 : index - 1;
		otpRef.current[previous].select();
	};

	return (
		<div className={classes.container}>
			<BaseModal
				size={'small'}
				title={t('try_again_later')}
				open={openReachedLimit}
				onClose={onCloseReachedLimit}
				customFooter={
					<Box className='customFooterModal'>
						<Button onClick={onCloseReachedLimit} variant='primary'>
							{t('got_it')}
						</Button>
					</Box>
				}
				contentComponent={<Typography variant='bodyMediumRegular'>{t('try_again_later_otp_content')}</Typography>}
			/>

			<Box className={classes.containerForm}>
				<Box>
					<TblIconButton onClick={handleGoBack} style={{ cursor: 'pointer', padding: '8px' }}>
						<TblIcon icon='arrow_back' fontSize={theme.fontSizeIcon.medium} mb={'24px'} />
					</TblIconButton>
				</Box>
				<Typography variant='headlineSmall'>{t('enter_verification_code')}</Typography>
				<Typography variant='bodyMediumRegular'>{t('a_text_message_with_a_6_digit')}</Typography>
				<Typography variant='titleSmall'>{userPhone}</Typography>
				<Typography variant='bodyMediumRegular'>{t('please_open_message_and_enter_code')}</Typography>
				<Box mb={'32px'} />

				<Box className={classes.containerCode}>
					{otpValues.map((value, index) => (
						<TextField
							autoFocus={index === 0}
							value={value ?? ''}
							inputRef={(el) => {
								otpRef.current[index] = el;
							}}
							type='number'
							onChange={(event) => {
								onChangeOtp({ event, index });
							}}
							className={classes.inputOtp}
							key={index}
							inputProps={{ maxLength: 1 }}
							onFocus={(event) => handleOneInputFocus({ event, index })}
							onKeyUp={(e) => {
								if (e.key === 'Backspace') {
									e.preventDefault();
									handleBackspace(index);
								}
							}}
							onKeyDownCapture={(e) => {
								if (e.key === otpValues[index]?.toString()) {
									const next = index + 1;
									next < 6 ? otpRef.current[next].focus() : otpRef.current[index].blur();
								}
							}}
						/>
					))}
				</Box>
				{errVerifyOtp && (
					<Typography
						className={classes.inlineError}
						variant={'bodySmallRegular'}
						color={theme.newColors.red[600]}
						mt={'8px'}
					>
						{errVerifyOtp}
					</Typography>
				)}
				<Box mb={'32px'} />
				<Button className={classes.buttonVerify} onClick={verifyCode}>
					{t('verify')}
				</Button>
				<Box mb={'20px'} />

				<CountdownResend
					onClick={resendCode}
					className={classes.inlineError}
					countdownTime={Number(COUNTDOWN_RESEND)}
				/>
			</Box>

			<BackgroundOtp />
		</div>
	);
};
EnterVerifyCode.defaultProps = { isOnBoarding: false };

export default EnterVerifyCode;
