import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import TblIcon from 'components/TblIcon';
import { TblIconButton } from 'components/TblIconButton';

import clsx from 'clsx';
import { Comments, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';

import GraderActions from '../../actions';

import { useStyles } from './styles';

const ToggleComment = (props) => {
  const { studentId, graderDetailId, currentUser, hideInputComment, comments, isLoadingComment, toggleComment } = props;
  const { t } = useTranslation('grader');
  const theme = useTheme();
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const handleEditComment = (values) => {
    dispatch(
      GraderActions.editGraderComment({
        studentId: studentId,
        submissionId: graderDetailId,
        commentId: values?.id,
        data: {
          message: values?.message.trim(),
        },
        isLoadingComment: true,
      })
    );
  };

  const handleDeleteComment = (commentId) => {
    dispatch(
      GraderActions.deleteGraderComment({
        studentId: studentId,
        submissionId: graderDetailId,
        commentId: commentId,
        isLoadingComment: true,
      })
    );
  };

  const handleAddComment = (values) => {
    dispatch(
      GraderActions.postGraderComment({
        studentId: studentId,
        submissionId: graderDetailId,
        data: {
          message: values?.message.trim(),
        },
        isLoadingComment: true,
      })
    );
  };

  const handleSubmitComment = (values) => {
    values?.id ? handleEditComment(values) : handleAddComment(values);
  };

  const handleToggleComment = () => {
    dispatch(
      GraderActions.graderSetState({
        toggleComment: !toggleComment,
      })
    );
  };

  return (
    <Box className={clsx(classes.rightContentRoot, classes.boxItem, 'tbl-scroll')}>
      <Box className={classes.commentContainer}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          marginBottom={(theme) => theme.spacing(3)}
          height={(theme) => theme.spacing(4)}
        >
          <Typography component={'div'} variant='titleSmall' sx={{ color: (theme) => theme.newColors.gray[800] }}>
            {t('comments', { ns: 'grader' })}
          </Typography>
          <TblIconButton
            size={'small'}
            className={classes.iconBtn}
            onClick={handleToggleComment}
            style={{ padding: theme.spacing(0.5) }}
          >
            <TblIcon icon='keyboard_double_arrow_right' fontSize={theme.fontSizeIcon.medium} />
          </TblIconButton>
        </Box>

        <Comments
          currentUserId={currentUser.id}
          onRemove={handleDeleteComment}
          onSubmit={handleSubmitComment}
          comments={comments}
          loading={isLoadingComment}
          hideInput={hideInputComment}
          maxRows={2}
          required
          enableScroll={true}
          scrollHeight={'calc( 100vh - 320px )'}
          maxHeightScroll={'calc( 100vh - 320px )'}
        />
      </Box>
    </Box>
  );
};

ToggleComment.propTypes = {
  comments: PropTypes.any,
  currentUser: PropTypes.shape({
    id: PropTypes.any,
  }),
  graderDetailId: PropTypes.any,
  hideInputComment: PropTypes.any,
  isLoadingComment: PropTypes.any,
  studentId: PropTypes.any,
};

export default ToggleComment;
