import React from 'react';

import PropTypes from 'prop-types';

const TblIcon = ({ icon, fill, weight, grade, optical, fontSize, color, style, isGrab, hasCursor = true }) => (
	<span
		className='material-symbols-rounded'
		style={{
			fontVariationSettings: `'FILL' ${fill}, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' ${optical}`,
			fontSize,
			color,
			cursor: hasCursor ? (isGrab ? 'grab' : 'pointer') : 'default',
			...style,
		}}
	>
		{icon}
	</span>
);

TblIcon.defaultProps = {
	fill: 0,
	weight: 400,
	grade: 0,
	optical: 20,
	isGrab: false,
};

TblIcon.propTypes = {
	icon: PropTypes.string,
	fill: PropTypes.oneOf([0, 1]),
	weight: PropTypes.number,
	grade: PropTypes.number,
	optical: PropTypes.number,
	fontSize: PropTypes.string,
	color: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default TblIcon;
