import React, { useEffect, useState } from 'react';

import { Box, Tooltip } from 'ella-storybook';
import PropTypes from 'prop-types';

export default function TblTooltipDynamic(props) {
	const { children, title, lines = 1, lineHeightText = 0, placement = 'top', ...rest } = props;
	const textElement = React.createRef();
	const [isOverflowed, setIsOverflowed] = useState(false);

	const updateWidth = () => {
		setIsOverflowed(
			textElement.current.scrollHeight > textElement.current.offsetHeight ||
				textElement.current.scrollWidth > textElement.current.offsetWidth,
		);
	};

	useEffect(() => {
		if (textElement.current) {
			window.addEventListener('resize', updateWidth);
			updateWidth();
		}

		return function cleanup() {
			window.removeEventListener('resize', updateWidth);
		};
	});

	// useEffect(() => {
	//   updateWidth();
	// }, [scrollWidth, updateWidth]);

	const maxHeight = lines > 1 ? lineHeightText * lines : 'unset';

	let className = 'text-ellipsis';
	if (lines === 2) className = 'text-ellipsis-2row';
	else if (lines === 3) className = 'text-ellipsis-3row';

	return (
		<Tooltip placement={placement} followCursor={true} title={title} disableHoverListener={!isOverflowed} {...rest}>
			<Box
				component='div'
				ref={textElement}
				sx={{
					maxHeight: maxHeight,
				}}
				className={className}
			>
				{children}
			</Box>
		</Tooltip>
	);
}

TblTooltipDynamic.propTypes = {
	children: PropTypes.node,
	placement: PropTypes.string,
	lineHeightText: PropTypes.number, // lineHeight of Typography
	lines: PropTypes.number,
};
