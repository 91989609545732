import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import TblActivityIcon from 'components/TblActivityIcon';
import TblFormHelperText from 'components/TblFormHelperText';

import lessonActions from 'shared/Lesson/actions';
import { APPLY_FOR, APPLY_SECTIONS, DATE_TYPE, DIALOG_TYPE } from 'shared/MyCourses/constants';
import { convertedDefaultValue, processSessionsAllSections } from 'shared/MyCourses/utils';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { BaseModal, Dropdown, Radio } from 'ella-storybook';
import { Field, FieldArray, Form, Formik } from 'formik';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import LessonPopQuizSectionSchedule from './LessonPopQuizSectionSchedule';
import ScheduleAllSectionsSkeleton from './ScheduleAllSectionsSkeleton';
import ScheduleCustomSkeleton from './ScheduleCustomSkeleton';
import { Content, Header } from './styles';

export default function LessonQuizScheduleDialog(props) {
	const {
		name,
		unitId,
		courseId,
		activityId,
		type,
		applyFor,
		visible,
		toggleCloseDialog,
		method,
		shadowId,
		sectionId,
		updateUnit,
		showOldSessions,
	} = props;
	const { t } = useTranslation(['myCourses', 'common', 'error']);
	const dispatch = useDispatch();
	const authContext = useContext(AuthDataContext);
	const { organizationId } = authContext.currentUser;

	const [commonError, setCommonError] = useState({
		isError: false,
		errorMessage: '',
	});

	const { mcConsolidateLessonSuccess, mcConsolidateLessonFailed, sections, sessions, isFetchingSectionsList } =
		useSelector((state) => state.AllCourses);
	const sessionsForAllSections = processSessionsAllSections(sessions, DATE_TYPE.ASSIGN);

	const [formState, setFormState] = useState({
		applyFor: shadowId ? APPLY_FOR.CUSTOM : applyFor ?? APPLY_FOR.ALL,
		showOldSessions,
		assignDate: null,
		sections: [],
	});

	useEffect(() => {
		if (sections?.length) {
			sectionsRef.current = sections;
			let defaultAllAssignDate = sections.find((item) => item.assignDate);

			setFormState({
				applyFor: shadowId ? APPLY_FOR.CUSTOM : applyFor ?? APPLY_FOR.ALL,
				showOldSessions,
				assignDate:
					applyFor === APPLY_FOR.ALL
						? sessionsForAllSections?.find((item) => item.value === Number(defaultAllAssignDate?.assignDate)) || null
						: null,
				sections: sections?.map((item) => ({
					select: false,
					id: item.id,
					assignDate: Number(item?.assignDate)
						? convertedDefaultValue(Number(item?.assignDate), item.id, DATE_TYPE.ASSIGN, sessions)
						: null,
					sectionName: item.sectionName,
					isPassed: item?.isPassed || false,
				})),
			});
		}
	}, [sections, showOldSessions]);

	useEffect(() => {
		if (mcConsolidateLessonSuccess) {
			toggleCloseDialog(type);
		}
		return () => {
			dispatch(
				lessonActions.resetStateLesson({
					lessonDetail: {},
				}),
			);
			dispatch(
				myCoursesActions.myCoursesSetState({
					mcConsolidateLessonSuccess: null,
					mcConsolidateLessonFailed: null,
					applyFor: undefined,
					sections: [],
				}),
			);
		};
	}, [mcConsolidateLessonSuccess]);

	useEffect(() => {
		dispatch(
			myCoursesActions.getAllScheduleSections({
				orgId: organizationId,
				courseId,
				urlParams: { activityId: activityId, type: type },
				isFetchingSectionsList: true,
			}),
		);
	}, [activityId, organizationId, courseId, type]);

	const initialValues = formState;

	const sectionsRef = useRef([]); // work-around because currently cannot found way to update the schema when re-render
	const validationSchema = Yup.object().shape({
		applyFor: Yup.number().required(t('common:required_message')),
		showOldSessions: Yup.boolean(),
		assignDate: Yup.object()
			.nullable()
			.when('applyFor', {
				is: (value) => value === APPLY_FOR.ALL,
				then: Yup.object()
					.required(t('common:required_message'))
					.test('unevenSession', t('uneven_sessions', { ns: 'error' }), function (value) {
						if (value && value.sectionSchedules.length !== sectionsRef.current.length) {
							return false;
						}
						return true;
					}),
			}),
		sections: Yup.array().when('applyFor', {
			is: (value) => value === APPLY_FOR.CUSTOM,
			then: Yup.array().of(
				Yup.object().shape({
					select: Yup.boolean().required(t('common:required_message')),
					assignDate: Yup.object()
						.nullable()
						.when('select', {
							is: true,
							then: Yup.object().nullable().required(t('common:required_message')),
						}),
				}),
			),
		}),
	});

	const onClose = () => {
		toggleCloseDialog(type);
	};

	const handleChange = (fieldName, value, setFieldValue, currentValues) => {
		setFieldValue(fieldName, value);

		if (
			(fieldName.indexOf('select') !== -1 && value && commonError.isError) ||
			(fieldName === 'applyFor' && value !== currentValues.applyFor)
		) {
			//reset error when select
			setCommonError({
				isError: false,
				errorMessage: '',
			});
		}
		if (fieldName === 'applyFor' && currentValues.applyFor !== value) {
			const stateValues = currentValues;
			stateValues.applyFor = value; // because setFieldValue above is async so we have to this value manually
			setFormState(stateValues);
		}
	};
	const handleBlur = (fieldName, setFieldTouched, value = true) => {
		setFieldTouched(fieldName, value);
	};
	const processDataPayload = (metaData, setSubmitting) => {
		let payload = {};
		if (metaData.applyFor === APPLY_FOR.CUSTOM) {
			const { sections } = metaData;
			const sectionsPayload = [];
			if (sections?.length) {
				payload['inThePast'] = false;
				sections.forEach(({ select, assignDate, id }) => {
					if (select) {
						sectionsPayload.push({
							id,
							assignDate,
						});
						if (assignDate.inThePast) {
							payload['inThePast'] = true;
						}
					}
				});
			}
			if (!sectionsPayload.length) {
				setSubmitting(false);
				setCommonError({
					isError: true,
					errorMessage: t('error:please_select_section'),
				});
				return;
			}
			payload['data'] = sectionsPayload;
		} else {
			const { assignDate } = metaData;
			if (assignDate) {
				const assignDateId = assignDate.value;
				payload['data'] = {
					assignDateId,
				};
				if (assignDate.inThePast) {
					payload['inThePast'] = true;
				} else {
					payload['inThePast'] = false;
				}
			}
		}
		payload['applyFor'] = metaData.applyFor;
		payload['showOldSessions'] = metaData.showOldSessions;

		return payload;
	};
	const onSubmit = (payload) => {
		if (method === DIALOG_TYPE.SCHEDULE) {
			dispatch(
				myCoursesActions.mcConsolidateLesson({
					orgId: organizationId,
					courseId,
					unitId,
					masterId: activityId,
					scheduleInThePast: payload?.inThePast,
					body: {
						...payload,
					},
					method: DIALOG_TYPE.SCHEDULE,
				}),
			);
		} else if (method === DIALOG_TYPE.RESCHEDULE) {
			dispatch(
				myCoursesActions.mcConsolidateLesson({
					orgId: organizationId,
					courseId,
					unitId,
					masterId: activityId,
					scheduleInThePast: payload?.inThePast,
					body: {
						...payload,
					},
					method: DIALOG_TYPE.RESCHEDULE,
				}),
			);
		}

		updateUnit && updateUnit(unitId);
	};
	const filterOldSessions = (sessions, showOldSessions) => {
		if (showOldSessions) {
			return sessions;
		}
		return sessions.map((s) => ({
			...s,
			isHidden: s.inThePast || s.value === -1 ? true : false,
		}));
	};

	const checkDisableDone = (values) =>
		values?.applyFor === APPLY_FOR.CUSTOM && !values?.sections?.find((section) => section.select);

	const renderAllSections = (errors, submitCount, values, touched, setFieldValue, setFieldTouched) => {
		switch (type) {
			case ACTIVITY_CATEGORY.LESSON:
				return (
					<Box display='flex' gap={2}>
						<Field
							component={Dropdown}
							// disableClearable
							disableCloseOnSelect={false}
							// fitContent={true}
							groupBy={(option) => values.showOldSessions && option.category}
							placement={'bottom-start'}
							onClose={() => {
								handleBlur('assignDate', setFieldTouched);
							}}
							label={t('delivered_on', { ns: 'myCourses' })}
							width={'100%'}
							name='assignDate'
							helperText={!!(touched?.assignDate && errors?.assignDate) ? errors.assignDate : null}
							error={!!(touched?.assignDate && errors?.assignDate)}
							placeholder={t('common:please_select')}
							options={filterOldSessions(sessionsForAllSections, values.showOldSessions)}
							value={values?.assignDate}
							onChange={(value) => handleChange('assignDate', value, setFieldValue, values)}
						/>
					</Box>
				);
			default:
				return;
		}
	};

	const renderCustomizeSections = (
		errors,
		submitCount,
		values,
		touched,
		setValues,
		isSubmitting,
		setFieldValue,
		setFieldTouched,
	) => (
		<LessonPopQuizSectionSchedule
			errors={errors}
			touched={touched}
			handleBlur={handleBlur}
			submitCount={submitCount}
			handleChange={handleChange}
			sessions={sessions}
			sections={values?.sections}
			values={values}
			type={type}
			setValues={setValues}
			isSubmitting={isSubmitting}
			setFieldValue={setFieldValue}
			setFieldTouched={setFieldTouched}
			showOldSessions={values.showOldSessions}
			filterOldSessions={filterOldSessions}
		/>
	);

	const contentComponent = ({
		touched,
		errors,
		type,
		name,
		values,
		handleChange,
		setFieldValue,
		isFetchingSectionsList,
		renderCustomizeSections,
		renderAllSections,
		commonError,
		setValues,
		isSubmitting,
		setFieldTouched,
		submitCount,
	}) => {
		return (
			<>
				<Header>
					<TblActivityIcon type={type} variant='titleSmall' name={name} />
				</Header>
				<Content>
					<Form>
						<Radio
							label={t('apply_for')}
							name='applyFor'
							options={APPLY_SECTIONS}
							value={values.applyFor}
							onChange={(value) => {
								handleChange('applyFor', Number(value), setFieldValue, values);
							}}
						/>
						<Box className='scheduleSection'>
							{values.applyFor === APPLY_FOR.CUSTOM ? (
								isFetchingSectionsList ? (
									<ScheduleCustomSkeleton type={type} />
								) : (
									<FieldArray name='sections'>
										{renderCustomizeSections(
											errors,
											submitCount,
											values,
											touched,
											setValues,
											isSubmitting,
											setFieldValue,
											setFieldTouched,
										)}
									</FieldArray>
								)
							) : isFetchingSectionsList ? (
								<ScheduleAllSectionsSkeleton type={type} />
							) : (
								renderAllSections(errors, submitCount, values, touched, setFieldValue, setFieldTouched)
							)}
						</Box>
					</Form>
				</Content>
				{commonError.isError && <TblFormHelperText errorMessage={commonError.errorMessage} />}
			</>
		);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			validateOnChange={true}
			validateOnBlur={false}
			onSubmit={(value, actions) => {
				if (commonError.isError) {
					actions.setSubmitting(false);
					return;
				}
				onSubmit(processDataPayload(value, actions.setSubmitting));
			}}
		>
			{({
				errors,
				submitCount,
				values,
				touched,
				setValues,
				setFieldValue,
				setFieldTouched,
				handleSubmit,
				isSubmitting,
			}) => (
				<BaseModal
					open={visible}
					size={'large'}
					onClose={onClose}
					title={t('myCourses:schedule_activity')}
					contentComponent={contentComponent({
						touched,
						errors,
						type,
						name,
						values,
						handleChange,
						setFieldValue,
						isFetchingSectionsList,
						renderCustomizeSections,
						renderAllSections,
						commonError,
						setValues,
						isSubmitting,
						setFieldTouched,
						submitCount,
					})}
					labelPrimaryBtn={t('done', { ns: 'myCourses' })}
					labelSecondaryBtn={t('cancel', { ns: 'common' })}
					showCheckBox={true}
					onCheckBox={(value) => handleChange('showOldSessions', value, setFieldValue, values)}
					labelCheckBox={t('show_old_sessions', { ns: 'myCourses' })}
					isCheckedCheckBox={values.showOldSessions}
					disabledPrimaryBtn={(isSubmitting && !mcConsolidateLessonFailed) || checkDisableDone(values)}
					disabledSecondaryBtn={isSubmitting && !mcConsolidateLessonFailed}
					loadingBtn={isSubmitting && !mcConsolidateLessonFailed}
					onAction={handleSubmit}
				/>
			)}
		</Formik>
	);
}

LessonQuizScheduleDialog.propTypes = {
	activity: PropTypes.object,
	activityId: PropTypes.number,
	applyFor: PropTypes.number,
	courseId: PropTypes.number,
	method: PropTypes.string,
	name: PropTypes.string,
	sectionId: PropTypes.number,
	shadowId: PropTypes.number,
	toggleCloseDialog: PropTypes.func,
	unitId: PropTypes.number,
	gradingPeriodId: PropTypes.number,
	visible: PropTypes.bool,
	type: PropTypes.oneOf(Object.values(ACTIVITY_CATEGORY)),
	showOldSessions: PropTypes.bool,
};

LessonQuizScheduleDialog.defaultProps = {
	type: ACTIVITY_CATEGORY.LESSON,
	showOldSessions: false,
};
