import './wdyr';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import 'material-symbols';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import './i18n';

// import icomoon css to use with Icon component in Material UI
import './assets/styles/icomoon/style.css';
// Perfect scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

if (process.env.NODE_ENV !== 'development')
	Sentry.init({
		dsn: 'https://a9334895f9654e72bbf97368f4304d43@glitch.tabulalearning.net/1',
		integrations: [
			new Sentry.BrowserTracing({
				// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ['*'],
			}),
		],
		tracesSampleRate: 1.0,
	});
ReactDOM.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// eslint-disable-next-line no-undef
serviceWorker.register();
