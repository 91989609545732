import React from 'react';

import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import clsx from 'clsx';
import { Typography } from 'ella-storybook';
import { PropTypes } from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	content: {
		margin: theme.spacing(1),
		color: theme.mainColors.primary1[0],
		minWidth: 0,
	},
	name: {
		width: '100%',
	},
	email: {
		fontSize: theme.fontSize['text-sm'],
	},
	avatar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rootAvatar: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		background: theme.newColors.gray[100],
		color: theme.mainColors.gray[6],
		// fontSize: theme.fontSize['text-sm'],
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		...theme.typography.bodyLargeBold,
	},
	typo: {
		width: '100%',
		textAlign: 'center',
		marginBottom: theme.spacing(0.5),
	},
}));

function UserProfile(props) {
	const { classes } = useStyles();
	const { name, email, lastName } = props?.itemInfo;

	if (!lastName)
		return (
			<div className={classes.itemCard}>
				<Box className={classes.avatar}>
					<Avatar className={`${classes.rootAvatar} 'avatar'`}>
						{(name?.charAt(0).toUpperCase() || '') + (name?.split(' ')[1].charAt(0).toUpperCase() || '')}
					</Avatar>
				</Box>
				<div className={clsx(classes.content, 'content')}>
					<Typography
						className={clsx(classes.typo, 'text-ellipsis')}
						variant='titleMedium'
						component={'div'}
						noWrap={true}
					>
						{name}
					</Typography>
					{email && (
						<Typography
							className={clsx(classes.typo, 'text-ellipsis')}
							component='div'
							variant='bodySmallRegular'
							noWrap
						>
							{email}
						</Typography>
					)}
				</div>
			</div>
		);
}

UserProfile.propTypes = {
	email: PropTypes.string,
	firstName: PropTypes.string,
	iconCollapse: PropTypes.bool,
	itemInfo: PropTypes.object,
	lastName: PropTypes.string,
	name: PropTypes.string,
	status: PropTypes.any,
};

UserProfile.defaultProps = {
	itemInfo: {
		name: '',
		email: 'No Students',
		firstName: 'First Name',
		lastName: 'Last Name',
	},
	iconCollapse: false,
};

export default UserProfile;
