import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';
import { useSchoolYearDataContext } from 'shared/Auth/containers/SchoolYear';

import { AuthDataContext, useAuthDataContext } from 'AppRoute/AuthProvider';
import { checkAuthorized } from 'AppRoute/rolePermission/utils';
import { get } from 'lodash';
import { SCHOOL_YEAR_STATUS } from 'modules/SchoolYear/constants';

import ToggleComment from '../components/ToggleComment';

const ToggleCommentContainer = (props) => {
	const authContext = useContext(AuthDataContext);
	const strPermissions = get(authContext.currentUser, 'permissions', '');
	const commentable = checkAuthorized({
		moduleAlias: MODULE_ALIAS.SUBMISSION_COMMENT,
		permissions: [PERMISSIONS.SUBMISSION_COMMENT.CREATE],
		strPermissions,
	});

	const studentId = useSelector((state) => state.Grader.studentId);
	const comments = useSelector((state) => state.Grader.comments);
	const toggleComment = useSelector((state) => state.Grader.toggleComment);
	const isLoadingComment = useSelector((state) => state.Grader.isLoadingComment);

	const graderDetailId = useSelector((state) => state.Grader.graderDetail?.id);
	const { currentUser } = useAuthDataContext();

	const schoolYearData = useSchoolYearDataContext();
	const hideInputComment =
		 schoolYearData.status === SCHOOL_YEAR_STATUS.ARCHIVED || !commentable;

	const objCommentData = useMemo(
		() => ({
			studentId,
			graderDetailId,
			currentUser,
			hideInputComment,
			comments,
			isLoadingComment,
			toggleComment,
		}),
		[studentId, graderDetailId, currentUser, hideInputComment, comments, isLoadingComment, toggleComment],
	);

	return <ToggleComment {...objCommentData} />;
};

ToggleCommentContainer.propTypes = {};

export default React.memo(ToggleCommentContainer);
