import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	text: {
		color: 'black',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	container: {
		position: 'relative',
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	bottom: {
		color: theme.newColors.gray[200],
	},
	percent: {
		position: 'absolute',
		left: 0,
		color: theme.customColors.primary1.main,
	},
	dot: {
		height: theme.spacing(1),
		width: theme.spacing(1),
		borderRadius: '50%',
		marginRight: theme.spacing(1.25),
	},
	toolTip: {
		width: theme.spacing(22.5),
		padding: theme.spacing(2),
		paddingBottom: 1,
		borderRadius: theme.borderRadius.default,
	},
	itemToolTip: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
		'& span': {
			marginRight: theme.spacing(1.25),
		},
	},
	graderNavBarRoot: {
		height: theme.spacing(10),
		backgroundColor: 'white',
		boxShadow: '0px 1px 4px rgba(33, 37, 41, 0.16)',
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
		paddingTop: theme.spacing(3.2),
		paddingBottom: theme.spacing(3.2),
	},
	courseName: {
		color: theme.newColors.gray[800],
		// maxWidth: theme.spacing(40),
	},
	select: {
		width: theme.spacing(30),
		height: theme.spacing(5),
	},
	divider: {
		display: 'flex',
		height: theme.spacing(3),
	},
	iconClose: {
		marginRight: theme.spacing(2.5),
	},
	list: {
		'& .MuiList-root': {
			width: theme.spacing(30),
			borderRadius: theme.borderRadius.default,
		},
	},
	actionBox: {
		border: '1px solid #E9ECEF',
		borderRadius: theme.spacing(2),
		padding: '8px',
		width: '484px',
		color: theme.newColors.gray[600],
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.newColors.gray[100],
		},
	},
	studentNameBox: {
		marginRight: '8px',
		width: '207px',
		height: 'auto',
	},
	sectionNameBox: {
		width: '120px',
	},
	sectionName: {
		color: theme.newColors.gray[800],
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	avatarContainer: {},
	studentInfo: {
		display: 'flex',
		alignItems: 'center',
		flex: '0 1 auto',
		gap: theme.spacing(1),
	},
	rootAvatar: {
		width: '32px',
		height: '32px',
		background: theme.newColors.gray[200],
		color: theme.newColors.gray[700],
		// fontSize: theme.fontSize['text-sm'],
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		...theme.typography.bodyLargeBold,
	},
}));

export { useStyles };
