import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from 'ella-storybook';
import { STUDENT_PROGRESS_STATUS } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';
import theme from 'theme';

import { useStyles } from './styles';

function BlockSubmissionStatus({ submissionStatus }) {
	const { t } = useTranslation(['myCourses', 'common']);
	const { classes } = useStyles();

	const { title: submissionTitle, color: submissionColor } = useMemo(() => {
		let title;
		let color;
		switch (+submissionStatus) {
			case STUDENT_PROGRESS_STATUS.TURN_IN:
				title = t('turn_in', { ns: 'myCourses' });
				color = theme.newColors.green[600];
				break;
			case STUDENT_PROGRESS_STATUS.MISSED:
				title = t('missed', { ns: 'myCourses' });
				color = theme.newColors.gray[400];
				break;
			case STUDENT_PROGRESS_STATUS.LATE_TURN_IN:
				title = t('turned_in_late', { ns: 'myCourses' });
				color = theme.newColors.yellow[600];
				break;
			case STUDENT_PROGRESS_STATUS.MISSING:
				title = t('missing', { ns: 'myCourses' });
				color = theme.newColors.red[600];
				break;
			case STUDENT_PROGRESS_STATUS.NOT_TURN_IN:
				title = t('not_turned_in', { ns: 'myCourses' });
				color = theme.newColors.gray[400];
				break;
			default:
				break;
		}
		return { title, color };
	}, [submissionStatus]);

	return (
		<Box className={classes.boxItem}>
			<Box className={classes.flexBox} gap={1}>
				<Typography sx={{ color: (theme) => theme.newColors.gray[600] }} variant='titleSmall'>
					{`${t('submission', { ns: 'myCourses' })} `}
				</Typography>
				<Typography variant='titleSmall' style={{ color: submissionColor }}>
					{submissionTitle}
				</Typography>
			</Box>
		</Box>
	);
}

BlockSubmissionStatus.propTypes = {
	submissionStatus: PropTypes.number,
};

export default BlockSubmissionStatus;
