import React from 'react';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box } from '@mui/material';

import { TblIconButton } from 'components/TblIconButton';

import EllaLogo from 'assets/images/ella_logo.svg';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: theme.navbar.appBar,
		padding: theme.spacing(2.5, 2, 2.5, 1.25),
	},
	button: {
		margin: theme.spacing(0, 0.5),
		marginRight: theme.spacing(2),
	},
}));
function LogoSidebar(props) {
	const { classes } = useStyles();
	const { toggleSidebar } = props;

	return (
		<Box className={classes.root}>
			<Box display='flex' alignItems='center'>
				<TblIconButton className={classes.button} size='large' onClick={() => toggleSidebar()}>
					<MenuRoundedIcon />
				</TblIconButton>
				<img src={EllaLogo} alt='ella_logo' width={88} height={40} />
			</Box>
		</Box>
	);
}

LogoSidebar.propTypes = {
	toggleSidebar: PropTypes.func,
};

export default LogoSidebar;
