import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@mui/material';

import { Box, Divider, Typography } from 'ella-storybook';
import { size } from 'lodash';

import Loading from './Loading';

const ListCourse = ({ isGettingCoursesNeedReschedule, courseList, onSelectCourse, temporaryInfo, rescheduling }) => {
	const { t } = useTranslation();

	return isGettingCoursesNeedReschedule ? (
		<Loading title={t('please_wait_while_process_data', { ns: 'common' })} />
	) : (
		<Box>
			<Box display={'flex'} flexDirection={'column'} gap={1} sx={{ marginBottom: (theme) => theme.spacing(1) }}>
				<Typography component={'div'} variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[800] }}>
					{t('update_schedule_modal_content', { ns: 'myCourses' })}
				</Typography>
				<Typography component={'div'} variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[500] }}>
					{`${size(courseList)} ${t('remaining_courses', {
						ns: 'myCourses',
						ending: size(courseList) > 1 ? 's' : '',
					})}`}
				</Typography>
			</Box>

			{(courseList || []).map((item, i) => (
				<Box>
					{i !== 0 && <Divider />}
					<Box
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
						paddingY={'12px'}
					>
						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
							maxWidth={(theme) => `calc( 100% - ${theme.spacing(6.375)} )`}
						>
							<Avatar alt='course_image' src={item?.courseImageUrl} />
							<Typography
								className='text-ellipsis'
								variant='titleSmall'
								component={'div'}
								sx={{ color: (theme) => theme.newColors.gray[900] }}
							>
								{item.courseName}
							</Typography>
						</Box>

						<Typography
							onClick={() => onSelectCourse && onSelectCourse(item)}
							variant='button'
							component={'div'}
							sx={{ color: (theme) => theme.newColors.primary[500], cursor: 'pointer' }}
						>
							{t('update', { ns: 'common' })}
						</Typography>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default memo(ListCourse);
