import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APPLY_FOR } from 'shared/MyCourses/constants';

import { Box, Typography } from 'ella-storybook';
import { Form, Formik } from 'formik';
import { get, isEmpty, isEqual, pick, size } from 'lodash';
import { ACTIVITY_CATEGORY, PUBLISH_NOW } from 'modules/MyCourses/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { filterSectionOptions } from 'utils';
import * as Yup from 'yup';

import Loading from './Loading';
import Sections from './Sections';

const ListSessions = ({
	getSectionsRescheduleActivityLoading,
	sections,
	sessions,
	selectedActivity,
	scheduleFormikRef,
	temporarySessions,
	metaDataSchedule,
	hasUpdated,
	onSubmit,
	onAllowShowLeavePage,
}) => {
	const { t } = useTranslation('myCourses');

	const [generalTouched, setGeneralTouched] = useState(null);
	const [generalErrors, setGeneralErrors] = useState(null);

	const mappedSectionWithSession = useCallback(
		({ filterOld = false }) => {
			if (isEmpty(sections)) return [];

			const result = sections.map((section) => {
				const { sectionId } = section;
				const assignOption = filterSectionOptions({
					sessions,
					sectionId,
					key: 'assign',
					filterOld,
					typeSession: 'start',
				});
				const dueOption = filterSectionOptions({
					sessions,
					sectionId,
					key: 'due',
					filterOld,
					typeSession: 'end',
				});

				return {
					...section,
					assignOption,
					dueOption,
				};
			});

			return result;
		},
		[sections, sessions],
	);

	const handleChange = useCallback(
		(fieldName, value, index, key, values, setFieldValue) => {
			const currentSectionId = get(values, `sections.[${index}].sectionId`, '');
			setFieldValue(fieldName, value);
			onAllowShowLeavePage(value, currentSectionId, key);
		},
		[onAllowShowLeavePage],
	);
	const handleBlur = useCallback((fieldName, value = true, setFieldTouched) => {
		setFieldTouched(fieldName, value);
	}, []);

	const memoOldSection = useMemo(() => {
		const modifiedSections = mappedSectionWithSession({ filterOld: false });
		return (
			<Box maxWidth={'500px'}>
				<Sections
					showSectionName={true}
					disableAll={true}
					sections={modifiedSections}
					{...{ touched: generalTouched, errors: generalErrors, metaDataSchedule }}
				/>
			</Box>
		);
	}, [mappedSectionWithSession, metaDataSchedule, generalTouched, generalErrors]);

	const memoNewSection = useCallback(
		({ setFieldValue, setFieldTouched, values, touched, errors }) => {
			setGeneralErrors((prev) => (!isEqual(errors, prev) ? errors : prev));
			setGeneralTouched((prev) => (!isEqual(touched, prev) ? touched : prev));

			const modifiedSections = mappedSectionWithSession({ filterOld: true });

			return (
				<Box maxWidth={'446px'}>
					<Sections
						sections={modifiedSections}
						{...{
							values,
							touched,
							errors,
							handleChange,
							handleBlur,
							temporarySessions,
							metaDataSchedule,
							setFieldValue,
							setFieldTouched,
							hasUpdated,
						}}
					/>
				</Box>
			);
		},
		[
			sections,
			sessions,
			temporarySessions,
			metaDataSchedule,
			hasUpdated,
			onAllowShowLeavePage,
			handleChange,
			handleBlur,
		],
	);

	const assignLabel =
		selectedActivity?.activityType === ACTIVITY_CATEGORY.ASSIGNMENT
			? t('assigned_on', { ns: 'myCourses' })
			: t('announced_on', { ns: 'myCourses' });
	const dueLabel =
		selectedActivity?.activityType === ACTIVITY_CATEGORY.ASSIGNMENT
			? t('due_on', { ns: 'myCourses' })
			: t('taken_on', { ns: 'myCourses' });

	const getValidDateValue = ({ objDate, optionsToCompare = [], callback }) => {
		let result = null;
		if (isEmpty(optionsToCompare) || optionsToCompare.find((option) => `${option.value}` === `${objDate?.value}`)) {
			result = objDate;
		}

		return result;
	};

	const initialValues = useMemo(
		() => ({
			sections: (mappedSectionWithSession({ filterOld: true }) || []).map((section, index) => ({
				sectionId: section?.sectionId,
				dueDate:
					temporarySessions?.find((temp) => temp?.sectionId === section?.sectionId)?.['dueDate'] ??
					getValidDateValue({ objDate: get(section, 'new.due'), optionsToCompare: get(section, 'dueOption') }),
				assignDate: temporarySessions?.find((temp) => temp?.sectionId === section?.sectionId)?.['assignDate'] ??
					get(section, 'new.assign') ?? { value: PUBLISH_NOW, label: get(metaDataSchedule, 'publishNow', ''), index },
			})),
		}),
		[temporarySessions, selectedActivity, metaDataSchedule, mappedSectionWithSession],
	);
	const validationSchema =
		selectedActivity?.activityType === ACTIVITY_CATEGORY.LESSON
			? Yup.object().shape({
					sections: Yup.array().of(
						Yup.object().shape({
							assignDate: Yup.object().nullable().required(t('common:required_message')),
							dueDate: Yup.object().nullable(),
						}),
					),
			  })
			: Yup.object().shape({
					sections: Yup.array().of(
						Yup.object().shape({
							assignDate: Yup.object().nullable().required(t('common:required_message')),
							dueDate: Yup.object()
								.nullable()
								.required(t('common:required_message'))
								.test(
									'compareAssignOn',
									t('due_on_less_than_assign_on', { ns: 'error', dueOn: dueLabel, assignOn: assignLabel }),
									function (value) {
										const { assignDate, dueDate, sectionId } = this.parent;

										if (get(assignDate, 'value') === PUBLISH_NOW) {
											const sectionsWithSession = get(
												mappedSectionWithSession({ filterOld: true }).find((s) => +get(s, 'sectionId') === +sectionId),
												'dueOption',
												[],
											);

											const endDate = get(
												sectionsWithSession.find((s) => {
													return +get(s, 'value') === +get(dueDate, 'value');
												}),
												'endDate',
												null,
											);

											if (!endDate) {
												return;
											}

											return moment(endDate).isSameOrAfter(moment());
										}
										if (assignDate && dueDate) {
											const assignDateNum = Number(assignDate.label.split(' ')[1]);
											const dueDateNum = Number(dueDate.label.split(' ')[1]);

											return assignDateNum > dueDateNum ? false : true;
										}
										return true;
									},
								),
						}),
					),
			  });

	const convertDataToSubmit = (values) => {
		let payload = {};
		const { sections } = values;
		const sectionsPayload = [];

		if (size(sections)) {
			sections.forEach(({ assignDate, dueDate, sectionId }) => {
				sectionsPayload.push({
					id: sectionId,
					assignDate:
						get(assignDate, 'value') === PUBLISH_NOW
							? { value: get(assignDate, 'publishAt', '') ? moment(get(assignDate, 'publishAt', '')) : moment() }
							: pick(assignDate, 'value'),
					dueDate: pick(dueDate, 'value'),
				});
			});
		}

		payload['data'] = sectionsPayload;
		payload['applyFor'] = APPLY_FOR.CUSTOM;
		return payload;
	};

	return getSectionsRescheduleActivityLoading ? (
		<Loading title={t('please_wait_while_process_data', { ns: 'common' })} />
	) : (
		<>
			<Box display={'flex'}>
				<Formik>
					<Box display={'flex'} flex={1} flexDirection={'column'} gap={2}>
						<Box display={'flex'} gap={2}>
							<Box width={'83px'} />
							<Typography component={'div'} variant='titleSmall'>
								{t('old')}
							</Typography>
						</Box>
						<Box
							display={'flex'}
							gap={1}
							paddingRight={2}
							marginRight={2}
							borderRight={(theme) => `2px dashed ${theme.newColors.gray[200]}`}
						>
							{memoOldSection}
						</Box>
					</Box>
				</Formik>

				<Formik
					innerRef={scheduleFormikRef}
					initialValues={initialValues}
					validationSchema={validationSchema}
					// enableReinitialize={true}
					validateOnChange={true}
					validateOnBlur={false}
					oChange={(e) => {}}
					onSubmit={(value, actions) => {
						onSubmit(convertDataToSubmit(value));
					}}
				>
					{({ errors, handleSubmit, values, touched, setFieldValue, setFieldTouched, setValues, isSubmitting }) => (
						<Form>
							<Box display={'flex'} flex={1} flexDirection={'column'} gap={2}>
								<Box display={'flex'}>
									<Box />
									<Typography component={'div'} variant='titleSmall'>
										{t('new')}
									</Typography>
								</Box>

								<Box display={'flex'} gap={1}>
									{memoNewSection({ values, touched, errors, setFieldValue, setFieldTouched })}
								</Box>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>
		</>
	);
};

ListSessions.propTypes = {
	getSectionsRescheduleActivityLoading: PropTypes.bool,
	hasUpdated: PropTypes.any,
	metaDataSchedule: PropTypes.oneOfType([
		PropTypes.shape({
			assignTitle: PropTypes.string,
			dueTitle: PropTypes.string,
			publishNow: PropTypes.string,
			hideDue: PropTypes.bool,
		}),
		PropTypes.object,
	]),
	onAllowShowLeavePage: PropTypes.func,
	onClickBack: PropTypes.func,
	onSubmit: PropTypes.func,
	scheduleFormikRef: PropTypes.shape({
		current: PropTypes.any,
	}),
	sections: PropTypes.array,
	selectedActivity: PropTypes.shape({
		activityName: PropTypes.string,
		activityType: PropTypes.number,
	}),
	sessions: PropTypes.array,
	temporarySessions: PropTypes.array,
};

ListSessions.defaultProps = {
	getSectionsRescheduleActivityLoading: false,
	metaDataSchedule: null,
	selectedActivity: null,

	sections: [],
	sessions: [],
	temporarySessions: [],
};

export default memo(ListSessions, (prev, curr) => {
	// const keys = Object.keys(prev);

	// keys.forEach((k, index) => {
	// 	if (!isEqual(curr[k], prev[k])) {
	// 		console.log('not equal' + index, k, curr[k], prev[k]);
	// 	}
	// });
	return isEqual(prev, curr);
});
