import moment from 'moment';

export const isValidDate = (dateTime) => {
	return dateTime && moment(moment(new Date(dateTime)).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid();
};

function isToday(momentDate) {
	const current = moment();
	const today = current.clone();

	return momentDate.isSame(today, 'd');
}

function isTomorrow(momentDate) {
	const current = moment();
	const tomorrow = current.clone().add(1, 'days');

	return momentDate.isSame(tomorrow, 'd');
}

function isThisWeek(momentDate) {
	const current = moment();

	return momentDate.isSame(current, 'week');
}

//// exported functions
const checkDateInAssignedTabAndCourseContent = (date) => {
	// const subDay = moment().subtract(2, 'days');
	const subDay = moment();

	const compare = moment(date);

	if (isToday(compare)) {
		return { trans: 'due_today', order: 1 };
	}

	if (isTomorrow(compare)) {
		return { trans: 'due_tomorrow', order: 2 };
	}

	if (isThisWeek(compare)) {
		return { trans: 'done_this_week', thisWeek: true, order: 3, dateOfWeek: Number(compare.format('D')) };
	}

	const checkNextWeek = compare.week() - subDay.week();
	const isNextYear = compare.year() > subDay.year();

	if (checkNextWeek === 1 || (isNextYear && compare.week() === 1)) {
		return { trans: 'due_next_week', order: 4 };
	}
	if (checkNextWeek > 1 || isNextYear) {
		return { trans: 'later_week', order: 5 };
	}
	if (checkNextWeek <= 0) return {}; // To do:  Waiting BA discuss case // history

	return {};
};

const formatDateTime = (value, formatBoth, opts) => {
	if (!isValidDate(value)) {
		return {
			formatted: '',
		};
	}

	const date = moment(value);
	const _time = 'h:mm A';
	const _date = opts?.dateFormat || 'dddd, MMM DD';

	if (isToday(date))
		return {
			isAlert: true,
			formatted: `Today - ${date.format(_time)}`,
		};

	if (isTomorrow(date))
		return {
			isAlert: true,
			formatted: `Tomorrow - ${date.format(_time)}`,
		};

	return {
		...(formatBoth && { formatDate: date.format(_date) }),
		formatted: date.format(`${_date} - ${_time}`),
	};
};

export { formatDateTime, checkDateInAssignedTabAndCourseContent };
