import MCheckbox from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as CheckboxIndeterminate } from 'assets/images/icn_checkbox_indeterminate.svg';
import { ReactComponent as CheckboxSelected } from 'assets/images/icn_checkbox_selected.svg';
import { ReactComponent as CheckboxUnselected } from 'assets/images/icn_checkbox_unselected.svg';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import styles from './styles';

const Checkbox = (props) => (
	<MCheckbox
		disableRipple
		checkedIcon={<SvgIcon component={CheckboxSelected} />}
		indeterminateIcon={<SvgIcon component={CheckboxIndeterminate} />}
		icon={<SvgIcon component={CheckboxUnselected} />}
		{...props}
	/>
);

Checkbox.propTypes = {
	disableRipple: PropTypes.bool,
	checkedIcon: PropTypes.node,
	indeterminateIcon: PropTypes.node,
	icon: PropTypes.node,
};

export default withStyles(Checkbox, styles);
