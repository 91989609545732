import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';

import { GlobalStyles } from '@mui/material';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { BreadcrumbProvider } from 'components/TblBreadcrumb';
import ErrorPage from 'components/TblErrorPage';
import BlockThirdPartyErrorPage from 'components/TblErrorPage/BlockThirdPartyError';
import InternalError from 'components/TblErrorPage/InternalError';

import CurrentUser from 'shared/Auth/containers/CurrentUser';
import DomainStatus from 'shared/Auth/containers/DomainStatus';
import SchoolYearProvider from 'shared/Auth/containers/SchoolYear';

import * as Sentry from '@sentry/react';
import { BotV2 as Bot, themeConfig } from 'cs-tools-ai';
import { createBrowserHistory as createHistory } from 'history';
import SwitchLayout, { Layout, LayoutProvider } from 'layout';
import { SnackbarProvider } from 'notistack';

import { AuthProvider, PrivateRoute, PublicRoute } from './AppRoute';
import globalStyles from './global.styled';
import NotificationProvider from './modules/Notifications/container/NotificationProviderV2';
import routes from './routes';
import GoogleEventListener from './shared/Google/container/GoogleEventListener';
import setupStore from './store';
import './yup';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './App.css';
import ServerEventProvider from 'modules/Notifications/container/ServerEventProvider';
const configTheme = themeConfig.light;

const history = createHistory();
const store = setupStore();
// const routes = getRoutes();
const internalError = () => <InternalError history={history} />;
function App() {
	const privateRoutes = [];
	const publicRoutes = [];
	const [openZella, setOpenZella] = useState(false);

	for (let i = 0; i < routes.length; i++) {
		if (routes[i].private) {
			privateRoutes.push(routes[i]);
		} else {
			publicRoutes.push(routes[i]);
		}
	}
	return (
		<Sentry.ErrorBoundary fallback={internalError} showDialog={false}>
			<GlobalStyles styles={globalStyles} />
			<Provider store={store}>
				<LocalizationProvider dateAdapter={DateAdapter}>
					{/* <MuiPickersUtilsProvider utils={TabulaMomentUtils}> */}
					<AuthProvider>
						<CurrentUser />
						<DomainStatus>
							<Router history={history}>
								<SchoolYearProvider>
									<LayoutProvider>
										<BreadcrumbProvider>
											<SnackbarProvider maxSnack={3}>
												<GoogleEventListener>
													<NotificationProvider>
														<ServerEventProvider>
															<Layout routes={routes} setOpenZella={setOpenZella}>
																<SwitchLayout routes={privateRoutes}>
																	{privateRoutes.map((route, index) => (
																		<PrivateRoute
																			key={index}
																			path={route.path}
																			exact={route.exact}
																			routerRoles={route.roles}
																			component={route.component}
																			routes={route.routes}
																			isPublic={!route.private}
																			external={route.external}
																			moduleAlias={route.moduleAlias}
																			permissions={route.permissions}
																		/>
																	))}
																	{publicRoutes.map((route, index) => (
																		<PublicRoute
																			component={route.component}
																			path={route.path}
																			exact={route.exact}
																			routes={route.routes}
																			isPublic={!route.private}
																			external={route.external}
																			key={index}
																		/>
																	))}
																	<Route
																		path='/block-third-party'
																		component={BlockThirdPartyErrorPage}
																		isPublic={true}
																	/>
																	<Route path='*' component={ErrorPage} isPublic={true} />
																</SwitchLayout>
																<Bot
																	isOpen={openZella}
																	themeConfig={configTheme}
																	onClose={() => setOpenZella(false)}
																	apiKey={process.env.REACT_APP_API_KEY_ZELLA}
																	mRightHeader={200}
																	title='Zella'
																/>
															</Layout>
														</ServerEventProvider>
													</NotificationProvider>
												</GoogleEventListener>
											</SnackbarProvider>
										</BreadcrumbProvider>
									</LayoutProvider>
								</SchoolYearProvider>
							</Router>
						</DomainStatus>
					</AuthProvider>
				</LocalizationProvider>
				{/* </MuiPickersUtilsProvider> */}
			</Provider>
		</Sentry.ErrorBoundary>
	);
}
export default App;
