import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import lessonActions from 'shared/Lesson/actions';

import { get, pick } from 'lodash';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';

export const useInitiateActivityData = ({
	params = {},
	shouldResetData = false,
	shouldReloadInstruction,
	shouldReloadStudentWork,
}) => {
	const dispatch = useDispatch();

	const [hadInitiatedInstruction, setHadInitiatedInstruction] = useState(false);
	const [hadInitiatedStudentWork, setHadInitiatedStudentWork] = useState(false);
	const selectedSectionRef = useRef();

	const assignment = useSelector((state) => get(state, 'AllCourses.assignment', null));
	const test = useSelector((state) => get(state, 'AllCourses.test', null));
	const lesson = useSelector((state) => get(state, 'AllCourses.lessonDetail', null));
	const participation = useSelector((state) => get(state, 'AllCourses.participation', null));
	const gradingLateCompletion = useSelector((state) => get(state, 'AllCourses.gradingLateCompletion', null));
	const sections = useSelector((state) => get(state, 'AllCourses.sectionsAndMeetingTimes.sections', null));
	const term = useSelector((state) => get(state, 'AllCourses.sectionsAndMeetingTimes.terms[0]', null));
	const activityStudentWorkList = useSelector((state) => get(state, 'AllCourses.activityStudentWorkList', null));
	const gradingPeriod = useSelector((state) =>
		get(state, 'AllCourses.sectionsAndMeetingTimes.terms[0].gradingPeriod[0]', null),
	);

	const getActivitySuccess = useSelector((state) => {
		return (
			get(state, 'AllCourses.getLessonDetailSuccess', false) ||
			get(state, 'AllCourses.getAssignmentDetailSuccess', false) ||
			get(state, 'AllCourses.getTestDetailSuccess', false) ||
			get(state, 'AllCourses.getParticipationDetailSuccess', false)
		);
	});

	/* prettier-ignore */
	const { 
            organizationId,
            courseId,
            unitId,
            activityId,
            schoolYearId,
            activityType,
            selectedSection,
			searchValueInput,
        } = pick(params, [
			'organizationId',
			'courseId',
			'unitId',
			'activityId',
			'schoolYearId',
			'activityType',
			'selectedSection',
			'searchValueInput',
		]);

	const isParticipation = activityType === ACTIVITY_CATEGORY.PARTICIPATION;

	useEffect(() => {
		setHadInitiatedStudentWork(false);
	}, [selectedSection]);

	useEffect(() => {
		setHadInitiatedStudentWork(false);
	}, [activityType]);

	useEffect(() => {
		if (shouldResetData || activityId) {
			setHadInitiatedInstruction(false);
			setHadInitiatedStudentWork(false);
		}
	}, [shouldResetData, activityId]);

	useEffect(() => {
		if (shouldReloadStudentWork) {
			setHadInitiatedStudentWork(false);
		}
	}, [shouldReloadStudentWork]);

	useEffect(() => {
		if (shouldReloadInstruction) {
			setHadInitiatedInstruction(false);
		}
	}, [shouldReloadInstruction]);

	// Initiate data for instruction tab
	useEffect(() => {
		if (
			!organizationId ||
			!activityType ||
			!courseId ||
			!(unitId || isParticipation) ||
			!activityId ||
			!schoolYearId ||
			hadInitiatedInstruction
		) {
			return;
		}

		let _hadInitiated = true;

		dispatch(
			myCoursesActions.mcGetGradeCalculation({
				courseId: courseId,
				orgId: organizationId,
				urlParams: {
					isCreateActivity: true,
					...(isParticipation && { isParticipation: true }),
				},
			}),
		);

		dispatch(
			myCoursesActions.getDetailGradingLateCompletion({
				orgId: organizationId,
				courseId: courseId,
			}),
		);

		dispatch(
			myCoursesActions.getSectionsAndMeetingTimes({
				orgId: organizationId,
				courseId,
				isFetchingSectionAndMeetingTimes: true,
			}),
		);

		dispatch(
			myCoursesActions.getPermissionCourse({
				orgId: organizationId,
				courseId,
			}),
		);
		if (activityType === ACTIVITY_CATEGORY.ASSIGNMENT) {
			dispatch(
				myCoursesActions.getAssignmentDetail({
					orgId: organizationId,
					courseId: courseId,
					unitId: unitId,
					assignmentId: activityId,
					isFetchingAssignmentDetails: true,
					fetchSessionsAfterDone: true,
					assignment: {},
				}),
			);
		} else if ([ACTIVITY_CATEGORY.TEST, ACTIVITY_CATEGORY.TEST_IN_CLASS].includes(activityType)) {
			dispatch(
				myCoursesActions.getTestDetail({
					orgId: organizationId,
					courseId,
					unitId,
					testId: activityId,
					isFetchingTestDetails: true,
					fetchSessionsAfterDone: true,
					test: {},
				}),
			);
		} else if (activityType === ACTIVITY_CATEGORY.LESSON) {
			dispatch(
				lessonActions.getLessonDetail({
					orgId: organizationId,
					courseId,
					unitId: unitId,
					lessonId: activityId,
					isFetchingLessonDetails: true,
					error: null,
					lessonDetail: {},
				}),
			);
		} else if (activityType === ACTIVITY_CATEGORY.PARTICIPATION) {
			dispatch(
				myCoursesActions.getParticipationDetail({
					orgId: organizationId,
					courseId: courseId,
					participationId: activityId,
					isFetchingParticipationDetails: true,
					createNewMasterParticipationSuccess: false,
					editMasterParticipationSuccess: false,
				}),
			);
		} else {
			_hadInitiated = false;
		}

		setHadInitiatedInstruction((prev) => _hadInitiated);
	}, [params, hadInitiatedInstruction]);

	// Initiate data for student work tab
	useEffect(() => {
		if (
			!organizationId ||
			!activityType ||
			!courseId ||
			!unitId ||
			!activityId ||
			!schoolYearId ||
			!selectedSection ||
			hadInitiatedStudentWork
		) {
			return;
		}

		selectedSectionRef.current = selectedSection;

		let _hadInitiated = true;

		if (activityType && activityType !== ACTIVITY_CATEGORY.LESSON && selectedSection) {
			handleActionStudentWork(searchValueInput);
		} else {
			_hadInitiated = false;
		}

		setHadInitiatedStudentWork((prev) => _hadInitiated);
	}, [params, hadInitiatedStudentWork]);

	const handleActionStudentWork = useCallback(
		(searchValue) => {
			dispatch(
				myCoursesActions.getActivityStudentWorkList({
					orgId: organizationId,
					courseId,
					schoolYearId,
					urlParams: {
						activityId,
						activityType,
						sectionId: selectedSection,
						...(searchValue && { search: searchValue.trim() }),
					},
				}),
			);
		},
		[organizationId, courseId, schoolYearId, activityId, activityType, selectedSection],
	);

	return {
		assignment,
		test,
		lesson,
		participation,
		gradingLateCompletion,
		sections,
		term,
		activityStudentWorkList,
		gradingPeriod,
		getActivitySuccess,
		handleActionStudentWork,
	};
};
