import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading({ maxHeight }) {
	return (
		<Box
			display='flex'
			width='100%'
			height={window.innerHeight}
			maxHeight={maxHeight || 'unset'}
			alignContent='center'
			justifyContent='center'
			justifyItems='center'
			alignItems='center'
		>
			<CircularProgress />
		</Box>
	);
}
