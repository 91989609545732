import loadable from 'utils/loadable';

import { MODULE_ALIAS, PERMISSION_BASIC_KEYS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/icn_subjects.svg';

import { ROUTE_SUBJECT } from './constantsRoute';

const Subject = loadable(() => import(/* webpackChunkName: "LazySubject" */ './containers'));

export default [
	{
		path: ROUTE_SUBJECT.DEFAULT,
		component: Subject,
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.SUBJECTS,
		private: true,
		exact: true,
		menu: {
			title: 'Subjects',
			group: 'Course Administration',
			icon: IcnMenu,
			order: 2,
			groupOrder: 1,
		},
	},
];
