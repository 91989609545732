import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as CheckboxIndeterminate } from 'assets/images/icn_checkbox_indeterminate.svg';
import { ReactComponent as CheckboxSelected } from 'assets/images/icn_checkbox_selected.svg';
import { ReactComponent as CheckboxUnselected } from 'assets/images/icn_checkbox_unselected.svg';
import { withStyles } from 'tss-react/mui';

import styles from './styles';

const TblCheckbox = (props) => (
	<Checkbox
		{...props}
		disableRipple
		checkedIcon={<SvgIcon component={CheckboxSelected} />}
		indeterminateIcon={<SvgIcon component={CheckboxIndeterminate} />}
		icon={<SvgIcon component={CheckboxUnselected} />}
	/>
);

export default withStyles(TblCheckbox, styles);
