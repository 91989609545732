import React from 'react';

import isNil from 'lodash/isNil';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';

import TblTooltipDynamic from 'components/TblTooltipDynamic';

import { ReactComponent as IcnAssignment } from 'assets/images/icn_assignment.svg';
import { ReactComponent as IcnLesson } from 'assets/images/icn_lesson.svg';
import { ReactComponent as IcnQuiz } from 'assets/images/icn_quiz.svg';
import { ReactComponent as IcnParticipation } from 'assets/images/participation.svg';
import clsx from 'clsx';
import { Typography } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import styles from './styles';

const TblActivityIcon = (props) => {
	const { type, name, iconColor, variant, justifyContent, lines, lineHeightText } = props;

	const renderIcon = () => {
		switch (Number(type)) {
			case ACTIVITY_CATEGORY.TEST_IN_CLASS: // EL-6178
			case ACTIVITY_CATEGORY.ASSIGNMENT:
				return <SvgIcon component={IcnAssignment} />;
			case ACTIVITY_CATEGORY.TEST:
				return <SvgIcon component={IcnQuiz} />;
			case ACTIVITY_CATEGORY.PARTICIPATION:
				return <SvgIcon component={IcnParticipation} />;
			case ACTIVITY_CATEGORY.LESSON:
				return <SvgIcon component={IcnLesson} />;
			default:
				return <AssignmentOutlinedIcon />;
		}
	};

	return (
		<Box display='flex' gap={1} width='100%' justifyContent={justifyContent} alignItems={'center'}>
			{!isNil(type) && (
				<Box display='flex' alignItems='start' color={iconColor} className={clsx('important-icon')}>
					{renderIcon()}
				</Box>
			)}
			{!isNil(name) && (
				<TblTooltipDynamic
					lines={lines || 3}
					lineHeightText={lineHeightText || 32} // titleLarge
					title={name}
				>
					<Typography variant={variant || 'titleLarge'} color='primary'>
						{name}
					</Typography>
				</TblTooltipDynamic>
			)}
		</Box>
	);
};

TblActivityIcon.propTypes = {
	classes: PropTypes.object,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	quizType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	iconColor: PropTypes.string,
	variant: PropTypes.string,
	lines: PropTypes.number,
	lineHeightText: PropTypes.number,
};

TblActivityIcon.defaultProps = {
	color: 'primary',
};

export default withStyles(TblActivityIcon, styles);
