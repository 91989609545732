import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import trim from 'lodash/trim';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import TblEditor from 'components/TblEditor';
import TblUseSnackbar from 'components/TblUseSnackbar';

import { useSchoolYearDataContext } from 'shared/Auth/containers/SchoolYear';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { Alert, Button, FullScreenDialog, TextField } from 'ella-storybook';
import { useFormik } from 'formik';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY, GRADE_WEIGHT_TYPE, MAX_LENGTH } from 'modules/MyCourses/constants';
import { SCHOOL_YEAR_STATUS } from 'modules/SchoolYear/constants';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { handleConvertBase64Image, replaceHTMLTag } from 'utils';
import * as Yup from 'yup';

import Grading from '../components/Grading';
import { GRADE_TYPE, TYPE_OF_CREATE } from '../constants';
import { checkPermissionCreateAndPublish, getDefaultGradeWeightValue } from '../utils';

const useStyles = makeStyles()((theme) => ({
	indicator: {
		margin: theme.spacing(3, 0, 3.25),
	},
	errorIndicator: {
		marginRight: theme.spacing(26.5),
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
	},
	leftContent: {
		width: theme.spacing(82),
		marginRight: theme.spacing(4),
	},
	estimate: {
		display: 'flex',
		position: 'relative',
		'& .MuiInputBase-root': {
			width: theme.spacing(15),
		},
	},
	estimateInfo: {
		cursor: 'pointer',
		top: '4px',
		'& .material-symbols-rounded': {
			fontSize: theme.spacing(1.75),
		},
	},
	formatTime: {
		color: theme.newColors.gray[400],
		marginTop: theme.spacing(4.5),
		position: 'absolute',
		left: theme.spacing(15),
	},
	rightContent: {
		width: theme.spacing(39),
		gap: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

function CreateParticipationDrawer(props) {
	const {
		isVisible,
		onClose,
		participationData,
		participationId,
		courseIdProp,
		editable,
		updateParticipation,
		shouldResetState,
		shouldCallAPItoGetModalData,
		isViewOnly,
	} = props;
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const { t } = useTranslation(['myCourses', 'common', 'tour']);
	const authContext = useContext(AuthDataContext);
	const { organizationId } = authContext.currentUser;
	const courseId = match.params.courseId || courseIdProp;

	const schoolYearData = useSchoolYearDataContext();
	const archiveMode = schoolYearData.status === SCHOOL_YEAR_STATUS.ARCHIVED;

	const permission = useSelector((state) => state.AllCourses?.permission);
	const havePermissionPublish = checkPermissionCreateAndPublish(permission);
	const gradeWeight = useSelector((state) => state?.MyCourses?.gradeWeight ?? []);
	const participation = useSelector((state) => state?.AllCourses?.participation);
	const isEditingAssignment = useSelector((state) => state?.AllCourses?.isEditingAssignment);
	const isFetchingParticipationDetails = useSelector((state) => state.AllCourses?.isFetchingParticipationDetails);
	const errorMasterAssignment = useSelector((state) => state.AllCourses?.errorMasterAssignment);
	const createNewMasterParticipationSuccess = useSelector(
		(state) => state.AllCourses?.createNewMasterParticipationSuccess,
	);

	const gradeCalculation = useSelector((state) => state.MyCourses?.gradeCalculation);
	const editMasterParticipationSuccess = useSelector((state) => state.AllCourses?.editMasterParticipationSuccess);

	const [isFetching, setIsFetching] = useState(false);
	const [typeOfCreate, setTypeOfCreate] = useState(0);

	const [gradeWeightOptions, setGradeWeightOptions] = useState([]);
	const [gradeWeightInitial, setGradeWeightInitial] = useState(null);

	const validationParticipation = Yup.object().shape({
		name: Yup.string().trim().required(t('common:required_message')),
		description: Yup.string().nullable(),
		gradeType: Yup.number().required(t('common:required_message')),
		// gradeWeightCriteriaId: Yup.mixed().when('gradeType', {
		//   is: (val) => val === GRADE_TYPE.GRADED,
		//   then: Yup.number().nullable().required(t('common:required_message')),
		// }),
		totalPoint: Yup.mixed().when('gradeType', {
			is: (val) => val === GRADE_TYPE.GRADED,
			then: Yup.number().nullable().min(1).max(10000).required(t('common:required_message')),
		}),
	});

	const onSubmit = async (values) => {
		const payload = {
			...values,
			description: replaceHTMLTag(values.description) === '' ? '' : values.description,
			name: trim(values.name),
			gradeWeightCriteriaId: values.gradeWeightCriteriaId,
		};

		payload.description = await handleConvertBase64Image(payload.description);

		if (isEmpty(participation)) {
			dispatch(
				myCoursesActions.createNewMasterParticipation({
					orgId: organizationId,
					courseId: courseId,
					gradingPeriodId: participationData?.gradingPeriodId,
					isFetchingParticipationDetails: true,
					data: payload,
				}),
			);
		} else {
			dispatch(
				myCoursesActions.editParticipation({
					orgId: organizationId,
					courseId: courseId,
					participationId,
					isFetchingParticipationDetails: true,
					isEditingParticipation: true,
					data: payload,
				}),
			);
		}
	};

	const formik = useFormik({
		initialValues: {
			description: participation?.description,
			name: participation?.name,
			gradeWeightCriteriaId: participation?.gradeWeightCriteriaId ?? gradeWeightInitial?.value,
			gradeType: participation?.gradeType ?? GRADE_TYPE.GRADED,
			totalPoint: participation?.totalPoint,
		},
		enableReinitialize: true,
		validationSchema: validationParticipation,
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: onSubmit,
	});

	const { values, touched, errors, setFieldValue, resetForm, setFieldError, submitCount, handleSubmit, handleBlur } =
		formik;

	const toggleCloseDrawer = () => {
		resetForm();
		shouldResetState &&
			dispatch(
				myCoursesActions.myCoursesSetState({
					participation: {},
					errorMasterParticipation: null,
					createNewMasterParticipationSuccess: false,
					editMasterParticipationSuccess: false,
					isFetchingParticipationDetails: false,
					gradeCalculation: {},
					mcGetGradeCalculationSuccessTest: false,
				}),
			);
		onClose();
	};

	const gradeWeightCategories = useCallback(() => {
		dispatch(
			myCoursesActions.mcGetGradeCalculation({
				courseId: courseId,
				orgId: organizationId,
				urlParams: {
					isCreateActivity: true,
					isParticipation: true,
				},
			}),
		);
	}, [courseId, isVisible, organizationId]);

	const getParticipationDetail = useCallback(() => {
		if (participationId && isVisible) {
			setIsFetching(true);

			dispatch(
				myCoursesActions.getParticipationDetail({
					orgId: organizationId,
					courseId: courseId,
					participationId,
					isFetchingParticipationDetails: true,
				}),
			);
		}
	}, [participationId, courseId, organizationId, isVisible]);

	useEffect(() => {
		// NOTE: just re fetch data when open edit mode in build tab. Activity detail page does not need to get data inside drawer
		if (!shouldCallAPItoGetModalData || !isVisible) return;
		gradeWeightCategories();
		getParticipationDetail();
	}, [getParticipationDetail, shouldCallAPItoGetModalData, isVisible]);

	useEffect(() => {
		const gradeWeightAssignment = gradeWeight
			.filter(({ type }) => type === GRADE_WEIGHT_TYPE.PARTICIPATION)
			.map((item) => ({ value: item.id, label: item.name, ...item }));
		if (gradeWeightAssignment.length) {
			// set first grade weight for default
			let init = getDefaultGradeWeightValue({ gradeWeightOptions: gradeWeightAssignment });
			if (!isEmpty(participation)) {
				init = gradeWeightAssignment.find(({ value }) => value === participation?.gradeWeightCriteriaId);
			}

			setGradeWeightInitial(init);
			setGradeWeightOptions(gradeWeightAssignment);
		}

		if (participation?.id === participationId) {
			setIsFetching(false);
		}
		// NOTE: Activity detail page does not need to reset state after unmounting because of UI/UX flashing
		if (!shouldResetState) return;

		return () => {
			myCoursesActions.myCoursesSetState({
				participation: {},
				errorMasterParticipation: null,
				createNewParticipationSuccess: false,
				editMasterParticipationSuccess: false,
				gradeWeight: [],
				isFetchingParticipationDetails: false,
				gradeCalculation: {},
				mcGetGradeCalculationSuccessTest: false,
			});
		};
	}, [participation, participationId, gradeWeight]);

	useEffect(() => {
		if (errorMasterAssignment) {
			switch (errorMasterAssignment.subcode) {
				default:
					break;
			}
		}
	}, [errorMasterAssignment]);

	useEffect(() => {
		if (createNewMasterParticipationSuccess || editMasterParticipationSuccess) {
			switch (typeOfCreate) {
				case TYPE_OF_CREATE.CREATE_AND_PUBLISH:
					toggleCloseDrawer();
					break;
				case TYPE_OF_CREATE.CREATE_AS_DRAFT:
					toggleCloseDrawer();
					break;
				default:
					break;
			}
			updateParticipation && updateParticipation(participationId);
		}
	}, [createNewMasterParticipationSuccess, editMasterParticipationSuccess]);

	const handleChange = (fieldName, value) => {
		if (value !== values[fieldName]) {
			setFieldValue(fieldName, value);
		}
	};

	const editorChange = React.useCallback(
		(content) => {
			const trimValue = replaceHTMLTag(content);
			if (touched.description || trimValue || participationId) {
				handleChange('description', content);
			}
			if (!trimValue) {
				handleChange('description', '');
			}
		},
		[touched.description, participationId],
	);

	const editorMemo = React.useMemo(
		() => (
			<TblEditor
				name='description'
				disabled={isViewOnly || archiveMode}
				label={t('common:description')}
				placeholder={t('participation_description_placeholder')}
				// customButtons={{
				//   insertImage: {
				//     onClick: insertImage,
				//   },
				// }}
				onChange={editorChange}
				defaultValue={values.description}
			/>
		),
		[values.description, touched.description, participationId],
	);

	const renderContent = () => (
		<form>
			{editMasterParticipationSuccess && <TblUseSnackbar />}
			{createNewMasterParticipationSuccess && (
				<TblUseSnackbar message={t('common:object_created', { objectName: 'Participation' })} />
			)}

			{!isEmpty(errorMasterAssignment) && ![4, 5].includes(errorMasterAssignment?.subcode) && (
				<Box className={classes.errorIndicator} mb={2}>
					<Alert severity='error'>{errorMasterAssignment?.message}</Alert>
				</Box>
			)}
			<div className={classes.content}>
				<div className={classes.leftContent}>
					<Box mt={2}>
						<TextField
							fullWidth={true}
							name='name'
							required
							onBlur={handleBlur}
							disabled={isViewOnly || archiveMode}
							label={t('common:name')}
							placeholder={t('common:enter_name')}
							type='text'
							error={!!((touched.name || submitCount) && errors.name)}
							helperText={!!(touched.name || submitCount) && errors.name}
							inputProps={{ maxLength: MAX_LENGTH.ACTIVITY_NAME }}
							value={values?.name}
							onChange={(e) => handleChange('name', e.target.value)}
						/>
					</Box>
					<Box mt={3}>{editorMemo}</Box>
					{!isViewOnly && <></>}
				</div>
				<div className={classes.rightContent}>
					<Grading
						activityType={ACTIVITY_CATEGORY.PARTICIPATION}
						gradeCalculation={gradeCalculation}
						values={values}
						handleBlur={handleBlur}
						errors={errors}
						touched={touched}
						handleChange={handleChange}
						disabled={!editable || archiveMode}
						setFieldError={setFieldError}
						isEditCategory={participation?.isEditCategory}
					/>
				</div>
			</div>
		</form>
	);

	const handleUpdateParticipation = async () => {
		// if (values.totalPoint !== participation.totalPoint) {
		// setFieldError('totalPoint', t('can_not_update_total_possible_points'));
		//   return false;
		// }

		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AS_DRAFT);
		handleSubmit(values);
	};

	const handleCreateAndPublish = async () => {
		setTypeOfCreate(TYPE_OF_CREATE.CREATE_AND_PUBLISH);
		handleSubmit(values);
	};

	return (
		<>
			<FullScreenDialog
				open={isVisible}
				onClose={toggleCloseDrawer}
				title={
					isFetching ? (
						<Skeleton variant='text' />
					) : !isEmpty(participation) ? (
						t('edit_the_participation')
					) : (
						t('create_an_participation')
					)
				}
				groupButtons={
					!!!participationId ? (
						<Button
							disabled={(isFetchingParticipationDetails || isViewOnly || isEditingAssignment) && havePermissionPublish}
							onClick={() => {
								handleCreateAndPublish();
							}}
							variant='primary'
						>
							{t('create_and_publish')}
						</Button>
					) : (
						!archiveMode &&
						!isViewOnly && (
							<Stack direction={'row'} gap={2}>
								<Button size='medium' variant='outlined' color='primary' onClick={toggleCloseDrawer}>
									{t('common:cancel')}
								</Button>
								<Button
									disabled={isFetchingParticipationDetails || isEditingAssignment}
									loading={isEditingAssignment}
									size='medium'
									variant='primary'
									onClick={handleUpdateParticipation}
								>
									{t('common:save')}
								</Button>
							</Stack>
						)
					)
				}
			>
				{renderContent()}
			</FullScreenDialog>
		</>
	);
}
CreateParticipationDrawer.propTypes = {
	onClose: PropTypes.func,
	updateUnit: PropTypes.func,
	unit: PropTypes.object,
	participationId: PropTypes.number,
	courseIdProp: PropTypes.number,
	isVisible: PropTypes.bool,
	assignmentInfo: PropTypes.object,
	unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	shadowAssignmentInfo: PropTypes.object,
	getCourseItemByUnit: PropTypes.func,
	isViewOnly: PropTypes.bool,
	assignmentViewData: PropTypes.object,
};
CreateParticipationDrawer.defaultProps = {
	isViewOnly: false,
	editable: true,
	viewMode: true,
	shouldCallAPItoGetModalData: true,
	shouldResetState: true,
};

export default React.memo(CreateParticipationDrawer, isEqual);
