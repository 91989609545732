import get from 'lodash/get';

import { isEmpty, isNumber } from 'lodash';
import { ASSIGNMENT_STATUS } from 'modules/MyCourses/constants';

export const buildStudentList = ({ studentData, sections }) => {
	if (isEmpty(studentData)) {
		return [];
	}

	const result = studentData.map((section, idx) => {
		const sectionName = get(section, 'sectionName', '');
		const totalPoint = get(section, 'totalPoint', '');
		const sectionId = get(section, 'sectionId', '');
		const shadow = get(section, 'shadow', {});
		const studentList = get(section, 'studentList', []);
		const mapped = studentList.map((student) => ({
			studentName: `${student.firstName} ${student.lastName}`,
			firstName: student.firstName,
			lastName: student.lastName,
			dueOn: `${student.sessionName} (${student.dateTimeLabel} ${student.endTimeLabel})`,
			grade: `${student.overallGrade ?? '--'}/${totalPoint}`,
			hasCustomDue: !!student.isCustomDue,
			hasOverriddenGrade: !!student.isOverridden,
			sectionId,
			sectionName,
			shadowId: student?.shadowId,
			studentId: student.studentId,
			canCustomDue: student.canCustomDue,
			dueDateIdCustom: student?.dueDateIdCustom,
			originDueId: student.originDueId,
			assignDate: student.assignDate,
			canResetDue: ![ASSIGNMENT_STATUS.UNSCHEDULED, ASSIGNMENT_STATUS.PUBLISHED_LATE,ASSIGNMENT_STATUS.CLOSED].includes(shadow?.status),
		}));

		return {
			sectionId,
			sectionName,
			studentList: mapped,
		};
	});
	return result;
};

export const isFalsy = (value) => {
	return !!(
		['', 0, undefined, null, false].includes(value) ||
		(Array.isArray(value) && !value?.length) ||
		!Object.keys(value)?.length ||
		(isNumber(value) && isNaN(+value))
	);
};
