import React from 'react';
import { useTranslation } from 'react-i18next';

import { GradeInput } from 'components/GradeInput';
import GradingStatusSaved from 'shared/MyCourses/components/GradingStatusSaved';

import { GRADE_CALCULATION_ATTEMPT, GRADE_CALCULATION_ATTEMPT_TYPE } from 'shared/MyCourses/constants';

import { Box, Divider, Skeleton, Typography } from 'ella-storybook';
import { OVERRIDE_GRADE_TYPE } from 'modules/Grader/constants';
import { isNumber } from 'modules/MyCourses/utils';
import { ordinal } from 'modules/MyTasks/utils';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

function FinalGrade(props) {
	const {
		finalGrade,
		totalPoint,
		overallGradeType,
		gradingAttemptsMethod,
		attemptIndex,
		isSubmittingGrade,
		updateGrade,
		disableInputGrade,
		switchMode,
		grade,
		isFetchingGraderDetail,
	} = props;
	const { t } = useTranslation();
	const { classes } = useStyles();
	if (isFetchingGraderDetail) {
		return (
			<Box width={'100%'} minHeight={(theme) => theme.spacing(43)}>
				<Skeleton width='100%' height={88} />
			</Box>
		);
	}

	const attemptMethodSelected = GRADE_CALCULATION_ATTEMPT.find(({ value }) => gradingAttemptsMethod === value);
	const attemptMethodName =
		attemptMethodSelected?.value === GRADE_CALCULATION_ATTEMPT_TYPE.AVERAGE_OF_ALL_ATTEMPTS ||
		(attemptIndex !== 0 && !attemptIndex)
			? attemptMethodSelected?.label
			: `${attemptMethodSelected?.label}: `;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }} gap={'16px'}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant='titleMedium'>
					{t('final_grade_on_total_point', {
						ns: 'grader',
						grade: isNumber(finalGrade) ? finalGrade : '--',
						totalPoint,
					})}
				</Typography>

				{overallGradeType === OVERRIDE_GRADE_TYPE.MANUAL_GRADE && (
					<Typography variant='bodyMediumRegular' className={classes.textItalic}>
						{t('overridden', { ns: 'grader' })}
					</Typography>
				)}
			</Box>

			<Divider width='100%' sx={{ border: '1px solid #E9ECEF' }} />

			{overallGradeType === OVERRIDE_GRADE_TYPE.AUTOMATED_GRADE && (
				<Box>
					<Typography variant='bodyMediumRegular'>
						{t('automated_final_grade_calculation', {
							ns: 'grader',
						})}
					</Typography>

					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[500] }}>
							{attemptMethodName}
						</Typography>

						{attemptMethodSelected?.value !== GRADE_CALCULATION_ATTEMPT_TYPE.AVERAGE_OF_ALL_ATTEMPTS &&
							(attemptIndex || attemptIndex === 0) && (
								<Typography variant='titleSmall' paddingLeft={'8px'}>{` ${ordinal(
									Number(attemptIndex),
								)} attempt`}</Typography>
							)}
					</Box>
					<Box height={'16px'} />
					<Box onClick={() => switchMode(OVERRIDE_GRADE_TYPE.MANUAL_GRADE)}>
						<Typography variant='bodyMediumRegular' className={classes.textButton}>
							{t('switch_to_override_mode', { ns: 'grader' })}
						</Typography>
					</Box>
				</Box>
			)}
			{overallGradeType === OVERRIDE_GRADE_TYPE.MANUAL_GRADE && (
				<Box>
					<Typography variant='titleSmall'>{t('grader:grade')}</Typography>

					<Box className={classes.grading}>
						<GradeInput
							sx={{ width: (theme) => theme.spacing(10) }}
							totalPoint={totalPoint}
							onBlur={updateGrade}
							disable={disableInputGrade}
							grade={grade}
						/>
						<Typography component='div' mt={0.5} variant='titleSmall'>
							{t('out_of_total_point', { ns: 'grader', totalPoint })}
						</Typography>
						<GradingStatusSaved isSubmittingGrade={isSubmittingGrade} />
					</Box>
					<Box height={'20px'} />
					<Box onClick={() => switchMode(OVERRIDE_GRADE_TYPE.AUTOMATED_GRADE)}>
						<Typography variant='bodyMediumRegular' className={classes.textButton}>
							{t('switch_to_automated_mode', { ns: 'grader' })}
						</Typography>
					</Box>
					<Typography variant='bodyMediumRegular' sx={{ color: (theme) => theme.newColors.gray[500] }}>
						{`${attemptMethodSelected?.label} `}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

FinalGrade.propTypes = {
	finalGrade: PropTypes.number,
	totalPoint: PropTypes.number,
	overallGradeType: PropTypes.number,
	gradingAttemptsMethod: PropTypes.number,
	attemptIndex: PropTypes.number,
	isSubmittingGrade: PropTypes.bool,
	disableInputGrade: PropTypes.bool,
	updateGrade: PropTypes.func,
	onChangeGrade: PropTypes.func,
	switchMode: PropTypes.func,
};

export default React.memo(FinalGrade);
