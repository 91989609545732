/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TblGoogleView from 'components/TblGoogleView';
import WithFullScreen from 'components/TblGoogleView/WithFullScreen';

import { USE_TEMPLATE_STATE } from 'utils/constants';
import useDidMountEffect from 'utils/customHook/useDidMoutEffect';

import googleActions from 'shared/Google/actions';
import { MEDIA_TYPES } from 'shared/Media/constants';

import { useAuthDataContext } from 'AppRoute/AuthProvider';
import { CardAttachment, Typography } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { GOOGLE_ACTION, GOOGLE_FILE_TYPE_SUPPORT_VIEW } from '../../shared/Google/constants';

const useStyles = makeStyles()((theme, props) => ({
	root: {
		width: '100%',
		minHeight: theme.spacing(6),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(1),
		boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.07)',
		'&:last-child': {
			boxShadow: 'none',
		},
	},
	name: {
		color: theme.newColors.gray[900],
	},
	cardGoogle: {
		'& div > img': {
			width: theme.spacing(2),
			height: theme.spacing(2),
			margin: `${theme.spacing(0, 0.375)} !important`,
		},
	},
	btnGroup: {
		display: 'flex',
	},
	useTemplateGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: theme.spacing(3.25),
	},
	useTemplateBtn: {},
	useTemplateTxt: {
		color: props.isTemplate ? theme.newColors.gray[900] : theme.newColors.gray[600],
	},
	removeBtn: {
		color: `${theme.newColors.gray[500]}!important`,
	},
	fileRow: {
		width: '100%',
		height: theme.spacing(7.5),
		display: 'flex',
		flexDirection: 'row',
		cursor: 'pointer',
		marginBottom: theme.spacing(2),
		border: `${theme.spacing(0.125)} solid ${theme.newColors.gray[200]}`,
		borderRadius: theme.spacing(1),
	},
	fileName: {
		maxWidth: 'calc(100% - 160px)',
		height: '100%',
		color: theme.newColors.gray[800],
		fontSize: theme.fontSize['text-sm'],
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		marginLeft: theme.spacing(2),
		'& .MuiTypography-bodySmall': {
			color: theme.newColors.gray[400],
		},
	},
	fileIcon: {
		width: theme.spacing(10),
		height: theme.spacing(7.5),
		color: theme.mainColors.primary1[0],
		fontSize: theme.fontSizeIcon.normal,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRight: `${theme.spacing(0.125)} solid ${theme.newColors.gray[200]}`,
	},
	actionIcon: {
		marginLeft: 'auto',
		marginRight: theme.spacing(2),
	},
}));
function TblGoogleFile(props) {
	const {
		file,
		onUseTemplate,
		type,
		onChangeChooseTemplate,
		disabled,
		onRemove,
		isRenderContextMenu,
		isStudentOrGuardian,
		submissionRequired,
		archiveMode,
		onSelectFile,
	} = props;
	const { id, sourceId, icon, url, isTemplate, name, filename, originalName } = file;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { classes } = useStyles(props);
	const authContext = useAuthDataContext();
	const canBeTemplate = !!onChangeChooseTemplate && GOOGLE_FILE_TYPE_SUPPORT_VIEW.includes(icon);

	const googleFile = useSelector((state) => state.Google.googleFile);
	const onAction = useSelector((state) => state.Google.onAction);
	const googleOauthUrlSuccess = useSelector((state) => state.Google.googleOauthUrlSuccess);
	const getGoogleTokenSuccess = useSelector((state) => state.Google.getGoogleTokenSuccess);
	const getGoogleFileSuccess = useSelector((state) => state.Google.getGoogleFileSuccess);
	const currentUser = useSelector((state) => state.Auth.currentUser);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		//Listen from refresh the access token
		if ((getGoogleTokenSuccess || googleOauthUrlSuccess || getGoogleFileSuccess) && sourceId === googleFile?.id) {
			if (onAction === GOOGLE_ACTION.VIEW) {
				window.open(url);
			} else if (onAction === GOOGLE_ACTION.USE_AS_TEMPLATE) {
				setOpen(true);
			}
		}
		return () => {
			dispatch(
				googleActions.googleSetState({
					googleOauthUrlSuccess: null,
					getGoogleOauthUrlSuccess: null,
					getGoogleOauthUrlFailed: null,
					getGoogleTokenSuccess: null,
					getGoogleFileSuccess: null,
				}),
			);
		};
	}, [googleOauthUrlSuccess, getGoogleTokenSuccess, onAction, getGoogleFileSuccess]);

	const isPublicFile = useCallback(
		(fileId) => {
			dispatch(
				googleActions.getGoogleFileWithoutPermission({
					fileId,
					onAction: GOOGLE_ACTION.VIEW,
					messageFailed: t('google:oauth_google_message'),
				}),
			);
		},
		[file.id, file.sourceId],
	);

	const openFile = useCallback(
		(id) => {
			if (isStudentOrGuardian) {
				isPublicFile(id);
			} else {
				window.open(url);
			}
		},
		[file.id, file.sourceId],
	);

	const onUseFileAsTemplate = (sourceId) => {
		dispatch(
			googleActions.getGoogleToken({
				messageOauthPopup: t('google:oauth_google_message'),
				onAction: GOOGLE_ACTION.USE_AS_TEMPLATE,
				googleFile: {
					id: sourceId,
				},
			}),
		);
	};

	const onClose = () => {
		setOpen(false);
		dispatch(
			googleActions.googleSetState({
				getGoogleFileSuccess: null,
				getGoogleFileFailed: null,
				googleOauthUrlSuccess: null,
				onAction: null,
				openOauthPopup: false,
				messageOauthPopup: '',
			}),
		);
	};

	useDidMountEffect(() => {
		if ((getGoogleTokenSuccess || googleOauthUrlSuccess) && sourceId === googleFile?.id) {
			switch (onAction) {
				case GOOGLE_ACTION.USE_AS_TEMPLATE:
					onUseTemplate(id);
					dispatch(
						googleActions.googleSetState({
							onAction: null,
						}),
					);
					break;
				default:
					break;
			}
		}
		return () => {};
	}, [getGoogleTokenSuccess, googleFile, onAction, googleOauthUrlSuccess]);

	let switchProps = !disabled &&
		canBeTemplate &&
		!archiveMode &&
		type !== ACTIVITY_CATEGORY.LESSON && {
			label: t('use_as_template', { ns: 'google' }),
			checked: isTemplate,
			isButton: false,
			onChange: () => onChangeChooseTemplate(sourceId),
		}; // teacher

	// student and guardian
	if (disabled && isStudentOrGuardian && isTemplate && authContext?.onUseTemplate !== USE_TEMPLATE_STATE.HIDDEN) {
		switchProps = {
			loading: true,
			label: t('use_template', { ns: 'google' }),
			isButton: true,
			isButtonDisabled: !authContext?.onUseTemplate || authContext?.onUseTemplate === USE_TEMPLATE_STATE.DISABLE,
			onChange: () => onUseFileAsTemplate(sourceId),
		};

		if (!submissionRequired && archiveMode) {
			switchProps = null;
		}
	}

	return (
		<>
			<CardAttachment
				className={classes.cardGoogle}
				src={icon}
				onClick={() => {
					if (onSelectFile) {
						return onSelectFile({ ...file, mediaType: MEDIA_TYPES.GOOGLE_DRIVE_FILE });
					}
					openFile(sourceId);
				}}
				title={name || originalName || filename}
				subTitle={t('google_docs', { ns: 'google' })}
				switchProps={switchProps}
				onRemove={!disabled && !archiveMode ? () => onRemove(sourceId) : null}
				contextMenuProps={
					isRenderContextMenu && {
						items: [
							{
								label: <Typography variant='bodyMediumRegular'>{t('view', { ns: 'common' })}</Typography>,
								onClick: openFile,
							},
						],
					}
				}
			/>
			{/* currently this model just use for useTemplate */}
			<WithFullScreen open={open} onClose={onClose}>
				<TblGoogleView />
			</WithFullScreen>
		</>
	);
}

TblGoogleFile.propTypes = {
	file: PropTypes.shape({
		icon: PropTypes.any,
		id: PropTypes.any,
		isTemplate: PropTypes.any,
		mineType: PropTypes.any,
		name: PropTypes.any,
		sourceId: PropTypes.any,
		url: PropTypes.any,
	}),
	hasPermission: PropTypes.bool,
	onChangeChooseTemplate: PropTypes.func,
	onOpenFullScreen: PropTypes.bool,
	isRenderContextMenu: PropTypes.bool,
	isStudentOrGuardian: PropTypes.bool,
	submissionRequired: PropTypes.bool,
	onRemove: PropTypes.func,
	onUseTemplate: PropTypes.func,
	archiveMode: PropTypes.bool,
};
TblGoogleFile.defaultProps = {
	hasPermission: true,
	onOpenFullScreen: true,
	isRenderContextMenu: false,
	isStudentOrGuardian: false,
	submissionRequired: false,
	archiveMode: false,
};

function TblGoogleFilePropsAreEqual(preFile, nextFile) {
	return (
		preFile.sourceId === nextFile.sourceId &&
		preFile.isTemplate === nextFile.isTemplate &&
		preFile.useTemplateLoading === nextFile.useTemplateLoading
	);
}
const TblGoogleFileMemo = React.memo(TblGoogleFile, TblGoogleFilePropsAreEqual);
export { TblGoogleFileMemo };
export default TblGoogleFile;
// export default TblGoogleFileMemo;
