import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

const compareArrays = (a, b) =>
	a?.length &&
	b?.length &&
	a.length === b.length &&
	a.every((element, index) => JSON.stringify(element) === JSON.stringify(b[index]));

export const deepCompareObject =
	(omitProps = [], compareArrWithIndex = []) =>
	(object1, object2) => {
		if ((!object1 || !object2) && [typeof object1, typeof object2].includes('object')) return true;
		const generalEqual = isEqual(omit(object1, omitProps), omit(object2, omitProps));

		if (compareArrWithIndex?.length) {
			let _object1 = pick(object1, compareArrWithIndex);
			let _object2 = pick(object2, compareArrWithIndex);

			if ((!_object1 || !_object2) && [typeof _object1, typeof _object2].includes('object')) return true;
			_object1 = Object.values(_object1);
			_object2 = Object.values(_object2);

			return compareArrays(_object1, _object2) && generalEqual;
		}

		return generalEqual;
	};
