import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import gradeReportActions from './actions';
import { END_POINT, actions } from './constants';

const getGradeReportEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADE_REPORT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_grade_report.method,
				END_POINT.get_grade_report.url(action.payload.courseId, action.payload.termId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						gradeReportActions.getGradeReportSuccess({
							gradeReport: data.response.data,
							fetchingGradeReport: false,
						}),
					),
				),
				catchError((error) =>
					of(
						gradeReportActions.getGradeReportFailed({
							error: error?.response?.errors,
							fetchingGradeReport: false,
						}),
					),
				),
			),
		),
	);

const getCourseListByTermEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COURSE_LIST_BY_TERM),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_courses_grade_report.method,
				END_POINT.get_courses_grade_report.url(action.payload.orgId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						gradeReportActions.getCourseListByTermSuccess({
							listCoursesByTerm: data.response.listCoursesByTerm,
							fetchingCourseListByTermSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						gradeReportActions.getCourseListByTermFailed({
							error: error?.response?.errors,
							fetchingCourseListByTerm: false,
							fetchingCourseListByTermSuccess: false,
						}),
					),
				),
			),
		),
	);

const getGradeDetailsActivitiesEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADE_DETAILS_ACTIVITIES),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_courses_grade_report_detail.method,
				END_POINT.get_courses_grade_report_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						gradeReportActions.getGradeDetailsActivitiesSuccess({
							gradeDetails: data.response.arrActivities,
							fetchingGradeDetailsActivities: false,
						}),
					),
				),
				catchError((error) =>
					of(
						gradeReportActions.getGradeDetailsActivitiesFailed({
							error: error?.response?.errors,
							fetchingGradeDetailsActivities: false,
						}),
					),
				),
			),
		),
	);

const getCourseInfoGradeReportEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_COURSE_INFO_GRADE_REPORT),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_courses_grade_report_info.method,
				END_POINT.get_courses_grade_report_info.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap((data) =>
					of(
						gradeReportActions.getCourseInfoGradeReportSuccess({
							courseInfo: data.response.courseInfo,
							selectedCourseId: action.payload.courseId,
							fetchingCourseInfoGradeReport: false,
						}),
					),
				),
				catchError((error) =>
					of(
						gradeReportActions.getCourseInfoGradeReportFailed({
							error: error?.response?.errors,
							fetchingCourseInfoGradeReport: false,
						}),
					),
				),
			),
		),
	);

const getGradeReportDetailsEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADE_REPORT_DETAILS),
		concatMap((action) =>
			concat(
				of(
					gradeReportActions.getCourseInfoGradeReport({
						orgId: action.payload.orgId,
						courseId: action.payload.courseId,
						fetchingCourseInfoGradeReport: true,
						urlParams: action.payload.urlParams,
					}),
				),
				of(
					gradeReportActions.getGradeDetailsActivities({
						orgId: action.payload.orgId,
						courseId: action.payload.courseId,
						urlParams: action.payload.urlParams,
						fetchingGradeDetailsActivities: true,
					}),
				),
			).pipe(
				catchError((error) =>
					of(
						gradeReportActions.getGradeReportDetailsFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
const getGradeReportAssignmentDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADE_REPORT_ASSIGNMENT_DETAIL),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_grade_report_assignment_detail.method,
				END_POINT.get_grade_report_assignment_detail.url(action.payload.shadowId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						gradeReportActions.getGradeReportAssignmentDetailSuccess({
							gradeReportAssignmentDetail: data.response.data,
						}),
					),
				),
				catchError((error) =>
					of(
						gradeReportActions.getGradeReportAssignmentDetailFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);

const getGradeReportQuizDetailEpic = (action$) =>
	action$.pipe(
		ofType(actions.GET_GRADE_REPORT_QUIZ_DETAIL),
		mergeMap((action) =>
			makeAjaxRequest(
				END_POINT.get_grade_report_quiz_detail.method,
				END_POINT.get_grade_report_quiz_detail.url(action.payload.shadowId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						gradeReportActions.getGradeReportQuizDetailSuccess({
							gradeReportQuizDetail: data.response.data,
						}),
					),
				),
				catchError((error) =>
					of(
						gradeReportActions.getGradeReportQuizDetailSuccessFailed({
							error: error?.response?.errors,
						}),
					),
				),
			),
		),
	);
export default [
	getGradeReportEpic,
	getCourseListByTermEpic,
	getGradeReportAssignmentDetailEpic,
	getGradeReportQuizDetailEpic,
	getGradeDetailsActivitiesEpic,
	getCourseInfoGradeReportEpic,
	getGradeReportDetailsEpic,
];
