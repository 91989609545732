import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModal, Box, Button } from 'ella-storybook';
import PropTypes from 'prop-types';

const LeavePageModal = ({ openWarningLoadingProcess, onCancelWarningDialog, onConfirmLeavePageDialog }) => {
	const { t } = useTranslation();

	return (
		<BaseModal
			size={'small'}
			open={openWarningLoadingProcess}
			title={t('leave_page', { ns: 'myTodos' })}
			onClose={() => onCancelWarningDialog()}
			customFooter={
				<Box className='customFooterModal'>
					<Button variant='outlined' color='primary' onClick={() => onCancelWarningDialog()}>
						{t('cancel', { ns: 'common' })}
					</Button>
					<Button variant='contained' color={'primary'} onClick={() => onConfirmLeavePageDialog()}>
						{t('leave', { ns: 'common' })}
					</Button>
				</Box>
			}
		/>
	);
};

LeavePageModal.propTypes = {
	openWarningLoadingProcess: PropTypes.bool,
	onCancelWarningDialog: PropTypes.func,
	onConfirmLeavePageDialog: PropTypes.func,
};

LeavePageModal.defaultProps = {};

export default React.memo(LeavePageModal);
