import loadable from 'utils/loadable';

import MobileDownloadScreen from '../../AppRoute/MobileDownloadScreen';

import { ROUTE_AUTH } from './constantsRoute';

const SetUpCourse = loadable(() => import(/* webpackChunkName: "LazySetUpCourse" */ './containers/SetUpCourse'));

const Login = loadable(() => import(/* webpackChunkName: "LazyLogin" */ './containers/LoginV2'));
const Logout = loadable(() => import(/* webpackChunkName: "LazyLogout" */ './containers/LogoutV2'));
// const SSO = loadable(() => import('./containers/SSO'));
const ForgotPassword = loadable(() =>
	import(/* webpackChunkName: "LazyForgotPassword" */ './containers/ForgotPasswordV2'),
);
const SetUpPassword = loadable(() =>
	import(/* webpackChunkName: "LazySetUpPassword" */ './containers/SetUpPasswordV2'),
);
const AcceptInvitation = loadable(() =>
	import(/* webpackChunkName: "LazyAcceptInvitation" */ './containers/AcceptInvitation'),
);
const SetupDomain = loadable(() => import(/* webpackChunkName: "LazySetupDomain" */ './containers/SetupDomain'));
const ConfirmEmail = loadable(() => import(/* webpackChunkName: "LazyConfirmEmail" */ './containers/ConfirmEmail'));

export default [
	{
		path: ROUTE_AUTH.DEFAULT,
		component: Login,
		exact: true,
	},
	{
		path: ROUTE_AUTH.LOGIN,
		component: Login,
	},
	// {
	//   path: ROUTE_AUTH.SETUP_GOOGLE,
	//   component: ConnectGoogle,
	// },
	// {
	//     path: '/sso/:token',
	//     component: SSO,
	//     exact: true
	// },
	{
		path: ROUTE_AUTH.LOGOUT,
		component: Logout,
		private: true,
		exact: true,
		userMenu: {
			title: 'Log out',
			order: 9,
		},
	},
	{
		path: ROUTE_AUTH.FORGOT_PASSWORD,
		component: ForgotPassword,
	},
	{
		path: ROUTE_AUTH.SET_PASSWORD(':token'),
		component: SetUpPassword,
		exact: true,
	},
	{
		path: ROUTE_AUTH.SET_UP_DOMAIN(':token'),
		component: SetupDomain,
		exact: true,
	},
	{
		path: ROUTE_AUTH.SET_UP_ACCOUNT(':token'),
		component: AcceptInvitation,
		exact: true,
	},
	{
		path: ROUTE_AUTH.CONFIRM_EMAIL(':token'),
		component: ConfirmEmail,
		exact: true,
	},
	{
		path: ROUTE_AUTH.SET_UP_COURSE,
		component: SetUpCourse,
		exact: true,
	},
	{
		path: ROUTE_AUTH.MOBILE_BROWSER,
		component: MobileDownloadScreen,
		exact: true,
	},
];
