import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';

import { ReactComponent as Image } from 'assets/images/no_permission.svg';
import { Box, Button, Grid, Typography } from 'ella-storybook';

export default function CoursePermissionDenied({onBack}) {
	const theme = useTheme();
	const { t } = useTranslation(['myCourses']);
	return (
		<Grid container alignItems='center' alignSelf={'center'} height={`calc(100vh - ${theme.navbar.appBar}px)`}>
			<Grid lg={12} md={12} item>
				<Box display={'flex'} flexDirection={'column'} gap={4}>
					<Box display='flex' alignItems='center' alignContent='center' justifyContent='center'>
						<Image />
					</Box>
					<Box display='flex' alignItems='center' flexDirection={'column'} gap={1}>
						<Typography variant='titleMedium' color={theme.newColors.gray[600]}>
							{t('no_permission')}
						</Typography>
						<Box display={'flex'} flexDirection={'column'}>
							<Typography variant='bodySmallRegular' color={theme.newColors.gray[400]}>
								{t('course_permission_denied_1')}
							</Typography>
							<Typography variant='bodySmallRegular' color={theme.newColors.gray[400]}>
								{t('course_permission_denied_2')}
							</Typography>
						</Box>
					</Box>
					<Box display='flex' justifyContent='center'>
						<Button
							onClick={() => {
								onBack && onBack();
							}}
						>
							{t('back_to_course_page')}
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}
