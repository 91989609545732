export default {
	GET_UNITS_GROUP_BY_GRADING_PERIOD: 'GET_UNITS_GROUP_BY_GRADING_PERIOD',
	GET_UNITS_GROUP_BY_GRADING_PERIOD_SUCCESS: 'GET_UNITS_GROUP_BY_GRADING_PERIOD_SUCCESS',
	GET_UNITS_GROUP_BY_GRADING_PERIOD_FAILED: 'GET_UNITS_GROUP_BY_GRADING_PERIOD_FAILED',

	GET_ACTIVITIES_BY_UNIT_ID: 'GET_ACTIVITIES_BY_UNIT_ID',
	GET_ACTIVITIES_BY_UNIT_ID_SUCCESS: 'GET_ACTIVITIES_BY_UNIT_ID_SUCCESS',
	GET_ACTIVITIES_BY_UNIT_ID_FAILED: 'GET_ACTIVITIES_BY_UNIT_ID_FAILED',

	GET_COURSES_NEED_RESCHEDULE: 'GET_COURSES_NEED_RESCHEDULE',
	GET_COURSES_NEED_RESCHEDULE_SUCCESS: 'GET_COURSES_NEED_RESCHEDULE_SUCCESS',
	GET_COURSES_NEED_RESCHEDULE_FAILED: 'GET_COURSES_NEED_RESCHEDULE_FAILED',

	GET_ACTIVITIES_RESCHEDULE_COURSE: 'GET_ACTIVITIES_RESCHEDULE_COURSE',
	GET_ACTIVITIES_RESCHEDULE_COURSE_SUCCESS: 'GET_ACTIVITIES_RESCHEDULE_COURSE_SUCCESS',
	GET_ACTIVITIES_RESCHEDULE_COURSE_FAILED: 'GET_ACTIVITIES_RESCHEDULE_COURSE_FAILED',

	GET_SECTIONS_RESCHEDULE_ACTIVITY: 'GET_SECTIONS_RESCHEDULE_ACTIVITY',
	GET_SECTIONS_RESCHEDULE_ACTIVITY_SUCCESS: 'GET_SECTIONS_RESCHEDULE_ACTIVITY_SUCCESS',
	GET_SECTIONS_RESCHEDULE_ACTIVITY_FAILED: 'GET_SECTIONS_RESCHEDULE_ACTIVITY_FAILED',

	GET_SESSIONS_RESCHEDULE_ACTIVITY: 'GET_SESSIONS_RESCHEDULE_ACTIVITY',
	GET_SESSIONS_RESCHEDULE_ACTIVITY_SUCCESS: 'GET_SESSIONS_RESCHEDULE_ACTIVITY_SUCCESS',
	GET_SESSIONS_RESCHEDULE_ACTIVITY_FAILED: 'GET_SESSIONS_RESCHEDULE_ACTIVITY_FAILED',
};
