import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import CircularProgress from '@mui/material/CircularProgress';

import ErrorPage from 'components/TblErrorPage';

import { isGuardian, isStudent } from 'utils/roles';

import { ROUTE_AUTH } from 'shared/Auth/constantsRoute';
import { USER_BEHAVIOR } from 'shared/User/constants';

import { get, pick } from 'lodash';
import OnBoardingProfile from 'modules/MyProfile/containers/OnBoardingProfileV2';
import PropTypes from 'prop-types';
import { checkPermission } from 'utils';

import { detectIpad, detectMobAndroid, detectMobIOS } from '../utils/detectMobileBrowsing';

import { useAuthDataContext } from './AuthProvider';
import { checkAuthorized } from './rolePermission/utils';

const checkWindowScreen = window.innerWidth > 1024 || window.innerHeight > 1024;
const PrivateRoute = ({ component, routerRoles, ...rest }) => {
	const { currentUser, fetchingUser, token } = useAuthDataContext();
	const { moduleAlias, permissions } = pick(rest, ['moduleAlias', 'permissions']);
	const strPermissions = get(currentUser, 'permissions');

	if (fetchingUser && token) {
		return <CircularProgress color='primary' />;
	}
	if (isEmpty(currentUser)) {
		const domain = process.env.REACT_APP_BASE_URL;
		const defaultSubdomain = process.env.REACT_APP_DEFAULT_SUBDOMAIN;
		const subdomain = window.location.hostname.split('.').filter((str) => !domain.includes(str));
		const searchParams = new URLSearchParams(window.location.search);
		const forceLogout = searchParams.get('forceLogout');
		const fullUrl = (subdomain) =>
			`${window.location.protocol}//${subdomain}.${domain}:${window.location.port}${ROUTE_AUTH.LOGIN}?logout=true`;

		// let to = fullUrl(forceLogout ? subdomain[0] : defaultSubdomain);
		let to = fullUrl(subdomain[0]);

		if (rest.to) {
			to = rest.to;
		}

		window.location.replace(to);
		return <></>;
		// return <Redirect to={to} />;
	}

	const hasPermission = checkPermission(currentUser, routerRoles);
	// NOTE: hasPermission will be removed when all modules has been revamped
	const isAuthorized = checkAuthorized({
		moduleAlias,
		permissions,
		strPermissions,
	});

	if (moduleAlias && permissions) {
		if (!isAuthorized) {
			return (
				<ErrorPage
					errorCode='403'
					shortDescription='forbidden'
					detailDescription='no_permission'
					isNotFoundPage={false}
					isPublic={true}
				/>
			);
		}
	} else if (!hasPermission) {
		return (
			<ErrorPage
				errorCode='403'
				shortDescription='forbidden'
				detailDescription='no_permission'
				isNotFoundPage={false}
				isPublic={true}
			/>
		);
	}

	if (
		hasPermission &&
		((detectMobAndroid() && !checkWindowScreen) ||
			detectMobIOS() ||
			(detectIpad() && window.location.href.includes('app.tabulalearning')))
	) {
		return <Redirect to={ROUTE_AUTH.MOBILE_BROWSER} />;
	}

	if (
		(isGuardian(currentUser) || isStudent(currentUser)) &&
		!currentUser?.settings?.behavior?.includes(USER_BEHAVIOR.HAVE_SET_UP_PROFILE)
	) {
		return <OnBoardingProfile isOnBoarding={true} />;
	}

	return <Route {...rest} component={component} />;
};

PrivateRoute.propTypes = {
	component: PropTypes.any,
	routerRoles: PropTypes.array,
};
export default PrivateRoute;
