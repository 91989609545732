import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LOCAL_STORAGE } from 'utils/constants';

import googleActions from 'shared/Google/actions';

import PropTypes from 'prop-types';

import { GOOGLE_ACTION } from '../../shared/Google/constants';
import { formatGoogleFile /* getGoogleToken */ } from '../../shared/Google/utils';

import GooglePicker from './GooglePicker';

function TblGooglePicker(props) {
	const { onChange, maxItemCanBeSelected, isOpenGooglePicker, setIsOpenGooglePicker } = props;
	const dispatch = useDispatch();
	const [authGoogle, setAuthGoogle] = useState(false);
	const [accessToken, setAccessToken] = useState(null);
	const { t } = useTranslation();
	const getGoogleOauthUrlSuccess = useSelector((state) => state.Google.getGoogleOauthUrlSuccess);
	const getGoogleOauthUrlFailed = useSelector((state) => state.Google.getGoogleOauthUrlFailed);
	const getGoogleTokenSuccess = useSelector((state) => state.Google.getGoogleTokenSuccess);
	const pickerFn = useSelector((state) => state.Google.pickerFn);

	const onAction = useSelector((state) => state.Google.onAction);

	const googleOauthUrlSuccess = useSelector((state) => state.Google.googleOauthUrlSuccess);

	const createPicker = () => {
		// const google = window.google;
		// // const uploadView = new google.picker.DocsUploadView();
		// const view = new google.picker.DocsView().setOwnedByMe(true);
		// var picker = new google.picker.PickerBuilder()
		//   // .addViewGroup(
		//   //   new google.picker.ViewGroup(google.picker.ViewId.DOCS)
		//   //     .addView(google.picker.ViewId.DOCUMENTS)
		//   //     .addView(google.picker.ViewId.PRESENTATIONS)
		//   // )
		//   // .addView(uploadView)
		//   // .addView(google.picker.ViewId.DOCUMENTS)
		//   // .addView(google.picker.ViewId.PRESENTATIONS)
		//   // .addView(google.picker.ViewId.SPREADSHEETS)
		//   .addView(view)
		//   .setMaxItems(maxItemCanBeSelected <= 0 ? -1 : maxItemCanBeSelected)
		//   .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
		//   .setOAuthToken(localStorage.getItem(LOCAL_STORAGE.GOOGLE_ACCESS_TOKEN)) //access token fetched from server
		//   .setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_API_KEY)
		//   .setCallback((e) => {
		//     dispatch(
		//       googleActions.googleSetState({
		//         getGoogleTokenSuccess: null,
		//         googleOauthUrlSuccess: null,
		//       })
		//     );
		//     setAuthGoogle(false);
		//     if (e.action === 'picked') {
		//       const docs = e.docs.map((doc) => formatGoogleFile(doc));
		//       onChange(docs);
		//     }
		//   })
		//   .build();
		// const callBack = (e) => {
		//   dispatch(
		//     googleActions.googleSetState({
		//       getGoogleTokenSuccess: null,
		//       googleOauthUrlSuccess: null,
		//     })
		//   );
		//   setAuthGoogle(false);
		//   if (e.action === 'picked') {
		//     const docs = e.docs.map((doc) => formatGoogleFile(doc));
		//     onChange(docs);
		//   }
		// };
		if (pickerFn) {
			GooglePicker.init(pickerFn.maxItem, pickerFn.callBack, accessToken);
			GooglePicker.open();
		}
	};

	const openGooglePicker = () => {
		const callBack = (e) => {
			if (e.action === 'cancel') {
				GooglePicker.reset();
			}

			if (e.action === 'loaded') {
				dispatch(
					googleActions.googleSetState({
						getGoogleTokenSuccess: null,
						googleOauthUrlSuccess: null,
						callBack: null,
					}),
				);
				setAuthGoogle(false);
			}
			if (e.action === 'picked') {
				GooglePicker.reset();

				const docs = e.docs.map((doc) => formatGoogleFile(doc));
				onChange(docs);
			}
		};
		const domain = window.location.hostname.split('.');
		return dispatch(
			googleActions.getGoogleToken({
				urlParams: {
					redirectUrl: `${window.location.origin}/google-oauth?subdomain=${domain[0]}`,
				},
				messageOauthPopup: t('google:oauth_google_message'),
				onAction: GOOGLE_ACTION.PICKER,
				pickerFn: { callBack, maxItem: maxItemCanBeSelected },
			}),
		);
	};

	useEffect(() => {
		if (isOpenGooglePicker) {
			openGooglePicker();
			setIsOpenGooglePicker(false);
		}
	}, [isOpenGooglePicker]);

	useEffect(() => {
		setAccessToken((prev) => {
			const _accessToken = localStorage.getItem(LOCAL_STORAGE.GOOGLE_ACCESS_TOKEN);
			if (prev !== _accessToken && ![undefined, null].includes(_accessToken)) {
				return _accessToken;
			}
			return prev;
		});
	});

	useEffect(
		() => () => {
			dispatch(
				googleActions.googleSetState({
					pickerFn: null,
				}),
			);
		},
		[],
	);

	useEffect(() => {
		//Listen from refresh the access token
		if ((getGoogleTokenSuccess || googleOauthUrlSuccess) && onAction === GOOGLE_ACTION.PICKER) {
			setAuthGoogle(true);
		}
		return () => {
			dispatch(
				googleActions.googleSetState({
					googleOauthUrlSuccess: null,
					getGoogleOauthUrlSuccess: null,
					getGoogleOauthUrlFailed: null,
					getGoogleTokenSuccess: null,
				}),
			);
		};
	}, [
		googleOauthUrlSuccess,
		getGoogleOauthUrlSuccess,
		getGoogleOauthUrlFailed,
		getGoogleTokenSuccess,
		dispatch,
		onAction,
	]);
	useEffect(() => {
		const googleAccessToken = localStorage.getItem(LOCAL_STORAGE.GOOGLE_ACCESS_TOKEN);
		if (authGoogle && window.google && accessToken) {
			createPicker();
		}
		return () => {
			GooglePicker.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authGoogle, accessToken]);

	return <></>;
}

TblGooglePicker.propTypes = {
	children: PropTypes.any,
	disabled: PropTypes.bool,
	hasPermission: PropTypes.bool,
	isEmptyList: PropTypes.bool,
	maxItemCanBeSelected: PropTypes.number,
	onChange: PropTypes.func,
};

export default TblGooglePicker;
TblGooglePicker.defaultProps = {
	isEmptyList: true,
	hasPermission: true,
	disabled: false,
};
