import PropTypes from 'prop-types';

import useStyles from './styled';

function Scrollbar({id, onScroll, children}) {
    const {classes} = useStyles();
    return (
        <div
            id={id}
            onScroll={onScroll}
            className={classes.scrollbar}
        >
            {children}
        </div>
    );
}

Scrollbar.defaultProps = {
    children: '',
    onScroll: () => {}
};

Scrollbar.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onScroll: PropTypes.func,
    children: PropTypes.node,
};

export default Scrollbar;