import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { ROUTE_MY_COURSES } from 'modules/MyCourses/constantsRoute';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import { objectToParams } from 'utils';
export const actions = {
	CALENDAR_SET_STATE: 'CALENDAR_SET_STATE',
	RESET_CALENDAR_REDUCER: 'RESET_CALENDAR_REDUCER',

	GET_DAY_HAVE_SESSION: 'GET_DAY_HAVE_SESSION',
	GET_DAY_HAVE_SESSION_SUCCESS: 'GET_DAY_HAVE_SESSION_SUCCESS',
	GET_DAY_HAVE_SESSION_FAILED: 'GET_DAY_HAVE_SESSION_FAILED',

	GET_SESSIONS_IN_PERIOD: 'GET_SESSIONS_IN_PERIOD',
	GET_SESSIONS_IN_PERIOD_SUCCESS: 'GET_SESSIONS_IN_PERIOD_SUCCESS',
	GET_SESSIONS_IN_PERIOD_FAILED: 'GET_SESSIONS_IN_PERIOD_FAILED',

	GET_EVENT_DETAIL: 'GET_EVENT_DETAIL',
	GET_EVENT_DETAIL_SUCCESS: 'GET_EVENT_DETAIL_SUCCESS',
	GET_EVENT_DETAIL_FAILED: 'GET_EVENT_DETAIL_FAILED',

	GET_EVENT_ACTIVITIES: 'GET_EVENT_ACTIVITIES',
	GET_EVENT_ACTIVITIES_SUCCESS: 'GET_EVENT_ACTIVITIES_SUCCESS',
	GET_EVENT_ACTIVITIES_FAILED: 'GET_EVENT_ACTIVITIES_FAILED',

	GET_EVENT_PERSONAL_NOTE: 'GET_EVENT_PERSONAL_NOTE',
	GET_EVENT_PERSONAL_NOTE_SUCCESS: 'GET_EVENT_PERSONAL_NOTE_SUCCESS',
	GET_EVENT_PERSONAL_NOTE_FAILED: 'GET_EVENT_PERSONAL_NOTE_FAILED',

	UPSERT_EVENT_PERSONAL_NOTE: 'UPSERT_EVENT_PERSONAL_NOTE',
	UPSERT_EVENT_PERSONAL_NOTE_SUCCESS: 'UPSERT_EVENT_PERSONAL_NOTE_SUCCESS',
	UPSERT_EVENT_PERSONAL_NOTE_FAILED: 'UPSERT_EVENT_PERSONAL_NOTE_FAILED',
};

export const END_POINT = {
	get_day_have_session: {
		method: 'GET',
		url: (orgId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/students/me/events/count-each-date?${objectToParams(
				params,
			)}`,
	},
	get_sessions_in_period: {
		method: 'GET',
		url: (orgId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/students/me/events?${objectToParams(params)}`,
	},
	get_event_detail: {
		method: 'GET',
		url: (orgId, sectionScheduleId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/section-schedules/${sectionScheduleId}?${objectToParams(params)}`,
	},
	get_event_activities: {
		method: 'GET',
		url: (orgId, sectionScheduleId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/section-schedules/${sectionScheduleId}/activities?${objectToParams(params)}`,
	},
	get_event_personal_note: {
		method: 'GET',
		url: (orgId, sectionScheduleId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/section-schedules/${sectionScheduleId}/personal-note?${objectToParams(params)}`,
	},
	upsert_event_personal_note: {
		method: 'PUT',
		url: (orgId, sectionScheduleId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/section-schedules/${sectionScheduleId}/personal-note?${objectToParams(params)}`,
	},
};

export const CALENDAR_MODE = { WEEK: 'WEEK', LIST: 'LIST' };

export const ACTIVITY_TIME_STATUS = {
	'DUE-TAKEN': 0,
	'ASSIGNED-ANNOUNCED': 1,
};

export const activityLabel = (translate) => ({
	[ACTIVITY_CATEGORY.LESSON]: translate('common:lesson'),
	[ACTIVITY_CATEGORY.ASSIGNMENT]: translate('common:assignment'),
	[ACTIVITY_CATEGORY.TEST]: translate('common:test'),
});

export const activitySubLabel = (translate) => ({
	[ACTIVITY_CATEGORY.ASSIGNMENT]: [translate('assignment_due'), translate('assignment_assigned')],
	[ACTIVITY_CATEGORY.TEST]: [translate('test_taken'), translate('test_announced')],
});

export const actionMenu = {
	[ACTIVITY_CATEGORY.LESSON]: [
		{
			key: 1,
			label: 'view_details',
			path: ({ courseId, activityId, activityType, unitId }) =>
				ROUTE_MY_COURSES.MY_COURSES_DETAIL_UNIT_ACTIVITY(courseId, activityId, activityType, unitId),
		},
	],
	[ACTIVITY_CATEGORY.ASSIGNMENT]: [
		[
			{
				key: 1,
				label: 'view_details',
				path: ({ courseId, activityId, activityType, unitId }) =>
					ROUTE_MY_COURSES.MY_COURSES_DETAIL_UNIT_ACTIVITY(courseId, activityId, activityType, unitId),
			},
			{ key: 2, label: 'go_to_this_task', path: ({ taskId }) => ROUTE_TASKS.TASK_DETAIL(taskId) },
		],
		[
			{
				key: 1,
				label: 'view_details',
				path: ({ courseId, activityId, activityType, unitId }) =>
					ROUTE_MY_COURSES.MY_COURSES_DETAIL_UNIT_ACTIVITY(courseId, activityId, activityType, unitId),
			},
			{ key: 2, label: 'action_submission', path: ({ taskId }) => ROUTE_TASKS.TASK_DETAIL(taskId) },
		],
	],
	[ACTIVITY_CATEGORY.TEST]: [
		[
			{
				key: 1,
				label: 'view_details',
				path: ({ courseId, activityId, activityType, unitId }) =>
					ROUTE_MY_COURSES.MY_COURSES_DETAIL_UNIT_ACTIVITY(courseId, activityId, activityType, unitId),
			},
			{ key: 2, label: 'view_study_for_test_submission', path: ({ taskId }) => ROUTE_TASKS.TASK_DETAIL(taskId) },
		],
		[
			{
				key: 1,
				label: 'view_details',
				path: ({ courseId, activityId, activityType, unitId }) =>
					ROUTE_MY_COURSES.MY_COURSES_DETAIL_UNIT_ACTIVITY(courseId, activityId, activityType, unitId),
			},
			{ key: 2, label: 'action_study_for_test_submission', path: ({ taskId }) => ROUTE_TASKS.TASK_DETAIL(taskId) },
		],
	],
};
