import createReducers from 'utils/createReducers';

import { actions } from './constants';

export const initialState = {
	isFetchingFile: true,
	importError: [],
	subcodeError: null,
	error: null,

	mcConsolidateLessonSuccess: null,
	mcConsolidateTestSuccess: null,
	mcConsolidateAssignmentSuccess: null,

	warningBannerInfo: null,
};

export default createReducers(initialState, actions, actions.RESET_FILE_PREVIEW);
