import createReducers from 'utils/createReducers';

import { actions } from './constants';

export const initialState = {
	getSchoolYearListSuccess: false,
	schoolYearList: [],
	termsList: [],
	schoolYearActivitiesNotGradeOrRelease: {},
	updateSchoolYearStatusPublishedToArchived: {},

	schoolYearDetail: {},
	schoolYearDetailForModalArchive: {},
	schoolYearValidation: {},
	isUpdatingTemplate: false,
	createSchoolYearSuccess: false,
	createSchoolYearFailed: null,
	isFetchingSchedules: false,
	schedules: {},

	getSchoolYearInformationSuccess: false,
	getSchoolYearInformationFailed: null,

	getAllSchoolYearActivitiesNotGradeOrReleaseSuccess: false,
	getAllSchoolYearActivitiesNotGradeOrReleaseFailed: null,

	updateStatusSchoolYearPublishedToArchiveSuccess: false,
	updateStatusSchoolYearPublishedToArchivedFailed: null,

	updateSchoolYearInformationSuccess: false,
	updateSchoolYearInformationFailed: null,

	updateTermsSuccess: false,

	dailyTemplates: [],
	createTimeSlotSuccess: false,
	createTimeSlotFailed: null,

	updateTimeSlotSuccess: false,
	updateTimeSlotFailed: null,
	isCreateDailyTemplateSuccess: false,
	schoolYearStatus: 0,

	isLoadingCreateDailyTemplate: false,

	getTermsBySchoolYearSuccess: false,
	isPublishedExist: 0,
	schoolYearPublishedName: '',
	validatePublishedSchoolYearSuccess: false,

	currentPublishedSY: {},
	courseListToReview: [],
	courseList: [],
	numberOfCourses: null,
	preReviewAPISuccess: null,

	fetchedSections: {},
	reviewAndRepublishError: false,
	reviewAndRepublish: null,
};

export default createReducers(initialState, actions, actions.SCHOOL_YEAR_RESET);
