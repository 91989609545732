import { useEffect } from 'react';

import { isFalsy } from '../utils';

const useSearch = ({ customizeDueDateSuccess, searchValueInput, setIsSearching, handleActionStudentWork }) => {
	useEffect(() => {
		if (!customizeDueDateSuccess) return;
		handleActionStudentWork(searchValueInput);
	}, [customizeDueDateSuccess, searchValueInput]);

	useEffect(() => {
		handleActionStudentWork(searchValueInput);
		setIsSearching((prev) => !isFalsy(searchValueInput));
	}, [searchValueInput]);
};

export default useSearch;
