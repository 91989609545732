import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
	flexBox: {
		display: 'flex',
		flexDirection: 'column',
	},
	boxItem: {
		padding: theme.spacing(2),
		backgroundColor: 'white',
		border: '1px solid #E9ECEF',
		borderRadius: theme.spacing(2),
		flex: '1 1 auto',
	},
	attemptTitle: {
		color: theme.newColors.gray[800],
		fontWeight: theme.fontWeight.semi,
		fontSize: theme.fontSize['text-xs'],
	},
	grading: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		'& .MuiTypography-root': {
			color: theme.newColors.gray[800],
		},
	},
	finalGrade: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(1),
	},
	rejectBtn: {
		width: '100%',
		marginTop: theme.spacing(2),
		color: theme.newColors.gray[800],
		backgroundColor: theme.newColors.gray[200],
		'&:hover': {
			backgroundColor: theme.newColors.gray[200],
		},
	},
	emptyText: {
		color: theme.newColors.gray[800],
		fontSize: theme.fontSize['text-xs'],
		fontWeight: theme.fontWeight.semi,
	},
	skeleton: {
		width: theme.spacing(36),
		height: theme.spacing(20),
	},
	overallGradingRoot: {
		position: 'relative',
		// margin: 'auto',
		width: theme.spacing(36),
		height: theme.spacing(14.25),
		borderRadius: theme.borderRadius.default,
		overflow: 'hidden',
		// backgroundColor: '#3FC057'
		backgroundColor: props?.overallGrade ? '#3FC057' : '#CED4DB',
		'&::before': {
			position: 'absolute',
			top: '-80px',
			right: '-110px',
			content: '""',
			backgroundColor: props?.overallGrade ? '#8DD99A' : '#E2E5E9',
			borderRadius: '50%',
			width: theme.spacing(19.5),
			height: theme.spacing(19.5),
		},
		'&::after': {
			position: 'absolute',
			bottom: '-80px',
			left: '-100px',
			content: '""',
			backgroundColor: props?.overallGrade ? '#8DD99A' : '#E2E5E9',
			borderRadius: '50%',
			width: theme.spacing(21.5),
			height: theme.spacing(21.5),
		},
	},
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		borderRadius: theme.borderRadius.default,
		overflow: 'hidden',
		'&::before': {
			zIndex: 1,
			position: 'absolute',
			top: '-50%',
			right: '-30%',
			content: '""',
			backgroundColor: props?.overallGrade ? '#B9E8C2' : '#EDF0F2',
			borderRadius: '50%',
			width: theme.spacing(13.5),
			height: theme.spacing(13.5),
		},
		'&::after': {
			zIndex: 1,
			position: 'absolute',
			bottom: '-50%',
			left: '-30%',
			content: '""',
			backgroundColor: props?.overallGrade ? '#B9E8C2' : '#EDF0F2',
			borderRadius: '50%',
			width: theme.spacing(15.5),
			height: theme.spacing(15.5),
		},
	},
	content: {
		width: '100%',
		height: '100%',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		textAlign: 'center',
		color: 'white',
	},
	overallGrade: {
		color: 'white',
		fontSize: theme.fontSize['text-3xl'],
		fontWeight: theme.fontWeight.semi,
	},
	hepper: {
		display: 'flex',
		color: 'white',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: theme.fontSize['text-xs'],
		fontWeight: theme.fontWeight.normal,
		'& .MuiSvgIcon-root': {
			marginLeft: theme.spacing(0.6),
			fontSize: theme.spacing(1.8),
		},
	},
	iconBtn: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		backgroundColor: 'white',
		zIndex: 1,
		color: props?.overallGrade ? '#3FC057' : '#CED4DB',
		'&:hover:': {
			backgroundColor: 'white',
		},
	},
	grading: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: theme.spacing(1),
		'& .MuiFormControl-root': {
			width: theme.spacing(13),
			height: theme.spacing(5),
			marginRight: theme.spacing(1),
			'& .MuiInputBase-root': {
				paddingRight: 0,
			},
		},
		'& span': {
			color: theme.newColors.gray[800],
			fontWeight: theme.fontWeight.semi,
			fontSize: theme.fontSize['text-sm'],
			marginBottom: theme.spacing(1),
		},
	},
	studentInfoRoot: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '56px',
		width: '100%',
	},
	userInfo: {
		width: '85%',
		// maxWidth: theme.spacing(90),
		'&:hover': {
			cursor: 'pointer',
		},
	},
	groupBtn: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'& .MuiIconButton-root': {
			padding: 0,
		},
	},
	textButton: {
		fontWeight: theme.fontWeight.medium,
		color: theme.newColors.primary[500],
		cursor: 'pointer',
	},
	textItalic: {
		color: theme.newColors.gray[500],
		fontStyle: 'italic',
	},
}));

export { useStyles };
