import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';

import PropTypes from 'prop-types';

import CheckBox from './CheckBox';
import TypographyCheckBox from './TypographyCheckBox';

const StoryBookCheckBox = ({ label, indeterminate, onChange, typographyVariant = 'bodySmallRegular', ...rest }) => (
	<FormControlLabel
		control={<CheckBox indeterminate={indeterminate} />}
		label={<TypographyCheckBox variant={typographyVariant}>{label}</TypographyCheckBox>}
		onChange={(e) => !!onChange && onChange(e.target.checked)}
		{...rest}
	/>
);

StoryBookCheckBox.defaultProps = {
	spacing: true,
};

StoryBookCheckBox.propTypes = {
	label: PropTypes.object,
	classes: PropTypes.object,
	containerClass: PropTypes.object,
	spacing: PropTypes.bool,
	onChange: PropTypes.func,
};

export default StoryBookCheckBox;
