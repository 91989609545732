import { camelCase } from 'lodash';
import { handleActions } from 'redux-actions';

const reducer = (initStates, constants, resetConstant = '') => {
	const keys = Object.keys(constants);
	const reducers = {};
	for (let i = 0; i < keys.length; i++) {
		const actionType = keys[i];

		const isAsyncAction = keys.includes(`${actionType}_SUCCESS`) || keys.includes(`${actionType}_FAILED`);

		const isEndAsyncAction = actionType.endsWith('_SUCCESS') || actionType.endsWith('_FAILED');

		const loadingForAction = camelCase(
			isEndAsyncAction ? actionType.replace(/_SUCCESS$|_FAILED$/, '_LOADING') : `${actionType}_LOADING`,
		);

		const successAction = camelCase(actionType.endsWith('_SUCCESS') ? actionType : `${actionType}_SUCCESS`);

		const loadingStateReducer = {
			...(isAsyncAction && { [loadingForAction]: true, [successAction]: false }),
			...(isEndAsyncAction && { [loadingForAction]: false, [successAction]: actionType.endsWith('_SUCCESS') }),
		};

		if (keys[i] === resetConstant) {
			reducers[keys[i]] = () => ({
				...initStates,
				...loadingStateReducer,
			});
		} else {
			reducers[keys[i]] = (state, action) => {
				let payload = action.payload;
				if (typeof payload === 'function') {
					payload = payload(state);
				}
				return {
					...state,
					...payload,
					...loadingStateReducer,
				};
			};
		}
	}

	return handleActions(reducers, initStates);
};

export default reducer;
