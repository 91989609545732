import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	rootAvatar: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		background: theme.newColors.gray[100],
		color: theme.mainColors.gray[6],
		// fontSize: theme.fontSize['text-sm'],
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		...theme.typography.bodyLargeBold,
	},
	studentInfoBtn: {
		display: 'flex',
		alignItems: 'center',
		flex: '0 1 auto',
		gap: theme.spacing(1),
		padding: `${theme.spacing(1)}`,
		borderRadius: theme.spacing(1),
		div: {
			fontWeight: '400',
		},
		'&:hover': {
			backgroundColor: theme.newColors.gray[100],
			cursor: 'pointer',
		},
	},
	chosen: {
		backgroundColor: theme.newColors.primary[50],
		div: {
			fontWeight: 'bold !important',
		},
	},
	studentList: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(0.5),
		// overflow: 'auto',
		// maxHeight: 'calc( 100vh - 457px )',
	},
}));

export { useStyles };
