import React from 'react';

import TblIcon from 'components/TblIcon';

import { Box, Tooltip, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import theme from 'theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	indicatorSetting: {
		marginLeft: theme.spacing(1.25),
		marginTop: theme.spacing(0.25),
		height: theme.spacing(2.5),
	},
}));

function TooltipSubmissionAttempts({ tooltip, label }) {
	const { classes } = useStyles();

	return (
		<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
			<Typography sx={{ mr: theme.spacing(1), fontSize: '16px' }}>{label}</Typography>
			<Tooltip title={tooltip} placement='top'>
				<Box className={classes.indicatorSetting}>
					<TblIcon icon='info' fontSize={theme.fontSizeIcon.small} />
				</Box>
			</Tooltip>
		</Box>
	);
}

TooltipSubmissionAttempts.propTypes = {
	title: PropTypes.node,
	label: PropTypes.string,
};

export default TooltipSubmissionAttempts;
