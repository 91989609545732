import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	media: [],
	fetching: false,
	errors: null,
	deletingFiles: {},
	mediaList: [],
	selectedMediaId: undefined,
	uploadingError: false,
};

export default createReducers(initialState, actions);
