import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { Routing } from 'utils/middlewareRoute';
import { isGuardian } from 'utils/roles';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

// import { ReactComponent as IcnMenu } from 'assets/images/icn_myTasks.svg';
import { ReactComponent as IcnMenu } from 'assets/images/icn_myTodos.svg';

import { EmptyStudentPage } from './components/ErrorPage';
import { ROUTE_TASKS } from './constantsRoute';
import reducer from './reducers';

const ViewMyTask = loadable(() => import(/* webpackChunkName: "LazyViewMyTask" */ './containers'));
const TaskDetail = loadable(() => import(/* webpackChunkName: "LazyTaskDetail" */ './containers/TaskDetail'));

export default [
	{
		permissions: [PERMISSIONS.COURSE_ACTIVITY.READ_PUBLIC],
		moduleAlias: MODULE_ALIAS.ACTIVITY,
		path: [
			ROUTE_TASKS.DEFAULT,
			ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(':studentId'), 
			ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT()
		],
		component: withReducer(
			'MyTasks',
			reducer,
		)(
			Routing(
				{
					defaultPath: ROUTE_TASKS.DEFAULT
				},
				{
					pathWithQuery: ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(':studentId'),
					redirectToThis: (studentId) => ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(studentId),
				},
				{
					errorPath: ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT(),
					errorRedirect: () => ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT(),
					ErrorComponent: EmptyStudentPage,
				},
				ViewMyTask
			),
		),
		menu: {
			title: 'Tasks',
			group: 'noname',
			icon: IcnMenu,
			order: 1,
		},
		exact: true,
		private: true,
		getSelectedPath: (context) => {
			if (isGuardian(context?.currentUser)) {
				if (!!context?.currentStudentId) {
					return ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(context.currentStudentId);
				}
				return ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT();
			}
			return ROUTE_TASKS.DEFAULT;
		},
	},
	{
		path: ROUTE_TASKS.TASK_DETAIL(':taskId'),
		component: withReducer('MyTasks', reducer)(TaskDetail),
		private: true,
		exact: true,
		permissions: [PERMISSIONS.COURSE_ACTIVITY.READ_PUBLIC],
		moduleAlias: MODULE_ALIAS.ACTIVITY,
	},
];
