import isEmpty from 'lodash/isEmpty';

import { COURSE_ITEM_TYPE } from 'utils/constants';
import { isGuardian } from 'utils/roles';

import { isUndefined } from 'lodash-es';
import { TASK_STATUS } from 'modules/MyTasks/constants';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import moment from 'moment';
import { objectToParams } from 'utils';

import {
	ACTIVITY_CATEGORY,
	GRADE_CALCULATION_TYPE,
	STATUS_STUDENT_ASSIGNMENT_IN_SUBMISSION_LIST,
	STUDENT_PROGRESS_STATUS,
} from '../constants';

export const getCourseDayId = (string = '') => {
	if (string !== '') {
		return string.replace('masterItem-', '').split('_')?.[0];
	}
	return string;
};

export const checkHasCharacter = (string, regExpType) => {
	var pattern = new RegExp(regExpType);
	return pattern.test(string);
};

export const isMasterItemInPlan = (string) => {
	const pattern = /^masterItem/i;
	return pattern.test(string);
};

export const isAvailableItemInPlan = (string) => {
	const pattern = /^availableList/i;
	return pattern.test(string);
};

export const isAnnouncedQuizItemInPlan = (string) => checkHasCharacter(string, 'Announced');

export const isAssignmentItemInPlan = (string) => {
	const pattern = /^Assignment/i;
	return pattern.test(string);
};

export const isLessonItemInPlan = (string) => {
	// const pattern = /^Lesson/;
	var pattern = new RegExp('Lesson');
	return pattern.test(string);
};

export const getKeyName = (type) => {
	switch (Number(type)) {
		case COURSE_ITEM_TYPE.ASSIGNMENT:
			return 'assignmentId';
		case COURSE_ITEM_TYPE.QUIZ:
			return 'quizId';
		default:
			return 'lessonId';
	}
};

export const getKeyType = (string) =>
	checkHasCharacter(string, 'Lesson')
		? COURSE_ITEM_TYPE.LESSON
		: checkHasCharacter(string, 'Assignment')
		? COURSE_ITEM_TYPE.ASSIGNMENT
		: COURSE_ITEM_TYPE.QUIZ;

export const getCourseItemInfo = (courseItems, sourceId) => {
	const courseItemsKeys = Object.keys(courseItems);
	for (let i = 0; i <= courseItemsKeys.length; i++) {
		const data = courseItems[`${courseItemsKeys[i]}`]?.find((i) => i.id === sourceId)?.data;
		if (data) {
			return data;
		}
	}
	return {};
};
// courseDays.map(i => i.dates?.find(date => date.id === courseDayId));
export const getCourseDayInfo = (courseDays, courseDayId) => {
	if (!!!courseDayId || isEmpty(courseDays)) {
		return;
	}
	for (let i = 0; i <= courseDays.length; i++) {
		// eslint-disable-next-line eqeqeq
		const data = courseDays[i]?.dates?.find((date) => date.id == courseDayId);
		if (data) {
			return data;
		}
	}
	return {};
};

export const getQueueUpdate = (courseDayIds) => {
	const queueUpdate = {};
	if (courseDayIds) {
		courseDayIds.forEach((id) => (queueUpdate[id] = true));
	}
	return queueUpdate;
};

export const convertedCourseDay = (courseDays) =>
	courseDays?.reduce((accumulator, currentValue) => [...accumulator, currentValue, ...currentValue.dates], []);

export const getInitialScrollOffset = (data, id, itemSize = 44) => {
	const index = data.findIndex((i) => i.id === id);
	return index !== -1 ? index * itemSize : 0;
};

export const getIndexOfTermAndGradingPeriod = (termsListByCourse) => {
	if (!termsListByCourse.length) {
		return {};
	}
	const currentDay = moment().startOf('day');
	const lastIndexOfTerm = termsListByCourse.length - 1;
	const isAfterLastDayOfTerm = currentDay.isAfter(termsListByCourse[lastIndexOfTerm].lastDay);
	const isAfterFirstDayOfTerm = currentDay.isAfter(termsListByCourse[0].firstDay);

	let currentTermIndex = termsListByCourse.findIndex((i) => currentDay.isBetween(i.firstDay, i.lastDay, null, '[]'));
	let indexOfTermNearly = termsListByCourse.findIndex((i, indx) =>
		indx > 0 ? currentDay.isBetween(termsListByCourse[indx - 1].lastDay, i.firstDay, null, '[]') : false,
	);
	let currentGdpIndex = 0;

	if (currentTermIndex !== -1) {
		currentGdpIndex = termsListByCourse[currentTermIndex]?.gradingPeriods?.findIndex((i) =>
			currentDay.isBetween(i.firstDay, i.lastDay, null, '[]'),
		);
	} else {
		currentTermIndex = indexOfTermNearly !== -1 ? indexOfTermNearly : 0;
	}

	// NOTE: Case 1: current time in the future
	// NOTE: Case 2: current time in a term
	// NOTE: Case 3: current time in the past
	// NOTE: Case 4: current time between two terms and selected term nearly in the future

	const indexTerm = isAfterLastDayOfTerm ? lastIndexOfTerm : isAfterFirstDayOfTerm ? currentTermIndex : 0;
	const indexGdp = isAfterLastDayOfTerm
		? termsListByCourse[indexTerm].gradingPeriods?.length - 1
		: isAfterFirstDayOfTerm
		? currentGdpIndex
		: 0;

	return { indexTerm, indexGdp };
};

export const getPathname = (authContext, item) => {
	const isGuardianRole = isGuardian(authContext.currentUser);

	switch (item?.status) {
		case TASK_STATUS.UNSCHEDULED:
			const unscheduledParam = `?${objectToParams({
				tabTaskActive: 'task_contents',
			})}`;
			if (isGuardianRole) {
				return (
					ROUTE_TASKS.GUARDIAN_VIEW_UNSCHEDULE_TASK_DETAILS(item.id, authContext.currentStudentId) + unscheduledParam
				);
			}
			return ROUTE_TASKS.UNSCHEDULE_TASK_DETAILS(item.id) + unscheduledParam;

		case TASK_STATUS.SCHEDULED:
			const scheduledParam = `?${objectToParams({
				tabTaskActive: 'task_contents',
			})}`;
			if (isGuardianRole) {
				return ROUTE_TASKS.GUARDIAN_VIEW_SCHEDULE_TASK_DETAILS(item.id, authContext.currentStudentId) + scheduledParam;
			}
			return ROUTE_TASKS.SCHEDULE_TASK_DETAILS(item.id) + scheduledParam;

		case TASK_STATUS.COMPLETED:
			const completedParam = `?${objectToParams({
				active: 'completed',
				tabTaskActive: 'task_contents',
			})}`;
			if (isGuardianRole) {
				return ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(authContext.currentStudentId) + completedParam;
			}
			return ROUTE_TASKS.COMPLETED_TASK_DETAILS(item.id) + completedParam;

		default:
			break;
	}
};

export const initFilterStatus = () => {
	const initFilter = {};
	Object.keys(STUDENT_PROGRESS_STATUS).forEach((key) => {
		// if (key !== 'LATE_TURN_IN') {
		initFilter[key] = true;
		// }
	});
	return initFilter;
};

export const getStatusStudentProgressFilter = (filters) => {
	const filtered = Object.keys(filters).filter((k) => filters[k]);
	const statusId = filtered.map((key) => STUDENT_PROGRESS_STATUS[key]);
	return statusId.toString();
};

export const getStatusStudentSubmission = (t, statusCode) => {
	if (!isUndefined(statusCode)) {
		const { name /* color */ } = STATUS_STUDENT_ASSIGNMENT_IN_SUBMISSION_LIST[statusCode];
		return t(`myCourses:${name}`);
	}
	return '';
};

export const updateSectionsByGradingPeriod = (activityId, data, sectionsByGradingPeriod, type) => {
	let newSectionsByGradingPeriod = [];
	let key;
	let activityIdKey;
	switch (type) {
		case ACTIVITY_CATEGORY.ASSIGNMENT:
			activityIdKey = 'masterAssignmentId';
			key = 'assignments';
			break;
		case ACTIVITY_CATEGORY.LESSON:
			activityIdKey = 'masterLessonId';
			key = 'lessons';
			break;
		case ACTIVITY_CATEGORY.TEST:
			activityIdKey = 'masterTestId';
			key = 'tests';
			break;

		default:
			break;
	}
	newSectionsByGradingPeriod = sectionsByGradingPeriod.map((section) => {
		section.sectionSchedules = section.sectionSchedules.map((item) => {
			item.courseDay[key] = item.courseDay[key].map((shadow) => {
				if (shadow[activityIdKey] === activityId) {
					shadow = {
						...shadow,
						...data,
					};
				}
				return shadow;
			});
			return item;
		});
		return section;
	});
	return newSectionsByGradingPeriod;
};

export const updateCourseItemByUnit = (state, masterId, data, typeName) => {
	const courseItemByUnit = state;
	if (courseItemByUnit?.items?.[typeName].length) {
		const activityList = courseItemByUnit.items[typeName];
		for (const activity of activityList) {
			if (activity.data.id === masterId) {
				activity.data = {
					...activity.data,
					...data,
				};
			}
		}
		courseItemByUnit.items[typeName] = activityList;
	}
	return courseItemByUnit;
};

export const updateActivityItemByUnits = (state, masterId, data, typeName) => {
	const listActivitiesByUnits = state;

	for (let i = 0; i < listActivitiesByUnits.length; i++) {
		const list = listActivitiesByUnits[i];
		const listActivity = list.items[typeName];
		const index = listActivity.findIndex((item) => item.id === masterId);
		if (index !== -1) {
			listActivity[index] = {
				...listActivity[index],
				...data,
			};
			break;
		}
	}

	return listActivitiesByUnits;
};

export const removeShadowFromCourseItemByUnit = (state, sectionIds, masterId, type) => {
	const courseItemByUnit = state;
	let key;
	let activityIdKey;
	switch (type) {
		case ACTIVITY_CATEGORY.ASSIGNMENT:
			activityIdKey = 'masterAssignmentId';
			key = 'assignments';
			break;
		case ACTIVITY_CATEGORY.TEST:
			activityIdKey = 'masterTestId';
			key = 'tests';
			break;
		case ACTIVITY_CATEGORY.LESSON:
			activityIdKey = 'masterLessonId';
			key = 'lessons';
			break;

		default:
			break;
	}
	for (const sectionId of sectionIds) {
		for (const section of courseItemByUnit) {
			if (sectionId === section.id) {
				section.sectionSchedules = section.sectionSchedules.map((item) => {
					item.courseDay[key] = item.courseDay[key].filter((shadow) => shadow[activityIdKey] !== masterId);
					return item;
				});
			}
		}
	}
	return courseItemByUnit;
};

export const scrollToElement = (scroller, element, useSmooth = true) => {
	// element.offsetTop and scroller.offsetTop is the distance between them
	// with the outermost element of app
	// element.offsetTop and scroller.offsetTop is the distance between them
	// with the outermost element of app
	if (!scroller) return;
	const offsetTop = element.offsetTop - scroller.offsetTop; // the distance from target element to the scrollable element
	const heightScrollViewPort = scroller.offsetHeight;
	const verticalCenter = offsetTop - heightScrollViewPort / 2;

	const offsetLeft = element.offsetLeft - scroller.offsetLeft;
	const widthScrollViewPort = scroller.offsetWidth;
	const horizontalCenter = offsetLeft - widthScrollViewPort / 2 + element.offsetWidth; // add target width to show full target
	const scrollOption = { top: verticalCenter, left: horizontalCenter, behavior: useSmooth ? 'smooth' : 'auto' };

	scroller.scrollTo(scrollOption);
};

export const isNumber = (number) => {
	const numberValue = Number(number);
	return number !== null && isFinite(numberValue);
};

export const isValidNumber = (value) => {
	if (value !== null && +value === 0) return true;
	if (!value || value === undefined || value === null) return false;

	return true;
};

export const typeOfCheck = (value) => Object.prototype.toString.call(value).slice(8, -1);

export const whatBlock = ({ targetSource = '', targetDes = '', activity = '' }) => {
	if (!targetSource && !targetDes) return {};
	const resultSource = targetSource.split(/[|-\s]/);
	const resultDes = targetDes.split(/[|-\s]/);
	const resultActivity = activity.split(/[|-\s]/);
	return {
		block: resultDes?.[0] || resultSource?.[0],
		destinationContainerId: +(resultDes?.[resultDes.length - 1] || resultSource?.[resultSource.length - 1]),
		sourceContainerId: +resultSource?.[resultSource.length - 1],
		isSameBlock:
			resultSource?.[0] === resultDes?.[0] &&
			resultDes?.[resultDes.length - 1] === resultSource?.[resultSource.length - 1]
				? true
				: !!(resultSource?.[0] && !resultDes?.[0]),
		isSameCategory: resultSource?.[1] === resultDes?.[1],
		category: resultDes?.[1] || resultSource?.[1],
		activityId: +resultActivity?.[resultActivity.length - 1],
	};
};

export const whatActivityId = (targetString = '') => {
	const result = targetString.split('-');
	return +result?.[1];
};

export const findBound = ({ arrTarget = [], target = null, isDragColumn }) => {
	if (!target || !arrTarget?.length) return;

	const currIndex = arrTarget.indexOf(target);
	return {
		upperBound:
			currIndex !== -1
				? isDragColumn
					? +arrTarget[currIndex - 1] || null
					: +(arrTarget[currIndex - 1]?.id || arrTarget[currIndex - 1]?.participationId) || null
				: null,
		lowerBound:
			currIndex !== -1
				? isDragColumn
					? +arrTarget[currIndex + 1] || null
					: +(arrTarget[currIndex + 1]?.id || arrTarget[currIndex + 1]?.participationId) || null
				: null,
	};
};

export const scrollToGroup = (category, containerId, delta) => {
	const sameCategoryNodeList = document.querySelectorAll(
		`div[drag-type^="${category}-category"]:not([drag-type*="${containerId}"])`,
	);

	if (sameCategoryNodeList?.length) {
		sameCategoryNodeList.forEach((node) => {
			const parent = node.parentNode;
			if (!parent?.nodeType) return;
			parent.scrollTop = node.offsetTop - parent.offsetTop - parent.offsetHeight / delta;
		});
	}
};

export const scrollToNewGroup = (delta) => {
	const nodeList = document.querySelectorAll('div[drag-type^="drag-new"]');
	nodeList.forEach((node) => {
		const parent = findAncestorNode('scrollbar-mini-plan', node, 5);
		if (!parent?.nodeType) return;
		parent.scrollTop = node.offsetTop - parent.offsetTop - parent.offsetHeight / delta;
	});
};

export const getParticipationName = (encodeName = '') =>
	encodeName && typeof encodeName === 'string' ? encodeName.split('%2')?.join(' ')?.split('-')?.slice(0, -1)?.[0] : '';

export const getParticipationId = (encodeName = '') =>
	encodeName && typeof encodeName === 'string' ? encodeName.split('%2')?.join(' ')?.split('-')?.slice(-1)?.[0] : '';

export const getBelowIndex = (activeRect, overRect, overIndex, itemLength) => {
	const isBelowOverItem =
		activeRect.current.translated &&
		activeRect.current.translated.top + activeRect.current.translated.height > overRect.top + overRect.height;
	const modifier = isBelowOverItem ? 1 : 0;

	return overIndex >= 0 ? overIndex + modifier : itemLength + 1;
};

export const arrayMoved = (array = [], from, to) => {
	const [removed] = array.splice(from, 1);
	array.splice(to, 0, removed);

	return removed;
};

export const findAncestorNode = (id, node, maxDeep = 4) => {
	if (!node?.nodeType) return null;
	let deep = 1;
	let result = null;

	(function recursive(props) {
		let { id, node, maxDeep } = props;
		node = node.parentNode;
		if (node && node.id.includes(id)) {
			result = node;
			return;
		}

		deep++;
		if (deep > maxDeep) return;

		recursive({ id, node, maxDeep });
	})({ id, node, maxDeep });

	return result;
};

export const getUnitGrade = (type) => {
	switch (type) {
		case GRADE_CALCULATION_TYPE.WEIGHTED_BY_CATEGORY:
			return '%';
		case GRADE_CALCULATION_TYPE.TOTAL_POINT:
		case GRADE_CALCULATION_TYPE.NO_OVERALL_GRADE:
			return 'points';
		default:
			return '';
	}
};

export const getConfigByGradeType = (type) => {
	switch (type) {
		case GRADE_CALCULATION_TYPE.WEIGHTED_BY_CATEGORY:
			return {
				required: true,
				ableConflict: true,
				showTotal: true,
				unit: getUnitGrade(type),
				max: 100,
				min: 0,
				maxLengthNumber: 3,
				defaultWeight: 0,
				showTotalTitle: true,
			};
		case GRADE_CALCULATION_TYPE.TOTAL_POINT:
		case GRADE_CALCULATION_TYPE.NO_OVERALL_GRADE:
			return {
				required: true,
				ableConflict: false,
				showTotal: false,
				unit: getUnitGrade(type),
				max: 10000,
				min: 1,
				maxLengthNumber: 5,
				defaultWeight: 100,
				showTotalTitle: false,
			};
		default:
			return {};
	}
};