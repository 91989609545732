import { objectToParams } from 'utils';

export default {
	get_units_groupby_grading_period: {
		url: (id, courseId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${id}/courses/${courseId}/grading-periods-units?${objectToParams(params)}`,
		method: 'GET',
	},
	get_activities_by_unit_id: {
		url: (id, courseId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${id}/courses/${courseId}/units/activities?${objectToParams(
				params,
			)}`,
		method: 'GET',
	},
	get_courses_need_reschedule: {
		url: (id, schoolYearId) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${id}/school-years/${schoolYearId}/teacher/courses-need-reschedule`,
		method: 'GET',
	},
	get_activities_reschedule_course: {
		url: (id, schoolYearId, courseId) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${id}/school-year/${schoolYearId}/course/${courseId}/activities/need-reschedule`,
		method: 'GET',
	},
	get_sections_reschedule_activity: {
		url: (id, courseId, activityId, params) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${id}/courses/${courseId}/activities/${activityId}/shadows?${objectToParams(params)}`,
		method: 'GET',
	},
	get_sessions_reschedule_activity: {
		url: (id, courseId, params) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${id}/courses/${courseId}/sessions?${objectToParams(params)}`,
		method: 'GET',
	},
};
