import { COURSE_ITEM_TYPE } from 'utils/constants';
import { ROLES_UPDATE_COURSE } from 'utils/roles';

import { ACTIVITY_CATEGORY, ACTIVITY_CATEGORY_NAME } from 'modules/MyCourses/constants';
import { ROUTE_MY_COURSES } from 'modules/MyCourses/constantsRoute';
import moment from 'moment';
import { objectToParams } from 'utils';
import { checkPermission } from 'utils';

import { DATE_TYPE, PUBLISH_NOW } from '../constants';

export const hoursForTimeToComplete = 60 * 8; // 8 hours

export const getUrlToStudentViewActivity = (item, studentId) => {
	if (!item) {
		return '';
	}
	let id = `${item?.type}-${item?.shadowId || item?.id}`;
	if (item.type === COURSE_ITEM_TYPE.QUIZ) {
		id = `${id}-${item?.quizType}`;
	}
	const params = objectToParams({
		week: `${item?.weekly}_${item?.termId}`,
		id,
		sectionId: item?.sectionId,
	});
	// NOTE: view for guardian
	if (!!studentId) {
		return `${ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(studentId, item?.courseId)}?${params}`;
	}
	return `${ROUTE_MY_COURSES.MY_COURSES_DETAIL(item?.courseId)}?${params}`;
};

export const getTimeItems = (stepMinute = 1, stepHours = 1, stepDays = 1) => {
	const groupSelect = {
		minutes: {
			to: 60 / stepMinute,
			step: stepMinute,
			title: 'Minutes',
			label: (value) => `${value} min`,
		},
		hours: {
			step: stepHours,
			to: 24,
			title: 'Hours',
			convertToMinute: (value) => value * 60,
			label: (value) => `${value} hour`,
		},
		days: {
			step: stepDays,
			to: 8,
			title: 'Days',
			convertToMinute: (value) => value * 60 * 24,
			label: (value) => `${value} day`,
		},
	};

	const items = [];
	Object.keys(groupSelect).forEach((key) => {
		const type = groupSelect[key];
		items.push({ title: type.title });
		for (let i = 1, j = type.to; i < j; i++) {
			const value = i * type.step;
			items.push({
				value: (type?.convertToMinute && type.convertToMinute(value)) || value,
				label: type.label(value),
			});
		}
	});
	return items;
};

export const checkPermissionCreateAndPublish = (permission) => checkPermission(permission, ROLES_UPDATE_COURSE);
export const formatTime = (time) => {
	time = moment.duration(time, 'minutes');
	const hh = time.years() * (365 * 24) + time.months() * (30 * 24) + time.days() * 24 + time.hours();
	const mm = time.minutes();
	const hourFormatStr = hh === 1 ? 'hr' : 'hrs';
	const minuteFormatStr = mm === 1 ? 'min' : 'mins';
	if(!hh){
		return `${mm} ${minuteFormatStr}`;

	}
	if (!mm) {
		return `${hh} ${hourFormatStr}`;
	}
	return `${hh} ${hourFormatStr} ${mm} ${minuteFormatStr}`;
};

export const validMinMaxInput = (min, max) => (values) => {
	const { floatValue, formattedValue } = values;
	return formattedValue === '' || (floatValue <= max && floatValue > min);
};

export const checkPastSections = (sectionSchedules) => {
	const today = moment();
	return sectionSchedules.some((section) => today.isAfter(moment(section.end)));
};
export const processDataSessions = (sectionId, type, sessions, isPublished = true, activityType) => {
	const today = moment();

	const filteredArray = sessions.flatMap((courseDay) =>
		courseDay.sectionSchedules
			.filter((section) => section.sectionId === sectionId)
			.map((section) => {
				const time =
					type === DATE_TYPE.ASSIGN || activityType === ACTIVITY_CATEGORY.TEST
						? section.startTimeLabel
						: section.endTimeLabel;
				let additionData = {};
				if (moment(section.start).isBefore(today)) {
					additionData = addingValuesForGroupSessions({}, 'OLD_SESSION');
					additionData.isInThePast = true;
				} else {
					additionData = addingValuesForGroupSessions({}, 'UPCOMING_SESSION');
					additionData.isInThePast = false;
				}
				return {
					value: Number(courseDay.id),
					label: `${section.sessionName} (${section.dateTimeLabel} - ${time})`,
					start: section.start,
					end: section.end,
					id: section.sectionId,
					...additionData,
				};
			}),
	);
	if (filteredArray.length) {
		if (!filteredArray.find((item) => item.category === 'Old sessions')) {
			filteredArray.unshift({
				label: '',
				value: -1,
				info: {},
				category: 'Old sessions',
				isHidden: true,
				disabled: true,
			});
		}
		if (!filteredArray.find((item) => item.category === 'Upcoming sessions')) {
			filteredArray.push({
				label: '',
				value: -1,
				info: {},
				category: 'Upcoming sessions',
				isHidden: true,
				disabled: true,
			});
		}
	}

	return filteredArray;
};

export const convertedDefaultValue = (dateId, sectionId, type, sessions, isPublished = false, item) => {
	if (Number(dateId) === PUBLISH_NOW) {
		return {
			value: Number(dateId),
			label: item.assignDateLabel,
			start: moment(item.assignDateLabel, 'MMM DD, YYYY - HH:mm A').toISOString(),
		};
	}
	let result = null;
	const metadata = processDataSessions(sectionId, type, sessions, isPublished);
	result = metadata.find((item) => item.value === dateId);
	return result;
};

// export const filterSessionsByCurrentGradingPeriod = ({
//   sectionSchedules,
//   today,
//   sessionGradingPeriodId,
//   gradingPeriodId,
// }) =>
//   `${sessionGradingPeriodId}` === `${gradingPeriodId}` &&
//   !sectionSchedules.some((section) => moment(section.start).isBefore(today, 'day'));

export const compareDateMoment = ({ momentDateA, momentDateB, isExactDay = false }) => {
	if (!momentDateA || !momentDateB) return false;
	return isExactDay ? moment(momentDateA).isBefore(momentDateB, 'day') : moment(momentDateA).isBefore(momentDateB);
};

// export const filterSessionsByListGradingPeriod = ({
//   sectionSchedules,
//   today,
//   sessionGradingPeriodId,
//   listFilteredGradingPeriod,
//   dateType,
// }) => {
//   const isExactDay = dateType === DATE_TYPE.ASSIGN;
//   if (listFilteredGradingPeriod.length) {
//     return (
//       listFilteredGradingPeriod.find((item) => `${item}` === `${sessionGradingPeriodId}`) &&
//       !sectionSchedules.some((section) =>
//         compareDateMoment({ momentDateA: section.start, momentDateB: today, isExactDay })
//       )
//     );
//   }
//   return !sectionSchedules.some((section) =>
//     compareDateMoment({ momentDateA: section.start, momentDateB: today, isExactDay })
//   );
// };

// export const filterCustomSessionsByListGradingPeriod = ({ sessionGradingPeriodId, listFilteredGradingPeriod }) => {
//   if (listFilteredGradingPeriod.length) {
//     return listFilteredGradingPeriod.find((item) => `${item}` === `${sessionGradingPeriodId}`);
//   }
//   return false;
// };

// export const filterSessionsByGradingPeriod = ({
//   dateType,
//   sessions = [],
//   gradingPeriodId,
//   today,
//   activityType,
//   forAllSessions = true,
// }) => {
//   const listGradingPeriod = [...new Set(sessions.map((s) => s.gradingPeriodId))];
//   const indexOfCurrentGradingPeriod = listGradingPeriod.indexOf(Number(gradingPeriodId));
//   const listGradingPeriodFromCurrent = listGradingPeriod.slice(indexOfCurrentGradingPeriod);
//   const listFilteredGradingPeriod =
//     dateType === DATE_TYPE.ASSIGN || activityType === ACTIVITY_CATEGORY.TEST || indexOfCurrentGradingPeriod === -1 // if current GP not in listGradingPeriod filter return []
//       ? [gradingPeriodId]
//       : [...listGradingPeriodFromCurrent];

//   if (!forAllSessions) {
//     return sessions.filter((s) =>
//       filterCustomSessionsByListGradingPeriod({
//         sessionGradingPeriodId: s.gradingPeriodId,
//         listFilteredGradingPeriod: listFilteredGradingPeriod,
//       })
//     );
//   }

//   return sessions.filter((s) =>
//     filterSessionsByListGradingPeriod({
//       sectionSchedules: s.sectionSchedules,
//       today,
//       sessionGradingPeriodId: s.gradingPeriodId,
//       dateType,
//       listFilteredGradingPeriod: listFilteredGradingPeriod,
//     })
//   );
// };

export const addingValuesForGroupSessions = (sessionData = {}, groupType) => {
	if (groupType === 'OLD_SESSION') {
		return {
			...sessionData,
			inThePast: true,
			category: 'Old sessions',
			info: {
				// Remove Passed tag in ticket 7802
				// label: 'Passed',
				// size: 'small',
				// sx: {
				// 	backgroundColor: '#F3F4F6',
				// 	color: '#374151',
				// },
			},
		};
	}
	return {
		...sessionData,
		inThePast: false,
		category: 'Upcoming sessions',
		info: {},
	};
};

export const processSessionsAllSections = (sessions, dateType, type) => {
	const today = moment();

	sessions = (sessions || []).map((s) => {
		if (s.sectionSchedules.some((section) => moment(section.start).isBefore(today))) {
			s = addingValuesForGroupSessions(s, 'OLD_SESSION');
			return s;
		}
		s = addingValuesForGroupSessions(s, 'UPCOMING_SESSION');
		return s;
	});

	let timeLabel;
	let time;

	switch (dateType) {
		case DATE_TYPE.ASSIGN:
			timeLabel = 'startTimeLabel';
			time = 'start';
			break;
		case DATE_TYPE.DUE:
			timeLabel = 'endTimeLabel';
			time = 'end';
			if (type === ACTIVITY_CATEGORY.TEST) {
				timeLabel = 'startTimeLabel';
			}
			break;
		default:
			break;
	}

	if (dateType === DATE_TYPE.ASSIGN || dateType === DATE_TYPE.DUE) {
		sessions.forEach((session) => {
			session.sectionSchedules.sort((a, b) => {
				if (moment(a[time]).isBefore(moment(b[time]))) return -1;
				return 1;
			});
			const sectionLength = session.sectionSchedules.length;
			const startBound = `${session.sectionSchedules[0].dateTimeLabel} - ${session.sectionSchedules[0][timeLabel]}`;
			if (sectionLength > 1) {
				const endBound = `${session.sectionSchedules[sectionLength - 1].dateTimeLabel} - ${
					session.sectionSchedules[sectionLength - 1][timeLabel]
				}`;
				session.sessionName = `${session.courseDayName} (${startBound} to ${endBound})`;
			} else {
				session.sessionName = `${session.courseDayName} (${startBound})`;
			}
		});
	}

	const res = sessions.map((session) => ({
		label: session.sessionName,
		value: Number(session.id),
		info: session.info,
		category: session.category,
		inThePast: session.inThePast,
		sectionSchedules: session.sectionSchedules.map((section) => ({
			sectionId: section.sectionId,
			start: section.start,
			end: section.end,
		})),
	}));
	if (res.length) {
		if (!res.find((item) => item.category === 'Old sessions')) {
			res.unshift({
				label: '',
				value: -1,
				info: {},
				category: 'Old sessions',
				isHidden: true,
				disabled: true,
			});
		}
		if (!res.find((item) => item.category === 'Upcoming sessions')) {
			res.push({
				label: '',
				value: -1,
				info: {},
				category: 'Upcoming sessions',
				isHidden: true,
				disabled: true,
			});
		}
	}
	return res;
};

export const getIconType = (item) => {
	const type = item?.lessonName
		? ACTIVITY_CATEGORY_NAME[5]
		: item?.testName
		? ACTIVITY_CATEGORY_NAME[4]
		: item?.assignmentName
		? ACTIVITY_CATEGORY_NAME[1]
		: ACTIVITY_CATEGORY_NAME[3];
	return type;
};

export const hasArray = (sectionSchedules, sections) => {
	const sectionIdOfSectionSchedules = (sectionSchedules || []).map((item) => item.sectionId);
	const sectionIdOfSections = (sections || []).map((item) => item.id);
	return sectionIdOfSections.every((v) => sectionIdOfSectionSchedules.includes(v));
};

export const getDefaultGradeWeightValue = ({ gradeWeightOptions = [] }) => {
	if (gradeWeightOptions.length === 1) {
		return gradeWeightOptions[0];
	}
	return null;
};
