import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

// import PerfectScrollbar from 'react-perfect-scrollbar';
import TblGoogleFile from 'components/TblGoogleFile';

import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {},
	googleList: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: theme.spacing(2),
	},
	emptyContent: {
		color: theme.newColors.gray[700],
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
}));
function TblGoogleFileList(props) {
	const { classes } = useStyles(props);
	const { t } = useTranslation('google');
	const {
		list,
		hasPermission,
		onRemove,
		onChangeChooseTemplate,
		onUseTemplate,
		useTemplateLoading,
		disableUseTemplate,
		disabled,
		type,
		isRenderContextMenu,
		isStudentOrGuardian,
		submissionRequired,
		archiveMode,
		onSelectFile,
		fileSelected,
	} = props;

	return (
		// <PerfectScrollbar className={classes.scrollBar}>
		<Box className={classes.googleList}>
			{list.map((item) => (
				<Box
					aria-selected={fileSelected?.sourceId === item.sourceId && fileSelected?.mediaType === item.mediaType}
					className='media-css'
				>
					<TblGoogleFile
						onSelectFile={onSelectFile}
						hasPermission={hasPermission}
						onRemove={onRemove}
						key={item.sourceId}
						file={{
							...item,
							name: item.originalName,
							icon: item?.iconLink,
							mineType: item.mimetype,
						}}
						type={type}
						onChangeChooseTemplate={onChangeChooseTemplate}
						onUseTemplate={onUseTemplate}
						useTemplateLoading={useTemplateLoading}
						disableUseTemplate={disableUseTemplate}
						disabled={disabled}
						isRenderContextMenu={isRenderContextMenu}
						isStudentOrGuardian={isStudentOrGuardian}
						submissionRequired={submissionRequired}
						archiveMode={archiveMode}
					/>
				</Box>
			))}
		</Box>
		// </PerfectScrollbar>
	);
}

TblGoogleFileList.propTypes = {
	disableUseTemplate: PropTypes.bool,
	hasPermission: PropTypes.bool,
	itemOnView: PropTypes.number,
	list: PropTypes.array,
	onChangeChooseTemplate: PropTypes.func,
	onRemove: PropTypes.func,
	onUseTemplate: PropTypes.func,
	useTemplateLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	isRenderContextMenu: PropTypes.bool,
	isStudentOrGuardian: PropTypes.bool,
	submissionRequired: PropTypes.bool,
	archiveMode: PropTypes.bool,
	onSelectFile: PropTypes.func,
};

TblGoogleFileList.defaultProps = {
	list: [],
	itemOnView: 4,
	isRenderContextMenu: false,
	isStudentOrGuardian: false,
	submissionRequired: false,
	archiveMode: false,
	fileSelected: null,
};

export default TblGoogleFileList;
