/* eslint-disable max-len */
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BreadcrumbContext } from 'components/TblBreadcrumb';
import ActivityHeader from 'modules/MyCourses/components/ActivityDetailPage/ActivityHeader';
import { TABS, TEST_TYPE } from 'modules/MyCourses/components/ActivityDetailPage/constants';
import Content from 'modules/MyCourses/components/ActivityDetailPage/TabPanel';
import NavigatorArea from 'modules/MyCourses/components/ActivityDetailPage/TabWrapper';

import useGetSchoolYear from 'utils/customHook/useGetSchoolYear';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { Box } from 'ella-storybook';
import { get, isEmpty, isEqual, isNil, pick, size } from 'lodash';
import myCoursesActions from 'modules/MyCourses/actions';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { ASSIGNMENT_PICK, LESSON_PICK, PARTICIPATION_PICK, TEST_PICK } from './constants';
import * as Handlers from './event-handlers';
import useCurrentSection from './hooks/use-current-section';
import useCurrentTab from './hooks/use-current-tab';
import useEditMode from './hooks/use-edit-mode';
import { useInitiateActivityData } from './hooks/use-initiate-activity-data';
import useSearch from './hooks/use-search';
import ModalControl from './ModalControl';
import { Container, HeaderArea } from './styles';

const ActivityDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const authContext = useContext(AuthDataContext);
	const breadcrumbContext = useContext(BreadcrumbContext);
	const { enqueueSnackbar } = useSnackbar();

	const { organizationId } = authContext.currentUser;
	const { schoolYearSelected: schoolYearId } = useGetSchoolYear();

	const params = useParams();
	const courseId = parseInt(params.courseId);
	const activityType = parseInt(params.activityType);
	const activityId = parseInt(params.activityId);
	const unitId = isNil(JSON.parse(params.unitId)) ? null : parseInt(params.unitId);

	const { t } = useTranslation();
	const [defaultSection, setDefaultSection] = useState(null);
	const [currentTab, setCurrentTab] = useState('');
	const [currentTestType, setCurrentTestType] = useState(null);
	const [showActivityModal, setShowActivityModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showGraderModal, setShowGraderModal] = useState(false);
	const [studentInfo, setStudentInfo] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedTab, setSelectedTab] = useState();
	const [selectedChip, setSelectedChip] = useState();
	const [searchValueInput, setSearchValueInput] = useState(null);
	const [searchValueTemporaryInput, setSearchValueTemporaryInput] = useState(null);
	const [shouldResetData, setShouldResetData] = useState(false);
	const [isSearching, setIsSearching] = useState(false);

	const headerElementInfoRef = useRef({});

	const searchValueRef = useRef('');

	const warningBannerInfo = useSelector((state) => get(state, 'Common.warningBannerInfo', null));
	const customizeDueDateSuccess = useSelector((state) => get(state, 'MyCourses.customizeDueDateSuccess'));
	const activiTyDetail = useSelector((state) => get(state, 'MyCourses.activiTyDetailWorkList'));

	const shouldReloadStudentWork = useSelector(
		(state) =>
			!!(
				get(state, 'Grader.inputGradeSuccess') ||
				get(state, 'Grader.updateStudentAttemptsSuccess') ||
				get(state, 'Grader.switchModeSuccess') ||
				get(state, 'MyCourses.gradeSuccess') ||
				get(state, 'MyCourses.mcConsolidateAssignmentSuccess') ||
				get(state, 'MyCourses.mcConsolidateTestSuccess') ||
				get(state, 'MyCourses.mcConsolidateLessonSuccess')
			),
	);

	const shouldReloadInstruction = useSelector(
		(state) =>
			!!(
				get(state, 'MyCourses.editMasterAssignmentSuccess') ||
				get(state, 'MyCourses.editTestSuccess') ||
				get(state, 'MyCourses.isEditLessonSuccess') ||
				get(state, 'MyCourses.editParticipationSuccess') ||
				get(state, 'MyCourses.mcConsolidateAssignmentSuccess') ||
				get(state, 'MyCourses.mcConsolidateTestSuccess') ||
				get(state, 'MyCourses.mcConsolidateLessonSuccess')
			),
	);

	const rescheduledSuccessfully = useSelector(
		(state) =>
			!!(
				get(state, 'MyCourses.mcConsolidateAssignmentSuccess') ||
				get(state, 'MyCourses.mcConsolidateTestSuccess') ||
				get(state, 'MyCourses.mcConsolidateLessonSuccess')
			),
	);

	const queryParams = useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location]);

	const initParams = useMemo(
		() => ({
			organizationId,
			courseId,
			unitId,
			activityId,
			selectedSection,
			schoolYearId,
			searchValueInput,
			activityType: TEST_TYPE[currentTestType] ?? activityType,
		}),
		[
			organizationId,
			courseId,
			unitId,
			activityId,
			selectedSection,
			schoolYearId,
			searchValueInput,
			currentTestType,
			activityType,
		],
	);

	/* prettier-ignore */
	const { 
		assignment,
		test,
		lesson,
		participation,
		gradingLateCompletion,
		sections, 
		term,
		activityStudentWorkList, 
		gradingPeriod,
		getActivitySuccess,handleActionStudentWork
	} = useInitiateActivityData({ params: initParams, shouldResetData, shouldReloadInstruction, shouldReloadStudentWork });
	const {
		handleShowActivityModal,
		handleCloseActivityModal,
		handleShowScheduleModal,
		handleCloseScheduleModal,
		handleShowGraderModal,
		handleCloseGraderModal,
		handleSearch,
		handleChangeQuery,
		handleSelectSection,
		handleClickSubHeader,
		handleSelectTab,
		handleSelectChip,
		handleDispatchSetState,
	} = Handlers;

	useEditMode(queryParams, getActivitySuccess, { setShowActivityModal });
	useCurrentSection(queryParams, defaultSection, sections, { setDefaultSection, setSelectedSection });
	useCurrentTab(queryParams, currentTab, { setCurrentTab, setSelectedTab, setCurrentTestType });
	useSearch({ customizeDueDateSuccess, searchValueInput, setIsSearching, handleActionStudentWork });

	useEffect(() => {
		breadcrumbContext.setData(null);

		return () => {
			dispatch(myCoursesActions.myCoursesReset());
		};
	}, []);

	useEffect(() => {
		if (!rescheduledSuccessfully) return;

		enqueueSnackbar(t('common:success'), { variant: 'success' });
	}, [rescheduledSuccessfully]);

	useEffect(() => {
		if (shouldResetData) {
			setShouldResetData(false);
		}
	}, [shouldResetData]);

	useEffect(() => {
		if (activityType === ACTIVITY_CATEGORY.LESSON) {
			setCurrentTab(TABS[0]);
		}
	}, [activityType]);

	const activityData = useMemo(() => {
		return {
			courseId,
			activityType: TEST_TYPE[currentTestType] ?? activityType,
			...(activityType === ACTIVITY_CATEGORY.ASSIGNMENT &&
				size(assignment) && {
					...pick(assignment, ASSIGNMENT_PICK),
					...{ activityName: get(assignment, 'assignmentName') },
					description: get(assignment, 'description', '') || '',
				}),
			...([ACTIVITY_CATEGORY.TEST, ACTIVITY_CATEGORY.TEST_IN_CLASS].includes(activityType) &&
				size(test) && {
					...pick(test, TEST_PICK),
					...(TEST_TYPE[currentTestType] === ACTIVITY_CATEGORY.TEST_IN_CLASS && pick(test.studyForTest, TEST_PICK)),
					...{ activityName: get(test, 'name') },
					description: get(test, 'description', '') || '',
				}),
			...(activityType === ACTIVITY_CATEGORY.LESSON &&
				size(lesson) && {
					...pick(lesson, LESSON_PICK),
					...{ activityName: get(lesson, 'lessonName') },
					description: get(lesson, 'description', '') || '',
				}),
			...(activityType === ACTIVITY_CATEGORY.PARTICIPATION &&
				size(participation) && {
					...pick(participation, PARTICIPATION_PICK),
					...{ activityName: get(participation, 'name') },
					description: get(participation, 'description', '') || '',
				}),
		};
	}, [courseId, activityType, assignment, test, lesson, participation, currentTestType]);

	const editLabel = currentTab === 'instruction' ? 'common:edit' : 'common:edit_schedule';

	const shouldCallAPItoGetModalData = isEmpty(activityData || gradingLateCompletion);

	const goBack = useCallback(() => {
		history.goBack();
	}, []);

	const handleCloseActivityModalCallBack = useMemo(() => {
		return handleCloseActivityModal({ setShowActivityModal, history, location, queryParams });
	}, []);

	const handleCloseScheduleModalCallBack = useMemo(() => {
		return handleCloseScheduleModal({ setShowScheduleModal, setShouldResetData });
	}, []);

	const handleCloseGraderModalCallBack = useMemo(() => {
		return handleCloseGraderModal({ setShowGraderModal, setShouldResetData });
	}, []);

	const handleSearchCallBack = useMemo(() => {
		return handleSearch({ searchValueRef, setSearchValueInput, searchValueInput });
	}, []);

	const handleSelectSectionCallBack = useMemo(() => {
		return handleSelectSection({ history, location, queryParams, setSelectedSection });
	}, [history, queryParams]);

	const handleSelectTabCallBack = useMemo(() => {
		return handleSelectTab({ setSelectedTab });
	}, []);

	const handleSelectChipCallBack = useMemo(() => {
		return handleSelectChip({ setSelectedChip });
	}, []);

	const handleShowActivityModalCallBack = useMemo(() => {
		return handleShowActivityModal({ setShowActivityModal });
	}, []);

	const handleShowScheduleModalCallBack = useMemo(() => {
		return handleShowScheduleModal({ setShowScheduleModal });
	}, []);

	const handleChangeQueryCallBack = useMemo(() => {
		return handleChangeQuery({ history, location, queryParams });
	}, [history, queryParams]);

	const handleShowGraderModalCallBack = useMemo(() => {
		return handleShowGraderModal({ setShowGraderModal, setStudentInfo });
	}, []);

	const handleClickSubHeaderCallBack = useMemo(() => {
		return handleClickSubHeader({ history, courseId });
	}, [history, courseId]);

	const handleDispatchSetStateCallBack = useMemo(() => {
		return handleDispatchSetState({ dispatch, action: myCoursesActions.myCoursesSetState });
	}, []);

	const isRelatedActivity = queryParams.has('relatedId') && +queryParams.get('relatedId') !== +activityId;

	return (
		<Container>
			<Box display={'flex'} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
				<HeaderArea
					ref={(el) => {
						const elementInfo = {
							clientHeight: get(el, 'clientHeight', null),
							clientWidth: get(el, 'clientWidth', null),
						};
						if (
							isEqual(headerElementInfoRef.current, elementInfo) ||
							isNil(elementInfo.clientHeight && elementInfo.clientWidth)
						)
							return;

						headerElementInfoRef.current = elementInfo;
					}}
				>
					<ActivityHeader {...{ activityData, isRelatedActivity, goBack, handleClickSubHeaderCallBack }} />
					<NavigatorArea
						translate={t}
						showTabs={![ACTIVITY_CATEGORY.LESSON, ACTIVITY_CATEGORY.PARTICIPATION].includes(activityType)}
						handleSearch={handleSearchCallBack}
						handleSelectSection={handleSelectSectionCallBack}
						handleSelectTab={handleSelectTabCallBack}
						handleSelectChip={handleSelectChipCallBack}
						handleShowActivityModal={handleShowActivityModalCallBack}
						handleShowScheduleModal={handleShowScheduleModalCallBack}
						handleChangeQuery={handleChangeQueryCallBack}
						{...{
							queryParams,
							activityType,
							editLabel,
							currentTab,
							sections,
							selectedSection,
							selectedChip,
							searchValueInput,
						}}
					/>
				</HeaderArea>
			</Box>
			<Content
				translate={t}
				handleShowGraderModal={handleShowGraderModalCallBack}
				handleDispatchSetState={handleDispatchSetStateCallBack}
				headerElementInfo={headerElementInfoRef.current}
				sections={useMemo(() => {
					return isNil(sections) ? [] : sections;
				}, [sections])}
				activityStudentWorkList={useMemo(() => {
					return isNil(activityStudentWorkList) ? [] : activityStudentWorkList;
				}, [activityStudentWorkList])}
				{...{
					organizationId,
					courseId,
					currentTab,
					selectedTab,
					warningBannerInfo,
					selectedSection,
					activityData,
					isSearching,
					queryParams,
				}}
			/>
			<ModalControl
				type={activityType}
				shouldResetState={false}
				handleCloseActivityModal={handleCloseActivityModalCallBack}
				handleCloseScheduleModal={handleCloseScheduleModalCallBack}
				handleCloseGraderModal={handleCloseGraderModalCallBack}
				{...{
					currentTab,
					shouldCallAPItoGetModalData,
					activiTyDetail,
					unitId,
					courseId,
					activityId: activityData.id || activityId,
					activityType: TEST_TYPE[currentTestType] ?? activityType,
					visible: showActivityModal,
					assignmentName: activityData.activityName,
					editable: activityData.editable || activityData.modifiable?.editable,
					modifiableSchedule: activityData.modifiable,
					totalPoint: activityData.totalPoint,
					showGraderModal,
					showScheduleModal,
					studentInfo,
					selectedSection,
					gradingPeriod,
					term,
				}}
			/>
			{/* <Test
				translate={t}
				handleShowGraderModal={handleShowGraderModalCallBack}
				handleDispatchSetState={handleDispatchSetStateCallBack}
				handleContentScrollY={handleContentScrollYCallBack}
				headerElementInfo={headerElementInfoRef.current}
				{...{
					organizationId,
					courseId,
					sections: useMemo(() => {
						return isNil(sections) ? [] : sections;
					}, [sections]),
					activityStudentWorkList: useMemo(() => {
						return isNil(activityStudentWorkList) ? [] : activityStudentWorkList;
					}, [activityStudentWorkList]),
					currentTab,
					selectedTab,
					warningBannerInfo,
					selectedSection,
					activityData,
					isSearching,
					queryParams,
				}}
			></Test> */}
		</Container>
	);
};

export default memo(ActivityDetailPage);
