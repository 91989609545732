/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isTeacher } from 'utils/roles';

import { ROUTE_AUTH } from 'shared/Auth/constantsRoute';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { BaseModal, Box, Button, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const headers = {
	Authorization: `Bearer ${localStorage.getItem('access_token')}`,
};

const url = process.env.REACT_APP_API_URL;
const common_events_url = `${url}/notifications/sse/events/listen?token=${headers.Authorization}`;
const AUTO_LOG_OUT_TIME = 2 * 60 * 60 * 1000;
const TeacherEventListener = () => {
	// const [event] = useServerPush(common_events_url);
	const history = useHistory();

	const { t } = useTranslation();
	const [sseData, setSseData] = useState();
	const [openForceLogOut, setOpenForceLogOut] = useState(false);

	useEffect(() => {
		const newEventSource = new EventSource(common_events_url);
		newEventSource.onmessage = function (event) {
			setSseData(JSON.parse(event.data));
		};

		newEventSource.onerror = function (error) {
			newEventSource.close();
		};
		return () => {
			newEventSource.close();
		};
	}, []);

	useEffect(() => {
		let timeout;
		if (sseData?.notify === 'force_log_out') {
			setOpenForceLogOut(true);
			timeout = setTimeout(() => backToLogin(), AUTO_LOG_OUT_TIME);
		}
		return () => {
			timeout && clearTimeout(timeout);
		};
	}, [sseData]);

	const backToLogin = () => {
		history.replace({ pathname: ROUTE_AUTH.LOGOUT, search: '?forceLogout=true' });
	};

	return (
		<>
			<BaseModal
				size='small'
				open={openForceLogOut}
				showCloseIcon={false}
				title={t('common:need_to_sign_in_again')}
				customFooter={
					<Box className='customFooterModal'>
						<Button onClick={backToLogin}>{t('media:got_it')}</Button>
					</Box>
				}
				contentComponent={<Typography variant='bodyMediumRegular'>{t('common:force_log_out_content')}</Typography>}
			/>
		</>
	);
};

function ServerEventProvider(props) {
	const authContext = useContext(AuthDataContext);
	const { currentUser } = authContext;

	const isTeacherRole = isTeacher(currentUser);

	return (
		<>
			{isTeacherRole && <TeacherEventListener />}
			{props.children}
		</>
	);
}

ServerEventProvider.propTypes = {
	children: PropTypes.node,
};

export default ServerEventProvider;
