export default (theme) => ({
	root: {
		'& .sidebar-container': {
			marginTop: theme.navbar.appBar,
		},
	},
	layoutSidebar: {
		transition: theme.transitionDefault,
		[theme.breakpoints.up('lg')]: {
			width: theme.sideBar.openWidth,
		},
		width: theme.sideBar.width,
		zIndex: theme.zIndex.drawer - 2,
	},
	layoutSidebarClose: {
		transition: theme.transitionDefault,

		width: theme.sideBar.width,
	},
	layoutContent: {
		position: 'relative',
		overflow: 'hidden',
		marginTop: theme.navbar.appBar,
		width: '100%',
		height: `calc(100vh - ${theme.navbar.appBar}px)`,
	},
	warningBanner: {
		height: theme.spacing(6),
		backgroundColor: theme.newColors.red[50],
		borderTop: `1px solid ${theme.newColors.red[100]}`,
		borderBottom: `1px solid ${theme.newColors.red[100]}`,
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '8px 40px 8px 40px',
	},
	contentWarningSchedule: {
		color: theme.newColors.red[900],
	},
	tourViewAllCourseUpdate: {
		maxWidth: `${theme.spacing(46)}!important`,
		minHeight: `${theme.spacing(27)}!important`,
		paddingLeft: `${theme.spacing(3)}!important`,
		paddingRight: `${theme.spacing(3)}!important`,
		fontFamily: 'Magenos',
		'& [data-tour-elem="controls"]': {
			justifyContent: 'flex-end',
		},
		'& button[data-tour-elem*="left"]': {
			marginRight: '0px',
			fontFamily: 'Magenos',
		},
		'& button[data-tour-elem*="right"]': {
			position: 'relative!important',
			fontFamily: 'Magenos',
		},
		'& [data-tour-elem="badge"]': {
			backgroundColor: 'transparent',
			color: theme.newColors.gray[500],
			fontSize: '16px',
			boxShadow: 'none',
			border: 'none',
			bottom: `${theme.spacing(2.5)}!important`,
			position: 'absolute!important',
			top: theme.spacing(20),
			left: theme.spacing(1.5),
			fontFamily: 'Magenos',
		},
		'& .tss-1vfc6li-content': {},
		'& .tss-i202gm-label': {},
	},
	tourDetailEachCourse: {
		maxWidth: `${theme.spacing(46)}!important`,
		paddingLeft: `${theme.spacing(3)}!important`,
		paddingRight: `${theme.spacing(3)}!important`,
		fontFamily: 'Magenos',
		'& [data-tour-elem="controls"]': {
			justifyContent: 'flex-end',
		},
		'& button[data-tour-elem*="left"]': {
			marginRight: '0px',
			fontFamily: 'Magenos',
		},
		'& button[data-tour-elem*="right"]': {
			position: 'relative!important',
			fontFamily: 'Magenos',
		},
		'& [data-tour-elem="badge"]': {
			backgroundColor: 'transparent',
			color: theme.newColors.gray[500],
			fontSize: '16px',
			boxShadow: 'none',
			border: 'none',
			bottom: `${theme.spacing(2.5)}!important`,
			position: 'absolute!important',
			top: theme.spacing(14.5),
			left: theme.spacing(1.5),
			fontFamily: 'Magenos',
		},
		'& .tss-1vfc6li-content': {},
		'& .tss-i202gm-label': {},
	},
});
