import sortBy from 'lodash/sortBy';

import myCoursesActions from 'modules/MyCourses/actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../../utils/ajax';
import gradeWeightingAction from '../actionConstants/gradeWeightingActions';
import END_POINT from '../endPoints/gradeWeightingEndPoints';

const updateCategories = (oldData, updatedData, method) => {
	switch (method) {
		case 'DELETE':
			return oldData.filter(({ id }) => id !== updatedData?.id);
		case 'GET':
			return sortBy(updatedData, ['type', 'createdAt']);
		default:
			return sortBy(updatedData.concat(oldData.filter(({ id }) => !updatedData.find(({ id: _id }) => _id === id))), [
				'type',
				'createdAt',
			]);
	}
};

const getGradeWeightEpic = (action$) =>
	action$.pipe(
		ofType(gradeWeightingAction.MC_GET_GRADE_WEIGHT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_grade_weight.method,
				END_POINT.mc_get_grade_weight.url(action.payload.orgId, action.payload.courseId),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetGradeWeightSuccess({
							gradeWeight: data.response?.gradeWeight?.criterias,
							mcGetGradeWeightSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of({ type: 'GLOBAL_ERROR', payload: { error } }, myCoursesActions.mcGetGradeWeightFailed()),
				),
			),
		),
	);

const updateGradWeightEpic = (action$) =>
	action$.pipe(
		ofType(gradeWeightingAction.MC_UPDATE_GRADE_WEIGHT),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_grade_weight.method,
				END_POINT.mc_update_grade_weight.url(action.payload.orgId, action.payload.courseId),
				{ gradeWeight: { criterias: action.payload.criterias } },
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.mcUpdateGradeWeightSuccess({
							updateGradeWeightSuccess: true,
						}),
						myCoursesActions.mcGetGradeWeight({
							orgId: action.payload.orgId,
							courseId: action.payload.courseId,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcUpdateGradeWeightFailed({
							updateGradeWeightFailed: true,
							error: error.response.errors,
						}),
					),
				),
			),
		),
	);

const gradeWeightCategoriesEpic = (action$, state$) =>
	action$.pipe(
		ofType(gradeWeightingAction.GRADE_WEIGHT_CATEGORIES),
		switchMap(({ payload }) => {
			const { orgId, courseId, payload: _payload, method } = payload;

			return makeAjaxRequest(method, END_POINT.grade_weight_categories.url(orgId, courseId), _payload).pipe(
				mergeMap(({ response }) => {
					const gradeWeight = updateCategories(
						state$?.value?.MyCourses?.gradeWeight ?? [],
						response?.data ?? _payload.data, // Only method POST return data from response
						method,
					);
					const isConflict = gradeWeight.reduce((total, { weight }) => total + (weight || 0), 0) !== 100;
					let flags = {
						updateSuccess: true,
						isGradeWeightConflict: isConflict,
					};

					if (method === 'GET') {
						flags = {
							fetchedData: true,
							isGradeWeightConflict: isConflict,
						};

						return of(
							myCoursesActions.gradeWeightCategoriesSuccess({
								gradeWeight,
								...flags,
							}),
						);
					}

					return of(
						myCoursesActions.gradeWeightCategoriesSuccess({
							gradeWeight,
							isLoading: false,
							...flags,
						}),
						myCoursesActions.getCourseValidation({ orgId, courseId }),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.gradeWeightCategoriesFailed({
							updateSuccess: null,
							isLoading: false,
						}),
					),
				),
			);
		}),
	);

const publishGradeWeightCategoriesEpic = (action$) =>
	action$.pipe(
		ofType(gradeWeightingAction.PUBLISH_GRADE_WEIGHT_CATEGORIES),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.publish_grade_weight_categories.method,
				END_POINT.publish_grade_weight_categories.url(payload.orgId, payload.courseId),
			).pipe(
				mergeMap(() => of(myCoursesActions.publishGradeWeightCategoriesSuccess({}))),
				catchError((error) =>
					of({ type: 'GLOBAL_ERROR', payload: { error } }, myCoursesActions.publishGradeWeightCategoriesFailed({})),
				),
			),
		),
	);

const getCompareGradeWeightCategoriesEpic = (action$) =>
	action$.pipe(
		ofType(gradeWeightingAction.GET_COMPARE_GRADE_WEIGHT_CATEGORIES),
		switchMap(({ payload }) =>
			makeAjaxRequest(
				END_POINT.get_compare_grade_weight_categories.method,
				END_POINT.get_compare_grade_weight_categories.url(payload.orgId, payload.courseId),
			).pipe(
				mergeMap(({ response }) =>
					of(
						myCoursesActions.getCompareGradeWeightCategoriesSuccess({
							gradeWeight: updateCategories(
								[],
								response?.data ?? [],
								END_POINT.get_compare_grade_weight_categories.method,
							),
							fetchedData: true,
							isLoading: false,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.getCompareGradeWeightCategoriesFailed({
							isLoading: false,
						}),
					),
				),
			),
		),
	);

export default [
	getGradeWeightEpic,
	updateGradWeightEpic,
	gradeWeightCategoriesEpic,
	getCompareGradeWeightCategoriesEpic,
	publishGradeWeightCategoriesEpic,
];
