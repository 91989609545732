import { objectToParams } from 'utils';

export const actions = {
	DASHBOARD_SET_STATE: 'DASHBOARD_SET_STATE',

	FETCH_COURSE_SECTIONS: 'FETCH_COURSE_SECTIONS',
	FETCH_COURSE_SECTIONS_SUCCESS: 'FETCH_COURSE_SECTIONS_SUCCESS',
	FETCH_COURSE_SECTIONS_FAILED: 'FETCH_COURSE_SECTIONS_FAILED',

	FETCH_STUDENT_PERFORMANCE: 'FETCH_STUDENT_PERFORMANCE',
	FETCH_STUDENT_PERFORMANCE_SUCCESS: 'FETCH_STUDENT_PERFORMANCE_SUCCESS',
	FETCH_STUDENT_PERFORMANCE_FAILED: 'FETCH_STUDENT_PERFORMANCE_FAILED',

	FETCH_TASK_PROGRESS_DETAIL: 'FETCH_TASK_PROGRESS_DETAIL',
	FETCH_TASK_PROGRESS_DETAIL_SUCCESS: 'FETCH_TASK_PROGRESS_DETAIL_SUCCESS',
	FETCH_TASK_PROGRESS_DETAIL_FAILED: 'FETCH_TASK_PROGRESS_DETAIL_FAILED',

	PRE_FETCH_TASK_PROGRESS_DETAIL: 'PRE_FETCH_TASK_PROGRESS_DETAIL',
	PRE_FETCH_TASK_PROGRESS_DETAIL_SUCCESS: 'PRE_FETCH_TASK_PROGRESS_DETAIL_SUCCESS',
	PRE_FETCH_TASK_PROGRESS_DETAIL_FAILED: 'PRE_FETCH_TASK_PROGRESS_DETAIL_FAILED',

	CREATE_TASK_POOL: 'CREATE_TASK_POOL',
	CREATE_TASK_POOL_DONE: 'CREATE_TASK_POOL_DONE',

	POOLING_FETCH_STUDENT_PERFORMANCE: 'POOLING_FETCH_STUDENT_PERFORMANCE',
	POOLING_FETCH_STUDENT_PERFORMANCE_SUCCESS: 'POOLING_FETCH_STUDENT_PERFORMANCE_SUCCESS',
	POOLING_FETCH_STUDENT_PERFORMANCE_FAILED: 'POOLING_FETCH_STUDENT_PERFORMANCE_FAILED',

	STOP_POOLING_FETCH_STUDENT_PERFORMANCE: 'STOP_POOLING_FETCH_STUDENT_PERFORMANCE',

};

export const END_POINT = {
	get_courses_and_sections: {
		url: (orgId, urlParams) =>
			`${process.env.REACT_APP_API_URL_V2}/organizations/${orgId}/course-list?${objectToParams(urlParams)}`,
		method: 'GET',
	},
	fetch_student_performance: {
		url: (orgId, courseId, sectionId, urlParams) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/courses/${courseId}/sections/${sectionId}/student-performance?${objectToParams(
				urlParams,
			)}`,
		method: 'GET',
	},
	fetch_task_progress_detail: {
		url: (orgId, courseId, sectionId, urlParams) =>
			`${
				process.env.REACT_APP_API_URL_V2
			}/organizations/${orgId}/courses/${courseId}/sections/${sectionId}/task-progress?${objectToParams(
				urlParams,
			)}`,
		method: 'GET',
	},
};
