import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	dayHaveSession: [],
	sessionsInPeriod: [],
	filterCalendar: {},
	eventDetail: {},
	eventActivities: {},
	personalNote: '',
};

export default createReducers(initialState, actions, actions.RESET_CALENDAR_REDUCER);
