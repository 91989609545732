import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

import TblInputs from 'components/TblInputs';
import BackgroundOtp from 'shared/Auth/components/BackgroundOtp';

import { isGuardian, isStudent } from 'utils/roles';

import { USER_BEHAVIOR } from 'shared/User/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { ReactComponent as Logo } from 'assets/images/logo_add_phone_number.svg';
import { useLayoutContext } from 'layout/LayoutContext';
import { OTP_CODE } from 'modules/MyProfile/constants';
import { ROUTE_MY_PROFILE } from 'modules/MyProfile/constantsRoute';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { makeStyles } from 'tss-react/mui';

import myProfileActions from '../../actions';

const { Typography, Box, Button, Dialog, BaseModal } = require('ella-storybook');

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 64px)',
		width: '100vw',
	},
	containerForm: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '480px',
	},
	buttonContinue: {
		backgroundColor: theme.newColors.primary[500],
		color: theme.newColors.white,
		width: '100%',
	},
	inlineError: {
		textAlign: 'center',
		width: '100%',
	},
	skipText: {
		cursor: 'pointer',
	},
}));

const AddPhoneNumber = (props) => {
	const { isOnBoarding } = props;
	const { classes } = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('myProfile');
	const authContext = useContext(AuthDataContext);

	const dispatch = useDispatch();
	const currentUser = authContext?.currentUser || {};
	const { organizationId, phone, settings } = currentUser;
	const history = useHistory();

	const otpSubCode = useSelector((state) => state.MyProfile.otpSubCode);
	const otpSubCodeSend = useSelector((state) => state.MyProfile.otpSubCodeSend);
	const isChangeProfileSuccess = useSelector((state) => state.MyProfile.isChangeProfileSuccess);

	const layoutContext = useLayoutContext();

	const [inlineErrPhone, setInlineErrPhone] = useState('');
	const [openReachedLimit, setOpenReachedLimit] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(phone);
	const [phoneInput, setPhoneInput] = useState(phone);
	const [guardianSkip, setGuardianSkip] = useState(false);

	const onCloseReachedLimit = () => {
		setOpenReachedLimit(false);

		if (isGuardian(currentUser) && isOnBoarding) {
			localStorage.setItem('backAddPhone', 1);
			const keys = Object.keys(settings);
			const _settings = { [keys[0]]: [...settings.behavior, USER_BEHAVIOR.HAVE_SET_UP_PROFILE] };

			const payload = {
				settings: _settings,
			};
			dispatch(myProfileActions.updateMyProfile(payload));
		}

		if (isStudent(currentUser) && isOnBoarding) {
			localStorage.setItem('backAddPhone', 1);
			history.replace({
				search: 'step=4',
			});
		}

		dispatch(
			myProfileActions.myProfileSetState({
				otpSubCode: null,
			}),
		);
	};

	useEffect(() => {
		layoutContext.setFullScreen(true);
		localStorage.removeItem('backAddPhone');
		localStorage.removeItem('backVerifyCode');
	}, []);

	useEffect(() => {
		if (otpSubCode || otpSubCodeSend) {
			setOpenReachedLimit(false);
			if ([otpSubCode, otpSubCodeSend].includes(OTP_CODE.REACH_LIMIT_RESEND)) {
				setOpenReachedLimit(true);
			}
			if ([otpSubCode, otpSubCodeSend].includes(OTP_CODE.PHONE_EXIST)) {
				setInlineErrPhone(t('myProfile:phone_number_already_in_another_acc'));
			}

			if (otpSubCode === OTP_CODE.SENT && isOnBoarding) {
				localStorage.setItem('backAddPhone', 1);
				history.push({
					search: 'step=2',
				});
			}

			if (otpSubCode === OTP_CODE.SENT && !isOnBoarding) {
				history.push({
					pathname: `${ROUTE_MY_PROFILE.VERIFY_PHONE}`,
				});
			}

			dispatch(
				myProfileActions.myProfileSetState({
					otpSubCode: null,
				}),
			);
		}
	}, [otpSubCode, otpSubCodeSend, isOnBoarding]);

	useEffect(() => {
		if (guardianSkip && isOnBoarding) {
			const keys = Object.keys(settings);
			const _settings = { [keys[0]]: [...settings.behavior, USER_BEHAVIOR.HAVE_SET_UP_PROFILE] };

			const payload = {
				settings: _settings,
			};
			dispatch(myProfileActions.updateMyProfile(payload));
		}
	}, [guardianSkip, isOnBoarding]);

	useEffect(
		() => () => {
			setInlineErrPhone('');
			setOpenReachedLimit(false);
			dispatch(
				myProfileActions.myProfileSetState({
					otpSubCode: null,
					otpSubCodeSend: null,
				}),
			);
		},
		[],
	);

	useEffect(() => {
		if (isGuardian(currentUser) && isChangeProfileSuccess && isOnBoarding) {
			const currentHostname = window.location.hostname;
			// localStorage.setItem('backAddPhone', 1);
			const studentId = currentUser?.students?.[0]?.id;
			let path = ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS_NO_STUDENT();

			if (studentId) {
				path = ROUTE_TASKS.GUARDIAN_VIEW_MY_TASKS(studentId);
			}

			const fullUrl = `${window.location.protocol}//${currentHostname}:${window.location.port}${path}`;

			window.location.replace(fullUrl);
		}
	}, [isOnBoarding, isChangeProfileSuccess]);

	const handleContinue = async () => {
		if (phoneInput) {
			if (phoneInput === phone) {
				dispatch(
					myProfileActions.sendOtp({
						orgId: organizationId,
						tool: 'phone',
						phone: phoneNumber,
						verifyOtpSuccess: false,
					}),
				);
			} else {
				const payload = {
					phone: phoneNumber,
					sendOtp: true,
					organizationId,
					isChangeProfileSuccess: false,
				};
				// localStorage.setItem('backAddPhone', 1);
				dispatch(myProfileActions.updateMyProfile(payload));
			}
		} else {
			setInlineErrPhone(t('myProfile:required_message_!'));
		}
	};

	const handleSkip = () => {
		if (isGuardian(currentUser) && isOnBoarding) {
			setGuardianSkip(true);
		}

		if (isStudent(currentUser) && isOnBoarding) {
			localStorage.setItem('backAddPhone', 1);
			history.push({ search: 'step=4' });
		}
	};

	return (
		<div className={classes.container}>
			<BaseModal
				size={'small'}
				title={t('try_again_later')}
				open={openReachedLimit}
				onClose={onCloseReachedLimit}
				customFooter={
					<Box className='customFooterModal'>
						<Button onClick={onCloseReachedLimit} variant='primary'>
							{t('got_it')}
						</Button>
					</Box>
				}
				contentComponent={<Typography variant='bodyMediumRegular'>{t('try_again_later_otp_content')}</Typography>}
			/>
			<Box className={classes.containerForm}>
				<Box width={'100%'} mb={'88px'} style={{ display: 'flex', justifyContent: 'center' }}>
					<Logo />
				</Box>

				<Typography variant='headlineSmall'>{t('add_your_phone_number')}</Typography>
				<Typography variant='bodyMediumRegular'>{t('please_add_your_phone')}</Typography>

				<Box mb={'24px'} />
				<TblInputs
					// placeholder={'xxx-xxx-xxxx'}
					errorMessage={inlineErrPhone && <div>{inlineErrPhone}</div>}
					label={<span>{t('common:phone_number')}</span>}
					inputType='phone'
					style={{ width: '100%' }}
					// on boarding does not need initial phone value
					value={phone || ''}
					country={'us'}
					onChange={(value, data) => {
						setPhoneInput(value.slice(data.dialCode.length));
						setPhoneNumber(value);
						setInlineErrPhone(null);
					}}
				/>

				<Box mb={'24px'} />
				<Button className={classes.buttonContinue} onClick={handleContinue}>
					{t('continue')}
				</Button>
				<Box mb={'16px'} />
				<Box textAlign={'center'}>
					<Typography
						className={classes.skipText}
						variant={'button'}
						onClick={handleSkip}
						color={theme.newColors.primary[500]}
					>
						{t('skip')}
					</Typography>
				</Box>
			</Box>

			<BackgroundOtp />
		</div>
	);
};
AddPhoneNumber.defaultProps = {
	isOnBoarding: false,
};

export default AddPhoneNumber;
