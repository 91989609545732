import React from 'react';
import { withRouter } from 'react-router';

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { TblIconButton } from 'components/TblIconButton';

import { Typography } from 'ella-storybook';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import BreadcrumbProvider, { BreadcrumbConsumer, BreadcrumbContext, useBreadcrumbContext } from './BreadcrumbContext';
import BreadcrumbTitle from './BreadcrumbTitle';

const styles = (theme) => ({
	root: {
		color: theme.palette.primary.main,
		overflow: 'hidden',
		paddingLeft: theme.spacing(4.5),
		'& .MuiTab-root': {
			paddingLeft: '0 !important',
			paddingRight: '0 !important',
		},
		'& .MuiTabs-indicator': {
			bottom: theme.spacing(0.75),
		},
	},
	boxShadow: {
		boxShadow: `${theme.spacing(0, 0, 0.25, 0)} rgba(0, 0, 0, 0.16)`,
	},
	header: {
		height: theme.spacing(3.5),
		alignItems: 'center',
		color: theme.palette.primary.main,
		fontSize: theme.fontSize['text-sm'],
	},
	body: {
		display: 'flex',
		minHeight: theme.spacing(5.5),
		alignItems: 'center',
		'& .MuiTypography-h3 ': {
			width: '80%',
			height: '100%',
		},
		paddingLeft: theme.spacing(0.75),
	},
	footer: {
		height: theme.spacing(5),
		marginTop: theme.spacing(-1.25),
		alignItems: 'center',
		fontWeight: theme.fontWeight.semi,
		'& .MuiTab-root': {
			fontWeight: `${theme.fontWeight.semi}!important`,
		},
		paddingLeft: theme.spacing(0.75),
	},
});

class Breadcrumb extends React.PureComponent {
	static contextType = BreadcrumbContext;

	componentDidMount() {
		const { history } = this.props;
		this.unlisten = history.listen((location) => {
			if (this.props.location.pathname !== location.pathname) {
				this.context.setData(null);
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	clickBreadcrumb = () => {
		const { history } = this.props;
		const { breadcrumb } = this.context;
		if (breadcrumb.handleBackFunc) {
			breadcrumb.handleBackFunc();
			return;
		}
		if (!breadcrumb.path) {
			return;
		}
		history.push(breadcrumb.path);
	};

	render() {
		if (!this.context?.breadcrumb) {
			return null;
		}
		const { breadcrumb } = this.context;
		const { classes } = this.props;
		return (
			<Box
				className={`${classes.root} ${breadcrumb.showBoxShadow ?? true ? classes.boxShadow : ''}`}
				pt={2}
				pb={!!!breadcrumb.footerContent ? 2 : 0.25}
				pl={5}
				pr={5}
			>
				{breadcrumb.headerContent && (
					<Grid container className={classes.header}>
						<TblIconButton onClick={this.clickBreadcrumb}>
							<span className={'icon-icn_back'} />
						</TblIconButton>
						<Typography className='text-ellipsis' variant='bodySmallRegular' component='span' color='primary'>
							{breadcrumb.headerContent}
						</Typography>
					</Grid>
				)}
				{breadcrumb.bodyContent ? (
					<Box className={classes.body}>
						{isString(breadcrumb.bodyContent) ? (
							<BreadcrumbTitle title={breadcrumb.bodyContent} />
						) : (
							breadcrumb.bodyContent
						)}
					</Box>
				) : (
					<Skeleton height={50} width={240} />
				)}
				{breadcrumb.footerContent && (
					<Grid container className={classes.footer}>
						{breadcrumb.footerContent}
					</Grid>
				)}
			</Box>
		);
	}
}

Breadcrumb.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	classes: PropTypes.object,
};
export { BreadcrumbContext, BreadcrumbProvider, useBreadcrumbContext, BreadcrumbConsumer };
export default withStyles(withRouter(Breadcrumb), styles);
