import React from 'react';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box } from '@mui/material';

import ElNotificationBell from 'components/ElNotificationBell';
import ElZella from 'components/ElZella';
import { TblIconButton } from 'components/TblIconButton';
import TblUserMenu from 'components/TblUserMenu';

import { isGuardian, isStudent } from 'utils/roles';

import { useAuthDataContext } from 'AppRoute/AuthProvider';
import EllaLogo from 'assets/images/ella_logo.svg';
import { Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: theme.navbar.appBar,
		padding: theme.spacing(2.5, 2, 2.5, 1.25),
		boxShadow: `${theme.spacing(0, 0, 0.5, 0)} ${theme.newColors.gray[300]}`,

		zIndex: theme.zIndex.drawer - 1,
	},
	button: {
		margin: theme.spacing(0, 0.5),
		marginRight: theme.spacing(2),
	},
}));
function TblAppBar(props) {
	const { classes } = useStyles();
	const { toggleSidebar, setOpenZella } = props;
	const authContext = useAuthDataContext();
	const { currentUser } = authContext;
	const {
		organization: { organizationName },
	} = currentUser;

	return (
		<Box className={classes.root}>
			<Box display='flex' alignItems='center'>
				<TblIconButton className={classes.button} size='large' onClick={() => toggleSidebar()}>
					<MenuRoundedIcon />
				</TblIconButton>
				<img src={EllaLogo} alt='ella_logo' width={88} height={40} />
				<Typography sx={{ marginLeft: (theme) => theme.spacing(1) }} variant='titleMedium'>
					{organizationName}
				</Typography>
			</Box>
			<Box display='flex' justifyContent='space-between'>
				<ElZella setOpenZella={setOpenZella} />
				{(isStudent(currentUser) || isGuardian(currentUser)) && <ElNotificationBell />}
				<TblUserMenu />
			</Box>
		</Box>
	);
}

TblAppBar.propTypes = {
	toggleSidebar: PropTypes.func,
};

export default TblAppBar;
