import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import TblIcon from 'components/TblIcon';

import clsx from 'clsx';
import { Button, Typography } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { ATTEMPTS } from 'modules/MyTasks/constants';
import PropTypes from 'prop-types';

import GraderActions from '../actions';

import FinalGradeBlock from './FinalGradeBlock';
import StudentAttempts from './StudentAttempts';
import { StyledBadge, useStyles } from './styles';

function GraderRightContent({
	courseId,
	extraCredit,
	activityType,
	studentInfo,
	sectionSelected,
	gradingPeriodSelected,
	currentTermId,
}) {
	const { t } = useTranslation('grader');
	const dispatch = useDispatch();

	const terms = useSelector((state) => state.Grader.basicInfo.terms);

	const isNotReadComments = useSelector((state) => state.Grader.isNotReadComments);
	const toggleComment = useSelector((state) => state.Grader.toggleComment);

	const createdAt = useSelector((state) => state.Grader.graderDetail.createdAt);
	const shadowActivity = useSelector((state) => state.Grader.graderDetail?.shadowActivity);
	const fetchingGradingList = useSelector((state) => state.Grader.fetchingGradingList);
	const attemptType = useSelector((state) => state.Grader.attemptType);
	const gradingAttemptsMethod = useSelector((state) => state.Grader.gradingAttemptsMethod);

	const [isNotReadCommentState, setIsNotReadCommentState] = useState(isNotReadComments);

	const { classes } = useStyles();

	// const objStudent = useMemo(() => {
	//   let _objStudent = (gradingList || []).find((g) => g.studentId === studentSelected);

	//   // if (sectionSelected !== -1) {
	//   //   _objStudent = (gradingList || []).find((g) => g.sectionId === sectionSelected);
	//   // }

	//   return _objStudent;
	// }, [studentSelected, gradingList]);

	useEffect(() => {
		isNotReadComments !== isNotReadCommentState && setIsNotReadCommentState(isNotReadComments);
	}, [isNotReadComments]);

	// useEffect(() => {
	//   if (createdAt && terms?.length) {
	//     const key = activityType === ACTIVITY_CATEGORY.ASSIGNMENT ? 'assignDate' : 'announceDate';
	//     const termIdOfGradeDetail = get(shadowActivity, `${key}.courseDay.gradingPeriod.termId`, null);

	//     const indexTerm = terms.findIndex((term) => term.id === termIdOfGradeDetail);

	//     dispatch(
	//       GraderActions.graderSetState({
	//         isOverTime: isTermOver7Days(terms[indexTerm]),
	//       })
	//     );
	//   }
	// }, [terms, createdAt]);

	useEffect(() => {
		if (studentInfo && !toggleComment) {
			const includeAttempt =
				attemptType === ATTEMPTS.UNLIMITED || activityType === ACTIVITY_CATEGORY.TEST_IN_CLASS
					? {}
					: { includeAttempt: 1 };

			dispatch(
				GraderActions.getGraderComments({
					studentId: studentInfo.studentId,
					submissionId: studentInfo.activitySubmissionId,
					urlParams: { ...includeAttempt },
				}),
			);
			setIsNotReadCommentState(false);
		}

		if (studentInfo) {
			dispatch(
				GraderActions.checkReadGraderComments({
					studentId: studentInfo.studentId,
					submissionId: studentInfo.activitySubmissionId,
				}),
			);
		}
	}, [activityType, attemptType, toggleComment, studentInfo]);

	const handleToggleComment = () => {
		dispatch(
			GraderActions.graderSetState({
				toggleComment: !toggleComment,
			}),
		);
	};

	if (fetchingGradingList) {
		return (
			<Box className={`${classes.rightContentRoot} tbl-scroll`}>
				<Skeleton height={70} />
				<Box mt={-8}>
					<Skeleton height={400} />
				</Box>
			</Box>
		);
	}

	return (
		<Box className={`${classes.rightContentRoot} tbl-scroll`}>
			<Box className={classes.gradingContainer}>
				<Box className={clsx(classes.boxItem)}>
					<FinalGradeBlock
						totalPoint={studentInfo?.totalPoint}
						extraCredit={extraCredit}
						studentProgressId={studentInfo?.activitySubmissionId}
						courseId={courseId}
						activityType={activityType}
						attemptType={attemptType}
						gradingAttemptsMethod={gradingAttemptsMethod}
						sectionSelected={sectionSelected}
						gradingPeriodSelected={gradingPeriodSelected}
						currentTermId={currentTermId}
					/>
				</Box>
				<Box className={classes.attemptList}>
					<StudentAttempts
						studentId={studentInfo?.studentId}
						currentTermId={currentTermId}
						studentProgressId={studentInfo?.activitySubmissionId}
						courseId={courseId}
						activityType={activityType}
						unlimitedAttempt={attemptType === ATTEMPTS.UNLIMITED}
						submissionStatus={studentInfo?.status}
					/>
				</Box>
			</Box>
			<Box className={clsx(classes.commentContainer)}>
				<Button
					className={clsx(classes.toggleCommentBtn, { [classes.selectedCommentBtn]: !toggleComment })}
					variant='outlined'
					startIcon={
						<Box display={'flex'} gap={1}>
							<TblIcon icon='keyboard_double_arrow_left' />
							<StyledBadge variant='dot' invisible={!isNotReadCommentState}>
								<TblIcon icon='forum' />
							</StyledBadge>
						</Box>
					}
					color='primary'
					onClick={handleToggleComment}
				>
					<Typography component={'div'} variant='bodyMediumRegular'>
						{t('comment', { ns: 'myCourses' })}
					</Typography>
				</Button>
			</Box>
		</Box>
	);
}

GraderRightContent.propTypes = {
	activityType: PropTypes.any,
	courseId: PropTypes.any,
	extraCredit: PropTypes.any,
	studentInfo: PropTypes.shape({
		activitySubmissionId: PropTypes.any,
		studentId: PropTypes.any,
		totalPoint: PropTypes.any,
	}),
};

export default React.memo(GraderRightContent);
