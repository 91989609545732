const { makeStyles } = require('tss-react/mui');

const useStyles = makeStyles()((theme, props) => ({
        scrollbar: {
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                backgroundColor: '#BBB',
            }
        }
    })
);

export default useStyles;