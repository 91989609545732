import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import flatten from 'lodash/flatten';
import isNil from 'lodash/isNil';
import size from 'lodash/size';

import { BaseModal, Box, Dropdown, Typography } from 'ella-storybook';
import PropTypes from 'prop-types';

import GraderActions from '../actions';
import ListStudentBySection from '../components/ListStudentBySection';

import { useStyles } from './styles';

const StudentListDialog = ({
	handleCloseModal,
	open,
	studentList,
	studentSelected,
	sectionList,
	sectionSelected,
	handleChangeSection,
}) => {
	const { t } = useTranslation(['grader']);

	const { classes } = useStyles();

	const dispatch = useDispatch();

	const [currentSectionValue, setCurrentSectionValue] = useState({
		label: t('grader:all_sections'),
		value: -1,
		type: null,
	});

	const option = useMemo(
		() =>
			flatten([
				{ label: t('grader:all_sections'), value: -1, type: null },
				(sectionList || []).map((section) => ({ label: section?.sectionName, value: section?.id })),
			]),
		[sectionList],
	);

	const mappingStudentList = useMemo(
		() =>
			studentList.map((student) => ({
				name: student.name,
				overallGrade: student.overallGrade,
				sectionId: student.sectionId,
				studentId: student.studentId,
				studentProgressId: student.activitySubmissionId,
				totalPoint: student.totalPoint,
			})),
		[studentList],
	);

	useEffect(() => {
		if (!isNil(sectionSelected) && size(option)) {
			const sectionValue = option.find((section) => section.value === sectionSelected);
			setCurrentSectionValue(sectionValue);
		}
	}, [sectionSelected, option]);

	const handleChooseStudent = useCallback(
		(student) => (value) => {
			const studentId = student?.studentId ?? null;

			dispatch(
				GraderActions.graderSetState({
					studentSelected: studentId,
				}),
			);
		},
		[],
	);

	const handleCloseDialog = () => {
		dispatch(
			GraderActions.graderSetState({
				openStudentListDialog: false,
			}),
		);
	};

	return (
		<BaseModal
			open={open}
			onClose={handleCloseDialog}
			showFooter={false}
			additionalComponentHeader={
				<Box minHeight={(theme) => theme.spacing(8)} paddingBottom={(theme) => theme.spacing(3)}>
					<Dropdown
						width={'100%'}
						className={classes.select}
						value={currentSectionValue}
						onChange={handleChangeSection}
						options={option}
						disableCloseOnSelect={false}
						disableClearable={true}
					/>
				</Box>
			}
			contentComponent={
				<Box display={'flex'} flexDirection={'column'}>
					<ListStudentBySection {...{ studentList: mappingStudentList, studentSelected, handleChooseStudent }} />
				</Box>
			}
			title={t('grading_list')}
			subTitle={
				<Typography component={'div'} variant='bodyMediumRegular'>
					{t('grading_list_title')}
				</Typography>
			}
		/>
	);
};

StudentListDialog.propTypes = {
	activityId: PropTypes.any,
	activitySelected: PropTypes.any,
	activitySelectedType: PropTypes.any,
	activityType: PropTypes.any,
	currentUser: PropTypes.shape({
		courseId: PropTypes.any,
		organizationId: PropTypes.any,
	}),
	handleChangeSection: PropTypes.func,
	handleCloseModal: PropTypes.any,
	open: PropTypes.any,
	sectionList: PropTypes.array,
	sectionSelected: PropTypes.any,
	studentList: PropTypes.array,
	studentSelected: PropTypes.any,
};

StudentListDialog.defaultProps = {
	studentList: [],
	handleChangeSection: () => {},
};

export default React.memo(StudentListDialog);
