import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Collapse, Dropdown, Radio, TextField } from 'ella-storybook';
import { get, isNil } from 'lodash';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import {
	AMOUNT_ATTEMPT_DEFAULT,
	ATTEMPT_TYPES,
	GRADE_CALCULATION_ATTEMPT,
	SUBMISSION_ATTEMPTS,
	SUBMISSION_ATTEMPTS_TOOLTIP,
} from '../../constants';
import CollapseOptions from '../CollapseOptions';

const AMOUNT_ATTEMPTS = {
	MIN: 1,
	MAX: 100,
};

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: theme.spacing(2),
		borderRadius: theme.spacing(2),
		background: theme.newColors.gray[100],
		marginTop: theme.spacing(3),
	},
	radioSubmissionRequired: {
		marginTop: theme.spacing(3),
	},
	radioSubmissionMethods: {
		marginTop: theme.spacing(3),
	},
	radioPictureSubmission: {
		marginTop: theme.spacing(3),
	},
}));

const inputNumber = {
	maxLength: 3,
	allowNegative: true,
};
function SubmissionAttempts({
	values,
	handleChange,
	disabled,
	attemptsActivity,
	gradingAttemptsMethodActivity,
	attemptsGradingDefault,
	gradingAttemptsMethodDefault,
	gradingLateCompletion,
	isShowTooltip,
}) {
	const { attemptActivityType } = gradingLateCompletion || {};

	const [open, setOpen] = useState(true);
	const [allowAttempt, setAttemptType] = useState(-1);

	useEffect(() => {
		let attemptType = null;
		const attempts = get(values, 'attempts', null);

		if (attempts > 0) {
			attemptType = ATTEMPT_TYPES.LIMITED;
		} else {
			attemptType = ATTEMPT_TYPES.UNLIMITED;
		}

		!isNil(+attemptType) && setAttemptType(attemptType);
	}, [values]);

	const { classes } = useStyles();
	const { t } = useTranslation(['myCourses', 'common']);

	const handleChangeAllowAttempt = (value) => {
		const _value = +value;
		if (_value === ATTEMPT_TYPES.LIMITED) {
			setAttemptType(ATTEMPT_TYPES.LIMITED);
			if (attemptsActivity > 0) {
				handleChange('attempts', attemptsActivity);
				handleChange('gradingAttemptsMethod', gradingAttemptsMethodDefault);
				return;
			}
			if (attemptsGradingDefault > 0) {
				handleChange('attempts', attemptsGradingDefault);
				handleChange('gradingAttemptsMethod', gradingAttemptsMethodDefault);
				return;
			}
			handleChange('attempts', AMOUNT_ATTEMPT_DEFAULT);
			return;
		}
		setAttemptType(ATTEMPT_TYPES.UNLIMITED);
		handleChange('attempts', -1);
		handleChange('gradingAttemptsMethod', null);
	};

	const handleBlurNumberAttempt = (e) => {
		let value = e.target.value === '' ? attemptsActivity || attemptsGradingDefault : e.target.value;
		if (value > AMOUNT_ATTEMPTS.MAX) {
			value = AMOUNT_ATTEMPTS.MAX;
		} else if (value < AMOUNT_ATTEMPTS.MIN) {
			value = AMOUNT_ATTEMPTS.MIN;
		}
		if (value !== null) {
			handleChange('attempts', value);
		}
	};

	const handleChangeNumberAttempt = ({ target }) => {
		if (values?.attempts > 0 && Number(target?.value > 0)) {
			handleChange('attempts', Number(target?.value));
		}
	};

	const handleChangeAttemptMethod = (selected) => {
		handleChange('gradingAttemptsMethod', Number(selected?.value));
	};

	const renderTitle = () => {
		if (attemptActivityType === ACTIVITY_CATEGORY.ASSIGNMENT) {
			return t('submission_attempts');
		}
		return t('test_submission_attempts');
	};

	return (
		<Box className={classes.root}>
			<CollapseOptions open={open} onClose={() => setOpen(!open)} title={renderTitle()}>
				{Number(attemptActivityType) === ACTIVITY_CATEGORY.ASSIGNMENT && (
					<Radio
						row={false}
						className={classes.radioSubmissionRequired}
						label={t('allowed_attempts')}
						options={isShowTooltip ? SUBMISSION_ATTEMPTS_TOOLTIP : SUBMISSION_ATTEMPTS}
						value={allowAttempt}
						disabled={disabled}
						onChange={handleChangeAllowAttempt}
						fullWidth={true}
					/>
				)}
				<Collapse in={allowAttempt === ATTEMPT_TYPES.LIMITED} timeout={0}>
					<TextField
						disabled={disabled}
						type='number'
						name='attempts'
						endAdornment={t('attempts')}
						label={t('number_of_attempt')}
						value={values?.attempts}
						onBlur={handleBlurNumberAttempt}
						onChange={handleChangeNumberAttempt}
						inputProps={inputNumber}
						sx={{ width: 160, mt: 2 }}
					/>
					<Box mt={3}>
						{Number(values?.gradeType) > 0 && (
							<Dropdown
								disabled={disabled}
								name='gradingAttemptsMethod'
								multiple={false}
								disableFilter
								disableCloseOnSelect={false}
								label={t('automated_final_grade_calculation')}
								width={'100%'}
								value={
									GRADE_CALCULATION_ATTEMPT.find(({ value }) => values?.gradingAttemptsMethod === value) ??
									GRADE_CALCULATION_ATTEMPT.find(({ value }) => gradingAttemptsMethodActivity === value) ??
									GRADE_CALCULATION_ATTEMPT.find(({ value }) => gradingAttemptsMethodDefault === value) ??
									GRADE_CALCULATION_ATTEMPT[0]
								}
								options={GRADE_CALCULATION_ATTEMPT}
								disableClearable
								onChange={handleChangeAttemptMethod}
								// sx={{ mx: 2 }}
							/>
						)}
					</Box>
				</Collapse>
			</CollapseOptions>
		</Box>
	);
}

SubmissionAttempts.propTypes = {
	method: PropTypes.number,
	submissionRequired: PropTypes.bool,
	handleChange: PropTypes.func,
	values: PropTypes.object,
	attemptsActivity: PropTypes.number,
	gradingAttemptsMethodActivity: PropTypes.number,
	attemptsGradingDefault: PropTypes.number,
	gradingAttemptsMethodDefault: PropTypes.number,
	gradingLateCompletion: PropTypes.object,
};

export default SubmissionAttempts;
