import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	previewFileRoot: {
		width: '100%',
		height: theme.spacing(7),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: 'white',
		overflow: 'hidden',
		'& .MuiIconButton-root.Mui-disabled': {
			backgroundColor: theme.newColors.gray[50],
		},
	},
	iconBtn: {
		backgroundColor: theme.newColors.gray[50],
		width: theme.spacing(5),
		height: theme.spacing(5),
		color: theme.newColors.gray[800],
		fontSize: theme.spacing(2.5),
		borderRadius: theme.spacing(5),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fileName: {
		marginLeft: theme.spacing(2),
		color: theme.newColors.gray[900],
		fontSize: theme.fontSize['text-sm'],
		fontWeight: theme.fontWeight.normal,
		maxWidth: theme.spacing(40),
	},
	fileCount: {
		color: theme.newColors.gray[800],
		fontSize: theme.fontSize['text-sm'],
		fontWeight: theme.fontWeight.normal,
		marginRight: theme.spacing(2),
	},
}));

export { useStyles };
