/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

import TblActivityIcon from 'components/TblActivityIcon/icon';
import TblIcon from 'components/TblIcon';
import TblTooltipDynamic from 'components/TblTooltipDynamic';

import clsx from 'clsx';
import { Box, Divider, Skeleton, Typography } from 'ella-storybook';
import { isEmpty, isEqual, size } from 'lodash';
import { ACTIVITY_CATEGORY_NAME } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';

import Delayed from './DelayedComponent';
import Loading from './Loading';
import { StyledActivityCard } from './styled';

const ListActivities = ({ getActivitiesRescheduleCourseLoading, activities, onSelectActivity }) => {
	const { t } = useTranslation('myCourses');

	return getActivitiesRescheduleCourseLoading ? (
		<Loading title={t('please_wait_while_process_data', { ns: 'common' })} />
	) : (
		<>
			{getActivitiesRescheduleCourseLoading ? (
				<Box>
					<Box>
						<Skeleton variant='circular' width={26} height={26} />
					</Box>
					<Typography
						component={'div'}
						variant='bodyMediumRegular'
						sx={{ color: (theme) => theme.newColors.gray[800] }}
					>
						{t('  ', { ns: 'common' })}
					</Typography>
				</Box>
			) : (
				<PerfectScrollbar style={{ maxHeight: 'calc(100% - 64px)', padding: '0 8px' }}>
					{(activities || []).map((item, index) => {
						if (isEmpty(item.activities)) return <></>;

						return (
							<Box
								display={'flex'}
								flexDirection={'column'}
								marginBottom={(theme) => (index < size(activities) - 1 ? theme.spacing(3) : 0)}
							>
								<Typography
									component={'div'}
									variant='labelMedium'
									sx={{
										color: (theme) => theme.newColors.gray[500],
										marginBottom: (theme) => theme.spacing(1),
										padding: (theme) => `0 ${theme.spacing(1)}`,
									}}
								>
									{t(ACTIVITY_CATEGORY_NAME[item?.type])}
								</Typography>
								{(item.activities || []).map((activity, idx) => (
									<Delayed waitBeforeShow={idx}>
										<Box>
											{idx > 0 && <Divider />}
											<StyledActivityCard
												onClick={() =>
													onSelectActivity && onSelectActivity({ ...activity, activityType: item?.type }, item)
												}
											>
												<Box
													display={'flex'}
													flexDirection={'row'}
													alignItems={'center'}
													gap={1}
													maxWidth={'calc( 100% - 48px )'}
												>
													<TblActivityIcon type={item?.type} sx={{ display: 'block' }} />

													<Typography
														variant='bodyMediumRegular'
														component={'div'}
														className={clsx('text-ellipsis')}
														sx={{ color: (theme) => theme.newColors.gray[800], flex: '1 1 auto' }}
													>
														<TblTooltipDynamic title={activity.activityName}>
															<>{activity.activityName}</>
														</TblTooltipDynamic>
													</Typography>
												</Box>

												{activity?.hasUpdated ? (
													<Box display={'flex'}>
														<CheckCircleSharpIcon
															sx={{
																color: (theme) => theme.mainColors.green[0],
																fontSize: (theme) => theme.spacing(3),
															}}
														/>
													</Box>
												) : (
													<TblIcon style={{ display: 'block' }} icon='chevron_right' fontSize={'24px'} />
												)}
											</StyledActivityCard>
										</Box>
									</Delayed>
								))}
							</Box>
						);
					})}
				</PerfectScrollbar>
			)}
		</>
	);
};

ListActivities.propTypes = {
	activities: PropTypes.array,
	amountActivities: PropTypes.number,
	getActivitiesRescheduleCourseLoading: PropTypes.bool,
	onClickBack: PropTypes.func,
	onSelectActivity: PropTypes.func,
	selectedCourse: PropTypes.shape({
		courseImageUrl: PropTypes.any,
		courseName: PropTypes.any,
	}),
};

ListActivities.defaultProps = {
	amountActivities: 0,
	getActivitiesRescheduleCourseLoading: false,
	activities: [],
	selectedCourse: null,
};

export default memo(ListActivities, (prev, curr) => {
	// const keys = Object.keys(prev);

	// keys.forEach((k, index) => {
	// 	if (!isEqual(curr[k], prev[k])) {
	// 		console.log('not equal' + index, k, curr[k], prev[k]);
	// 	}
	// });
	return isEqual(prev, curr);
});
