import loadable from 'utils/loadable';

const Sample = loadable(() => import('./sample'));
const Demo = loadable(() => import('./demo-calendar'));

export default [
	{
		path: '/ui-components',
		component: Sample,
		exact: true,
	},
	{
		path: '/calendar-demo',
		component: Demo,
		exact: true,
	},
];
