import myCoursesActions from 'modules/MyCourses/actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../../utils/ajax';
import gradeWeightingAction from '../actionConstants/gradeWeightingActionsV2';
import END_POINT from '../endPoints/gradeWeightingEndPointsV2';

const getGradeCalculationEpic = (action$) =>
	action$.pipe(
		ofType(gradeWeightingAction.MC_GET_GRADE_CALCULATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_get_grade_calculation.method,
				END_POINT.mc_get_grade_calculation.url(action.payload.orgId, action.payload.courseId, action.payload.urlParams),
			).pipe(
				mergeMap((data) =>
					of(
						myCoursesActions.mcGetGradeCalculationSuccess({
							gradeCalculation: data.response?.data,
							mcGetGradeCalculationSuccess: true,
						}),
						myCoursesActions.getCourseValidation({ orgId: action.payload.orgId, courseId: action.payload.courseId }),
					),
				),
				catchError((error) =>
					of({ type: 'GLOBAL_ERROR', payload: { error } }, myCoursesActions.mcGetGradeCalculationFailed()),
				),
			),
		),
	);

const updateGradeCalculationEpic = (action$) =>
	action$.pipe(
		ofType(gradeWeightingAction.MC_UPDATE_GRADE_CALCULATION),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.mc_update_grade_calculation.method,
				END_POINT.mc_update_grade_calculation.url(action.payload.orgId, action.payload.courseId),
				{ ...action.payload.submitGrade },
			).pipe(
				mergeMap(() =>
					of(
						myCoursesActions.mcUpdateGradeCalculationSuccess({
							updateGradeCalculationSuccess: true,
						}),
						myCoursesActions.mcGetGradeCalculation({
							orgId: action.payload.orgId,
							courseId: action.payload.courseId,
						}),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myCoursesActions.mcUpdateGradeCalculationFailed({
							updateGradeCalculationFailed: true,
							error: error.response.errors,
						}),
					),
				),
			),
		),
	);

export default [getGradeCalculationEpic, updateGradeCalculationEpic];
