import { memo, useEffect, useState } from 'react';

import { Tab, Tabs } from '@mui/material';

import { Box, Typography } from 'ella-storybook';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import PropTypes from 'prop-types';

import ActionBar from './ActionBar';
import { TABS, TEST_TABS } from './constants';
import StackChipNavigator from './StackChipNavigator';

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const TabWrapper = ({
	translate,
	editLabel = '',
	sections,
	selectedSection,
	selectedChip,
	searchValueInput,
	showTabs,
	activityType,
	queryParams,
	handleSearch = () => {},
	handleSelectTab = () => {},
	handleSelectChip = () => {},
	handleShowActivityModal = () => {},
	handleShowScheduleModal = () => {},
	handleChangeQuery = () => {},
	handleSelectSection = () => {},
}) => {
	const [index, setIndex] = useState(null);
	const [testIndex, setTestIndex] = useState(null);

	const tab = queryParams.get('tab');
	const defaultSectionId = queryParams.get('defaultSectionId');
	const testType = queryParams.get('type');

	const isTestStudentWork =
		[ACTIVITY_CATEGORY.TEST, ACTIVITY_CATEGORY.TEST_IN_CLASS].includes(activityType) && TABS[tab] === TABS.student;

	useEffect(() => {
		handleSelectTab(index);
	}, [index]);

	useEffect(() => {
		handleSelectChip(testIndex);
	}, [testIndex]);

	if (typeof index !== 'number' && TABS[tab] in TABS) {
		setIndex(TABS[tab]);
	}

	if (!tab && typeof index !== 'number') {
		setIndex(TABS.instruction);
	}

	if (!defaultSectionId) {
		handleChangeQuery(['defaultSectionId'], ['-1']);
	}

	if (isTestStudentWork && typeof testIndex !== 'number' && TEST_TABS[testType] in TEST_TABS) {
		setTestIndex(TEST_TABS[testType]);
	}

	if (isTestStudentWork && !testType && typeof testIndex !== 'number') {
		setTestIndex(TEST_TABS.test);
	}

	useEffect(() => {
		if (TABS.instruction === index && tab !== TABS[index.toString()]) {
			handleChangeQuery('tab', 'instruction');
			handleChangeQuery('type', null);
			return;
		}

		if (TABS.student === index && tab !== TABS[index.toString()]) {
			handleChangeQuery('tab', 'student');
			return;
		}
	}, [index]);

	useEffect(() => {
		/*prettier-ignore */
		if (
			[TEST_TABS.test, TEST_TABS.studyForTest].includes(testIndex)
				&& testType !== TEST_TABS[testIndex.toString()]
		) {
			handleChangeQuery('type' ,TEST_TABS[testIndex.toString()]);
			return;
		}
	}, [testIndex, index]);

	useEffect(() => {
		if (TABS[tab] in TABS && isFinite(TABS[tab])) {
			handleChangeTab({}, TABS[tab]);
		}
	}, [tab]);

	const hasFilter = TABS[tab] === TABS.student;

	const handleChangeTab = (event, newValue) => {
		if (index !== newValue) {
			setIndex(newValue);
		}
	};
	const handleChangeChip = (event, newValue) => {
		setTestIndex(newValue);
	};

	const handleEdit = () => {
		if (TABS[tab] === TABS.instruction) {
			handleShowActivityModal();
		}

		if (TABS[tab] === TABS.student) {
			handleShowScheduleModal();
		}
	};

	const arrItemNavigator = [
		{ label: translate('myCourses:test'), color: 'secondary', handleChangeChip },
		{ label: translate('myCourses:study_for_test'), color: 'secondary', handleChangeChip },
	];

	return (
		<Box>
			<Tabs
				value={index}
				onChange={handleChangeTab}
				aria-label='activity detail tabs'
				textColor='secondary'
				indicatorColor='secondary'
				sx={{
					display: showTabs ? 'block' : 'none',
					maxHeight: '40px',
					minHeight: '40px',
					'& .MuiTab-textColorSecondary': { color: (theme) => theme.newColors.gray[400] },
					'& .Mui-selected': { color: (theme) => theme.newColors.primary[500] },
					'& .MuiButtonBase-root': { minHeight: (theme) => theme.spacing(5), padding: '8px 16px' },
					'& .MuiTabs-flexContainer': {
						maxHeight: (theme) => theme.spacing(5),
						borderBottom: (theme) => `2px solid ${theme.newColors.gray[100]}`,
					},
				}}
			>
				<Tab
					label={<Typography variant={'labelLarge'}>{translate('myCourses:instruction')}</Typography>}
					{...a11yProps(0)}
				/>
				<Tab
					label={<Typography variant={'labelLarge'}>{translate('myCourses:student_work')}</Typography>}
					{...a11yProps(1)}
				/>
			</Tabs>

			{
				/* prettier-ignore */
				[ACTIVITY_CATEGORY.TEST, ACTIVITY_CATEGORY.TEST_IN_CLASS].includes(activityType) &&
					TABS[tab] === TABS.student &&
						<StackChipNavigator 
							arrItem={arrItemNavigator} 
							selectedChip={selectedChip} 
							sx={{marginY: (theme) => theme.spacing(2)}}
						/>
			}

			<Box
				width={'100%'}
				marginY={1.5}
				minHeight={(theme) => theme.spacing(4)}
				maxHeight={(theme) => theme.spacing(5)}
				display={'flex'}
				justifyContent={'space-between'}
			>
				<ActionBar
					{...{
						activityType,
						translate,
						editLabel,
						sections,
						selectedSection,
						hasFilter,
						searchValueInput,
						handleEdit,
						handleSelectSection,
						handleSearch,
					}}
				/>
			</Box>
		</Box>
	);
};

TabWrapper.propTypes = {
	editLabel: PropTypes.string,
	handleChangeQuery: PropTypes.func,
	handleSearch: PropTypes.func,
	handleSelectSection: PropTypes.func,
	handleSelectTab: PropTypes.func,
	handleShowActivityModal: PropTypes.func,
	handleShowScheduleModal: PropTypes.func,
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	searchValueInput: PropTypes.any,
	sections: PropTypes.any,
	selectedSection: PropTypes.any,
	showTabs: PropTypes.any,
	translate: PropTypes.func,
};

export default memo(TabWrapper);
