import { useTranslation } from 'react-i18next';

import { ReactComponent as ZellaIcon } from 'assets/images/zella_icon.svg';
import { Box, Button, Typography } from 'ella-storybook';

const ElZella = ({ setOpenZella }) => {
	const { t } = useTranslation('user');

	return (
		<Box>
			<Button
				variant='text'
				startIcon={<ZellaIcon />}
				onClick={() => setOpenZella(true)}
				sx={{
					gap: '8px',
					'.MuiButton-startIcon': {
						svg: {
							width: '43px',
						},
					},
				}}
			>
				<Typography variant='bodyLargeBold' sx={{ color: (theme) => theme.newColors.primary[500] }}>
					{t('zella')}
				</Typography>
			</Button>
		</Box>
	);
};

export default ElZella;
