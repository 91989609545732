import createReducers from 'utils/createReducers';

import { initialState as taskInitState } from 'modules/MyTasks/reducers';

import { actions } from './constants';
export const initialState = {
	...taskInitState,
	gradeBoostData: [],
	schoolPlan: [],
	isUpdateStudentGradeBoostActivitySuccess: null,
	dataInfoPotential: {},
	numOfAvailableCourses: 0,
	selectedCourses: 0
};

export default createReducers(initialState, actions);
