import { useCallback, useEffect, useState } from 'react';

import { CorsWorker as Worker } from 'workers/CorsWorker';

export default function useWebWorkers() {
	const [worker, setWorker] = useState(null);
	const [event, setEvent] = useState({
		type: null,
		data: null,
	});
	const send = useCallback(
		(data) => {
			worker.postMessage(data);
		},
		[worker],
	);

	useEffect(() => {
		const initWorker = new Worker(new URL('workers/upload.js', import.meta.url)).getWorker();

		setWorker(initWorker);

		initWorker.onmessage = (e) => {
			const { event, ...rest } = e.data;
			setEvent({
				type: event,
				data: rest,
			});
		};

		return () => {
			if (initWorker) {
				initWorker.terminate();
			}
		};
	}, []);

	return [event, send];
}
