import React from 'react';

import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	image: {
		width: '100%',
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
	},
	label: {
		fontSize: theme.fontSize['text-2xl'],
		fontWeight: theme.fontWeight.semi,
		color: theme.newColors.gray[800],
		marginBottom: theme.spacing(2),
	},
	content: {
		fontSize: theme.fontSize['text-base'],
		fontWeight: theme.fontWeight.normal,
		wordBreak: 'break-word',
		color: theme.newColors.gray[600],
	},
}));

function TourContent(props) {
	const { label, content, image } = props;
	const { classes } = useStyles();
	return (
		<Box className={classes.root}>
			{image && (
				<Box marginX={-2} marginTop={-2}>
					<img className={classes.image} src={image} alt='' />
				</Box>
			)}
			<div className={classes.label}>{label}</div>
			<div className={classes.content}>{content}</div>
		</Box>
	);
}

TourContent.propTypes = {
	content: PropTypes.string,
	image: PropTypes.element,
	label: PropTypes.string,
};

export default TourContent;
