import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import size from 'lodash/size';

import { useTheme } from '@mui/material/styles';

import BackgroundOtp from 'shared/Auth/components/BackgroundOtp';

import { isStudent } from 'utils/roles';

import { USER_BEHAVIOR } from 'shared/User/constants';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import { Box, Button, Dropdown, Typography } from 'ella-storybook';
import { useFormik } from 'formik';
import { useLayoutContext } from 'layout/LayoutContext';
import { ROUTE_TASKS } from 'modules/MyTasks/constantsRoute';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { makeStyles } from 'tss-react/mui';

import myProfileActions from '../actions';
import { bedtimeArray, wakeUpArray } from '../utils';

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 64px)',
		width: '100vw',
	},
	containerForm: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '480px',
	},
	containerCode: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	inputOtp: {
		border: 'none',
		borderBottom: '2px solid #D1D5DB',
		maxWidth: '36px',
		borderRadius: '0',
		color: theme.newColors.primary[500],
		'& fieldset': { border: 'none' },
		'&:focus-within fieldset, &:focus-visible fieldset': {
			border: '0px solid red!important',
			boxShadow: 'none !important',
		},
		'&.MuiInputBase-root': {
			...theme.typography.headlineMedium,
			'& .MuiInputBase-input': {
				padding: '0',
			},
		},
		padding: '8px',
		margin: '0 4px',
	},
	buttonContinue: {
		backgroundColor: theme.newColors.primary[500],
		color: theme.newColors.white,
		width: '100%',
	},
	inlineError: {
		textAlign: 'center',
		width: '100%',
	},
	skipText: {
		textAlign: 'center',
		width: '100%',
		cursor: 'pointer',
	},
}));

const OneMoreStep = (props) => {
	const { isOnBoarding } = props;
	const { classes } = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation('myProfile');
	const authContext = useContext(AuthDataContext);
	const layoutContext = useLayoutContext();

	const bedTimeInReducer = useSelector((state) => state.Auth.currentUser.bedTime);
	const wakeupTimeInReducer = useSelector((state) => state.Auth.currentUser.wakeupTime);
	const isChangeProfileSuccess = useSelector((state) => state.MyProfile.isChangeProfileSuccess);

	const currentUser = authContext.currentUser;
	const { settings } = currentUser;

	const handleSubmitRoutine = (values) => {
		const { bedTime, wakeupTime } = values;

		if (!isOnBoarding) return;

		const keys = Object.keys(settings);
		const _settings = { [keys[0]]: [...settings.behavior, USER_BEHAVIOR.HAVE_SET_UP_PROFILE] };

		const payload = {
			settings: _settings,
		};
		if (isStudent(currentUser)) {
			if (!(bedTime?.value || wakeupTime?.value)) return;

			const bedTimeData = {
				bedTime: bedTime?.value,
				wakeupTime: wakeupTime?.value,
			};
			Object.assign(payload, bedTimeData);
		}

		dispatch(myProfileActions.updateMyProfile(payload));
	};

	const formik = useFormik({
		initialValues: {
			bedTime: bedtimeArray[2],
			wakeupTime: wakeUpArray[0],
		},
		onSubmit: handleSubmitRoutine,
	});

	const {
		values,
		setFieldValue,
		handleSubmit,
		// setFieldTouched,
	} = formik;

	const { bedTime, wakeupTime } = values;

	useEffect(() => {
		layoutContext.setFullScreen(true);

		const bedTimeReducerIndex = bedtimeArray.findIndex((item) =>
			moment(item.value, 'hh:mm a').isSame(moment(bedTimeInReducer, 'HH:mm:ss'), 'minute'),
		);
		const wakeupTimeReducerIndex = wakeUpArray.findIndex((item) =>
			moment(item.value, 'hh:mm a').isSame(moment(wakeupTimeInReducer, 'HH:mm:ss'), 'minute'),
		);

		if (Number(bedTime) !== bedTimeReducerIndex && bedTimeReducerIndex !== -1) {
			setFieldValue('bedTime', bedtimeArray[bedTimeReducerIndex]);
		}
		if (wakeupTimeReducerIndex !== -1) {
			setFieldValue('wakeupTime', wakeUpArray[wakeupTimeReducerIndex]);
		}
	}, []);

	useEffect(() => {
		if (isChangeProfileSuccess && isOnBoarding) {
			const currentHostname = window.location.hostname;

			const fullUrl = `${window.location.protocol}//${currentHostname}:${window.location.port}${ROUTE_TASKS.DEFAULT}`;

			window.location.replace(fullUrl);
		}
	}, [isChangeProfileSuccess, isOnBoarding]);

	const handleChangeWakeup = (data) => {
		size(data) && setFieldValue('wakeupTime', data);
	};

	const handleChangeBedTime = (data) => {
		size(data) && setFieldValue('bedTime', data);
	};

	return (
		<div className={classes.container}>
			<Box className={classes.containerForm}>
				<Box>
					<Typography variant='headlineSmall'>{t('one_more_step')}</Typography>
				</Box>
				<Box mb={1}>
					<Typography variant='bodyMediumRegular'>{t('bed_time_instruction')}</Typography>
				</Box>
				<Box>
					<Typography variant='bodyMediumRegular'>{t('can_change_anytime')}</Typography>
				</Box>
				<Box mt={4} mb={4} display='flex' flexDirection={'column'}>
					<Box mb={2}>
						<Dropdown
							label={t('bedtime')}
							width='100%'
							value={bedTime}
							onChange={handleChangeBedTime}
							options={bedtimeArray}
						/>
					</Box>
					<Box>
						<Dropdown
							label={t('wake_up')}
							width='100%'
							value={wakeupTime}
							onChange={handleChangeWakeup}
							options={wakeUpArray}
						/>
					</Box>
				</Box>

				<Button className={classes.btn} onClick={handleSubmit} size='large' variant='contained'>
					{t('we_are_all_set')}
				</Button>
			</Box>

			<BackgroundOtp />
		</div>
	);
};
OneMoreStep.defaultProps = { isOnBoarding: false };

export default OneMoreStep;
