import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';

import useStopwatch from 'utils/customHook/useStopWatch/useStopwatch';

import { COUNTDOWN_RESEND } from 'modules/MyProfile/constants';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const { Typography, Box, Button, TextField, Dialog } = require('ella-storybook');

const useStyles = makeStyles()((theme) => ({
	resendText: {
		textAlign: 'center',
		width: '100%',
		cursor: 'pointer',
	},
}));

const CountdownResend = (props) => {
	const { onClick, countdownTime } = props;
	const { classes } = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('myProfile');

	const [enableResend, setEnableResend] = useState(false);

	const { seconds, minutes, isRunning, reset } = useStopwatch({
		autoStart: true,
		currentTime: Number(countdownTime), //initial time in second
		isCountDown: true,
	});

	useEffect(() => {
		if (!isRunning) {
			setEnableResend(true);
		}
	}, [isRunning]);

	const resendCode = () => {
		setEnableResend(false);
		reset(Number(countdownTime), true, true);
		onClick();
	};

	return (
		<div>
			{enableResend ? (
				<Box className={classes.resendText} onClick={resendCode}>
					<Typography variant={'button'} color={theme.newColors.primary[500]}>
						{t('resend_code')}
					</Typography>
				</Box>
			) : (
				<Box className={classes.resendText}>
					<Typography variant={'button'} color={theme.newColors.gray[400]}>
						{t('resend_code_in')} {minutes}:{seconds}
						{'s'}
					</Typography>
				</Box>
			)}
		</div>
	);
};
CountdownResend.defaultProps = {
	countdownTime: COUNTDOWN_RESEND,
};

CountdownResend.propTypes = {
	onClick: PropTypes.func,
	countdownTime: PropTypes.number,
};

export default CountdownResend;
