import authActions from 'shared/Auth/actions';
import { TYPE_UPDATE_PROFILE } from 'shared/User/constants';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { makeAjaxRequest } from '../../utils/ajax';

import myProfileActions from './actions';
import { END_POINT, OTP_CODE, actions } from './constants';
import { checkPhoneNumber } from './utils';

const updateProfileEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_MY_PROFILE),
		mergeMap((action) => {
			const { phone } = action.payload;
			if (phone && phone !== '') {
				action.payload.phone = checkPhoneNumber(phone);
			}
			return makeAjaxRequest(END_POINT.update_my_profile.method, END_POINT.update_my_profile.url, action.payload).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myProfileActions.updateMyProfileFailed({ isBusy: false, otpSubCode: data.response.errors?.subcode }),
						);
					}

					if (action.payload.sendOtp) {
						return of(
							myProfileActions.updateMyProfileSuccess({
								error: undefined,
								isBusy: false,
								isChangeProfileSuccess: action.payload.isChangeProfileSuccess ?? true,
							}),
							authActions.authFetchUser(),
							myProfileActions.sendOtp({
								orgId: action.payload.organizationId,
								tool: 'phone',
								phone: data.response?.userProfile?.phone || phone,
								verifyOtpSuccess: false,
							}),
						);
					}
					return of(
						myProfileActions.updateMyProfileSuccess({
							error: undefined,
							isBusy: false,
							isChangeProfileSuccess: action.payload?.type === TYPE_UPDATE_PROFILE.TEACHER_ON_BOARDING ? null : true,
						}),
						authActions.authFetchUser({
							keepPermissions: action.payload?.keepPermissions
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myProfileActions.updateMyProfileFailed({
							error: error?.response?.errors?.subcode === OTP_CODE.PHONE_EXIST ? null : error?.response?.errors,
							isBusy: false,
							otpSubCodeSend: error?.response?.errors?.subcode,
						}),
					),
				),
			);
		}),
	);

const changePasswordEpic = (action$) =>
	action$.pipe(
		ofType(actions.CHANGE_PASSWORD),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.change_password.method, END_POINT.change_password.url, action.payload).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(myProfileActions.changePasswordFailed({ isBusy: false }));
					}
					return of(
						myProfileActions.changePasswordSuccess({
							error: undefined,
							isBusy: false,
							isChangePassWordSuccess: true,
						}),
					);
				}),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myProfileActions.changePasswordFailed({
							error: error?.response?.errors,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const updateBedtimePreferenceEpic = (action$) =>
	action$.pipe(
		ofType(actions.UPDATE_BEDTIME_PREFERENCE),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.update_my_profile.method, END_POINT.update_my_profile.url, action.payload).pipe(
				mergeMap(() =>
					of(
						myProfileActions.updateBedtimePreferenceSuccess({
							error: undefined,
							isBusy: false,
							isChangeBedtimePreferenceSuccess: true,
						}),
						authActions.authFetchUser(),
					),
				),
				catchError((error) =>
					of(
						{ type: 'GLOBAL_ERROR', payload: { error } },
						myProfileActions.updateBedtimePreferenceFailed({
							error: error?.response?.errors,
							isBusy: false,
						}),
					),
				),
			),
		),
	);

const sendOtpEpic = (action$) =>
	action$.pipe(
		ofType(actions.SEND_OTP),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.send_otp.method, END_POINT.send_otp.url(action.payload.orgId), action.payload).pipe(
				mergeMap((data) => {
					if (data.response.errors) {
						return of(
							myProfileActions.sendOtpFailed({
								errorOtp: data?.response?.errors,
								otpSubCode: data?.response?.errors?.subcode,
							}),
						);
					}

					return of(
						myProfileActions.sendOtpSuccess({
							errorOtp: undefined,
							otpSubCode: data?.response?.subcode,
						}),
					);
				}),
				catchError((error) =>
					of(
						myProfileActions.sendOtpFailed({
							errorOtp: error?.response?.errors,
							otpSubCode: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const resendOtpEpic = (action$) =>
	action$.pipe(
		ofType(actions.RESEND_OTP),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.resend_otp.method, END_POINT.resend_otp.url(action.payload.orgId), action.payload).pipe(
				mergeMap((data) =>
					of(
						myProfileActions.resendOtpSuccess({
							errorOtp: undefined,
							otpSubCode: data?.response?.subcode,
						}),
					),
				),
				catchError((error) =>
					of(
						myProfileActions.resendOtpFailed({
							errorOtp: error?.response?.errors,
							otpSubCode: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

const verifyOtpEpic = (action$) =>
	action$.pipe(
		ofType(actions.VERIFY_OTP),
		mergeMap((action) =>
			makeAjaxRequest(END_POINT.verify_otp.method, END_POINT.verify_otp.url(action.payload.orgId), action.payload).pipe(
				mergeMap((data) =>
					of(
						myProfileActions.verifyOtpSuccess({
							errorOtp: undefined,
							otpSubCode: data?.response?.subcode,
							verifyOtpSuccess: true,
						}),
						authActions.authFetchUser(),
					),
				),
				catchError((error) =>
					of(
						myProfileActions.verifyOtpFailed({
							errorOtp: error?.response?.errors,
							otpSubCode: error?.response?.errors?.subcode,
						}),
					),
				),
			),
		),
	);

export default [
	updateProfileEpic,
	changePasswordEpic,
	updateBedtimePreferenceEpic,
	sendOtpEpic,
	resendOtpEpic,
	verifyOtpEpic,
];
