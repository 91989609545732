import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// import { AuthDataContext } from 'AppRoute/AuthProvider';

import { get } from 'lodash';
import { ACTIVITY_CATEGORY } from 'modules/MyCourses/constants';
import { ROUTE_MY_COURSES } from 'modules/MyCourses/constantsRoute';
import PropTypes from 'prop-types';

import GraderActions from '../actions';
import GraderNavBar from '../components/Navbar/GraderNavBar';

function GeneralBar({
	courseId,
	onCloseDialog,
	schoolYearSelected,
	activitySelected,
	sectionSelected,
	studentSelected,
	filtersSelected,
	gradingList,
	basicInfo,
	sectionList,
	activityName,
	isExtraCredit,
	activityType,
	activityId,
	unitInfo,
	studyForTestWithTestId,
}) {
	const { t } = useTranslation('grader');

	const [studentInfo, setStudentInfo] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!studentSelected) return;
		setStudentInfo(gradingList.find((item) => item.studentId === studentSelected));
	}, [studentSelected, gradingList]);

	const currentStudentIndex = useMemo(
		() => (gradingList || []).findIndex((student) => student.studentId === studentSelected),
		[studentSelected, gradingList],
	);

	const totalStudent = useMemo(() => (gradingList || []).length, [gradingList]);

	const handlePreviousStudentSelect = useCallback(() => {
		if (currentStudentIndex === -1 || currentStudentIndex === 0) return;

		dispatch(
			GraderActions.graderSetState({
				studentSelected: (gradingList || [])?.[currentStudentIndex - 1]?.studentId ?? null,
			}),
		);
	}, [gradingList, currentStudentIndex]);

	const handleNextStudentSelect = useCallback(() => {
		if (currentStudentIndex === -1 || currentStudentIndex === totalStudent - 1) return;

		dispatch(
			GraderActions.graderSetState({
				studentSelected: (gradingList || [])?.[currentStudentIndex + 1]?.studentId ?? null,
			}),
		);
	}, [gradingList, currentStudentIndex]);

	const handleRedirectToNewTab = useCallback(() => {
		const path = ROUTE_MY_COURSES.MY_COURSES_ACTIVITY_DETAIL_PAGE_QUERY(
			courseId,
			get(unitInfo, 'id', ''),
			activityType === ACTIVITY_CATEGORY.TEST ? studyForTestWithTestId : activityId,
			[ACTIVITY_CATEGORY.TEST, ACTIVITY_CATEGORY.TEST_IN_CLASS].includes(activityType)
				? ACTIVITY_CATEGORY.TEST
				: activityType,
		);

		const query = `?defaultSectionId=${sectionSelected}`;

		const url = window.open(`${window.location.origin}${path}${query}`);
		// const url = `${ROUTE_MY_COURSES.MY_COURSES_DETAIL(courseId)}?activityId=${activityId}&activityType=${activityType}`;
		return window.open(url);
	}, [activityType, activityId, courseId, unitInfo, sectionSelected]);

	return (
		<GraderNavBar
			{...{
				onCloseDialog,
				schoolYearSelected,
				activitySelected,
				sectionSelected,
				// sectionInfo,
				studentInfo,
				basicInfo,
				activityName,
				isExtraCredit,
				sectionList,
				totalStudent,
				currentStudentIndex,
				handlePreviousStudentSelect,
				handleNextStudentSelect,
				handleRedirectToNewTab,
			}}
		/>
	);
}

GeneralBar.propTypes = {
	activityName: PropTypes.any,
	activitySelected: PropTypes.any,
	basicInfo: PropTypes.any,
	courseId: PropTypes.any,
	filtersSelected: PropTypes.array,
	gradingList: PropTypes.array,
	isExtraCredit: PropTypes.any,
	onCloseDialog: PropTypes.func,
	schoolYearSelected: PropTypes.any,
	sectionList: PropTypes.any,
	sectionSelected: PropTypes.any,
	studentSelected: PropTypes.any,
};

export default React.memo(GeneralBar);
