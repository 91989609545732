import { styled } from '@mui/material';

import { Badge } from 'ella-storybook';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		backgroundColor: theme.newColors.gray[50],
	},
	content: {
		margin: 'auto',
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(2.25),
		paddingTop: theme.spacing(2.25),
		height: 'calc( 100vh - 80px )',
		gap: theme.spacing(2),
	},
	rightContent: {
		maxHeight: theme.spacing(103.875),
		overflowX: 'hidden',
		overflowY: 'auto',
		paddingBottom: theme.spacing(10),
		'&:hover::-webkit-scrollbar': {
			width: theme.spacing(0.75),
		},
	},
	leftContentRoot: {
		width: '100%',
		// height: '100%',
		height: '100%',
		// maxHeight: theme.spacing(104),
		overflow: 'hidden',
		border: '1px solid #E9ECEF',
		borderRadius: theme.borderRadius.default,
	},
	previewFileContainer: {
		width: '100%',
		height: `calc(100% - ${theme.spacing(7)})`,
	},
	rightContentRoot: {
		display: 'flex',
		flexDirection: 'column',
		width: 'calc(100%-24px)',
		height: '100%',
		paddingBottom: theme.spacing(10),

		justifyContent: 'space-between',
		'& .title': {
			color: theme.newColors.gray[800],
			fontWeight: theme.fontWeight.semi,
			fontSize: theme.fontSize['text-xs'],
		},
	},
	gradingContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
		width: '100%',
		overflow: 'hidden',
		maxHeight: 'calc( 100% - 64px )',
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		width: theme.spacing(30),
		height: theme.spacing(0.25),
		backgroundColor: theme.newColors.gray[200],
	},
	boxItem: {
		padding: theme.spacing(2),
		backgroundColor: 'white',
		border: '1px solid #E9ECEF',
		borderRadius: theme.spacing(2),
		flex: '1 1 auto',
	},
	emptyText: {
		color: theme.newColors.gray[800],
		fontSize: theme.fontSize['text-sm'],
		fontWeight: theme.fontWeight.semi,
	},
	hide: {
		display: 'none',
	},
	toggleCommentBtn: {
		'& .MuiButton-startIcon': {
			margin: 0,
		},
		'&:hover': {
			backgroundColor: theme.newColors.gray[100],
			border: `1.5px solid ${theme.newColors.gray[200]}`,
		},
		width: '100%',
		justifyContent: 'flex-start',
		gap: theme.spacing(1),
		borderRadius: theme.spacing(2),
	},

	selectedCommentBtn: {
		backgroundColor: theme.newColors.gray[100],
	},
	attemptList: {
		width: '100%',
		overflow: 'hidden',
		flex: '1 1 auto',
		'& .MuiPaper-root ': {
			// background: 'transparent',
		},
	},
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-dot': {
		background: theme.newColors.red[600],
		position: 'absolute',
		right: theme.spacing(1.25),
		top: '2px',
		boxShadow: '0px 0px 0px 2px white',
	},
}));

export { useStyles, StyledBadge };
