export default {
	gradeWeight: [],
	mcGetGradeWeightSuccess: null,
	updateGradeWeightSuccess: null,
	updateGradeWeightFailed: null,
	error: null,
	updateSuccess: undefined,
	fetchedData: false,
	isGradeWeightConflict: false,

	updateGradeCalculationSuccess: undefined,
	updateGradeCalculationFailed: undefined,
	gradeCalculation: null,
};
