import React from 'react';

import Loading from 'components/TblLoading';
import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { Routing } from 'utils/middlewareRoute';

import { MODULE_ALIAS, PERMISSIONS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/icn_myCourses.svg';

import { EmptyStudentPage, ForbiddenPage } from './components/ErrorPage';
import { ROUTE_MY_COURSES } from './constantsRoute';
import ActivityDetailPage from './containers/ActivityDetailPage';
import GuardCourse from './Guards/GuardCourse';
import reducer from './reducers';

const MyCourses = loadable(() => import(/* webpackChunkName: "LazyMyCourses" */ './containers'), {
	fallback: <Loading />,
});
const MyCoursesDetail = loadable(
	() => import(/* webpackChunkName: "LazyMyCoursesDetail" */ './containers/MyCoursesDetail'),
	{
		fallback: <Loading />,
	},
);
const CourseInfoDetail = loadable(
	() => import(/* webpackChunkName: "LazyCourseInfoDetail" */ './containers/CourseInfoDetail'),
	{
		fallback: <Loading />,
	},
);
const appendReducer = (currentState) => !!!currentState?.myCoursesList;

// const { EmptyStudentPage, ForbiddenPage } = loadable(() => import('components/TblErrorPage'));

export default [
	{
		path: [
			ROUTE_MY_COURSES.DEFAULT,
			ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(':studentId'),
			ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT(':courseId'),
		],
		component: withReducer(
			'AllCourses',
			reducer,
			appendReducer,
		)(
			Routing(
				{
					defaultPath: ROUTE_MY_COURSES.DEFAULT,
				},
				{
					pathWithQuery: ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(':studentId'),
					redirectToThis: (studentId) => ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(studentId),
				},
				{
					errorPath: ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT(':courseId'),
					errorRedirect: () => ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT(),
					ErrorComponent: EmptyStudentPage,
				},
				MyCourses,
			),
		),
		permissions: [PERMISSIONS.COURSE_ACTIVITY.READ_PUBLIC, PERMISSIONS.COURSE_ACTIVITY.READ],
		moduleAlias: MODULE_ALIAS.ACTIVITY,
		private: true,
		exact: true,
		menu: {
			title: 'Courses',
			group: 'noname',
			icon: IcnMenu,
			order: 2,
		},
	},
	{
		path: ROUTE_MY_COURSES.MY_COURSES_ACTIVITY_DETAIL_PAGE(':courseId', ':unitId', ':activityId', ':activityType'),
		component: withReducer(
			'AllCourses',
			reducer,
			appendReducer,
		)(
			GuardCourse({
				permissions: [PERMISSIONS.COURSE_ACTIVITY.UPDATE],
				Component: ActivityDetailPage,
				module: MODULE_ALIAS.ACTIVITY,
			}),
		),
		private: true,
		exact: true,
	},
	// {
	// 	path: [ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(':studentId'), ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT()],
	// 	component: withReducer(
	// 		'AllCourses',
	// 		reducer,
	// 	)(
	// 		GuardianMW(
	// 			{
	// 				defaultPath: ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(':studentId'),
	// 				defaultRedirect: (studentId) => ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(studentId),
	// 				DefaultComponent: MyCourses,
	// 			},
	// 			{
	// 				errorPath: ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT(),
	// 				errorRedirect: () => ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT(),
	// 				ErrorComponent: EmptyStudentPage,
	// 			},
	// 		),
	// 	),
	// 	roles: [GUARDIAN],
	// 	menu: {
	// 		title: 'Courses',
	// 		group: 'noname',
	// 		icon: IcnMenu,
	// 		order: 0,
	// 	},
	// 	exact: true,
	// 	private: true,
	// 	getSelectedPath: (context) => {
	// 		if (isGuardian(context?.currentUser)) {
	// 			if (!!context?.currentStudentId) {
	// 				return ROUTE_MY_COURSES.MY_COURSES_GUARDIAN(context.currentStudentId);
	// 			}
	// 			return ROUTE_MY_COURSES.MY_COURSES_GUARDIAN_NO_STUDENT();
	// 		}
	// 		return ROUTE_MY_COURSES.DEFAULT;
	// 	},
	// },
	{
		path: [
			ROUTE_MY_COURSES.MY_COURSES_DETAIL(':courseId'),
			ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(':studentId', ':courseId'),
			ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN_NO_STUDENT(':courseId'),
		],
		component: withReducer(
			'AllCourses',
			reducer,
		)(
			GuardCourse(
				{
					permissions: [PERMISSIONS.COURSE.READ],
					Component: MyCoursesDetail,
				},
				{
					permissions: [PERMISSIONS.COURSE.READ_PUBLIC],
					Component: Routing(
						{
							defaultPath: ROUTE_MY_COURSES.MY_COURSES_DETAIL(':courseId'),
						},
						{
							pathWithQuery: ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(':studentId', ':courseId'),
							redirectToThis: (studentId, params) =>
								ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(studentId, params?.courseId),
						},
						{
							errorPath: ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN_NO_STUDENT(':courseId'),
							errorRedirect: null,
							ErrorComponent: ForbiddenPage,
						},
						CourseInfoDetail,
					),
				},
			),
		),
		private: true,
		exact: true,
	},
	// {
	// 	path: [
	// 		ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(':studentId', ':courseId'),
	// 		ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN_NO_STUDENT(':courseId'),
	// 	],
	// 	component: withReducer(
	// 		'AllCourses',
	// 		reducer,
	// 	)(
	// 		Routing(
	// 			{
	// 				defaultPath: ROUTE_MY_COURSES.MY_COURSES_DETAIL(':courseId'),
	// 			},
	// 			{
	// 				pathWithQuery: ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(':studentId', ':courseId'),
	// 				redirectToThis: (studentId, params) =>
	// 					ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN(studentId, params?.courseId),
	// 			},
	// 			{
	// 				errorPath: ROUTE_MY_COURSES.MY_COURSES_DETAIL_GUARDIAN_NO_STUDENT(':courseId'),
	// 				errorRedirect: null,
	// 				ErrorComponent: ForbiddenPage,
	// 			},
	// 			CourseInfoDetail,
	// 		),
	// 	),
	// 	private: true,
	// 	exact: true,
	// 	roles: [GUARDIAN],
	// },
];
