import { makeAjaxRequest } from 'utils/ajax';

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { of, timer } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import dashboardActions from './actions';
import { END_POINT, actions } from './constants';

const fetchCourseSectionsEpic = (action$) =>
	action$.pipe(
		ofType(actions.FETCH_COURSE_SECTIONS),
		switchMap((action) =>
			makeAjaxRequest(
				END_POINT.get_courses_and_sections.method,
				END_POINT.get_courses_and_sections.url(action.payload.orgId, action.payload.urlParams),
			).pipe(
				mergeMap(({ response }) =>
					of(
						dashboardActions.fetchCourseSectionsSuccess({
							loadingControlBar: false,
							coursesWithSections: response.data.courseList,
							fetchCourseSectionsSuccess: true,
						}),
					),
				),
				catchError((error) =>
					of(
						dashboardActions.fetchCourseSectionsFailed({
							error: error?.response?.errors,
							loadingControlBar: false,
							fetchCourseSectionsSuccess: false,
						}),
					),
				),
			),
		),
	);

const fetchStudentPerformanceEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.FETCH_STUDENT_PERFORMANCE),
		mergeMap((action) => {
			const query = action.payload?.urlParams?.query || '';
			const originalState = {};

			const queryList = query.split(',');
			if (queryList.includes('participation-distribution')) {
				originalState.loadingParticipationDistribution = false;
			}
			if (queryList.includes('at-risk-students')) {
				originalState.loadingAtRiskStudents = false;
			}
			if (queryList.includes('grade-distribution')) {
				originalState.loadingGradeDistributionByTask = false;
				originalState.loadingGradeDistributionOverall = false;
			}
			if (queryList.includes('task-progress')) {
				originalState.loadingTaskProgressClosed = false;
				originalState.loadingTaskProgressActive = false;
			}

			const transformData = (data) => {
				if (data?.taskProgress) {
					const taskProgress = cloneDeep(state$.value.DashBoard.taskProgress);
					if (data.taskProgress?.active) {
						taskProgress.active = data.taskProgress.active;
						taskProgress.amountActive = data.taskProgress.active.length || 0;
					}
					if (data.taskProgress?.closed) {
						taskProgress.closed = data.taskProgress.closed;
						taskProgress.amountClosed = data.taskProgress.closed.length || 0;
						taskProgress.missedRate = (
							((data.taskProgress.closed || []).reduce((pre, curr) => {
								return pre + curr?.totalMissed;
							}, 0) /
								(data.taskProgress.closed || []).reduce((pre, curr) => {
									return pre + curr?.totalAssign;
								}, 0)) *
							100
						).toFixed(2);
					}

					return {
						...data,
						taskProgress,
					};
				}

				return data;
			};

			return makeAjaxRequest(
				END_POINT.fetch_student_performance.method,
				END_POINT.fetch_student_performance.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.sectionId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(({ response }) =>
					of(
						dashboardActions.fetchStudentPerformanceSuccess({
							...originalState,
							...transformData(response.data),
						}),
					),
				),
				catchError((error) =>
					of(
						dashboardActions.fetchStudentPerformanceFailed({
							error: error?.response?.errors,
							...originalState,
						}),
					),
				),
			);
		}),
	);

/**
 * This is not effective for this time, but we need fast develop this time so pooling is a acceptable solution for this time
 * TODO: impl websocket/sse for update realtimeu
 */
const poolingFetchStudentPerformanceEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.POOLING_FETCH_STUDENT_PERFORMANCE),
		mergeMap((action) => {
			const query = action.payload?.urlParams?.query || '';
			const originalState = {};

			const queryList = query.split(',');
			if (queryList.includes('participation-distribution')) {
				originalState.loadingParticipationDistribution = false;
			}
			if (queryList.includes('at-risk-students')) {
				originalState.loadingAtRiskStudents = false;
			}
			if (queryList.includes('grade-distribution')) {
				originalState.loadingGradeDistributionByTask = false;
				originalState.loadingGradeDistributionOverall = false;
			}
			if (queryList.includes('task-progress')) {
				originalState.loadingTaskProgressClosed = false;
				originalState.loadingTaskProgressActive = false;
			}

			const transformData = (data) => {
				if (data?.taskProgress) {
					const taskProgress = cloneDeep(state$.value.DashBoard.taskProgress);
					if (data.taskProgress?.active) {
						taskProgress.active = data.taskProgress.active;
						taskProgress.amountActive = data.taskProgress.active.length || 0;
					}
					if (data.taskProgress?.closed) {
						taskProgress.closed = data.taskProgress.closed;
						taskProgress.amountClosed = data.taskProgress.closed.length || 0;
						taskProgress.missedRate = (
							((data.taskProgress.closed || []).reduce((pre, curr) => {
								return pre + curr?.totalMissed;
							}, 0) /
								(data.taskProgress.closed || []).reduce((pre, curr) => {
									return pre + curr?.totalAssign;
								}, 0)) *
							100
						).toFixed(2);
					}

					return {
						...data,
						taskProgress,
					};
				}

				return data;
			};
			return timer(5, 5*60*1000).pipe(
				// filter(() => !isRequesting),

				switchMap(() =>
					makeAjaxRequest(
						END_POINT.fetch_student_performance.method,
						END_POINT.fetch_student_performance.url(
							action.payload.orgId,
							action.payload.courseId,
							action.payload.sectionId,
							action.payload.urlParams,
						),
					).pipe(
						mergeMap(({ response }) =>
							of(
								dashboardActions.poolingFetchStudentPerformanceSuccess({
									...originalState,
									...transformData(response.data),
									lastUpdatedTime: moment().format(),
								}),
							),
						),
						catchError((error) =>
							of(
								dashboardActions.poolingFetchStudentPerformanceFailed({
									error: error?.response?.errors,
								}),
							),
						),
					),
				),
				takeUntil(
					action$.pipe(ofType(actions.STOP_POOLING_FETCH_STUDENT_PERFORMANCE))
				),
			);
			// .pipe(
			// 	takeUntil(
			// 		merge(
			// 			action$.pipe(ofType(actions.STOP_POOLING_FETCH_STUDENT_PERFORMANCE)),
			// 			action$.pipe(ofType(actions.POOLING_FETCH_STUDENT_PERFORMANCE_FAILED)),
			// 		),
			// 	),
			// );
		}),
	);

const fetchTaskProgressDetailEpic = (action$, state$) =>
	action$.pipe(
		ofType(actions.FETCH_TASK_PROGRESS_DETAIL),
		debounceTime(500),
		mergeMap((action) => {
			return makeAjaxRequest(
				END_POINT.fetch_task_progress_detail.method,
				END_POINT.fetch_task_progress_detail.url(
					action.payload.orgId,
					action.payload.courseId,
					action.payload.sectionId,
					action.payload.urlParams,
				),
			).pipe(
				mergeMap(({ response }) =>
					of(
						dashboardActions.fetchTaskProgressDetailSuccess({
							taskProgressDetail: response.data,
							fetchTaskProgressDetailSuccess: true,
							loadingTaskProgressDetail: false,
						}),
					),
				),
				catchError((error) =>
					of(
						dashboardActions.fetchTaskProgressDetailFailed({
							error: error?.response?.errors,
							fetchTaskProgressDetailSuccess: false,
							loadingTaskProgressDetail: false,
						}),
					),
				),
			);
		}),
	);

export default [
	fetchCourseSectionsEpic,
	fetchStudentPerformanceEpic,
	fetchTaskProgressDetailEpic,
	poolingFetchStudentPerformanceEpic,
];
