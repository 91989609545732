/* eslint-disable max-len */
import { lazy, memo } from 'react';
import { Suspense } from 'react';

import { Box, CustomScrollbar } from 'ella-storybook';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { TABS } from './constants';

const Instruction = lazy(() => import('modules/MyCourses/containers/ActivityDetailPage/Instruction'));
const StudentWork = lazy(() => import('modules/MyCourses/containers/ActivityDetailPage/StudentWork'));
const TabPanelWrapper = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box display={'flex'} gap={2} justifyContent={'center'}>
					{children}
				</Box>
			)}
		</Box>
	);
};

const TabPanel = ({
	translate,
	organizationId,
	courseId,
	sections,
	activityStudentWorkList,
	currentTab,
	selectedTab,
	warningBannerInfo,
	activityData,
	selectedSection,
	isSearching,
	queryParams,
	headerElementInfo,
	handleShowGraderModal,
	handleDispatchSetState,
}) => {
	const TOP_BANNER_HEIGHT = '68';
	const SCROLL_BAR_LENGTH = '150';
	const FIXED_WIDTH = '100%';

	return (
		<CustomScrollbar
			allowScroll={TABS.instruction !== TABS[currentTab]}
			maxHeightScroll={`calc( 100vh - ${TOP_BANNER_HEIGHT}px - ${get(warningBannerInfo, 'clientHeight', 0)}px - ${get(
				headerElementInfo,
				'clientHeight',
				0,
			)}px )`}
			style={{ width: FIXED_WIDTH }}
			maxScrollbarLength={SCROLL_BAR_LENGTH}
		>
			<Box paddingBottom={(theme) => theme.spacing(10)} display={'flex'} justifyContent={'center'} width={FIXED_WIDTH}>
				<Box flexBasis={(theme) => theme.spacing(125)} maxWidth={(theme) => theme.spacing(125)}>
					<TabPanelWrapper value={selectedTab} index={0}>
						<Suspense fallback={<div />}>
							<Instruction
								{...{
									activityData,
									translate,
									currentTab,
									queryParams,
									warningBannerInfo,
									headerElementInfo,
									handleDispatchSetState,
								}}
							/>
						</Suspense>
					</TabPanelWrapper>
					<TabPanelWrapper value={selectedTab} index={1}>
						<Suspense fallback={<div />}>
							<StudentWork
								{...{
									translate,
									currentTab,
									organizationId,
									courseId,
									sections,
									activityStudentWorkList,
									activityData,
									selectedSection,
									isSearching,
									handleShowGraderModal,
								}}
							/>
						</Suspense>
					</TabPanelWrapper>
				</Box>
			</Box>
		</CustomScrollbar>
	);
};

TabPanel.propTypes = {
	activityData: PropTypes.object,
	activityStudentWorkList: PropTypes.any,
	courseId: PropTypes.any,
	currentTab: PropTypes.any,
	handleDispatchSetState: PropTypes.any,
	handleShowGraderModal: PropTypes.any,
	isSearching: PropTypes.any,
	organizationId: PropTypes.any,
	queryParams: PropTypes.any,
	sections: PropTypes.any,
	selectedSection: PropTypes.any,
	selectedTab: PropTypes.any,
	translate: PropTypes.any,
	warningBannerInfo: PropTypes.object,
};

export default memo(TabPanel);
