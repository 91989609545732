import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import TblCustomScrollbar from 'components/TblCustomScrollbar';

import useWindowSize from 'utils/windowSize';

import { Typography } from 'ella-storybook';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	containerLeft: {
		height: '100vh',
		padding: theme.spacing(2, 3, 0, 3),
		width: theme.spacing(37.5),
	},
	containerRight: {
		width: 'calc(100% - 321px)',
	},
	title: {
		display: 'unset',
	},
}));
function Layout2(props) {
	const theme = useTheme();
	const { classes } = useStyles();
	const size = useWindowSize();
	// 70 is height breadcrumb
	const height = size.height - 90;
	const scrollHeight = theme.navbar.appBar + theme.navbar.headerTab;
	const { children, background } = props;
	const {
		title: titleLeft,
		children: childrenLeft,
		style,
		styleContainerLeft,
		classNameLeft,
		customStyleLeft,
	} = children[0].props;

	const { title: titleRight, children: childrenRight, variant, styleContainerRight } = children[1].props;

	const layoutContent = (
		<Grid container spacing={0}>
			<Grid
				id='course-content-scroll'
				item
				className={`${classes.containerLeft} ${classNameLeft}`}
				sx={{
					...customStyleLeft,
				}}
				style={{
					...styleContainerLeft,
					background: background ?? `${theme.newColors.gray[100]}`,
					paddingBottom: theme.spacing(10),
				}}
			>
				{!!titleLeft && (
					<Typography variant='h5' className='title-container text-ellipsis'>
						{titleLeft}
					</Typography>
				)}
				<Box style={style}>{childrenLeft}</Box>
			</Grid>
			<Grid item className={classes.containerRight}>
				<TblCustomScrollbar maxHeightScroll={`calc(100vh - ${scrollHeight}px)`} paddingBottom={theme.spacing(10)}>
					<Box
						component='div'
						pt={!!titleRight ? 2 : 0}
						pl={5}
						pr={4}
						pb={5}
						style={{
							position: 'relative',
							...styleContainerRight,
						}}
					>
						{typeof titleRight === 'string' && (
							<Box display='flex' className='text-ellipsis-2row' mb={2} justifySelf='center' justifyItems='center'>
								<Typography component='div' variant={variant || 'labelMedium'} className='text-ellipsis-2row'>
									{titleRight}
								</Typography>
							</Box>
						)}
						{React.isValidElement(titleRight) && <>{titleRight}</>}
						<Box>{childrenRight}</Box>
					</Box>
				</TblCustomScrollbar>
			</Grid>
		</Grid>
	);

	return (
		<Box height={height}>
			{/* fix bug scrollbar */}
			{layoutContent}
		</Box>
	);
}

Layout2.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node,
	background: PropTypes.string,
	scrollable: PropTypes.bool,
};

export default Layout2;
