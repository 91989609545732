import { objectToParams } from 'utils';

export default {
	mc_get_grade_calculation: {
		url: (id, courseId, urlParams) =>
			`${process.env.REACT_APP_API_URL_V2}/organization/${id}/courses/${courseId}/gradeWeight?${objectToParams(
				urlParams,
			)}`,
		method: 'GET',
	},
	mc_update_grade_calculation: {
		url: (id, courseId) => `${process.env.REACT_APP_API_URL_V2}/organization/${id}/courses/${courseId}/gradeWeight`,
		method: 'PUT',
	},
};
