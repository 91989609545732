import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

import { Box, Typography } from 'ella-storybook';

const GradingStatusSaved = ({ isSubmittingGrade }) => {
  const { t } = useTranslation();
  const [hiddenChecked, setHiddenChecked] = useState(false);

  const isSubmittingRef = useRef(isSubmittingGrade);
  let returnResult = <></>;

  if (isSubmittingGrade) {
    isSubmittingRef.current = isSubmittingGrade;

    returnResult = (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
        <CircularProgress size={20} color='primary' />
        <Typography
          component={'div'}
          variant='titleSmall'
          fontWeight={400}
          ml={1}
          sx={{ color: (theme) => theme.newColors.gray[500] }}
        >
          {t('saving', { ns: 'myCourses' })}
        </Typography>
      </div>
    );
  }

  if ((!isSubmittingGrade && isSubmittingGrade !== isSubmittingRef.current) || hiddenChecked) {
    if (!hiddenChecked) {
      setTimeout(() => {
        setHiddenChecked(true);
        isSubmittingRef.current = isSubmittingGrade;
      }, 3000);
    } else {
      setHiddenChecked(false);
      isSubmittingRef.current = false;
    }

    returnResult = (
      <div style={{ display: hiddenChecked ? 'none' : 'flex', justifyContent: 'center', alignItems: 'end' }}>
        <CheckCircleIcon sx={{ color: (theme) => theme.mainColors.green[0] }} size={20} />
        <Typography
          component={'div'}
          variant='titleSmall'
          fontWeight={400}
          ml={1}
          sx={{ color: (theme) => theme.newColors.gray[500] }}
        >
          {t('saved', { ns: 'myCourses' })}
        </Typography>
      </div>
    );
  }

  return (
    <Box ml={1} height={'100%'}>
      {returnResult}
    </Box>
  );
};

export default React.memo(GradingStatusSaved);
