import React from 'react';
import { connect } from 'react-redux';

import Preloader from 'components/TblPreloader';

import { AuthDataContext } from 'AppRoute/AuthProvider';
import PropTypes from 'prop-types';

import authActions from '../actions';

// import Suspended from './Suspended';

class DomainStatus extends React.PureComponent {
	static contextType = AuthDataContext;

	constructor(props) {
		super(props);

		this.state = {
			already: false,
		};
		// console.log(window.location.search);
		// console.log(searchParams.entries());
	}

	componentDidMount() {
		const domain = process.env.REACT_APP_BASE_URL;
		const subdomain = window.location.hostname.split('.');
		const searchParams = new URLSearchParams(window.location.search);
		const ignoreDomains = process.env.REACT_APP_IGNORE_DOMAIN?.split(',');
		const currentHostname = window.location.hostname;
		// console.log(searchParams.entries());
		// Handle Single Sign On
		if (searchParams.get('new_session') === 'true') {
			// removeSchoolYear();
		}
		if (searchParams.has('access_token')) {
			localStorage.setItem('access_token', searchParams.get('access_token'));
			this.context.setData({ token: searchParams.get('access_token') });
			window.history.pushState('', '', window.location.pathname);
			this.setState({ already: true });

			searchParams.delete('access_token');

			const fullUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;

			window.location.href = fullUrl;
		} else {
			this.setState({ already: true });
		}
		// Check subdomain information
		// If not has let set to context
		//
		// Do not need to check subdomain anymore because it will be handled in LoginV2 container
		//
		// if (
		// 	domain !== window.location.hostname &&
		// 	subdomain.length >= 2 &&
		// 	this.context.subdomain !== subdomain[0] &&
		// 	ignoreDomains.indexOf(currentHostname) === -1
		// ) {
		// 	this.context.setData({ subdomain: subdomain[0], fetchingDomain: true });
		// 	this.props.authCheckDomainStatus({
		// 		isBusy: true,
		// 		subdomain: subdomain[0],
		// 		email: '',
		// 	});
		// }
	}

	componentDidUpdate(prevProps) {
		const { subDomainStatus } = this.props;
		if (subDomainStatus !== this.context.domainStatus && subDomainStatus !== prevProps.subDomainStatus) {
			// Set timeout to force function setData working
			// https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
			this.context.setData({
				domainStatus: subDomainStatus,
				fetchingDomain: false,
			});
			if (!this.state.already) {
				this.setState({ already: true });
			} else {
				this.forceUpdate();
			}
		}
	}
	componentWillUnmount() {}
	render() {
		if (this.context.fetchingDomain || this.context.fetchingUser || !this.state.already) {
			return <Preloader />;
		}
		return this.props.children;
	}
}

DomainStatus.propTypes = {
	t: PropTypes.func,
	children: PropTypes.node,
	subDomainStatus: PropTypes.number,
	errorCode: PropTypes.number,
	location: PropTypes.object,
	authCheckDomainStatus: PropTypes.func,
	setState: PropTypes.func,
	isBusy: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	subDomainStatus: state.Auth.subDomainStatus,
	errorCode: state.Auth.errorCode,
	isBusy: state.Auth.isBusy,
});

const mapDispatchToProps = (dispatch) => ({
	authCheckDomainStatus: (payload) => dispatch(authActions.authCheckDomainStatus(payload)),
	setState: (payload) => dispatch(authActions.authSetState(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainStatus);
