import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';

import { ReactComponent as IcnMyCourses } from 'assets/images/sidebarIcon/teach.svg';

import { ROUTE_AGENDA } from './constantsRoute';
import reducer from './reducers';

const Agenda = loadable(() => import(/* webpackChunkName: "LazyAgenda" */ './containers'));

export default [
	{
		path: ROUTE_AGENDA.DEFAULT,
		component: withReducer('Agenda', reducer)(Agenda),
		private: true,
		roles: ['Teacher'],
		exact: true,
		menu: {
			title: 'Teach', // update at ticket EL-6675
			icon: IcnMyCourses,
			group: 'noname',
			order: 3,
		},
	},
];
