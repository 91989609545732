import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Grid } from '@mui/material';

import StoryBookCheckBox from 'components/StoryBookCheckBox';

import { PUBLISH_NOW } from 'shared/MyCourses/constants';
import { addingValuesForGroupSessions } from 'shared/MyCourses/utils';

import { Dropdown, IconButton, Tooltip } from 'ella-storybook';
import { Field } from 'formik';
import { pick } from 'lodash';
import { ACTIVITY_CATEGORY, ASSIGNMENT_STATUS, TEST_STATUS } from 'modules/MyCourses/constants';
import moment from 'moment';

import { ScheduleLabel, ScheduleLayout, ScheduleRow } from './styles';
const DATE_TYPE = {
	ASSIGN: 1,
	DUE: 2,
};

export default function SectionSchedule(props) {
	const {
		assignLabel,
		dueLabel,
		sessions,
		sections,
		handleChange,
		submitCount,
		values,
		errors,
		touched,
		handleBlur,
		setValues,
		isSubmitting,
		setFieldValue,
		setFieldTouched,
		activityType,
		showOldSessions,
		emptyText,
		headerOptions,
	} = props;
	const { t } = useTranslation(['myCourses', 'common']);

	const [errorForLabel, setErrorForLabel] = useState({
		assignOn: false,
		dueOn: false,
	});

	const processDataSessions = (sectionId, type) => {
		const today = moment();

		const filteredArray = sessions.flatMap((courseDay) =>
			courseDay.sectionSchedules
				.filter((section) => section.sectionId === sectionId)
				.map((section) => {
					const time =
						type === DATE_TYPE.ASSIGN || activityType === ACTIVITY_CATEGORY.TEST
							? section.startTimeLabel
							: section.endTimeLabel;
					let additionData = {};
					if (moment(section.start).isBefore(today)) {
						additionData = addingValuesForGroupSessions({}, 'OLD_SESSION');
						additionData.isInThePast = true;
					} else {
						additionData = addingValuesForGroupSessions({}, 'UPCOMING_SESSION');
						additionData.isInThePast = false;
					}
					return {
						value: Number(courseDay.id),
						label: `${section.sessionName} (${section.dateTimeLabel} - ${time})`,
						start: section.start,
						end: section.end,
						id: section.sectionId,
						...additionData,
					};
				}),
		);
		if (filteredArray.length) {
			if (!filteredArray.find((item) => item.category === 'Old sessions')) {
				filteredArray.unshift({
					label: '',
					value: -1,
					info: {},
					category: 'Old sessions',
					isHidden: true,
					disabled: true,
				});
			}
			if (!filteredArray.find((item) => item.category === 'Upcoming sessions')) {
				filteredArray.push({
					label: '',
					value: -1,
					info: {},
					category: 'Upcoming sessions',
					isHidden: true,
					disabled: true,
				});
			}
		}

		return filteredArray;
	};

	const filterOldSessions = (sessions) => {
		if (!sessions) {
			return [];
		}
		if (showOldSessions) {
			return sessions;
		}
		return sessions.map((s) => ({
			...s,
			isHidden: s.inThePast ? true : false,
		}));
	};

	const sectionSchedules = sections.reduce((obj, item) => {
		const { id: sectionId } = item;
		obj[sectionId] = {
			assign: processDataSessions(sectionId, DATE_TYPE.ASSIGN),
			due: processDataSessions(sectionId, DATE_TYPE.DUE),
		};
		return obj;
	}, {});

	useEffect(() => {
		if (isSubmitting && errors?.sections?.length && touched?.sections?.length) {
			const checkAssign = errors.sections.some(
				(error, index) =>
					error?.assignDate === t('required_message', { ns: 'common' }) && values?.sections?.[index]?.select === true,
			);
			const checkDue = errors.sections.some(
				(error, index) =>
					error?.dueDate === t('required_message', { ns: 'common' }) && values?.sections?.[index]?.select === true,
			);
			setErrorForLabel({
				assignOn: checkAssign,
				dueOn: checkDue,
			});
		} else {
			setErrorForLabel({
				assignOn: false,
				dueOn: false,
			});
		}
	}, [errors?.sections, touched?.sections, values?.sections]);

	const applyAll = (selectedSection) => {
		// const { sections } = values;

		if (sections.length) {
			const { assignDate, dueDate } = sections[selectedSection];
			if (!assignDate || !dueDate) return;
			const { sectionSchedules: relateSectionsAssignDate } =
				sessions.find(({ id }) => Number(id) === Number(assignDate.value)) || Number(assignDate.value) === PUBLISH_NOW
					? {
							sectionSchedules: sections.map((section,idx) => ({
								...pick(assignDate, ['label', 'value']),
								index: idx,
								sectionId: section.id,
							})),
					  }
					: {
							sectionSchedules: [],
					  };
			const { sectionSchedules: relateSectionsDueDate } = sessions.find(
				({ id }) => Number(id) === Number(dueDate.value),
			);

			const newSections = sections.map((section) => {
				const isSameSession =
					relateSectionsAssignDate.findIndex(({ sectionId }) => Number(sectionId) === Number(section.id)) !== -1 &&
					relateSectionsDueDate.findIndex(({ sectionId }) => Number(sectionId) === Number(section.id)) !== -1;

				if (isSameSession && section.select && !section.sectionDisabled) {
					const appliedAssignDate = relateSectionsAssignDate.find(
						({ sectionId }) => Number(sectionId) === Number(section.id),
					);
					const appliedDueDate = relateSectionsDueDate.find(
						({ sectionId }) => Number(sectionId) === Number(section.id),
					);

					//current selected assigned is in future or null
					if (appliedAssignDate.value !== PUBLISH_NOW) {
						if (
							!section?.assignDate ||
							(section.assignDate.start && moment(section.assignDate.start).isAfter(moment())) || 
							!section.status
						) {
							section.assignDate = {
								...section.assignDate,
								value: Number(appliedAssignDate.value),
								label: appliedAssignDate.label,
								start: appliedAssignDate.start,
								end: appliedAssignDate.end,
							};
						}
					} else {
						// if current assign value in the past --> not apply
						if (
							appliedAssignDate.value === PUBLISH_NOW &&
							(!section?.assignDate || !section.assignDate.sectionDisabled)
						) {
							section.assignDate = {
								...appliedAssignDate,
							};
						}
					}
					section.dueDate = {
						...section.dueDate,
						value: Number(dueDate.value),
						label: `${appliedDueDate.sessionName} (${appliedDueDate.dateTimeLabel} - ${appliedDueDate.endTimeLabel})`,
						start: appliedDueDate.start,
						end: appliedDueDate.end,
					};
				}
				return section;
			});

			setValues({ ...values, sections: newSections });
		}
	};
	const publishNowOnSection = (e, props) => {
		const { value, item, onChange } = props;
		e.preventDefault(); // prevent blur
		if (value?.index === item.index) {
			//   setDisableAllOptions(false);
			onChange && onChange(null);
			return;
		}
		if (onChange) {
			//   setDisableAllOptions(true);
			onChange(item);
		}
	};

	return (
		<ScheduleLayout>
			<Grid container spacing={2}>
				<Grid item xs={3} className='scheduleItem'>
					<ScheduleLabel variant='labelMedium'>{'Section'}</ScheduleLabel>
				</Grid>
				<Grid item xs={4} className='scheduleItem'>
					<ScheduleLabel
						sx={{ color: (theme) => errorForLabel.assignOn && theme.newColors.red[600] }}
						variant='labelMedium'
					>
						{assignLabel}
					</ScheduleLabel>
				</Grid>
				<Grid item xs={4} className='scheduleItem'>
					<ScheduleLabel
						sx={{ color: (theme) => errorForLabel.dueOn && theme.newColors.red[600] }}
						variant='labelMedium'
					>
						{dueLabel}
					</ScheduleLabel>
				</Grid>
			</Grid>
			<>
				{sections?.map((section, index) => {
					const sectionsErrors = (errors?.sections?.length && errors.sections[index]) || {};
					const sectionsTouched = (touched?.sections?.length && touched.sections[index]) || {};
					const sectionDisabled =
						activityType === ACTIVITY_CATEGORY.ASSIGNMENT
							? [ASSIGNMENT_STATUS.CLOSED, ASSIGNMENT_STATUS.PUBLISHED_LATE].includes(section.status)
							: section.status === TEST_STATUS.CLOSED;
					section.sectionDisabled = sectionDisabled;

					const disabledAll = section?.assignDate?.value === PUBLISH_NOW;

					return (
						<ScheduleRow container spacing={2}>
							<Grid item xs={3} className='scheduleItem'>
								<StoryBookCheckBox
									name={`sections.${index}.select`}
									label={section.sectionName}
									labelPlacement='end'
									checked={section?.select}
									onChange={(value) => {
										handleChange(`sections.${index}.select`, value, setFieldValue, values);
										handleBlur(`sections.${index}.assignDate`, setFieldTouched);
										handleBlur(`sections.${index}.dueDate`, setFieldTouched);
									}}
									disabled={sectionDisabled}
								/>
							</Grid>
							<Grid item xs={4} className='scheduleItem'>
								<Field
									component={Dropdown}
									multiple={false}
									disableCloseOnSelect={false}
									fitContent={true}
									groupBy={(option) => showOldSessions && option.category}
									placement={'bottom-start'}
									isOptionEqualToValue={(option, inputOption) => option.value === inputOption.value}
									width={'100%'}
									name={`sections.${index}.assignDate`}
									helperText={
										!!((sectionsTouched?.assignDate || submitCount) && sectionsErrors?.assignDate) &&
										sectionsErrors?.assignDate
									}
									onClose={() => {
										if (section.select) {
											handleBlur(`sections.${index}.assignDate`, setFieldTouched);
										}
									}}
									value={section?.assignDate}
									error={!!((sectionsTouched?.assignDate || submitCount) && sectionsErrors?.assignDate)}
									placeholder={t('common:please_select')}
									// options={processDataSessions(section.id, DATE_TYPE.ASSIGN)}
									options={filterOldSessions(sectionSchedules[section.id]?.assign) || []}
									disabled={
										!sections[index].select ||
										sectionDisabled ||
										(activityType === ACTIVITY_CATEGORY.ASSIGNMENT
											? section.status === ASSIGNMENT_STATUS.PUBLISHED
											: section.status === TEST_STATUS.PUBLISHED)
									}
									onChange={(value) => {
										handleChange(`sections.${index}.assignDate`, value, setFieldValue, values);
										if (!value) {
											handleBlur(`sections.${index}.assignDate`, setFieldTouched);
										}
									}}
									disableAllOption={disabledAll}
									stickyOptions={headerOptions(publishNowOnSection,index)}
									emptyGroupText={emptyText(sectionSchedules[section.id]?.assign || [])}
								/>
							</Grid>
							<Grid item xs={4} className='scheduleItem'>
								<Field
									component={Dropdown}
									disableCloseOnSelect={false}
									multiple={false}
									fitContent={true}
									groupBy={(option) => showOldSessions && option.category}
									placement={'bottom-start'}
									width={'100%'}
									name={`sections.${index}.dueDate`}
									placeholder={t('common:please_select')}
									onClose={() => {
										if (section.select) {
											handleBlur(`sections.${index}.dueDate`, setFieldTouched);
										}
									}}
									value={section?.dueDate}
									helperText={
										!!((sectionsTouched?.dueDate || submitCount) && sectionsErrors?.dueDate) && sectionsErrors?.dueDate
									}
									error={!!((sectionsTouched?.dueDate || submitCount) && sectionsErrors?.dueDate)}
									// options={processDataSessions(section.id, DATE_TYPE.DUE)}
									disabled={!sections[index].select || sectionDisabled}
									options={filterOldSessions(sectionSchedules[section.id]?.due) || []}
									onChange={(value) => {
										handleChange(`sections.${index}.dueDate`, value, setFieldValue, values);
										if (!value) {
											handleBlur(`sections.${index}.dueDate`, setFieldTouched);
										}
									}}
									emptyGroupText={emptyText(sectionSchedules[section.id]?.assign || [])}
								/>
							</Grid>
							<Grid item xs={1} className='scheduleItem'>
								<Tooltip title={t('apply_all')} placement='top'>
									<IconButton
										size='medium'
										onClick={() => {
											applyAll(index);
										}}
									>
										<CopyAllIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</ScheduleRow>
					);
				})}
			</>
		</ScheduleLayout>
	);
}
