import { memo } from 'react';

import { Skeleton } from '@mui/material';

import TblIcon from 'components/TblIcon';

import { Box, IconButton, Typography } from 'ella-storybook';
import { get } from 'lodash';
import { Header, SubHeader } from 'modules/MyCourses/containers/ActivityDetailPage/styles';

const ActivityHeader = ({ activityData, isRelatedActivity, goBack, handleClickSubHeaderCallBack }) => {
	return (
		<>
			{!get(activityData, 'activityName', '') ? (
				<Skeleton variant='rounded' width={460} height={38} sx={{ marginBottom: '2px' }} />
			) : (
				<Box display={'flex'} gap={1}>
					{isRelatedActivity && (
						<IconButton sx={{ alignSelf: 'center' }} size='medium' onClick={goBack}>
							<TblIcon icon='arrow_back' />
						</IconButton>
					)}

					<Header variant='headlineSmall'>{get(activityData, 'activityName', '')}</Header>
				</Box>
			)}

			{!get(activityData, 'courseName', '') ? (
				<Skeleton variant='rounded' width={145} height={24} />
			) : (
				<SubHeader variant='text' onClick={handleClickSubHeaderCallBack}>
					<Typography variant='button' color={'secondary'}>
						{get(activityData, 'courseName', '')}
					</Typography>
				</SubHeader>
			)}
		</>
	);
};

export default memo(ActivityHeader);
