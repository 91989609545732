import createReducers from 'utils/createReducers';

import { actions } from './constants';

const initialState = {
	notifications: [],
	firebaseMessage: [],
	isGettingNotifications: false,
	lastNotification: 0,
	totalNew: 0,
	limit: 10,
	loadMore: false,
	timeClick: null,
	isThatAll: false,
};

export default createReducers(initialState, actions);
