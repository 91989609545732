import withReducer from 'components/TblWithReducer';

import loadable from 'utils/loadable';
import { DOMAIN_OWNER } from 'utils/roles';

import { MODULE_ALIAS, PERMISSION_BASIC_KEYS } from 'shared/Auth/constants';

import { ReactComponent as IcnMenu } from 'assets/images/sidebarIcon/domain_settings.svg';

import { ROUTE_DOMAIN_SETTINGS } from './constantsRoute';
import reducer from './reducers';

const DomainSettings = loadable(() => import(/* webpackChunkName: "LazyDomainSettings" */ './containers'));

export default [
	{
		path: ROUTE_DOMAIN_SETTINGS.DEFAULT,
		component: withReducer('DomainSettings', reducer)(DomainSettings),
		private: true,
		roles: [DOMAIN_OWNER],
		permissions: [PERMISSION_BASIC_KEYS.READ],
		moduleAlias: MODULE_ALIAS.DOMAIN_SETTING,
		exact: true,
		menu: {
			title: 'Domain Settings',
			icon: IcnMenu,
			group: 'Domain',
			order: 1,
			groupOrder: 3,
		},
	},
];
